import { useMemo } from "react";
import {
  LineChartEduExpensesMain,
  LineChartEduInvestMain,
} from "./EduLineChart";
import ViewTableExpenses from "./ViewTableExpenses";
import ViewTableInsuarance from "./ViewTableInsuarance";
import { STYLE_PARENT_TABLE_OVERSCROLL } from "../../../utils/useStyle";

const ViewInsuaranceFundEDU = ({ localEducationData }) => {
  const dataInsurance = useMemo(() => {
    const expenses_items = localEducationData?.education_items?.map(
      (education_item) => {
        return education_item.sum_insurance;
      }
    );
    return expenses_items;
  }, [localEducationData]);

  const dataLabels = useMemo(() => {
    const expenses_items = localEducationData?.education_items?.map(
      (education_item) => {
        return education_item?.education?.label;
      }
    );
    return expenses_items;
  }, [localEducationData]);

  const heightTable = localEducationData?.education_items?.length * 50 + 100;
  const height = `${heightTable}px`;

  const checkHeight = (height) => {
    let value = heightTable;
    // console.log(value);

    if (value > 600) {
      return 600;
    } else {
      return value;
    }
  };
  // console.log(checkHeight(height));

  return (
    <div>
      <div className=" mt-2 ">
        <LineChartEduInvestMain dataFund={dataInsurance} labels={dataLabels} />
      </div>
      {/* ตารางสรุปค่าใช้จ่ายเพื่อการศึกษา */}
      <div
        style={{
          height: `${checkHeight(height)}px`,
        }}
        className="px-6 rounded-[20px] overflow-auto  mt-[14px]"
      >
        <ViewTableInsuarance localEducationData={localEducationData} />
      </div>
    </div>
  );
};

export default ViewInsuaranceFundEDU;
