import { useNavigate } from "react-router-dom";
import Container from "../../../../components/layout/Container";
import {
  STYLE_INPUT,
  STYLE_INPUT_CARD_BALANCE,
  STYLE_MARGIN_TOP_PARENT,
  STYLE_PARENT_BUTTON_TWO,
  STYLE_PARENT_CARD_BALANCE,
  STYLE_PARENT_PADDING,
  STYLE_TEXT_BLUE,
  STYLE_TITLE_CARD_DRAWER_BL,
} from "../../../../utils/useStyle";
import { HeaderTitle } from "../../../../components/header/Header";
import NavigateGoBack from "../../../../functions/Navigate.Goback";
import { CardMain } from "../../../../components/card/CardMain";
import { InputMain } from "../../../../components/input/InputMain";
import { NumericFormat } from "react-number-format";
import { ICON_INFO } from "../../../../utils/useIcons";
import {
  ButtonCounter,
  MTButton,
} from "../../../../components/button/MTButton";
import { CardTaxPremium } from "../../styles/Card/CardMenuPremium";
import { InfoDrawer } from "../../../../components/drawer/InfoDrawer";
import { useState } from "react";
import { option_income_business_tax_40_6 } from "../../functions/options";
import NumbericValidateRed from "../../../../components/DebtBalance/numericRed";
import { useTaxStore } from "../../../../_store/taxplanStore";
import numeral from "numeral";
import { numberWithoutComma } from "../../../balance/component/VIDrawerDebt.Main";
import { PopupMain, PopupMain2 } from "../../../../components/popup/PopupMain";

export const PayerIncomeInfo = ({ openInfo, setOpenInfo }) => {
  return (
    <InfoDrawer
      head={"เลขผู้จ่ายเงินได้"}
      text={
        <div className=" mt-14">
          เลขประจำตัวผู้เสียภาษีอากรของผู้จ่ายเงินได้ ตามหนังสือรับรองการหัก ณ
          ที่จ่าย
        </div>
      }
      open={openInfo}
      onClose={() => {
        setOpenInfo(false);
      }}
    />
  );
};

const FreelanceIncomeInfo = ({ openFreeLanceInfo, setOpenFreeLanceInfo }) => {
  return (
    <InfoDrawer
      head={"เงินได้ตามมาตรา 40(2)"}
      text={
        <div className=" mb-4">
          เงินได้เนื่องจากหน้าที่
          หรือตำแหน่งงานที่ทำหรือจากการรับทำงานให้ไม่ว่าหน้าที่ ตำแหน่งงาน
          หรืองานที่รับทำให้จะเป็นการประจำหรือชั่วคราว เช่น ค่าธรรมเนียม
          ค่านายหน้า ส่วนลด เบี้ยประชุมเงินค่าเช่าบ้าน ค่าปรึกษา เงินประจำ
          ตำแหน่งและประโยชน์ตอบแทนอย่างอื่นเนื่องจากการเป็นสมาชิกสภาผู้แทนราษฎร
          สว. อบต. เป็นต้น
          <div className=" mt-2 text-sm">
            *** เงินได้ตามมาตรา 40(2) ต่างจาก 40(8) ดังนี้ 40(2)
            ใช้แรงงานเป็นสำคัญและมีค่าใช้จ่ายน้อย ส่วน 40(8)
            ทำในรูปของธุรกิจมีสำนักงาน และมีค่าใช้จ่ายในการดำเนินงานสูง ***
          </div>
        </div>
      }
      open={openFreeLanceInfo}
      onClose={() => {
        setOpenFreeLanceInfo(false);
      }}
    />
  );
};

const Income406Info = ({ open406Info, setOpen406Info }) => {
  return (
    <InfoDrawer
      head={"เงินได้ตามมาตรา 40(6)"}
      text={
        <div className=" mb-4">
          เงินได้จากวิชาชีพอิสระ คือ วิชากฎหมาย(ค่าว่าความ) การประกอบโรคศิลปะ
          (แพทย์เปิดคลีนิค) วิศวกรรม สถาปัตยกรรม การบัญชี(ค่าสอบบัญชี)
          ประณีตศิลปกรรม
        </div>
      }
      open={open406Info}
      onClose={() => {
        setOpen406Info(false);
      }}
    />
  );
};

const ViewIncomeFreelance = () => {
  const navigate = useNavigate();
  const taxData = useTaxStore((state) => state.taxData);
  const setTaxData = useTaxStore((state) => state.setTaxData);

  const [openInfo, setOpenInfo] = useState(false);
  const [openFreeLanceInfo, setOpenFreeLanceInfo] = useState(false);
  const [open406Info, setOpen406Info] = useState(false);
  const [popupError, setPopuperror] = useState({
    expense: false,
    Tax_Payer_ID: false,
  });

  const [formTax, setFormTax] = useState({
    ...taxData,
    // freeJob:[]
    freeJob: taxData?.freeJob
      ? taxData?.freeJob
      : [
          {
            type_job: "",
            tax_income: "",
            with_holding_tax: "",
            tax_payer_ID: "",
            calculating_method: "หักแบบเหมา",
          },
        ],
  });
  const [rowSecond, setRowSecond] = useState(formTax?.freeJob?.length || 0);

  const onChangeFreelane = (value, field) => {
    // let value = e.target.value;
    setFormTax((prev) => {
      return { ...prev, freelance: { ...formTax?.freelance, [field]: value } };
    });
  };

  const onChangeFreeJob = (value, field, index) => {
    let listOrder = [...formTax?.freeJob];
    let tempForm = { ...formTax?.freeJob?.[index] };
    let allInTax = 0;

    if (
      tempForm?.calculating_method === "หักแบบเหมา" &&
      field === "tax_income"
    ) {
      if (tempForm?.type_job?.value === " tax_income_medicine") {
        allInTax = value - value * 0.6;
      } else {
        allInTax = value - value * 0.3;
      }

      tempForm.income_for_calculating_all_in = allInTax;
    }

    if (value === "หักแบบเหมา" && field === "calculating_method") {
      if (tempForm?.type_job?.value === " tax_income_medicine") {
        allInTax = tempForm?.tax_income - tempForm?.tax_income * 0.6;
      } else {
        allInTax = tempForm?.tax_income - tempForm?.tax_income * 0.3;
      }

      tempForm.income_for_calculating_all_in = allInTax;
    }

    tempForm[field] = value;
    listOrder[index] = tempForm;

    setFormTax((prev) => {
      return { ...prev, freeJob: [...listOrder] };
    });
  };

  const AddOrder = () => {
    let listOrder = [];
    listOrder = [...(formTax?.freeJob || [])];
    let tempForm = {
      type_job: "",
      tax_income: "",
      with_holding_tax: "",
      tax_payer_ID: "",
      calculating_method: "หักแบบเหมา",
    };

    listOrder.push(tempForm);
    setFormTax((prev) => {
      return { ...prev, freeJob: listOrder };
    });
  };

  const DeleteOrder = (index) => {
    let listOrder = [...formTax?.freeJob];
    listOrder.splice(index, 1);
    setFormTax((prev) => {
      return { ...prev, freeJob: listOrder };
    });
  };

  const TotalExpense = () => {
    let tempTotalExpense = 0;
    tempTotalExpense = formTax?.freeJob
      ?.filter((item) => {
        return item?.calculating_method !== "หักแบบเหมา";
      })
      .reduce((total, num) => {
        return total + Number(num?.Tax_Real || 0);
      }, 0);
    return tempTotalExpense;
  };

  const onChangeExpenseDetail = (value, field) => {
    setFormTax((prev) => {
      return { ...prev, [field]: value };
    });
  };

  const Submit = () => {
    let sumExpense =
      numberWithoutComma(formTax?.free_job_expense_cost || 0) +
      numberWithoutComma(formTax?.free_job_expense_salary || 0) +
      numberWithoutComma(formTax?.free_job_expense_other || 0);
    let checkTaxPayerId = formTax?.freeJob
      ?.filter((item) => item?.tax_income !== "")
      ?.map((item) => String(item?.tax_payer_ID))
      .some((item) => item === "" || item.length !== 13);

    if (TotalExpense() !== sumExpense) {
      setPopuperror((prev) => {
        return { ...prev, expense: true };
      });
      return;
    }

    if (checkTaxPayerId) {
      setPopuperror((prev) => {
        return { ...prev, Tax_Payer_ID: true };
      });
      return;
    }

    setTaxData({ ...formTax });
    NavigateGoBack(navigate);
  };


  return (
    <div>
      <PopupMain2
        onClick={() => {
          setPopuperror((prev) => {
            return { ...prev, expense: false };
          });
        }}
        open={popupError?.expense}
        text={
          <div className=" flex flex-col items-start w-full px-[20px] text-left text-sm ">
            <p>
              ท่านบันทึก จำนวนเงินรวมในรายการค่าใช้จ่ายจริง ของผู้มีเงินได้
              ไม่เท่ากับค่าใช้จ่ายจริง โปรดตรวจสอบ
            </p>
          </div>
        }
      />
      <PopupMain2
        onClick={() => {
          setPopuperror((prev) => {
            return { ...prev, Tax_Payer_ID: false };
          });
        }}
        open={popupError?.Tax_Payer_ID}
        text={
          <div className=" flex flex-col items-start w-full px-[20px] text-left text-sm ">
            <p>
              ท่านยังไม่ได้บันทึก เลขประจำตัวผู้เสียภาษีอากร (13 หลัก)
              ของผู้จ่ายเงินได้ โปรดตรวจสอบ
              การบันทึกเลขประจำตัวผู้เสียภาษีอากรของผู้จ่ายเงินได้ ให้บันทึกเลขฯ
              ผู้จ่ายเงินได้ตามหนังสือรับรองการหักภาษี ณ ที่จ่าย
              หากท่านได้รับเงินได้จากผู้จ่ายเงินได้มากกว่า 1 ราย กรุณาบันทึกเลขฯ
              ผู้จ่ายเงินได้รายที่ท่านได้รับเงินได้มากที่สุด
            </p>
          </div>
        }
      />
      <Container>
        <div className={STYLE_MARGIN_TOP_PARENT}>
          <HeaderTitle
            rounded="rounded-r-[20px] pr-[8px]"
            title={
              <div>รายได้รายได้จากฟรีแลนซ์, รับจ้างทั่วไป, อาชีพอิสระ</div>
            }
            isGoback
            onClickGoBack={() => {
              NavigateGoBack(navigate);
            }}
          />
          <div className={STYLE_PARENT_PADDING}>
            <CardMain
              onClick={() => {
                setOpenFreeLanceInfo(true);
              }}
              icon={true}
              marginyellow="mt-3"
              title={
                <div className="">
                  เงินได้จากการรับจ้าง ฟรีแลนซ์ ค่าตำแหน่ง
                  <div>เบี้ยประชุม หรือค่านายหน้า (มาตรา 40(2))</div>
                </div>
              }
              panel={
                <div className=" px-3 -mt-3 space-y-[15px]">
                  <div>
                    <div className={`${STYLE_TEXT_BLUE} text-sm mb-1`}>
                      เงินได้ทั้งหมด
                    </div>
                    <NumericFormat
                      maxLength={11}
                      placeholder="0"
                      thousandSeparator=","
                      inputMode="decimal"
                      pattern="[0-9],*"
                      className={`${STYLE_INPUT}`}
                      value={formTax?.freelance?.tax_income}
                      onChange={(e) => {
                        onChangeFreelane(
                          numberWithoutComma(e.target.value),
                          "tax_income"
                        );
                      }}
                    />
                  </div>
                  <div>
                    <div className={`${STYLE_TEXT_BLUE} text-sm mb-1`}>
                      ภาษีหัก ณ ที่จ่าย 
                    </div>
                    <NumericFormat
                      maxLength={11}
                      placeholder="0"
                      thousandSeparator=","
                      inputMode="decimal"
                      pattern="[0-9],*"
                      className={`${STYLE_INPUT}`}
                      value={formTax?.freelance?.with_holding_tax}
                      onChange={(e) => {
                        onChangeFreelane(
                          numberWithoutComma(e.target.value),
                          "with_holding_tax"
                        );
                      }}
                    />
                  </div>
                  <div>
                    <div
                      className={`${STYLE_TEXT_BLUE} text-sm mb-1 flex gap-2 `}
                    >
                      เลขผู้จ่ายเงินได้{" "}
                      <ICON_INFO
                        onClick={() => {
                          setOpenInfo(true);
                        }}
                        className={` w-4 h-4 text-gray-900 mt-0.5`}
                      />
                    </div>
                    <NumericFormat
                      maxLength={13}
                      placeholder="0"
                      // thousandSeparator=","
                      inputMode="decimal"
                      pattern="[0-9],*"
                      className={`${STYLE_INPUT}`}
                      value={formTax?.freelance?.tax_payer_ID}
                      onChange={(e) => {
                        onChangeFreelane(
                          numberWithoutComma(e.target.value),
                          "tax_payer_ID"
                        );
                      }}
                    />
                  </div>
                  <div className=" text-maingray text-xs space-y-1">
                    <div> * กรณีมีเงินได้จากหลายที่</div>
                    <div>
                      1. ให้รวมเงินได้ / ภาษีหัก ณ ที่จ่าย เป็นรายการเดียว
                      ก่อนกรอก
                    </div>
                    <div>
                      2.
                      ให้ใส่เลขผู้จ่ายเงินได้จากบริษัทที่จ่ายเงินได้ให้มากที่สุด
                    </div>
                  </div>
                </div>
              }
            />
            <CardMain
              marginyellow=" mt-14 "
              title={
                <div className=" pt-12 pr-3 flex">
                  ค่าตอบแทนจากการประกอบวิชาชีพอิสระ วิชากฎหมาย การประกอบโรคศิลปะ
                  วิศวกรรม สถาปัตยกรรม การบัญชี ประณีตศิลปกรรม (มาตรา 40(6))
                  <ICON_INFO
                    className={` w-4 h-4 text-gray-900 flex-none`}
                    onClick={() => {
                      setOpen406Info(true);
                    }}
                  />
                </div>
              }
              panel={
                <div className=" px-3 -mt-3 space-y-[15px]">
                  <div className=" bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9 ">
                    <div className=" text-sm text-black font-medium">
                      เพิ่มรายการ
                    </div>
                    <ButtonCounter
                      value={Number(formTax?.freeJob?.length || 0)}
                      handleNext={() => {
                        // setRowSecond(rowSecond + 1);
                        AddOrder();
                      }}
                    />
                  </div>
                  {formTax?.freeJob?.map((data, index) => {
                    return (
                      <CardTaxPremium
                        options={option_income_business_tax_40_6}
                        index={index}
                        onChangeFreeJob={onChangeFreeJob}
                        DeleteOrder={DeleteOrder}
                        data={data}
                        allIn_percent={
                          data?.type_job?.value === " tax_income_medicine"
                            ? "60"
                            : data?.type_job?.value
                            ? "30"
                            : "-"
                        }
                      />
                    );
                  })}
                  {formTax?.freeJob?.map((item)=> item?.calculating_method).some((item)=> item === "หักตามจริง") && <div>
                    <div className=" text-center text-black font-medium text-sm">
                      แจกแจงค่าใช้จ่ายที่ขอหัก
                    </div>
                    <div className=" text-xs text-maingray my-1">
                      *กรณีเงินได้/การขอหัก มากกว่า 1 รายการ ตอนกรอก
                      ให้เติมทุกรายการ
                    </div>
                    <div className=" flex justify-between items-center">
                      <div className=" text-black text-sm font-semibold">
                        ยอดค่าใช้จ่ายที่ขอหัก
                      </div>
                      <div className=" text-maincyan text-xl font-medium">
                        {" "}
                        {numeral(TotalExpense())?.format("0,0")} บาท
                      </div>
                    </div>
                      <div className=" bg-white p-3 rounded-[20px] my-[15px]">
                        <div className=" text-center text-black font-medium text-sm">
                          แจกแจงค่าใช้จ่าย
                        </div>
                        <div className={STYLE_PARENT_CARD_BALANCE}>
                          <div
                            className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-3 `}
                          >
                            1. ต้นทุนการให้บริการ
                          </div>
                          <NumericFormat
                            maxLength={11}
                            placeholder="0"
                            value={formTax?.free_job_expense_cost}
                            onChange={(e) => {
                              onChangeExpenseDetail(
                                e.target.value,
                                "free_job_expense_cost"
                              );
                            }}
                            thousandSeparator=","
                            inputMode="decimal"
                            pattern="[0-9],*"
                            className={`${STYLE_INPUT_CARD_BALANCE} col-span-2 w-full`}
                          />
                        </div>
                        <div className={STYLE_PARENT_CARD_BALANCE}>
                          <div
                            className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-3 `}
                          >
                            2.เงินเดือนค่าจ้าง
                          </div>
                          <NumericFormat
                            maxLength={11}
                            placeholder="0"
                            value={formTax?.free_job_expense_salary}
                            onChange={(e) => {
                              onChangeExpenseDetail(
                                e.target.value,
                                "free_job_expense_salary"
                              );
                            }}
                            thousandSeparator=","
                            inputMode="decimal"
                            pattern="[0-9],*"
                            className={`${STYLE_INPUT_CARD_BALANCE} col-span-2 w-full`}
                          />
                        </div>
                        <div className={STYLE_PARENT_CARD_BALANCE}>
                          <div
                            className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-3 `}
                          >
                            3.ค่าใช้จ่ายอื่นๆ
                          </div>
                          <NumericFormat
                            maxLength={11}
                            placeholder="0"
                            value={formTax?.free_job_expense_other}
                            onChange={(e) => {
                              onChangeExpenseDetail(
                                e.target.value,
                                "free_job_expense_other"
                              );
                            }}
                            thousandSeparator=","
                            inputMode="decimal"
                            pattern="[0-9],*"
                            className={`${STYLE_INPUT_CARD_BALANCE} col-span-2 w-full`}
                          />
                        </div>
                      </div>
                      <div className=" flex justify-between items-center ">
                        <div className=" text-black text-sm font-semibold">
                          รวมค่าใช้จ่ายทั้งหมด
                        </div>
                        <div className=" text-maincyan text-xl font-medium">
                          {" "}
                          {numeral(
                            numberWithoutComma(
                              formTax?.free_job_expense_cost || 0
                            ) +
                              numberWithoutComma(
                                formTax?.free_job_expense_salary || 0
                              ) +
                              numberWithoutComma(
                                formTax?.free_job_expense_other || 0
                              )
                          )?.format("0,0")}{" "}
                          บาท
                        </div>
                      </div>
                  </div>}
               
                </div>
              }
            />
            <div className={STYLE_PARENT_BUTTON_TWO}>
              <MTButton
                text="ย้อนกลับ"
                color="bg-white"
                border="border border-maincyan"
                colortext="text-maincyan group-hover:text-white"
                hovercolor="hover:bg-maincyan group "
                width="w-full"
                onClick={() => {
                  NavigateGoBack(navigate);
                }}
              />
              <MTButton
                text={"บันทึก"}
                color="bg-maincyan"
                width="w-full"
                onClick={() => {
                  Submit();
                }}
              />
            </div>
          </div>
        </div>
      </Container>
      {openInfo && (
        <PayerIncomeInfo
          open402Info={openInfo}
          setOpen402Info={setOpenInfo}
          setOpenInfo={setOpenInfo}
        />
      )}
      {openFreeLanceInfo && (
        <FreelanceIncomeInfo
          openFreeLanceInfo={openFreeLanceInfo}
          setOpenFreeLanceInfo={setOpenFreeLanceInfo}
        />
      )}
      {open406Info && (
        <Income406Info
          open406Info={open406Info}
          setOpen406Info={setOpen406Info}
        />
      )}
    </div>
  );
};
export default ViewIncomeFreelance;
