import { useNavigate } from "react-router-dom";
import { HeaderTitle } from "../../../components/header/Header";
import Container from "../../../components/layout/Container";
import { useEffect } from "react";
import { first_reload } from "../../tax_plan/functions/firstLoad";
import { HeaderReport } from "../components/header.main";
import { STYLE_REPORT_CONTAIN } from "../../../utils/useStyle";
import { MTButton } from "../../../components/button/MTButton";

const ReportHeritagePage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    let ignore = false;
    first_reload(ignore);
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <Container>
      <div id="first_reload" className="pt-[14px]">
        <HeaderTitle
          title="รายงาน"
          isGoback
          onClickGoBack={() => {
            navigate("/report");
          }}
        />

        <div className={`${STYLE_REPORT_CONTAIN} flex flex-col gap-5`}>
          <HeaderReport
            icon={"/images/stakeholders-report.png"}
            title={"แผนมรดก"}
            className={" z-20"}
          />
          <div className="bg-blueopacity rounded-[20px] p-[15px]">
            <div className=" bg-white p-2.5 rounded-[10px] text-xs text-center leading-relaxed">
              เริ่มต้นด้วยการทำทะเบียนสินทรัพย์ สินส่วนตัว สินสมรส หนี้สิน
              ทะเบียนญาติ ทำการระบุผู้จัดการมรดก
              ผู้ปกครองโดยชอบธรรมให้บุตรที่ยังเยาว์วัยทำการเขียนพินัยแบบมือตนเองทั้งฉบับ
              หรือแบบพิมพ์พร้อมพยานลงชื่อพร้อมกัน 2 คน​
              พยานห้ามเป็นผู้รับผลประโยชน์จากพินัยกรรม
              และทำมอบให้บุคคลที่ไว้วางใจ และปรับปรุงข้อมูลอย่างสม่ำเสมอ​
              และสำหรับการพินัยกรรมแบบฝ่ายเมืองต้องนัดหมายนายอำเภอ /
              ผู้อำนวยการเขตในการดำเนินการต่อไป​
            </div>
          </div>
          <div className=" flex justify-center gap-2.5 ">
            <MTButton
              onClick={() => {
                navigate("/legacyplan");
              }}
              flexnone="flex-none"
              width="w-full text-xs"
              text="ดูตัวอย่างการเขียนพินัยกรรม"
            />
            <MTButton
              onClick={() => {
                navigate("/report");
              }}
              width="w-full"
              text="ย้อนกลับ"
              color="bg-white"
              border="border border-maincyan"
              colortext="text-maincyan group-hover:text-white"
              hovercolor="hover:bg-maincyan group "
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ReportHeritagePage;
