import { useNavigate } from "react-router-dom";
import { HeaderTitle } from "../../../../components/header/Header";
import Container from "../../../../components/layout/Container";
import NavigateGoBack from "../../../../functions/Navigate.Goback";
import {
  STYLE_MARGIN_TOP_PARENT,
  STYLE_PARENT_BUTTON_TWO,
  STYLE_PARENT_PADDING,
} from "../../../../utils/useStyle";
import { CardMain } from "../../../../components/card/CardMain";
import { ICON_INFO } from "../../../../utils/useIcons";
import {
  ButtonCounter,
  MTButton,
} from "../../../../components/button/MTButton";
import { CardTaxPremium } from "../../styles/Card/CardMenuPremium";
import {
  option_income_business_tax_40_3,
  option_income_business_tax_40_5,
  option_income_business_tax_40_7,
  option_income_business_tax_40_8,
  option_income_business_tax_40_8_asset,
} from "../../functions/options";
import { useState } from "react";
import { PayerIncomeInfo } from "./VIIncome_Freelance";
import { InfoDrawer } from "../../../../components/drawer/InfoDrawer";
import DetailCost from "./DetailCost/DetailCost";
import { useTaxStore } from "../../../../_store/taxplanStore";
import {
  AddOrder,
  DeleteOrder,
  Tax408PropertyPercent,
  TotalExpense,
  onChangeExpensTaxDetail,
  onChangeTax403,
  onChangeTax405,
  onChangeTax407,
  onChangeTax408,
  onChangeTax408_property,
  tax405Percent,
} from "../../../../functions/taxFucntion/premium/businessFunction";
import _ from "lodash";
import { numberWithoutComma } from "../../../balance/component/VIDrawerDebt.Main";

const Income403Info = ({ open403Info, setOpen403Info, text="", head="เงินได้ตามมาตรา 40(3)" }) => {
  return (
    <InfoDrawer
      head={head}
      text={text}
      open={open403Info}
      onClose={() => {
        setOpen403Info(false);
      }}
    />
  );
};

const Income405Info = ({ open405Info, setOpen405Info }) => {
  return (
    <InfoDrawer
      head={"เงินได้ตามมาตรา 40(5)"}
      text={
        <div className=" mb-4">
          เงินหรือประโยชน์ที่ได้รับเนื่องจากการให้เช่าทรัพย์สิน เช่น ค่าเช่าบ้าน
          ค่าเช่ารถยนต์ การผิดสัญญาเช่าซื้อ หรือการผิดสัญญาซื้อขายเงินผ่อน
          เป็นต้น
        </div>
      }
      open={open405Info}
      onClose={() => {
        setOpen405Info(false);
      }}
    />
  );
};

const Income407Info = ({ open407Info, setOpen407Info }) => {
  return (
    <InfoDrawer
      head={"เงินได้ตามมาตรา 40(7)"}
      text={
        <div className=" mb-4">
          เงินหรือประโยชน์ที่ได้รับเนื่องจากการให้เช่าทรัพย์สิน เช่น ค่าเช่าบ้าน
          ค่าเช่ารถยนต์ การผิดสัญญาเช่าซื้อ หรือการผิดสัญญาซื้อขายเงินผ่อน
          เป็นต้น
        </div>
      }
      open={open407Info}
      onClose={() => {
        setOpen407Info(false);
      }}
    />
  );
};

const Income408Info = ({ open408Info, setOpen408Info }) => {
  return (
    <InfoDrawer
      head={"เงินได้ตามมาตรา 40(8)"}
      text={
        <div className=" mb-4">
          เงินได้จากธุรกิจ การพาณิชย์ ฯลฯ เงินส่วนแบ่งกำไรจากกองทุนรวม
          (ก่อนวันที่ 20 สิงหาคม 2562)
          เงินได้จากการขายอสังหาริมทรัพย์อันเป็นมรดก
          หรือได้มาโดยมิได้มุ่งในทางการค้า เงินหรือผลประโยชน์ใดๆ
          ที่ได้รับเนื่องจากการขายหน่วยลงทุนคืนให้แก่กองทุนรวมเพื่อการเลี้ยงชีพตามกฎหมายว่าด้วยหลักทรัพย์และตลาดหลักทรัพย์
          ทั้งที่ได้รับยกเว้นภาษีเงินได้และที่ไม่ได้รับยกเว้นภาษีเงินได้
        </div>
      }
      open={open408Info}
      onClose={() => {
        setOpen408Info(false);
      }}
    />
  );
};

const Income408AssetInfo = ({ open408AssetInfo, setOpen408AssetInfo }) => {
  return (
    <InfoDrawer
      head={
        <div className=" text-xl">
          เงินได้จากการขายอสังหาริมทรัพย์
          ที่ได้มาโดยมิได้มุ่งในทางการค้าหรือหากำไรและเลือกเสียภาษีโดยไม่นำไปรวมคำนวณภาษีกับเงินได้อื่นๆ
        </div>
      }
      text={
        <div className="">
          เงินได้จากการขายอสังหาริมทรัพย์อันเป็นมรดกอสังหาริมทรัพย์ที่ได้รับจากการให้โดยเสน่หา
          หรืออสังหาริมทรัพย์ที่ได้มาโดยมิได้มุ่งในทางการค้าหรือหากำไร
          และเลือกเสียภาษีโดยไม่นำไปรวมคำนวณภาษีกับเงินได้อื่นๆ
          หากมีความประสงค์จะขอหักค่าใช้จ่ายตามความจำเป็น และสมควร
          ให้คำนวณภาษีที่ต้องชำระ
          โดยนำเงินได้จากการขายอสังหาริมทรัพย์หักค่าใช้จ่ายตามความจำเป็นและสมควร
          เหลือเป็นเงินได้สุทธิให้หารด้วยจำนวนปีที่ถือครอง
          ได้ผลลัพธ์เท่าใดให้คำนวณภาษีตามอัตราภาษีเงินได้บุคคลธรรมดา
          (ไม่ได้รับยกเว้น 150,000 บาท)
          ได้เท่าใดให้คูณด้วยจำนวนปีที่ถือครองผลลัพธ์เป็นภาษีที่ต้องชำระจำนวนภาษีดังกล่าวข้างต้นต้องไม่เกินร้อยละ
          20 ของราคาขาย
        </div>
      }
      open={open408AssetInfo}
      onClose={() => {
        setOpen408AssetInfo(false);
      }}
    />
  );
};

const ViewIncomeBusiness = () => {
  const navigate = useNavigate();
  const taxData = useTaxStore((state) => state.taxData);
  const setTaxData = useTaxStore((state) => state.setTaxData);

  const [openInfo, setOpenInfo] = useState(false);
  const [open403Info, setOpen403Info] = useState(false);
  const [open405Info, setOpen405Info] = useState(false);
  const [open407Info, setOpen407Info] = useState(false);
  const [open408Info, setOpen408Info] = useState(false);
  const [open408AssetInfo, setOpen408AssetInfo] = useState(false);
  const [openAllInData,setAllInData] = useState(false)


  const [formTax, setFormTax] = useState({
    ...taxData,
    tax_403: taxData?.tax_403
      ? taxData?.tax_403
      : [
          {
            type_job: "",
            tax_income: "",
            with_holding_tax: "",
            tax_payer_ID: "",
            calculating_method: "",
          },
        ],
    tax_405: taxData?.tax_405
      ? taxData?.tax_405
      : [
          {
            type_job: "",
            tax_income: "",
            with_holding_tax: "",
            tax_payer_ID: "",
            calculating_method: "",
          },
        ],
    tax_407: taxData?.tax_407
      ? taxData?.tax_407
      : [
          {
            type_job: "",
            tax_income: "",
            with_holding_tax: "",
            tax_payer_ID: "",
            calculating_method: "",
          },
        ],
    tax_408: taxData?.tax_408
      ? taxData?.tax_408
      : [
          {
            type_job: "",
            tax_income: "",
            with_holding_tax: "",
            tax_payer_ID: "",
            calculating_method: "",
          },
        ],
    tax_408_property: taxData?.tax_408_property
      ? taxData?.tax_408_property
      : [
          {
            tax_holding_year: "",
            type_job: "",
            tax_income: "",
            with_holding_tax: "",
            tax_payer_ID: "",
            calculating_method: "",
          },
        ],
  });

  // console.log("tax_408", formTax?.tax_408);

  return (
    <div>
      <Container>
        <div className={STYLE_MARGIN_TOP_PARENT}>
          <HeaderTitle
            title=" รายได้จากทรัพย์สิน, การทำธุรกิจ "
            isGoback
            onClickGoBack={() => {
              NavigateGoBack(navigate);
              setTaxData({ ...formTax });
            }}
          />
          <div className={STYLE_PARENT_PADDING}>
            {/* ------ 40(3) ------ */}
            <CardMain
              marginyellow=" mt-14 "
              title={
                <div className=" pt-12 pr-3 flex">
                  ค่าลิขสิทธิ์ ค่าสิทธิ์ในทรัพย์สินทางปัญญา (Royalty) และ
                  ค่ากู๊ดวิลล์ (Goodwill) หรือ เงินได้รายปีที่ได้ มาจากนิติกรรม
                  และคำพิพากษาของศาล (มาตรา 40(3))
                  <ICON_INFO
                    className={` w-4 h-4 text-gray-900 flex-none`}
                    onClick={() => {
                      setOpen403Info(true);
                    }}
                  />
                </div>
              }
              panel={
                <>
                  <div className=" px-3 -mt-3 space-y-[15px]">
                    <div className=" bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9 ">
                      <div className=" text-sm text-black font-medium">
                        เพิ่มรายการ
                      </div>
                      <ButtonCounter
                        handleNext={() => {
                          AddOrder("tax_403", formTax, setFormTax);
                        }}
                        value={formTax?.tax_403?.length}
                      />
                    </div>
                    {formTax?.tax_403?.map((data, index) => {
                      return (
                        <CardTaxPremium
                          index={index}
                          data={data}
                          ishasCal={
                            data?.type_job?.value !== " tax_income_annual"
                          }
                          isHasAllInInfo={true}
                          options={option_income_business_tax_40_3}
                          expressTitle="วิธีการหักค่าใช้จ่าย"
                          allIn_percent={"50"}
                          onChangeFreeJob={(value, field, index) => {
                            onChangeTax403(
                              value,
                              field,
                              index,
                              formTax,
                              setFormTax
                            );
                          }}
                          openAllInData={()=>{
                            setAllInData(true)
                          }}
                          openInfo={() => {
                            setOpenInfo(true);
                          }}
                          DeleteOrder={(a) => {
                            DeleteOrder(a, formTax, setFormTax, "tax_403");
                          }}
                        />
                      );
                    })}
                  </div>
                  {formTax?.tax_403
                    ?.map((item) => item?.calculating_method)
                    .some((item) => item === "หักตามจริง") && (
                    <div className="px-3 mt-[15px]">
                      <DetailCost
                        data={formTax?.tax_403}
                        TotalExpense={formTax?.tax_403
                          ?.filter((item) => {
                            return item?.calculating_method === "หักตามจริง";
                          })
                          ?.reduce((total, num) => {
                            return total + Number(num?.Tax_Real || 0);
                          }, 0)}
                        onChangeExpenstaxeDetail={(value, field) => {
                          onChangeExpensTaxDetail(
                            numberWithoutComma(value),
                            field,
                            setFormTax
                          );
                        }}
                        cost={{
                          cost: formTax?.tax403_expense_cost,
                          salary: formTax?.tax403_expense_salary,
                          others: formTax?.tax403_expense_other,
                        }}
                        field={{
                          service: "tax403_expense_cost",
                          salary: "tax403_expense_salary",
                          others: "tax403_expense_other",
                        }}
                        sum={
                          _.get(formTax, "tax403_expense_cost", 0) +
                          _.get(formTax, "tax403_expense_salary", 0) +
                          _.get(formTax, "tax403_expense_other", 0)
                        }
                      />
                    </div>
                  )}
                </>
              }
            />
            {/* ------ 40(5) ------ */}
            <CardMain
              marginyellow=" mt-4"
              title={
                <div className=" pt-4 pr-3 flex">
                  ค่าเช่า ค่าผิดสัญญาเช่าซื้อหรือซื้อขายเงินผ่อน (มาตรา 40(5))
                  <ICON_INFO
                    className={` w-4 h-4 text-gray-900 flex-none`}
                    onClick={() => {
                      setOpen405Info(true);
                    }}
                  />
                </div>
              }
              panel={
                <>
                  <div className=" px-3 -mt-3 space-y-[15px]">
                    <div className=" bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9 ">
                      <div className=" text-sm text-black font-medium">
                        เพิ่มรายการ
                      </div>
                      <ButtonCounter
                        handleNext={() => {
                          AddOrder("tax_405", formTax, setFormTax);
                        }}
                        value={formTax?.tax_405?.length}
                      />
                    </div>
                    {formTax?.tax_405?.map((data, index) => {
                      return (
                        <CardTaxPremium
                          expressTitle="วิธีการหักค่าใช้จ่าย"
                          options={option_income_business_tax_40_5}
                          openInfo={() => {
                            setOpenInfo(true);
                          }}
                          index={index}
                          data={data}
                          allIn_percent={String(
                            tax405Percent.find(
                              (item) => item?.label === data?.type_job?.label
                            )?.value || "-"
                          )}
                          ishasCal={
                            data?.type_job?.label !==
                            "การผิดสัญญาเช่าซื้อทรัพย์สิน/ซื้อขายเงินผ่อน"
                          }
                          onChangeFreeJob={(value, field, index) => {
                            onChangeTax405(
                              value,
                              field,
                              index,
                              formTax,
                              setFormTax
                            );
                          }}
                          DeleteOrder={(a) => {
                            DeleteOrder(a, formTax, setFormTax, "tax_405");
                          }}
                        />
                      );
                    })}
                    {formTax?.tax_405
                      ?.map((item) => item?.calculating_method)
                      .some((item) => item === "หักตามจริง") && (
                      <div className="px-3 mt-[15px]">
                        <DetailCost
                          data={formTax?.tax_405}
                          TotalExpense={formTax?.tax_405
                            ?.filter((item) => {
                              return item?.calculating_method === "หักตามจริง";
                            })
                            ?.reduce((total, num) => {
                              return total + Number(num?.Tax_Real || 0);
                            }, 0)}
                          onChangeExpenstaxeDetail={(value, field) => {
                            onChangeExpensTaxDetail(
                              numberWithoutComma(value),
                              field,
                              setFormTax
                            );
                          }}
                          cost={{
                            cost: formTax?.tax405_expense_cost,
                            salary: formTax?.tax405_expense_salary,
                            others: formTax?.tax405_expense_other,
                          }}
                          field={{
                            service: "tax405_expense_cost",
                            salary: "tax405_expense_salary",
                            others: "tax405_expense_other",
                          }}
                          sum={
                            _.get(formTax, "tax405_expense_cost", 0) +
                            _.get(formTax, "tax405_expense_salary", 0) +
                            _.get(formTax, "tax405_expense_other", 0)
                          }
                        />
                      </div>
                    )}
                  </div>
                </>
              }
            />

            {/* ------ 40(7) ------ */}
            <CardMain
              marginyellow=" mt-14"
              title={
                <div className=" pt-12 pr-3 flex">
                  เงินได้จากการรับเหมาที่ผู้รับเหมาทั้งค่าแรง และค่าของ
                  ที่ต้องลงทุนด้วยการจัดหาสัมภาระ ในส่วนสำคัญ นอกจากเครื่องมือ
                  (มาตรา 40(7))
                  <ICON_INFO
                    className={` w-4 h-4 text-gray-900 flex-none`}
                    onClick={() => {
                      setOpen407Info(true);
                    }}
                  />
                </div>
              }
              panel={
                <div className=" px-3 -mt-3 space-y-[15px]">
                  <div className=" bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9 ">
                    <div className=" text-sm text-black font-medium">
                      เพิ่มรายการ
                    </div>
                    <ButtonCounter
                      handleNext={() => {
                        AddOrder("tax_407", formTax, setFormTax);
                      }}
                      value={formTax?.tax_407?.length}
                    />
                  </div>
                  {formTax?.tax_407?.map((data, index) => {
                    return (
                      <CardTaxPremium
                        index={index}
                        options={option_income_business_tax_40_7}
                        expressTitle="วิธีการหักค่าใช้จ่าย"
                        openInfo={() => {
                          setOpenInfo(true);
                        }}
                        data={data}
                        // ishasCal={
                        //   data?.type_job?.value !== " tax_income_annual"
                        // }
                        allIn_percent={60}
                        onChangeFreeJob={(value, field, index) => {
                          onChangeTax407(
                            value,
                            field,
                            index,
                            formTax,
                            setFormTax
                          );
                        }}
                        DeleteOrder={(a) => {
                          DeleteOrder(a, formTax, setFormTax, "tax_407");
                        }}
                      />
                    );
                  })}
                  {formTax?.tax_407
                    ?.map((item) => item?.calculating_method)
                    .some((item) => item === "หักตามจริง") && (
                    <div className="px-3 mt-[15px]">
                      <DetailCost
                        data={formTax?.tax_407}
                        TotalExpense={formTax?.tax_407
                          ?.filter((item) => {
                            return item?.calculating_method === "หักตามจริง";
                          })
                          ?.reduce((total, num) => {
                            return total + Number(num?.Tax_Real || 0);
                          }, 0)}
                        onChangeExpenstaxeDetail={(value, field) => {
                          onChangeExpensTaxDetail(
                            numberWithoutComma(value),
                            field,
                            setFormTax
                          );
                        }}
                        cost={{
                          cost: formTax?.tax407_expense_cost,
                          salary: formTax?.tax407_expense_salary,
                          others: formTax?.tax407_expense_other,
                        }}
                        field={{
                          service: "tax407_expense_cost",
                          salary: "tax407_expense_salary",
                          others: "tax407_expense_other",
                        }}
                        sum={
                          _.get(formTax, "tax407_expense_cost", 0) +
                          _.get(formTax, "tax407_expense_salary", 0) +
                          _.get(formTax, "tax407_expense_other", 0)
                        }
                      />
                    </div>
                  )}
                </div>
              }
            />

            {/* ------ 40(8) ------ */}
            <CardMain
              marginyellow=" mt-10"
              title={
                <div className=" pt-8 pr-3 flex">
                  เงินได้จากธุรกิจ การพาณิชย์ การเกษตร การอุตสาหกรรม การขนส่ง
                  และเงินได้อื่นๆ (มาตรา 40(8))
                  <ICON_INFO
                    className={` w-4 h-4 text-gray-900 flex-none`}
                    onClick={() => {
                      setOpen408Info(true);
                    }}
                  />
                </div>
              }
              panel={
                <div className=" px-3 -mt-3 space-y-[15px]">
                  <div className=" bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9 ">
                    <div className=" text-sm text-black font-medium">
                      เพิ่มรายการ
                    </div>
                    <ButtonCounter
                      handleNext={() => {
                        AddOrder("tax_408", formTax, setFormTax);
                      }}
                      value={formTax?.tax_408?.length}
                    />
                  </div>
                  {formTax?.tax_408?.map((data, index) => {
                    return (
                      <CardTaxPremium
                        index={index}
                        options={option_income_business_tax_40_8}
                        optionsGroup={true}
                        expressTitle="วิธีการหักค่าใช้จ่าย"
                        openInfo={() => {
                          setOpenInfo(true);
                        }}
                        data={data}
                        ishasCal={
                          data?.type_job?.label !==
                          "เงินส่วนแบ่งของกำไรจากกองทุนรวมตามประกาศคณะปฏิวัติฯ"
                        }
                        allIn_percent={data?.type_job === "" ? 0:60}
                        onChangeFreeJob={(value, field, index) => {
                          onChangeTax408(
                            value,
                            field,
                            index,
                            formTax,
                            setFormTax
                          );
                        }}
                        isActive={data?.type_job?.label !== "เงินได้อื่นๆ"}
                        DisableAllIn={data?.type_job?.label === "เงินได้อื่นๆ"}
                        DeleteOrder={(a) => {
                          DeleteOrder(a, formTax, setFormTax, "tax_408");
                        }}
                      />
                    );
                  })}
                  {formTax?.tax_408
                    ?.map((item) => item?.calculating_method)
                    .some((item) => item === "หักตามจริง") && (
                    <div className="px-3 mt-[15px]">
                      <DetailCost
                        data={formTax?.tax_408}
                        TotalExpense={formTax?.tax_408
                          ?.filter((item) => {
                            return item?.calculating_method === "หักตามจริง";
                          })
                          ?.reduce((total, num) => {
                            return total + Number(num?.Tax_Real || 0);
                          }, 0)}
                        onChangeExpenstaxeDetail={(value, field) => {
                          onChangeExpensTaxDetail(
                            numberWithoutComma(value),
                            field,
                            setFormTax
                          );
                        }}
                        cost={{
                          cost: formTax?.tax408_expense_cost,
                          salary: formTax?.tax408_expense_salary,
                          others: formTax?.tax408_expense_other,
                        }}
                        field={{
                          service: "tax408_expense_cost",
                          salary: "tax408_expense_salary",
                          others: "tax408_expense_other",
                        }}
                        sum={
                          _.get(formTax, "tax408_expense_cost", 0) +
                          _.get(formTax, "tax408_expense_salary", 0) +
                          _.get(formTax, "tax408_expense_other", 0)
                        }
                      />
                    </div>
                  )}
                </div>
              }
            />
            {/* ------ 40(8) อสังหา------ */}
            <CardMain
              marginyellow=" mt-10"
              title={
                <div className=" pt-8 pr-3 flex">
                  เงินได้จากการขายอสังหาริมทรัพย์ฯ (มาตรา 40(8))
                  <ICON_INFO
                    className={` w-4 h-4 text-gray-900 flex-none`}
                    onClick={() => {
                      setOpen408AssetInfo(true);
                    }}
                  />
                </div>
              }
              panel={
                <div className=" px-3 -mt-3 space-y-[15px]">
                  <div className=" bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9 ">
                    <div className=" text-sm text-black font-medium">
                      เพิ่มรายการ
                    </div>
                    <ButtonCounter
                      handleNext={() => {
                        AddOrder("tax_408_property", formTax, setFormTax);
                      }}
                      value={formTax?.tax_408_property?.length}
                    />
                  </div>
                  {formTax?.tax_408_property?.map((data, index) => {
                    // console.log("data?.type_job?.label",data?.type_job?.label)
                    return (
                      <CardTaxPremium
                        index={index}
                        expressTitle="วิธีการหักค่าใช้จ่าย"
                        claculateMethodTitle="การคำนวณภาษี"
                        options={option_income_business_tax_40_8_asset}
                        isHasHoldingYear={
                          data?.type_job?.label ===
                          "การขายอสังหาริมทรัพย์ที่ได้มาโดยมิได้มุ่งในทางการค้าหรือหากำไร"
                        }
                        isSeparateCal={
                          data?.type_job?.label ===
                          "การขายอสังหาริมทรัพย์ที่ได้มาโดยมิได้มุ่งในทางการค้าหรือหากำไร"
                        }
                        openInfo={() => {
                          setOpenInfo(true);
                        }}
                        data={data}
                        ishasCal={
                          data?.type_job?.label !==
                          "เงินส่วนแบ่งของกำไรจากกองทุนรวมตามประกาศคณะปฏิวัติฯ"
                        }
                        DisableAllIn={data?.calculating_way === "แยกคำนวณ"}
                        DisableReal = { data?.type_job?.label !==
                          "การขายอสังหาริมทรัพย์ที่ได้มาโดยมิได้มุ่งในทางการค้าหรือหากำไร" }
                        allIn_percent={
                          data?.type_job?.label ===
                          "การขายอสังหาริมทรัพย์ที่ได้มาโดยมิได้มุ่งในทางการค้าหรือหากำไร"
                            ? Tax408PropertyPercent(data?.tax_holding_year || 0)
                            : 50
                        }
                        onChangeFreeJob={(value, field, index) => {
                          onChangeTax408_property(
                            value,
                            field,
                            index,
                            formTax,
                            setFormTax
                          );
                        }}
                        DeleteOrder={(a) => {
                          DeleteOrder(
                            a,
                            formTax,
                            setFormTax,
                            "tax_408_property"
                          );
                        }}
                      />
                    );
                  })}
                </div>
              }
            />
          </div>
          <div className={STYLE_PARENT_BUTTON_TWO}>
            <MTButton
              text="ย้อนกลับ"
              color="bg-white"
              border="border border-maincyan"
              colortext="text-maincyan group-hover:text-white"
              hovercolor="hover:bg-maincyan group "
              width="w-full"
              onClick={() => {
                NavigateGoBack(navigate);
              }}
            />
            <MTButton
              text={"บันทึก"}
              color="bg-maincyan"
              width="w-full"
              onClick={() => {
                setTaxData({ ...formTax });
                NavigateGoBack(navigate);
              }}
            />
          </div>
        </div>
      </Container>
      {openInfo && (
        <PayerIncomeInfo openInfo={openInfo} setOpenInfo={setOpenInfo} />
      )}
      {/* openAllInData */}
      {openAllInData && (
        <Income403Info
          open403Info={openAllInData}
          setOpen403Info={setAllInData}
          head={"การหักค่าใช้จ่าย"}
          text={ <div className=" mb-4">
          หักแบบเหมา 50% แต่ไม่เกิน ฿100,000 หรือหักตามจริง
        </div>}
        />
      )}
      {open403Info && (
        <Income403Info
          open403Info={open403Info}
          setOpen403Info={setOpen403Info}
          text={ <div className=" mb-4">
          ค่าแห่งกู๊ดวิลล์ ค่าแห่งลิขสิทธิ หรือสิทธิอย่างอื่นในทำนอง เดียวกัน
          หรือเงินได้ที่มีลักษณะเป็นเงินได้รายปี
          เช่นค่าเขียน/ค่าพัฒนาโปรแกรมคอมพิวเตอร์
        </div>}
        />
      )}
      {open405Info && (
        <Income405Info
          open405Info={open405Info}
          setOpen405Info={setOpen405Info}
        />
      )}
      {open407Info && (
        <Income407Info
          open407Info={open407Info}
          setOpen407Info={setOpen407Info}
        />
      )}
      {open408Info && (
        <Income408Info
          open408Info={open408Info}
          setOpen408Info={setOpen408Info}
        />
      )}
      {open408AssetInfo && (
        <Income408AssetInfo
          open408AssetInfo={open408AssetInfo}
          setOpen408AssetInfo={setOpen408AssetInfo}
        />
      )}
    </div>
  );
};

export default ViewIncomeBusiness;
