import React, { useCallback, useEffect, useMemo, useState } from "react";
import { HeaderTitle } from "../../components/header/Header";
import Container from "../../components/layout/Container";
import { mock_data_head_table, mock_data_table } from "../../utils/useMockData";
import {
  STYLE_MARGIN_TOP_PARENT,
  STYLE_WHITE_TABLE_RETIRE,
} from "../../utils/useStyle";
import { styleTable, styleTableBody } from "./styles/TableStyle";
import { useRetireStore } from "../../_store/retireStore";
import numeral from "numeral";
import { MTButton } from "../../components/button/MTButton";
import { ICON_DL_PDF } from "../../utils/useIcons";
import NavigateGoBack from "../../functions/Navigate.Goback";
import { useLocation, useNavigate } from "react-router-dom";
import { avgWeight } from "./functions/functionMain";
import {
  dataAge,
  dataAgeAF,
  getYear,
  getYearAF,
  loopAge,
  loopIncome,
  loopIncomeAF,
  loopInvestmentAssets,
  loopInvestmentAssetsAF,
  loopOutcome,
  loopOutcomeAF,
  loopRemain,
  loopRemainAF,
  loopReturnInvestment,
  loopReturnInvestmentAF,
  loopYear,
} from "./functions/functionRetriementPlan";
import {
  dataAgeAFSensitive,
  loopIncomeAFSensitive,
  loopInvestmentAssetsAFSensitive,
  loopOutcomeAFSensitive,
  loopRemainAFSensitive,
  loopReturnInvestmentAFSensitive,
} from "./functions/functionRetrementSensitivePlan";

const SummaryTableFinance = ({}) => {
  const location = useLocation();
  const isBeforeMode = location?.search === "?before";
  const isAfterMode = location?.search === "?after";
  const isSensitive = location?.search === "?sensitive";

  const retireData = useRetireStore((state) => state.retireData);
  const setRetireData = useRetireStore((state) => state.setRetireData);
  const clearRetireData = useRetireStore((state) => state.clearRetireData);
  const navigate = useNavigate();

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      setRetireData({
        ...retireData,

        bf_plan_age: loopAge(),
        bf_plan_income: loopIncome(),
        bf_plan_outcome: loopOutcome(),
        bf_plan_remain: loopRemain(),
        bf_return_investment: loopReturnInvestment(),
        bf_investment_assets: loopInvestmentAssets(),

        af_plan_age: dataAgeAF(),
        af_plan_income: loopIncomeAF(),
        af_plan_outcome: loopOutcomeAF(),
        af_plan_remain: loopRemainAF(),
        af_return_investment: loopReturnInvestmentAF(),
        af_investment_assets: loopInvestmentAssetsAF(),
      });
    }

    return () => {
      ignore = true;
    };
  }, [
    setRetireData,
    retireData?.strategy_out_come,
    retireData?.strategy_income,
    retireData?.strategy_passive_income_retire,
    retireData?.strategy_assets_generate,
    retireData?.strategy_assets_generate_per_year,
    retireData?.strategy_salary,
    retireData?.strategy_salary_peryear,
    retireData?.strategy_control_rate_outcome,
    retireData?.strategy_age_retire,
    retireData?.strategy_age_dead,
    retireData?.strategy_investment_bf_retirement,
    retireData?.strategy_investment_af_retirement,
  ]);

  const dataPlan = useMemo(() => {
    if (isBeforeMode) {
      return retireData?.bf_investment_assets?.map((item) => {
        return item?.results;
      });
    }
    if (isAfterMode) {
      return retireData?.af_investment_assets?.map((item) => {
        return item?.results;
      });
    }
    if (isSensitive) {
      return loopInvestmentAssetsAFSensitive()?.map((item) => {
        return item?.results;
      });
    }
  }, [
    retireData?.bf_investment_assets,
    retireData?.af_investment_assets,
    loopInvestmentAssetsAFSensitive(),
  ]);

  const checkColor = useCallback(
    (i) => {
      const green = [];
      const yellow = [];
      const red = [];

      for (let index = 0; index < dataPlan?.length; index++) {
        const currentValue = dataPlan[index];
        const nextValue = dataPlan[index + 1];

        //จุดแรก
        if (index === 0) {
          if (dataPlan[index] <= 0) {
            red.push(dataPlan[index]);
            yellow.push(null);
            green.push(null);
          } else {
            if (dataPlan[0] > dataPlan[1]) {
              yellow.push(dataPlan[index]);
              red.push(null);
              green.push(null);
            } else {
              green.push(dataPlan[index]);
              yellow.push(null);
              red.push(null);
            }
          }
          continue;
        }

        // red
        if (dataPlan[index] <= 0) {
          if (nextValue > currentValue && currentValue < 0 && nextValue > 0) {
            red.push(dataPlan[index]);
            green.push(dataPlan[index]);
            yellow.push(null);
            continue;
          }
          red.push(dataPlan[index]);
          yellow.push(null);
          green.push(null);
          continue;
        }

        if (dataPlan[index] < dataPlan[index - 1]) {
          // yellow => green
          if (nextValue > currentValue) {
            green.push(dataPlan[index]);
            yellow.push(dataPlan[index]);
            // yellow.push(null);
            red.push(null);
            continue;
          }

          //yellow => red
          if (nextValue < currentValue && nextValue < 0) {
            red.push(dataPlan[index]);
            green.push(null);
            yellow.push(dataPlan[index]);
            // yellow.push(null);
            continue;
          }

          yellow.push(dataPlan[index]);
          green.push(null);
          red.push(null);
        } else {
          //green => yellow

          if (nextValue < currentValue && nextValue > 0) {
            red.push(null);
            green.push(dataPlan[index]);
            yellow.push(dataPlan[index]);
            // yellow.push(null);
            continue;
          }
          //green
          green.push(dataPlan[index]);
          yellow.push(null);
          red.push(null);
        }
      }

      if (green[i] !== null) {
        return "bg-greenopacity border-maingreen/20 border ";
      }

      if (yellow[i] !== null) {
        return "bg-yellowopacity border-mainyellow/20 border ";
      }
      return "bg-redopacity border-mainred/20 border";
    },
    [dataPlan]
  );

  return (
    <React.Fragment>
      {isBeforeMode ? (
        <Container>
          {/*-------------------- ตารางก่อนวางแผน --------------------*/}
          <div className={`${STYLE_MARGIN_TOP_PARENT} min-h-screen mb-20 `}>
            <HeaderTitle
              title="ตารางสรุปความเคลื่อนไหวการเงิน"
              color="bg-[#EAEAEA]"
              isGoback
              onClickGoBack={() => {
                NavigateGoBack(navigate);
              }}
            />
            {/* //Head */}
            <div className=" mt-8 px-6">
              <div className="flex w-full ">
                <div className=" shadow-md rounded-tl-[20px] rounded-bl-[20px] shadow-gray-500 w-fit sticky z-10 ">
                  <div className="  bg-gray50 h-14 px-3 font-semibold text-mainblue rounded-t-[20px]  rounded-tr-none w-12 flex gap-[38px] items-center ">
                    <div>อายุ</div>
                  </div>
                  <div
                    className="  "
                  >
                    <div
                      className={`  flex  items-center text-lg font-medium text-mainblue w-12 h-full `}
                    >
                      <div className=" h-14 w-full">
                        {dataAge()?.map((row, index) => {
                          return (
                            <div
                              key={index}
                              className={` h-full flex justify-center items-center text-mainblue w-full ${checkColor(
                                index
                              )}`}
                            >
                              {row}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" w-full max-w-2xl overflow-hidden overflow-x-scroll  h-full rounded-tr-[20px] rounded-br-[20px] bg-gray50">
                  <div className=" w-[100vh] ">
                    <div className=" h-14 font-semibold text-mainblue grid grid-cols-5 items-center w-full bg-gray50 pt-1 ">
                      <div className=" text-sm pl-1 text-center md:break-words md:whitespace-nowrap break-word bg-gray50 h-full flex justify-center items-center  md:pr-4 ">
                        สินทรัพย์เพื่อการลงทุนสุทธิ
                      </div>
                      <div className=" whitespace-nowrap bg-gray50 h-full flex justify-center items-center px-10  ">
                        รายได้
                      </div>
                      <div className=" whitespace-nowrap bg-gray50 h-full flex justify-center items-center ">
                        รายจ่าย
                      </div>
                      <div className=" whitespace-nowrap bg-gray50 h-full flex justify-center items-center ">
                        คงเหลือ
                      </div>
                      <div className=" md:text-center md:break-words md:whitespace-normal  whitespace-nowrap bg-gray50 h-full flex justify-center items-center ">
                        ผลตอบแทนการลงทุน
                      </div>
                    </div>
                  </div>

                  {/* //Body */}

                  <div className={`w-[100vh] `}>
                    <div
                      // key={index}
                      className={` font-semibold text-mainblue grid grid-cols-5  items-center w-full 
                  `}
                    >
                      <div className="">
                        {loopInvestmentAssets()?.map((row, index) => {
                          return (
                            <div
                              key={index}
                              className={` whitespace-nowrap h-14 flex justify-center items-center  ${checkColor(
                                index
                              )}`}
                            >
                              {numeral(row?.results).format("0,0.00")}
                            </div>
                          );
                        })}
                      </div>
                      <div className="">
                        {loopIncome()?.map((row, index) => {
                          return (
                            <div
                              key={index}
                              // className={` whitespace-nowrap h-14 flex justify-center items-center
                              // ${checkColor(index)} `}
                              className={STYLE_WHITE_TABLE_RETIRE}
                            >
                              {numeral(row).format("0,0.00")}
                            </div>
                          );
                        })}
                      </div>
                      <div className="">
                        {loopOutcome()?.map((row, index) => {
                          return (
                            <div
                              key={index}
                              className={STYLE_WHITE_TABLE_RETIRE}
                            >
                              {numeral(row).format("0,0.00")}
                            </div>
                          );
                        })}
                      </div>
                      <div className="">
                        {loopRemain()?.map((row, index) => {
                          return (
                            <div
                              key={index}
                              className={STYLE_WHITE_TABLE_RETIRE}
                            >
                              {numeral(row?.results).format("0,0.00")}
                            </div>
                          );
                        })}
                      </div>
                      <div className="">
                        {loopReturnInvestment()?.map((row, index) => {
                          return (
                            <div
                              key={index}
                              className={STYLE_WHITE_TABLE_RETIRE}
                            >
                              {numeral(row?.results).format("0,0.00")}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className=" flex  justify-center mt-5">
              <MTButton
                // loading={loading}
                text="ย้อนกลับ"
                color="border border-maincyan "
                colortext="text-maincyan group-hover:text-white"
                hovercolor="hover:bg-maincyan group "
                onClick={() => {
                  // clearRetireData();
                  NavigateGoBack(navigate);
                }}
              />
            </div>
          </div>
        </Container>
      ) : isAfterMode ? (
        <Container>
          {/*-------------------- ตารางหลังวางแผน --------------------*/}
          <div className={`${STYLE_MARGIN_TOP_PARENT} min-h-screen mb-20 `}>
            <HeaderTitle
              title="ตารางสรุปความเคลื่อนไหวการเงิน"
              color="bg-[#EAEAEA]"
              isGoback
              onClickGoBack={() => {
                NavigateGoBack(navigate);
              }}
            />
            {/* //Head */}
            <div className=" mt-8 px-6">
              <div className="flex w-full ">
                <div className=" shadow-md rounded-tl-[20px] rounded-bl-[20px] shadow-gray-500 w-fit sticky z-10 ">
                  <div className="bg-gray50 h-14 px-3 font-semibold text-mainblue rounded-t-[20px]  rounded-tr-none w-14 flex gap-[38px] items-center ">
                    {/* <div>ปีที่</div> */}
                    <div>อายุ</div>
                  </div>
                  <div
                    className=""
                  >
                    <div
                      className={`  flex  items-center text-lg font-medium text-mainblue w-14 h-full `}
                    >
                      <div className=" h-14 w-full">
                        {dataAgeAF()?.map((row, index) => {
                          return (
                            <div
                              key={index}
                              className={` ${styleTableBody(
                                row?.status
                              )} h-full flex justify-center items-center text-mainblue w-full ${checkColor(
                                index
                              )}`}
                            >
                              {row}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" w-full max-w-2xl overflow-hidden overflow-x-scroll h-full rounded-tr-[20px] rounded-br-[20px] bg-gray50">
                  <div className=" w-[100vh]  ">
                    <div className=" h-14 font-semibold text-mainblue grid grid-cols-5 items-center w-full bg-gray50  ">
                      <div className=" text-sm pl-1 md:text-center md:break-words md:whitespace-normal break-word bg-gray50 h-full flex justify-center items-center  md:pr-4 ">
                        สินทรัพย์เพื่อการลงทุนสุทธิ
                      </div>
                      <div className=" whitespace-nowrap bg-gray50 h-full flex justify-center items-center px-10  ">
                        รายได้
                      </div>
                      <div className=" whitespace-nowrap bg-gray50 h-full flex justify-center items-center ">
                        รายจ่าย
                      </div>
                      <div className=" whitespace-nowrap bg-gray50 h-full flex justify-center items-center  ">
                        คงเหลือ
                      </div>
                      <div className=" md:text-center md:break-words md:whitespace-normal  whitespace-nowrap bg-gray50 h-full flex justify-center items-center ">
                        ผลตอบแทนการลงทุน
                      </div>
                    </div>
                  </div>

                  {/* //Body */}
                  <div className={`w-[100vh] `}>
                    <div
                      // key={index}
                      className={` font-semibold text-mainblue grid grid-cols-5  items-center w-full 
                  `}
                    >
                      <div className="">
                        {loopInvestmentAssetsAF()?.map((row, index) => {
                          return (
                            <div
                              key={index}
                              className={` whitespace-nowrap h-14 flex justify-center items-center  ${checkColor(
                                index
                              )}`}
                            >
                              {numeral(row?.results).format("0,0.00")}
                            </div>
                          );
                        })}
                      </div>
                      <div className="">
                        {loopIncomeAF()?.map((row, index) => {
                          return (
                            <div
                              key={index}
                              className={STYLE_WHITE_TABLE_RETIRE}
                            >
                              {numeral(row).format("0,0.00")}
                            </div>
                          );
                        })}
                      </div>
                      <div className="">
                        {loopOutcomeAF()?.map((row, index) => {
                          return (
                            <div
                              key={index}
                              className={STYLE_WHITE_TABLE_RETIRE}
                            >
                              {numeral(row).format("0,0.00")}
                            </div>
                          );
                        })}
                      </div>
                      <div className="">
                        {loopRemainAF()?.map((row, index) => {
                          return (
                            <div
                              key={index}
                              className={STYLE_WHITE_TABLE_RETIRE}
                            >
                              {numeral(row?.results).format("0,0.00")}
                            </div>
                          );
                        })}
                      </div>
                      <div className="">
                        {loopReturnInvestmentAF()?.map((row, index) => {
                          return (
                            <div
                              key={index}
                              className={STYLE_WHITE_TABLE_RETIRE}
                            >
                              {numeral(row?.results).format("0,0.00")}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className=" flex  justify-center mt-5">
              <MTButton
                text="ย้อนกลับ"
                color="border border-maincyan "
                colortext="text-maincyan group-hover:text-white"
                hovercolor="hover:bg-maincyan group "
                onClick={() => {
                  NavigateGoBack(navigate);
                }}
              />
            </div>
          </div>
        </Container>
      ) : isSensitive ? (
        <Container>
          {/*-------------------- ตารางทดสอบความอ่อนไหว --------------------*/}
          <div className={`${STYLE_MARGIN_TOP_PARENT} min-h-screen mb-20 `}>
            <HeaderTitle
              title="ตารางสรุปความเคลื่อนไหวการเงิน"
              color="bg-[#EAEAEA]"
              isGoback
              onClickGoBack={() => {
                NavigateGoBack(navigate);
              }}
            />
            {/*---------- Head ---------*/}
            <div className=" mt-8 px-6">
              <div className="flex w-full ">
                <div className=" shadow-md rounded-tl-[20px] rounded-bl-[20px] shadow-gray-500 w-fit sticky z-10 ">
                  <div className="bg-gray50 h-14 px-3 font-semibold text-mainblue rounded-t-[20px]  rounded-tr-none w-14 flex gap-[38px] items-center ">
                    <div>อายุ</div>
                  </div>
                  <div className="">
                    <div
                      className={`flex  items-center text-lg font-medium text-mainblue w-14 h-full`}
                    >
                      <div className=" h-14 w-full">
                        {dataAgeAFSensitive()?.map((row, index) => {
                          return (
                            <div
                              key={index}
                              className={` ${styleTableBody(
                                row?.status
                              )} h-full flex justify-center items-center text-mainblue w-full ${checkColor(
                                index
                              )}`}
                            >
                              {row}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>

                {/*---------- Body ----------*/}
                <div className=" w-full max-w-2xl overflow-hidden overflow-x-scroll h-full rounded-tr-[20px] rounded-br-[20px] bg-gray50">
                  <div className=" w-[100vh]  ">
                    <div className=" h-14 font-semibold text-mainblue grid grid-cols-5 items-center w-full bg-gray50  ">
                      <div className=" text-sm pl-1 md:text-center md:break-words md:whitespace-normal break-word bg-gray50 h-full flex justify-center items-center  md:pr-4 ">
                        สินทรัพย์เพื่อการลงทุนสุทธิ
                      </div>
                      <div className=" whitespace-nowrap bg-gray50 h-full flex justify-center items-center px-10  ">
                        รายได้
                      </div>
                      <div className=" whitespace-nowrap bg-gray50 h-full flex justify-center items-center ">
                        รายจ่าย
                      </div>
                      <div className=" whitespace-nowrap bg-gray50 h-full flex justify-center items-center  ">
                        คงเหลือ
                      </div>
                      <div className=" md:text-center md:break-words md:whitespace-normal  whitespace-nowrap bg-gray50 h-full flex justify-center items-center ">
                        ผลตอบแทนการลงทุน
                      </div>
                    </div>
                  </div>

                  <div className={`w-[100vh] `}>
                    <div
                      className={` font-semibold text-mainblue grid grid-cols-5  items-center w-full 
                  `}
                    >
                      {/*---- สินทรัพย์เพื่อการลงทุนหลังปรับ ----*/}
                      <div className="">
                        {loopInvestmentAssetsAFSensitive()?.map(
                          (row, index) => {
                            return (
                              <div
                                key={index}
                                className={` whitespace-nowrap h-14 flex justify-center items-center  ${checkColor(
                                  index
                                )}`}
                              >
                                {numeral(row?.results).format("0,0.00")}
                              </div>
                            );
                          }
                        )}
                      </div>

                      {/*---- รายได้หลังปรับกลยุทธ์ ----*/}
                      <div className="">
                        {loopIncomeAFSensitive()?.map((row, index) => {
                          return (
                            <div
                              key={index}
                              className={STYLE_WHITE_TABLE_RETIRE}
                            >
                              {numeral(row).format("0,0.00")}
                            </div>
                          );
                        })}
                      </div>

                      {/*---- รายจ่ายหลังปรับกลยุทธ์ ----*/}
                      <div className="">
                        {loopOutcomeAFSensitive()?.map((row, index) => {
                          return (
                            <div
                              key={index}
                              className={STYLE_WHITE_TABLE_RETIRE}
                            >
                              {numeral(row).format("0,0.00")}
                            </div>
                          );
                        })}
                      </div>

                      {/*---- คงเหลือหลังปรับ ----*/}
                      <div className="">
                        {loopRemainAFSensitive()?.map((row, index) => {
                          return (
                            <div
                              key={index}
                              className={STYLE_WHITE_TABLE_RETIRE}
                            >
                              {numeral(row?.results).format("0,0.00")}
                            </div>
                          );
                        })}
                      </div>

                      {/*---- ผลตอบแทนการลงทุนหลังปรับ ----*/}
                      <div className="">
                        {loopReturnInvestmentAFSensitive()?.map(
                          (row, index) => {
                            return (
                              <div
                                key={index}
                                className={STYLE_WHITE_TABLE_RETIRE}
                              >
                                {numeral(row?.results).format("0,0.00")}
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className=" flex  justify-center mt-5">
              <MTButton
                text="ย้อนกลับ"
                color="border border-maincyan "
                colortext="text-maincyan group-hover:text-white"
                hovercolor="hover:bg-maincyan group "
                onClick={() => {
                  NavigateGoBack(navigate);
                }}
              />
            </div>
          </div>
        </Container>
      ) : (
        <Container>
          <div>Page Not Found.</div>
        </Container>
      )}
    </React.Fragment>
  );
};

export default SummaryTableFinance;
