import numeral from "numeral";
import { ImageMain } from "../../../components/img/Img.Main";

export const StatusCard = ({ value, status }) => {
  const checkstatus = (status) => {
    if (status === "more") {
      return "ควรซื้อเพิ่มอีก";
    } else if (status === "normal") {
      return "ยอดเยี่ยม";
    } else if (status === "toomuch") {
      return "ทุนประกันเกินจากที่แนะนำ";
    }
  };

  const checkicon = () => {
    if (status === "more") {
      return "../images/bad_face.png";
    } else if (status === "normal") {
      return "../images/verygoodface.png";
    } else if (status === "toomuch") {
      return "../images/taxicon3.png";
    }
  };

  const checkText = (status) => {
    if (status === "more") {
      return (
        <div className="flex flex-col">
          <div className=" text-maincyan font-semibold text-3xl text-center">
            {numeral(value)?.format("0,0")} บาท
          </div>
        </div>
      );
    } else if (status === "normal") {
      return (
        <div className=" text-mainblue font-semibold text-sm text-center">
          คุณมีทุนประกันที่วางแผนมาแล้วอย่างพอดี
        </div>
      );
    } else if (status === "toomuch") {
      return (
        <div className=" text-mainblue font-semibold text-sm text-center flex flex-col">
          <div>คุณรักครอบครัวมาก มีการคุ้มครองมาดีแล้ว</div>
          <div className="">
            หากต้องคุ้มครองเป็นกองมรดกเพิ่ม ก็แล้วแต่ความเห็นสมควร
          </div>
        </div>
      );
    }
  };

  return (
    <div className=" mx-auto">
      <div className="flex justify-center items-center gap-2 font-medium">
        <ImageMain
          src={checkicon(status)}
          alt="insu_plan_3"
          className=" w-8 h-8"
        />
        <div className="">{checkstatus(status)}</div>
      </div>
      {checkText(status)}
    </div>
  );
};
