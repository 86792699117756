import numeral, { options } from "numeral";
import { ImageMain } from "../../../components/img/Img.Main";
import {
  ICON_ARROW_UP_DOWN,
  ICON_CALENDAR,
  ICON_DELETE,
  ICON_EDIT_PENCIL,
  ICON_INFO,
  ICON_MINUS_CIRCLE,
  ICON_PLUS,
} from "../../../utils/useIcons";
import {
  STYLE_ACCORDION_BODY,
  STYLE_ACCORDION_HEADER,
  STYLE_CARD_ACCORDION,
  STYLE_CARD_BALANCE,
  STYLE_IMG_BALANCE,
  STYLE_INPUT,
  STYLE_INPUT_CARD_BALANCE,
  STYLE_INPUT_INSET,
  STYLE_PARENT_CARD_BALANCE,
  STYLE_TEXTAREA_CARD_BALANCE,
  STYLE_TITLE_ACCORDION_BALANCE,
  STYLE_TITLE_BALANCE,
  STYLE_TITLE_CARD_DRAWER_BL,
  STYLE_VALUE_ACCORDION_BALANCE,
} from "../../../utils/useStyle";
import { NumericFormat } from "react-number-format";
import { calPeryear } from "../functions";
import { forwardRef, useMemo } from "react";
import { DeleteButtonCard } from "../../../components/button/MTButton";
import { InputMain } from "../../../components/input/InputMain";
import { Checkbox } from "@material-tailwind/react";
import { CheckBoxSecond } from "../../../components/checkbox/CheckboxSecond";
import DropDownMain from "../../../components/dropdown/DropDownMain";
import ReactDatePicker from "react-datepicker";
import { FormExpenseFixed } from "../form/Form.Main";
import { CardTable } from "../../../components/card/CardMain";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import th from "date-fns/locale/th";
import _ from "lodash";
import { cn, mapDebtConditionColor } from "../../../utils/useFunctions";
import NumbericValidateRed from "../../../components/DebtBalance/numericRed";
registerLocale("th", th);

export const options_period_car = [
  {
    label: 12,
    value: 12,
  },
  {
    label: 24,
    value: 24,
  },
  {
    label: 36,
    value: 36,
  },
  {
    label: 48,
    value: 48,
  },
  {
    label: 60,
    value: 60,
  },
  {
    label: 72,
    value: 72,
  },
  {
    label: 84,
    value: 84,
  },
  {
    label: 96,
    value: 96,
  },
];

export const options_debt = [
  {
    label: "ปกติ",
    value: "normal",
  },
  {
    label: "ชำระล่าช้าไม่เกิน 90 วัน",
    value: "more_90",
  },
  {
    label: "ไม่ได้ชำระเกิน 90 วัน",
    value: "less_90",
  },
];

const years = _.range(1890, new Date().getFullYear() + 1, 1);
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const CustomInputBirthdayHearder = ({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) => {
  return (
    <div className="flex items-center gap-5 justify-center">
      <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
        {"<"}
      </button>
      <select
        value={date ? new Date(date).getFullYear() : new Date().getFullYear()}
        onChange={({ target: { value } }) => changeYear(value)}
      >
        {years.map((option) => (
          <option key={option} value={option}>
            {option + 543}
          </option>
        ))}
      </select>

      <select
        value={months[date ? new Date(date).getMonth() : new Date().getMonth()]}
        onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
      >
        {months.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>

      <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
        {">"}
      </button>
    </div>
  );
};

export const CustomInputBirthday = forwardRef((props, ref) => {
  const { value, onClick } = props;

  let thaiDate = "";
  if (value) {
    const splitDate = String(value)?.split("/");
    const date = splitDate[0];
    const month = Number(splitDate[1]);
    const year = Number(splitDate[2]) + 543;
    thaiDate = `${date}/${month}/${year}`;
  }

  return (
    <label
      onClick={onClick}
      className={cn(
        " relative flex items-center rounded-full w-full h-10 mt-[2px]",
        props?.className
      )}
    >
      <div className="px-[14px] w-full h-[35px] border border-gray-200 items-center flex rounded-full caret-transparent">
        {value ? thaiDate : <span className="text-gray-400">วัน/เดือน/ปี</span>}
      </div>
      <ICON_CALENDAR className={" absolute mx-[14px] right-0 w-6 h-6"} />
    </label>
  );
});

export const BoxWhite = ({
  title = "",
  value,
  colortext,
  icon = "/images/finance_income2.png",
  iconStyle = STYLE_IMG_BALANCE,
  cardStyle = STYLE_CARD_BALANCE,
  info,
  onClickInfo,
  isIconTypeImage = true,
}) => {
  return (
    <div className={cardStyle}>
      {isIconTypeImage ? <ImageMain src={icon} className={iconStyle} alt="" />: icon}
      <div>
        <div className=" flex gap-[6px] items-center">
          <div className={STYLE_TITLE_BALANCE}>{title}</div>
          {info && (
            <ICON_INFO
              className=" w-[14px] h-[14px] text-gray90"
              onClick={onClickInfo}
            />
          )}
        </div>
        <div className={`text-xl font-semibold ${colortext} `}>
          {numeral(value).format("0,0")} บาท
        </div>
      </div>
    </div>
  );
};

export const CardAccordian = ({
  title = "",
  textColor,
  icon = "/images/finance_income2.png",
  iconStyle = STYLE_IMG_BALANCE,
  value,
  onClickOpen,
  onCLickMinus,
  onClickAccordion,
  open,
  add,
  panel,
  zIndex,
  isIconTypeImage=true,
  data,
}) => {
  return (
    <div
      style={{
        zIndex: zIndex,
      }}
      id={open}
      className={`${STYLE_CARD_ACCORDION} cursor-pointer`}
    >
      <div className={`flex gap-4 items-center w-full  `}>
       {isIconTypeImage ? <ImageMain src={icon} className={iconStyle} />: icon}
        <div onClick={onClickOpen} className="flex items-center w-full">
          <div>
            <div className={STYLE_TITLE_ACCORDION_BALANCE}>{title}</div>
            <div className={STYLE_VALUE_ACCORDION_BALANCE}>
              รวม
              <div className={` ${textColor} font-semibold `}>
                {numeral(value).format("0,0") || 0}
              </div>
              บาท
            </div>
          </div>
        </div>

        <div className=" ml-auto">
          <div>
            {add ? (
              <ICON_PLUS
                className="w-6 text-maincyan cursor-pointer right-0"
                onClick={onClickAccordion}
              />
            ) : open ? (
              <ICON_MINUS_CIRCLE
                className=" w-6 text-maincyan ml-auto cursor-pointer"
                onClick={onCLickMinus}
              />
            ) : (
              <ICON_PLUS
                className=" w-6 text-maincyan cursor-pointer"
                onClick={onClickAccordion}
              />
            )}
          </div>
        </div>
      </div>
      <div
        onClick={onClickOpen}
        className=" text-sm text-maingray  text-center underline underline-offset-2 hover:text-maincyan"
      >
        {open ? "ซ่อนรายละเอียด" : "ดูรายละเอียด"}
      </div>
      {open && (
        <div
          className={` ${STYLE_ACCORDION_BODY}  ${
            { open }
              ? " max-h-fit transition-all duration-300 ease-in-out "
              : "max-h-0 overflow-hidden duration-300 ease-in-out "
          } `}
        >
          {panel}
        </div>
      )}
    </div>
  );
};

export const CardAccordianSingle = ({
  title = "",
  textColor,
  icon = "/images/finance_income2.png",
  iconStyle = STYLE_IMG_BALANCE,
  value,
  onClickOpen,
  onCLickMinus,
  onClickAccordion,
  open,
  add,
  panel,
  zIndex,
  data,
}) => {
  return (
    <div
      style={{
        zIndex: zIndex,
      }}
      id={open}
      className={`${STYLE_CARD_ACCORDION} cursor-pointer`}
    >
      <div className={`flex gap-4 items-center w-full  `}>
        <ImageMain src={icon} className={iconStyle} />
        <div onClick={onClickOpen} className="flex items-center w-full">
          <div>
            <div className={STYLE_TITLE_ACCORDION_BALANCE}>{title}</div>
            <div className={STYLE_VALUE_ACCORDION_BALANCE}>
              รวม
              <div className={` ${textColor} font-semibold `}>
                {numeral(value).format("0,0") || 0}
              </div>
              บาท
            </div>
          </div>
        </div>

        <div className=" ml-auto w-full">
          <div>
            {data ? (
              open ? (
                <div
                  onClick={onClickOpen}
                  className=" text-sm text-maingray  text-right underline underline-offset-2 hover:text-maincyan"
                >
                  ซ่อนรายละเอียด
                </div>
              ) : (
                ""
              )
            ) : (
              <ICON_PLUS
                className=" w-6 text-maincyan cursor-pointer ml-auto"
                onClick={onClickAccordion}
              />
            )}
          </div>
        </div>
      </div>
      <div
        onClick={onClickOpen}
        className=" text-sm text-maingray  text-center underline underline-offset-2 hover:text-maincyan"
      >
        {open ? "" : "ดูรายละเอียด"}
      </div>
      {open && (
        <div
          className={` ${STYLE_ACCORDION_BODY}  ${
            { open }
              ? " max-h-fit transition-all duration-300 ease-in-out "
              : "max-h-0 overflow-hidden duration-300 ease-in-out "
          } `}
        >
          {panel}
        </div>
      )}
    </div>
  );
};

export const CardIncome = ({
  placeholder = "กรุณากรอกชื่อรายการ",
  valueTitle = "",
  onChange,
  onClickDelete,
  head,
  title_1,
  value_1,
  onChangeValue1,
  total,
  value_2,
  title_2,
  onChangeValue2,
  isHasvalue_2,
  isShowYear = true,
}) => {
  return (
    <div className=" bg-white rounded-[20px] px-4 pt-3 pb-[14px] mt-4 ">
      <div className=" flex  items-end w-full">
        <div className="w-full">
          <div className={`${STYLE_TITLE_CARD_DRAWER_BL}`}>{head}</div>
          <InputMain
            placeholder={placeholder}
            className={`${STYLE_INPUT_CARD_BALANCE} w-full `}
            value={valueTitle}
            onChange={onChange}
          />
        </div>
        <ICON_DELETE
          className="w-5 h-5 cursor-pointer ml-1 mb-2"
          onClick={onClickDelete}
        />
      </div>
      <div className={STYLE_PARENT_CARD_BALANCE}>
        <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2  `}>
          {title_1}
        </div>
        <NumericFormat
          maxLength={11}
          value={value_1}
          placeholder="0"
          onChange={onChangeValue1}
          thousandSeparator=","
          inputMode="decimal"
          pattern="[0-9],*"
          className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
        />
      </div>
      {isHasvalue_2 && (
        <div className={STYLE_PARENT_CARD_BALANCE}>
          <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2  `}>
            {title_2}
          </div>
          <NumericFormat
            maxLength={11}
            value={value_2}
            placeholder="0"
            onChange={onChangeValue2}
            thousandSeparator=","
            inputMode="decimal"
            pattern="[0-9],*"
            className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
          />
        </div>
      )}
      {isShowYear ? (
        <div className=" flex justify-center items-center mt-3">
          รวมปีละ{" "}
          <span className="font-semibold text-center text-maincyan ml-1">
            {numeral(total).format("0,0")}
          </span>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

//------------------------------------------------ Card หนี้ระยะสั้น ------------------------------------------------//
export const CardDebt = ({
  keyIndex = "",
  indexCard = "",
  placeholder = "ระบุ",
  placeholderShortTerm,
  onChange,
  title = "",
  onChangeTitle,
  creditor,
  creditor_name = "",
  onChangeCreditName,
  compoundingType,
  onChangeCompoundingType,
  optionCompounding,

  startAmount,
  onChangeStartAmount,
  startUnpaidAmount,
  onChangeStartUnpaidAmount,
  unpaidAmount,
  onChangeUnpaidAmount,

  interest,
  onChangeInterest,
  payPermonth,
  onChangePayPerMonth,
  period,
  onChangePeriod,
  onChangeYear,
  payPeryear,
  onClickDelete,
  isCheckedNormalPayment,
  handleCheckboxChange,
  typePayment = "",
  handleCheckboxChangeType,
  cardType = "",
  year,
  onChangeStatus,
  status,
  onChangeNumberOfDaysInformalShortTerm,
  number_of_days,
}) => {
  // แปลงจำนวนงวดเป็นเวลากี่ปีกี่เดือน
  const convertPeriodToYearsMonths = (period) => {
    if (String(period) === "Infinity") {
      return "";
    }

    let value = period ? period : 0;
    if (value < 12) {
      return `${Math.ceil(value)} เดือน`;
    } else {
      const years = value / 12;
      const remainingMonths = value % 12;
      return `${Math.ceil(years)} ปี ${Math.ceil(remainingMonths)} เดือน`;
    }
  };

  const convertPeriodToYears = (period) => {
    let value = period ? period : 0;
    if (value < 12) {
      return false;
    } else {
      const years = value / 12;
      return years > 10 ? true : false;
    }
  };

  return (
    <>
      {/*--------------------------- หนี้เงินกู้ระยะสั้น || หนี้เงินกู้ระยะสั้นอื่น ๆ || หนี้นอกระบบ --------------------------*/}
      {creditor ? (
        <div className=" bg-white rounded-[20px] px-4 pt-3 pb-[14px] mt-4 ">
          <div className="flex flex-col gap-0.5">
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} `}>ชื่อเจ้าหนี้</div>
            <div className=" flex justify-between items-center w-full">
              <input
                placeholder={placeholderShortTerm}
                className={`${STYLE_INPUT_CARD_BALANCE} h-5 rounded-[4px] w-full `}
                value={creditor_name}
                onChange={onChangeCreditName}
              />
              <ICON_DELETE
                className="w-5 h-5 cursor-pointer ml-1"
                onClick={onClickDelete}
              />
            </div>
          </div>

          <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2  `}>
              จำนวนเงินต้น
            </div>
            <NumericFormat
              maxLength={11}
              value={
                startAmount
                // cardType === "debt_informal_shortterm"
                //   ? unpaidAmount
                //   : startAmount
              }
              placeholder="0"
              // onChange={
              //   cardType === "debt_informal_shortterm"
              //     ? onChangeUnpaidAmount
              //     : onChangeStartAmount
              // }
              onChange={onChangeStartAmount}
              thousandSeparator=","
              inputMode="decimal"
              pattern="[0-9],*"
              className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
            />
          </div>

          {["debt_informal_shortterm"].includes(cardType) && (
            <div className={STYLE_PARENT_CARD_BALANCE}>
              <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2  `}>
                ชำระวันละ (บาท)
              </div>
              <NumericFormat
                maxLength={11}
                placeholder="0"
                value={payPermonth}
                onChange={onChangePayPerMonth}
                thousandSeparator=","
                inputMode="decimal"
                pattern="[0-9],*"
                className={`${STYLE_INPUT_CARD_BALANCE}  col-span-3 ml-1  `}
              />
            </div>
          )}

          {["debt_informal_shortterm"].includes(cardType) && (
            <div className={STYLE_PARENT_CARD_BALANCE}>
              <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2  `}>
                จำนวนวันที่ต้องชำระ (วัน)
              </div>
              <NumericFormat
                maxLength={11}
                placeholder="0"
                value={number_of_days}
                onChange={onChangeNumberOfDaysInformalShortTerm}
                thousandSeparator=","
                inputMode="decimal"
                pattern="[0-9],*"
                className={`${STYLE_INPUT_CARD_BALANCE}  col-span-3 ml-1  `}
              />
            </div>
          )}

          {!["debt_informal_shortterm", "debt_shortterm_other"].includes(
            cardType
          ) && (
            <div className={STYLE_PARENT_CARD_BALANCE}>
              <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2  `}>
                อัตราดอกเบี้ยเงินกู้ต่อปี (%)
              </div>
              <NumericFormat
                maxLength={11}
                value={interest}
                placeholder="0"
                onChange={onChangeInterest}
                thousandSeparator=","
                inputMode="decimal"
                pattern="[0-9],*"
                className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1  `}
              />
              {/* <div className=" absolute right-6 text-maingray">%</div> */}
            </div>
          )}

          {[ "debt_shortterm_other"].includes(
            cardType
          ) && (
            <div className={STYLE_PARENT_CARD_BALANCE}>
              <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2  `}>
                อัตราดอกเบี้ยเงินกู้ต่อเดือน (%)
              </div>
              <NumericFormat
                maxLength={11}
                value={interest}
                placeholder="0"
                onChange={onChangeInterest}
                thousandSeparator=","
                inputMode="decimal"
                pattern="[0-9],*"
                className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1  `}
              />
              {/* <div className=" absolute right-6 text-maingray">%</div> */}
            </div>
          )}

          {["debt_informal_shortterm"].includes(cardType) && (
            <div className={STYLE_PARENT_CARD_BALANCE}>
              <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2  `}>
                อัตราดอกเบี้ยเงินกู้ต่อปี (%)
              </div>
              <NumericFormat
                maxLength={11}
                value={numeral(interest).format("0.00")}
                placeholder="0"
                onChange={onChangeInterest}
                thousandSeparator=","
                inputMode="decimal"
                pattern="[0-9],*"
                disabled
                className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1  `}
              />
              {/* <div className=" absolute right-6 text-maingray">%</div> */}
            </div>
          )}

          {!["debt_informal_shortterm"].includes(cardType) && (
            <div className={`${STYLE_PARENT_CARD_BALANCE} `}>
              <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
                จำนวนเงินค้างชำระ
              </div>
              <NumericFormat
                maxLength={11}
                value={unpaidAmount}
                placeholder="0"
                onChange={onChangeUnpaidAmount}
                thousandSeparator=","
                inputMode="decimal"
                pattern="[0-9],*"
                className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
              />

              {/* {startAmount && startUnpaidAmount && interest ? (
               <div className="text-[10px] text-mainred mt-1">
                 *ควรอยู่ระหว่าง {startUnpaidAmount} -{" "}
                 {startAmount + startAmount * (interest / 100)}
               </div>
             ) : (
               ""
             )} */}
              {/* <div className=" absolute right-6 text-maingray">บาท</div> */}
            </div>
          )}

          {![
            "shortterm",
            "debt_informal_shortterm",
            "debt_shortterm_other",
          ].includes(cardType) && (
            <div className="flex flex-col gap-0.5 mt-2">
              <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2  `}>
                การคิดทบต้น
              </div>
              <DropDownMain
                placeholder={`กรุณาเลือกประเภท`}
                className={`border border-gray60 rounded-full`}
                value={optionCompounding?.filter((option) => {
                  if (option?.value === compoundingType) return option?.label;
                })}
                onChange={onChangeCompoundingType}
                options={optionCompounding}
                zIndex={2}
              />
            </div>
          )}

          {!["debt_informal_shortterm"].includes(cardType) && (
            <div className={STYLE_PARENT_CARD_BALANCE}>
              <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2  `}>
                ชำระงวดละ (บาท)
              </div>
              <NumericFormat
                maxLength={11}
                placeholder="0"
                value={payPermonth}
                onChange={onChangePayPerMonth}
                thousandSeparator=","
                inputMode="decimal"
                pattern="[0-9],*"
                // disabled={cardType === "shortterm"}
                className={`${STYLE_INPUT_CARD_BALANCE}  col-span-3 ml-1  `}
              />
            </div>
          )}

          {cardType !== "debt_informal_shortterm" && (
            <div className={`${STYLE_PARENT_CARD_BALANCE} mt-2.5`}>
              <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2  `}>
                ระยะเวลา (งวด)
              </div>
              <div className="col-span-3 ml-1 font-semibold text-maincyan text-sm">
                {String(period) === "Infinity" ? (
                  <div className="flex items-center gap-1.5">
                    ไม่มีที่สิ้นสุด
                  </div>
                ) : (
                  <div className="flex items-center gap-1.5">
                    <div>{numeral(period).format("0,0")}</div>
                    <div>งวด</div>
                    <div>({convertPeriodToYearsMonths(period)})</div>
                  </div>
                )}
              </div>
              {/* <NumericFormat
              disabled={true}
              maxLength={11}
              placeholder="0"
              value={period}
              onChange={onChangePeriod}
              thousandSeparator=","
              inputMode="decimal"
              pattern="[0-9],*"
              className={`${STYLE_INPUT_CARD_BALANCE}  col-span-3 ml-1  `}
            /> */}
            </div>
          )}

          {cardType === "shortterm" && String(period) === "Infinity" && (
            <div className={`${STYLE_PARENT_CARD_BALANCE} mt-2.5`}>
              <div
                className={`${STYLE_TITLE_CARD_DRAWER_BL} !text-gray-600 col-span-4  `}
              >
                *หากคุณชำระรายงวดแบบนี้ต่อไปต้องใช้ระยะเวลานานกว่าหนี้สินจะจบลง
              </div>
            </div>
          )}

          {/* {cardType === "debt_informal_shortterm" && (
            <div className={`${STYLE_PARENT_CARD_BALANCE} mt-2.5`}>
              <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2  `}>
                ระยะเวลาที่จะชำระหมด (ปี)
              </div>
              <NumericFormat
                // disabled={true}
                maxLength={11}
                placeholder="0"
                value={year}
                onChange={onChangeYear}
                thousandSeparator=","
                inputMode="decimal"
                pattern="[0-9],*"
                className={`${STYLE_INPUT_CARD_BALANCE}  col-span-3 ml-1  `}
              />
            </div>
          )} */}

          {/* <div className={`${STYLE_PARENT_CARD_BALANCE} mt-3`}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2  `}>
              สถานภาพของหนี้
            </div>
            <div className="col-span-3">
              <div className=" -mt-3">
                <CheckBoxSecond
                  isLeft={true}
                  label_title={`ชำระปกติ`}
                  checked={isCheckedNormalPayment}
                  onClick={handleCheckboxChange}
                />
              </div>
            </div>
          </div> */}

          <div className={`${STYLE_PARENT_CARD_BALANCE} mt-3`}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2  `}>
              สถานภาพของหนี้
            </div>
            <div className="col-span-3">
              <div className=" -mt-3">
                <DropDownMain
                  placeholder={`สถานภาพของหนี้`}
                  className={`border border-gray60 rounded-full mt-3 !text-yellow-500`}
                  value={options_debt?.filter((option) => {
                    if (option?.value === status) return option?.label;
                  })}
                  color={mapDebtConditionColor(
                    options_debt?.find((option) => {
                      if (option?.value === status) return option?.label;
                    })?.value
                  )}
                  onChange={onChangeStatus}
                  options={options_debt}
                  zIndex={2}
                />
              </div>
            </div>
          </div>

          {!["debt_informal_shortterm", "creditcard", "shortterm"].includes(
            cardType
          ) && (
            <div className=" flex justify-center items-center gap-1.5 mt-3">
              <span>ปีละ</span>
              <span className="font-semibold text-center text-maincyan">
                {numeral(payPeryear).format("0,0.00")}
              </span>{" "}
              <span>บาท</span>
            </div>
          )}
          {/* <div className=" flex justify-center items-center gap-1.5 mt-3">
            <span>ปีละ</span>
            <span className="font-semibold text-center text-maincyan">
              {numeral(payPeryear).format("0,0.00")}
            </span>{" "}
            <span>บาท</span>
          </div> */}
        </div>
      ) : (
        <div className=" bg-white rounded-[20px] px-4 pt-3 pb-[14px] mt-4 ">
          {/*------------------------------ หนี้บัตรเครดิต ------------------------------*/}
          <div className="flex flex-col gap-0.5">
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} `}>
              ชื่อบัตรเครดิต
            </div>
            <div className=" flex space-x-2 items-center w-full">
              <input
                placeholder={placeholder}
                className={`${STYLE_INPUT_CARD_BALANCE} h-5 rounded-[4px] w-full `}
                value={creditor_name}
                onChange={onChangeCreditName}
              />
              <ICON_DELETE
                className="w-5 h-5 cursor-pointer ml-1"
                onClick={onClickDelete}
              />
            </div>
          </div>

          <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2  `}>
              จำนวนเงินต้น
            </div>
            <NumericFormat
              maxLength={11}
              value={startAmount}
              placeholder="0"
              onChange={onChangeStartAmount}
              thousandSeparator=","
              inputMode="decimal"
              pattern="[0-9],*"
              className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
            />
            {/* <div className=" absolute right-6 text-maingray">บาท</div> */}
          </div>

          {/* <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2  `}>
              จำนวนเงินต้นค้างชำระ
            </div>
            <NumericFormat
              maxLength={11}
              value={startUnpaidAmount}
              placeholder="0"
              onChange={onChangeStartUnpaidAmount}
              thousandSeparator=","
              inputMode="decimal"
              pattern="[0-9],*"
              className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
            />
           
          </div> */}

          <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2  `}>
              อัตราดอกเบี้ยเงินกู้ต่อปี (%)
            </div>
            <NumericFormat
              maxLength={11}
              value={interest}
              placeholder="0"
              onChange={onChangeInterest}
              thousandSeparator=","
              inputMode="decimal"
              pattern="[0-9],*"
              className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1  `}
            />
            {/* <div className=" absolute right-6 text-maingray">%</div> */}
          </div>

          <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2  `}>
              จำนวนเงินค้างชำระ
            </div>
            <div className=" col-span-3 ml-1">
              <NumericFormat
                maxLength={11}
                value={unpaidAmount}
                placeholder="0"
                onChange={onChangeUnpaidAmount}
                thousandSeparator=","
                inputMode="decimal"
                pattern="[0-9],*"
                className={`${STYLE_INPUT_CARD_BALANCE} w-full`}
              />
              {/* {startAmount && startUnpaidAmount && interest ? (
                <div className="text-[10px] text-mainred mt-1">
                  *ควรอยู่ระหว่าง {startUnpaidAmount} -{" "}
                  {startAmount + startAmount * (interest / 100)}
                </div>
              ) : (
                ""
              )} */}
            </div>
            {/* <div className=" absolute right-6 text-maingray">บาท</div> */}
          </div>

          <div className={`${STYLE_PARENT_CARD_BALANCE} mt-3`}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2  `}>
              สถานภาพของหนี้
            </div>
            <div className="col-span-3">
              <div className=" -mt-3">
                <DropDownMain
                  placeholder={`สถานภาพของหนี้`}
                  className={`border border-gray60 rounded-full mt-3`}
                  value={options_debt?.filter((option) => {
                    if (option?.value === status) return option?.label;
                  })}
                  onChange={onChangeStatus}
                  options={options_debt}
                  zIndex={2}
                  color={mapDebtConditionColor(
                    options_debt?.find((option) => {
                      if (option?.value === status) return option?.label;
                    })?.value
                  )}
                />
              </div>
            </div>
          </div>

          {/*---------- วิธีชำระหนี้บัตรเครดิต ----------*/}
          <div className="mt-2.5">
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2  `}>
              ชำระหนี้
            </div>
            <div onChange={handleCheckboxChangeType}>
              <div className=" flex justify-between items-center">
                <CheckBoxSecond
                  label_title={`จ่ายเต็มจำนวน`}
                  isLeft={true}
                  name={`payment_type_${keyIndex}_${indexCard}`}
                  value={`pay_full`}
                  checked={typePayment === "pay_full" ? true : false}
                />
                {typePayment === "pay_full" && (
                  <div className=" mt-auto text-mainblue font-semibold text-sm">
                    ({numeral(payPermonth).format("0,0.00")} บาท)
                  </div>
                )}
              </div>

              <div className="flex flex-col">
                <div className=" flex justify-between items-center">
                  <CheckBoxSecond
                    label_title={`จ่ายขั้นต่ำ 8%`}
                    isLeft={true}
                    name={`payment_type_${keyIndex}_${indexCard}`}
                    value={`pay_minimum`}
                    checked={typePayment === "pay_minimum" ? true : false}
                  />

                  {typePayment === "pay_minimum" && (
                    <div className=" mt-auto text-mainblue font-semibold text-sm">
                      ({numeral(payPermonth).format("0,0.00")} บาท)
                    </div>
                  )}
                </div>

                <div className="mt-2 text-[12px] font-medium">
                  (เป็นการประมาณค่า 8% ของยอดค้างชำระต่อเดือน)
                </div>
              </div>

              <div className=" flex justify-between items-start">
                <CheckBoxSecond
                  label_title={`ระบุจำนวนเงิน`}
                  label_title_2="(มากกว่าขั้นต่ำ)"
                  isLeft={true}
                  name={`payment_type_${keyIndex}_${indexCard}`}
                  value={`pay_other`}
                  checked={typePayment === "pay_other" ? true : false}
                />
                {typePayment === "pay_other" && (
                  <div className="flex flex-col ml-1">
                    <NumericFormat
                      maxLength={11}
                      value={payPermonth}
                      placeholder="0"
                      onChange={onChangePayPerMonth}
                      thousandSeparator=","
                      inputMode="decimal"
                      pattern="[0-9],*"
                      className={`${STYLE_INPUT_CARD_BALANCE}`}
                    />
                    <div className={`${STYLE_TITLE_CARD_DRAWER_BL} mt-1`}>
                      (มากกว่าขั้นต่ำ)
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {!["debt_informal_shortterm", "creditcard", "shortterm"].includes(
            cardType
          ) && (
            <div className=" flex justify-center items-center gap-1.5 mt-3">
              <span>ปีละ</span>
              <span className="font-semibold text-center text-maincyan">
                {numeral(payPeryear).format("0,0.00")}
              </span>{" "}
              <span>บาท</span>
            </div>
          )}
        </div>
      )}
    </>
  );
};

//------------------------------------------------ Card หนี้ระยะยาว ------------------------------------------------//
export const CardDebtLong = ({
  isCar = false,
  isPlace = false,
  isSubmited=false,
  keyIndex,
  indexCard,
  onChange,
  onClickDelete,
  onChangeDropdown,
  optionsDropdown,
  placeholder = "กรุณากรอกชื่อบัตรเครดิต",
  options_debt_long,
  typeCar = "",
  typePlace = "",
  title = "",
  onChangeTitle,
  registration = "",
  onChangeRegistration,
  currentPrice,
  onChangeCurrentPrice,
  personalOrInvestment,
  onChangePersonalOrInvestment,

  startAmount,
  onChangeStartAmount,
  startUnpaidAmount,
  onChangeStartUnpaidAmount,
  unpaidAmount,
  onChangeUnpaidAmount,

  interest,
  onChangeInterest,
  payPermonth,
  onChangePayPerMonth,
  payPeryear,
  period,
  guarantorNameOne = "",
  onChangeGuarantorNameOne,
  guarantorRelationshipOne = "",
  onChangeGuarantorRelationshipOne,
  guarantorNameTwo = "",
  onChangeGuarantorNameTwo,
  guarantorRelationshipTwo = "",
  onChangeGuarantorRelationshipTwo,
  addressPlace,
  onChangeAddressPlace,
  typePlaceOther,
  onChangeTypeOther,
  collateralAssets,
  onChangeCollateralAssets,
  collateralAssetData,
  onChangeCollateralAssetData,
  collateralAssetValue,
  onChangeCollateralAssetValue,
  creditorName,
  onChangeCreditName,
  onChangeStatus,
  onChangePeriod,
  status,
}) => {
  // แปลงจำนวนงวดเป็นเวลากี่ปีกี่เดือน
  const convertPeriodToYearsMonths = (period) => {
    let value = period ? period : 0;
    if (value < 12) {
      return `${Math.ceil(value)} เดือน`;
    } else {
      const years = value / 12;
      const remainingMonths = value % 12;
      return `${Math.ceil(years)} ปี ${Math.ceil(remainingMonths)} เดือน`;
    }
  };

  return (
    <>
      {isCar ? (
        //---------- Car ----------//
        <div className=" bg-white rounded-[20px] px-4 pt-3 pb-[14px] mt-4 ">
          <div className=" flex items-center">
            <div className="w-full">
              <div className={`${STYLE_TITLE_CARD_DRAWER_BL}  `}>
                ชื่อยี่ห้อรถยนต์
              </div>
              <input
                className={`${STYLE_INPUT_CARD_BALANCE} h-5 rounded-[4px] w-full`}
                value={title}
                onChange={onChangeTitle}
                placeholder={placeholder}
              />
            </div>
            <ICON_DELETE
              className=" w-5 h-5 ml-auto mt-4 cursor-pointer"
              onClick={onClickDelete}
            />
          </div>

          <div className=" mt-2.5">
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL}  `}>ประเภท</div>
            <DropDownMain
              value={optionsDropdown?.filter((item) => {
                if (item.value === typeCar) return item.label;
              })}
              onChange={onChangeDropdown}
              options={optionsDropdown}
              zIndex={1}
              border=""
              height={25}
            />
          </div>

          <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
              ทะเบียนรถ
            </div>
            <input
              placeholder="ระบุ"
              value={registration}
              onChange={onChangeRegistration}
              className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
            />
          </div>

          <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
              มูลค่ารถ ณ ปัจจุบัน
            </div>
            <NumbericValidateRed
              value={currentPrice}
              onChange={onChangeCurrentPrice}
              isSubmited={isSubmited}
              shouldValdiate={true}
            />
          </div>

          {/*---------- ใช้ส่วนตัว หรือลงทุน ----------*/}
          <div
            onChange={onChangePersonalOrInvestment}
            className="flex items-center mb-3 space-x-6"
          >
            <CheckBoxSecond
              isLeft={true}
              name={`${keyIndex}_${indexCard}`}
              label_title={`ใช้ส่วนตัว`}
              value={`personal`}
              checked={personalOrInvestment === "personal" ? true : false}
            />
            <CheckBoxSecond
              isLeft={true}
              name={`${keyIndex}_${indexCard}`}
              label_title={`ลงทุน`}
              value={`investment`}
              checked={personalOrInvestment === "investment" ? true : false}
            />
          </div>

          <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
              เจ้าหนี้
            </div>
            <input
              placeholder="ระบุ"
              value={creditorName}
              onChange={onChangeCreditName}
              className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
            />
          </div>

          <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
              จำนวนเงินต้น
            </div>
            <NumericFormat
              maxLength={11}
              placeholder="0"
              value={startAmount}
              onChange={onChangeStartAmount}
              className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
            />
          </div>

          <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
              อัตราดอกเบี้ยเงินกู้ต่อปี (%)
            </div>
            <NumbericValidateRed
              value={interest}
              onChange={onChangeInterest}
              isSubmited={isSubmited}
              shouldValdiate={true}
            />
          </div>

          <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
              จำนวนเงินค้างชำระ
            </div>
            <NumbericValidateRed
              value={unpaidAmount}
              onChange={onChangeUnpaidAmount}
              isSubmited={isSubmited}
              shouldValdiate={true}
            />
          </div>

          <div className={cn(STYLE_PARENT_CARD_BALANCE, "")}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
              ระยะเวลา (เดือน)
            </div>
            <div className="col-span-3">
              <DropDownMain
                placeholder={`ระยะเวลา`}
                value={options_period_car?.filter((option) => {
                  if (option?.value === period) return option?.label;
                })}
                onChange={onChangePeriod}
                options={options_period_car}
                isSubmited={isSubmited}
                shouldValdiate={true}
                zIndex={2}
                className={`border border-gray60 rounded-full mt-3`}
              />
            </div>

          </div>

          <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2`}>
              ชำระเดือนละ
            </div>
            <NumericFormat
              maxLength={11}
              value={payPermonth}
              placeholder="0"
              onChange={onChangePayPerMonth}
              thousandSeparator=","
              inputMode="decimal"
              pattern="[0-9],*"
              disabled
              className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
            />
          </div>

          {/*---- ผู้ค้ำประกัน ----*/}
          <div className=" mt-2.5">
            <div className={STYLE_TITLE_CARD_DRAWER_BL}> ผู้ค้ำประกัน</div>
            <div className=" bg-maincyan/10 grid grid-cols-2 rounded-[5px] p-[5px] mt-[5px] h-[30px] items-center">
              <div className={`${STYLE_TITLE_CARD_DRAWER_BL} `}>ชื่อ</div>
              <div className={`${STYLE_TITLE_CARD_DRAWER_BL} ml-3`}>
                ความสัมพันธ์
              </div>
            </div>
            <div className="flex flex-col gap-2.5 bg-[#f9f9f9] p-2 rounded-[5px]">
              <div className=" flex items-center ">
                <div className={STYLE_TITLE_CARD_DRAWER_BL}>1.</div>
                <div className=" grid grid-cols-2 items-center ">
                  <input
                    placeholder="ระบุชื่อ - นามสกุล"
                    value={guarantorNameOne}
                    onChange={onChangeGuarantorNameOne}
                    className={`${STYLE_INPUT_CARD_BALANCE} ml-1`}
                  />
                  <input
                    placeholder="ระบุความสัมพันธ์"
                    value={guarantorRelationshipOne}
                    onChange={onChangeGuarantorRelationshipOne}
                    className={`${STYLE_INPUT_CARD_BALANCE} ml-1`}
                  />
                </div>
              </div>
              <div className=" flex items-center mt-2.5 ">
                <div className={STYLE_TITLE_CARD_DRAWER_BL}>2.</div>
                <div className=" grid grid-cols-2 items-center ">
                  <input
                    placeholder=" ระบุชื่อ - นามสกุล"
                    value={guarantorNameTwo}
                    onChange={onChangeGuarantorNameTwo}
                    className={`${STYLE_INPUT_CARD_BALANCE} ml-1`}
                  />
                  <input
                    placeholder="ระบุความสัมพันธ์"
                    value={guarantorRelationshipTwo}
                    onChange={onChangeGuarantorRelationshipTwo}
                    className={`${STYLE_INPUT_CARD_BALANCE} ml-1`}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={`${STYLE_PARENT_CARD_BALANCE} mt-3`}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2  `}>
              สถานภาพของหนี้
            </div>
            <div className="col-span-3">
              <div className=" -mt-3">
                <DropDownMain
                  placeholder={`สถานภาพของหนี้`}
                  className={`border border-gray60 rounded-full mt-3`}
                  value={options_debt?.filter((option) => {
                    if (option?.value === status) return option?.label;
                  })}
                  onChange={onChangeStatus}
                  options={options_debt}
                  zIndex={2}
                />
              </div>
            </div>
          </div>

          <div className=" flex justify-center items-center mt-3">
            ปีละ{" "}
            <span className="font-semibold text-center text-maincyan ml-1">
              {numeral(payPeryear).format("0,0")}
            </span>
          </div>
        </div>
      ) : isPlace ? (
        //---------- Place ----------//
        <div className=" bg-white rounded-[20px] px-4 pt-3 pb-[14px] mt-4 ">
          <ICON_DELETE
            className=" w-5 h-5 ml-auto cursor-pointer"
            onClick={onClickDelete}
          />

          <div className={` `}>
            <div className={STYLE_TITLE_CARD_DRAWER_BL}>ที่อยู่</div>
            <textarea
              className={`${STYLE_TEXTAREA_CARD_BALANCE} w-full mt-1`}
              value={addressPlace}
              onChange={onChangeAddressPlace}
              placeholder={placeholder}
            />
          </div>

          <div className=" w-full">
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} `}> ประเภท </div>
            <DropDownMain
              value={optionsDropdown?.filter((item) => {
                if (item.value === typePlace) return item.label;
              })}
              onChange={onChangeDropdown}
              options={optionsDropdown}
              zIndex={1}
              border=""
              height={25}
            />
            {typePlace === "type_place_other" && (
              <div className=" w-full mt-1.5">
                <textarea
                  className={`${STYLE_TEXTAREA_CARD_BALANCE} w-full`}
                  placeholder="โปรดระบุ"
                  value={typePlaceOther}
                  onChange={onChangeTypeOther}
                />
              </div>
            )}
          </div>

          <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
              มูลค่าที่อยู่ ณ ปัจจุบัน
            </div>
            <NumbericValidateRed
              value={currentPrice}
              onChange={onChangeCurrentPrice}
              isSubmited={isSubmited}
              shouldValdiate={true}
            />
          </div>

          {/*---------- ใช้สินทรัพย์ค้ำประกัน ---------*/}
          <div className={``}>
            <div className={` flex items-center  `}>
              <CheckBoxSecond
                isRight={true}
                label_title={`ใช้สินทรัพย์ค้ำประกัน`}
                name={`collateral_assets_${keyIndex}_${indexCard}`}
                onClick={onChangeCollateralAssets}
                checked={collateralAssets}
              />
            </div>
            {collateralAssets && (
              <div className="bg-gray30 p-[5px] rounded-[8px]">
                <div className={STYLE_PARENT_CARD_BALANCE}>
                  <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
                    ข้อมูลสินทรัพย์
                  </div>
                  <input
                    placeholder="ระบุ"
                    value={collateralAssetData}
                    onChange={onChangeCollateralAssetData}
                    className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
                  />
                </div>
                <div className=" grid grid-cols-5 my-2 ">
                  <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2  `}>
                    มูลค่าสินทรัพย์ค้ำประกัน
                  </div>
                  <NumericFormat
                    maxLength={11}
                    placeholder="0"
                    value={collateralAssetValue}
                    onChange={onChangeCollateralAssetValue}
                    thousandSeparator=","
                    inputMode="decimal"
                    pattern="[0-9],*"
                    className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
                  />
                </div>
              </div>
            )}
          </div>

          {/*---------- ใช้ส่วนตัว หรือลงทุน ----------*/}
          <div
            onChange={onChangePersonalOrInvestment}
            className="flex items-center mb-3 space-x-6"
          >
            <CheckBoxSecond
              isLeft={true}
              name={`${keyIndex}_${indexCard}`}
              label_title={`ใช้ส่วนตัว`}
              value={`personal`}
              checked={personalOrInvestment === "personal" ? true : false}
            />
            <CheckBoxSecond
              isLeft={true}
              name={`${keyIndex}_${indexCard}`}
              label_title={`ลงทุน`}
              value={`investment`}
              checked={personalOrInvestment === "investment" ? true : false}
            />
          </div>

          <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
              เจ้าหนี้
            </div>
            <input
              placeholder="ระบุ"
              value={creditorName}
              onChange={onChangeCreditName}
              className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
            />
          </div>

          <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
              จำนวนเงินต้น
            </div>
            <NumericFormat
              maxLength={11}
              placeholder="0"
              value={startAmount}
              onChange={onChangeStartAmount}
              thousandSeparator=","
              inputMode="decimal"
              pattern="[0-9],*"
              className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
            />
          </div>

          <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
              อัตราดอกเบี้ยเงินกู้ต่อปี (%)
            </div>
            <NumbericValidateRed
              value={interest}
              onChange={onChangeInterest}
              isSubmited={isSubmited}
              shouldValdiate={true}
            />
          </div>

          <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
              จำนวนเงินค้างชำระ
            </div>
            <NumbericValidateRed
              value={unpaidAmount}
              onChange={onChangeUnpaidAmount}
              isSubmited={isSubmited}
              shouldValdiate={true}
            />
          </div>

          <div className={cn(STYLE_PARENT_CARD_BALANCE, "")}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
              ระยะเวลา (ปี)
            </div>
            <NumbericValidateRed
              value={period}
              onChange={onChangePeriod}
              isSubmited={isSubmited}
              shouldValdiate={true}
            />
          </div>

          <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2`}>
              ชำระเดือนละ
            </div>
            <NumericFormat
              maxLength={11}
              placeholder="0"
              value={payPermonth}
              onChange={onChangePayPerMonth}
              thousandSeparator=","
              inputMode="decimal"
              pattern="[0-9],*"
              disabled
              className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
            />
          </div>

          {/* <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
              จำนวนงวดคงค้าง
            </div>
            <div className="col-span-3 ml-1 font-semibold text-maincyan text-sm">
              <div className="flex items-center gap-1.5">
                <div>{numeral(period).format("0,0")}</div>
                <div>งวด</div>
                <div>({convertPeriodToYearsMonths(period)})</div>
              </div>
            </div>
          </div> */}

          {/*---- ผู้ค้ำประกัน ----*/}
          <div className=" mt-2.5">
            <div className={STYLE_TITLE_CARD_DRAWER_BL}> ผู้ค้ำประกัน</div>
            <div className=" bg-maincyan/10 grid grid-cols-2 rounded-[5px] p-[5px] mt-[5px] h-[30px] items-center">
              <div className={`${STYLE_TITLE_CARD_DRAWER_BL} `}>ชื่อ</div>
              <div className={`${STYLE_TITLE_CARD_DRAWER_BL} ml-3`}>
                ความสัมพันธ์
              </div>
            </div>
            <div className="flex flex-col gap-2.5 bg-[#f9f9f9] p-2 rounded-[5px]">
              <div className=" flex items-center ">
                <div className={STYLE_TITLE_CARD_DRAWER_BL}>1.</div>
                <div className=" grid grid-cols-2 items-center ">
                  <input
                    placeholder="ระบุชื่อ - นามสกุล"
                    value={guarantorNameOne}
                    onChange={onChangeGuarantorNameOne}
                    className={`${STYLE_INPUT_CARD_BALANCE} ml-1`}
                  />
                  <input
                    placeholder="ระบุความสัมพันธ์"
                    value={guarantorRelationshipOne}
                    onChange={onChangeGuarantorRelationshipOne}
                    className={`${STYLE_INPUT_CARD_BALANCE} ml-1`}
                  />
                </div>
              </div>
              <div className=" flex items-center mt-2.5 ">
                <div className={STYLE_TITLE_CARD_DRAWER_BL}>2.</div>
                <div className=" grid grid-cols-2 items-center ">
                  <input
                    placeholder=" ระบุชื่อ - นามสกุล"
                    value={guarantorNameTwo}
                    onChange={onChangeGuarantorNameTwo}
                    className={`${STYLE_INPUT_CARD_BALANCE} ml-1`}
                  />
                  <input
                    placeholder="ระบุความสัมพันธ์"
                    value={guarantorRelationshipTwo}
                    onChange={onChangeGuarantorRelationshipTwo}
                    className={`${STYLE_INPUT_CARD_BALANCE} ml-1`}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={`${STYLE_PARENT_CARD_BALANCE} mt-3`}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2  `}>
              สถานภาพของหนี้
            </div>
            <div className="col-span-3">
              <div className=" -mt-3">
                <DropDownMain
                  placeholder={`สถานภาพของหนี้`}
                  className={`border border-gray60 rounded-full mt-3`}
                  value={options_debt?.filter((option) => {
                    if (option?.value === status) return option?.label;
                  })}
                  onChange={onChangeStatus}
                  options={options_debt}
                  zIndex={2}
                />
              </div>
            </div>
          </div>

          <div className=" flex justify-center items-center mt-3">
            ปีละ{" "}
            <span className="font-semibold text-center text-maincyan ml-1">
              {numeral(payPeryear).format("0,0")}
            </span>
          </div>
        </div>
      ) : (
        //---------- Long Other ----------//
        <div className=" bg-white rounded-[20px] px-4 pt-3 pb-[14px] mt-4 ">
          <div className={`flex flex-col gap-0.5`}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} `}>
              ชื่อหนี้สินระยะยาวอื่น ๆ
            </div>
            <div className=" flex items-center w-full">
              <input
                className={`${STYLE_INPUT_CARD_BALANCE} h-5 rounded-[4px] w-full`}
                value={title}
                onChange={onChangeTitle}
                placeholder={placeholder}
              />
              <ICON_DELETE
                className=" w-5 h-5 ml-auto cursor-pointer"
                onClick={onClickDelete}
              />
            </div>
          </div>

          <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
              มูลค่าสินทรัพย์ ณ ปัจจุบัน
            </div>
            <NumbericValidateRed
              value={currentPrice}
              onChange={onChangeCurrentPrice}
              isSubmited={isSubmited}
              shouldValdiate={true}
            />
          </div>

          {/*---- ใช้สินทรัพย์ค้ำประกัน ----*/}
          <div className={``}>
            <div className={` flex items-center ml-2`}>
              <CheckBoxSecond
                isRight={true}
                label_title={`ใช้สินทรัพย์ค้ำประกัน`}
                name={`collateral_assets_${keyIndex}_${indexCard}`}
                onClick={onChangeCollateralAssets}
                checked={collateralAssets}
              />
            </div>
            {collateralAssets && (
              <div className="bg-gray30 p-[5px] rounded-[8px]">
                <div className={STYLE_PARENT_CARD_BALANCE}>
                  <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
                    ข้อมูลสินทรัพย์
                  </div>
                  <input
                    placeholder="ระบุ"
                    value={collateralAssetData}
                    onChange={onChangeCollateralAssetData}
                    className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
                  />
                </div>
                <div className=" grid grid-cols-5 my-2 ">
                  <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2  `}>
                    มูลค่าสินทรัพย์ค้ำประกัน
                  </div>
                  <NumericFormat
                    maxLength={11}
                    placeholder="0"
                    value={collateralAssetValue}
                    onChange={onChangeCollateralAssetValue}
                    thousandSeparator=","
                    inputMode="decimal"
                    pattern="[0-9],*"
                    className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
                  />
                </div>
              </div>
            )}
          </div>

          {/*---------- ใช้ส่วนตัว หรือลงทุน ----------*/}
          <div
            onChange={onChangePersonalOrInvestment}
            className="flex items-center mb-3 space-x-6"
          >
            <CheckBoxSecond
              isLeft={true}
              name={`${keyIndex}_${indexCard}`}
              label_title={`ใช้ส่วนตัว`}
              value={`personal`}
              checked={personalOrInvestment === "personal" ? true : false}
            />
            <CheckBoxSecond
              isLeft={true}
              name={`${keyIndex}_${indexCard}`}
              label_title={`ลงทุน`}
              value={`investment`}
              checked={personalOrInvestment === "investment" ? true : false}
            />
          </div>

          <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
              เจ้าหนี้
            </div>
            <input
              placeholder="ระบุ"
              value={creditorName}
              onChange={onChangeCreditName}
              className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
            />
          </div>

          <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
              จำนวนเงินต้น
            </div>
            <NumericFormat
              maxLength={11}
              placeholder="0"
              value={startAmount}
              onChange={onChangeStartAmount}
              thousandSeparator=","
              inputMode="decimal"
              pattern="[0-9],*"
              className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
            />
          </div>

          {/* <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2  `}>
              จำนวนเงินต้นค้างชำระ
            </div>
            <NumericFormat
              maxLength={11}
              value={startUnpaidAmount}
              placeholder="0"
              onChange={onChangeStartUnpaidAmount}
              thousandSeparator=","
              inputMode="decimal"
              pattern="[0-9],*"
              className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
            />
          </div> */}

          <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
              อัตราดอกเบี้ยเงินกู้ต่อปี (%)
            </div>
            <NumbericValidateRed
              value={interest}
              onChange={onChangeInterest}
              isSubmited={isSubmited}
              shouldValdiate={true}
            />
          </div>

          <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
              จำนวนเงินค้างชำระ
            </div>
            <NumbericValidateRed
              value={unpaidAmount}
              onChange={onChangeUnpaidAmount}
              isSubmited={isSubmited}
              shouldValdiate={true}
            />
          </div>

          <div className={cn(STYLE_PARENT_CARD_BALANCE, "")}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
              ระยะเวลา (ปี)
            </div>
            <NumbericValidateRed
              value={period}
              onChange={onChangePeriod}
              isSubmited={isSubmited}
              shouldValdiate={true}
            />
          </div>

          <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2`}>
              ชำระเดือนละ
            </div>
            <NumericFormat
              maxLength={11}
              placeholder="0"
              value={payPermonth}
              onChange={onChangePayPerMonth}
              thousandSeparator=","
              inputMode="decimal"
              pattern="[0-9],*"
              disabled
              className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
            />
          </div>
          <div className={STYLE_PARENT_CARD_BALANCE}>
            {/* <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
              จำนวนงวดคงค้าง
            </div>
            <div className="col-span-3 ml-1 font-semibold text-maincyan text-sm">
              <div className="flex items-center gap-1.5">
                <div>{numeral(period).format("0,0")}</div>
                <div>งวด</div>
                <div>({convertPeriodToYearsMonths(period * 12)})</div>
              </div>
            </div> */}
          </div>

          <div className={`${STYLE_PARENT_CARD_BALANCE} mt-3`}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2  `}>
              สถานภาพของหนี้
            </div>
            <div className="col-span-3">
              <div className=" -mt-3">
                <DropDownMain
                  placeholder={`สถานภาพของหนี้`}
                  className={`border border-gray60 rounded-full mt-3`}
                  value={options_debt?.filter((option) => {
                    if (option?.value === status) return option?.label;
                  })}
                  onChange={onChangeStatus}
                  options={options_debt}
                  zIndex={2}
                />
              </div>
            </div>
          </div>

          <div className=" flex justify-center items-center mt-3">
            ปีละ{" "}
            <span className="font-semibold text-center text-maincyan ml-1">
              {numeral(payPeryear).format("0,0")}
            </span>
          </div>
        </div>
      )}
    </>
  );
};

//------------------------------------------------ Card สินทรัพย์ ------------------------------------------------//
export const CardPropertyAsset = ({
  keyIndex = "",
  indexCard = "",
  placeholder = "",
  onClickDelete,

  isDebtFree = false,
  isCar = false,
  isHome = false,
  head = "",
  head2 = "",
  isPropertyPrivate = false,
  isFundDebt = false,
  title = "",
  onChangeTitle,
  accountNumber = "",
  onChangeAccountNumber,
  amount,
  onChangeAmount,
  titleFund = "",
  onChangeTitleFund,
  isCheckedDebtFree = false,
  handleCheckboxChange,
  registration = "",
  onChangeRegistration,
  address = "",
  onChangeAddress,
  typePlace = "",
  handleCheckboxChangeType,
  addressOther = "",
  onChangeAddressOther,
}) => {
  return (
    <>
      {!isPropertyPrivate ? (
        <div className=" bg-white rounded-[20px] px-4 pt-3 pb-[14px] mt-4 ">
          {isFundDebt ? (
            <>
              <ICON_DELETE
                className=" w-5 h-5 ml-auto cursor-pointer"
                onClick={onClickDelete}
              />
              <div className={STYLE_PARENT_CARD_BALANCE}>
                <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2  `}>
                  ระบุชื่อผู้ให้บริการ
                </div>
                <InputMain
                  placeholder="ระบุ"
                  className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1  `}
                  value={title}
                  onChange={onChangeTitle}
                />
              </div>
            </>
          ) : (
            <div className=" flex items-center gap-1">
              <div className=" w-full">
                <div className={`${STYLE_TITLE_CARD_DRAWER_BL}  `}>{head}</div>
                <input
                  placeholder={placeholder}
                  className={`${STYLE_INPUT_CARD_BALANCE} h-5 rounded-[4px] w-full `}
                  value={title}
                  onChange={onChangeTitle}
                />
              </div>

              <ICON_DELETE
                className=" w-5 h-5 ml-auto cursor-pointer mt-3"
                onClick={onClickDelete}
              />
            </div>
          )}

          {/*---------- ชื่อกองทุน / เลขที่บัญชีธนาคาร ----------*/}
          {isFundDebt ? (
            <div className={STYLE_PARENT_CARD_BALANCE}>
              <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2  `}>
                ระบุชื่อกองทุน
              </div>
              <input
                placeholder={`ระบุ`}
                className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1 `}
                value={titleFund}
                onChange={onChangeTitleFund}
              />
            </div>
          ) : (
            <div className={STYLE_PARENT_CARD_BALANCE}>
              <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2  `}>
                เลขที่บัญชีธนาคาร
              </div>
              <input
                placeholder={`ระบุ`}
                className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1 `}
                value={accountNumber}
                onChange={onChangeAccountNumber}
              />
            </div>
          )}

          <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2  `}>
              จำนวนเงิน
            </div>
            <NumericFormat
              maxLength={11}
              value={amount}
              placeholder="0"
              onChange={onChangeAmount}
              thousandSeparator=","
              inputMode="decimal"
              pattern="[0-9],*"
              className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1  `}
            />
          </div>
        </div>
      ) : (
        // สินทรัพย์ใช้ส่วนตัว และ สินทรัพย์ไม่มีตัวตน
        <div className=" bg-white rounded-[20px] px-4 pt-3 pb-[14px] mt-4 ">
          {isHome ? (
            <div>
              <ICON_DELETE
                className=" w-5 h-5 ml-auto cursor-pointer mt-3"
                onClick={onClickDelete}
              />
              <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 mb-1`}>
                {head}
              </div>
              <textarea
                className={`${STYLE_TEXTAREA_CARD_BALANCE} w-full`}
                placeholder={placeholder}
                value={address}
                onChange={onChangeAddress}
              />
              <div className=" flex ">
                <div className={`${STYLE_TITLE_CARD_DRAWER_BL}`}>ประเภท</div>
                <div
                  onChange={handleCheckboxChangeType}
                  className=" flex justify-end -mt-3 w-full "
                >
                  <CheckBoxSecond
                    isRight={true}
                    label_title={"บ้าน"}
                    name={`place_${keyIndex}_${indexCard}`}
                    value={`home`}
                    checked={typePlace === "home" ? true : false}
                  />
                  <CheckBoxSecond
                    isRight={true}
                    label_title={"คอนโด"}
                    name={`place_${keyIndex}_${indexCard}`}
                    value={`Condo`}
                    checked={typePlace === "Condo" ? true : false}
                  />
                  <CheckBoxSecond
                    isRight={true}
                    label_title={"อื่น ๆ"}
                    name={`place_${keyIndex}_${indexCard}`}
                    value={`other`}
                    checked={typePlace === "other" ? true : false}
                  />
                </div>
              </div>

              {typePlace === "other" && (
                <textarea
                  className={`${STYLE_TEXTAREA_CARD_BALANCE} w-full mt-3`}
                  placeholder="โปรดระบุ"
                  value={addressOther}
                  onChange={onChangeAddressOther}
                />
              )}
            </div>
          ) : (
            <div className=" flex items-center gap-1">
              <div className=" w-full">
                <div className={`${STYLE_TITLE_CARD_DRAWER_BL}  `}>{head}</div>
                <input
                  placeholder={placeholder}
                  className={`${STYLE_INPUT_CARD_BALANCE} h-5 rounded-[4px] w-full `}
                  value={title}
                  onChange={onChangeTitle}
                />
              </div>

              <ICON_DELETE
                className=" w-5 h-5 ml-auto cursor-pointer mt-3"
                onClick={onClickDelete}
              />
            </div>
          )}

          {isCar && (
            <div className={STYLE_PARENT_CARD_BALANCE}>
              <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2  `}>
                ทะเบียนรถ
              </div>
              <InputMain
                placeholder="ระบุ"
                className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 `}
                value={registration}
                onChange={onChangeRegistration}
              />
            </div>
          )}

          <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2  `}>
              {head2}
            </div>
            <NumericFormat
              maxLength={11}
              value={amount}
              placeholder="0"
              onChange={onChangeAmount}
              thousandSeparator=","
              inputMode="decimal"
              pattern="[0-9],*"
              className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1  `}
            />
          </div>

          {isDebtFree && (
            <div className=" ml-2">
              <CheckBoxSecond
                name={`debt_free_${keyIndex}_${indexCard}`}
                isRight={true}
                label_title={"ปลอดภาระหนี้"}
                checked={isCheckedDebtFree}
                onClick={handleCheckboxChange}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

//------------------------------------------------ Card ค่าใช้จ่าย ------------------------------------------------//
export const CardExpenses = ({
  onClickDelete,
  keyIndex,
  indexCard,
  head = "",
  placeholder = "",
  isFormOne = false,
  isFormTwo = false,
  isCar = false,
  hasDropdown,

  onChangePolicySurrenderValue,
  onChangeStatusAIAVitlaity,
  onChangeAIAMutiplayCIMore,
  onChangeAIATotalCareMore,
  onChangeTypeOfPayment,
  onChangeTotalYear,
  onChangeContractualRefund,

  title = "",
  onChangeTitle,
  insurancePolicyType = "",
  onChangeInsurancePolicyType,
  insurancePolicyNumber = "",
  onChangeInsurancePolicyNumber,
  insurancePolicyName = "",
  onChangeInsurancePolicyName,
  insurancePolicyDate,
  onChangeInsurancePolicyDate,
  insurancePolicyDateMakecompact,
  onChangeInsurancePolicyDateMakecompact,
  insurancePolicyDateFinishcompact,
  onChangeInsurancePolicyDateFinishcompact,
  allowancePeryear,
  onChangeAllowancePeryear,
  insuranceFund,
  onChangeInsuranceFund,
  insurancePolicyCashSurrender,
  onChangeInsurancePolicyCashSurrender,
  moreDetails,
  onChangeLifeInsurance,
  onChangeLifeAllowance,
  onChangeExceptAllowanceWP,
  onChangeExceptAllowancePB,
  onChangeContractTPD,
  onChangeProtectionLifeLT,
  onChangeExceptAllowanceLT,
  onChangeAccidentAI,
  onChangeAccidentADB,
  onChangeAccidentADD,
  onChangeHospitalTreatmentSurgery,
  onChangeHospitalTreatmentSurgeryLumpSumPayment,
  onChangeDailyCompensationForTreatment,
  onChangeDailyCompensationForTreatmentSpecial,
  onChangeCompensationForCancerTreatmentCR,
  onChangeCompensationECIR,
  onChangeCompensationAIASupercare,
  onChangeCompensationAIACIPlusGold,
  onChangeCompensationAIACIPlus,
  onChangeCompensationMutipleCareCi,
  onChangeCriticalIllnessInsuranceYearlyCi,
  onChangeMedicalExpensesAccidentPA,
  onChangeAllowanceLastTime,
  onChangeDatePayAllowanceLastTime,
  onChangeDateDueAllowanceNextTime,
  onChangeDateDueAllowanceFinalTime,
  onChangeAnnotation,

  //ผู้รับผลประโยชน์
  beneficiaryNameOne = "",
  onChangeBeneficiaryNameOne,
  beneficiaryNameTwo = "",
  onChangeBeneficiaryNameTwo,
  beneficiaryRelationshipOne = "",
  onChangeBeneficiaryRelationshipOne,
  beneficiaryRelationshipTwo = "",
  onChangeBeneficiaryRelationshipTwo,

  registrationCar,
  onChangeRegistrationCar,

  //ประเภท
  valueDropdown,
  onChangeDropdown,
  optionsDropdown,

  //รายละเอียดเพิ่มเติม
  etc = false,
  open,
  onClickOpen,
}) => {
  return (
    <>
      {isFormOne && (
        <div className=" bg-white rounded-[20px] px-4 pt-[6px] pb-[14px] mt-4 ">
          <div className=" flex justify-between items-center">
            <div className=" flex gap-[6px] items-end w-full">
              <div>
                <label className={`${STYLE_TITLE_CARD_DRAWER_BL}`}>
                  {" "}
                  {head}{" "}
                </label>
                <input
                  className={`${STYLE_INPUT_CARD_BALANCE} h-5 rounded-[4px] w-full`}
                  value={title}
                  onChange={onChangeTitle}
                  placeholder={placeholder}
                />
              </div>

              <ICON_DELETE
                className=" w-6 h-6 mb-1.5 text-maincyan cursor-pointer"
                onClick={onClickDelete}
              />
            </div>
          </div>

          <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
              กรมธรรม์ประเภท
            </div>
            <input
              placeholder="ระบุ"
              value={insurancePolicyType}
              onChange={onChangeInsurancePolicyType}
              className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
            />
          </div>

          <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
              กรมธรรม์เลขที่
            </div>
            <input
              // type="number"
              placeholder="0"
              value={insurancePolicyNumber}
              onChange={onChangeInsurancePolicyNumber}
              className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
            />
          </div>

          <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
              ชื่อกรมธรรม์
            </div>
            <input
              placeholder="ระบุ"
              value={insurancePolicyName}
              onChange={onChangeInsurancePolicyName}
              className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
            />
          </div>

          <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
              วันที่ของกรมธรรม์ประกันภัย
            </div>
            <div className="col-span-3">
              <DatePicker
                selected={
                  insurancePolicyDate ? new Date(insurancePolicyDate) : ""
                }
                locale={th}
                dateFormat="dd/MM/yyyy"
                renderCustomHeader={CustomInputBirthdayHearder}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                wrapperClassName="w-full"
                customInput={<CustomInputBirthday />}
                // showTimeSelect
                onChange={onChangeInsurancePolicyDate}
              />
            </div>
          </div>

          <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
              วันทำสัญญาประกันภัย
            </div>
            <div className="col-span-3">
              <DatePicker
                selected={
                  insurancePolicyDateMakecompact
                    ? new Date(insurancePolicyDateMakecompact)
                    : ""
                }
                locale={th}
                dateFormat="dd/MM/yyyy"
                renderCustomHeader={CustomInputBirthdayHearder}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                wrapperClassName="w-full"
                customInput={<CustomInputBirthday />}
                // showTimeSelect
                onChange={onChangeInsurancePolicyDateMakecompact}
              />
            </div>
          </div>

          <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
              วันครบกำหนดสัญญาประกันภัย
            </div>
            <div className="col-span-3">
              <DatePicker
                selected={
                  insurancePolicyDateFinishcompact
                    ? new Date(insurancePolicyDateFinishcompact)
                    : ""
                }
                locale={th}
                dateFormat="dd/MM/yyyy"
                renderCustomHeader={CustomInputBirthdayHearder}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                wrapperClassName="w-full"
                customInput={<CustomInputBirthday />}
                // showTimeSelect
                onChange={onChangeInsurancePolicyDateFinishcompact}
              />
            </div>
          </div>

          <div className=" mt-2.5">
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL}  `}>
              สถานะกรมธรรม์
            </div>
            <DropDownMain
              placeholder="กรุณาเลือกสถานะกรมธรรม์"
              value={optionsDropdown?.filter((item) => {
                if (item.value === valueDropdown) return item.label;
              })}
              onChange={onChangeDropdown}
              options={optionsDropdown}
              zIndex={1}
              border=""
              height={25}
            />
          </div>

          <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
              ค่าเบี้ย (ต่อปี)
            </div>
            <NumericFormat
              maxLength={11}
              placeholder="0"
              value={allowancePeryear}
              onChange={onChangeAllowancePeryear}
              thousandSeparator=","
              inputMode="decimal"
              pattern="[0-9],*"
              className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
            />
          </div>

          <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
              ทุนประกัน
            </div>
            <NumericFormat
              maxLength={11}
              placeholder="0"
              value={insuranceFund}
              onChange={onChangeInsuranceFund}
              thousandSeparator=","
              inputMode="decimal"
              pattern="[0-9],*"
              className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
            />
          </div>

          <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
              มูลค่าเวนคืน
            </div>
            <NumericFormat
              maxLength={11}
              placeholder="0"
              value={insurancePolicyCashSurrender}
              onChange={onChangeInsurancePolicyCashSurrender}
              thousandSeparator=","
              inputMode="decimal"
              pattern="[0-9],*"
              className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
            />
          </div>

          {etc && (
            <div>
              <div className={STYLE_PARENT_CARD_BALANCE}>
                <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-3 `}>
                  กรอกรายละเอียดเพิ่มเติม
                </div>
                <ICON_ARROW_UP_DOWN
                  className={` w-6 h-6 col-span-2 ml-auto ${
                    open ? "rotate-180" : "rotate-0"
                  }`}
                  onClick={onClickOpen}
                />
                <div
                  className={` ${
                    open ? " h-full " : " h-0  overflow-hidden"
                  } col-span-full  transition-all duration-300 `}
                >
                  {open && (
                    <div className="bg-gray50 p-2 mt-1 rounded-[5px] col-span-full ">
                      <FormExpenseFixed
                        form={moreDetails}
                        onChangeContractualRefund={onChangeContractualRefund}
                        onChangePolicySurrenderValue={onChangePolicySurrenderValue}
                        onChangeStatusAIAVitlaity={onChangeStatusAIAVitlaity}
                        onChangeTotalYear={onChangeTotalYear}
                        onChangeTypeOfPayment={onChangeTypeOfPayment}
                        onChangeAIATotalCareMore={onChangeAIATotalCareMore}
                        onChangeAIAMutiplayCIMore={onChangeAIAMutiplayCIMore}
                        onChangeLifeInsurance={onChangeLifeInsurance}
                        onChangeLifeAllowance={onChangeLifeAllowance}
                        onChangeExceptAllowanceWP={onChangeExceptAllowanceWP}
                        onChangeExceptAllowancePB={onChangeExceptAllowancePB}
                        onChangeContractTPD={onChangeContractTPD}
                        onChangeProtectionLifeLT={onChangeProtectionLifeLT}
                        onChangeExceptAllowanceLT={onChangeExceptAllowanceLT}
                        onChangeAccidentAI={onChangeAccidentAI}
                        onChangeAccidentADB={onChangeAccidentADB}
                        onChangeAccidentADD={onChangeAccidentADD}
                        onChangeHospitalTreatmentSurgery={
                          onChangeHospitalTreatmentSurgery
                        }
                        onChangeHospitalTreatmentSurgeryLumpSumPayment={
                          onChangeHospitalTreatmentSurgeryLumpSumPayment
                        }
                        onChangeDailyCompensationForTreatment={
                          onChangeDailyCompensationForTreatment
                        }
                        onChangeDailyCompensationForTreatmentSpecial={
                          onChangeDailyCompensationForTreatmentSpecial
                        }
                        onChangeCompensationForCancerTreatmentCR={
                          onChangeCompensationForCancerTreatmentCR
                        }
                        onChangeCompensationECIR={onChangeCompensationECIR}
                        onChangeCompensationAIASupercare={
                          onChangeCompensationAIASupercare
                        }
                        onChangeCompensationAIACIPlusGold={
                          onChangeCompensationAIACIPlusGold
                        }
                        onChangeCompensationAIACIPlus={
                          onChangeCompensationAIACIPlus
                        }
                        onChangeCompensationMutipleCareCi={
                          onChangeCompensationMutipleCareCi
                        }
                        onChangeCriticalIllnessInsuranceYearlyCi={
                          onChangeCriticalIllnessInsuranceYearlyCi
                        }
                        onChangeMedicalExpensesAccidentPA={
                          onChangeMedicalExpensesAccidentPA
                        }
                        onChangeAllowanceLastTime={onChangeAllowanceLastTime}
                        onChangeDatePayAllowanceLastTime={
                          onChangeDatePayAllowanceLastTime
                        }
                        onChangeDateDueAllowanceNextTime={
                          onChangeDateDueAllowanceNextTime
                        }
                        onChangeDateDueAllowanceFinalTime={
                          onChangeDateDueAllowanceFinalTime
                        }
                        onChangeBeneficiaryNameOne={onChangeBeneficiaryNameOne}
                        onChangeBeneficiaryNameTwo={onChangeBeneficiaryNameTwo}
                        onChangeBeneficiaryRelationshipOne={
                          onChangeBeneficiaryRelationshipOne
                        }
                        onChangeBeneficiaryRelationshipTwo={
                          onChangeBeneficiaryRelationshipTwo
                        }
                        onChangeAnnotation={onChangeAnnotation}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      {isFormTwo && (
        <div className=" bg-white rounded-[20px] px-4 pt-[6px] pb-[14px] mt-4 ">
          <div className=" flex justify-between items-center">
            <div className=" flex gap-[6px] items-end w-full">
              <div>
                <label className={`${STYLE_TITLE_CARD_DRAWER_BL}`}>
                  {head}
                </label>
                <input
                  className={`${STYLE_INPUT_CARD_BALANCE} h-5 rounded-[4px] w-full`}
                  value={title}
                  onChange={onChangeTitle}
                  placeholder={placeholder}
                />
              </div>
              <ICON_DELETE
                className=" w-6 h-6 mb-1.5 text-maincyan cursor-pointer"
                onClick={onClickDelete}
              />
            </div>
          </div>

          {isCar && (
            <div className={STYLE_PARENT_CARD_BALANCE}>
              <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
                ทะเบียนรถ
              </div>
              <input
                placeholder="ระบุ"
                value={registrationCar}
                onChange={onChangeRegistrationCar}
                className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
              />
            </div>
          )}
          {hasDropdown ? (
            <div className=" mt-2.5">
              <div className={`${STYLE_TITLE_CARD_DRAWER_BL}  `}>
                กรมธรรม์ประเภท
              </div>
              <DropDownMain
                placeholder="กรุณาเลือกประเภทกรมธรรม์"
                value={optionsDropdown?.filter((item) => {
                  if (item.value === valueDropdown) return item.label;
                })}
                onChange={onChangeDropdown}
                options={optionsDropdown}
                zIndex={1}
                border=""
                height={25}
              />
            </div>
          ) : (
            <div className={STYLE_PARENT_CARD_BALANCE}>
              <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
                กรมธรรม์ประเภท
              </div>
              <input
                placeholder="ระบุ"
                value={insurancePolicyType}
                onChange={onChangeInsurancePolicyType}
                className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
              />
            </div>
          )}
          <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
              กรมธรรม์เลขที่
            </div>
            <input
              placeholder="0"
              value={insurancePolicyNumber}
              onChange={onChangeInsurancePolicyNumber}
              className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
            />
          </div>
          <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
              ชื่อกรมธรรม์
            </div>
            <input
              placeholder="ระบุ"
              value={insurancePolicyName}
              onChange={onChangeInsurancePolicyName}
              className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
            />
          </div>
          <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
              วันทำสัญญาประกันภัย
            </div>
            <input
              type="date"
              placeholder="ระบุ"
              value={insurancePolicyDateMakecompact}
              onChange={onChangeInsurancePolicyDateMakecompact}
              className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
            />
          </div>
          <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
              วันครบกำหนดสัญญาประกันภัย
            </div>
            <input
              type="date"
              placeholder="ระบุ"
              value={insurancePolicyDateFinishcompact}
              onChange={onChangeInsurancePolicyDateFinishcompact}
              className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
            />
          </div>
          <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
              ทุนประกัน
            </div>
            <NumericFormat
              maxLength={11}
              value={insuranceFund}
              placeholder="0"
              onChange={onChangeInsuranceFund}
              thousandSeparator=","
              inputMode="decimal"
              pattern="[0-9],*"
              className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
            />
          </div>
          <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
              ค่าเบี้ยประกันภัย (ต่อปี)
            </div>
            <NumericFormat
              maxLength={11}
              value={allowancePeryear}
              placeholder="0"
              onChange={onChangeAllowancePeryear}
              thousandSeparator=","
              inputMode="decimal"
              pattern="[0-9],*"
              className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
            />
          </div>
          <CardTable
            head="ผู้รับผลประโยชน์"
            title_1="ชื่อ-นามสกุล"
            title_2="ความสัมพันธ์"
            name1={beneficiaryNameOne}
            name2={beneficiaryNameTwo}
            relationship1={beneficiaryRelationshipOne}
            relationship2={beneficiaryRelationshipTwo}
            onChangeName1={onChangeBeneficiaryNameOne}
            onChangeName2={onChangeBeneficiaryNameTwo}
            onChangeRelationship1={onChangeBeneficiaryRelationshipOne}
            onChangeRelationship2={onChangeBeneficiaryRelationshipTwo}
          />
        </div>
      )}
    </>
  );
};

export const CardExpensesInvest = ({
  value,
  onChange,
  valuePayPerYear,
  onClickDelete,
  etc = false,
  valueTitleServiceProvider = "",
  onChangeTitleSertviceProvider,
  valueTitleAccount = "",
  onChangeTitleAccount,
  valueEtc = "",
  onChangeEtc,
}) => {
  return (
    <>
      {etc ? (
        <div className={STYLE_PARENT_CARD_BALANCE}>
          <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2  `}>
            โปรดระบุ
          </div>
          <input
            placeholder="ระบุ"
            className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
            value={valueEtc}
            onChange={onChangeEtc}
          />
        </div>
      ) : (
        ""
      )}
      <div className={STYLE_PARENT_CARD_BALANCE}>
        <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2  `}>
          ระบุชื่อผู้ให้บริการ
        </div>
        <input
          placeholder="ระบุ"
          className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
          value={valueTitleServiceProvider}
          onChange={onChangeTitleSertviceProvider}
        />
      </div>
      <div className={STYLE_PARENT_CARD_BALANCE}>
        <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2  `}>
          ระบุชื่อบัญชี
        </div>
        <input
          placeholder="ระบุ"
          className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
          value={valueTitleAccount}
          onChange={onChangeTitleAccount}
        />

        {/* <ICON_DELETE
          className=" w-5 h-5 text-maincyan  flex-none "
          onClick={onClickDelete}
        /> */}
      </div>
      <div className={STYLE_PARENT_CARD_BALANCE}>
        <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2  `}>
          เดือนละ
        </div>
        <NumericFormat
          maxLength={11}
          value={value}
          placeholder="0"
          onChange={onChange}
          thousandSeparator=","
          inputMode="decimal"
          pattern="[0-9],*"
          className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
        />
      </div>

      <div className=" flex justify-center gap-2 items-center mt-2.5 ">
        <div className=" text-black ">ปีละ</div>
        <div className="text-maincyan text-center text-md font-semibold">
          {numeral(valuePayPerYear).format("0,0")}
        </div>
      </div>
    </>
  );
};

export const HeaderInvest = () => {
  return (
    <div className=" bg-maincyan/10 rounded-[10px] px-2.5 w-full h-10 grid grid-cols-3 text-center gap-1 items-center my-2.5">
      <div className="text-mainblue text-sm font-medium ">สินทรัพย์</div>
      <div className="text-mainblue text-sm font-medium ">มูลค่าปัจจุบัน</div>
      <div className="text-mainblue text-sm font-medium">
        ผลตอบแทน
        <div>ต่อปี (%)</div>
      </div>
    </div>
  );
};

export const SelectTargetInvest = ({ value, onChange, options }) => {
  return (
    <div className=" items-center mt-2">
      <div className="text-mainblue text-sm font-medium w-2/3">
        เป้าหมายการลงทุน
      </div>
      <DropDownMain
        value={value}
        onChange={onChange}
        options={options}
        placeholder="กรุณาเลือกเป้าหมายการลงทุน"
        className={` w-full`}
      />
    </div>
  );
};

export const CardMonthly = ({
  valuePayPerYear,
  value,
  onChange,
  onClickDelete,
}) => {
  return (
    <div>
      <div className=" flex items-center gap-1 mt-3 w-full ">
        <div className=" text-black text-sm flex-none ">เดือนละ</div>
        <NumericFormat
          maxLength={11}
          value={value}
          placeholder="5,000"
          onChange={onChange}
          thousandSeparator=","
          inputMode="decimal"
          pattern="[0-9],*"
          className={` rounded-full px-[14px] py-2 placeholder:text-gray80 text-mainblue text-sm font-semibold w-full focus:outline-none focus:ring-1 focus:ring-mainblue  `}
        />
      </div>
      <div className=" flex justify-center gap-2 items-center mt-2.5 ">
        <div className=" text-black ">ปีละ</div>
        <div className="text-maincyan text-center text-md font-semibold">
          {" "}
          {numeral(valuePayPerYear).format("0,0")}
        </div>
      </div>
    </div>
  );
};

export const CardYearly = ({ value, onChange, onClickDelete }) => {
  return (
    <div>
      <div className=" flex items-center gap-1 mt-3 w-full ">
        <div className=" text-black text-sm flex-none ">ปีละ</div>
        <NumericFormat
          maxLength={11}
          value={value}
          placeholder="5,000"
          onChange={onChange}
          thousandSeparator=","
          inputMode="decimal"
          pattern="[0-9],*"
          className={` rounded-full px-[14px] py-2 placeholder:text-gray80 text-mainblue text-sm font-semibold w-full focus:outline-none focus:ring-1 focus:ring-mainblue  `}
        />
      </div>
    </div>
  );
};
