import React from "react";
import { CheckTest } from "../styles/TestCard.Main";

const RiskPlanList = ({ formRisk, setMenu }) => {
  return (
    <div className=" flex flex-col gap-2.5 mt-4 px-[30px]">
      <CheckTest
        title={`กรณีเสียชีวิต`}
        status={formRisk?.dead_sum_score === 0 ? false : true}
        onClick={() => {
          setMenu("dead_case");
          window.scrollTo(0, 0);
        }}
      />
      <CheckTest
        title={`กรณีเจ็บป่วย`}
        status={formRisk?.sick_sum_score === 0 ? false : true}
        onClick={() => {
          setMenu("sick_case");
          window.scrollTo(0, 0);
        }}
      />
      <CheckTest
        title={`กรณีทุพพลภาพ`}
        status={formRisk?.disable_sum_score === 0 ? false : true}
        onClick={() => {
          setMenu("disable_case");
          window.scrollTo(0, 0);
        }}
      />
      <CheckTest
        title={`กรณีอุบัติเหตุ`}
        status={formRisk?.accident_sum_score === 0 ? false : true}
        onClick={() => {
          setMenu("accident_case");
          window.scrollTo(0, 0);
        }}
      />
      <CheckTest
        title={`กรณีอัคคีภัย`}
        status={formRisk?.fire_sum_score === 0 ? false : true}
        onClick={() => {
          setMenu("fire_case");
          window.scrollTo(0, 0);
        }}
      />
      <CheckTest
        title={`กรณีอุบัติเหตุทางรถยนต์`}
        status={formRisk?.car_accident_sum_score === 0 ? false : true}
        onClick={() => {
          setMenu("car_accident");
          window.scrollTo(0, 0);
        }}
      />
    </div>
  );
};

export default RiskPlanList;
