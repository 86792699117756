import React, { useState } from "react";
import { MTButton } from "../../components/button/MTButton";
import Footer from "../../components/layout/Footer";
import {
  ICON_EDIT_PENCIL,
  ICON_EYE,
  ICON_INFO,
  ICON_USER,
  LOGO_FACEBOOK,
  LOGO_GOOGLE,
} from "../../utils/useIcons";
import {
  STYLE_INPUT,
  STYLE_TEXT_BLUE,
  STYLE_TEXT_CYAN,
} from "../../utils/useStyle";
import {
  InputMain,
  InputNumberOTP,
  InputPasswordMain,
} from "../../components/input/InputMain";
import { PopupMain, PopupMain2 } from "../../components/popup/PopupMain";
import {
  signUp,
  confirmSignUp,
  resendSignUpCode,
  signInWithRedirect,
} from "aws-amplify/auth";
import ErrorMain from "../../components/error/Error.Main";
import { useGoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { decodeAccessToken } from "../../functions/main";
import axios from "axios";
import Api from "../../api/api.main";
import { useNavigate } from "react-router-dom";
import { useUserStore } from "../../_store/userStore";
import { useSavingStore } from "../../_store/savingStore";
import CheckBoxMain from "../../components/checkbox/CheckboxMain";
import { Checkbox } from "@material-tailwind/react";
import { set } from "lodash";
import { useRetireStore } from "../../_store/retireStore";
import { useTaxStore } from "../../_store/taxplanStore";
import { useRiskStore } from "../../_store/riskStore";
import { useBalanceStore } from "../../_store/balanceStore";
import { useStrategyStore } from "../../_store/strategyRetireStore";
import { useEducationStore } from "../../_store/educationStore";
import { useInsuranceStore } from "../../_store/insuranceStore";
import { useFamilyStore } from "../../_store/familyStore";
import { useFamilytreeStore } from "../../_store/familytreeStore";
import { useDebtStore } from "../../_store/debtplanStore";
import { useFuturePlanStore } from "../../_store/futurePlanStore";
import { useInvestStore } from "../../_store/investStore";

const LoginMain = () => {
  const navigate = useNavigate();
  // UserData
  const userData = useUserStore((state) => state.userData);
  const setUserData = useUserStore((state) => state.setUserData);

  // แผนการออม
  const savingData = useSavingStore((state) => state.savingData);
  const investData = useInvestStore((state) => state.investData);
  // const setSavingeData = useSavingStore((state) => state.setSavingeData);

  // mark
  // State Clear Data Plan
  const clearSavingData = useSavingStore((state) => state.clearSavingData);
  const clearRetireData = useRetireStore((state) => state?.clearRetireData);
  const clearTaxData = useTaxStore((state) => state?.clearTaxData);
  const clearRiskData = useRiskStore((state) => state.clearRiskData);
  const clearBalanceData = useBalanceStore((state) => state?.clearBalanceData);
  const clearInvestData = useInvestStore((state) => state.clearInvestData);

  const setStrategy = useStrategyStore((state) => state.setStrategy);
  const setRetireData = useRetireStore((state) => state.setRetireData);
  const setTaxData = useTaxStore((state) => state.setTaxData);
  const setSavingeData = useSavingStore((state) => state.setSavingeData);
  const setBalanceData = useBalanceStore((state) => state.setBalanceData);
  const setRiskData = useRiskStore((state) => state.setRiskData);
  const setEducationData = useEducationStore((state) => state.setEducationData);
  const setInsuranceData = useInsuranceStore((state) => state.setInsuranceData);
  // const familyData = useFamilyStore((state) => state);
  // const openData = useOpenStore((state) => state);
  const setFamilytreeData = useFamilytreeStore((state) => state.setFamilytreeData);
  const setDebtData = useDebtStore((state) => state.setDebtData);
  const setFuturePlanData = useFuturePlanStore((state) => state.setFuturePlanData);
  const setFormInvest = useInvestStore((state) => state.setInvestData);

  const [openAlert, setOpenAlert] = useState(false);
  const [openAlertPolicy, setOpenAlertPolicy] = useState(false);
  const [loading, setLoading] = useState(false);

  const [isChecked, setChecked] = useState(false);
  const handleCheckboxChange = () => {
    setChecked(!isChecked);
  };

  // mark
  const loginByGmail = useGoogleLogin({
    onSuccess: async (res) => {
      // ล้างข้อมูลบนเครื่องออกก่อน
      clearSavingData();
      clearRetireData();
      clearTaxData();
      clearRiskData();
      clearBalanceData();
      clearInvestData();
      try {
        setLoading(true);
        const { data: userInfo } = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: { Authorization: `Bearer ${res.access_token}` },
          }
        );

        const paramFilter = {
          filter: {
            email: userInfo.email,
          },
        };

        const { data: dataProfile } = await Api.get("/profiles", {
          params: paramFilter,
        });

        if (dataProfile?.profiles?.length > 0) {
          const profile = dataProfile?.profiles[0];

          const { data: dataStorage } = await Api.get(`/storages/profile/${profile?._id}`);

          if (dataStorage?.storage) {
            setStrategy(dataStorage?.storage?.strategy);
            setRetireData(dataStorage?.storage?.retireData);
            setTaxData(dataStorage?.storage?.taxData);
            setSavingeData(dataStorage?.storage?.savingData);
            setBalanceData(dataStorage?.storage?.balanceData);
            setRiskData(dataStorage?.storage?.riskData);
            setEducationData(dataStorage?.storage?.educationData);
            setInsuranceData(dataStorage?.storage?.insuranceData);
            setFamilytreeData(dataStorage?.storage?.familytreeData);
            setDebtData(dataStorage?.storage?.debtData);
            setFuturePlanData(dataStorage?.storage?.futurePlanData);
            setFormInvest(dataStorage?.storage?.investData || investData);
          }

          if (profile.first_login) {
            setLoading(false);
            navigate(`/firstlogin?email=${profile.email}`);
            return;
          } else {
            // navigate("/");
            setUserData(profile);

            //---- get SavingPlan by user id ----//
            // const { data: dataSavingPlan } = await Api.get(
            //   `/savingPlans?user_id=${0}`
            // );
            // setSavingeData(dataSavingPlan?.savingPlans);
            setLoading(false);
            setOpenAlert(true);
            return;
          }
        }

        await Api.post(`profiles`, {
          email: userInfo.email,
        });
        setLoading(false);
        navigate(`/firstlogin?email=${userInfo.email}`);
      } catch (error) {
        console.log("Error : ", error);
      }
    },
    scope: "profile email",
  });

  // onClick เข้าสู่ระบบ
  const onClickLoginByGmail = () => {
    if (!isChecked) {
      setOpenAlertPolicy(true);
      return;
    }
    loginByGmail();
  };

  return (
    <React.Fragment>
      <div className=" font-IBMPlexSansThai flex flex-col justify-between relative min-h-screen mx-auto max-w-3xl ">
        <div className=" relative pt-[105px] px-[32px]">
          <img
            src="/images/circle_login1.svg"
            className=" absolute top-0 right-0"
            alt=""
          />
          <div>
            {/* <img
              src="/images/logo_assure.png"
              className=" w-[93px] h-[37px]"
              alt=""
            /> */}
            <div className=" text-maincyan font-bold text-5xl leading-relaxed">
              เข้าสู่ระบบ
            </div>
          </div>
          <div className="flex flex-col items-center text-mainblue text-base mt-[86px] mb-[15px] text-center -mx-4">
            <div>ทำให้วางแผนการเงินเป็นเรื่องง่าย!</div>
            <div className="font-semibold">
              เข้าสู่ระบบเพื่อทำแผนการเงินของคุณให้เป็นจริงวันนี้
            </div>
          </div>
          <div className=" w-full flex flex-col gap-[15px] ">
            <MTButton
              loading={loading}
              iconleft={true}
              icon={<LOGO_GOOGLE className={` w-7 h-7`} />}
              text="เข้าสู่ระบบด้วย Gmail"
              color="border border-gray-300 "
              colortext=" text-center text-gray-400 group-hover:text-white"
              flexnone="flex-none"
              hovercolor="hover:bg-maincyan group "
              rounded="rounded-[10px]"
              width="w-full"
              onClick={onClickLoginByGmail}
            />
            {/* <MTButton
              iconleft={true}
              icon={<LOGO_FACEBOOK className={` w-6 h-6`} />}
              text="เข้าสู่ระบบด้วย Facebook"
              color="border border-gray-300 "
              colortext="w-full text-center text-gray-400 group-hover:text-white"
              flexnone="flex-none"
              hovercolor="hover:bg-maincyan group "
              rounded="rounded-[10px]"
              width="w-full"
              // onClick={() => {}}
            /> */}
            <div className="flex justify-center items-center text-base gap-1.5">
              <Checkbox
                type="checkbox"
                color="cyan"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <div className="text-maingray -ml-2">นโยบายความเป็นส่วนตัว</div>
              <button className="text-blue400  hover:font-semibold underline cursor-pointer">
                อ่านเพิ่มเติม
              </button>
            </div>
          </div>
          <PopupMain
            open={openAlert}
            text={`Login เรียบร้อย`}
            img={"/images/homeicon_smile_1.png"}
            onClick={() => {
              navigate("/");
              setOpenAlert(false);
            }}
          />
          <PopupMain
            open={openAlertPolicy}
            text={`กรุณา "ยอมรับ" นโยบายความเป็นส่วนตัวก่อน`}
            img={"/images/bad_face.png"}
            onClick={() => {
              setOpenAlertPolicy(false);
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

// ***ห้ามลบ แบบเก่า มี OTP //
//---------------------------------------------------------------------------------------------------------------//

// const LoginMain = () => {
//   const [stepPage, setStepPage] = useState(3);

//   const [loading, setLoading] = useState(false);

//   // state ที่ใช้ login หน้าแรก
//   const [mainPhoneNumber, setMainPhoneNumber] = useState("");
//   const [mainPassword, setMainPassword] = useState("");
//   const [mainPasswordShown, setMainPasswordShown] = useState(false);
//   const toggleMainPasswordShown = () => {
//     setMainPasswordShown(!mainPasswordShown);
//   };

//   // Form data login
//   const [formLogin, setFormLogin] = useState({
//     username: "",
//     password: "",
//     fullname: "",
//     birthday: "",
//     age: "",
//     sex: "",
//     email: "",
//     phone_number: "",
//   });

//   console.log("formLogin : ", formLogin);

//   // Validate FormLogin
//   const [errorForm, setErrorForm] = useState({
//     username: { isError: false, message: "" },
//     password: { isError: false, message: "" },
//     // recheckPassword: { isError: false, message: "" },
//     fullname: { isError: false, message: "" },
//     birthday: { isError: false, message: "" },
//     age: { isError: false, message: "" },
//     sex: { isError: false, message: "" },
//     email: { isError: false, message: "" },
//     phone_number: { isError: false, message: "" },
//   });

//   // State Recheck Email
//   const [recheckPassword, setRecheckPassword] = useState("");
//   const [openAlert, setOpenAlert] = useState(false);
//   const [openAlert2, setOpenAlert2] = useState(false);

//   // OTP
//   const [otp, setOtp] = useState("");

//   // Email
//   const onChangeEmail = (e) => {
//     let value = e.target.value;
//     setErrorForm({
//       ...errorForm,
//       email: formLogin.email ? false : true,
//     });
//     return setFormLogin({ ...formLogin, email: value });
//   };

//   // Password
//   const onChangePassword = (e) => {
//     let value = e.target.value;
//     setErrorForm({
//       ...errorForm,
//       password: formLogin.password ? false : true,
//     });
//     return setFormLogin({ ...formLogin, password: value });
//   };
//   const [passwordShown, setPasswordShown] = useState(false);
//   const togglePasswordShown = () => {
//     setPasswordShown(!passwordShown);
//   };

//   // Recheck Password
//   const onChangeRecheckPassword = (e) => {
//     let value = e.target.value;
//     return setRecheckPassword(value);
//   };
//   const [recheckPasswordShown, setRecheckPasswordShown] = useState(false);
//   const toggleRecheckPasswordShown = () => {
//     setRecheckPasswordShown(!recheckPasswordShown);
//   };

//   // Username
//   const onChangeUsername = (e) => {
//     let value = e.target.value;
//     return setFormLogin({ ...formLogin, username: value });
//   };

//   // Fullname
//   const onChangeFullname = (e) => {
//     let value = e.target.value;
//     return setFormLogin({ ...formLogin, fullname: value });
//   };

//   // Birthday
//   const onChangeBirthday = (e) => {
//     let value = e.target.value;
//     return setFormLogin({ ...formLogin, birthday: value });
//   };

//   // Age
//   const onChangeAge = (e) => {
//     let value = Number(e.target.value);
//     if (value === 0) {
//       return setFormLogin({ ...formLogin, age: "" });
//     }
//     return setFormLogin({ ...formLogin, age: value });
//   };

//   // Sex
//   const onChangeSex = (e) => {
//     let value = e.target.value;
//     return setFormLogin({ ...formLogin, sex: value });
//   };

//   // Phone Number
//   const onChangePhoneNumber = (e) => {
//     let value = e.target.value;
//     return setFormLogin({ ...formLogin, phone_number: value });
//   };

//   //----------------------------------------------------------------------------------------------//

//   const validateStepOne = () => {
//     let checkError = false;
//     let localErrorForm = { ...errorForm };
//     if (!formLogin.email) {
//       localErrorForm.email = {
//         isError: true,
//         message: "กรุณากรอกอีเมล",
//       };
//       checkError = true;
//     }
//     if (!formLogin.password) {
//       localErrorForm.password = {
//         isError: true,
//         message: "กรุณากรอกรหัสผ่าน",
//       };
//       checkError = true;
//     }

//     setErrorForm(localErrorForm);
//     return checkError;
//   };

//   //----------------------------------------------------------------------------------------------//
//   const onClickGoStepOne = () => {
//     setFormLogin({
//       username: "",
//       password: "",
//       fullname: "",
//       birthday: "",
//       age: "",
//       sex: "",
//       email: "",
//       phone_number: "",
//     });
//     setErrorForm({
//       username: { isError: false, message: "" },
//       password: { isError: false, message: "" },
//       // recheckPassword: { isError: false, message: "" },
//       fullname: { isError: false, message: "" },
//       birthday: { isError: false, message: "" },
//       age: { isError: false, message: "" },
//       sex: { isError: false, message: "" },
//       email: { isError: false, message: "" },
//       phone_number: { isError: false, message: "" },
//     });
//     setStepPage(stepPage + 1);
//   };

//   const onClickGoStepTwo = async () => {
//     const isError = validateStepOne();
//     if (isError) {
//       return;
//     }

//     if (formLogin.password !== recheckPassword) {
//       setOpenAlert(true);
//       return;
//     }

//     try {
//       const paramFilter = {
//         filter: {
//           email: formLogin.email,
//         },
//       };
//       const { data } = await Api.get("/profiles", {
//         params: paramFilter,
//       });
//       // console.log("data : ", data);

//       if (data.count > 0) {
//         setOpenAlert2(true);
//         return;
//       }

//       await signUp({
//         username: formLogin.email,
//         password: formLogin.password,
//         options: {
//           userAttributes: {
//             email: formLogin.email,
//           },
//         },
//       });
//       setStepPage(stepPage + 1);
//       setOtp("");
//     } catch (error) {
//       console.log(error);
//       if (error?.name === "UsernameExistsException") {
//         await resendSignUpCode({
//           username: formLogin.email,
//         });
//         setStepPage(stepPage + 1);
//         setOtp("");
//       }
//     }
//   };

//   const onClickGoStepThree = async () => {
//     try {
//       await confirmSignUp({
//         username: formLogin.email,
//         confirmationCode: otp,
//       });
//       setStepPage(stepPage + 1);
//     } catch (error) {
//       console.log(error);
//       if (
//         ["CodeMismatchException", "EmptyConfirmSignUpCode"].includes(error.name)
//       ) {
//         setOpenAlert(true);
//       }
//     }
//   };

//   //----------------------------------------------------------------------------------------------//

//   const onSubmitForm = async () => {
//     try {
//       setLoading(true);

//       await Api.post(`profiles`, formLogin);
//     } catch (error) {
//       console.log(error);
//     } finally {
//       setLoading(false);
//       setStepPage(0);
//     }
//   };

//   return (
//     <React.Fragment>
//       {/*---------- Step 0 ---------- */}
//       {stepPage === 0 && (
//         <div
//           //   style={{
//           //     backgroundImage:
//           //       window?.location?.pathname === "/" ? "" : "url(/images/circle.png)",
//           //     backgroundRepeat: "no-repeat",
//           //     backgroundPosition: window?.location?.pathname === "/" ? "" : "left",
//           //   }}
//           className=" font-IBMPlexSansThai flex flex-col justify-between relative min-h-screen mx-auto max-w-3xl "
//         >
//           <div className=" relative pt-[105px] px-[32px]">
//             <img
//               src="/images/circle_login1.svg"
//               className=" absolute top-0 right-0"
//               alt=""
//             />
//             <div>
//               <img
//                 src="/images/logo_assure.png"
//                 className=" w-fit h-[37px]"
//                 alt=""
//               />
//               <div className=" text-maincyan font-bold text-5xl leading-relaxed">
//                 เข้าสู่ระบบ
//               </div>
//             </div>
//             <div className=" flex flex-col gap-4">
//               <div>
//                 <label className={STYLE_TEXT_BLUE}>เบอร์โทร</label>
//                 <InputMain
//                   type="number"
//                   className={``}
//                   onChange={(e) => setMainPhoneNumber(e.target.value)}
//                 />
//               </div>
//               <div>
//                 <label className={STYLE_TEXT_BLUE}>รหัสผ่าน</label>
//                 <div className=" relative flex items-center w-full border border-gray-200 rounded-full h-10 mt-[2px] ">
//                   <InputPasswordMain
//                     passwordShown={mainPasswordShown}
//                     className={``}
//                     onChange={(e) => setMainPassword(e.target.value)}
//                   />
//                   <ICON_EYE
//                     className={` absolute w-4 h-4 text-maingray right-0 mx-[14px] cursor-pointer`}
//                     onClick={toggleMainPasswordShown}
//                   />
//                 </div>
//               </div>
//             </div>
//             <div className=" w-full mt-[26px]">
//               <MTButton text="เข้าสู่ระบบ" width="w-full" colortext={`mt-1`} />
//             </div>
//             <div className=" flex gap-2 items-center my-4">
//               <div className=" border-b border-[#eaeaea] w-full"></div>
//               <div className={STYLE_TEXT_BLUE}>or</div>
//               <div className=" border-b border-[#eaeaea] w-full"></div>
//             </div>
//             <div className=" w-full flex flex-col gap-[15px] ">
//               <MTButton
//                 iconleft={true}
//                 icon={<ICON_USER className={` w-6 h-6 text-maincyan`} />}
//                 text="ลงทะเบียนใช้งาน"
//                 color="border border-gray-300 "
//                 colortext="w-full text-center text-gray-400 group-hover:text-white"
//                 flexnone="flex-none"
//                 hovercolor="hover:bg-maincyan group "
//                 rounded="rounded-[10px]"
//                 width="w-full"
//                 onClick={onClickGoStepOne}
//               />
//               <MTButton
//                 iconleft={true}
//                 icon={<LOGO_GOOGLE className={` w-7 h-7`} />}
//                 text="เข้าสู่ระบบด้วย Gmail"
//                 color="border border-gray-300 "
//                 colortext="w-full text-center text-gray-400 group-hover:text-white"
//                 flexnone="flex-none"
//                 hovercolor="hover:bg-maincyan group "
//                 rounded="rounded-[10px]"
//                 width="w-full"
//                 // onClick={() => {}}
//               />
//               <MTButton
//                 iconleft={true}
//                 icon={<LOGO_FACEBOOK className={` w-6 h-6`} />}
//                 text="เข้าสู่ระบบด้วย Facebook"
//                 color="border border-gray-300 "
//                 colortext="w-full text-center text-gray-400 group-hover:text-white"
//                 flexnone="flex-none"
//                 hovercolor="hover:bg-maincyan group "
//                 rounded="rounded-[10px]"
//                 width="w-full"
//                 // onClick={() => {}}
//               />

//               {/* <div className=" flex gap-1 items-center justify-center">
//                 <div className=" text-sm text-mainblue">
//                   ยังไม่มีบัญชีใช่ไหม
//                 </div>
//                 <div className=" text-maincyan font-medium">สมัครสมาชิก</div>
//               </div> */}
//             </div>
//             {/* <img
//               src="/images/circle_login2.svg"
//               className=" absolute bottom-40"
//               alt=""
//             /> */}
//           </div>
//           {/* <Footer /> */}
//         </div>
//       )}

//       {/*---------- Step 1 ---------- */}
//       {stepPage === 1 && (
//         <div
//           //   style={{
//           //     backgroundImage:
//           //       window?.location?.pathname === "/" ? "" : "url(/images/circle.png)",
//           //     backgroundRepeat: "no-repeat",
//           //     backgroundPosition: window?.location?.pathname === "/" ? "" : "left",
//           //   }}
//           className=" font-IBMPlexSansThai flex flex-col relative min-h-screen mx-auto max-w-3xl"
//         >
//           <div className=" relative pt-[105px] px-[32px]">
//             <img
//               src="/images/circle_login1.svg"
//               className=" absolute top-0 right-0"
//               alt=""
//             />

//             <div>
//               <img
//                 src="/images/logo_assure.png"
//                 className=" w-fit h-[37px]"
//                 alt=""
//               />
//               <div className=" text-maincyan font-bold text-5xl leading-relaxed">
//                 ลงทะเบียน
//               </div>
//             </div>
//             <div className=" flex flex-col gap-4">
//               <div>
//                 <div className="text-mainblue font-normal">
//                   Step {stepPage}/3
//                 </div>
//               </div>
//               <div>
//                 <label className={STYLE_TEXT_BLUE}>อีเมล</label>
//                 <InputMain
//                   placeholder="example@gmail.com"
//                   className={`${errorForm.email.isError && "border-red-500"}`}
//                   value={formLogin.email}
//                   onChange={onChangeEmail}
//                 />
//                 {errorForm.email.isError && (
//                   <ErrorMain errortext={errorForm.email.message} />
//                 )}
//               </div>
//               <div>
//                 <label className={STYLE_TEXT_BLUE}>รหัสผ่าน</label>
//                 <div
//                   className={` relative flex items-center w-full border ${
//                     errorForm.password.isError
//                       ? "border-red-500"
//                       : "border-gray-200"
//                   }   rounded-full h-10 mt-[2px] `}
//                 >
//                   <InputPasswordMain
//                     passwordShown={passwordShown}
//                     placeholder="อย่างน้อย 8 ตัวอักษร"
//                     className={``}
//                     value={formLogin.password}
//                     onChange={onChangePassword}
//                   />
//                   <ICON_EYE
//                     onClick={togglePasswordShown}
//                     className={` absolute w-4 h-4 text-maingray right-0 mx-[14px] cursor-pointer`}
//                   />
//                 </div>
//                 {errorForm.password.isError && (
//                   <ErrorMain errortext={errorForm.password.message} />
//                 )}
//               </div>
//               <div>
//                 <label className={STYLE_TEXT_BLUE}>ยืนยันรหัสผ่าน</label>
//                 <div className=" relative flex items-center w-full border border-gray-200 rounded-full h-10 mt-[2px] ">
//                   <InputPasswordMain
//                     passwordShown={recheckPasswordShown}
//                     placeholder="อย่างน้อย 8 ตัวอักษร"
//                     className={``}
//                     value={recheckPassword}
//                     onChange={onChangeRecheckPassword}
//                   />
//                   <ICON_EYE
//                     onClick={toggleRecheckPasswordShown}
//                     className={` absolute w-4 h-4 text-maingray right-0 mx-[14px] cursor-pointer`}
//                   />
//                 </div>
//               </div>
//             </div>
//           </div>

//           <div className="flex justify-center gap-2.5 w-full px-[8.5px] mt-[26px] mb-[38px]">
//             <MTButton
//               text="ย้อนกลับ"
//               color="bg-white"
//               border="border border-maincyan"
//               colortext="text-maincyan group-hover:text-white"
//               hovercolor="hover:bg-maincyan group "
//               width="w-full"
//               onClick={() => setStepPage(stepPage - 1)}
//             />
//             <MTButton
//               text={"ถัดไป"}
//               color="bg-maincyan"
//               width="w-full"
//               onClick={onClickGoStepTwo}
//             />
//           </div>

//           <PopupMain
//             open={openAlert}
//             text={`กรุณากรอก รหัสผ่าน ให้เหมือนกัน`}
//             onClick={() => {
//               setOpenAlert(false);
//             }}
//           />
//           <PopupMain2
//             open={openAlert2}
//             text={`อีเมลนี้ลงทะเบียนไปแล้ว`}
//             onClick={() => {
//               setOpenAlert2(false);
//             }}
//           />
//         </div>
//       )}

//       {/*---------- Step 2 ---------- */}
//       {stepPage === 2 && (
//         <div
//           //   style={{
//           //     backgroundImage:
//           //       window?.location?.pathname === "/" ? "" : "url(/images/circle.png)",
//           //     backgroundRepeat: "no-repeat",
//           //     backgroundPosition: window?.location?.pathname === "/" ? "" : "left",
//           //   }}
//           className=" font-IBMPlexSansThai flex flex-col relative min-h-screen mx-auto max-w-3xl"
//         >
//           <div className=" relative pt-[105px] px-[32px]">
//             <img
//               src="/images/circle_login1.svg"
//               className=" absolute top-0 right-0"
//               alt=""
//             />

//             <div>
//               <img
//                 src="/images/logo_assure.png"
//                 className=" w-fit h-[37px]"
//                 alt=""
//               />
//               <div className=" text-maincyan font-bold text-5xl leading-relaxed">
//                 ลงทะเบียน
//               </div>
//             </div>
//             <div className=" flex flex-col gap-4">
//               <div>
//                 <div className="text-mainblue font-normal">
//                   Step {stepPage}/3
//                 </div>
//               </div>

//               <div className="flex flex-col items-center gap-[18px]">
//                 <div className={STYLE_TEXT_BLUE}>
//                   กรุณากรอกเลข OTP ที่ส่งไปยัง
//                 </div>
//                 <div className={`${STYLE_TEXT_CYAN} text-[20px]`}>
//                   {formLogin.email}
//                 </div>
//               </div>

//               <div className="mt-[52px] mb-[42px]">
//                 {console.log("otp : ", otp)}
//                 <InputNumberOTP otp={otp} setOtp={setOtp} />
//               </div>
//             </div>
//           </div>

//           <div className="flex justify-center gap-2.5 w-full px-[8.5px] mt-[26px] mb-[38px]">
//             <MTButton
//               text="ย้อนกลับ"
//               color="bg-white"
//               border="border border-maincyan"
//               colortext="text-maincyan group-hover:text-white"
//               hovercolor="hover:bg-maincyan group "
//               width="w-full"
//               onClick={() => setStepPage(stepPage - 1)}
//             />
//             <MTButton
//               text={"ถัดไป"}
//               color="bg-maincyan"
//               width="w-full"
//               onClick={onClickGoStepThree}
//             />
//           </div>

//           <PopupMain
//             open={openAlert}
//             text={`กรุณากรอก OTP ให้ถูกต้อง`}
//             onClick={() => {
//               setOpenAlert(false);
//             }}
//           />
//         </div>
//       )}

//       {/*---------- Step 3 ---------- */}
//       {stepPage === 3 && (
//         <div
//           //   style={{
//           //     backgroundImage:
//           //       window?.location?.pathname === "/" ? "" : "url(/images/circle.png)",
//           //     backgroundRepeat: "no-repeat",
//           //     backgroundPosition: window?.location?.pathname === "/" ? "" : "left",
//           //   }}
//           className=" font-IBMPlexSansThai flex flex-col relative min-h-screen mx-auto max-w-3xl"
//         >
//           <div className=" relative pt-[105px] px-[32px]">
//             <img
//               src="/images/circle_login1.svg"
//               className=" absolute top-0 right-0"
//               alt=""
//             />

//             <div>
//               <img
//                 src="/images/logo_assure.png"
//                 className=" w-fit h-[37px]"
//                 alt=""
//               />
//               <div className=" text-maincyan font-bold text-5xl leading-relaxed">
//                 ลงทะเบียน
//               </div>
//             </div>
//             <div className=" flex flex-col gap-4">
//               <div>
//                 <div className="text-mainblue font-normal">
//                   Step {stepPage}/3
//                 </div>
//               </div>
//               <div>
//                 <label className={STYLE_TEXT_BLUE}>Username</label>
//                 <InputMain
//                   placeholder="ระบุ"
//                   className={``}
//                   value={formLogin.username}
//                   onChange={onChangeUsername}
//                 />
//               </div>
//               <div>
//                 <label className={STYLE_TEXT_BLUE}>ชื่อ - นามสกุล</label>
//                 <InputMain
//                   placeholder="ระบุ"
//                   className={``}
//                   value={formLogin.fullname}
//                   onChange={onChangeFullname}
//                 />
//               </div>
//               <div>
//                 <label className={STYLE_TEXT_BLUE}>วันเกิด</label>
//                 <InputMain
//                   type="date"
//                   className={``}
//                   value={formLogin.birthday}
//                   onChange={onChangeBirthday}
//                 />
//               </div>
//               <div>
//                 <label className={STYLE_TEXT_BLUE}>อายุ</label>
//                 <InputMain
//                   type="number"
//                   placeholder="ระบุ"
//                   className={``}
//                   value={formLogin.age}
//                   onChange={onChangeAge}
//                 />
//               </div>
//               <div>
//                 <label className={STYLE_TEXT_BLUE}>เพศ</label>
//                 <div
//                   className="grid grid-cols-2 gap-2.5 items-center"
//                   onChange={onChangeSex}
//                 >
//                   <div className="flex items-center gap-[17px] border rounded-[10px] px-[14px] py-[8px]">
//                     <input
//                       type="radio"
//                       name="sex"
//                       value="ชาย"
//                       checked={formLogin.sex === "ชาย" ? true : false}
//                     />{" "}
//                     ชาย
//                   </div>
//                   <div className="flex items-center gap-[17px] border rounded-[10px] px-[14px] py-[8px]">
//                     <input
//                       type="radio"
//                       name="sex"
//                       value="หญิง"
//                       checked={formLogin.sex === "หญิง" ? true : false}
//                     />{" "}
//                     หญิง
//                   </div>
//                 </div>
//               </div>
//               <div>
//                 <label className={STYLE_TEXT_BLUE}>เบอร์โทร</label>
//                 <InputMain
//                   type="number"
//                   placeholder="ระบุ"
//                   className={``}
//                   value={formLogin.phone_number}
//                   onChange={onChangePhoneNumber}
//                 />
//               </div>
//             </div>
//           </div>

//           <div className="flex justify-center gap-2.5 w-full px-[8.5px] mt-[26px] mb-[38px]">
//             <MTButton
//               text="ย้อนกลับ"
//               color="bg-white"
//               border="border border-maincyan"
//               colortext="text-maincyan group-hover:text-white"
//               hovercolor="hover:bg-maincyan group "
//               width="w-full"
//               onClick={() => setStepPage(1)}
//             />
//             <MTButton
//               text={"ลงทะเบียน"}
//               color="bg-maincyan"
//               width="w-full"
//               onClick={onSubmitForm}
//             />
//           </div>
//           {/* <Footer /> */}
//         </div>
//       )}
//     </React.Fragment>
//   );
// };

export default LoginMain;
