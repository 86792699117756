import { useNavigate } from "react-router-dom";
import { HeaderTitle } from "../../../components/header/Header";
import Container from "../../../components/layout/Container";
import NavigateGoBack from "../../../functions/Navigate.Goback";
import {
  STYLE_MARGIN_TOP_PARENT,
  STYLE_PADDING_MAIN,
} from "../../../utils/useStyle";
import { FormLegacyManulOne, FormLegacyManulTwo } from "./VIForm";
import { MTButton } from "../../../components/button/MTButton";

const ViManualFormTwo = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <div className={`${STYLE_MARGIN_TOP_PARENT} mb-[30px]`}>
        <HeaderTitle
          title="พินัยกรรมแบบเขียนเอง แบบที่ 2"
          isGoback
          onClickGoBack={() => {
            NavigateGoBack(navigate, "/");
          }}
        />
        <div className={STYLE_PADDING_MAIN}>
          <FormLegacyManulTwo />
          <div className=" flex justify-center items-center gap-2 mt-4">
            <MTButton
              // loading={loading}
              text="ย้อนกลับ"
              color="border border-maincyan "
              colortext="text-maincyan group-hover:text-white"
              hovercolor="hover:bg-maincyan group "
              onClick={(e) => {
                navigate(-1);
              }}
            />

            <MTButton
              // loading={loading}
              text={"บันทึก"}
              color="bg-maincyan"
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ViManualFormTwo;
