import _ from "lodash";
import { useTaxStore } from "../../../_store/taxplanStore";
import { numberWithoutComma } from "../../../pages/balance/component/VIDrawerDebt.Main";

export const TabThreeSumIncomeCalculation = (taxData) => {
  let salary_Income = numberWithoutComma(_.get(taxData, "tax_income", 0) || 0);
  let freeLance_Income = Number(taxData?.freelance?.tax_income || 0);
  let freeJob_Income = taxData?.freeJob?.reduce((total, num) => {
    return total + Number(num?.tax_income || 0);
  }, 0);

  let income_tax_403 = taxData?.tax_403?.reduce((total, num) => {
    return total + Number(num?.tax_income || 0);
  }, 0);

  let income_tax_405 = taxData?.tax_405?.reduce((total, num) => {
    return total + Number(num?.tax_income || 0);
  }, 0);

  let income_tax_407 = taxData?.tax_407?.reduce((total, num) => {
    return total + Number(num?.tax_income || 0);
  }, 0);

  let income_tax_408 = taxData?.tax_408?.reduce((total, num) => {
    return total + Number(num?.tax_income || 0);
  }, 0);

  let income_tax_408_property = taxData?.tax_408_property?.reduce(
    (total, num) => {
      return total + Number(num?.tax_income || 0);
    },
    0
  );

  let income_tax_404 = taxData?.tax_404?.reduce((total, num) => {
    return total + Number(num?.tax_income || 0);
  }, 0);

  // tax_404_stock
  let income_tax_404_stock = taxData?.tax_404_stock?.reduce((total, num) => {
    if (num?.type_job?.label === "หุ้น") {
    return total + Number(num?.dividend || 0) + Number(num?.tax_credit || 0) ;
    }
    return total + Number(num?.dividend || 0);
  }, 0);

  let income_not_include_tax = _.get(taxData, "income_not_include_tax", 0);

  let RMF_Profit = (Number(taxData?.RMF_Profit?.selling_price||0) - Number(taxData?.RMF_Profit?.cost_price||0))
  let SSF_Profit = (Number(taxData?.SSF_Profit?.selling_price||0) - Number(taxData?.SSF_Profit?.cost_price||0))

  let Income_42_26 = 0;
  let Income_42_27 = 0;
  let Income_42_28 = 0;

  if (!taxData?.heritage?.tax_calc_include) {
    Income_42_26 = Number(taxData?.heritage?.income_42_26 || 0);
    Income_42_27 = Number(taxData?.heritage?.income_42_27 || 0);
    Income_42_28 = Number(taxData?.heritage?.income_42_28 || 0);
  }

  let tax408_property = taxData?.tax_408_property?.reduce((total, num) => {
    if (num?.calculating_way === "ไม่แยกคำนวณ") {
      return total+Number(num?.tax_income||0); 
    }
    return total; 
  },0);

  return (
    salary_Income +
    freeJob_Income +
    freeLance_Income +
    income_tax_403 +
    income_tax_405 +
    income_tax_407 +
    income_tax_408 +
    // income_tax_408_property +
    income_tax_404 +
    income_tax_404_stock +
    income_not_include_tax +
    RMF_Profit +
    SSF_Profit +
    Income_42_26 +
    Income_42_27 +
    Income_42_28 +
    tax408_property
  );
};

export const TabThreeExpenseCalculation = (taxData) => {
  let salary_expense =
    numberWithoutComma(_.get(taxData, "tax_income", 0) || 0) / 2 > 100000
      ? 100000
      : numberWithoutComma(_.get(taxData, "tax_income", 0) || 0) / 2;

  //   วิชาชีพอิสระ
  let freeJob_expense = taxData?.freeJob?.reduce((total, num) => {
    if (num?.calculating_method === "หักแบบเหมา") {
      return (
        total +
        (Number(num?.tax_income || 0) -
          Number(num?.income_for_calculating_all_in || 0))
      );
    }
    if (num?.calculating_method === "หักตามจริง") {
      return (
        total +
        (Number(num?.tax_income || 0) -
          Number(num?.income_for_calculating || 0))
      );
    }
    return total + 0;
  }, 0);

  //   40(3)
  let expense_tax_403 = taxData?.tax_403?.reduce((total, num) => {
    if (num?.calculating_method === "หักแบบเหมา") {
      return (
        total +
        (Number(num?.tax_income || 0) -
          Number(num?.income_for_calculating_all_in || 0))
      );
    }
    if (num?.calculating_method === "หักตามจริง") {
      return (
        total +
        (Number(num?.tax_income || 0) -
          Number(num?.income_for_calculating || 0))
      );
    }
    return total + 0;
  }, 0);

  //   40(5)
  let expense_tax_405 = taxData?.tax_405?.reduce((total, num) => {
    if (num?.calculating_method === "หักแบบเหมา") {
      return (
        total +
        (Number(num?.tax_income || 0) -
          Number(num?.income_for_calculating_all_in || 0))
      );
    }
    if (num?.calculating_method === "หักตามจริง") {
      return (
        total +
        (Number(num?.tax_income || 0) -
          Number(num?.income_for_calculating || 0))
      );
    }
    return total + 0;
  }, 0);

  //   40(7)
  let expense_tax_407 = taxData?.tax_407?.reduce((total, num) => {
    if (num?.calculating_method === "หักแบบเหมา") {
      return (
        total +
        (Number(num?.tax_income || 0) -
          Number(num?.income_for_calculating_all_in || 0))
      );
    }
    if (num?.calculating_method === "หักตามจริง") {
      return (
        total +
        (Number(num?.tax_income || 0) -
          Number(num?.income_for_calculating || 0))
      );
    }
    return total + 0;
  }, 0);

  //   40(8)
  let expense_tax_408 = taxData?.tax_408?.reduce((total, num) => {
    if (num?.calculating_method === "หักแบบเหมา") {
      return (
        total +
        (Number(num?.tax_income || 0) -
          Number(num?.income_for_calculating_all_in || 0))
      );
    }
    if (num?.calculating_method === "หักตามจริง") {
      return (
        total +
        (Number(num?.tax_income || 0) -
          Number(num?.income_for_calculating || 0))
      );
    }
    return total + 0;
  }, 0);

  //   40(8)_property
  let expense_tax_408_property = taxData?.tax_408_property?.reduce(
    (total, num) => {
      if(num?.calculating_way === "ไม่แยกคำนวณ"){
        if (num?.calculating_method === "หักแบบเหมา") {
          return (
            total +
            (Number(num?.tax_income || 0) -
              Number(num?.income_for_calculating_all_in || 0))
          );
        }
        if (num?.calculating_method === "หักตามจริง") {
          return (
            total +
            (Number(num?.tax_income || 0) -
              Number(num?.income_for_calculating || 0))
          );
        }
      }
      return total + 0;
    },
    0
  );

  let sumExpense =
    salary_expense +
    freeJob_expense +
    expense_tax_403 +
    expense_tax_405 +
    expense_tax_407 +
    expense_tax_408 +
    expense_tax_408_property;

  //   console.log("freeJob_expense",freeJob_expense)
  //   console.log("expense_tax_403", expense_tax_403);
  //   console.log("tax_403", taxData?.tax_403);

  return sumExpense;
};

export const TabThreeWithHoldingTax = (taxData) => {
  let taxWithholdingNormal = numberWithoutComma(
    _.get(taxData, "tax_withholding", 0) || 0
  );

  let quitjob = numberWithoutComma(taxData?.tax_QuitJob?.with_holding_tax || 0);

  let freelanceWithholding = Number(taxData?.freelance?.with_holding_tax || 0);
  let freeJobWithholding = taxData?.freeJob?.reduce((total, num) => {
    return total + Number(num?.with_holding_tax || 0);
  }, 0);

  let Withholding403 = taxData?.tax_403?.reduce((total, num) => {
    return total + Number(num?.with_holding_tax || 0);
  }, 0);

  let Withholding405 = taxData?.tax_405?.reduce((total, num) => {
    return total + Number(num?.with_holding_tax || 0);
  }, 0);

  let Withholding407 = taxData?.tax_407?.reduce((total, num) => {
    return total + Number(num?.with_holding_tax || 0);
  }, 0);

  let Withholding408 = taxData?.tax_408?.reduce((total, num) => {
    return total + Number(num?.with_holding_tax || 0);
  }, 0);

  let Withholding408_property = taxData?.tax_408_property?.reduce(
    (total, num) => {
      if (num?.calculating_way === "ไม่แยกคำนวณ") {
        return total+Number(num?.with_holding_tax||0); 
      }
      return total; 
    },
    0
  );

  let income_tax_404 = taxData?.tax_404?.reduce((total, num) => {
    return total + Number(num?.with_holding_tax || 0);
  }, 0);

  let income_tax_404_stock = taxData?.tax_404_stock?.reduce((total, num) => {
  
    return total + Number(num?.with_holding_tax || 0)+ Number(num?.tax_credit || 0);
  }, 0);


  let RMF_Profit = Number(taxData?.RMF_Profit?.withholding_tax || 0);
  let SSF_Profit = Number(taxData?.SSF_Profit?.withholding_tax || 0);

  let sumWitholdingTax =
    taxWithholdingNormal +
    quitjob +
    freelanceWithholding +
    freeJobWithholding +
    Withholding403 +
    Withholding405 +
    Withholding407 +
    Withholding408 +
    Withholding408_property +
    income_tax_404 +
    income_tax_404_stock +
    RMF_Profit +
    SSF_Profit;
  return sumWitholdingTax;
};
