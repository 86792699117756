import { useEffect, useMemo, useState } from "react";
import { MTButton } from "../../../components/button/MTButton";
import { HeaderTitle, HeadtitleCard } from "../../../components/header/Header";
import Container from "../../../components/layout/Container";
import {
  STYLE_CARD_BG,
  STYLE_FLEX_STATUS,
  STYLE_INPUT,
  STYLE_INPUT_ERROR,
  STYLE_INPUT_SLIDER,
  STYLE_INPUT_SLIDER_MAX,
  STYLE_MARGIN_TOP_PARENT,
} from "../../../utils/useStyle";
import { Input, Slider } from "@material-tailwind/react";
import LineChartMain from "../../../components/chart/LineChartMain";
import numeral from "numeral";
import LineChartSavingMain from "./LineChartSaving.Main";
import { useNavigate } from "react-router-dom";
import ViewSavingSum from "./VISavingSum.Main";
import { useSavingStore } from "../../../_store/savingStore";
import { getPercent } from "../functions/functionsMain";
import "./stylesSlider.css";
import { SliderSingle } from "../../../components/slider/SliderSingle";
import { ICON_INFO, ICON_WARNING } from "../../../utils/useIcons";
import ErrorMain from "../../../components/error/Error.Main";
import { NumericFormat } from "react-number-format";
import { formatNumberWithComma } from "../../../functions/Navigate.Goback";
import Api from "../../../api/api.main";
import { useUserStore } from "../../../_store/userStore";
import NumbericValidateRed from "../../../components/DebtBalance/numericRed";

const checkError = (formSaving) => {
  const saving_money_month =
    formSaving?.saving_money_month < 0 ||
    formSaving?.saving_money_month === "" ||
    formSaving?.saving_money_month === 0 ||
    formSaving?.saving_money_month === null;
  // formSaving?.saving_money_month > 100000000;

  const saving_yield =
    formSaving?.saving_yield === "" ||
    formSaving?.saving_yield === 0 ||
    formSaving?.saving_yield > formSaving?.saving_yield_max;

  const saving_yield_max =
    formSaving?.saving_yield_max === "" ||
    formSaving?.saving_yield_max === 0 ||
    formSaving?.saving_yield_max > 100 ||
    formSaving?.saving_yield_max === null ||
    formSaving?.saving_yield > formSaving?.saving_yield_max;

  const saving_per_year =
    formSaving?.saving_per_year === "" || formSaving?.saving_per_year === 0;

  const saving_per_year_max =
    formSaving?.saving_per_year_max === "" ||
    formSaving?.saving_per_year_max === 0 ||
    formSaving?.saving_per_year_max === null ||
    formSaving?.saving_per_year_max > 100;

  return {
    saving_money_month,
    saving_yield,
    saving_yield_max,
    saving_per_year,
    saving_per_year_max,
  };
};

const ViewSavingPlanMain = ({ formSaving, setFormSaving, setShow }) => {
  const [sum, setSum] = useState();

  // UserData Logined
  const userData = useUserStore((state) => state.userData);
  const setUserData = useUserStore((state) => state.setUserData);

  // SavingPlan Logined
  const savingData = useSavingStore((state) => state.savingData);
  const setSavingeData = useSavingStore((state) => state.setSavingeData);
  const clearSavingData = useSavingStore((state) => state.clearSavingData);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    saving_money_month: false,
    saving_yield: false,
    saving_yield_max: false,
    saving_per_year: false,
    saving_per_year_max: false,
  });

  const onChangeSavingMoneyMonth = (e) => {
    let value = e.target.value;
    let output = parseFloat(value?.replace(/,/g, ""))
      ? parseFloat(value?.replace(/,/g, ""))
      : null;
    if (isNaN(output)) return;
    // const formattedValue = numeral(value).format("0,0");
    // if (value > 10000000) return;
    else {
      setError({
        ...error,
        saving_money_month: false,
      });
    }
    setFormSaving({
      ...formSaving,
      saving_money_month: output,
    });
    // if (isNaN(value)) return;
    // setFormSaving({
    //   ...formSaving,
    //   saving_money_month: formatNumberWithComma(value),
    // });
  };

  const getSavingYear = () => {
    let sum = 0;
    const thisValue = formSaving?.saving_money_month
      ? formSaving?.saving_money_month
      : 0;
    sum = thisValue * 12;
    return Number(sum);
  };
  const onChangeSavingYield = (e) => {
    const value = e.target.value ? Number(e.target.value) : null;
    if (isNaN(e.target.value)) return;
    if (value > Number(formSaving?.saving_yield_max)) return;
    else {
      setError({
        ...error,
        saving_yield: false,
      });
    }
    setFormSaving({
      ...formSaving,
      saving_yield: value,
    });
  };

  const onChangeSavingYieldMax = (e) => {
    if (isNaN(e.target.value)) return;
    const value = e.target.value ? Number(e.target.value) : null;
    if (value > 100) return;
    else {
      setError({
        ...error,
        saving_yield_max: false,
      });
    }
    setFormSaving({
      ...formSaving,
      saving_yield_max: value,
      saving_yield: value,
    });
    // setFormSaving({
    //   ...formSaving,
    //   saving_yield_max:
    // });
  };

  const onChangeSavingPerYear = (e) => {
    const value = e.target.value ? Number(e.target.value) : null;
    if (isNaN(e.target.value)) return;
    if (value > formSaving?.saving_per_year_max) return;
    setFormSaving({
      ...formSaving,
      saving_per_year: value,
    });
  };
  const onChangeSavingPerYeardMax = (e) => {
    const value = e.target.value ? Number(e.target.value) : null;

    if (isNaN(e.target.value)) return;
    if (value > 100) return;
    else {
      setError({
        ...error,
        saving_per_year_max: false,
      });
    }
    setFormSaving({
      ...formSaving,
      saving_per_year_max: value,
      saving_per_year: value,
    });
  };

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      setFormSaving({
        ...formSaving,
        saving_money_year: getSavingYear(),
      });
    }
  }, [formSaving?.saving_money_month]);

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      if (!userData) {
        setFormSaving({
          ...formSaving,
          saving_per_year: formSaving?.saving_per_year_max,
        });
      }
    }
  }, [
    formSaving?.saving_per_year_max,
  ]);

  const onClickSubmit = async () => {
    const formerror = checkError(formSaving);
    const isformerror = Object.values(formerror).some((item) => item === true);
    setError(formerror);
    if (isformerror) {
      return;
    } else {
      setSavingeData(formSaving);
      setSum("sum");

      // เช็คว่า User Online อยู่ไหม
      if (userData) {
        try {
          const userId = userData._id;

          const paramFilter = {
            filter: {
              user_id: userId,
            },
          };

          //---------- ตารางแผนการออม ----------//
          const { data: dataSavingPlan } = await Api.get("/savingPlans", {
            params: paramFilter,
          });

          if (dataSavingPlan?.savingPlans?.length > 0) {
            // update saving_plan table
            const id = dataSavingPlan?.savingPlans[0]?._id;
            await Api.put(`savingPlans/${id}`, formSaving);
          } else {
            // create saving_plan table
            await Api.post(`savingPlans`, {
              user_id: userId,
              ...formSaving,
            });
          }
          //------------------------------------//
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  return (
    <div>
      <div>
        <div className="  flex flex-col justify-center items-center mt-[18px]">
          <img
            src="/images/saving_save.png"
            className=" w-[60px] h-[60px] "
            alt=""
          />
          <div className=" text-black text-xl font-semibold mt-2.5">
            วางแผนการออม
          </div>
        </div>
        {!sum && (
          <div
            style={{
              paddingBottom: `calc(87px)`,
            }}
            className=" mx-[25px] space-y-4 mt-[14px]  "
          >
            <div className={`${STYLE_CARD_BG}`}>
              <HeadtitleCard title={`ตั้งใจออมเดือนละ`} border />
              <div className={` px-[13px] mt-[14px] `}>
               <NumbericValidateRed
                  // type="number"
                  maxLength={11}
                  value={formSaving?.saving_money_month}
                  placeholder="5,000"
                  onChange={onChangeSavingMoneyMonth}
                  thousandSeparator=","
                  inputMode="decimal"
                  pattern="[0-9],*"
                  className={
                    error?.saving_money_month ? STYLE_INPUT_ERROR : STYLE_INPUT
                  }
                />
                {error?.saving_money_month && <ErrorMain />}
                <div className=" mt-[18px] text-center text-mainblue font-medium ">
                  รวมออมปีละ{" "}
                  <span className=" text-maincyan text-xl font-semibold">
                    {formSaving?.saving_money_year
                      ? numeral(getSavingYear()).format("0,0")
                      : 0}
                  </span>{" "}
                  บาท
                </div>
              </div>
            </div>
            <div className={`${STYLE_CARD_BG}`}>
              <HeadtitleCard title={`อัตราผลตอบแทนต่อปี (%)`} border mb="" />
              <div className={` px-[13px] mt-[9px]`}>
                <div>
                  <div className=" flex justify-center">
                    <input
                      onWheelCapture={(e) => {
                        e.target.blur();
                      }}
                      inputMode="decimal"
                      pattern="[0-9],*"
                      className={STYLE_INPUT_SLIDER}
                      value={formSaving?.saving_yield}
                      onChange={onChangeSavingYield}
                      placeholder="5"
                    />
                  </div>
                  <SliderSingle
                    valuemax={formSaving?.saving_yield_max}
                    value={formSaving?.saving_yield}
                    onChange={onChangeSavingYield}
                    onChnageMax={onChangeSavingYieldMax}
                  />
                </div>

                {error?.saving_yield_max ? (
                  <ErrorMain
                    // errortext={`กรุณากรอกอัตราผลตอบแทนไม่เกิน ${formSaving?.saving_yield_max} %`}
                    errortext={
                      formSaving?.saving_yield_max === null
                        ? `กรุณากรอกอัตราผลตอบแทนสูงสุดต่อปี`
                        : `กรุณากรอกอัตราผลตอบแทนไม่เกิน ${formSaving?.expenses_invest_yield_max} %`
                    }
                  />
                ) : error?.saving_yield ? (
                  <ErrorMain errortext="กรุณากรอกอัตราผลตอบแทนต่อปี" />
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className={`${STYLE_CARD_BG}`}>
              <HeadtitleCard title={`ออมเงินเป็นระยะเวลา (ปี) `} border mb="" />
              <div className={` px-[13px] mt-[9px]`}>
                <div>
                  <div className=" flex justify-center">
                    <input
                      className={STYLE_INPUT_SLIDER}
                      value={formSaving?.saving_per_year}
                      onChange={onChangeSavingPerYear}
                      placeholder="10"
                    />
                  </div>
                  <SliderSingle
                    valuemax={formSaving?.saving_per_year_max}
                    value={formSaving?.saving_per_year}
                    onChange={onChangeSavingPerYear}
                    onChnageMax={onChangeSavingPerYeardMax}
                    year
                  />
                </div>
                {error?.saving_per_year_max ? (
                  <ErrorMain
                    // errortext={`กรุณากรอกระยะเวลาไม่เกิน ${formSaving?.saving_per_year_max} ปี`}

                    errortext={
                      formSaving?.saving_per_year_max === null
                        ? `กรุณากรอกระยะเวลาสูงสุด`
                        : `กรุณากรอกระยะเวลาไม่เกิน ${formSaving?.saving_per_year_max} ปี`
                    }
                  />
                ) : error?.saving_per_year ? (
                  <ErrorMain errortext="กรุณากรอกระยะเวลา" />
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="flex justify-center mb-6">
              <MTButton
                text="คำนวณ"
                loading={loading}
                onClick={onClickSubmit}
              />
            </div>
          </div>
        )}
      </div>

      {sum === "sum" && (
        <ViewSavingSum
          formSaving={formSaving}
          setFormSaving={setFormSaving}
          sum={sum}
          setSum={setSum}
          setShow={setShow}
          setError={setError}
        />
      )}
    </div>
  );
};
export default ViewSavingPlanMain;
