import { Radio } from "@material-tailwind/react";

export const TestCardMain = ({
  img,
  title,
  onClick,
  classNameImg = " w-[50px] h-[50px]",
}) => {
  return (
    <div
      onClick={onClick}
      className=" bg-gray50 hover:bg-maincyan duration-200 py-[9px] rounded-[10px] flex flex-col gap-[9px] justify-center items-center cursor-pointer "
    >
      <img src={img} className={classNameImg} />
      <div className=" text-black text-sm font-medium">{title}</div>
    </div>
  );
};

export const QuestionCard = ({
  question,
  position,
  choice1,
  choice2,
  choice3,
  choice4,
  name = "",
  onChange,
  formRisk,
  checkedHigh = false,
  checkedMedium = false,
  checkedLow = false,
  checkedAvoidance = false,
  checkedControl = false,
  checkedAccept = false,
  checkedTransfer = false,
}) => {
  return (
    <div className=" bg-gray50  rounded-[10px] p-5 ">
      <div className=" text-black text-md font-medium">{question}</div>
      {position === "verticle" ? (
        <div className=" flex flex-col" onChange={onChange}>
          <Radio
            value={"avoid"}
            name={name}
            color="light-blue"
            className="border-gray55 bg-white p-0 transition-all hover:before:opacity-0"
            label={<div className="text-mainblue font-medium">{choice1}</div>}
            checked={checkedAvoidance}
          />
          <Radio
            value={"control"}
            name={name}
            color="light-blue"
            className="border-gray55 bg-white p-0 transition-all hover:before:opacity-0"
            label={<div className="text-mainblue font-medium">{choice2}</div>}
            checked={checkedControl}
          />
          <Radio
            value={"accept"}
            name={name}
            color="light-blue"
            className="border-gray55 bg-white p-0 transition-all hover:before:opacity-0"
            label={<div className="text-mainblue font-medium">{choice3}</div>}
            checked={checkedAccept}
          />
          <Radio
            value={"transfer"}
            name={name}
            color="light-blue"
            className="border-gray55 bg-white p-0 transition-all hover:before:opacity-0"
            label={<div className="text-mainblue font-medium">{choice4}</div>}
            checked={checkedTransfer}
          />
        </div>
      ) : (
        <div className=" flex justify-center gap-[31px] " onChange={onChange}>
          <Radio
            value={"high"}
            name={name}
            color="light-blue"
            className="border-gray55 bg-white p-0 transition-all hover:before:opacity-0"
            label={<div className="text-mainblue font-medium">{choice1}</div>}
            checked={checkedHigh}
          />
          <Radio
            value={"medium"}
            name={name}
            color="light-blue"
            className="border-gray55 bg-white p-0 transition-all hover:before:opacity-0"
            label={<div className="text-mainblue font-medium">{choice2}</div>}
            checked={checkedMedium}
          />
          <Radio
            value={"low"}
            name={name}
            color="light-blue"
            className="border-gray55 bg-white p-0 transition-all hover:before:opacity-0"
            label={<div className="text-mainblue font-medium">{choice3}</div>}
            checked={checkedLow}
          />
        </div>
      )}
    </div>
  );
};

export const PopupResult = ({
  text = "",
  text2 = "",
  text3 = "",
  text4 = "",
  text5 = "",
  text6 = "",
  text7 = "",
  img,
  onClick,
  classNameImg,
}) => {
  return (
    <div
      onClick={onClick}
      className="bg-gray50  rounded-[10px]  p-[9px] flex flex-col gap-4 justify-center items-center"
    >
      <img src={img} className={classNameImg} />
      <div className=" text-mainblue text-center">
        <div>{text}</div>
        <div>{text2}</div>
        <div>{text3}</div>
        <div>{text4}</div>
        <div>{text5}</div>
        <div>{text6}</div>
        <div>{text7}</div>
      </div>
    </div>
  );
};

export const CheckTest = ({ title, onClick, status }) => {
  return (
    <div className=" flex justify-between items-center">
      <div className=" text-mainblue">{title}</div>
      <div className=" cursor-pointer hover:opacity-70" onClick={onClick}>
        {status ? (
          <div className="text-maingreen">ทำแล้ว</div>
        ) : (
          // <div className="text-gray90">ยังไม่ทำ</div>
          <svg
            width="12"
            height="7"
            viewBox="0 0 12 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.75 3.50749H11.25M11.25 3.50749L8.33333 0.59082M11.25 3.50749L8.33333 6.42415"
              stroke="#18488A"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
      </div>
    </div>
  );
};
