import { cn } from "../../../utils/useFunctions";

export function PdfHeaderText({
  icon = "/images/coin.svg",
  iconRender,
  title = "ภาพรวมเป้าหมายทางการเงินของคุณ ​",
  classNameIcon = "",
}) {
  return (
    <div>
      <div className="flex space-x-2 items-center">
        <div>
          {iconRender ? (
            iconRender
          ) : (
            <img
              src={icon}
              alt="logo"
              className={cn("bg-cover cursor-pointer mr-auto", classNameIcon)}
            />
          )}
        </div>
        <div className="font-bold text-[16px]">{title}</div>
      </div>
      <div className="w-44 h-[0.5px] bg-mainyellow "></div>
    </div>
  );
}

export function PdfHeaderCardText({
  icon = "/images/coin.svg",
  title = "ภาพรวมเป้าหมายทางการเงินของคุณ ​",
  titleEnd = "",
  classNameRoot = "",
  classNameIcon = "",
}) {
  return (
    <div className={cn("flex justify-between", classNameRoot)}>
      <div className="flex space-x-2 items-center">
        <div>
          {icon && (
            <img
              src={icon}
              alt="logo"
              className={cn("bg-cover cursor-pointer mr-auto ", classNameIcon)}
            />
          )}
        </div>
        <div className="font-semibold text-[11px]">{title}</div>
      </div>
      {titleEnd && (
        <div className="font-[600] text-maingray text-[14px] mr-2">
          {titleEnd}
        </div>
      )}
    </div>
  );
}
