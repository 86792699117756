import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

const userStore = (set) => ({
  userData: null,
  setUserData: (userData) => set(() => ({ userData })),
  clearUserData: () => set(() => ({ userData: null })),
});

export const useUserStore = create(
  persist(userStore, {
    name: "userStore",
    storage: createJSONStorage(() => localStorage), // (optional) by default the 'localStorage' is used
  })
);
