import { useState } from "react";
import { HeaderTitle } from "../../../../components/header/Header";
import Container from "../../../../components/layout/Container";
import NavigateGoBack from "../../../../functions/Navigate.Goback";
import {
  STYLE_INPUT_CARD_BALANCE,
  STYLE_MARGIN_TOP_PARENT,
  STYLE_PARENT_BUTTON_TWO,
  STYLE_PARENT_CARD_BALANCE,
  STYLE_PARENT_PADDING,
  STYLE_TITLE_CARD_DRAWER_BL,
} from "../../../../utils/useStyle";
import { useNavigate } from "react-router-dom";
import { CardMain } from "../../../../components/card/CardMain";
import { ICON_INFO } from "../../../../utils/useIcons";
import {
  ButtonCounter,
  MTButton,
} from "../../../../components/button/MTButton";
import { CardTaxPremium } from "../../styles/Card/CardMenuPremium";
import {
  option_income_invest_tax_40_4,
  option_income_invest_tax_40_4_asset,
  option_income_invest_tax_40_4_asset_tax,
} from "../../functions/options";
import { NumericFormat } from "react-number-format";
import { PayerIncomeInfo } from "./VIIncome_Freelance";
import { InfoDrawer } from "../../../../components/drawer/InfoDrawer";
import NumbericValidateRed from "../../../../components/DebtBalance/numericRed";
import { useTaxStore } from "../../../../_store/taxplanStore";
import {
  AddOrder,
  DeleteOrder,
  onChangeRMFProfit,
  onChangeSSFProfit,
  onChangeTax404,
  onChangeTax404Stock,
} from "../../../../functions/taxFucntion/premium/businessFunction";
import numeral from "numeral";
import { numberWithoutComma } from "../../../balance/component/VIDrawerDebt.Main";

const Income404Info = ({ open404Info, setOpen404Info }) => {
  return (
    <InfoDrawer
      head={"เงินได้ตามมาตรา 40(4)"}
      text={
        <div className=" mb-4">
          เงินได้ที่เกิดจากการลงทุนในตราสารทุนหรือตราสารหนี้
          ซึ่งได้รับในลักษณะทำนองเดียวกับดอกเบี้ย ผลประโยชน์ หรือค่าตอบแทนอื่นๆ
          เช่น ดอกเบี้ย (เงินฝากธนาคาร พันธบัตร หุ้นกู้)
          เงินปันผลจากบริษัทต่างประเทศ
          เงินส่วนแบ่งกำไรจากกองทุนรวมตามประกาศคณะปฏิวัติฯ (ก่อนวันที่ 20
          สิงหาคม 2562) เงินส่วนแบ่งกำไรจากการถือหรือครอบครองโทเคนดิจิทัล
          ผลประโยชน์ที่ได้รับจากการโอนคริปโทเคอร์เรนซีหรือโทเคนดิจิทัล เป็นต้น
          <div className=" text-sm mt-2">
            ***
            สำหรับเงินปันผลที่ได้รับจากบริษัทหรือห้างหุ้นส่วนนิติบุคคลที่ตั้งขึ้นตามกฎหมายไทย
            เลือกหัวข้อถัดไป (เงินได้ตามมาตรา 40(4)(ข)) ***
          </div>
        </div>
      }
      open={open404Info}
      onClose={() => {
        setOpen404Info(false);
      }}
    />
  );
};

const Income404Info2 = ({ open404Info2, setOpen404Info2 }) => {
  return (
    <InfoDrawer
      head={"เงินได้ตามมาตรา 40(4)(ข)"}
      text={
        <div className=" mb-4">
          เงินปันผล จากบริษัทหรือห้างหุ้นส่วนนิติบุคคล ที่ตั้งขึ้นตามกฎหมายไทย
          หรือ เงินส่วนแบ่งกำไรจากกองทุนรวม กรณีที่ได้รับเครดิตภาษี เท่ากับ
          (อัตราภาษีเงินได้นิติบุคคล X เงินปันผลหรือส่วนแบ่งกำไรที่ได้รับ)
          หารด้วย (100 - อัตราภาษีเงินได้นิติบุคคล)
          <div className=" text-sm mt-2">
            ***เงินปันผลของบุตรที่ยังไม่บรรลุนิติภาวะ
            ให้ถือเป็นเงินได้ของบิดาหรือมารดาผู้ใช้อำนาจปกครอง***
          </div>
        </div>
      }
      open={open404Info2}
      onClose={() => {
        setOpen404Info2(false);
      }}
    />
  );
};

const AssessableIncomeInfo = ({
  openAssessableIncomeInfo,
  setOpenAssessableIncomeInfo,
}) => {
  return (
    <InfoDrawer
      head={
        <div className=" text-xl">
          เงินได้พึงประเมินที่ได้ใช้สิทธิเลือกเสียภาษีโดยไม่ต้องนำมารวมคำนวณภาษีกับเงินได้อื่น
        </div>
      }
      text={
        <div className=" mb-4">
          <div>
            เงินได้พึงประเมินที่ได้ใช้สิทธิเลือกเสียภาษีโดยไม่ต้องนำมารวมคำนวณภาษีกับเงินได้อื่น
            (เพื่อนำไปเป็นฐานในการคำนวณซื้อหน่วยลงทุนในกองทุนรวมเพื่อการเลี้ยงชีพหรือกองทุนรวมเพื่อการออม)
          </div>
          <div>
            ตัวอย่างเงินได้ที่นำมาเป็นฐานในการคำนวณ
            <li>
              เงินปันผล ที่ถูกหักภาษี ณ ที่จ่าย และเลือกเสียภาษีในอัตราร้อยละ 10
              โดยไม่นำเงินปันผลไปรวมคำนวณภาษีกับเงินได้อื่นในการยื่นแบบฯ{" "}
            </li>
            <li>
              เงินส่วนแบ่งกำไรจากกองทุนรวมที่จัดตั้งตาม
              พ.ร.บ.หลักทรัพย์และตลาดหลักทรัพทย์ ที่ถูกหักภาษี ณ ที่จ่าย
              และเลือกภาษีในอัตราร้อยละ 10
              โดยไม่นำเงินส่วนแบ่งของกำไรจากกองทุนรวมฯ
              ไปรวมคำนวณภาษีเงินได้อื่นในการยื่นแบบฯ
            </li>
            <li>
              ดอกเบี้ยรับจากธนาคารพาณิชย์ ที่ไม่ได้รับยกเว้นภาษี
              และเป็นเงินได้ที่มีสิทธิเลือกเสียภาษีโดยไม่ต้องนำไปรวมคำนวณกับเงินได้อื่นในการยื่นแบบฯ
            </li>
          </div>
        </div>
      }
      open={openAssessableIncomeInfo}
      onClose={() => {
        setOpenAssessableIncomeInfo(false);
      }}
    />
  );
};

const RMFIncomeInfo = ({ openRMFIncomeInfo, setOpenRMFIncomeInfo }) => {
  return (
    <InfoDrawer
      head={"เงินค่าขายกองทุนรวมเพื่อการเลี้ยงชีพ (RMF)"}
      text={
        <div className="">
          เงินหรือผลประโยชน์ใดๆ ที่ได้รับเนื่องจากการขายหน่วยลงทุนคืน
          ให้แก่กองทุนรวมเพื่อการเลี้ยงชีพตามกฎหมายว่าด้วยหลักทรัพย์และตลาดหลักทรัพย์
          ทั้งที่ได้รับยกเว้นภาษีเงินได้และที่ไม่ได้รับยกเว้นภาษีเงินได้
          ถือเป็นเงินได้ตามมาตรา 40(4)(ช)
        </div>
      }
      open={openRMFIncomeInfo}
      onClose={() => {
        setOpenRMFIncomeInfo(false);
      }}
    />
  );
};

const SSFIncomeInfo = ({ openSSFIncomeInfo, setOpenSSFIncomeInfo }) => {
  return (
    <InfoDrawer
      head={"เงินค่าขายหน่วยลงทุนเพื่อการออม (SSF)"}
      text={
        <div className="">
          เงินหรือผลประโยชน์ใดๆ ที่ได้รับเนื่องจากการขายหน่วยลงทุนคืน
          ให้แก่หน่วยลงทุนเพื่อการออมตามกฎหมายว่าด้วยหลักทรัพย์และตลาดหลักทรัพย์
          ทั้งที่ได้รับยกเว้นภาษีเงินได้และที่ไม่ได้รับยกเว้นภาษีเงินได้
          ถือเป็นเงินได้ตามมาตรา 40(4)(ช)
        </div>
      }
      open={openSSFIncomeInfo}
      onClose={() => {
        setOpenSSFIncomeInfo(false);
      }}
    />
  );
};

const ViewIncomeInvest = () => {
  const navigate = useNavigate();
  const taxData = useTaxStore((state) => state.taxData);
  const setTaxData = useTaxStore((state) => state.setTaxData);
  const [openInfo, setOpenInfo] = useState(false);
  const [open404Info, setOpen404Info] = useState(false);
  const [open404Info2, setOpen404Info2] = useState(false);
  const [openAssessableIncomeInfo, setOpenAssessableIncomeInfo] =
    useState(false);

  const [openRMFIncomeInfo, setOpenRMFIncomeInfo] = useState(false);
  const [openSSFIncomeInfo, setOpenSSFIncomeInfo] = useState(false);

  const [formTax, setFormTax] = useState({
    ...taxData,
    tax_404: taxData?.tax_404
      ? taxData?.tax_404
      : [
          {
            type_job: "",
            tax_income: "",
            with_holding_tax: "",
            tax_payer_ID: "",
            calculating_method: "",
          },
        ],
    tax_404_stock: taxData?.tax_404_stock
      ? taxData?.tax_404_stock
      : [
          {
            type_job: "",
            tax_income: "",
            with_holding_tax: "",
            tax_payer_ID: "",
            calculating_method: "",
          },
        ],
  });

  const onClickOpen = () => {
    setOpenInfo(true);
  };

  const sumStockDividend = () => {
    let sum = formTax?.tax_404_stock
      ?.filter((item) => item?.type_job?.label === "หุ้น")
      ?.reduce((total, num) => {
        return total + Number(num?.dividend || 0);
      }, 0);

    return numeral(sum)?.format("0,0");
  };

  const sumFundDividend = () => {
    let sum = formTax?.tax_404_stock
      ?.filter((item) => item?.type_job?.label === "กองทุน")
      ?.reduce((total, num) => {
        return total + Number(num?.dividend || 0);

      }, 0);

    return numeral(sum)?.format("0,0");
  };

  const sumTaxCreditDividend = () => {
    let sum = formTax?.tax_404_stock?.reduce((total, num) => {
      // let calTaxCredit = (num?.dividend*num?.interest_rate?.value)/(100-num?.interest_rate?.value)
      // return total + Number(calTaxCredit || 0);
      return total + Number(num?.tax_credit || 0);
    }, 0);

    return numeral(sum)?.format("0,0");
  };

  const sumWithHoldingTaxDividend = () => {
    let sum = formTax?.tax_404_stock?.reduce((total, num) => {
      return total + Number(num?.with_holding_tax || 0);
    }, 0);

    return numeral(sum)?.format("0,0");
  };

  const Submit = () => {
    setTaxData({ ...formTax });
    NavigateGoBack(navigate);
  };

  return (
    <div>
      <Container>
        <div className={STYLE_MARGIN_TOP_PARENT}>
          <HeaderTitle
            title=" รายได้จากการลงทุน"
            isGoback
            onClickGoBack={() => {
              NavigateGoBack(navigate);
            }}
          />
          <div className={STYLE_PARENT_PADDING}>
            {/* ------ 40(4) ------ */}
            <CardMain
              marginyellow=" mt-14 "
              title={
                <div className=" pt-12 pr-3 flex">
                  ดอกเบี้ย เงินปันผลจากบริษัทต่างประเทศ ประโยชน์ใดๆ
                  จากคริปโทเคอร์เรนซีหรือโทเคนดิจิทัล เงินเพิ่มทุน เงินลดทุน
                  (มาตรา 40(4))
                  <ICON_INFO
                    className={` w-4 h-4 text-gray-900 flex-none`}
                    onClick={() => {
                      setOpen404Info(true);
                    }}
                  />
                </div>
              }
              panel={
                <div className=" px-3 -mt-3 space-y-[15px]">
                  <div className=" bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9 ">
                    <div className=" text-sm text-black font-medium">
                      เพิ่มรายการ
                    </div>
                    <ButtonCounter
                      handleNext={() => {
                        AddOrder("tax_404", formTax, setFormTax);
                      }}
                      value={formTax?.tax_404?.length}
                    />
                  </div>
                  {formTax?.tax_404?.map((data, index) => {
                    return (
                      <CardTaxPremium
                        index={index}
                        data={data}
                        ishasCal={false}
                        options={option_income_invest_tax_40_4}
                        openInfo={onClickOpen}
                        onChangeFreeJob={(value, field, index) => {
                          onChangeTax404(
                            value,
                            field,
                            index,
                            formTax,
                            setFormTax
                          );
                        }}
                        DeleteOrder={(a) => {
                          DeleteOrder(a, formTax, setFormTax, "tax_404");
                        }}
                      />
                    );
                  })}
                </div>
              }
            />

            {/* ------ 40(4)(ข) ------ */}
            <CardMain
              marginyellow=" mt-6 "
              title={
                <div className=" pt-4 pr-3 flex">
                  เงินปันผล ส่วนแบ่งกำไรจากหุ้น/กองทุน (มาตรา 40(4)(ข))
                  <ICON_INFO
                    className={` w-4 h-4 text-gray-900 flex-none`}
                    onClick={() => {
                      setOpen404Info2(true);
                    }}
                  />
                </div>
              }
              panel={
                <div className=" px-3 -mt-3 space-y-[15px]">
                  <div className=" bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9 ">
                    <div className=" text-sm text-black font-medium">
                      เพิ่มรายการ
                    </div>

                    <ButtonCounter
                      handleNext={() => {
                        AddOrder("tax_404_stock", formTax, setFormTax);
                      }}
                      value={formTax?.tax_404_stock?.length}
                    />
                  </div>
                  <div className=" space-y-1">
                    <div className=" text-black text-sm font-semibold ">
                      ผลรวมของส่วนของผู้มีเงินได้ จำนวน{" "}
                      <span> {formTax?.tax_404_stock?.length} รายการ</span>
                    </div>
                    <div className=" flex justify-between items-center">
                      <div className=" text-black text-sm font-semibold">
                        รวมเงินปันผลจากหุ้น
                      </div>
                      <div className=" text-maincyan text-xl font-medium">
                        {" "}
                        {/* numeral(item?.amount).format("0,0.00") */}
                        {sumStockDividend()} บาท
                      </div>
                    </div>

                    <div className=" flex justify-between items-center">
                      <div className=" text-black text-sm font-semibold">
                        รวมเครดิตภาษี
                      </div>
                      <div className=" text-maincyan text-xl font-medium">
                        {" "}
                        {sumTaxCreditDividend()} บาท
                      </div>
                    </div>
                    <div className=" flex justify-between items-center">
                      <div className=" text-black text-sm font-semibold">
                        รวมเงินปันผลจากกองทุน
                      </div>
                      <div className=" text-maincyan text-xl font-medium">
                        {" "}
                        {sumFundDividend()} บาท
                      </div>
                    </div>
                    <div className=" flex justify-between items-center">
                      <div className=" text-black text-sm font-semibold">
                        รวมภาษี หัก ณ ที่จ่าย
                      </div>
                      <div className=" text-maincyan text-xl font-medium">
                        {" "}
                        {sumWithHoldingTaxDividend()} บาท
                      </div>
                    </div>
                  </div>
                  {formTax?.tax_404_stock?.map((data, index) => {
                    return (
                      <CardTaxPremium
                        index={index}
                        data={data}
                        isHastitle1={false}
                        ishasCal={false}
                        isCompanyName={true}
                        options={option_income_invest_tax_40_4_asset}
                        dropdown_title="ประเภทเงินปันผล"
                        openInfo={onClickOpen}
                        isHasDividend={true}
                        isHasTaxPercent={true}
                        isDisableTaxCredit={true}
                        isDisableWithholdingTax={true}
                        // isDisableDropdown2={true}
                        isDisableDropdown2={data?.type_job?.label !== "หุ้น"}
                        CardTaxPremium={data?.type_job === "กองทุน"}
                        options_2={option_income_invest_tax_40_4_asset_tax}
                        onChangeFreeJob={(value, field, index) => {
                          onChangeTax404Stock(
                            value,
                            field,
                            index,
                            formTax,
                            setFormTax
                          );
                        }}
                        DeleteOrder={(a) => {
                          DeleteOrder(a, formTax, setFormTax, "tax_404_stock");
                        }}
                      />
                    );
                  })}
                </div>
              }
            />
            {/* ------ เงินได้พึงประเมิน ------ */}
            <CardMain
              marginyellow=" mt-6 "
              title={
                <div className=" pt-4 pr-3 flex">
                  เงินได้พึงประเมินที่ได้ใช้สิทธิเลือกเสียภาษีโดย
                  ไม่ต้องนำมารวมคำนวณภาษีกับเงินได้อื่น
                  <ICON_INFO
                    className={` w-4 h-4 text-gray-900 flex-none`}
                    onClick={() => {
                      setOpenAssessableIncomeInfo(true);
                    }}
                  />
                </div>
              }
              panel={
                <div className=" px-3 -mt-3">
                  <div className={`${STYLE_TITLE_CARD_DRAWER_BL} `}>
                    เงินได้พึงประเมินที่นำมาเป็นฐานในการคำนวณ ซื้อหน่วยลงทุน
                  </div>
                  <NumericFormat
                    maxLength={11}
                    placeholder="0"
                    value={formTax?.income_not_include_tax}
                    onChange={(e) => {
                      let value = numberWithoutComma(e.target.value);
                      setFormTax((prev) => {
                        return { ...prev, income_not_include_tax: value };
                      });
                    }}
                    thousandSeparator=","
                    inputMode="decimal"
                    pattern="[0-9],*"
                    className={`${STYLE_INPUT_CARD_BALANCE} w-full`}
                  />
                </div>
              }
            />
            {/* ------ กำไรจากการขายกองทุนรวมเพื่อการเลี้ยงชีพ (RMF) ------ */}

            <CardMain
              marginyellow=" mt-6 "
              title={
                <div className=" pt-4 pr-3 flex">
                  กำไรจากการขายกองทุนรวมเพื่อการเลี้ยงชีพ (RMF)
                  <ICON_INFO
                    className={` w-4 h-4 text-gray-900 flex-none`}
                    onClick={() => {
                      setOpenRMFIncomeInfo(true);
                    }}
                  />
                </div>
              }
              panel={
                <div className=" px-3 -mt-3 space-y-[15px]">
                  <div className=" text-black font-medium text-sm">
                    <div>กรณีมีเงินได้จากหลายที่</div>
                    <div>
                      1. ให้รวมเงินได้ / ภาษีหัก ณ ที่จ่าย เป็นอันเดียว ก่อนกรอก
                    </div>
                    <div>
                      2.ให้ใส่เลขผู้จ่ายเงินได้จากบริษัทที่จ่ายเงิน
                      ได้ให้มากที่สุด
                    </div>
                  </div>
                  <div className=" bg-white rounded-[20px] px-4 pt-3 pb-[14px] mt-4 ">
                    <div className=" flex justify-between">
                      <div className=" text-base text-mainblue font-semibold">
                        ส่วนของผู้มีเงินได้
                      </div>
                    </div>
                    {/* เงินค่าขาย */}
                    <div className={STYLE_PARENT_CARD_BALANCE}>
                      <div
                        className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}
                      >
                        เงินค่าขาย
                      </div>

                      <NumericFormat
                        maxLength={11}
                        placeholder="0"
                        value={formTax?.RMF_Profit?.selling_price}
                        onChange={(e) => {
                          let value = numberWithoutComma(e.target.value);
                          onChangeRMFProfit(
                            value,
                            "selling_price",
                            formTax,
                            setFormTax
                          );
                        }}
                        thousandSeparator=","
                        inputMode="decimal"
                        pattern="[0-9],*"
                        className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
                      />
                    </div>
                    {/* ราคาทุน */}
                    <div className={STYLE_PARENT_CARD_BALANCE}>
                      <div
                        className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}
                      >
                        ราคาทุน
                      </div>
                      <NumericFormat
                        maxLength={11}
                        placeholder="0"
                        value={formTax?.RMF_Profit?.cost_price}
                        onChange={(e) => {
                          let value = numberWithoutComma(e.target.value);
                          onChangeRMFProfit(
                            value,
                            "cost_price",
                            formTax,
                            setFormTax
                          );
                        }}
                        thousandSeparator=","
                        inputMode="decimal"
                        pattern="[0-9],*"
                        className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
                      />
                    </div>
                    {/* จำนวนเงินส่วนต่างที่ยกเว้นภาษี */}
                    <div className={STYLE_PARENT_CARD_BALANCE}>
                      <div
                        className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}
                      >
                        จำนวนเงินส่วนต่างที่ยกเว้นภาษี
                      </div>
                      <NumericFormat
                        maxLength={11}
                        placeholder="0"
                        value={formTax?.RMF_Profit?.excepting_tax_differnce}
                        onChange={(e) => {
                          let value = numberWithoutComma(e.target.value);
                          onChangeRMFProfit(
                            value,
                            "excepting_tax_differnce",
                            formTax,
                            setFormTax
                          );
                        }}
                        thousandSeparator=","
                        inputMode="decimal"
                        pattern="[0-9],*"
                        className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
                      />
                    </div>
                    {/* จำนวนเงินส่วนต่างที่ ไม่ยกเว้นภาษี */}
                    <div className={STYLE_PARENT_CARD_BALANCE}>
                      <div
                        className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}
                      >
                        จำนวนเงินส่วนต่างที่ไม่ยกเว้นภาษี
                      </div>
                      <NumericFormat
                        maxLength={11}
                        placeholder="0"
                        value={formTax?.RMF_Profit?.non_excepting_tax_differnce}
                        onChange={(e) => {
                          let value = numberWithoutComma(e.target.value);
                          onChangeRMFProfit(
                            value,
                            "non_excepting_tax_differnce",
                            formTax,
                            setFormTax
                          );
                        }}
                        thousandSeparator=","
                        inputMode="decimal"
                        pattern="[0-9],*"
                        className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
                      />
                    </div>
                    {/* ภาษีหัก ณ ที่จ่าย */}
                    <div className={STYLE_PARENT_CARD_BALANCE}>
                      <div
                        className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}
                      >
                        ภาษีหัก ณ ที่จ่าย
                      </div>
                      <NumericFormat
                        maxLength={11}
                        placeholder="0"
                        value={formTax?.RMF_Profit?.withholding_tax}
                        onChange={(e) => {
                          let value = numberWithoutComma(e.target.value);
                          onChangeRMFProfit(
                            value,
                            "withholding_tax",
                            formTax,
                            setFormTax
                          );
                        }}
                        thousandSeparator=","
                        inputMode="decimal"
                        pattern="[0-9],*"
                        className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
                      />
                    </div>
                    {/* เลขผู้จ่ายเงินได้ */}
                    <div className={STYLE_PARENT_CARD_BALANCE}>
                      <div
                        className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 flex items-center gap-[2px]`}
                      >
                        เลขผู้จ่ายเงินได้
                        <ICON_INFO
                          className={` w-4 h-4 text-maingray`}
                          onClick={onClickOpen}
                        />
                      </div>
                      <NumericFormat
                        maxLength={13}
                        placeholder="0"
                        value={formTax?.RMF_Profit?.tax_payer_ID}
                        onChange={(e) => {
                          let value = numberWithoutComma(e.target.value);
                          onChangeRMFProfit(
                            value,
                            "tax_payer_ID",
                            formTax,
                            setFormTax
                          );
                        }}
                        // thousandSeparator=","
                        inputMode="decimal"
                        pattern="[0-9],*"
                        className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
                      />
                    </div>
                  </div>
                </div>
              }
            />

            {/* ------ กำไรจากการขายกองทุนรวมเพื่อการเลี้ยงชีพ (SSF) ------ */}

            <CardMain
              icon={true}
              onClick={() => {
                setOpenSSFIncomeInfo(true);
              }}
              title={
                <div className=" pr-3 flex">
                  กำไรจากการขายกองทุนเพื่อการออม (SSF)
                </div>
              }
              panel={
                <div className=" px-3 -mt-3 space-y-[15px]">
                  <div className=" text-black font-medium text-sm">
                    <div>กรณีมีเงินได้จากหลายที่</div>
                    <div>
                      1. ให้รวมเงินได้ / ภาษีหัก ณ ที่จ่าย เป็นอันเดียว ก่อนกรอก
                    </div>
                    <div>
                      2.ให้ใส่เลขผู้จ่ายเงินได้จากบริษัทที่จ่ายเงิน
                      ได้ให้มากที่สุด
                    </div>
                  </div>
                  <div className=" bg-white rounded-[20px] px-4 pt-3 pb-[14px] mt-4 ">
                    <div className=" flex justify-between">
                      <div className=" text-base text-mainblue font-semibold">
                        ส่วนของผู้มีเงินได้
                      </div>
                    </div>
                    {/* เงินค่าขาย */}
                    <div className={STYLE_PARENT_CARD_BALANCE}>
                      <div
                        className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}
                      >
                        เงินค่าขาย
                      </div>

                      <NumericFormat
                        maxLength={11}
                        placeholder="0"
                        value={formTax?.SSF_Profit?.selling_price}
                        onChange={(e) => {
                          let value = numberWithoutComma(e.target.value);
                          onChangeSSFProfit(
                            value,
                            "selling_price",
                            formTax,
                            setFormTax
                          );
                        }}
                        thousandSeparator=","
                        inputMode="decimal"
                        pattern="[0-9],*"
                        className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
                      />
                    </div>
                    {/* ราคาทุน */}
                    <div className={STYLE_PARENT_CARD_BALANCE}>
                      <div
                        className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}
                      >
                        ราคาทุน
                      </div>
                      <NumericFormat
                        maxLength={11}
                        placeholder="0"
                        value={formTax?.SSF_Profit?.cost_price}
                        onChange={(e) => {
                          let value = numberWithoutComma(e.target.value);
                          onChangeSSFProfit(
                            value,
                            "cost_price",
                            formTax,
                            setFormTax
                          );
                        }}
                        thousandSeparator=","
                        inputMode="decimal"
                        pattern="[0-9],*"
                        className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
                      />
                    </div>
                    {/* จำนวนเงินส่วนต่างที่ยกเว้นภาษี */}
                    <div className={STYLE_PARENT_CARD_BALANCE}>
                      <div
                        className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}
                      >
                        จำนวนเงินส่วนต่างที่ยกเว้นภาษี
                      </div>
                      <NumericFormat
                        maxLength={11}
                        placeholder="0"
                        value={formTax?.SSF_Profit?.excepting_tax_differnce}
                        onChange={(e) => {
                          let value = numberWithoutComma(e.target.value);
                          onChangeSSFProfit(
                            value,
                            "excepting_tax_differnce",
                            formTax,
                            setFormTax
                          );
                        }}
                        thousandSeparator=","
                        inputMode="decimal"
                        pattern="[0-9],*"
                        className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
                      />
                    </div>
                    {/* จำนวนเงินส่วนต่างที่ ไม่ยกเว้นภาษี */}
                    <div className={STYLE_PARENT_CARD_BALANCE}>
                      <div
                        className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}
                      >
                        จำนวนเงินส่วนต่างที่ไม่ยกเว้นภาษี
                      </div>
                      <NumericFormat
                        maxLength={11}
                        placeholder="0"
                        value={formTax?.SSF_Profit?.non_excepting_tax_differnce}
                        onChange={(e) => {
                          let value = numberWithoutComma(e.target.value);
                          onChangeSSFProfit(
                            value,
                            "non_excepting_tax_differnce",
                            formTax,
                            setFormTax
                          );
                        }}
                        thousandSeparator=","
                        inputMode="decimal"
                        pattern="[0-9],*"
                        className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
                      />
                    </div>
                    {/* ภาษีหัก ณ ที่จ่าย */}
                    <div className={STYLE_PARENT_CARD_BALANCE}>
                      <div
                        className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}
                      >
                        ภาษีหัก ณ ที่จ่าย
                      </div>
                      <NumericFormat
                        maxLength={11}
                        placeholder="0"
                        value={formTax?.SSF_Profit?.withholding_tax}
                        onChange={(e) => {
                          let value = numberWithoutComma(e.target.value);
                          onChangeSSFProfit(
                            value,
                            "withholding_tax",
                            formTax,
                            setFormTax
                          );
                        }}
                        thousandSeparator=","
                        inputMode="decimal"
                        pattern="[0-9],*"
                        className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
                      />
                    </div>
                    {/* เลขผู้จ่ายเงินได้ */}
                    <div className={STYLE_PARENT_CARD_BALANCE}>
                      <div
                        className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 flex items-center gap-[2px]`}
                      >
                        เลขผู้จ่ายเงินได้
                        <ICON_INFO
                          className={` w-4 h-4 text-maingray`}
                          onClick={onClickOpen}
                        />
                      </div>
                      <NumericFormat
                        maxLength={13}
                        placeholder="0"
                        value={formTax?.SSF_Profit?.tax_payer_ID}
                        onChange={(e) => {
                          let value = numberWithoutComma(e.target.value);
                          onChangeSSFProfit(
                            value,
                            "tax_payer_ID",
                            formTax,
                            setFormTax
                          );
                        }}
                        // thousandSeparator=","
                        inputMode="decimal"
                        pattern="[0-9],*"
                        className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
                      />
                    </div>
                  </div>
                </div>
              }
            />
            <div className={STYLE_PARENT_BUTTON_TWO}>
              <MTButton
                text="ย้อนกลับ"
                color="bg-white"
                border="border border-maincyan"
                colortext="text-maincyan group-hover:text-white"
                hovercolor="hover:bg-maincyan group "
                width="w-full"
                onClick={() => {
                  NavigateGoBack(navigate);
                }}
              />
              <MTButton
                text={"บันทึก"}
                color="bg-maincyan"
                width="w-full"
                onClick={() => {
                  Submit();
                }}
              />
            </div>
          </div>
        </div>
      </Container>
      {openInfo && (
        <PayerIncomeInfo openInfo={openInfo} setOpenInfo={setOpenInfo} />
      )}
      {open404Info && (
        <Income404Info
          open404Info={open404Info}
          setOpen404Info={setOpen404Info}
        />
      )}

      {open404Info2 && (
        <Income404Info2
          open404Info2={open404Info2}
          setOpen404Info2={setOpen404Info2}
        />
      )}

      {openAssessableIncomeInfo && (
        <AssessableIncomeInfo
          openAssessableIncomeInfo={openAssessableIncomeInfo}
          setOpenAssessableIncomeInfo={setOpenAssessableIncomeInfo}
        />
      )}

      {openRMFIncomeInfo && (
        <RMFIncomeInfo
          openRMFIncomeInfo={openRMFIncomeInfo}
          setOpenRMFIncomeInfo={setOpenRMFIncomeInfo}
        />
      )}
      {openSSFIncomeInfo && (
        <SSFIncomeInfo
          openSSFIncomeInfo={openSSFIncomeInfo}
          setOpenSSFIncomeInfo={setOpenSSFIncomeInfo}
        />
      )}
    </div>
  );
};

export default ViewIncomeInvest;
