import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

const educationStore = (set) => ({
  educationData: [],
  setEducationData: (educationData) => set(() => ({ educationData })),
  clearEducationData: () => set(() => ({ educationData: null })),
});

export const useEducationStore = create(
  persist(educationStore, {
    name: "educationStore",
    storage: createJSONStorage(() => localStorage), // (optional) by default the 'localStorage' is used
  })
);
