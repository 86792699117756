export const ImageMain = ({ src = "", className = "", alt = "", onClick }) => {
  return (
    <div className={className}>
      <img
        src={src}
        className={` w-full h-full `}
        alt={alt}
        onClick={onClick}
      />
    </div>
  );
};
