import { useNavigate } from "react-router-dom";
import { CardMenuPremium } from "../../styles/Card/CardMenuPremium";
import { useState } from "react";
import { STYLE_SPACE_GRAY_MENU } from "../../../../utils/useStyle";

const ViewStepOnePremium = ({ form, setForm }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  return (
    <div className={STYLE_SPACE_GRAY_MENU}>
      <CardMenuPremium
        title={"ข้อมูลส่วนตัวผู้เสียภาษี"}
        img={"../images/profile_tax.png"}
        onClick={() => {
          navigate("/taxpayerInfo");
        }}
        hasDetails={false}
      />
      <CardMenuPremium
        title={"รายได้จากเงินเดือน"}
        img={"../images/saving_expenses.png"}
        onClick={() => {
          navigate("/taxIncomeSalary");
        }}
        hasDetails={true}
        open={open}
        onClickOpen={() => setOpen(!open)}
      />
      <CardMenuPremium
        title={
          <div>
            รายได้จากฟรีแลนซ์ รับจ้างทั่วไป,
            <div>อาชีพอิสระ</div>
          </div>
        }
        img={"../images/tax_freelance.png"}
        onClick={() => {
          navigate("/taxIncomeFreelance");
        }}
        hasDetails={false}
      />
      <CardMenuPremium
        title={"รายได้จากทรัพย์สิน, การทำธุรกิจ"}
        img={"../images/tax_business.png"}
        onClick={() => {
          navigate("/taxIncomeBusiness");
        }}
        hasDetails={false}
      />
      <CardMenuPremium
        title={"รายได้จากการลงทุน"}
        img={"../images/tax_invest.png"}
        onClick={() => {
          navigate("/taxIncomeInvest");
        }}
        hasDetails={false}
      />
      <CardMenuPremium
        title={"รายได้จากมรดกหรือได้รับมา"}
        img={"../images/tax_heritage.png"}
        onClick={() => {
          navigate("/taxIncomeHeritage");
        }}
        hasDetails={false}
      />
    </div>
  );
};

export default ViewStepOnePremium;
