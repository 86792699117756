import { MTButton } from "../../../components/button/MTButton";
import { ImageMain } from "../../../components/img/Img.Main";
import { ICON_RIGHT } from "../../../utils/useIcons";
import { STYLE_HEAD_TITLE } from "../../../utils/useStyle";

export const CardMenu = ({
  title,
  icon,
  onClick,
  color,
  loading,
  colormain,
  padding = "p-3",
  planed = false,
}) => {
  return (
    <>
      {planed && (
        <div
          onClick={onClick}
          className={` ${color}  w-full h-[143px] rounded-[20px] flex flex-col justify-center ${padding} pb-5 cursor-pointer relative  `}
        >
          {/* {planed && (
            <div className=" absolute top-3 w-10/12 left-[8%]">
              <div className=" bg-white rounded-md w-full h-6 flex justify-center gap-1 items-center shadow-md  shadow-black/5 ">
                <ImageMain className=" w-3 h-3" src="/images/bad_face.png" />
                <div className=" text-maingray font-medium  text-[10px]">
                  ยังไม่ได้เริ่มวางแผน
                </div>
              </div>
            </div>
          )} */}

          <ImageMain src={icon} className="w-28 mx-auto -mt-2" alt="" />
          <div className="-mt-4">
            <MTButton
              text={title}
              width="w-full px-0"
              color="bg-white "
              hovercolor={`hover:border ${colormain}`}
              colortext={` ${colormain} font-semibold flex-none`}
              textsize="text-sm"
              loading={loading}
              onClick={onClick}
            />
          </div>
        </div>
      )}
    </>
  );
};

export const CardMenu2 = ({
  title,
  icon,
  onClick,
  color,
  loading,
  colormain,
  padding = "p-3",
  planed = true,
}) => {
  return (
    <>
      {!planed && (
        <div
          onClick={onClick}
          className={` ${color}  w-full h-[143px] rounded-[20px] flex flex-col justify-center ${padding} pb-5 cursor-pointer relative  `}
        >
          {/* {planed && (
            <div className=" absolute top-3 w-10/12 left-[8%]">
              <div className=" bg-white rounded-md w-full h-6 flex justify-center gap-1 items-center shadow-md  shadow-black/5 ">
                <ImageMain className=" w-3 h-3" src="/images/bad_face.png" />
                <div className=" text-maingray font-medium  text-[10px]">
                  ยังไม่ได้เริ่มวางแผน
                </div>
              </div>
            </div>
          )} */}

          <ImageMain src={icon} className="w-28 mx-auto -mt-2" alt="" />
          <div className="-mt-4">
            <MTButton
              text={title}
              width="w-full px-0"
              color="bg-white "
              hovercolor={`hover:border ${colormain}`}
              colortext={` ${colormain} font-semibold flex-none`}
              textsize="text-sm"
              loading={loading}
              onClick={onClick}
            />
          </div>
        </div>
      )}
    </>
  );
};

export const CardMenu3 = ({
  title,
  icon,
  onClick,
  color,
  loading,
  colormain,
  padding = "p-3",
}) => {
  return (
    <div
      // onClick={onClick}
      className={` ${color}  w-full h-[143px] rounded-[20px] flex flex-col justify-center ${padding} pb-5 cursor-pointer relative  `}
    >
      <ImageMain src={icon} className="w-28 mx-auto -mt-2" alt="" />
      <div className="-mt-4">
        <MTButton
          text={title}
          width="w-full px-0"
          color="bg-white "
          hovercolor={`hover:border ${colormain}`}
          colortext={` ${colormain} font-semibold flex-none`}
          textsize="text-sm"
          loading={loading}
          // onClick={onClick}
        />
      </div>
    </div>
  );
};

export const CardProfile = ({
  onClick,
  img,
  head = "ข้อมูลผู้วางแผน",
  title = "อีเมล",
  details = `email@gmail.com`,
  bottom = " -bottom-4 ",
}) => {
  return (
    <div
      onClick={onClick}
      className=" bg-blueopacity w-full rounded-[30px] h-[88px] px-5 pt-2.5 overflow-clip relative cursor-pointer"
    >
      <div className={`${STYLE_HEAD_TITLE} flex items-center`}>
        {head}
        <ICON_RIGHT className={` w-6 h-6 `} />
      </div>
      <div className={` mt-[7px] flex items-center gap-2 `}>
        <div className="text-[10px] text-black font-medium">{title}</div>
        <div className=" font-medium text-maincyan bg-blueopacity z-10 rounded-sm px-1">
          {details}
        </div>
      </div>
      <img
        src={img}
        className={`  w-[144px]  ml-auto absolute right-0 ${bottom} `}
        alt=""
      />
    </div>
  );
};
