import { useEffect, useMemo } from "react";
import { useBalanceStore } from "../../../_store/balanceStore";
import { cn, toFloat2 } from "../../../utils/useFunctions";
import {
  PdfBalanceHeaderItemCard,
  PdfBalanceItemCard,
  PdfCard,
  PdfCardGray,
  PdfCardWhite,
  PdfFamilyHeaderItemCard,
} from "../component/pdf.c.card";
import PdfHeader from "../component/pdf.c.header";
import {
  PdfHeaderCardText,
  PdfHeaderText,
} from "../component/pdf.c.headerText";
import PdfLine from "../component/pdf.c.line";
import {
  STYLE_PAGE,
  STYLE_PDF_ITEM_TOTAL,
  STYLE_PDF_TEXT_MEDIUM_11,
  STYLE_PDF_TEXT_MEDIUM_13,
  STYLE_PDF_TEXT_MEDIUM_BLUE_11,
  STYLE_PDF_TEXT_NORMAL_10,
  STYLE_PDF_TEXT_NORMAL_11,
  STYLE_PDF_TEXT_NORMAL_BLUE_10,
  STYLE_PDF_TEXT_NORMAL_BLUE_11,
  STYLE_PDF_TEXT_NORMAL_BLUE_12,
  STYLE_PDF_TEXT_SEMIBOLD_BLUE_12,
  STYLE_PDF_TEXT_SEMIBOLD_BLUE_14,
} from "../styles/pdf.style";
import {
  key_property_invest_category,
  options_property_asset,
  options_property_intangible,
  options_property_invest,
  options_property_private,
} from "../../balance/functions";
import _ from "lodash";
import numeral from "numeral";
import {
  calPropertyLocalOption,
  getAllBalanceTotal,
  getBalanceItems,
} from "../lib/pdf.lib";
import PdfFooter from "../component/pdf.c.footer";
import { useInvestStore } from "../../../_store/investStore";
import FamilyBarChart from "../component/pdf.c.familyChart";
import ViewTableFamily from "../../insuarance_plan/component/VITableFamily.Main";
import { StatusCard } from "../../insuarance_plan/styles/Card";
import { ImageMain } from "../../../components/img/Img.Main";
import { DonutChartInvestMain, RandomColor } from "../../../components/chart/DonutChartMain";

function PdfPageInvestOne({ page }) {
  const balanceData = useBalanceStore((state) => state?.balanceData);

  const investData = useInvestStore((state) => state.investData);

  const result = investData?.sum_score;

  const checkLevel = () => {
    if (result < 15) {
      return "1";
    } else if (result > 15 && result < 21) {
      return "2";
    } else if (result > 22 && result < 29) {
      return "3";
    } else if (result > 30 && result < 36) {
      return "4";
    } else {
      return "5";
    }
  };

  const checkLevelTitle = () => {
    if (result < 15) {
      return "รับความเสี่ยงระดับต่ำ";
    } else if (result > 15 && result < 21) {
      return "รับความเสี่ยงระดับต่ำ-ปานกลาง";
    } else if (result > 22 && result < 29) {
      return "รับความเสี่ยงระดับปานกลาง";
    } else if (result > 30 && result < 36) {
      return "รับความเสี่ยงระดับปานกลาง-สูง";
    } else {
      return "รับความเสี่ยงระดับสูง";
    }
  };

  const investRecommendItems = useMemo(() => {
    if (result < 15) {
      return [
        {
          label: "พอร์ตเป้าหมายระยะสั้น 0-3 ปี",
          items: [
            {
              label: "ตราสารหนี้",
              value: 50,
            },
            {
              label: "เงินฝาก",
              value: 50,
            },
          ],
          returns: [
            {
              label: "ผลตอบแทนคาดหวัง",
              value: "1.23%",
            },
            {
              label: "ผลตอบแทนปรับฐาน (-1SD)",
              value: "0.80%",
            },
            {
              label: "ผลตอบแทนปรับฐาน (-2SD)",
              value: "0.40%",
            },
          ],
        },
        {
          label: "พอร์ตเป้าหมายระยะกลาง 3-7 ปี",
          items: [
            {
              label: "ตราสารทุน",
              value: 10,
            },
            {
              label: "ตราสารหนี้",
              value: 90,
            },
          ],
          returns: [
            {
              label: "ผลตอบแทนคาดหวัง",
              value: "2.56%",
            },
            {
              label: "ผลตอบแทนปรับฐาน (-1SD)",
              value: "0.00%",
            },
            {
              label: "ผลตอบแทนปรับฐาน (-2SD)",
              value: "-2.60%",
            },
          ],
        },
        {
          label: "พอร์ตเป้าหมายระยะยาว 7 ปีขึ้นไป",
          items: [
            {
              label: "ตราสารทุน",
              value: 20,
            },
            {
              label: "ตราสารหนี้",
              value: 80,
            },
          ],
          returns: [
            {
              label: "ผลตอบแทนคาดหวัง",
              value: "2.73%",
            },
            {
              label: "ผลตอบแทนปรับฐาน (-1SD)",
              value: "-1.20%",
            },
            {
              label: "ผลตอบแทนปรับฐาน (-2SD)",
              value: "-5.20%",
            },
          ],
        },
      ];
    } else if (result > 15 && result < 21) {
      return [
        {
          label: "พอร์ตเป้าหมายระยะสั้น 0-3 ปี",
          items: [
            {
              label: "ตราสารทุน",
              value: 15,
            },
            {
              label: "ตราสารหนี้",
              value: 75,
            },
            {
              label: "เงินฝาก",
              value: 10,
            },
          ],
          returns: [
            {
              label: "ผลตอบแทนคาดหวัง",
              value: "1.34%",
            },
            {
              label: "ผลตอบแทนปรับฐาน (-1SD)",
              value: "-0.50%",
            },
            {
              label: "ผลตอบแทนปรับฐาน (-2SD)",
              value: "-2.30%",
            },
          ],
        },
        {
          label: "พอร์ตเป้าหมายระยะกลาง 3-7 ปี",
          items: [
            {
              label: "ตราสารทุน",
              value: 30,
            },
            {
              label: "ตราสารหนี้",
              value: 70,
            },
          ],
          returns: [
            {
              label: "ผลตอบแทนคาดหวัง",
              value: "3.82%",
            },
            {
              label: "ผลตอบแทนปรับฐาน (-1SD)",
              value: "-1.90%",
            },
            {
              label: "ผลตอบแทนปรับฐาน (-2SD)",
              value: "-7.60%",
            },
          ],
        },
        {
          label: "พอร์ตเป้าหมายระยะยาว 7 ปีขึ้นไป",
          items: [
            {
              label: "ตราสารทุน",
              value: 30,
            },
            {
              label: "ตราสารหนี้",
              value: 70,
            },
          ],
          returns: [
            {
              label: "ผลตอบแทนคาดหวัง",
              value: "3.82%",
            },
            {
              label: "ผลตอบแทนปรับฐาน (-1SD)",
              value: "-1.90%",
            },
            {
              label: "ผลตอบแทนปรับฐาน (-2SD)",
              value: "-7.60%",
            },
          ],
        },
      ];
    } else if (result > 22 && result < 29) {
      return [
        {
          label: "พอร์ตเป้าหมายระยะสั้น 0-3 ปี",
          items: [
            {
              label: "ตราสารทุน",
              value: 25,
            },
            {
              label: "ตราสารหนี้",
              value: 75,
            },
          ],
          returns: [
            {
              label: "ผลตอบแทนคาดหวัง",
              value: "2.49%",
            },
            {
              label: "ผลตอบแทนปรับฐาน (-1SD)",
              value: "-1.10%",
            },
            {
              label: "ผลตอบแทนปรับฐาน (-2SD)",
              value: "-4.70%",
            },
          ],
        },
        {
          label: "พอร์ตเป้าหมายระยะกลาง 3-7 ปี",
          items: [
            {
              label: "ตราสารทุน",
              value: 30,
            },
            {
              label: "ตราสารหนี้",
              value: 65,
            },
            {
              label: "สินทรัพย์ทางเลือก",
              value: 5,
            },
          ],
          returns: [
            {
              label: "ผลตอบแทนคาดหวัง",
              value: "4.43%",
            },
            {
              label: "ผลตอบแทนปรับฐาน (-1SD)",
              value: "-1.90%",
            },
            {
              label: "ผลตอบแทนปรับฐาน (-2SD)",
              value: "-8.10%",
            },
          ],
        },
        {
          label: "พอร์ตเป้าหมายระยะยาว 7 ปีขึ้นไป",
          items: [
            {
              label: "ตราสารทุน",
              value: 30,
            },
            {
              label: "ตราสารหนี้",
              value: 60,
            },
            {
              label: "สินทรัพย์ทางเลือก",
              value: 10,
            },
          ],
          returns: [
            {
              label: "ผลตอบแทนคาดหวัง",
              value: "4.33%",
            },
            {
              label: "ผลตอบแทนปรับฐาน (-1SD)",
              value: "-2.50%",
            },
            {
              label: "ผลตอบแทนปรับฐาน (-2SD)",
              value: "-9.40%",
            },
          ],
        },
      ];
    } else if (result > 30 && result < 36) {
      return [
        {
          label: "พอร์ตเป้าหมายระยะสั้น 0-3 ปี",
          items: [
            {
              label: "ตราสารทุน",
              value: 25,
            },
            {
              label: "ตราสารหนี้",
              value: 75,
            },
          ],
          returns: [
            {
              label: "ผลตอบแทนคาดหวัง",
              value: "2.49%",
            },
            {
              label: "ผลตอบแทนปรับฐาน (-1SD)",
              value: "-1.10%",
            },
            {
              label: "ผลตอบแทนปรับฐาน (-2SD)",
              value: "-4.70%",
            },
          ],
        },
        {
          label: "พอร์ตเป้าหมายระยะกลาง 3-7 ปี",
          items: [
            {
              label: "ตราสารทุน",
              value: 40,
            },
            {
              label: "ตราสารหนี้",
              value: 50,
            },

            {
              label: "สินทรัพย์ทางเลือก",
              value: 10,
            },
          ],
          returns: [
            {
              label: "ผลตอบแทนคาดหวัง",
              value: "5.41%",
            },
            {
              label: "ผลตอบแทนปรับฐาน (-1SD)",
              value: "-3.20%",
            },
            {
              label: "ผลตอบแทนปรับฐาน (-2SD)",
              value: "-11.80%",
            },
          ],
        },
        {
          label: "พอร์ตเป้าหมายระยะยาว 7 ปีขึ้นไป",
          items: [
            {
              label: "ตราสารทุน",
              value: 40,
            },
            {
              label: "ตราสารหนี้",
              value: 40,
            },
            {
              label: "สินทรัพย์ทางเลือก",
              value: 20,
            },
          ],
          returns: [
            {
              label: "ผลตอบแทนคาดหวัง",
              value: "5.93%",
            },
            {
              label: "ผลตอบแทนปรับฐาน (-1SD)",
              value: "-3.70%",
            },
            {
              label: "ผลตอบแทนปรับฐาน (-2SD)",
              value: "-13.30%",
            },
          ],
        },
      ];
    } else {
      return [
        {
          label: "พอร์ตเป้าหมายระยะสั้น 0-3 ปี",
          items: [
            {
              label: "ตราสารทุน",
              value: 25,
            },
            {
              label: "ตราสารหนี้",
              value: 75,
            },
          ],
          returns: [
            {
              label: "ผลตอบแทนคาดหวัง",
              value: "2.49%",
            },
            {
              label: "ผลตอบแทนปรับฐาน (-1SD)",
              value: "-1.10%",
            },
            {
              label: "ผลตอบแทนปรับฐาน (-2SD)",
              value: "-4.70%",
            },
          ],
        },
        {
          label: "พอร์ตเป้าหมายระยะกลาง 3-7 ปี",
          items: [
            {
              label: "ตราสารทุน",
              value: 40,
            },
            {
              label: "ตราสารหนี้",
              value: 50,
            },
            {
              label: "สินทรัพย์ทางเลือก",
              value: 40,
            },
          ],
          returns: [
            {
              label: "ผลตอบแทนคาดหวัง",
              value: "5.41%",
            },
            {
              label: "ผลตอบแทนปรับฐาน (-1SD)",
              value: "-3.20%",
            },
            {
              label: "ผลตอบแทนปรับฐาน (-2SD)",
              value: "-11.80%",
            },
          ],
        },
        {
          label: "พอร์ตเป้าหมายระยะยาว 7 ปีขึ้นไป",
          items: [
            {
              label: "ตราสารทุน",
              value: 80,
            },
            {
              label: "สินทรัพย์ทางเลือก",
              value: 20,
            },
          ],
          returns: [
            {
              label: "ผลตอบแทนคาดหวัง",
              value: "8.79%",
            },
            {
              label: "ผลตอบแทนปรับฐาน (-1SD)",
              value: "-7.50%",
            },
            {
              label: "ผลตอบแทนปรับฐาน (-2SD)",
              value: "-23.80%",
            },
          ],
        },
      ];
    }
  }, []);

  const investItems = useMemo(() => {
    if (!balanceData?.property_invest_list?.length) return [];

    const filterPropertyInvest = balanceData?.property_invest_list?.filter(
      (item) => item?.options_property_invest_category
    );

    const groupInvest = _.groupBy(
      filterPropertyInvest,
      "options_property_invest_category"
    );

    const convertGroupInvest = Object.keys(groupInvest).map((key) => {
      const amount = groupInvest[key].reduce((prev, curr) => {
        const itemKeys = Object.keys(curr);
        for (const itemKey of itemKeys) {
          const itemValue = curr[itemKey];
          if (_.isArray(itemValue)) {
            return (
              prev + itemValue.reduce((prev, curr) => prev + curr.amount, 0)
            );
          }
        }
      }, 0);
      return {
        key,
        label: key_property_invest_category[key],
        value: amount,
        amount,
      };
    });

    const newItems = [];
    const total = convertGroupInvest.reduce(
      (prev, curr) => prev + curr.value,
      0
    );

    for (const convertGroupInvestItem of convertGroupInvest) {
      const createItem = {
        label: convertGroupInvestItem.label,
        value: (convertGroupInvestItem.value / total) * 100,
        amount: convertGroupInvestItem.amount,
      };
      newItems.push(createItem);
    }

    // clean data
    const cleanItems = newItems.map((item) => {
      return {
        label: item.label,
        value: toFloat2(item.value),
        amount: item.amount,
      };
    });

    const sortItems = [
      {
        label: "ตราสารทุน",
      },
      {
        label: "ตราสารหนี้",
      },
      {
        label: "เงินฝาก",
      },
      {
        label: "สินทรัพย์ทางเลือก",
      },
    ];

    const sortItemsMap = sortItems.map((sortItem) => {
      const findItem = cleanItems.find((item) => item.label === sortItem.label);
      return findItem;
    });

    return sortItemsMap;
  }, [balanceData]);

  return (
    <div className={cn(STYLE_PAGE)}>
      <PdfHeader />
      <PdfLine />
      <PdfHeaderText title="การจัดทัพการลงทุน" icon="/images/revenue.svg" />
      {/* การจัดทัพการลงทุน */}
      <PdfCard className={"flex flex-col space-y-3"}>
        <div className="flex justify-between">
          <div className={cn(STYLE_PDF_TEXT_MEDIUM_11)}>สัดส่วนลงทุนของคุณ</div>
          {/* <div className={cn(STYLE_PDF_TEXT_SEMIBOLD_BLUE_12)}>
            ระดับความสามารถในการรับความเสี่ยงของคุณคือ {checkLevelTitle()}
          </div> */}
        </div>
        <PdfCardWhite className={cn("flex space-x-2 justify-center")}>
          <DonutChartInvestMain
            className={cn("w-[140px] h-[140px] pt-0")}
            classNameText={cn(STYLE_PDF_TEXT_NORMAL_11)}
            width={20}
            height={20}
            items={investItems}
            isAmount
          />

          <div className={cn(STYLE_PDF_TEXT_MEDIUM_11)}>
            พอร์ตเป้าหมายระยะยาว 7 ปีขึ้นไป 
          </div>
        </PdfCardWhite>
      </PdfCard>

      <PdfCard className={"flex flex-col space-y-3"}>
        <div className="flex justify-between">
          <div className={cn(STYLE_PDF_TEXT_MEDIUM_11)}>
            สัดส่วนการลงทุนที่แนะนำ
          </div>
          <div className={cn(STYLE_PDF_TEXT_SEMIBOLD_BLUE_12, "text-[13px]")}>
            ประเภทนักลงทุน{checkLevelTitle()} (ระดับ {checkLevel()})
          </div>
        </div>

        <div className={cn("grid grid-cols-3 gap-3")}>
          {investRecommendItems.map((item, index) => {
            return (
              <PdfCardWhite
                key={index}
                className={cn("flex flex-col items-center justify-center")}
              >
                <div className={cn(STYLE_PDF_TEXT_MEDIUM_11)}>{item.label}</div>
                <DonutChartInvestMain
                  className={cn("w-[140px] h-[140px] flex justify-center pt-0")}
                  classNameText={cn(STYLE_PDF_TEXT_NORMAL_11, "!text-[9px]")}
                  width={20}
                  height={20}
                  items={item.items}
                />
                <div className="mt-1"></div>
                <div className="flex bg-gray-100 mt-auto rounded p-2 space-y-1 flex-col items-center">
                  {item?.returns.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className={cn(
                          "flex space-x-2 text-[8px] justify-between w-full"
                        )}
                      >
                        <div>{item?.label}</div>
                        <div>{item?.value}</div>
                      </div>
                    );
                  })}
                </div>
              </PdfCardWhite>
            );
          })}
        </div>
      </PdfCard>
      <PdfFooter page={page} />
    </div>
  );
}

export default PdfPageInvestOne;
