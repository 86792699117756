import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { first_reload } from "../../tax_plan/functions/firstLoad";
import { HeaderTitle } from "../../../components/header/Header";
import { STYLE_REPORT_CONTAIN } from "../../../utils/useStyle";
import { HeaderReport } from "../components/header.main";
import { MTButton } from "../../../components/button/MTButton";
import { useRetireStore } from "../../../_store/retireStore";
import { TableReport } from "../components/table.main";
import { ImageMain } from "../../../components/img/Img.Main";
import { CardReport } from "../components/cardMenu";
import { RetireAfterSection, RetireBeforeSection } from "./report.v.overview";
import {
  dataAge,
  dataAgeAF,
  loopInvestmentAssets,
  loopInvestmentAssetsAF,
} from "../../retirement_plan/functions/functionRetriementPlan";
import {
  ICON_CHECK,
  ICON_CLOSE,
  ICON_SAVING,
  ICON_STAR,
  ICON_WARNING,
} from "../../../utils/useIcons";
import { cn } from "../../../utils/useFunctions";
import _ from "lodash";
import numeral from "numeral";
import { calPMT } from "../../education/functions/function";
import LineChartMain from "../../../components/chart/LineChartMain";
import { loopInvestmentAssetsAFSensitive } from "../../retirement_plan/functions/functionRetrementSensitivePlan";

const { default: Container } = require("../../../components/layout/Container");

const InfoSection = ({ retireData, table_income }) => {
  return (
    <div>
      <div className=" text-xl font-semibold text-center">ข้อมูลก่อนวางแผน</div>
      <div className=" grid grid-cols-3 justify-center items-center my-5">
        <div className=" mx-auto flex flex-col justify-center items-center gap-1">
          <img
            alt="present_age"
            src="/images/present_age2.png"
            className=" mx-auto w-[50px] h-[50px]"
          />
          <div className="mt-2 text-sm font-semibold text-maincyan">
            อายุปัจจุบัน
          </div>
          <div className="text-mainblue font-bold">
            {retireData?.age_current || 25} ปี
          </div>
        </div>
        <div className=" mx-auto flex flex-col justify-center items-center gap-1">
          <img
            alt=""
            src="/images/old_age2.png"
            className=" mx-auto w-[50px] h-[50px]"
          />
          <div className="mt-2 text-sm font-semibold text-[#B95940]">
            อายุเกษียณ
          </div>
          <div className="text-mainblue font-bold">
            {retireData?.age_retire || 60} ปี
          </div>
        </div>
        <div className=" mx-auto flex flex-col justify-center items-center gap-1">
          <img
            alt=""
            src="/images/dead2.png"
            className=" mx-auto w-[50px] h-[50px]"
          />
          <div className="mt-2 text-sm font-semibold text-mainorange">
            สิ้นอายุขัย
          </div>
          <div className="text-mainblue font-bold text-center">
            {retireData?.age_dead || 80} ปี
          </div>
        </div>
      </div>
      <div className="bg-gray50 rounded-[20px] w-full h-ful p-3">
        <TableReport
          isHeadMonth={false}
          headTable="รายการ"
          data={table_income}
        />

        <div className=" space-y-2.5 mt-2.5">
          <div className=" flex items-center justify-between ">
            <div className=" flex gap-1 items-center">
              <ImageMain src="/images/cash-report.png" className="w-6 h-6" />
              <div className=" font-semibold text-sm">บำนาญต่อเดือน</div>
            </div>
            <div className=" text-mainblue text-sm font-medium">
              {numeral(retireData?.pension)?.format("0,0")} บาท
            </div>
          </div>
          <div className=" flex items-center justify-between ">
            <div className=" flex gap-1 items-center">
              <ImageMain src="/images/chart-report.png" className="w-6 h-6" />
              <div className=" font-semibold text-sm">สินทรัพย์ลงทุน</div>
            </div>
            <div className=" text-mainblue text-sm font-medium">
              {numeral(retireData?.investment_assets)?.format("0,0")} บาท
            </div>
          </div>
          <div className=" flex items-center justify-between ">
            <div className=" flex gap-1 items-center">
              <ImageMain src="/images/chart-report.png" className="w-6 h-6" />
              <div className=" font-semibold text-sm">
                ผลตอบแทนจากการลงทุน
                <div>ก่อนเกษียณต่อปี (%)</div>
              </div>
            </div>
            <div className=" text-mainblue text-sm font-medium">
              {retireData?.investment_bf_retirement}%
            </div>
          </div>
          <div className=" flex items-center justify-between ">
            <div className=" flex gap-1 items-center">
              <ImageMain src="/images/chart-report.png" className="w-6 h-6" />
              <div className=" font-semibold text-sm">
                ผลตอบแทนจากการลงทุน
                <div>หลังเกษียณต่อปี (%)</div>
              </div>
            </div>
            <div className=" text-mainblue text-sm font-medium">
              {retireData?.investment_af_retirement}%
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ReportRetirePage = () => {
  const retireData = useRetireStore((state) => state.retireData);
  const navigate = useNavigate();

  console.log("aaa", retireData);
  const table_income = [
    // เงินเดือน

    {
      source: "เงินเดือน (บาท/เดือน)",
      yearly: numeral(retireData?.salary).format("0,0"),
      icon: "/images/finance_income2.png",
      head: "เงินเดือน",
    },
    {
      source: "เงินเดือนที่เพิ่มขึ้นต่อไป",
      percent: retireData?.salary_peryear,
    },
    {
      source: "รายจ่าย (บาท/เดือน)",
      yearly: numeral(retireData?.out_come).format("0,0"),
      icon: "/images/outcome-report.png",
      head: "รายจ่าย",
    },
    {
      source: "คงเหลือ",
      yearly: numeral(retireData?.outcome_remain).format("0,0"),
    },
    {
      source: "รายจ่ายที่เพิ่มขึ้นต่อไป",
      percent: retireData?.out_come_peryear,
    },
  ];
  // วางแผนเกษียณ
  const getInvestSum = loopInvestmentAssets();
  const lastResultBF = getInvestSum[getInvestSum?.length - 1];
  const retirementAge = retireData?.age_retire;
  const retireBF = getInvestSum.find(
    (result) => result.age === retirementAge
  )?.results;
  const getremainBF = getInvestSum?.filter((thisRemain, i) => {
    if (thisRemain?.results <= 0) return { ...thisRemain };
  })?.[0];
  const remainBF = getremainBF?.age - 1;
  const beforeDiffYear = retireData?.age_retire - retireData?.age_current;
  const sumBeforeRetireInvest = useMemo(() => {
    const convertedMonth = beforeDiffYear * 12;
    const sumPMT = calPMT(
      retireData?.investment_bf_retirement / 100 / 12,
      convertedMonth,
      -lastResultBF?.results * -1
    );
    return sumPMT;
  }, [retireData]);
  const strategyChange = retireData?.strategy_sum ? true : false;

  const dataPlanAF = retireData?.af_investment_assets?.map(
    (af_investment_assets) => {
      return af_investment_assets?.results;
    }
  );

  const getInvestSumAF = loopInvestmentAssetsAF();
  const lastResultAF = getInvestSumAF[getInvestSumAF?.length - 1];
  const getremainAF = getInvestSumAF?.filter((thisRemain, i) => {
    if (thisRemain?.results <= 0) return { ...thisRemain };
  })?.[0];
  const remainAF = Number(getremainAF?.age) - 1;
  const afterDiffYear =
    retireData?.strategy_age_retire - retireData?.age_current;

  const convertYearToMonth = (year) => {
    return year * 12;
  };

  const sumAfterRetireInvest = useMemo(() => {
    const convertedMonth = convertYearToMonth(afterDiffYear);
    const sumPMT = calPMT(
      retireData?.strategy_investment_bf_retirement / 100 / 12,
      convertedMonth,
      -lastResultAF?.results * -1
    );
    return sumPMT;
  }, [retireData]);

  const dataPlanBf = retireData?.bf_investment_assets?.map(
    (bf_investment_assets) => {
      return bf_investment_assets?.results;
    }
  );
  const getDetailBF = () => {
    if (strategyChange) {
      if (lastResultBF?.results > 0) {
        return [
          {
            icon: <ICON_CHECK className=" w-[14px] h-[14px] text-maincyan" />,
            render: () => {
              return (
                <div className=" flex gap-1">
                  <div className="text-[10px] ">
                  เริ่มวางแผนเกษียณตอนอายุ{" "}
                  </div>
                  <div
                    className={cn("text-[10px] font-semibold flex space-x-1")}
                  >
                    <div className="">
                      {_.isNaN(retireData?.age_current)
                        ? "-"
                        : retireData?.age_current}
                    </div>
                    <span className="text-mainblue font-normal">ปี</span>
                  </div>
                </div>
              );
            },
          },
          {
            icon: (
              <ICON_WARNING className=" w-[14px] h-[14px] text-mainyellow" />
            ),
            render: () => {
              return (
                <div className=" flex gap-1">
                  <div className="text-[10px] ">สามารถใช้จนได้ถึงอายุ</div>
                  <div className={cn("text-[10px] flex space-x-1")}>
                    <div className="font-semibold">
                      {retireData?.strategy_age_dead
                        ? retireData?.strategy_age_dead
                        : retireData?.age_dead || "-"}
                    </div>
                    <span className="text-mainblue font-normal">ปี</span>
                    <div>แต่อย่าประมาทนะ</div>
                  </div>
                </div>
              );
            },
            // title: "สามารถใช้จนได้ถึงอายุ",
            // value: retireData?.strategy_age_dead
            //   ? retireData?.strategy_age_dead
            //   : retireData?.age_dead,
            // unit: "ปี แต่อย่าประมาทนะ",
          },
        ];
      }
      return [
        {
          icon: (
            <ICON_CHECK className=" w-[14px] h-[14px] text-maincyan flex-none" />
          ),
          render: () => {
            return (
              <div className=" flex gap-1">
                <div className="text-[10px] ">
                  คาดการณ์สินทรัพย์เพื่อการลงทุน ณ อายุ{" "}
                </div>
                <div className={cn("text-[10px] flex space-x-1")}>
                  <div className="font-semibold">
                    {_.isNaN(retireData?.age_current)
                      ? "-"
                      : retireData?.age_current}{" "}
                    ปี
                  </div>
                </div>
              </div>
            );
          },
        },
        {
          icon: <ICON_WARNING className=" w-[14px] h-[14px] text-mainyellow" />,
          render: () => {
            return (
              <div className=" flex flex-col">
                <div className="flex gap-1">
                  <div className="text-[10px] ">สามารถใช้จนได้ถึงอายุ</div>
                  <div className={cn("text-[10px] flex space-x-1")}>
                    <div className="font-semibold">
                      {_.isNaN(remainBF) ? "-" : remainBF} ปี
                    </div>
                  </div>
                </div>
              </div>
            );
          },
        },
        {
          icon: <ICON_CLOSE className=" w-[14px] h-[14px] text-maincyan" />,
          render: () => {
            return (
              <div className="flex flex-col">
                <div className=" flex gap-1">
                  <div className="text-[10px] ">
                    ถ้าต้องการมีเงินใช้จ่ายจนถึงอายุ{" "}
                  </div>
                  <div className={cn("text-[10px] font-semibold")}>
                    {_.isNaN(retireData?.age_dead) ? "-" : retireData?.age_dead}{" "}
                    ปี
                  </div>
                </div>
                <div>
                  <div className="text-[10px] ">
                    ยังขาดอยู่ทั้งหมด{" "}
                    <span className=" font-medium text-black">
                      {lastResultBF?.results < 0
                        ? numeral(lastResultBF?.results * -1)?.format("0,0")
                        : numeral(lastResultBF?.results)?.format("0,0")}
                      {""} บาท
                    </span>
                  </div>
                </div>
              </div>
            );
          },
        },
        {
          icon: <ICON_STAR className=" w-[14px] h-[14px] text-maingreen" />,
          render: () => {
            return (
              <div className="flex flex-col">
                <div className=" flex gap-1">
                  <div className="text-[10px] ">ควรวางแผนลงทุนผลตอบแทน </div>
                  <div className={cn("text-[10px] font-semibold")}>
                    {_.isNaN(retireData?.investment_bf_retirement)
                      ? ""
                      : retireData?.investment_bf_retirement}{" "}
                    %
                  </div>
                </div>
                <div className="text-[10px] flex space-x-1">
                  <div>เป็นระยะเวลา</div>
                  <span className=" font-semibold">
                    {_.isNaN(beforeDiffYear) ? "-" : beforeDiffYear} ปี
                  </span>{" "}
                  <div>เดือนละ</div>
                  <span className=" font-semibold text-mainblue">
                    {numeral(sumBeforeRetireInvest)?.format("0,0")} บาท
                  </span>
                </div>
              </div>
            );
          },
        },
      ];
    } else {
      if (lastResultBF?.results > 0) {
        return [
          {
            icon: <ICON_CHECK className=" w-[14px] h-[14px] text-maincyan" />,
            render: () => {
              return (
                <div className="text-[10px] flex flex-col gap-1">
                  <div className=" ">
                    คาดการณ์สินทรัพย์เพื่อการลงทุน ณ วันเกษียณอายุ
                  </div>
                  <div className="flex">
                    <span className="font-medium mr-1">
                      {_.isNaN(retireData?.age_retire)
                        ? "-"
                        : retireData?.age_retire}{" "}
                      ปี
                    </span>
                    <div className="flex space-x-1">
                      <div>จำนวน</div>
                      <span className=" font-medium text-mainblue">
                        {numeral(retireBF)?.format("0,0.00")}
                      </span>
                    </div>
                  </div>
                </div>
              );
            },
          },
          {
            icon: (
              <ICON_WARNING className=" w-[14px] h-[14px] text-mainyellow" />
            ),
            render: () => {
              return (
                <div className=" flex gap-1">
                  <div className="text-[10px] ">สามารถใช้จนได้ถึงอายุ</div>
                  <div className={cn("text-[10px] flex space-x-1")}>
                    <div className="font-semibold">
                      {_.isNaN(retireData?.age_dead)
                        ? "-"
                        : retireData?.age_dead}
                    </div>
                    <span className="text-mainblue font-normal">ปี</span>
                    <div>แต่อย่าประมาทนะ</div>
                  </div>
                </div>
              );
            },
          },
        ];
      }
      return [
        {
          icon: <ICON_WARNING className=" w-[14px] h-[14px] text-mainyellow" />,
          render: () => {
            return (
              <div className=" flex gap-1">
                <div className="text-[10px] ">สามารถใช้จนได้ถึงอายุ</div>
                <div className={cn("text-[10px] flex space-x-1")}>
                  <div className="font-semibold">
                    {" "}
                    {_.isNaN(remainBF) ? "-" : remainBF} ปี
                  </div>
                </div>
              </div>
            );
          },
        },
        {
          icon: <ICON_CLOSE className=" w-[14px] h-[14px] text-mainred" />,
          render: () => {
            return (
              <div className="flex flex-col">
                <div className=" flex gap-1 flex-wrap ">
                  <div className="text-[10px] ">
                    ถ้าต้องการมีเงินใช้จ่ายจนถึงอายุ
                  </div>
                  <div className={cn("text-[10px] flex space-x-1")}>
                    <div className="font-semibold">
                      {_.isNaN(retireData?.age_dead)
                        ? "-"
                        : retireData?.age_dead}{" "}
                      ปี
                    </div>
                  </div>
                </div>
                <div className="flex gap-1 text-[10px]">
                  <div className="  text-[10px]">ยังขาดอยู่ทั้งหมด</div>
                  <div
                    className={cn("text-[10px] font-semibold text-black")}
                  >
                    {_.isNaN(lastResultBF?.results)
                      ? "-"
                      : numeral(
                          lastResultBF?.results < 0
                            ? lastResultBF?.results * -1
                            : lastResultBF?.results
                        ).format("0,0")}{" "}
                    บาท
                  </div>
                </div>
              </div>
            );
          },
        },
        {
          icon: <ICON_STAR className=" w-[14px] h-[14px] text-maincyan" />,
          render: () => {
            return (
              <div className="flex flex-col">
                <div className=" flex gap-1">
                  <div className="text-[10px] ">ควรวางแผนลงทุนผลตอบแทน</div>
                </div>
                <div className={cn("text-[10px] font-semibold")}>
                  {retireData?.investment_bf_retirement}
                  <span className=" font-normal"> เป็นระยะเวลา </span>
                  <span className="font-semibold">
                    {_.isNaN(beforeDiffYear) ? "-" : beforeDiffYear} ปี{" "}
                  </span>
                  <span className="font-normal">เดือนละ </span>
                  <span className="text-mainblue">
                    {_.isNaN(sumBeforeRetireInvest)
                      ? "-"
                      : numeral(sumBeforeRetireInvest)?.format("0,0")}
                  </span>
                </div>
              </div>
            );
          },
        },
      ];
    }
  };

  const getDetailAF = () => {
    if (strategyChange) {
      if (lastResultAF?.results > 0) {
        return [
          {
            icon: <ICON_CHECK className=" w-[14px] h-[14px] text-maincyan" />,
            render: () => {
              return (
                <div className=" flex gap-1">
                  <div className="text-[10px] ">
                    เริ่มวางแผนเกษียณตอนอายุ{" "}
                  </div>
                  <div
                    className={cn("text-[10px] font-semibold flex space-x-1")}
                  >
                    <div className="">
                      {_.isNaN(retireData?.age_current)
                        ? "-"
                        : retireData?.age_current}{" "}
                      ปี
                    </div>
                  </div>
                </div>
              );
            },
          },
          {
            icon: (
              <ICON_WARNING className=" w-[14px] h-[14px] text-mainyellow" />
            ),
            render: () => {
              return (
                <div className=" flex gap-1">
                  <div className="text-[10px] ">สามารถใช้จนได้ถึงอายุ</div>
                  <div className={cn("text-[10px] flex space-x-1")}>
                    <div className="font-semibold">
                      {retireData?.strategy_age_dead
                        ? retireData?.strategy_age_dead
                        : retireData?.age_dead || "-"}
                    </div>
                    <span className="text-mainblue font-normal">ปี</span>
                    <div>แต่อย่าประมาทนะ</div>
                  </div>
                </div>
              );
            },
          },
        ];
      }
      return [
        {
          icon: (
            <ICON_CHECK className=" w-[14px] h-[14px] text-maincyan flex-none" />
          ),
          render: () => {
            return (
              <div className=" flex gap-1">
                <div className="text-[10px] ">
                เริ่มวางแผนเกษียณตอนอายุ{" "}
                </div>
                <div className={cn("text-[10px] flex space-x-1")}>
                  <div className="font-semibold">
                    {_.isNaN(retireData?.age_current)
                      ? "-"
                      : retireData?.age_current}{" "}
                    ปี
                  </div>
                </div>
              </div>
            );
          },
        },
        {
          icon: <ICON_WARNING className=" w-[14px] h-[14px] text-mainyellow" />,
          render: () => {
            return (
              <div className=" flex flex-col">
                <div className="flex gap-1">
                  <div className="text-[10px] ">สามารถใช้จนได้ถึงอายุ</div>
                  <div className={cn("text-[10px] flex space-x-1")}>
                    <div className="font-semibold">
                      {_.isNaN(remainAF) ? "-" : remainAF} ปี
                    </div>
                  </div>
                </div>
              </div>
            );
          },
        },
        {
          icon: <ICON_CLOSE className=" w-[14px] h-[14px] text-maincyan" />,
          render: () => {
            return (
              <div className="flex flex-col">
                <div className=" flex gap-1">
                  <div className="text-[10px] ">
                    ถ้าต้องการมีเงินใช้จ่ายจนถึงอายุ{" "}
                  </div>
                  <div className={cn("text-[10px] font-semibold")}>
                    {_.isNaN(retireData?.age_dead) ? "-" : retireData?.age_dead}{" "}
                    ปี
                  </div>
                </div>
                <div>
                  <div className="text-[10px] ">
                    ยังขาดอยู่ทั้งหมด{" "}
                    <span className=" font-medium text-black">
                      {lastResultAF?.results < 0
                        ? numeral(lastResultAF?.results * -1)?.format("0,0")
                        : numeral(lastResultAF?.results)?.format("0,0")}
                      {""} บาท
                    </span>
                  </div>
                </div>
              </div>
            );
          },
        },
        {
          icon: <ICON_STAR className=" w-[14px] h-[14px] text-maingreen" />,
          render: () => {
            return (
              <div className="flex flex-col">
                <div className=" flex gap-1">
                  <div className="text-[10px] ">ควรวางแผนลงทุนผลตอบแทน </div>
                  <div className={cn("text-[10px] font-semibold")}>
                    {_.isNaN(retireData?.investment_af_retirement)
                      ? ""
                      : retireData?.investment_af_retirement}{" "}
                    %
                  </div>
                </div>
                <div className="text-[10px] flex space-x-1">
                  <div>เป็นระยะเวลา</div>
                  <span className=" font-semibold">
                    {_.isNaN(afterDiffYear) ? "-" : afterDiffYear} ปี
                  </span>{" "}
                  <div>เดือนละ</div>
                  <span className=" font-semibold text-mainblue">
                    {numeral(sumAfterRetireInvest)?.format("0,0")} บาท
                  </span>
                </div>
              </div>
            );
          },
        },
      ];
    } else {
      if (lastResultAF?.results > 0) {
        return [
          {
            icon: <ICON_CHECK className=" w-[14px] h-[14px] text-maincyan" />,
            render: () => {
              return (
                <div className="text-[10px] flex flex-col gap-1">
                  <div className=" ">
                    คาดการณ์สินทรัพย์เพื่อการลงทุน ณ วันเกษียณอายุ
                  </div>
                  <div className="flex">
                    <span className="font-medium mr-1">
                      {_.isNaN(retireData?.age_retire)
                        ? "-"
                        : retireData?.age_retire}{" "}
                      ปี
                    </span>
                    <div className="flex space-x-1">
                      <div>จำนวน</div>
                      <span className=" font-medium text-mainblue">
                        {numeral(retireBF)?.format("0,0.00")}
                      </span>
                    </div>
                  </div>
                </div>
              );
            },
          },
          {
            icon: (
              <ICON_WARNING className=" w-[14px] h-[14px] text-mainyellow" />
            ),
            render: () => {
              return (
                <div className=" flex gap-1">
                  <div className="text-[10px] ">สามารถใช้จนได้ถึงอายุ</div>
                  <div className={cn("text-[10px] flex space-x-1")}>
                    <div className="font-semibold">
                      {_.isNaN(retireData?.age_dead)
                        ? "-"
                        : retireData?.age_dead}
                    </div>
                    <span className="text-mainblue font-normal">ปี</span>
                    <div>แต่อย่าประมาทนะ</div>
                  </div>
                </div>
              );
            },
          },
        ];
      }
      return [
        {
          icon: <ICON_WARNING className=" w-[14px] h-[14px] text-mainyellow" />,
          render: () => {
            return (
              <div className=" flex gap-1">
                <div className="text-[10px] ">สามารถใช้จนได้ถึงอายุ</div>
                <div className={cn("text-[10px] flex space-x-1")}>
                  <div className="font-semibold">
                    {" "}
                    {_.isNaN(remainAF) ? "-" : remainAF} ปี
                  </div>
                </div>
              </div>
            );
          },
        },
        {
          icon: <ICON_CLOSE className=" w-[14px] h-[14px] text-mainred" />,
          render: () => {
            return (
              <div className="flex flex-col">
                <div className=" flex gap-1 flex-wrap ">
                  <div className="text-[10px] ">
                    ถ้าต้องการมีเงินใช้จ่ายจนถึงอายุ
                  </div>
                  <div className={cn("text-[10px] flex space-x-1")}>
                    <div className="font-semibold">
                      {_.isNaN(retireData?.age_dead)
                        ? "-"
                        : retireData?.age_dead}{" "}
                      ปี
                    </div>
                  </div>
                </div>
                <div className="flex gap-1 text-[10px]">
                  <div className="  text-[10px]">ยังขาดอยู่ทั้งหมด</div>
                  <div
                    className={cn("text-[10px] font-semibold text-black")}
                  >
                    {_.isNaN(lastResultAF?.results)
                      ? "-"
                      : numeral(
                          lastResultAF?.results < 0
                            ? lastResultAF?.results * -1
                            : lastResultAF?.results
                        ).format("0,0")}{" "}
                    บาท
                  </div>
                </div>
              </div>
            );
          },
        },
        {
          icon: <ICON_STAR className=" w-[14px] h-[14px] text-maincyan" />,
          render: () => {
            return (
              <div className="flex flex-col">
                <div className=" flex gap-1">
                  <div className="text-[10px] ">ควรวางแผนลงทุนผลตอบแทน</div>
                </div>
                <div className={cn("text-[10px] font-semibold")}>
                  {retireData?.investment_af_retirement}
                  <span className=" font-normal"> เป็นระยะเวลา </span>
                  <span className="font-semibold">
                    {_.isNaN(afterDiffYear) ? "-" : afterDiffYear} ปี{" "}
                  </span>
                  <span className="font-normal">เดือนละ </span>
                  <span className="text-mainblue">
                    {_.isNaN(sumAfterRetireInvest)
                      ? "-"
                      : numeral(sumAfterRetireInvest)?.format("0,0")}
                  </span>
                </div>
              </div>
            );
          },
        },
      ];
    }
  };

  //ทดสอบความอ่อนไหว

  const getDetailSensitive = () => {
    return [
      {
        icon: (
          <ImageMain src="/images/assetdown.svg" className="w-6 h-6 mb-3" />
        ),
        render: () => {
          return (
            <div className="text-sm text-left">
              <div className="">
                สถานการณ์ที่ 1
                <div className="  ">
                  เศรษฐกิจถดถอย ผลตอบแทนการลงทุน
                  <div>
                    หลังเกษียณ เหลือเพียง{" "}
                    <span className=" font-semibold text-red300">1 %</span>
                  </div>
                </div>
              </div>
            </div>
          );
        },
      },
      {
        icon: (
          <ImageMain src="/images/heart_medical.svg" className="w-6 h-6 mb-3" />
        ),
        render: () => {
          return (
            <div className="text-sm text-left">
              <div className="">
                สถานการณ์ที่ 2
                <div className="  ">
                  เจ็บป่วยโรคร้ายแรงมีค่ารักษาพยาบาล
                  <div>
                    1 ล้านบาท ตอนอายุ{" "}
                    <span className=" font-semibold text-red300">
                      {" "}
                      {retireData?.age_current
                        ? retireData?.age_current + 5
                        : "-"}
                    </span>{" "}
                    ปี
                  </div>
                </div>
              </div>
            </div>
          );
        },
      },
      {
        icon: <ImageMain src="/images/moneybag.svg" className="w-6 h-6 mb-3" />,
        render: () => {
          return (
            <div className="text-sm text-left">
              <div className="   ">
                สถานการณ์ที่ 3
                <div className="   ">
                  ค้ำประกันให้ผู้อื่น เกิดหนี้ 5 ล้านบาท{" "}
                  <div>
                    ตอนอายุ{" "}
                    <span className=" font-semibold text-maingreen">
                      {retireData?.age_current
                        ? retireData?.age_current + 10
                        : "-"}
                    </span>{" "}
                    ปี
                  </div>
                </div>
              </div>
            </div>
          );
        },
      },
    ];
  };

  const dataPlanSensitive = loopInvestmentAssetsAFSensitive()?.map((item) => {
    return item?.results;
  });

  useEffect(() => {
    let ignore = false;
    first_reload(ignore);
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <Container>
      <div id="first_reload" className="pt-[14px]">
        <HeaderTitle
          title="รายงาน"
          isGoback
          onClickGoBack={() => {
            navigate("/report");
          }}
        />
        <div
          className={`${STYLE_REPORT_CONTAIN} flex flex-col gap-5 mb-[30px]`}
        >
          <HeaderReport
            icon={"/images/grandparents-report.png"}
            title={"แผนเกษียณ"}
            className={" z-20"}
          />
          <InfoSection retireData={retireData} table_income={table_income} />

          <RetireBeforeSection
            dataPlanBf={dataPlanBf}
            dataAge={dataAge}
            getDetailBF={getDetailBF}
          />
          <RetireAfterSection
            dataPlanAF={dataPlanAF}
            dataAgeAF={dataAgeAF}
            getDetailAF={getDetailAF}
          />

          <CardReport
            icon_head={"/images/grandparents-report.png"}
            title={"ทดสอบความอ่อนไหว"}
            panel={
              <div>
                <div className=" px-4  mx-auto">
                  <LineChartMain
                    axisSize={6}
                    labels={dataAgeAF()}
                    dataPlan={dataPlanSensitive}
                    fontSize="text-sm"
                    head={``}
                    // height={250}
                  />
                </div>
                <div className="flex flex-col space-y-1 mt-1">
                  {getDetailSensitive().map((item, index) => {
                    return (
                      <div key={index} className="flex gap-2.5">
                        <div>{item?.icon}</div>
                        {item?.render && item?.render()}
                      </div>
                    );
                  })}
                </div>
              </div>
            }
          />

          <div className=" flex justify-center ">
            <MTButton
              onClick={() => {
                navigate("/report");
              }}
              text="ย้อนกลับ"
              color="bg-white"
              border="border border-maincyan"
              colortext="text-maincyan group-hover:text-white"
              hovercolor="hover:bg-maincyan group "
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ReportRetirePage;
