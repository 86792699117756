import ExcelExport from "export-xlsx";

const useExportCSV = ({
  fileName,
  headers,
  data,
  sheetName,
  tableTitle = "",
}) => {
  const onClickExport = () => {
    const { SETTINGS_FOR_EXPORT, dataExport } = generateExcel();
    const excelExport = new ExcelExport();
    excelExport.downloadExcel(SETTINGS_FOR_EXPORT, dataExport);
  };

  const generateExcel = () => {
    const splitFileName = fileName?.split(".");
    const finalFileName =
      splitFileName?.length > 0 ? splitFileName[0] : fileName;

    const headerDefinition = headers.map((header) => {
      return {
        ...header,
        name: header?.label,
        key: header?.key,
        width: header?.width ?? 25,
        // border: {
        //   left: "thin",
        //   right: "thin",
        //   top: "thin",
        //   bottom: "thin",
        // },
      };
    });

    const SETTINGS_FOR_EXPORT = {
      // Table settings
      fileName: finalFileName,
      workSheets: [
        {
          sheetName: sheetName ?? finalFileName,
          startingRowNumber: 1,
          gapBetweenTwoTables: 2,
          tableSettings: {
            data: {
              tableTitle,
              headerDefinition,
              
            },
          },
        },
      ],
    };
    const dataExport = [
      {
        data,
      },
    ];

    return {
      SETTINGS_FOR_EXPORT,
      dataExport,
    };
  };

  const CsvFilePanel = () => {
    return <></>;
  };

  return {
    onClickExport,
    CsvFilePanel,
  };
};

export default useExportCSV;
