import { Bar } from "react-chartjs-2";
import { STYLE_TITLE_DONUTCHART } from "../../../utils/useStyle";
import { useMemo } from "react";
import { size } from "lodash";

export const ChartCashFlow = ({
  dataDebt,
  labels,
  merge_keys = [],
  isRemoveGray = false,
  width = 500,
  height = 200,
}) => {
  const randomColorInRange = (startColor, endColor) => {
    // Convert start and end colors to numbers
    const startColorNumber = parseInt(startColor.substring(1), 16);
    const endColorNumber = parseInt(endColor.substring(1), 16);

    // Calculate range from start color to end color
    const randomRange = endColorNumber - startColorNumber;

    // Generate random offset within the range
    const randomOffset = Math.floor(Math.random() * randomRange);

    // Add offset to start color number
    const colorNumber = startColorNumber + randomOffset;

    // Convert back to hex color string
    const color = colorNumber.toString(16);

    // Pad with zeros if necessary
    return `#${color.padStart(6, "0")}`;
  };
  const startingColor = "#D21329";
  const endingColor = "#ECBC3A";
  const randomColor = randomColorInRange(startingColor, endingColor);

  const colors = useMemo(() => {
    return merge_keys?.map((key) => {
      if (key === "income_peryear") {
        return "#47A3F7";
      } else if (key === "remain_accumulate") {
        return "#00B61D";
      } else if (key === "remain_accumulate") {
        return "#00B61D";
      } else if (key === "outcome_peryear") {
        return "#F7A647";
      } else if (key === "out_come_stable") {
        return "#ff33b2";
      } else if (key === "expenese_car_items") {
        return "#FF0000";
      } else if (key === "expenese_children_items") {
        return "#FF0000";
      } else if (key === "expenese_travel_items") {
        return "#FF0000";
      } else if (key === "expenese_collection_items") {
        return "#FF0000";
      } else if (key === "expenese_other_items") {
        return "#FF0000";
      } else if (key === "income_legacy_items") {
        return "#00BDCE";
      } else if (key === "income_business_items") {
        return "#00FFFF";
      } else if (key === "income_property_items") {
        return "#FFA500";
      } else if (key === "income_other_items") {
        return "#0000FF";
      } else if (key === "pension_peryear") {
        return "#0bdaf2";
      }
    });
  }, [dataDebt]); 


  const merge_label_key = {
    expenese_home_items: "บ้าน",
    expenese_car_items: "รถ",
    expenese_children_items: "ลูก",
    expenese_travel_items: "ท่องเที่ยว",
    expenese_collection_items: "ของสะสม",
    expenese_other_items: "รายจ่ายอื่นๆ",
    income_legacy_items: "รายได้จากมรดก",
    income_business_items: "รายได้จากธุรกิจ",
    income_property_items: "รายได้จากอสังหาริม",
    income_other_items: "รายได้อื่นๆ",
    income_peryear: "รายได้",
    remain_accumulate: "สินทรัพย์ลงทุน",
    outcome_peryear: "จ่ายผันแปร",
    out_come_stable: "จ่ายคงที่ (ไม่รวมหนี้)",
    outcome_total: "รวมรายจ่ายต่อปี ",
    pension_peryear: "บำนาญต่อปี",
    remain: "เงินคงเหลือ",
    income_investment: "ผลตอบแทนการลงทุน ",
  };

  const negativeList = ["บ้าน","รถ","ลูก","ท่องเที่ยว","รายจ่ายอื่นๆ","จ่ายผันแปร","จ่ายคงที่ (ไม่รวมหนี้)"]

  const datasets = dataDebt.map((data, index) => {
    const merge_key = merge_label_key[merge_keys[index]];

    // console.log("merge_key", merge_label_key[merge_keys[index]],':', merge_keys[index]);

    let tempExpenseSet = []

    if (negativeList.includes(merge_key) ) {
      tempExpenseSet = data.map((item, index) => -Math.abs(item))
    } 
    else{
      tempExpenseSet = data
    }

    return {
      label: `${merge_key}`,
      data: tempExpenseSet,
      backgroundColor: 
      colors[index]
      ,
      // stack: "Stack 0",
    };
  });

  const data = {
    labels: labels,
    datasets: datasets,
  };

  const config = {
    type: "bar",
    data: data,
  };

  const options = {
    responsive: true,
    interaction: {
      intersect: false,
    },
    scales: {
      x: {
        // stacked: true,
        ticks: {
          font: {
            size: 8,
          },
        },
      },
      y: {
        // stacked: true,
        ticks: {
          font: {
            size: 8,
          },
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        labels: {
          boxWidth: 14,
          boxHeight: 14,
        },
        position: "bottom",
      },
    },
    // barThickness: 20,
  };


  return (
    <div className="overflow-auto ">
      <div className=" relative w-full h-full">
        {/* <div className=" bg-white w-full h-11 absolute top-0"></div> */}
        <Bar
          width={width}
          height={height}
          data={data}
          config={config}
          options={options}
        />
      </div>
    </div>
  );
};
