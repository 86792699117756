import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  RadialLinearScale,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { useEffect } from "react";
import { useRetireStore } from "../../_store/retireStore";
import { useIsMobile } from "../../utils/useIsMobile";
import numeral from "numeral";
import { checkExist } from "../../functions/main";
import { cn, nFormatter } from "../../utils/useFunctions";

ChartJS.register(
  RadialLinearScale,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Filler,
  Title,
  Tooltip,
  Legend
);

// const red = [-2, -1, 0, null, null, null, null, null, null];

const LineChartMain = ({
  labels,
  dataPlan,
  fontSize = "text-sm",
  axisSize = 10,
  width,
  height,
  classNameAge,
  classNameBath,
}) => {
  const green = [];
  const yellow = [];
  const red = [];

  // console.log("dataplan", dataPlan);
  for (let index = 0; index < dataPlan?.length; index++) {
    const currentValue = dataPlan[index];
    const nextValue = dataPlan[index + 1];

    //จุดแรก
    if (index === 0) {
      if (dataPlan[index] <= 0) {
        red.push(dataPlan[index]);
        yellow.push(null);
        green.push(null);
      } else {
        if (dataPlan[0] > dataPlan[1]) {
          yellow.push(dataPlan[index]);
          red.push(null);
          green.push(null);
        } else {
          green.push(dataPlan[index]);
          yellow.push(null);
          red.push(null);
        }
      }
      continue;
    }

    // red
    if (dataPlan[index] <= 0) {
      if (nextValue > currentValue && currentValue < 0 && nextValue > 0) {
        red.push(dataPlan[index]);
        green.push(dataPlan[index]);
        yellow.push(null);
        continue;
      }
      red.push(dataPlan[index]);
      yellow.push(null);
      green.push(null);
      continue;
    }

    if (dataPlan[index] < dataPlan[index - 1]) {
      // yellow => green
      if (nextValue > currentValue) {
        green.push(dataPlan[index]);
        yellow.push(dataPlan[index]);
        // yellow.push(null);
        red.push(null);
        continue;
      }

      //yellow => red
      if (nextValue < currentValue && nextValue < 0) {
        red.push(dataPlan[index]);
        green.push(null);
        yellow.push(dataPlan[index]);
        // yellow.push(null);
        continue;
      }

      yellow.push(dataPlan[index]);
      green.push(null);
      red.push(null);

      // console.log(`currentValue: `, currentValue);
      // console.log(`nextValue: `, nextValue);
    } else {
      //green => yellow

      if (nextValue < currentValue && nextValue > 0) {
        red.push(null);
        green.push(dataPlan[index]);
        yellow.push(dataPlan[index]);
        // yellow.push(null);
        continue;
      }
      //green
      green.push(dataPlan[index]);
      yellow.push(null);
      red.push(null);
    }
  }

  // console.log("green", green);
  // console.log("yellow", yellow);
  // console.log("red", red);

  const isMobile = useIsMobile();

  const getConRed = () => {
    if (red.every((item) => item === null) === true) {
      return [];
    }
    if (yellow.every((item) => item === null) === true) {
      return red.map((item) => (item === null ? 0 : item));
    }
    return red;
  };

  const data = {
    labels: labels,

    datasets: [
      {
        label: "",
        data: green,

        fill: true,
        pointRadius: 0,
        borderColor: ({ chart: { ctx } }) => {
          const borderColor = "#16AA37";
          return borderColor;
        },

        pointBackgroundColor: "#16AA37",
        backgroundColor: ({ chart: { ctx } }) => {
          const gradient = ctx.createLinearGradient(0, 0, 0, 300);
          gradient.addColorStop(0.2, "#16AA37");
          gradient.addColorStop(0.6, "rgba(255, 255, 255, 0.0)");

          return gradient;
        },
        tension: 0.2,
      },
      {
        label: "",
        data: yellow,

        fill: true,
        pointRadius: 0,
        borderColor: ({ chart: { ctx } }) => {
          const borderColor = "#FFD200";
          return borderColor;
        },

        pointBackgroundColor: "#FFD200",
        backgroundColor: ({ chart: { ctx } }) => {
          const gradient = ctx.createLinearGradient(0, 0, 0, 300);
          gradient.addColorStop(0.2, "#FFD200");
          gradient.addColorStop(0.6, "rgba(255, 255, 255, 0.0)");
          return gradient;
        },
        tension: 0.2,
      },
      {
        label: "",
        data: getConRed(),
        fill: true,
        pointRadius: 0,
        borderColor: ({ chart: { ctx } }) => {
          const borderColor = "#AA1616";
          return borderColor;
        },

        pointBackgroundColor: "#AA1616",

        backgroundColor: ({ chart: { ctx } }) => {
          const gradient = ctx.createLinearGradient(0, 0, 0, 300);
          gradient.addColorStop(0.1, "#AA1616");
          gradient.addColorStop(0.6, "rgba(255, 255, 255, 0)");
          return gradient;
        },
        tension: 0.2,
      },
    ].filter((dataItem) => dataItem),
  };

  const config = {
    type: "line",
    data,
  };

  const options = {
    scales: {
      x: {
        ticks: {
          maxTicksLimit: isMobile ? 10 : 20,
          font: {
            size: axisSize,
          },
        },
      },
      y: {
        ticks: {
          beginAtZero: true,
          callback: function (value, index, values) {
            return nFormatter(value, 2);
          },
          font: {
            size: axisSize,
          },
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          boxWidth: 0,
        },
      },
    },
  };

  return (
    <div className=" w-full relative ">
      <div
        className={cn(
          `absolute top-0 md:-top-0 left-[15%] md:left-[6%] ${fontSize} text-maingray font-semibold`,
          classNameBath
        )}
      >
        {`บาท`}
      </div>
      <div className={` w-full flex-none -ml-[5%]`}>
        <Line
          data={data}
          config={config}
          dataPlan={dataPlan}
          options={options}
          height={height}
          width={width}
        />
      </div>
      <div
        className={cn(
          `absolute bottom-0 -right-2 md:right-0 ${fontSize} text-maingray font-semibold`,
          classNameAge
        )}
      >
        {`อายุ`}
      </div>
    </div>
  );
};

export default LineChartMain;
