import {
  Button,
  Drawer,
  IconButton,
  Typography,
} from "@material-tailwind/react";
import React, { useEffect, useRef, useState } from "react";
import { MTButton } from "../button/MTButton";
import {
  ICON_ABOUTUS,
  ICON_ARROW_UP_DOWN,
  ICON_ASK,
  ICON_BALANCE,
  ICON_DEBT,
  ICON_EDIT_PENCIL,
  ICON_EDIT_PROFILE,
  ICON_EDUCATION,
  ICON_FUTURE,
  ICON_HAMBURGER,
  ICON_HOME,
  ICON_INHERIT,
  ICON_INSURANCE,
  ICON_INVEST,
  ICON_LOGIN,
  ICON_LOGOUT,
  ICON_NAVIGATE_RIGHT,
  ICON_PROFILE,
  ICON_REPORT,
  ICON_RETIREMENT,
  ICON_RIGHT,
  ICON_RISK,
  ICON_RLOCNG_RIGHT,
  ICON_SAVING,
  ICON_STAR,
  ICON_TAX,
} from "../../utils/useIcons";
import { useIsMobile } from "../../utils/useIsMobile";
import classNames from "classnames";
import { useLocation, useNavigate } from "react-router-dom";
import {
  STYLE_ICON_LOGIN,
  STYLE_ICON_LOGOUT,
  STYLE_SIDEBAR_NAVBAR,
} from "../../utils/useStyle";
import { set } from "lodash";
import { PopupMain } from "../popup/PopupMain";
import { ImageMain } from "../img/Img.Main";
import { getPathURL } from "../../utils/useFunctions";

import { useUserStore } from "../../_store/userStore";
import { useRiskStore } from "../../_store/riskStore";
import { useSavingStore } from "../../_store/savingStore";
import { useBalanceStore } from "../../_store/balanceStore";
import { useRetireStore } from "../../_store/retireStore";
import { useTaxStore } from "../../_store/taxplanStore";
import { differenceInDays } from "date-fns";
import api from "../../api/api.main";
import { DateTime } from "luxon";

const Menu = [
  {
    label: "งบดุล",
    icon: <ICON_BALANCE className={` w-6 h-6  `} />,
    pathpremium: "/balance",
  },

  {
    label: "แผนการศึกษาบุตร",
    icon: <ICON_EDUCATION className={` w-6 h-6  `} />,
    pathpremium: "/education",
  },
  {
    label: "แผนหนี้สิน",
    icon: <ICON_DEBT className={` w-6 h-6  `} />,
    pathpremium: "/debtplan",
  },
  {
    label: "แผนความเสี่ยง",
    icon: <ICON_RISK className={` w-6 h-6  `} />,
    pathpremium: "/riskplan",
  },
  {
    label: "แผนประกัน",
    icon: <ICON_INSURANCE className={` w-6 h-6  `} />,
    pathpremium: "/insuranceplan",
  },
  {
    label: "แผนลงทุน",
    icon: <ICON_INVEST className={` w-6 h-6  `} />,
    pathpremium: "/investplan",
  },
  {
    label: "แผนมรดก",
    icon: <ICON_INHERIT className={` w-6 h-6  `} />,
    pathpremium: "/legacyplan",
  },
  {
    label: "แผนอนาคต",
    icon: <ICON_FUTURE className={` w-6 h-6  `} />,
    pathpremium: "/futureplan",
  },
  {
    label: "รายงานสรุป",
    icon: <ICON_REPORT className={` w-6 h-6  `} />,
    pathpremium: "/report",
  },
  {
    label: "อัพเกรดเป็นผู้ใช้งานพรีเมี่ยม",
    icon: <ICON_STAR className={` w-6 h-6  `} />,
    pathpremium: "/",
  },
];



const MenuProfile = ({ onLogout, onProfile, open }) => {
  const userData = useUserStore((state) => state.userData);

  let expiredDays = userData?.premium_expired ? differenceInDays(
    new Date(userData?.premium_expired),
    new Date(
      DateTime.now().set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      })
    )
  ) : 0;
  if (expiredDays < 0) {
    expiredDays = 0;
  }

  return (
    <>
      {open ? (
        <div
          className={`${
            open ? " h-fit" : " h-0 "
          }transition-all duration-100 animate-fade-up fixed top-10 right-10 md:right-1/3 md:top-16 z-30 `}
        >
          <div className=" bg-white p-4 py-5 shadow-xl  rounded-[20px] w-fit h-[124px]  space-y-2.5 text-sm ">
            <div onClick={onProfile} className=" text-mainblue ">
              {" "}
              ดูรายละเอียดโปรโฟล์
            </div>
            <div className=" text-mainblue  ">
              {" "}
              ใช้งานได้อีก{" "}
              {expiredDays}
              วัน
            </div>
            <div
              onClick={onLogout}
              className=" text-red-500 hover:text-mainred duration-200 "
            >
              ออกจากระบบ
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

const MobileMenu = ({
  userData,
  clearUserData,
  openAlert,
  setOpenAlert,
  openDrawer,
  setOpenDrawer,
  onLogout,
  onClickGotoProfile,
  setOpenMenuProfile,
  openMenuProfile,
}) => {

  // const userData = useUserStore((state) => state.userData);

  const navigate = useNavigate();
  const location = useLocation();
  const [openPopup, setOpenPopup] = useState(false);

  const UserProfile = ({ userData, clearUserData }) => {
    return (
      <div>
        {userData?._id ? (
          <div>
            <div className=" flex gap-3 ">
              <div className=" bg-white rounded-full  w-[57px] h-[57px]">
                <ImageMain
                  className=" rounded-full w-[57px] h-[57px] object-cover"
                  src={
                    userData?.image_key
                      ? getPathURL(userData.image_key)
                      : "/images/user_profile.png"
                  }
                />
              </div>
              <div>
                <div className=" flex gap-1">
                  <div className=" text-black  font-medium truncate">
                    {userData?.firstname}
                    <span className="pl-1">{userData?.lastname}</span>
                  </div>
                  <ICON_EDIT_PROFILE
                    className={` text-maincyan w-4 h-4`}
                    onClick={onClickGotoProfile}
                  />
                </div>
                <div className=" text-gray90 text-xs font-normal">
                  {userData?.phone_number}
                </div>
              </div>
            </div>
            <div
              onClick={() => {
                setOpenAlert(true);
              }}
              className=" flex justify-center gap-1 text-maincyan hover:text-secondcyan hover:underline mt-[6px] w-fit mx-auto"
            >
              <ICON_LOGOUT className={` w-6 h-6 cursor-pointer `} />
              <div>Logout</div>
            </div>
          </div>
        ) : (
          <div className=" flex flex-col justify-center items-center gap-3">
            <div className="text-center ">
              <span className="text-sm text-gray90 font-normal">
                ยังไม่มีบัญชีผู้ใช้ ?{" "}
                <span
                  className=" text-maincyan font-medium"
                  onClick={() => {
                    navigate("/login");
                    setOpenDrawer(false);
                  }}
                >
                  {" "}
                  สมัครสมาชิก
                </span>
              </span>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <div
        // className=" px-8 pt-[30px] pb-[14px] bg-white h-fit fixed w-full z-20"
        className=" px-7 py-2 bg-white h-fit fixed w-full z-40 "
      >
        <div className=" flex justify-between items-center">
          <img
            src="/images/logo_assure.png"
            alt="logo"
            className=" w-[78px] h-[32px] cursor-pointer "
            onClick={() => navigate("/")}
          />
          <div className=" flex space-x-[5px] items-center h-[35px] w-fit">
            {userData?._id ? (
              <div className=" text-maincyan text-sm font-semibold flex gap-1 items-center">
                {/* <ICON_PROFILE className={` w-6 h-6 `} /> */}

                {userData?.firstname}
                <ICON_ARROW_UP_DOWN
                  className={` ${
                    openMenuProfile ? "rotate-180" : "rotate-0"
                  } w-5 h-5`}
                  color="#00A9A0"
                  onClick={() => {
                    setOpenMenuProfile(!openMenuProfile);
                  }}
                />
              </div>
            ) : (
              <ICON_LOGIN
                className={STYLE_ICON_LOGIN}
                onClick={() => {
                  navigate("/login");
                }}
              />
            )}

            <ICON_ASK className={` w-6 h-6`} />
            <ICON_HAMBURGER
              className={` w-[25px] cursor-pointer`}
              onClick={() => setOpenDrawer(!openDrawer)}
            />
          </div>
        </div>
      </div>

      <Drawer
        placement="right"
        open={openDrawer}
        // onClose={() => setOpenDrawer(!false)}
        className="p-5 overflow-x-auto "
      >
        <svg
          onClick={() => setOpenDrawer(false)}
          className="absolute right-3 w-6 h-6 cursor-pointer"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={2}
          stroke="#00A9A0"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
        <div className=" flex flex-col justify-center  gap-4  mt-6">
          <div
            onClick={(e) => {
              e.preventDefault();
              navigate("/");
              setOpenDrawer(false);
            }}
            className={STYLE_SIDEBAR_NAVBAR}
          >
            <div className=" flex gap-2  font-medium ">
              <ICON_HOME className={` w-6 h-6  `} />
              <div>หน้าแรก</div>
            </div>
          </div>
          <div
            onClick={(e) => {
              e.preventDefault();
              navigate("/retireplan");
              setOpenDrawer(false);
            }}
            className={STYLE_SIDEBAR_NAVBAR}
          >
            <div className=" flex gap-2  font-medium ">
              <ICON_RETIREMENT className={` w-6 h-6  `} />
              <div>แผนเกษียณ</div>
            </div>
          </div>
          <div
            onClick={(e) => {
              e.preventDefault();
              navigate("/taxplan");
              setOpenDrawer(false);
            }}
            className={STYLE_SIDEBAR_NAVBAR}
          >
            <div className=" flex gap-2  font-medium ">
              <ICON_TAX className={` w-6 h-6  `} />
              <div>แผนภาษี</div>
            </div>
          </div>
          <div
            onClick={(e) => {
              e.preventDefault();
              navigate("/savingsplan");
              setOpenDrawer(false);
            }}
            className={STYLE_SIDEBAR_NAVBAR}
          >
            <div className=" flex gap-2  font-medium ">
              <ICON_SAVING className={` w-6 h-6  `} />
              <div>แผนการออมและลดค่าใช้จ่าย</div>
            </div>
          </div>

          {Menu?.map((row, index) => {
            if (
              row?.label === "อัพเกรดเป็นผู้ใช้งานพรีเมี่ยม" 
            ) {
              return (
                <>
                { !userData?.is_premium && <div key={index}>
                  <div
                    onClick={async () => {
                      setOpenPopup(true);
                      setOpenDrawer(false);
                    }}
                    className={`${"text-mainblue"} text-lg font-bold font-IBMPlexSansThai text-center cursor-pointer hover:underline`}
                  >
                    <div className=" flex gap-2 font-medium">
                      {row?.icon}
                      {row?.label}
                    </div>
                  </div>
                </div>}
                </>
              );
            }

            return (
              <div key={index}>
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    if (userData?._id && userData?.is_premium) {
                      navigate(row?.pathpremium);
                    } else {
                      navigate("/");
                    }
                    setOpenDrawer(false);
                  }}
                  className={`${
                    userData?.is_premium ? "text-mainblue" : "text-maingray"
                  }  hover:text-maincyan duration-100 text-lg font-bold font-IBMPlexSansThai cursor-pointer`}
                >
                  <div className=" flex gap-2  font-medium ">
                    {row?.icon}
                    {row?.label}
                  </div>
                </div>
              </div>
            );
          })}
          <div
            onClick={(e) => {
              e.preventDefault();
              navigate("/aboutus");
              setOpenDrawer(false);
            }}
            className={STYLE_SIDEBAR_NAVBAR}
          >
            <div className=" flex gap-2  font-medium ">
              <ICON_ABOUTUS className={` w-6 h-6  `} />
              <div>รู้จักเรา</div>
            </div>
          </div>
          <div className=" text-maincyan font-semibold text-center underline cursor-pointer hover:text-secoundblue ">
            <div>สมัครตัวแทนประกันชีวิต</div>
            <div>และที่ปรึกษาการเงิน</div>
          </div>

          <div className=" border-b border-gray55 w-full"></div>

          <UserProfile userData={userData} clearUserData={clearUserData} />
        </div>
      </Drawer>
      {userData?.is_premium === false && <PopupMain
        flex=" flex flex-col"
        iconleft={true}
        icon={<ImageMain src="/images/workwithus.png" className=" w-5 h-5 " />}
        open={openPopup}
        textButton="สมัครโปรแกรมวางแผนการเงิน"
        img="/images/popup_icon_premium.png"
        width="w-[127px] "
        height="h-[93px]"
        text={
          <div className=" text-sm ">
            ตอบรับทุกช่วงชีวิต
            <div>ด้วยการวางแผนการเงินที่มีประสิทธิภาพ!</div>
            <div>เริ่มต้นกับโปรแกรมวางแผนการเงินแบบพรีเมี่ยม</div>
            <div>
              เพื่อให้มีเพื่อนคู่คิดตลอดทุกช่วงชีวิต มีความสุขและความสงบทางจิตใจ
            </div>
          </div>
        }
        title={`มั่นใจทางการเงินในระดับพรีเมี่ยม`}
        onClick={async () => {
          try {
            await api.put(`/profiles/${userData?._id}`, {
              is_premium: true,
              premium_created_date: DateTime.now()
                .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                .setZone("Asia/Bangkok")
                .toISO(),
              premium_expired: DateTime.now()
                .plus({ days: 7 })
                .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                .setZone("Asia/Bangkok")
                .toISO(),
            });
            alert("สมัครสมาชิกในระดับพรีเมี่ยมเรียบร้อย");
            setOpenPopup(false);
            await onLogout();
          } catch (error) {
            console.log("preimum error", error);
          }
        }}
        button2={true}
        textButton2="ขอพิจารณาเพิ่มเติม"
        onClickSecond={() => {
          setOpenPopup(false);
        }}
      />}
    </div>
  );
};

const DesktopMenu = ({
  userData,
  clearUserData,
  setOpenAlert,
  openDrawer,
  setOpenDrawer,
  onClickGotoProfile,
  setOpenMenuProfile,
  openMenuProfile,
  onLogout,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [openPopup, setOpenPopup] = useState(false);

  const UserProfile = ({ userData }) => {
    return (
      <div>
        {userData?._id ? (
          <div>
            <div className=" flex gap-3 mt-2">
              <div className=" bg-white rounded-full  w-[57px] h-[57px]">
                <ImageMain
                  className=" rounded-full w-[57px] h-[57px] object-cover"
                  src={
                    userData?.image_key
                      ? getPathURL(userData.image_key)
                      : "/images/user_profile.png"
                  }
                />
              </div>
              <div>
                <div className=" flex gap-1">
                  <div className=" text-black textmd font-medium">
                    {userData?.firstname} {userData?.lastname}
                  </div>
                  <ICON_EDIT_PROFILE
                    className={` text-maincyan w-4 h-4 cursor-pointer`}
                    onClick={onClickGotoProfile}
                  />
                </div>
                <div className=" text-gray90 text-xs font-normal">
                  {userData?.phone_number}
                </div>
              </div>
            </div>
            <div
              onClick={() => {
                setOpenAlert(true);
              }}
              className=" flex justify-center gap-1 text-maincyan mt-6 w-fit mx-auto cursor-pointer"
            >
              <ICON_LOGOUT className={` w-6 h-6 `} />
              <div>Logout</div>
            </div>
          </div>
        ) : (
          <div className=" flex flex-col justify-center items-center gap-3 mt-5">
            <div className="text-center ">
              <span className="text-sm text-gray90 font-normal">
                ยังไม่มีบัญชีผู้ใช้ ?{" "}
                <span
                  className=" text-maincyan font-medium cursor-pointer"
                  onClick={() => {
                    navigate("/login");
                    setOpenDrawer(false);
                  }}
                >
                  {" "}
                  สมัครสมาชิก
                </span>
              </span>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="max-w-2xl w-full">
      <div className=" px-8 pt-[30px] pb-8 bg-white h-10 fixed max-w-2xl w-full z-20">
        <div className=" flex justify-between items-center  w-full">
          <img
            src="/images/logo_assure.png"
            alt="logo"
            className=" w-[78px] h-[32px] cursor-pointer"
            onClick={() => navigate("/")}
          />
          <div className=" flex gap-[9px] items-center h-[35px]">
            {userData?._id ? (
              <div className=" text-maincyan text-sm font-semibold flex gap-1 items-center cursor-pointer hover:text-secondcyan hover:underline">
                {userData?.firstname}
                <ICON_ARROW_UP_DOWN
                  className={` ${
                    openMenuProfile ? "rotate-180" : "rotate-0"
                  } w-5 h-5 `}
                  color="#00A9A0"
                  onClick={() => {
                    setOpenMenuProfile(!openMenuProfile);
                  }}
                />
              </div>
            ) : (
              <ICON_LOGIN
                className={STYLE_ICON_LOGIN}
                onClick={() => {
                  navigate("/login");
                }}
              />
            )}

            <ICON_ASK className={` w-6 h-6`} />
            <ICON_HAMBURGER
              className={` w-[25px] cursor-pointer`}
              onClick={() => setOpenDrawer(!openDrawer)}
            />
          </div>
        </div>
      </div>
      <Drawer
        placement="right"
        open={openDrawer}
        // onClose={() => setOpenDrawer(!false)}
        className="p-4 overflow-x-auto "
      >
        <svg
          onClick={() => setOpenDrawer(false)}
          className="absolute right-3 w-6 h-6 cursor-pointer"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={2}
          stroke="#00A9A0"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
        <div className=" flex flex-col gap-5 my-10">
          <div
            onClick={(e) => {
              e.preventDefault();
              navigate("/");
              setOpenDrawer(false);
            }}
            className={STYLE_SIDEBAR_NAVBAR}
          >
            <div className=" flex gap-2  font-medium ">
              <ICON_HOME className={` w-6 h-6  `} />
              <div>หน้าแรก</div>
            </div>
          </div>
          <div
            onClick={(e) => {
              e.preventDefault();
              navigate("/retireplan");
              setOpenDrawer(false);
            }}
            className={STYLE_SIDEBAR_NAVBAR}
          >
            <div className=" flex gap-2  font-medium ">
              <ICON_RETIREMENT className={` w-6 h-6  `} />
              <div>แผนเกษียณ</div>
            </div>
          </div>
          <div
            onClick={(e) => {
              e.preventDefault();
              navigate("/taxplan");
              setOpenDrawer(false);
            }}
            className={STYLE_SIDEBAR_NAVBAR}
          >
            <div className=" flex gap-2  font-medium ">
              <ICON_TAX className={` w-6 h-6  `} />
              <div>แผนภาษี</div>
            </div>
          </div>
          <div
            onClick={(e) => {
              e.preventDefault();
              navigate("/savingsplan");
              setOpenDrawer(false);
            }}
            className={STYLE_SIDEBAR_NAVBAR}
          >
            <div className=" flex gap-2  font-medium ">
              <ICON_SAVING className={` w-6 h-6  `} />
              <div>แผนการออมและลดค่าใช้จ่าย</div>
            </div>
          </div>

          {Menu?.map((row, index) => {
            if (
              row?.label === "อัพเกรดเป็นผู้ใช้งานพรีเมี่ยม" 
            ) {
              return (<>
                {!userData?.is_premium && <div key={index}>
                  <div
                    onClick={async () => {
                      setOpenPopup(true);
                      setOpenDrawer(false);
                    }}
                    className={`${"text-mainblue"} text-lg font-bold font-IBMPlexSansThai text-center cursor-pointer hover:underline`}
                  >
                    <div className=" flex gap-2 font-medium">
                      {row?.icon}
                      {row?.label}
                    </div>
                  </div>
                </div>}
                </>
              );
            }

            return (
              <div key={index}>
                <div
                  onClick={() => {
                    userData?._id && userData?.is_premium
                      ? navigate(row?.pathpremium)
                      : navigate("/");
                    setOpenDrawer(false);
                    // onUpgradePremium(userData?._id);
                  }}
                  className={`${
                    userData?.is_premium ? "text-mainblue" : "text-maingray"
                  } text-lg font-bold font-IBMPlexSansThai text-center cursor-pointer hover:underline`}
                >
                  <div className=" flex gap-2 font-medium">
                    {row?.icon}
                    {row?.label}
                  </div>
                </div>
              </div>
            );
          })}
          <div
            onClick={(e) => {
              e.preventDefault();
              navigate("/aboutus");
              setOpenDrawer(false);
            }}
            className={STYLE_SIDEBAR_NAVBAR}
          >
            <div className=" flex gap-2  font-medium ">
              <ICON_ABOUTUS className={` w-6 h-6  `} />
              <div>รู้จักเรา</div>
            </div>
          </div>
        </div>
        <div className=" border-b border-gray55 w-full"></div>
        <UserProfile userData={userData} />
      </Drawer>

      <PopupMain
        flex=" flex flex-col"
        iconleft={true}
        icon={<ImageMain src="/images/workwithus.png" className=" w-5 h-5 " />}
        open={openPopup}
        textButton="สมัครโปรแกรมวางแผนการเงิน"
        img="/images/popup_icon_premium.png"
        width="w-[127px] "
        height="h-[93px]"
        text={
          <div className=" text-sm ">
            ตอบรับทุกช่วงชีวิต
            <div>ด้วยการวางแผนการเงินที่มีประสิทธิภาพ!</div>
            <div>เริ่มต้นกับโปรแกรมวางแผนการเงินแบบพรีเมี่ยม</div>
            <div>
              เพื่อให้มีเพื่อนคู่คิดตลอดทุกช่วงชีวิต มีความสุขและความสงบทางจิตใจ
            </div>
          </div>
        }
        title={`มั่นใจทางการเงินในระดับพรีเมี่ยม`}
        onClick={async () => {
          console.log("premium")
          try {
            await api.put(`/profiles/${userData?._id}`, {
              is_premium: true,
              premium_created_date: DateTime.now()
                .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                .setZone("Asia/Bangkok")
                .toISO(),
              premium_expired: DateTime.now()
                .plus({ days: 7 })
                .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                .setZone("Asia/Bangkok")
                .toISO(),
            });
            alert("สมัครสมาชิกในระดับพรีเมี่ยมเรียบร้อย");
            setOpenPopup(false);
            await onLogout();
          } catch (error) {
            console.log("preimum error", error);
          }
        }}
        button2={true}
        textButton2="ขอพิจารณาเพิ่มเติม"
        onClickSecond={() => {
          setOpenPopup(false);
        }}
      />
    </div>
  );
};

const Navbar = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [openMenuProfile, setOpenMenuProfile] = useState(false);
  const location = useLocation();
  const wrapperRef = useRef(null);

  useEffect(() => {
    // Function to handle click event
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setOpenMenuProfile(false);
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // User Data
  const userData = useUserStore((state) => state.userData);
  const clearUserData = useUserStore((state) => state.clearUserData);

  // State Clear Data Plan
  const clearSavingData = useSavingStore((state) => state.clearSavingData);
  const clearRetireData = useRetireStore((state) => state?.clearRetireData);
  const clearTaxData = useTaxStore((state) => state?.clearTaxData);
  const clearRiskData = useRiskStore((state) => state.clearRiskData);
  const clearBalanceData = useBalanceStore((state) => state?.clearBalanceData);

  const onClickGotoProfile = () => {
    navigate("/profile");
    setOpenDrawer(false);
    setOpenMenuProfile(false);
  };

  const onClickLogout = () => {
    localStorage.clear();
    setOpenAlert(false);
    setOpenDrawer(false);
    setOpenMenuProfile(false);
    window.location.href = "/login";
  };

  if (location.pathname === "/pdf") {
    return null;
  }

  return (
    <React.Fragment>
      {isMobile ? (
        <MobileMenu
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          userData={userData}
          clearUserData={clearUserData}
          openAlert={openAlert}
          setOpenAlert={setOpenAlert}
          onClickGotoProfile={onClickGotoProfile}
          openMenuProfile={openMenuProfile}
          setOpenMenuProfile={setOpenMenuProfile}
          onLogout={onClickLogout}
        />
      ) : (
        <DesktopMenu
          userData={userData}
          clearUserData={clearUserData}
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          openAlert={openAlert}
          setOpenAlert={setOpenAlert}
          onClickGotoProfile={onClickGotoProfile}
          openMenuProfile={openMenuProfile}
          setOpenMenuProfile={setOpenMenuProfile}
          onLogout={onClickLogout}
        />
      )}

      <div ref={wrapperRef}>
        <MenuProfile
          onLogout={() => {
            setOpenAlert(true);
          }}
          onProfile={onClickGotoProfile}
          open={openMenuProfile}
        />
      </div>

      <PopupMain
        open={openAlert}
        text={`ยืนยันการออกจากระบบ ?`}
        img={"/images/bad_face.png"}
        onClick={onClickLogout}
        button2
        textButton2={"ยกเลิก"}
        onClickSecond={() => setOpenAlert(false)}
      />
    </React.Fragment>
  );
};
export default Navbar;
