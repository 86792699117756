import { useNavigate } from "react-router-dom";
import { HeaderTitle } from "../../components/header/Header";
import { NavigatePath } from "../../functions/Navigate.Goback";
import {
  STYLE_ABOUST_US_ICON,
  STYLE_ABOUT_US_DES,
  STYLE_BG_CIRCLE,
  STYLE_MARGIN_TOP_PARENT,
  STYLE_PADDING_MAIN,
  STYLE_TEXT_BLUE,
  STYLE_TEXT_CYAN,
  STYTLE_ABOUT_US,
} from "../../utils/useStyle";
import Container from "../../components/layout/Container";
import { ImageMain } from "../../components/img/Img.Main";
import { MTButton } from "../../components/button/MTButton";

const AboutusMain = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <div className={`${STYLE_MARGIN_TOP_PARENT} mb-10 `}>
        <HeaderTitle
          title="รู้จักเรา"
          isGoback
          onClickGoBack={() => {
            NavigatePath(navigate, "/");
          }}
        />
        <div className={`${STYLE_PADDING_MAIN} space-y-3 mt-5`}>
          <div className=" flex flex-col justify-center items-center">
            <ImageMain
              src="../images/logo_assure.png"
              className="h-11 w-fit "
            />
            <div className=" text-mainblue font-semibold text-sm">
              เราสร้างสรรค์สังคม มั่นคง มั่งคั่ง ยั่งยืน
            </div>
          </div>
          <div className={`${STYLE_TEXT_CYAN} text-center text-xl`}>
            พันธกิจของเรา
          </div>
          <div
            className={`${STYTLE_ABOUT_US} animate-fade-left animate-delay-75`}
          >
            <ImageMain src="../images/about_1.png" className="w-full" />
            <div>
              <ImageMain
                src="../images/about_1-1.png"
                className={STYLE_ABOUST_US_ICON}
              />

              <div className={` ${STYLE_ABOUT_US_DES} mt-2.5`}>
                สร้างความสุขและความสงบทางจิตใจ
                <div>ด้วยการวางแผนการเงิน</div>
              </div>
            </div>
          </div>
          <div
            className={`${STYTLE_ABOUT_US} animate-fade-right animate-delay-100`}
          >
            <div className={` ${STYLE_ABOUT_US_DES} leading-loose`}>
              สร้างสรรค์นักวางแผนการเงิน CFP® ที่ปรึกษาการเงิน AFPT ™
              <div>และที่ปรึกษาการเงินและ</div>
              <div>การประกันภัย FchFP </div>
              <div>ที่เชี่ยวชาญในวิชาชีพและมีศิลธรรม</div>
            </div>
            <ImageMain src="../images/about_2.png" className="w-full" />
          </div>
          <div
            className={`${STYTLE_ABOUT_US} animate-fade-left animate-delay-200`}
          >
            <ImageMain src="../images/about_3.png" className="w-full" />
            <div className={` ${STYLE_ABOUT_US_DES} leading-loose`}>
              สร้างสรรค์เทคโนโลยีที่สนับสนุน วางแผนการเงินช่วยให้ผู้คนเข้าถึง
              <div>สินค้าการเงินที่เหมาะสม</div>
              <div>กับแผนการเงิน</div>
            </div>
          </div>
          <div className=" relative animate-fade-up animate-delay-300">
            <ImageMain
              src="../images/about_4.png"
              className="w-40 md:w-60 md:h-52 mx-auto h-[142px]"
            />
            <div className=" bg-[#F1F8FE] w-full rounded-[10px] px-2.5 py-1 absolute -bottom-4">
              <div className={`${STYLE_ABOUT_US_DES} text-xs`}>
                “เราสร้างความสุขและความสงบทางจิตใจ
                <div>ด้วยการวางแผนเงิน”</div>
              </div>
              <div className=" text-right text-[10px] md:text-xs text-maingray font-light">
                วรเดช ปัญจรงคะ, CFP®
              </div>
            </div>
          </div>
          <div className={`${STYLE_TEXT_CYAN} text-center text-xl pt-3`}>
            ผลงานของเรา
          </div>
          <div
            className={`${STYTLE_ABOUT_US} animate-fade-left animate-delay-500`}
          >
            <ImageMain src="../images/about_5.png" className="w-full" />
            <div className={` ${STYLE_ABOUT_US_DES} leading-loose`}>
              สร้างผู้นำองค์กรในธุรกิจการเงิน
              <div>และประกันชีวิต</div>
            </div>
          </div>
          <div
            className={`${STYTLE_ABOUT_US} animate-fade-right animate-delay-700`}
          >
            <div>
              <ImageMain
                src="../images/money_hand.png"
                className={`w-9 h-9 md:w-20 md:h-20 mx-auto`}
              />
              <div className={` ${STYLE_ABOUT_US_DES} mt-2.5`}>
                สร้างทีมนักวางแผนการเงิน CFP®
                <div>และที่ปรึกษาการเงิน</div>
              </div>
            </div>
            <ImageMain src="../images/about_6.png" className="w-full" />
          </div>
          <div
            className={`${STYTLE_ABOUT_US} animate-fade-left animate-delay-1000`}
          >
            <ImageMain src="../images/about_7.png" className="w-full" />
            <div className={` ${STYLE_ABOUT_US_DES} leading-loose`}>
              บริการระบบอีเลิรน์นิ่งเพื่อ
              <div>การเรียนรู้ได้ทุกที่ทุกเวลา</div>
            </div>
          </div>
          <div className=" w-full pt-5">
            {" "}
            <MTButton
              iconleft
              icon={
                <ImageMain src="/images/smile_open.png" className=" w-6 h-6 " />
              }
              text="มารู้จักเรามากขึ้น! "
              textsize="text-base font-semibold"
              width="w-full h-10 "
              onClick={() => {
                window?.open("https://assurewealth.co.th/");
              }}
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default AboutusMain;
