import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

const store = (set) => ({
  savingData: null,
  setSavingeData: (savingData) => set(() => ({ savingData })),
  clearSavingData: () => set(() => ({ savingData: null })),
});

export const useSavingStore = create(
  persist(store, {
    name: "savingStore",
    storage: createJSONStorage(() => localStorage), // (optional) by default the 'localStorage' is used
  })
);
