import { useRetireStore } from "../../../_store/retireStore";
import { avgWeight } from "./functionMain";

// ---------------------------------------------------- ก่อนวางแผน ------------------------------------------------ //
//ปีที่
export const getYear = () => {
  const retireData = useRetireStore.getState().retireData;
  let sum = 0;
  sum = Number(retireData?.age_dead) - retireData?.age_current;
  // sum =
  //   (retireData?.strategy_age_dead
  //     ? Number(retireData?.strategy_age_dead)
  //     : Number(retireData?.age_dead)) - retireData?.age_current;

  return sum;
};

//อายุ
export const dataAge = () => {
  const retireData = useRetireStore.getState().retireData;
  let age = [];
  for (let i = retireData?.age_current; i <= retireData?.age_dead; i++) {
    age.push(i);
  }
  return age;
};

export const loopYear = (getYear) => {
  const retireData = useRetireStore.getState().retireData;
  let year = [];
  for (let i = 0; i <= getYear; i++) {
    year.push(i);
  }
  // console.log("arr", year);
  return year;
};

export const loopAge = () => {
  const retireData = useRetireStore.getState().retireData;
  let age = [];
  for (let i = retireData?.age_current; i <= retireData?.age_dead; i++) {
    age.push(i);
  }
  return age;
  // console.log("arr", age);
};

//รายรับ
export const loopIncome = () => {
  const retireData = useRetireStore.getState().retireData;
  let results = [];
  for (let i = 0; i <= getYear(); i++) {
    const monthlyInterest = Math.pow(1 + retireData?.salary_peryear / 100, i);
    const monthlyPayment = retireData?.salary * 12 * monthlyInterest;
    if (i > retireData?.age_retire - retireData?.age_current) {
      results.push(retireData?.pension * 12);
      continue;
    }
    results.push(monthlyPayment);
  }
  // console.log("results", results);
  return results;
};

//รายจ่าย
export const loopOutcome = () => {
  const retireData = useRetireStore.getState().retireData;
  let results = [];
  for (let i = 0; i <= getYear(); i++) {
    const monthlyInterest = Math.pow(1 + retireData?.out_come_peryear / 100, i);
    const monthlyPayment = retireData?.out_come * 12 * monthlyInterest;
    const afterRetire = retireData?.out_come * 0.7 * 12 * monthlyInterest;
    if (i > retireData?.age_retire - retireData?.age_current) {
      results.push(afterRetire);
      continue;
    }
    results.push(monthlyPayment);
  }
  // console.log("results", results);
  return results;
};

//คงเหลือ
export const loopRemain = () => {
  const retireData = useRetireStore.getState().retireData;
  let results = [];
  let income = loopIncome();
  let outcome = loopOutcome();

  for (let i = 0; i <= getYear(); i++) {
    const remain = income[i] - outcome[i];
    results.push({
      income: income[i],
      outcome: outcome[i],
      results: remain,
    });
  }
  return results;
};

//ผลตอบแทนการลงทุน
export const loopReturnInvestment = () => {
  const retireData = useRetireStore.getState().retireData;
  let results = [];
  let remain = loopRemain();
  let asset = Number(retireData?.investment_assets);

  for (let i = 0; i <= getYear(); i++) {
    if (i < 1) {
      const returninvest =
        asset * (Number(retireData?.investment_bf_retirement) / 100);
      const invest = asset + returninvest + remain[i]?.results;
      results.push({ results: returninvest, invest });
    } else {
      const beforeRetire =
        results[i - 1]?.invest *
        (Number(retireData?.investment_bf_retirement) / 100);
      const prev_invest = results[i - 1]?.invest;
      const invest = prev_invest + beforeRetire + remain[i]?.results;

      const afterRetire =
        results[i - 1]?.invest * (retireData?.investment_af_retirement / 100);
      const investaf = prev_invest + afterRetire + remain[i]?.results;
      const newreturn =
        i > retireData?.age_retire - retireData?.age_current
          ? afterRetire
          : beforeRetire;

      results.push({ results: newreturn, invest });
    }
  }
  return results;
};

//สินทรัพย์พื่อกรลงทุนสุทธิ
export const loopInvestmentAssets = () => {
  const retireData = useRetireStore.getState().retireData;
  let age = dataAge();
  let results = [];
  let remain = loopRemain();
  let asset = Number(retireData?.investment_assets);

  for (let i = 0; i <= getYear(); i++) {
    if (i < 1) {
      const returninvest =
        asset * (Number(retireData?.investment_bf_retirement) / 100);
      const invest = asset + returninvest + remain[i]?.results;
      results.push({ results: invest, age: age[i] });
    } else {
      const newReturn =
        results[i - 1]?.results *
        (Number(retireData?.investment_bf_retirement) / 100);
      const prev_invest = results[i - 1]?.results;
      const invest = prev_invest + newReturn + remain[i]?.results;
      //after retire
      const newReturnaf =
        results[i - 1]?.results * (retireData?.investment_af_retirement / 100);
      const investaf = prev_invest + newReturnaf + remain[i]?.results;
      const sum =
        i > retireData?.age_retire - retireData?.age_current
          ? investaf
          : invest;
      // ? results.push({ results: afterRetire, investaf })
      // : results.push({ results: beforeRetire, invest });
      results.push({ results: sum, age: age[i] });
    }
  }
  return results;
};

// ---------------------------------------------------- หลังวางแผน ------------------------------------------------ //
//ปีที่
export const getYearAF = () => {
  const retireData = useRetireStore.getState().retireData;
  let sum = 0;
  sum =
    (retireData?.strategy_age_dead
      ? Number(retireData?.strategy_age_dead)
      : Number(retireData?.age_dead)) - retireData?.age_current;
  return sum;
};

//อายุ
export const dataAgeAF = () => {
  const retireData = useRetireStore.getState().retireData;
  let age = [];
  for (
    let i = retireData?.age_current;
    i <=
    (retireData?.strategy_age_dead
      ? Number(retireData?.strategy_age_dead)
      : Number(retireData?.age_dead));
    // Number(retireData?.age_dead);
    i++
  ) {
    age.push(i);
  }
  return age;
};

//รายได้หลังปรับกลยุทธ์
export const loopIncomeAF = () => {
  const retireData = useRetireStore.getState().retireData;
  let results = [];
  let income = Number(retireData?.strategy_salary)
    ? Number(retireData?.strategy_salary)
    : Number(retireData?.salary);

  for (let i = 0; i <= getYearAF(); i++) {
    const monthlyInterest = Math.pow(
      1 +
        (Number(retireData?.strategy_salary_peryear)
          ? Number(retireData?.strategy_salary_peryear)
          : Number(retireData?.salary_peryear)) /
          100,
      i
    );
    const monthlyPayment =
      (income + retireData?.strategy_income) * 12 * monthlyInterest;
    if (
      i >
      (retireData?.strategy_age_retire
        ? retireData?.strategy_age_retire
        : retireData?.age_retire) -
        retireData?.age_current
    ) {
      results.push(
        (Number(retireData?.strategy_passive_income_retire)
          ? Number(retireData?.pension) +
            Number(retireData?.strategy_passive_income_retire)
          : Number(retireData?.pension)) * 12
      );
      continue;
    }
    results.push(monthlyPayment);
  }
  // console.log("loopIncomeAF", results);
  return results;
};

//รายจ่ายหลังปรับกลยุทธ์
export const loopOutcomeAF = () => {
  const retireData = useRetireStore.getState().retireData;
  let results = [];

  for (let i = 0; i <= getYearAF(); i++) {
    // console.log("out_come_peryear" , retireData?.out_come_peryear)
    const monthlyInterest = Math.pow(
      1 +
        (retireData?.strategy_control_rate_outcome
          ? retireData?.strategy_control_rate_outcome
          : retireData?.out_come_peryear) /
          100,
      i
    );
    const monthlyPayment =
      (Number(retireData?.out_come) - Number(retireData?.strategy_out_come)) *
      12 *
      monthlyInterest;
    const afterRetire =
      (Number(retireData?.out_come) - Number(retireData?.strategy_out_come)) *
      0.7 *
      12 *
      monthlyInterest;
    if (
      i >
      (retireData?.strategy_age_retire
        ? retireData?.strategy_age_retire
        : retireData?.age_retire) -
        retireData?.age_current
    ) {
      results.push(afterRetire);
      continue;
    }
    results.push(monthlyPayment);
  }
  // console.log("loopOutcomeAF", results);
  return results;
};

//คงเหลือหลังปรับ
export const loopRemainAF = () => {
  const retireData = useRetireStore.getState().retireData;
  let results = [];
  let income = loopIncomeAF();
  let outcome = loopOutcomeAF();
  let age = dataAgeAF();

  for (let i = 0; i <= getYearAF(); i++) {
    const remain = income[i] - outcome[i];
    results.push({
      income: income[i],
      outcome: outcome[i],
      results: remain,
      year: i + 1,
      age: age[i],
    });
  }

  return results;
};

export const weightValue = () => {
  const retireData = useRetireStore.getState().retireData;
  const result = avgWeight(
    retireData?.investment_bf_retirement,
    retireData?.investment_assets,
    retireData?.strategy_assets_generate,
    retireData?.strategy_assets_generate_per_year
  );
  return result;
};

//ผลตอบแทนการลงทุนหลังปรับ
export const loopReturnInvestmentAF = () => {
  const retireData = useRetireStore.getState().retireData;
  let results = [];
  let remain = loopRemainAF();
  let income = loopIncomeAF();
  let outcome = loopOutcomeAF();
  let asset = retireData?.strategy_assets_generate
    ? Number(retireData?.strategy_assets_generate) +
      Number(retireData?.investment_assets)
    : Number(retireData?.investment_assets);

  let assetAndStg =
    Number(retireData?.investment_assets) +
    Number(retireData?.strategy_assets_generate);

  let checkAsset =
    retireData?.strategy_assets_generate_per_year &&
    retireData?.strategy_investment_bf_retirement;

  // const beforeSTG = !checkAsset
  //   ? // ? weightValue()
  //     // : Number(retireData?.strategy_investment_bf_retirement);
  //     Number(retireData?.strategy_investment_bf_retirement)
  //   : weightValue()
  //   ? weightValue()
  //   : 0;

  let beforeSTG = weightValue();

  // openInvestment

  if (retireData?.openAssetGen && retireData?.openInvestment) {
    beforeSTG = weightValue();
  } else {
    beforeSTG = retireData?.strategy_investment_bf_retirement
      ? retireData?.strategy_investment_bf_retirement
      : retireData?.investment_bf_retirement;
  }

  // const beforeSTG = 8.9;

  const afterSTG = retireData?.strategy_investment_af_retirement
    ? Number(retireData?.strategy_investment_af_retirement)
    : Number(retireData?.investment_af_retirement);

  for (let i = 0; i <= getYearAF(); i++) {
    if (i < 1) {
      //first
      const returninvest = asset * (beforeSTG / 100); //25
      const invest = assetAndStg + returninvest + remain[i]?.results;
      results.push({ results: returninvest, invest });
    } else {
      const ageRetie = retireData?.strategy_age_retire
        ? retireData?.strategy_age_retire
        : retireData?.age_retire;

      //หลังเกษียณ
      const remain = income[i] - outcome[i];
      const CG =
        results[i - 1]?.invest *
        (i + retireData.age_current > retireData?.age_retire
          ? afterSTG / 100
          : beforeSTG / 100);
      const newInvest = results[i - 1]?.invest + remain + CG;
      results.push({ results: CG, invest: newInvest });

      // H25*($B$19/100)

      // const cD =
      // results.push({ results: newreturn, invest });
      // const newReturn = results[i - 1]?.results * (weightValue() / 100);
      // const prev_invest = results[i - 1]?.results;
      // const invest = prev_invest + newReturn + remain[i]?.results;
      // ก่อนเกษียณ
      // const beforeRetire = results[i - 1]?.invest * (weightValue() / 100);
      // const prev_invest = results[i - 1]?.invest;
      // const invest = prev_invest + beforeRetire + remain[i]?.results;
      // console.log("prev_invest", remain[i]?.results);
      //หลังเกษียณ
      // const prev_invest_af = results[i - 1]?.invest;
      // const investaf = prev_invest_af + afterRetire + remain[i]?.results;
      // const afterRetire =
      //   results[i - 1]?.invest * (retireData?.investment_af_retirement / 100);
      // ((retireData?.strategy_investment_af_retirement
      //   ? Number(retireData?.strategy_investment_af_retirement)
      //   : Number(retireData?.investment_af_retirement)) /
      //   100);
      // console.log("AA:", results[i - 1]?.invest);  const newReturnaf =
      // results[i - 1]?.results *
      // ((retireData?.strategy_investment_af_retirement
      //   ? Number(retireData?.strategy_investment_af_retirement)
      //   : Number(retireData?.investment_af_retirement)) /
      //   100);
      // const newReturnaf =
      //   results[i - 1]?.invest *
      //   ((retireData?.strategy_investment_af_retirement
      //     ? Number(retireData?.strategy_investment_af_retirement)
      //     : Number(retireData?.investment_af_retirement)) /
      //     100);
      // const investaf = prev_invest + newReturnaf + remain[i]?.results;
      // const ageRetie = retireData?.strategy_age_retire
      //   ? retireData?.strategy_age_retire
      //   : retireData?.age_retire;
      // const newreturn =
      //   i > ageRetie - retireData?.age_current ? afterRetire : beforeRetire;
      // console.log("i", retireData?.age_current + i);
      // console.log("prev_invest", prev_invest);
      // console.log("beforeRetire", beforeRetire);
      // console.log("invest", invest);
      // // console.log("newReturnaf", newReturnaf);
      // console.log("---------------------------------------------");
      // results.push({ results: newreturn, invest });
    }
  }
  // console.log("loopReturnInvestmentAF", results);
  return results;
};

//สินทรัพย์เพื่อการลงทุนหลังปรับ
export const loopInvestmentAssetsAF = () => {
  const retireData = useRetireStore.getState().retireData;
  let age = dataAgeAF();
  let results = [];
  let remain = loopRemainAF();
  let asset = retireData?.strategy_assets_generate
    ? Number(retireData?.strategy_assets_generate) +
      Number(retireData?.investment_assets)
    : Number(retireData?.investment_assets);
  let assetAndStg =
    Number(retireData?.investment_assets) +
    Number(retireData?.strategy_assets_generate);

  let checkAsset =
    retireData?.strategy_assets_generate_per_year &&
    retireData?.strategy_investment_af_retirement;

  // const beforeSTG = checkAsset
  //   ? Number(retireData?.strategy_investment_bf_retirement)
  //   : weightValue()
  //   ? weightValue()
  //   : 0;
  let beforeSTG = weightValue();

  if (retireData?.openAssetGen && retireData?.openInvestment) {
    beforeSTG = weightValue();
  } else {
    beforeSTG = retireData?.strategy_investment_bf_retirement
      ? retireData?.strategy_investment_bf_retirement
      : retireData?.investment_bf_retirement;
  }

  // const beforeSTG = 8.9;

  const afterSTG = retireData?.strategy_investment_af_retirement
    ? Number(retireData?.strategy_investment_af_retirement)
    : Number(retireData?.investment_af_retirement);

  for (let i = 0; i <= getYearAF(); i++) {
    //ตัวแรก
    if (i < 1) {
      const returninvest = asset * (beforeSTG / 100);
      const invest = assetAndStg + returninvest + remain[i]?.results;
      results.push({ results: invest, age: age[i] });
    } else {
      //ก่อนเกษียณ
      const newReturn = results[i - 1]?.results * (beforeSTG / 100);
      const prev_invest = results[i - 1]?.results;
      const invest = prev_invest + newReturn + remain[i]?.results;

      //หลังเกษียณ
      const newReturnaf = results[i - 1]?.results * (afterSTG / 100);

      const investaf = prev_invest + newReturnaf + remain[i]?.results;

      const sum =
        i >
        (retireData?.strategy_age_retire
          ? Number(retireData?.strategy_age_retire)
          : retireData?.age_retire) -
          retireData?.age_current
          ? investaf
          : invest;

      results.push({ results: sum, age: age[i] });
    }
  }

  return results;
};
