import { NumericFormat } from "react-number-format";
import DropDownMain from "../../../../components/dropdown/DropDownMain";
import { ImageMain } from "../../../../components/img/Img.Main";
import { ICON_DELETE, ICON_INFO, ICON_RIGHT } from "../../../../utils/useIcons";
import {
  STYLE_INPUT_CARD_BALANCE,
  STYLE_PARENT_CARD_BALANCE,
  STYLE_TEXTAREA_CARD_BALANCE,
  STYLE_TITLE_CARD_DRAWER_BL,
} from "../../../../utils/useStyle";
import { CheckBoxSecond } from "../../../../components/checkbox/CheckboxSecond";
import DropDownGroupMain from "../../../../components/dropdown/DropDownGroupMain";
import {
  InputMain,
  InputWithLabel,
} from "../../../../components/input/InputMain";
import NumbericValidateRed from "../../../../components/DebtBalance/numericRed";
import { useTaxStore } from "../../../../_store/taxplanStore";
import numeral from "numeral";
import { useEffect, useState } from "react";
import { numberWithoutComma } from "../../../balance/component/VIDrawerDebt.Main";
import { cn } from "../../../../utils/useFunctions";

export const CardMenuPremium = ({
  img,
  title,
  onClick,
  hasDetails,
  onClickOpen,
  open,
}) => {
  const taxData = useTaxStore((state) => state.taxData);

  return (
    <div
      className={`bg-gray50 group hover:bg-blueopacity duration-200 px-[18px] py-2.5 rounded-[20px]   cursor-pointer`}
    >
      <div className="flex items-center  ">
        <div className={`flex items-center space-x-[17px] w-full  `}>
          <ImageMain src={img} className=" w-[50px] h-[50px] " />
          <div className=" text-sm text-black font-semibold ">{title}</div>
        </div>
        <div className={`flex items-center justify-end `}>
          <ICON_RIGHT
            className={` w-6 h-6 flex-none ml-auto text-[#14181F] `}
            onClick={onClick}
          />
        </div>
      </div>

      {hasDetails ? (
        <div onClick={onClickOpen} className="  ">
          {open ? (
            <div className="text-xs  text-maingray  text-center underline underline-offset-2 w-full col-span-full">
              ซ่อนดูรายละเอียด
            </div>
          ) : (
            <div className="text-xs  text-maingray  text-center underline underline-offset-2  w-full col-span-full">
              ดูรายละเอียด
            </div>
          )}
          {open && (
            <div className=" grid grid-cols-2 mt-2 ">
              <div className=" text-maingray text-sm">รวมปีละ</div>
              <div className=" text-mainblue font-medium ml-auto">
                {numeral(taxData?.tax_income)?.format("0,0")} บาท
              </div>
              <div className=" text-maingray text-sm">ภาษีหัก ณ ที่จ่าย</div>
              <div className=" text-mainblue font-medium ml-auto">
                {numeral(taxData?.tax_withholding)?.format("0,0")} บาท
              </div>
            </div>
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export const CardTaxPremium = ({
  index,
  isActive = false,
  options,
  value_dropdown,
  optionsGroup = false,
  openInfo,
  expressTitle = " การคำนวณค่าใช้จ่าย ",
  ishasCal = true,
  dropdown_title = "ประเภทธุรกิจ",
  isHasCompany = false,
  isHastitle1 = true,
  title1 = "เงินได้ทั้งหมด",
  isHasHoldingYear = false,
  titleHoldingYear = "ปีที่ถือครอง",
  dropdown_title_2 = "อัตราภาษี",
  allIn_percent = "",
  claculateMethodTitle = "การคำนวณภาษี",
  options_2,
  value_dropdown_2,
  isHasTaxPercent = false,
  isHasDividend = false,
  isHasDropDown1 = true,
  isHasDate1 = false,
  isHasDate2 = false,
  isHasPay = false,
  isHasLand = false,
  isDisableDropdown2 = false,
  onChangeFreeJob = () => {},
  DeleteOrder = () => {},
  data = {},
  isSeparateCal = false,
  DisableAllIn = false,
  DisableReal = false,
  isCompanyName = false,
  isDisableTaxCredit = false,
  isDisableWithholdingTax = false,
  isHasAllInInfo = false,
  openAllInData
}) => {
  // const [openAllInData,setAllInData] = useState(false)

  const calculatingTaxAllIn = () => {
    if (data?.calculating_method === "หักแบบเหมา" && data?.type_job?.value) {
      if (data?.type_job?.value === " tax_income_medicine") {
        return numeral(data?.tax_income - data?.tax_income * 0.6)?.format(
          "0,0"
        );
      } else {
        return numeral(data?.tax_income - data?.tax_income * 0.3)?.format(
          "0,0"
        );
      }
    } else {
      return "-";
    }
  };

  const calculatingTaxReal = () => {
    let RealTaxPay = 0;
    if (data?.calculating_method === "หักตามจริง") {
      if (data?.tax_income > data?.Tax_Real) {
        RealTaxPay = data?.tax_income - Number(data?.Tax_Real || 0);
      } else {
        RealTaxPay = 0;
      }
    }

    return RealTaxPay;
  };

  useEffect(() => {
    onChangeFreeJob(
      numberWithoutComma(calculatingTaxReal()),
      "income_for_calculating",
      index
    );
  }, [data?.Tax_Real]);

  return (
    <div
      key={index}
      className=" bg-white rounded-[20px] px-4 pt-3 pb-[14px] mt-4 "
    >
      <div className=" flex justify-between">
        <div className=" text-base text-mainblue font-semibold">
          รายการที่{index + 1}
        </div>
        <ICON_DELETE
          className=" w-5 h-5 ml-auto cursor-pointer"
          onClick={() => {
            DeleteOrder(index);
          }}
        />
      </div>

      {isHasDropDown1 ? (
        <div>
          <div className={`${STYLE_TITLE_CARD_DRAWER_BL}  `}>
            {dropdown_title}
          </div>
          {optionsGroup ? (
            <DropDownGroupMain
              options={options}
              value={value_dropdown}
              onChange={(e) => {
                onChangeFreeJob(e, "type_job", index);
              }}
            />
          ) : (
            <DropDownMain
              className="border border-gray80 rounded-full"
              options={options}
              value={data?.type_job}
              onChange={(e) => {
                onChangeFreeJob(e, "type_job", index);
              }}
            />
          )}
        </div>
      ) : (
        ""
      )}
      {isHasDate1 ? (
        <div className={STYLE_PARENT_CARD_BALANCE}>
          <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
            วัน/เดือน/ปีที่โอน
          </div>
          <InputMain
            type="date"
            placeholder="ระบุ"
            className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
            value={data?.date_transaction}
            onChange={(e) => {
              onChangeFreeJob(e.target.value, "date_transaction", index);
            }}
          />
        </div>
      ) : (
        ""
      )}

      {isHasPay ? (
        <div>
          <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
              ค่าตอบแทน
            </div>
            <div className="flex items-center mb-3 space-x-6 col-span-3 justify-end">
              <CheckBoxSecond
                isLeft={true}
                label_title={`มี`}
                checked={data?.Compensation === `มี`}
                onClick={() => {
                  onChangeFreeJob(`มี`, "Compensation", index);
                }}
              />
              <CheckBoxSecond
                isLeft={true}
                label_title={`ไม่มี`}
                checked={data?.Compensation === `ไม่มี`}
                onClick={() => {
                  onChangeFreeJob(`ไม่มี`, "Compensation", index);
                }}
              />
            </div>
          </div>
          <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
              เลขที่ใบเสร็จรับเงิน
            </div>
            <NumericFormat
              placeholder="0"
              value={data?.receipt_number}
              onChange={(e) => {
                onChangeFreeJob(e.target.value, "receipt_number", index);
              }}
              className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
            />
          </div>
        </div>
      ) : (
        ""
      )}
      {isHasDate2 ? (
        <div className={STYLE_PARENT_CARD_BALANCE}>
          <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
            วัน/เดือน/ปี เลขที่ใบเสร็จรับเงิน
          </div>
          <InputMain
            type="date"
            placeholder="ระบุ"
            className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
            value={data?.date_receipt}
            onChange={(e) => {
              onChangeFreeJob(e.target.value, "date_receipt", index);
            }}
          />
        </div>
      ) : (
        ""
      )}

      {isHasLand ? (
        <>
          <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
              เลขที่โฉนด
            </div>
            <NumericFormat
              placeholder="0"
              value={data?.deed_number}
              onChange={(e) => {
                onChangeFreeJob(e.target.value, "deed_number", index);
              }}
              className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
            />
          </div>
          <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
              ระวางโฉนด
            </div>
            <NumericFormat
              placeholder="0"
              value={data?.Transport_deed}
              onChange={(e) => {
                onChangeFreeJob(
                  numberWithoutComma(e.target.value),
                  "Transport_deed",
                  index
                );
              }}
              className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
            />
          </div>
          <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
              มูลค่า (ราคาประเมิน)
            </div>
            <NumericFormat
              placeholder="0"
              value={data?.deed_value}
              onChange={(e) => {
                onChangeFreeJob(
                  numberWithoutComma(e.target.value),
                  "deed_value",
                  index
                );
              }}
              className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
            />
          </div>
          <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
              หัก ณ ที่จ่าย
            </div>
            <NumericFormat
              placeholder="0"
              value={data?.deed_withholding_tax}
              onChange={(e) => {
                onChangeFreeJob(
                  numberWithoutComma(e.target.value),
                  "deed_withholding_tax",
                  index
                );
              }}
              className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
            />
          </div>
          <div className={``}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL}`}>รายละเอียด</div>
            <textarea
              //  value={value}
              className={`${STYLE_TEXTAREA_CARD_BALANCE} w-full`}
              placeholder="ระบุ"
              value={data?.deed_detail}
              onChange={(e) => {
                onChangeFreeJob(e.target.value, "deed_detail", index);
              }}
            />
          </div>
        </>
      ) : (
        ""
      )}

      {isHasCompany ? (
        <div className={STYLE_PARENT_CARD_BALANCE}>
          <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
            ชื่อบริษัท
          </div>
          <InputMain
            placeholder="ระบุ"
            className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
          />
        </div>
      ) : (
        ""
      )}

      {isHasHoldingYear ? (
        <div className={STYLE_PARENT_CARD_BALANCE}>
          <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
            {titleHoldingYear}
          </div>

          <NumericFormat
            maxLength={11}
            placeholder="0"
            value={data?.tax_holding_year}
            onChange={(e) => {
              onChangeFreeJob(
                numberWithoutComma(e.target.value),
                "tax_holding_year",
                index
              );
            }}
            thousandSeparator=","
            inputMode="decimal"
            pattern="[0-9],*"
            className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
          />
        </div>
      ) : (
        ""
      )}

      {isHastitle1 ? (
        <div className={STYLE_PARENT_CARD_BALANCE}>
          <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
            {title1}
          </div>

          <NumericFormat
            maxLength={11}
            placeholder="0"
            value={data?.tax_income}
            onChange={(e) => {
              onChangeFreeJob(
                numberWithoutComma(e.target.value),
                "tax_income",
                index
              );
            }}
            thousandSeparator=","
            inputMode="decimal"
            pattern="[0-9],*"
            className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
          />
        </div>
      ) : (
        ""
      )}

      {isCompanyName && (
        <>
          <div className="mt-[5px]">
            <InputWithLabel
              headerStyle={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2`}
              className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
              mainStyle={`${STYLE_PARENT_CARD_BALANCE} col-span-2  `}
              label={`ชื่อบริษัท`}
              placeholder="ระบุ"
              value={data?.company_name}
              onChange={(e) => {
                onChangeFreeJob(e.target.value, "company_name", index);
              }}
            />
          </div>
          <div className={`${STYLE_PARENT_CARD_BALANCE} mb-[5px]`}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
              <div className="flex gap-1">
                {" "}
                <div>เลขผู้จ่ายเงินได้</div>{" "}
                {/* <ICON_INFO
                  className={` w-4 h-4 text-gray-900`}
                  onClick={openInfo}
                /> */}
              </div>
            </div>
            <NumericFormat
              maxLength={13}
              placeholder="0"
              value={data?.tax_payer_ID}
              onChange={(e) => {
                onChangeFreeJob(e.target.value, "tax_payer_ID", index);
              }}
              className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
            />
          </div>
        </>
      )}

      {isHasTaxPercent ? (
        <div>
          <div className={`${STYLE_TITLE_CARD_DRAWER_BL}  `}>
            {dropdown_title_2}
          </div>
          <DropDownMain
            className="border border-gray80 rounded-full"
            options={options_2}
            value={data?.interest_rate}
            isDisabled={isDisableDropdown2}
            onChange={(e) => {
              onChangeFreeJob(e, "interest_rate", index);
            }}
          />
        </div>
      ) : (
        ""
      )}

      {isHasDividend ? (
        <div>
          <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
              จำนวนเงินปันผล/ ส่วนแบ่งกำไร
            </div>
            <NumericFormat
              placeholder="0"
              thousandSeparator=","
              value={data?.dividend}
              onChange={(e) => {
                onChangeFreeJob(
                  numberWithoutComma(e.target.value),
                  "dividend",
                  index
                );
              }}
              className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
            />
          </div>
          <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
              เครดิตภาษี
            </div>
            <NumericFormat
              placeholder="0"
              thousandSeparator=","
              value={data?.tax_credit}
              disabled={isDisableTaxCredit}
              decimalScale={2}
              onChange={(e) => {
                onChangeFreeJob(
                  numberWithoutComma(e.target.value),
                  "tax_credit",
                  index
                );
              }}
              className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
            />
          </div>
          <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
              ภาษีหัก ณ ที่จ่าย
            </div>
            <NumericFormat
              placeholder="0"
              thousandSeparator=","
              decimalScale={2}
              value={data?.with_holding_tax}
              disabled={isDisableWithholdingTax}
              onChange={(e) => {
                onChangeFreeJob(
                  numberWithoutComma(e.target.value),
                  "with_holding_tax",
                  index
                );
              }}
              className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
            />
          </div>
        </div>
      ) : isHasLand ? (
        ""
      ) : (
        <div>
          <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
              ภาษีหัก ณ ที่จ่าย
            </div>
            <NumericFormat
              placeholder="0"
              value={data?.with_holding_tax}
              disabled={isDisableWithholdingTax}
              onChange={(e) => {
                onChangeFreeJob(
                  numberWithoutComma(e.target.value),
                  "with_holding_tax",
                  index
                );
              }}
              thousandSeparator=","
              inputMode="decimal"
              pattern="[0-9],*"
              className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
            />
          </div>
          <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
              <div className="flex gap-1">
                {" "}
                <div>เลขผู้จ่ายเงินได้</div>{" "}
                <ICON_INFO
                  className={` w-4 h-4 text-gray-900`}
                  onClick={openInfo}
                />
              </div>
            </div>
            <NumericFormat
              maxLength={13}
              placeholder="0"
              value={data?.tax_payer_ID}
              onChange={(e) => {
                onChangeFreeJob(e.target.value, "tax_payer_ID", index);
              }}
              className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
            />
          </div>
          {isSeparateCal && (
            <>
              <div className="font-semibold text-black mt-2 text-sm">
                {claculateMethodTitle}
              </div>
              <div className="flex space-x-[20px] ">
                <CheckBoxSecond
                  isLeft={true}
                  label_title={"ไม่แยกคำนวณ"}
                  checked={data?.calculating_way === "ไม่แยกคำนวณ"}
                  onClick={() => {
                    onChangeFreeJob("ไม่แยกคำนวณ", "calculating_way", index);
                  }}
                />
                <CheckBoxSecond
                  isLeft={true}
                  label_title={"แยกคำนวณ"}
                  checked={data?.calculating_way === "แยกคำนวณ"}
                  onClick={() => {
                    onChangeFreeJob("แยกคำนวณ", "calculating_way", index);
                  }}
                />
              </div>
            </>
          )}
          {ishasCal ? (
            <>
              <div className="font-semibold text-black mt-2 text-sm">
                {expressTitle}
              </div>
              <CheckBoxSecond
                isLeft={true}
                label_title={
                  <div className="flex"> 
                    <div>หักแบบเหมา  ({allIn_percent} %)</div>
                    {isHasAllInInfo && <ICON_INFO
                      className={` w-4 h-4 text-gray-900`}
                      onClick={()=>{
                        openAllInData(true)
                      }}
                    />}
                  </div>
                }
                checked={data?.calculating_method === "หักแบบเหมา"}
                onClick={() => {
                  onChangeFreeJob("หักแบบเหมา", "calculating_method", index);
                }}
                disable={DisableAllIn}
              />

              <div className=" flex justify-between items-center mt-3">
                <div className="font-semibold text-black  text-sm">
                  เงินที่ต้องไปคำนวณภาษี
                </div>
                <div
                  className={`${
                    isActive
                      ? "text-maincyan font-medium"
                      : "text-maingray font-medium"
                  } `}
                >
                  {numeral(data?.income_for_calculating_all_in)?.format("0,0")}{" "}
                  บาท
                </div>
              </div>
              <div className="text-maingray text-xs space-y-1">
                *ไม่ต้องแสดงหลักฐาน
              </div>
              <CheckBoxSecond
                isLeft={true}
                label_title={"หักตามจริง"}
                checked={data?.calculating_method === "หักตามจริง"}
                onClick={() => {
                  onChangeFreeJob("หักตามจริง", "calculating_method", index);
                }}
                disable={DisableReal}
              />
              <div className={STYLE_PARENT_CARD_BALANCE}>
                <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
                  ระบุจำนวนเงิน
                </div>
                <NumericFormat
                  maxLength={11}
                  placeholder="0"
                  value={data?.Tax_Real}
                  onChange={(e) => {
                    onChangeFreeJob(
                      numberWithoutComma(e.target.value),
                      "Tax_Real",
                      index
                    );
                  }}
                  disabled={
                    data?.calculating_method === "หักแบบเหมา" ? true : false
                  }
                  thousandSeparator=","
                  inputMode="decimal"
                  pattern="[0-9],*"
                  className={cn(`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`, {
                    "text-maingray": data?.calculating_method === "หักแบบเหมา",
                  })}
                />
              </div>
              <div className=" flex justify-between items-center mt-3">
                <div className="font-semibold text-black  text-sm">
                  เงินที่ต้องไปคำนวณภาษี
                </div>
                <div
                  className={`${
                    isActive
                      ? "text-maincyan font-medium"
                      : "text-maingray font-medium"
                  } `}
                >
                  {numeral(calculatingTaxReal())?.format("0,0")} บาท
                </div>
              </div>
              <div className="text-maingray text-xs space-y-1">
                *ต้องแจกแจงและแสดงหลักฐานเพิ่มเติม
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
};
