export const option_cal_express = [
  {
    label: "เดือนสุดท้าย",
    value: "tax_last_month",
  },
  {
    label: "เฉลี่ย 12เดือนสุดท้าย + ร้อยละ 10 ของเงินเดือนถัวเฉลี่ย",
    value: "tax_12_month",
  },
];

export const option_first_cal = [
  {
    label: "7,000",
    value: 7000,
  },
  {
    label: "3,500",
    value: 3500,
  },
];

export const option_income_business_tax_40_6 = [
  {
    label: "เงินได้จากวิชาชีพอิสระ (วิชากฎหมาย)",
    value: " tax_income_law",
  },
  {
    label: "เงินได้จากวิชาชีพอิสระ (การประกอบโรคศิลปะ)",
    value: " tax_income_medicine",
  },
  {
    label: "เงินได้จากวิชาชีพอิสระ (วิศวกรรม)",
    value: " tax_income_engineer",
  },
  {
    label: "เงินได้จากวิชาชีพอิสระ (สถาปัตยกรรม)",
    value: " tax_income_architecture",
  },
  {
    label: "เงินได้จากวิชาชีพอิสระ (การบัญชี)",
    value: " tax_income_accounting",
  },
  {
    label: "เงินได้จากวิชาชีพอิสระ (ประณีตศิลปกรรม)",
    value: " tax_income_sculpture",
  },
];

export const option_income_business_tax_40_3 = [
  {
    label: "ค่าแห่งกู๊ดวิลล์",
    value: "tax_income_goodwill",
  },
  {
    label: "ค่าสิทธิ์อย่างอื่น",
    value: " tax_income_other",
  },
  {
    label: "เงินได้ที่มีลักษณะเป็นเงินรายปี",
    value: " tax_income_annual",
  },
  {
    label: "ค่าแห่งลิขสิทธิ",
    value: " tax_income_royalty",
  },
];

export const option_income_business_tax_40_5 = [
  {
    label: "การให้เช่าบ้าน โรงเรือน สิ่งปลูกสร้างอย่างอื่น หรือแพ",
    value: " tax_income_rent_house",
  },
  // {
  //   label: "สิ่งปลูกสร้างอย่างอื่นหรือแพ",
  //   value: " tax_income_other_building",
  // },
  {
    label: "การให้เช่าที่ดินที่ใช้ในการเกษตรกรรม",
    value: " tax_income_rent_land_agriculture",
  },
  {
    label: "การให้เช่าที่ดินที่มิได้ใช้ในการเกษตรกรรม",
    value: " tax_income_rent_land_not_agriculture",
  },
  {
    label: "การให้เช่ายานพาหนะ",
    value: " tax_income_rent_vehicle",
  },
  {
    label: "การให้เช่าทรัพย์สินอื่น ๆ ",
    value: " tax_income_rent_other",
  },
  {
    label: "การผิดสัญญาเช่าซื้อทรัพย์สิน/ซื้อขายเงินผ่อน",
    value: " tax_income_breach_contract",
  },
];

export const option_income_business_tax_40_7 = [
  {
    label: "เงินได้จากการรับเหมา",
    value: " tax_income_contract",
  },
];
export const option_income_business_tax_40_8 = [
  //  ----- รางวัล, ส่วนแบ่งกำไร -----
  {
    label: "รางวัล, ส่วนแบ่งกำไร",
    options: [
      {
        label: "การเก็บค่าต๋ง หรือค่าเกมจากการพนัน การแข่งขันหรือการเล่นต่าง ๆ",
        value: "",
      },
      {
        label: "รางวัลที่เจ้าของม้าได้จากการส่งม้าเข้าแข่ง ",
        value: "",
      },
      {
        label: "เงินส่วนแบ่งของกำไรจากกองทุนรวมตามประกาศคณะปฏิวัติฯ",
        value: "",
      },
    ],
  },

  //  ----- งานช่าง งานฝีมือ งานเขียน -----
  {
    label: "งานช่าง งานฝีมือ งานเขียน",
    options: [
      {
        label: "การถ่าย ล้าง อัด หรือขยายรูป ภาพยนตร์ รวมทั้งการขายส่วนประกอบ",
        value: "",
      },
      {
        label:
          "การทำกิจการคานเรือ อู่เรือ หรือซ่อมเรือที่มิใช่ซ่อมเครื่องจักร เครื่องกล ",
        value: "",
      },
      {
        label:
          "การทำรองเท้า และเครื่องหนังแท้ หรือหนังเทียม รวมทั้งการขายส่วนประกอบ ",
        value: "",
      },
      {
        label:
          "การตัด เย็บ ถัก ปักเสื้อผ้า หรือสิ่งอื่น ๆ รวมทั้งการขายส่วนประกอบ ",
        value: "",
      },
      {
        label: "การทำ ตกแต่ง หรือซ่อมแซมเครื่องเรือน รวมทั้งการขายส่วนประกอบ",
        value: "",
      },
      {
        label: "การทำวรรณกรรม",
        value: "",
      },
      {
        label:
          "การทำบล๊อกและตรา การรับพิมพ์หรือเย็บสมุด เอกสาร รวมทั้งการขายส่วนประกอบ",
        value: "",
      },
      {
        label:
          "การทำเครื่องกระเบื้อง เครื่องเคลือบ เครื่องชีเมนต์ หรือ ดินเผา  ",
        value: "",
      },
      {
        label: "การทำลูกโป่ง เครืองแก้ว เครื่องพลาสติก หรือเครื่องยางสำเร็จรูป",
        value: "",
      },
      {
        label: "การซักรีด หรือย้อมสี ",
        value: "",
      },
      {
        label: "การฟอกหนัง ",
        value: "",
      },
      {
        label: "เงินได้จากการแสดง ",
        value: "",
      },
    ],
  },

  //  ----- อาหาร เครื่องอุปโภค บริโภค -----
  {
    label: "อาหาร เครื่องอุปโภค บริโภค",
    options: [
      {
        label:
          "การทำกิจการโรงแรม หรือภัตตาคาร หรือการปรุงอาหารหรือเครื่องดื่มจำหน่าย",
        value: "",
      },
      {
        label: "การทำสบู่ แชมพู หรือเครื่องสำอาง",
        value: "",
      },
      {
        label: "การทำเครื่องดื่มตามกฎหมายว่าด้วยภาษีเครื่องดื่ม",
        value: "",
      },
      {
        label: "การทำน้ำแข็ง",
        value: "",
      },
      {
        label: "การทำน้ำตาล หรือน้ำเหลืองของน้ำตาล",
        value: "",
      },
      {
        label: "การทำกิจการโรงสีข้าว",
        value: "",
      },
      {
        label: "การอบหรือบ่มใบยาสูบ",
        value: "",
      },
      {
        label: "การฆ่าสัตว์จำหน่าย รวมทั้งการขายวัตถุพลอยได้",
        value: "",
      },
    ],
  },
  //  ----- สุขภาพและความงาม -----
  {
    label: "สุขภาพและความงาม",
    options: [
      {
        label: "การดัด ตัด แต่งผม หรือตกแต่งร่างกาย",
        value: "",
      },
      {
        label: "การทำกิจการสถานพยาบาล รวมทั้งการรักษาพยาบาลและการจำหน่ายยา",
        value: "",
      },
    ],
  },
  //  ----- การขาย-รับซื้อสินค้า -----
  {
    label: "การขาย-รับซื้อสินค้า",
    options: [
      {
        label:
          "การค้าเครื่องเงิน ทอง นาก เพชรพลอย หรืออัญมณีอื่น ๆ รวมทั้งการขายส่วนประกอบ",
        value: "",
      },
      {
        label: "การขายของนอกจากที่ระบุไว้ในข้ออื่น ซึ่งผู้ขายมิได้เป็นผู้ผลิต",
        value: "",
      },
      {
        label:
          "การรับสินไถ่ทรัพย์สินที่ขายฝาก หรือการได้กรรมสิทธิในทรัพย์สินโดยเด็ดขาดจากการขายฝาก",
        value: "",
      },
      {
        label:
          "การขายเรือกำปั่นหรือเรือมีระวางตั้งแต่ 6 ตันขึ้นไป เรีอกลไฟหรือเรือยนต์มีระวางตั้งแต่ 5 ตันขึ้นไป",
        value: "",
      },
    ],
  },
  //  ----- อุตสาหกรรม -----
  {
    label: "อุตสาหกรรม, แปรรูป",
    options: [
      {
        label: "การโม่ย่อยหิน",
        value: "",
      },
      {
        label: "การทำเหมืองแร่",
        value: "",
      },
      {
        label: "การทำหรือจำหน่ายกระแสไฟฟ้า",
        value: "",
      },
      {
        label:
          "การทำกาว แป้งเปียก หรือสิ่งที่มีลักษณะทำนองเดียวกัน และการทำแป้งชนิดต่าง ๆ ที่มิใช่เครื่องสำอาง",
        value: "",
      },
      {
        label: "การรมยาง การทำยางแผ่น หรือยางอย่างอื่นที่มิใช่ยางสำเร็จรูป",
        value: "",
      },
      {
        label: "การทำกิจการโรงเลื่อย",
        value: "",
      },
      {
        label: "การกลั่น หรือหีบน้ำมัน",
        value: "",
      },
    ],
  },
  //  ----- การเกษตร การประมง -----
  {
    label: "การเกษตร การประมง",
    options: [
      {
        label: "การทำป่าไม้ สวนยาง หรือไม้ยืนต้น",
        value: "",
      },
      {
        label: "การจับสัตว์น้ำ",
        value: "",
      },
      {
        label: "การทำเกษตรกรรมประเภทไม้ล้มลุกและธัญชาติ",
        value: "",
      },
      {
        label: "การเลี้ยงสัตว์ทุกชนิด รวมทั้งการขายวัตถุพลอยได้",
        value: "",
      },
      {
        label: "การทำนาเกลือ",
        value: "",
      },
    ],
  },
  //  ----- การขนส่ง  -----
  {
    label: "ขนส่ง",
    options: [
      {
        label: "การขนส่ง หรือรับจ้างด้วยยานพาหนะ",
        value: "",
      },
    ],
  },
  //  ----- ปล่อยเช่า  -----
  {
    label: "ปล่อยเช่า",
    options: [
      {
        label: "การให้เช่าซื้อสังหาริมทรัพย์ที่ไม่เข้าลักษณะตามมาตรา 40 (5)",
        value: "",
      },
      {
        label: "แห่งประมวลรัษฎากร",
        value: "",
      },
      {
        label: "การขายที่ดินเงินผ่อนหรือการให้เช่าซื้อที่ดิน",
        value: "",
      },
    ],
  },
  //  ----- เงินได้อื่น ๆ  -----
  {
    label: "การให้บริการ",
    options: [
      {
        label: "เงินได้อื่นๆ",
        value: "",
      },
    ],
  },
];

export const option_income_business_tax_40_8_asset = [
  {
    label: "การขายอสังหาริมทรัพย์อันเป็นมรดก หรือที่ได้รับจากการให้โดยเสน่หา",
    value: "",
  },
  {
    label: "การขายอสังหาริมทรัพย์ที่ได้มาโดยมิได้มุ่งในทางการค้าหรือหากำไร",
    value: "",
  },
];

export const option_income_invest_tax_40_4 = [
  {
    label:
      "ดอกเบี้ย (เฉพาะที่ไม่เลือกเสียภาษีในอัตราร้อยละ 15.0)/ เงินเทียบเท่าเงินปั่นผลจาก THAI NVDR",
    value: "",
  },
  {
    label: "เงินโบนัสที่จ่ายแก่ผู้ถือหุ้นหรือผู้เป็นหุ้นส่วน",
    value: "",
  },
  {
    label: "เงินลดทุนฯ",
    value: "",
  },
  {
    label: "เงินเพิ่มทุนฯ",
    value: "",
  },
  {
    label:
      "ผลประโยชน์ที่ได้จากการที่กิจการควบเข้ากัน,รับช่วงกัน,เลิกกัน,โอนหุ้น",
    value: "",
  },
  {
    label:
      "ผลต่างระหว่างราคาไถ่ถอนกับราคาซื้อตั๋วเงิน หรือตราสารแสดงสิทธิในหนี้ฯ",
    value: "",
  },
  {
    label: "เงินปันผลจากบริษัทต่างประเทศ",
    value: "",
  },
  {
    label:
      "เงินส่วนแบ่งของกำไรหรือผลประโยชน์อื่นใดในลักษณะเดียวกันที่ได้จากการถือหรือครอบครองโทเคนดิจิทัล",
    value: "",
  },
  {
    label: "ผลประโยชน์ที่ได้รับจากการโอนคริปโทเคอร์เรนซีหรือโทเคนติจิทัล",
    value: "",
  },
];

export const option_income_invest_tax_40_4_asset = [
  {
    label: "หุ้น",
    value: "",
  },
  {
    label: "กองทุน",
    value: "",
  },
];

export const option_income_invest_tax_40_4_asset_tax = [
  {
    label: "ร้อยละ 35",
    value: 35,
  },
  {
    label: "ร้อยละ 30",
    value: 30,
  },
  {
    label: "ร้อยละ 25",
    value: 25,
  },
  {
    label: "ร้อยละ 20",
    value: 20,
  },
  {
    label: "ร้อยละ 15",
    value: 15,
  },
  {
    label: "ร้อยละ 12.5",
    value: 12.5,
  },
  {
    label: "ร้อยละ 11.5",
    value: 11.5,
  },
  {
    label: "ร้อยละ 10",
    value: 10,
  },
  {
    label: "ร้อยละ 3",
    value: 3,
  },
  {
    label: "ร้อยละ 1.5",
    value: 1.5,
  },
  {
    label: "ไม่ได้รับเครดิตภาษี",
    value: "ไม่ได้รับเครดิตภาษี",
  },
  {
    label: "ยกเว้นภาษี",
    value: "ยกเว้นภาษี",
  },
];
