import { cn } from "../../../utils/useFunctions";
import PdfFooter from "../component/pdf.c.footer";
import PdfHeader from "../component/pdf.c.header";
import PdfLine from "../component/pdf.c.line";
import { STYLE_PAGE, STYLE_PAGE_LANDING } from "../styles/pdf.style";

function PdfPageFinacialLanding({page}) {
  return (
    <div className={cn(STYLE_PAGE)}>
      <PdfHeader />
      <PdfLine/>
      <img className="bg-cover" src={"/images/finalcial_landing.svg"} />
      <PdfFooter page={page}/>
    </div>
  );
}

export default PdfPageFinacialLanding;
