import { useEffect, useMemo, useState } from "react";
import { useBalanceStore } from "../../../_store/balanceStore";
import {
  DeleteButtonCard,
  MTButton
} from "../../../components/button/MTButton";
import {
  STYLE_MAX_W_DRAWER,
  STYLE_PADDING_BALANCE_DRAWER
} from "../../../utils/useStyle";
import {
  calPeryear,
  options_other_income,
  options_other_income_interest,
} from "../functions";

import { Drawer } from "@material-tailwind/react";
import DropDownMain from "../../../components/dropdown/DropDownMain";

import { HeaderDrawer } from "../../../components/header/Header";
import { PopupMain } from "../../../components/popup/PopupMain";
import { CardAccordian, CardIncome } from "../styles/Card";

export const DrawerBalanceIncome = ({
  open,
  onclose,
  formBL,
  setFormBL,
  setOpenDrawer,
  openAccordion,
  setOpenAccordion,
  addIncome,
  setAddIncome,
  addInterest,
  setAddInterest,
  addOtherIncome,
  setAddOtherIncome,
}) => {
  const setBalanceData = useBalanceStore((state) => state.setBalanceData);
  const balanceData = useBalanceStore((state) => state.balanceData);

  const [openAlert, setOpenAlert] = useState(false);
  const [openAlert2, setOpenAlert2] = useState(false);

  const [indexDeleteOne, setIndexDeleteOne] = useState("");

  const [loading, setLoading] = useState(false);

  const handleOpen = (value) => setOpenAccordion(open === value ? 0 : value);

  const calSalaryPerYear = useMemo(
    () => calPeryear(formBL?.salary_permonth),
    [formBL?.salary_permonth]
  );

  //------------------------------------------------ เงินเดือน ------------------------------------------------//
  const onIncomeChange = (index, e) => {
    const value = e.target.value;
    let output = parseFloat(value?.replace(/,/g, ""))
      ? parseFloat(value?.replace(/,/g, ""))
      : null;

    if (isNaN(output)) return;

    setAddIncome((prev) => {
      const result = [...prev];
      result[index].salary_permonth = output;
      result[index].amount = calPeryear(output);
      return result;
    });
  };

  const onChangeTitleIncome = (index, e) => {
    setAddIncome((prev) => {
      const result = [...prev];
      result[index].title = e.target.value;
      return result;
    });
  };

  const onClickDeleteIncomeOne = (index, e) => {
    setAddIncome((prev) => {
      const result = [...prev];
      result.splice(index, 1);
      return result;
    });
    setIndexDeleteOne("");
    setOpenAlert2(false);
  };

  const onClickDeleteIncomeAll = () => {
    const result = [...addIncome];
    result.splice(0, addIncome.length);
    setAddIncome(result);
    setIndexDeleteOne("");
    setOpenAlert(false);
  };

  //------------------------------------------------ ดอกเบี้ย ------------------------------------------------//
  const onChangeSelectedInterest = (index, e) => {
    setAddInterest((prev) => {
      let value = e.value;
      const result = [...prev];
      const findOption = options_other_income_interest?.filter((item) => {
        if (item.value === value) return item;
      });
      result[index].option_interest = value;
      result[index].option_interest_title = findOption?.[0]?.label;
      return result;
    });
  };

  const onChangeTitleInterest = (index, e) => {
    setAddInterest((prev) => {
      const result = [...prev];
      result[index].title = e.target.value;
      return result;
    });
  };

  const onTaxInterestChange = (index, e) => {
    const value = e.target.value;
    let output = parseFloat(value?.replace(/,/g, ""))
      ? parseFloat(value?.replace(/,/g, ""))
      : null;

    if (isNaN(output)) return;

    setAddInterest((prev) => {
      const result = [...prev];
      result[index].tax_interest = output;
      return result;
    });
  };

  const onInterestYearChange = (index, e) => {
    const value = e.target.value;
    let output = parseFloat(value?.replace(/,/g, ""))
      ? parseFloat(value?.replace(/,/g, ""))
      : null;

    if (isNaN(output)) return;

    setAddInterest((prev) => {
      const result = [...prev];
      result[index].interest_peryear = output;
      return result;
    });
  };

  const onClickDeleteTaxInterestOne = (index, e) => {
    setAddInterest((prev) => {
      const result = [...prev];
      result.splice(index, 1);
      return result;
    });
    setIndexDeleteOne("");
    setOpenAlert2(false);
  };

  const onClickDeleteInterestAll = () => {
    const result = [...addInterest];
    result.splice(0, addInterest.length);
    setAddInterest(result);
    setIndexDeleteOne("");
    setOpenAlert(false);
  };

  //-------------------------------------------------- อื่น ๆ --------------------------------------------------//
  const onChangeSelectedIncomeOther = (index, e) => {
    setAddOtherIncome((prev) => {
      let value = e.value;
      const findOption = options_other_income?.filter((item) => {
        if (item.value === value) return item;
      });
      const result = [...prev];
      result[index].option_income_other = value;
      result[index].option_income_other_title = findOption?.[0]?.label;
      return result;
    });
  };

  const onChangeTitleOther = (index, e) => {
    setAddOtherIncome((prev) => {
      const result = [...prev];
      result[index].title = e.target.value;
      return result;
    });
  };

  const onTaxOtherChange = (index, e) => {
    const value = e.target.value;
    let output = parseFloat(value?.replace(/,/g, ""))
      ? parseFloat(value?.replace(/,/g, ""))
      : null;
    if (isNaN(output)) return;

    setAddOtherIncome((prev) => {
      const result = [...prev];
      result[index].tax_income_other = output;
      return result;
    });
  };

  const onOtherChange = (index, e) => {
    const value = e.target.value;
    let output = parseFloat(value?.replace(/,/g, ""))
      ? parseFloat(value?.replace(/,/g, ""))
      : null;
    if (isNaN(output)) return;

    setAddOtherIncome((prev) => {
      const result = [...prev];
      result[index].other_income_peryear = output;
      return result;
    });
  };

  const onClickDeleteOtherOne = (index, e) => {
    setAddOtherIncome((prev) => {
      const result = [...prev];
      result.splice(index, 1);
      return result;
    });
    setIndexDeleteOne("");
    setOpenAlert2(false);
  };

  const onClickDeleteOtherAll = () => {
    const result = [...addOtherIncome];
    result.splice(0, addOtherIncome.length);
    setAddOtherIncome(result);
    setIndexDeleteOne("");
    setOpenAlert(false);
  };

  //---------------------------------------------------------------------------------------------------------//

  // รวมเงินเดือน
  const sumIncomePeryear = () => {
    let result = 0;
    addIncome?.map((row) => {
      result += Number(row?.amount);
    });
    return Number(result);
  };

  // รวมดอกเบี้ย
  const sumInterestPeryear = () => {
    let result = 0;
    addInterest?.map((row) => {
      result += Number(row?.interest_peryear);
    });
    return Number(result);
  };

  // รวมรายได้อื่น ๆ
  const sumOtherIncome = () => {
    let result = 0;
    addOtherIncome?.map((row) => {
      result += Number(row?.other_income_peryear);
    });
    return Number(result);
  };

  //-------------------- Submit --------------------//
  const onClickSubmit = () => {
    let new_net_temp_cash_flow = 0;
    let old_temp_cash_flow = formBL?.temp_net_cash_flow || 0;
    let new_expenses_propotion = 0;
    if (formBL?.salary_propotion) {
      new_expenses_propotion =
        +formBL?.sum_expenses_invest_saving -
        +formBL?.temp_net_cash_flow +
        +formBL?.sum_expenses_fixed +
        +formBL?.sum_expenses_variable;
      let new_net_cash_flow = formBL?.expenses_propotion
        ? formBL?.salary_propotion - new_expenses_propotion
        : formBL?.salary_propotion;
      if (new_net_cash_flow < 0) {
        new_net_cash_flow = 0;
      }
      new_expenses_propotion += new_net_cash_flow;
      new_net_temp_cash_flow = new_net_cash_flow;
    }

    const old_sum_expenses_invest_saving =
      formBL?.sum_expenses_invest_saving - old_temp_cash_flow;
    const new_sum_expenses_invest_saving =
      old_sum_expenses_invest_saving + new_net_temp_cash_flow;

    setBalanceData({
      ...formBL,
      temp_net_cash_flow: new_net_temp_cash_flow,
      expenses_propotion: Number(new_expenses_propotion),
      sum_expenses_invest_saving: Number(new_sum_expenses_invest_saving),
    });
    onclose();
  };

  useEffect(() => {
    setFormBL({
      ...formBL,
      salary_peryear_list: addIncome,
      sum_salary_peryear: sumIncomePeryear(),
      interest_peryear_list: addInterest,
      sum_interest_peryear: sumInterestPeryear(),
      other_income_list: addOtherIncome,
      sum_other_income: sumOtherIncome(),
      salary_propotion:
        sumIncomePeryear() + sumInterestPeryear() + sumOtherIncome(),
    });
  }, [sumIncomePeryear(), sumInterestPeryear(), sumOtherIncome()]);

  // console.log("formBL?.sum_other_income",formBL?.sum_other_income)
  // console.log("options_other_income:",options_other_income)

  return (
    <Drawer
      placement="right"
      open={open}
      onClose={onclose}
      className=" overflow-y-scroll  !max-w-[100%]"
    >
      <div
        style={{
          backgroundImage: `url(/images/circle.png)`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: " left  ",
        }}
        className={`min-h-screen `}
      >
        <div className={STYLE_MAX_W_DRAWER}>
          <HeaderDrawer title={`สัดส่วนรายได้`} onClickGoBack={onclose} />
          <div className={STYLE_PADDING_BALANCE_DRAWER}>
            {/*---------- เงินเดือน ----------*/}
            <CardAccordian
              add
              data={formBL?.salary_peryear}
              open={openAccordion === 1}
              onClickOpen={() => {
                handleOpen(1);
                if (openAccordion === 1) setOpenAccordion(false);
              }}
              title="เงินเดือน"
              textColor="text-green400"
              icon="/images/finance_income2.png"
              value={formBL?.sum_salary_peryear}
              onCLickMinus={() => {
                setOpenAccordion(false);
              }}
              onClickAccordion={() => {
                const date = new Date();
                const created = date.toISOString();
                const result = [
                  {
                    created: created,
                    title: "",
                    salary_permonth: "",
                    amount: 0,
                  },
                ];
                setAddIncome((prev) => {
                  return [...prev, ...result];
                });
                handleOpen(1);
              }}
              panel={
                <div>
                  {addIncome?.length > 0 ? (
                    <div className="bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9  mt-2.5">
                      <div className=" text-sm text-black font-medium">
                        จำนวนบริษัท (แห่ง)
                      </div>
                      <div className=" text-sm text-black font-medium">
                        {addIncome?.length}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {addIncome
                    ?.sort((a, b) => new Date(b.created) - new Date(a.created))
                    ?.map((row, index) => {
                      return (
                        <div key={index} className=" mt-2.5">
                          <CardIncome
                            head={"ชื่อบริษัท/องค์กร"}
                            title_1={"เดือนละ"}
                            valueTitle={row?.title}
                            value_1={row?.salary_permonth}
                            total={row?.amount}
                            onChange={onChangeTitleIncome.bind(this, index)}
                            onChangeValue1={onIncomeChange.bind(this, index)}
                            onClickDelete={() => {
                              setIndexDeleteOne(index);
                              setOpenAlert2("income");
                            }}
                          />
                        </div>
                      );
                    })}

                  {addIncome?.length === 0 ? (
                    ""
                  ) : (
                    <DeleteButtonCard
                      onClick={() => {
                        setOpenAlert("income");
                      }}
                    />
                  )}
                </div>
              }
            />

            {/*---------- ดอกเบี้ย ----------*/}
            <CardAccordian
              add
              open={openAccordion === 2}
              data={formBL?.interest_peryear}
              title="ดอกเบี้ย"
              textColor="text-green300"
              icon="/images/finance_interest2.png"
              value={formBL.sum_interest_peryear}
              onClickOpen={() => {
                handleOpen(2);
                if (openAccordion === 2) setOpenAccordion(false);
              }}
              onCLickMinus={() => {
                setOpenAccordion(false);
              }}
              onClickAccordion={() => {
                const date = new Date();
                const created = date.toISOString();
                const result = [
                  {
                    created: created,
                    option_interest: "",
                    option_interest_title: "",
                    title: "",
                    tax_interest: "",
                    interest_peryear: "",
                  },
                ];
                setAddInterest((prev) => {
                  return [...prev, ...result];
                });
                handleOpen(2);
              }}
              panel={
                <div>
                  {addInterest?.length ? (
                    <div className=" bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9 mt-2.5">
                      <div className=" text-sm text-black font-medium">
                        ที่มาของดอกเบี้ย (แหล่ง)
                      </div>
                      <div className=" text-sm text-black font-medium">
                        {addInterest?.length}
                      </div>
                      {/* <ButtonCounter
                      value={row.value}
                      // handleNext={handleNext.bind(this, index)}
                      onChange={onIncomeChange.bind(this, index)}
                    /> */}
                    </div>
                  ) : (
                    ""
                  )}
                  {addInterest
                    ?.sort((a, b) => new Date(b.created) - new Date(a.created))
                    ?.map((row, index) => {
                      return (
                        <div key={index} className=" mt-2.5">
                          <DropDownMain
                            value={options_other_income_interest?.filter(
                              (option) => {
                                if (option?.value === row?.option_interest)
                                  return option?.label;
                              }
                            )}
                            onChange={onChangeSelectedInterest?.bind(
                              this,
                              index
                            )}
                            options={options_other_income_interest}
                          />

                          <CardIncome
                            head={"สถาบันทางการเงิน"}
                            isShowYear={false}
                            valueTitle={row?.title}
                            title_1={"ภาษีหัก ณ ที่จ่าย"}
                            value_1={row?.tax_interest}
                            isHasvalue_2
                            title_2={"จำนวน (ต่อปี)"}
                            value_2={row?.interest_peryear}
                            total={row?.interest_peryear}
                            onChange={onChangeTitleInterest.bind(this, index)}
                            onChangeValue1={onTaxInterestChange.bind(
                              this,
                              index
                            )}
                            onChangeValue2={onInterestYearChange.bind(
                              this,
                              index
                            )}
                            onClickDelete={() => {
                              setIndexDeleteOne(index);
                              setOpenAlert2("interest");
                            }}
                          />
                        </div>
                      );
                    })}
                  {addInterest?.length === 0 ? (
                    ""
                  ) : (
                    <DeleteButtonCard
                      onClick={() => {
                        setOpenAlert("interest");
                      }}
                    />
                  )}
                </div>
              }
            />

            {/*---------- รายได้อื่น ๆ ----------*/}
            <CardAccordian
              open={openAccordion === 3}
              title="รายได้อื่น ๆ"
              textColor="text-green600"
              icon="images/finance_others2.png"
              iconStyle="w-[46px] h-fit"
              onClickOpen={() => {
                handleOpen(3);
                if (openAccordion === 3) setOpenAccordion(false);
              }}
              value={formBL?.sum_other_income}
              add
              onClickAccordion={() => {
                const date = new Date();
                const created = date.toISOString();
                const result = [
                  {
                    created: created,
                    title: "",
                    option_income_other: "",
                    option_income_other_title: "",
                    tax_income_other: "",
                    other_income_peryear: "",
                  },
                ];
                setAddOtherIncome((prev) => {
                  return [...prev, ...result];
                });
                handleOpen(3);
              }}
              panel={
                <div>
                  {addOtherIncome?.length > 0 ? (
                    <div className=" bg-white rounded-full px-4 py-[6px] my-2.5 flex justify-between items-center h-9 mt-2.5">
                      <div className=" text-sm text-black font-medium">
                        จำนวนที่มาของรายได้อื่น ๆ
                      </div>
                      <div className=" text-sm text-black font-medium">
                        {addOtherIncome?.length}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {addOtherIncome
                    ?.sort((a, b) => new Date(b.created) - new Date(a.created))
                    ?.map((row, index) => {
                      return (
                        <div key={index}>
                          <div className=" pt-3 ">
                            <DropDownMain
                              value={options_other_income?.filter((option) => {
                                if (option?.value === row?.option_income_other)
                                  return option?.label;
                              })}
                              onChange={onChangeSelectedIncomeOther?.bind(
                                this,
                                index
                              )}
                              options={options_other_income}
                            />
                          </div>

                          <CardIncome
                            head={"แหล่งที่มา"}
                            placeholder="ระบุชื่อแหล่งที่มา"
                            isShowYear={false}
                            valueTitle={row?.title}
                            title_1={"ภาษีหัก ณ ที่จ่าย"}
                            value_1={row?.tax_income_other}
                            isHasvalue_2
                            title_2={"จำนวน (ต่อปี)"}
                            value_2={row?.other_income_peryear}
                            total={row?.other_income_peryear}
                            onChange={onChangeTitleOther.bind(this, index)}
                            onChangeValue1={onTaxOtherChange.bind(this, index)}
                            onChangeValue2={onOtherChange.bind(this, index)}
                            onClickDelete={() => {
                              setIndexDeleteOne(index);
                              setOpenAlert2("other");
                            }}
                          />
                        </div>
                      );
                    })}
                  {addOtherIncome?.length === 0 ? (
                    ""
                  ) : (
                    <DeleteButtonCard
                      onClick={() => {
                        setOpenAlert("other");
                      }}
                    />
                  )}
                </div>
              }
            />

            <div className="flex justify-center mb-6">
              <MTButton
                text="บันทึก"
                loading={loading}
                onClick={onClickSubmit}
              />
            </div>
          </div>
        </div>

        {/*---------- แจ้งเตือนลบ 1 รายการ ----------*/}
        <PopupMain
          title={"ยืนยันการลบข้อมูลเงินเดือน"}
          text={
            <div className=" text-base">
              คุณต้องการลบข้อมูลรายการนี้ใช่หรือไม่
            </div>
          }
          button2={true}
          textButton={"ยืนยัน"}
          textButton2={"ยกเลิก"}
          open={openAlert2 === "income"}
          onClick={onClickDeleteIncomeOne?.bind(this, indexDeleteOne)}
          onClickSecond={() => setOpenAlert2(false)}
        />

        <PopupMain
          title={"ยืนยันการลบข้อมูลดอกเบี้ย"}
          text={
            <div className=" text-base">
              คุณต้องการลบข้อมูลรายการนี้ใช่หรือไม่
            </div>
          }
          button2={true}
          textButton={"ยืนยัน"}
          textButton2={"ยกเลิก"}
          open={openAlert2 === "interest"}
          onClick={onClickDeleteTaxInterestOne?.bind(this, indexDeleteOne)}
          onClickSecond={() => setOpenAlert2(false)}
        />

        <PopupMain
          title={"ยืนยันการลบข้อมูลรายได้อื่น ๆ"}
          text={
            <div className=" text-base">
              คุณต้องการลบข้อมูลรายการนี้ใช่หรือไม่
            </div>
          }
          button2={true}
          textButton={"ยืนยัน"}
          textButton2={"ยกเลิก"}
          open={openAlert2 === "other"}
          onClick={onClickDeleteOtherOne?.bind(this, indexDeleteOne)}
          onClickSecond={() => setOpenAlert2(false)}
        />

        {/*---------- แจ้งเตือนลบทั้งหมด ----------*/}
        <PopupMain
          title={"ยืนยันการลบข้อมูลเงินเดือน"}
          text={
            <div className=" text-base">
              คุณต้องการลบข้อมูลทั้งหมดนี้ใช่หรือไม่
            </div>
          }
          button2={true}
          textButton={"ยืนยัน"}
          textButton2={"ยกเลิก"}
          open={openAlert === "income"}
          onClick={onClickDeleteIncomeAll}
          onClickSecond={() => setOpenAlert(false)}
        />

        <PopupMain
          title={"ยืนยันการลบข้อมูลดอกเบี้ย"}
          text={
            <div className=" text-base">
              คุณต้องการลบข้อมูลทั้งหมดนี้ใช่หรือไม่
            </div>
          }
          button2={true}
          textButton={"ยืนยัน"}
          textButton2={"ยกเลิก"}
          open={openAlert === "interest"}
          onClick={onClickDeleteInterestAll}
          onClickSecond={() => setOpenAlert(false)}
        />

        <PopupMain
          title={"ยืนยันการลบข้อมูลรายได้อื่น ๆ"}
          text={
            <div className=" text-base">
              คุณต้องการลบข้อมูลทั้งหมดนี้ใช่หรือไม่
            </div>
          }
          button2={true}
          textButton={"ยืนยัน"}
          textButton2={"ยกเลิก"}
          open={openAlert === "other"}
          onClick={onClickDeleteOtherAll}
          onClickSecond={() => setOpenAlert(false)}
        />
      </div>
    </Drawer>
  );
};
