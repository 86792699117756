import { useNavigate } from "react-router-dom";
import Container from "../../../components/layout/Container";
import { useBalanceStore } from "../../../_store/balanceStore";
import { DonutChartDebt } from "../../../components/chart/DonutChartMain";
import numeral from "numeral";
import { MTButton } from "../../../components/button/MTButton";
import {
  HeaderTableReport,
  HeaderTitleTableReport,
  TableReport,
} from "../components/table.main";
import { HeaderReport } from "../components/header.main";
import { STYLE_REPORT_CONTAIN } from "../../../utils/useStyle";
import { HeaderTitle } from "../../../components/header/Header";
import { CardReportAverage } from "../components/cardMenu";
import { useEffect, useMemo } from "react";
import { first_reload } from "../../tax_plan/functions/firstLoad";
import { cn } from "../../../utils/useFunctions";

const ChartSection = ({ balanceData, items }) => {
  return (
    <div className=" flex flex-col items-center">
      <DonutChartDebt
        classNameGray={" bg-white h-11"}
        className="-mt-14"
        classNameText={
          " text-sm  absolute top-[55%] left-1/2 -translate-x-1/2 -translate-y-1/4 text-black font-IBMPlexSansThai text-center"
        }
        classNameValue={" text-base"}
        classNameDonut=" w-[220px] pb-3 "
        text={`หนี้สิน`}
        value={numeral(balanceData?.debt_propotion).format("0,0")}
        // defalut={getDebtBalance > 0 ? 0 : 100}
        data1={balanceData?.sum_debt_short}
        data2={balanceData?.sum_debt_long}
      />

      <div className=" flex flex-col justify-center ">
        {items.map((item, index) => {
          return (
            <div key={index} className=" flex items-center gap-2.5 font-medium">
              <div className={` ${item?.color} h-2 w-5`}></div>
              <div className="flex items-center text-xs gap-1">
                <div>
                  {item?.title}{" "}
                  <span className=" font-bold">
                    {numeral(item?.sum_per_year)?.format("0,0")}
                  </span>{" "}
                  บาท คิดเป็น{" "}
                </div>
                <div className={` ${item?.textcolor} font-bold text-sm `}>
                  {item?.sum_percent?.toFixed(2)} %
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const TableDebt = ({ items }) => {
  return (
    <div className="bg-gray50 rounded-[20px] w-full h-ful p-3">
      <HeaderTableReport
        title="แหล่งที่มาของหนี้สิน"
        textjusify="text-center"
      />
      {items.map((item, index) => {
        return (
          <div key={index} className={cn("  items-center")}>
            <div className=" col-span-full">
              <HeaderTitleTableReport
                img={`/images/${item?.icon}`}
                title={item?.title}
              />
            </div>

            <div
              className={`bg-[#F9F9F9] py-1 rounded-[10px] grid grid-cols-8 px-2 items-center font-semibold text-mainblue text-sm`}
            >
              <div className=" col-span-3 ">
                {/* <HeaderTitleTableReport
              img={`/images/${item?.icon}`}
              title={item?.title}
            /> */}
              </div>
              <div className=" col-span-2 text-right">
                {numeral(item.sum_per_month).format("0,0")}
              </div>
              <div className="col-span-3 text-right ">
                {numeral(item.sum_per_year).format("0,0")} บาท
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const ExplanSection = ({ balanceData }) => {
  return (
    <div className=" text-center flex flex-col gap-[6px]">
      <div className=" text-base font-medium ">
        หลังจากแจกแจงหนี้สินแล้วเราจะได้ภาพรวม ดังนี้
      </div>
      <div className="text-gray90 text-xs">
        (หนี้สินระยะสั้น + หนี้สินระยะยาว = ภาระหนี้สินรวมต่อเดือน)
      </div>
      <div className=" text-base font-medium text-maincyan">
        ภาระหนี้สินรวมต่อเดือน{" "}
        <span className=" text-mainblue">
          {numeral(balanceData?.debt_propotion)?.format("0,0")}
        </span>
      </div>
    </div>
  );
};

const ReportDebtPage = () => {
  const navigate = useNavigate();
  const balanceData = useBalanceStore((state) => state.balanceData);

  const createSumObject = (sum_per_month, sum_per_year, sum_percent) => ({
    sum_per_month: sum_per_month || 0,
    sum_per_year: sum_per_year || 0,
    sum_percent: sum_percent || 0,
  });

  // หายอดรวมค่าใช้จ่ายหนี้สินรายเดือนทั้งหมด
  const sumExpensesDebtPermonth = () => {
    let result = 0;
    if (balanceData?.sum_debt_short_permonth) {
      result += balanceData?.sum_debt_short_permonth;
    }
    if (balanceData?.sum_debt_long_permonth) {
      result += balanceData?.sum_debt_long_permonth;
    }
    return result;
  };


  const sumLong = useMemo(() => {
    return createSumObject(
      balanceData?.sum_debt_long_permonth,
      balanceData?.sum_debt_long,
      (balanceData?.sum_debt_long / balanceData?.debt_propotion) * 100
    );
  }, [balanceData]);

  const sumShort = useMemo(() => {
    return createSumObject(
      balanceData?.sum_debt_short_permonth,
      balanceData?.sum_debt_short,
      (balanceData?.sum_debt_short / balanceData?.debt_propotion) * 100
    );
  }, [balanceData]);

  const sumTotal = useMemo(
    () => sumLong.sum_per_year + sumShort.sum_per_year,
    [sumLong, sumShort]
  );

  const items = useMemo(() => {
    return [
      {
        title: "หนี้สินระยะสั้น",
        icon: "credit_card.svg",
        ...sumShort,
        color: "bg-red500",
        textcolor: "text-red-500",
      },
      {
        title: "หนี้สินระยะยาว",
        icon: "home_loan.svg",
        ...sumLong,
        color: "bg-mainorange",
        textcolor: "text-mainorange",
      },
    ];
  }, [sumTotal]);

  useEffect(() => {
    let ignore = false;
    first_reload(ignore);
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <Container>
      <div id="first_reload" className="pt-[14px]">
        <HeaderTitle
          title="รายงาน"
          isGoback
          onClickGoBack={() => {
            navigate("/report");
          }}
        />

        <div className={`${STYLE_REPORT_CONTAIN} flex flex-col gap-5`}>
          <HeaderReport
            icon={"/images/debt-report.png"}
            title={"สัดส่วนหนี้สินของคุณ"}
            className={" z-20"}
          />
          <ChartSection balanceData={balanceData} items={items} />
          <CardReportAverage
            title="คุณมีภาระหนี้สินเฉลี่ย"
            // value={numeral(balanceData?.debt_propotion / 12).format("0,0")}
            value={numeral(sumExpensesDebtPermonth()).format("0,0")}
          />
          <TableDebt items={items} />
          <ExplanSection balanceData={balanceData} />

          <div className=" flex justify-center ">
            <MTButton
              onClick={() => {
                navigate("/report");
              }}
              text="ย้อนกลับ"
              color="bg-white"
              border="border border-maincyan"
              colortext="text-maincyan group-hover:text-white"
              hovercolor="hover:bg-maincyan group "
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ReportDebtPage;
