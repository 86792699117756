import { cn } from "./useFunctions";
import { STYLE_INPUT_CARD_BALANCE } from "./useStyle";

export const isEmpty = (isSubmited = false, value) => {
  if (isSubmited) {
    if (value === undefined || value === null || value === "" || value === 0)
      return true;
  }
  return false;
};

export const styleEmpyCard = (
  isEmpty = false,
  shouldValidate = false,
  style_true,
  style = `${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`
) => {
  if (isEmpty && shouldValidate) {
    return cn(style_true, {
      "focus:outline-none focus:ring-1 focus:ring-red-500 border-red-500 border-2 ": true,
    });
  }
  return cn(style, style_true);
};
