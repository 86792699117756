/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

// const oauth = {
//   // domain: "medconnect.auth.ap-southeast-1.amazoncognito.com",
//   scope: [
//     "phone",
//     "email",
//     "profile",
//     "openid",
//     "aws.cognito.signin.user.admin",
//   ],
//   redirectSignIn: "http://localhost:3000/login",
//   // NODE_ENV === "development"
//   //   ? "http://localhost:3000/login"
//   //   : "https://www.assurewealth.website/login",
//   redirectSignOut: "http://localhost:3000/login",
//   // NODE_ENV === "development"
//   //   ? "http://localhost:3000/login"
//   //   : "https://www.assurewealth.website/login",
//   responseType: "code",
// };

const awsmobile = {
  aws_project_region: "ap-southeast-1",
  aws_cloud_logic_custom: [
    {
      name: "apiaca03172",
      endpoint:
        "https://c2v1kz8v4f.execute-api.ap-southeast-1.amazonaws.com/dev",
      region: "ap-southeast-1",
    },
  ],
  aws_cognito_identity_pool_id:
    "ap-southeast-1:e54afe63-a8cf-46d5-afbb-3be6bbaf8915",
  aws_cognito_region: "ap-southeast-1",
  aws_user_pools_id: "ap-southeast-1_yT1TBZonH",
  aws_user_pools_web_client_id: "2gpp6tqi312mvm06qf63ptueoj",
  oauth: {},
  aws_cognito_username_attributes: [],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: [],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: ["SMS"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
  aws_user_files_s3_bucket:
    "dashboardassurewealt84d40719b29f4b4ab4a173b8828160848-dev",
  aws_user_files_s3_bucket_region: "ap-southeast-1",
};

export default awsmobile;
