import { Doughnut } from "react-chartjs-2";
import {
  STYLE_TEXT_CENTER_DONUT_BALANCE,
  STYLE_TITLE_DONUTCHART,
  STYLE_VALUE_CENTER_DONUT_BALANCE,
  STYLE_WIDTH_DONUT_BALANCE,
} from "../../utils/useStyle";
import { cn } from "../../utils/useFunctions";
import numeral from "numeral";
import _ from "lodash";

export const DonutChartEmpty = ({ text }) => {
  return (
    <div className={`relative mt-4 `}>
      <div className={`w-[250px] mx-auto pb-6 relative `}>
        <div className="bg-[#D3D3D3] w-full rounded-full h-[250px] z-10">
          <div className=" bg-gray50  rounded-full h-[180px] w-[180px] z-10 top-[13%] left-[14%] absolute "></div>
        </div>
        <div
          className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-xl text-black font-IBMPlexSansThai text-center z-30`}
        >
          {text}
          <div className={STYLE_VALUE_CENTER_DONUT_BALANCE}>{0}</div>
        </div>
      </div>
    </div>
  );
};

export const DonutChartMain = ({
  text,
  value,
  defalut,
  data1,
  data2,
  data3,
  data4,
  left,
  isRemoveGray = false,
  isRemoveLegend = false,
  classNameGray,
  className,
  width,
  height,
  classNameDonut,
  classNameText,
  classNameValue,
}) => {
  const data = {
    labels: [
      "ค่าใช้จ่ายคงที่",
      "ค่าใช้จ่ายผันแปร",
      "ค่าใช้จ่ายเพื่อการออม/ลงทุน",
    ],
    datasets: [
      {
        data: [data1, data2, data3],
        backgroundColor: [
          "rgba(244, 179, 49, 1)",
          "rgba(255, 67, 74, 1)",
          "rgba(22, 170, 55, 1)",
        ],
        hoverOffset: 4,
        cutout: "70%",
        borderRadius: 0,
        borderWidth: 0,
      },
    ],
  };

  const config = {
    type: "pie",
    data,
  };
  const options = {
    plugins: {
      tooltip: {
        position: "average",
      },
      legend: {
        display: isRemoveLegend ? false : true,
      },
    },
  };

  return (
    <div className={cn(` relative `, className)}>
      {!isRemoveGray && (
        <div
          className={cn(
            " bg-gray50 w-full h-11 z-10 absolute top-0 ",
            classNameGray
          )}
        ></div>
      )}
      <div className={cn(STYLE_TEXT_CENTER_DONUT_BALANCE, classNameText)}>
        {text}
        <div className={cn(STYLE_VALUE_CENTER_DONUT_BALANCE, classNameValue)}>
          {value}
        </div>
      </div>
      <div
        className={cn(`${STYLE_WIDTH_DONUT_BALANCE}  relative`, classNameDonut)}
      >
        <Doughnut config={config} data={data} options={options} />
      </div>
    </div>
  );
};

export const DonutChartProperty = ({
  text,
  value,
  defalut,
  data1,
  data2,
  data3,
  data4,
  left,
  isRemoveGray = false,
  isRemoveLegend = false,
  classNameGray,
  className,
  width,
  height,
  classNameDonut,
  classNameText,
  classNameValue,
}) => {
 
  const data = {
    labels: [
      "สินทรัพย์สภาพคล่อง",
      "สินทรัพย์เพื่อการลงทุน",
      "สินทรัพย์ใช้ส่วนตัว",
      "สินทรัพย์ไม่มีตัวตน",
    ],
    // labels: false,
    datasets: [
      {
        data: [data1, data2, data3, data4],
        // backgroundColor: [
        //   "rgba(85, 7, 147, 1)",
        //   "rgba(203, 164, 255, 1)",
        //   "rgba(183, 128, 255, 1)",
        //   "rgba(141, 50, 232, 1)",
        // ],
        backgroundColor: [
          "#550793",
          "#7f1eff",
          "#8c37fc",
          "#8d32e8",
        ],
        hoverOffset: 4,
        cutout: "70%",
        borderRadius: 0,
        borderWidth: 0,
      },
    ],
  };

  const config = {
    type: "pie",
    data,
  };
  const options = {
    plugins: {
      tooltip: {
        position: "average",
      },
      legend: {
        display: isRemoveLegend ? false : true,
      },
    },
  };

  return (
    <div className={cn("relative", className)}>
      {!isRemoveGray && (
        <div
          className={cn(
            " bg-gray50 w-full h-[66px] z-10 absolute top-0 ",
            classNameGray
          )}
        ></div>
      )}

      <div
        className={cn(
          `absolute top-[60%] left-1/2 -translate-x-1/2 -translate-y-1/2 text-xl text-black font-IBMPlexSansThai text-center`,
          classNameText
        )}
      >
        {text}
        <div className={cn(STYLE_VALUE_CENTER_DONUT_BALANCE, classNameValue)}>
          {value}
        </div>
      </div>
      <div className={cn(` w-[310px] mx-auto pb-6  relative`, classNameDonut)}>
        <Doughnut
          width={width}
          height={height}
          config={config}
          data={data}
          options={options}
        />
      </div>
    </div>
  );
};

export const DonutChartIncome = ({
  text,
  value,
  defalut,
  data1,
  data2,
  data3,
  data4,
  isRemoveGray = false,
  isRemoveLegend = false,
  classNameGray,
  className,
  width,
  height,
  classNameDonut,
  classNameText,
  classNameValue,
}) => {
  const data = {
    labels: ["เงินเดือน ", " ดอกเบี้ย ", " รายได้อื่น ๆ"],
    datasets: [
      {
        data: [data1, data2, data3],
        backgroundColor: [
          "rgba(60, 164, 128, 1)",
          "rgba(107, 186, 124, 1)",
          "rgba(61, 122, 101, 1)",
        ],
        hoverOffset: 4,
        cutout: "70%",
        borderRadius: 0,
        borderWidth: 0,
      },
    ],
  };

  const config = {
    type: "pie",
    data,
    options: {
      plugins: {
        legend: {
          display: false,
        },
      },
    },
  };

  const options = {
    plugins: {
      legend: {
        display: isRemoveLegend ? false : true,
      },
    },
  };

  return (
    <div className={cn(`relative `, className)}>
      {!isRemoveGray && (
        <div
          className={cn(
            " bg-gray50 w-full h-11 z-10 absolute top-0 ",
            classNameGray
          )}
        ></div>
      )}
      <div className={cn(STYLE_TEXT_CENTER_DONUT_BALANCE, classNameText)}>
        {text}
        <div className={cn(STYLE_VALUE_CENTER_DONUT_BALANCE, classNameValue)}>
          {value}
        </div>
      </div>
      <div className={cn(`${STYLE_WIDTH_DONUT_BALANCE} `, classNameDonut)}>
        <Doughnut
          width={width}
          height={height}
          config={config}
          data={data}
          options={options}
        />
      </div>
    </div>
  );
};

export const DonutChartDebt = ({
  text,
  value,
  defalut,
  data1,
  data2,
  isRemoveGray = false,
  isRemoveLegend = false,
  classNameGray,
  className,
  width,
  height,
  classNameDonut,
  classNameText,
  classNameValue,
}) => {
  const data = {
    labels: ["หนี้สินระยะสั้น", "หนี้สินระยะยาว"],
    datasets: [
      {
        data: [data1, data2],
        backgroundColor: ["rgba(231, 30, 38, 1)", "rgba(255, 122, 0, 1)"],
        hoverOffset: 4,
        cutout: "70%",
        borderRadius: 0,
        borderWidth: 0,
      },
    ],
  };

  const config = {
    type: "pie",
    data,
  };

  const options = {
    plugins: {
      legend: {
        display: isRemoveLegend ? false : true,
      },
    },
  };

  return (
    <div className={`relative ${className} `}>
      {!isRemoveGray && (
        <div
          className={cn(
            " bg-gray50 w-full h-8 z-10 absolute top-0  ",
            classNameGray
          )}
        ></div>
      )}
      <div className={cn(STYLE_TEXT_CENTER_DONUT_BALANCE, classNameText)}>
        {text}
        <div className={cn(STYLE_VALUE_CENTER_DONUT_BALANCE, classNameValue)}>
          {value}
        </div>
      </div>
      <div className={cn(`w-[280px] mx-auto pb-6 relative`, classNameDonut)}>
        <Doughnut
          width={width}
          height={height}
          config={config}
          data={data}
          options={options}
        />
      </div>
    </div>
  );
};

export const RandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const DonutChartInvestMain = ({
  width,
  height,
  items = [],
  className = "",
  classNameText = "",
  sectionFrom = "total",
  classNameDetail,
  classNameImg,
  classNameRoot,
  isAmount = false,
}) => {
  const itemKey = {
    เงินฝาก: "#FFD200",
    ตราสารหนี้: "#B2C465",
    ตราสารทุน: "#EEA73C",
    ตราสารหนี้เอกชน: "#DFCE4C",
    สินทรัพย์ทางเลือก: "#EC723E",
  };

  const bgKey = {
    เงินฝาก: "bg-[#FFD200]",
    ตราสารหนี้: "bg-[#B2C465]",
    ตราสารทุน: "bg-[#EEA73C]",
    ตราสารหนี้เอกชน: "bg-[#DFCE4C]",
    สินทรัพย์ทางเลือก: "bg-[#EC723E]",
  };

  // Function to generate a unique random color
  const getUniqueRandomColor = (usedColors) => {
    let color;
    do {
      color = RandomColor();
    } while (usedColors.has(color));
    usedColors.add(color);
    return color;
  };

  const usedColors = new Set();

  const bgColorItems = items.map((item) => {
    if (!itemKey[item?.label]) {
      return getUniqueRandomColor(usedColors);
    }
    return itemKey[item?.label];
  });

  const bgColorItemsDetail = items.map((item) => {
    return item?.color;
  });

  // console.log(sectionFrom,"bgColorItemsDetail", bgColorItemsDetail);


  const data = {
    labels: false,
    datasets: [
      {
        data: items.map((item) => _.get(item, "value", "")),
        backgroundColor:
          sectionFrom === "total" ? bgColorItems : items.map((item) => item?.color),
        hoverOffset: 4,
        cutout: "0%",
        borderRadius: 0,
        borderWidth: 0,
      },
    ],
  };

  const config = {
    type: "pie",
    data,
  };

  // console.log("items", items);

  return (
    <div className={cn(classNameRoot)}>
      <div className={cn(`${STYLE_WIDTH_DONUT_BALANCE} pt-6`, className)}>
        <Doughnut
          className={classNameImg}
          height={height}
          width={width}
          config={config}
          data={data}
        />
      </div>
      <div className={cn(" space-y-[6px]", classNameDetail)}>
        {items
          .filter((item) => item !== undefined)
          .map((item, index) => {
            return (
              <div className=" flex items-center gap-2.5 ">
                <div
                  className={cn(`h-2 w-5`)}
                  style={{
                    backgroundColor:
                      // sectionFrom === "total"
                      //   ? bgColorItems[item?.label]
                      //   : bgColorItemsDetail[item?.label],
                      sectionFrom === "total"
                        ? itemKey[item?.label]
                        : item?.color,
                  }}
                ></div>
                <div className={cn(STYLE_TITLE_DONUTCHART, classNameText)}>{` ${
                  item?.label
                } : ${numeral(item?.value).format("0.00")}%`}</div>
                {isAmount && (
                  <div className={cn(STYLE_TITLE_DONUTCHART, classNameText)}>
                    ({`${numeral(item?.amount).format("0,0.00")} บาท`})
                  </div>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export const DonutChartInvestMain2 = ({
  text,
  value,
  defalut,
  data1,
  data2,
  data3,
  data4,
  titleGreen,
  titleLighrGreen,
  titleYellow,
  titleOrange,
  rotate = "rotate-45",
  isCustom = false,
  panel,
  classNameDetail,
}) => {
  
  const data = {
    labels: false,
    datasets: [
      {
        data: [data1, data2, data3, data4],
        backgroundColor: ["#B2C465", "#DFCE4C", "#EEA73C", "#FFD200"],
        hoverOffset: 4,
        cutout: "0%",
        borderRadius: 0,
        borderWidth: 0,
      },
    ],
  };

  const config = {
    type: "pie",
    data,
  };

  return (
    <div>
      <div className={`${STYLE_WIDTH_DONUT_BALANCE} pt-6`}>
        <div className={` ${rotate}`}>
          <Doughnut config={config} data={data} />
        </div>
      </div>
      {isCustom ? (
        <div>{panel}</div>
      ) : (
        <div className={cn(classNameDetail, " space-y-[6px]")}>
          <div className=" flex items-center gap-2.5 ">
            <div className=" bg-[#EEA73C] h-2 w-5"></div>
            <div
              className={STYLE_TITLE_DONUTCHART}
            >{` ตราสารทุน : ${titleOrange}`}</div>
          </div>
          <div className=" flex items-center gap-2.5 ">
            <div className=" bg-[#DFCE4C] h-2 w-5"></div>
            <div
              className={STYLE_TITLE_DONUTCHART}
            >{` ตราสารหนี้เอกชน : ${titleLighrGreen}`}</div>
          </div>
          <div className=" flex items-center gap-2.5 ">
            <div className=" bg-[#B2C465] h-2 w-5"></div>
            <div
              className={STYLE_TITLE_DONUTCHART}
            >{` ตราสารหนี้ : ${titleGreen} `}</div>
          </div>
          <div className=" flex items-center gap-2.5 ">
            <div className=" bg-[#FFD200] h-2 w-5"></div>
            <div
              className={STYLE_TITLE_DONUTCHART}
            >{` เงินฝาก/ตราหนี้ระยะสั้น : ${titleYellow}`}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export const DonutChartInvestMain3 = ({
  data1,
  data2,
  titleYellow,
  titleOrange,
  rotate = "-rotate-90",
  height = "h-[310px]",
  info = true,
}) => {
  const data = {
    labels: false,
    datasets: [
      {
        data: [data1, data2],
        backgroundColor: ["#EC723E", "#EEA73C"],
        hoverOffset: 4,
        cutout: "0%",
        borderRadius: 0,
        borderWidth: 0,
      },
    ],
  };

  const config = {
    type: "pie",
    data,
  };

  return (
    <div>
      <div className={`${STYLE_WIDTH_DONUT_BALANCE} pt-6`}>
        <div className={` ${rotate} ${height}`}>
          <Doughnut config={config} data={data} />
        </div>
      </div>
      {info && (
        <div className=" space-y-[6px]">
          <div className=" flex items-center gap-2.5 ">
            <div className=" bg-[#EC723E] h-2 w-5"></div>
            <div
              className={STYLE_TITLE_DONUTCHART}
            >{` สินทรัพย์ทางเลือก : ${titleOrange}`}</div>
          </div>
          <div className=" flex items-center gap-2.5 ">
            <div className=" bg-[#EEA73C] h-2 w-5"></div>
            <div
              className={STYLE_TITLE_DONUTCHART}
            >{` ตราสารทุน : ${titleYellow}`}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export const DonutChartInvestMain4 = ({
  data1,
  data2,
  titleYellow,
  titleLightGreen,
}) => {
  const data = {
    labels: false,
    datasets: [
      {
        data: [data1, data2],
        backgroundColor: ["#B2C465", "#FFD200"],
        hoverOffset: 4,
        cutout: "0%",
        borderRadius: 0,
        borderWidth: 0,
      },
    ],
  };

  const config = {
    type: "pie",
    data,
  };

  return (
    <div>
      <div className={`${STYLE_WIDTH_DONUT_BALANCE} pt-6`}>
        <Doughnut config={config} data={data} />
      </div>
      <div className=" space-y-[6px]">
        <div className=" flex items-center gap-2.5 ">
          <div className=" bg-[#B2C465] h-2 w-5"></div>
          <div className={STYLE_TITLE_DONUTCHART}>{titleLightGreen}</div>
        </div>
        <div className=" flex items-center gap-2.5 ">
          <div className=" bg-[#FFD200] h-2 w-5"></div>
          <div className={STYLE_TITLE_DONUTCHART}>{titleYellow}</div>
        </div>
      </div>
    </div>
  );
};
