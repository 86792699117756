import {
  cn,
  getPathURL,
  mapStatus,
  toFloat2,
} from "../../../utils/useFunctions";
import { PdfCard, PdfCardGray, PdfCardWhite } from "../component/pdf.c.card";
import PdfHeader from "../component/pdf.c.header";
import {
  PdfHeaderCardText,
  PdfHeaderText,
} from "../component/pdf.c.headerText";
import PdfLine from "../component/pdf.c.line";
import {
  STYLE_PAGE,
  STYLE_PDF_TEXT_MEDIUM_11,
  STYLE_PDF_TEXT_MEDIUM_12,
  STYLE_PDF_TEXT_MEDIUM_13,
  STYLE_PDF_TEXT_NORMAL_10,
  STYLE_PDF_TEXT_NORMAL_11,
} from "../styles/pdf.style";
import { useBalanceStore } from "../../../_store/balanceStore";
import numeral from "numeral";
import { useEffect, useMemo, useState } from "react";
import HalfChartMain from "../../../components/chart/HalfChartMain";
import {
  ICON_BAHT,
  ICON_CHECK,
  ICON_CLOSE,
  ICON_GRAPH,
  ICON_SAVING,
  ICON_WARNING,
} from "../../../utils/useIcons";
import { STYLE_CARD_BG } from "../../../utils/useStyle";
import PdfFooter from "../component/pdf.c.footer";
import LineChartMain from "../../../components/chart/LineChartMain";
import { ImageMain } from "../../../components/img/Img.Main";
import {
  DonutChartInvestMain,
  DonutChartInvestMain3,
} from "../../../components/chart/DonutChartMain";
import HorizonLineChartMain from "../../../components/chart/HorizonLineChartMain";
import { avgWeight } from "../../retirement_plan/functions/functionMain";
import {
  lastResultInvestmentAssetsAFSensitive,
  loopInvestmentAssetsAFSensitive,
} from "../../retirement_plan/functions/functionRetrementSensitivePlan";
import { calPMT } from "../../education/functions/function";
import {
  dataAgeAF,
  loopInvestmentAssets,
  loopInvestmentAssetsAF,
} from "../../retirement_plan/functions/functionRetriementPlan";
import { calculateTax, checkSum } from "../../tax_plan/functions";
import { useStrategyStore } from "../../../_store/strategyRetireStore";
import { useTaxStore } from "../../../_store/taxplanStore";
import { useRetireStore } from "../../../_store/retireStore";
import _ from "lodash";
import { key_property_invest_category } from "../../balance/functions";
import FamilyBarChart from "../component/pdf.c.familyChart";
import { useInsuranceStore } from "../../../_store/insuranceStore";
import { useFuturePlanStore } from "../../../_store/futurePlanStore";
import { ChartCashFlow } from "../../future_plan/components/VIChartCashFlow.Main";
import { useEducationStore } from "../../../_store/educationStore";
import { useDebtStore } from "../../../_store/debtplanStore";
import { useUserStore } from "../../../_store/userStore";
import { DateTime } from "luxon";
import { getUrl } from "aws-amplify/storage";

function PdfPageAssumeOne({ page }) {
  const futurePlanData = useFuturePlanStore((state) => state.futurePlanData);
  const educationData = useEducationStore((state) => state.educationData);
  const balanceData = useBalanceStore((state) => state.balanceData);
  const debtData = useDebtStore((state) => state.debtData);
  const userData = useUserStore((state) => state.userData);
  const retireData = useRetireStore((state) => state.retireData);
  const [imageKey, setImageKey] = useState(null);

  useEffect(() => {
    initData();
  }, [userData]);

  const getDetailSensitive = () => {
    return [
      {
        render: () => {
          return (
            <div
              className={cn("text-[10px] flex gap-1", STYLE_PDF_TEXT_MEDIUM_12)}
            >
              <div className="flex  flex-none">
                <div className=" flex ">
                  เศรษฐกิจถดถอย ผลตอบแทนการลงทุน
                  <div>
                    หลังเกษียณ เหลือเพียง{" "}
                    <span className=" font-semibold text-red300">1 %</span>
                  </div>
                </div>
              </div>
            </div>
          );
        },
      },
      {
        icon: (
          <ImageMain src="/images/heart_medical.svg" className="w-4 h-4 mb-3" />
        ),
        render: () => {
          return (
            <div
              className={cn("text-[10px] flex gap-1", STYLE_PDF_TEXT_MEDIUM_12)}
            >
              <div className=" flex">
                <div className=" flex ">
                  เจ็บป่วยโรคร้ายแรงมีค่ารักษาพยาบาล
                  <div>
                    &nbsp; 1 ล้านบาท ตอนอายุ{" "}
                    <span className=" font-semibold text-red300">
                      {" "}
                      {retireData?.age_current
                        ? retireData?.age_current + 5
                        : "-"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          );
        },
      },
      {
        icon: <ImageMain src="/images/moneybag.svg" className="w-4 h-4 mb-3" />,
        render: () => {
          return (
            <div
              className={cn("text-[10px] flex gap-1", STYLE_PDF_TEXT_MEDIUM_12)}
            >
              <div className="flex">
                ค้ำประกันให้ผู้อื่น เกิดหนี้ 5 ล้านบาท{" "}
                <div>
                  ตอนอายุ{" "}
                  <span className=" font-semibold text-maingreen">
                    {retireData?.age_current
                      ? retireData?.age_current + 10
                      : "-"}
                  </span>
                </div>
              </div>
            </div>
          );
        },
      },
    ];
  };

  const initData = async () => {
    if (!userData?.image_key) return;
    const imageKeyS3 = await getUrl({
      path: `public/${userData.image_key}`,
      options: {
        expiresIn: 60 * 60 * 24 * 1,
      },
    });
    const convertedImageKeyBase64 = await convertUrlToBase64(
      imageKeyS3.url.href
    );
    setImageKey(convertedImageKeyBase64);
  };

  async function convertUrlToBase64(url) {
    try {
      // Fetch the file from the URL
      const response = await fetch(url, {
        mode: "cors",
      });

      // Check if the response is ok
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Get the file as a blob
      const blob = await response.blob();

      // Create a FileReader to read the blob as a data URL (base64)
      const reader = new FileReader();
      reader.readAsDataURL(blob);

      // Return a promise that resolves with the base64 string
      return new Promise((resolve, reject) => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
      });
    } catch (error) {
      console.error("Error converting URL to base64:", error);
      throw error;
    }
  }

  return (
    <div
      style={{
        backgroundImage: `url(/images/bg_pdf.svg)`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "bottom right",
      }}
      className={cn(STYLE_PAGE)}
    >
      <PdfHeader />
      <PdfLine />
      {/* INFO */}
      <PdfCard className="space-y-2.5 ">
        <div className={cn("flex space-x-2")}>
          <div className={cn("bg-white w-[69px] h-[69px] rounded-[10px]")}>
            {imageKey && (
              <img
                src={imageKey}
                className=" w-full h-full object-cover"
                alt=""
              />
            )}
          </div>

          <div className="flex flex-col">
            <div className="flex space-x-1">
              <div className={cn(STYLE_PDF_TEXT_MEDIUM_11, "flex space-x-1")}>
                ชื่อ-สกุล
              </div>
              <div className={cn(STYLE_PDF_TEXT_MEDIUM_13, "text-maincyan")}>
                {userData?.firstname} {userData?.lastname}
              </div>
            </div>
            <PdfLine className={"mt-0 mb-0"} />

            <div className="flex items-center space-x-2">
              <div className="flex items-center space-x-1">
                <div>
                  <img src="/images/email_pdf.svg" />
                </div>
                <div className={cn(STYLE_PDF_TEXT_MEDIUM_11)}>อีเมล</div>
                <div className={cn(STYLE_PDF_TEXT_MEDIUM_11, "text-maincyan")}>
                  {userData?.email}
                </div>
              </div>
              <div className="flex items-center space-x-1">
                <div>
                  <img src="/images/phone.svg" />
                </div>
                <div className={cn(STYLE_PDF_TEXT_MEDIUM_11)}>เบอร์โทร</div>
                <div className={cn(STYLE_PDF_TEXT_MEDIUM_11, "text-maincyan")}>
                  {userData?.phone_number}
                </div>
              </div>
            </div>

            <div className="flex items-center space-x-2">
              <div className="flex items-center space-x-1">
                <div>
                  <img src="/images/calendar.svg" />
                </div>
                <div className={cn(STYLE_PDF_TEXT_MEDIUM_11)}>วันเกิด</div>
                <div className={cn(STYLE_PDF_TEXT_MEDIUM_11, "text-maincyan")}>
                  {DateTime.fromJSDate(new Date(userData?.birthday)).toFormat(
                    "dd MMM yyyy"
                  )}
                </div>
              </div>

              <div className="flex items-center space-x-1">
                <div>
                  <img src="/images/sex.svg" />
                </div>
                <div className={cn(STYLE_PDF_TEXT_MEDIUM_11)}>เพศ</div>
                <div className={cn(STYLE_PDF_TEXT_MEDIUM_11, "text-maincyan")}>
                  {userData?.sex}
                </div>
              </div>

              <div className="flex items-center space-x-1">
                <div>
                  <img src="/images/idcard.svg" />
                </div>
                <div className={cn(STYLE_PDF_TEXT_MEDIUM_11)}>
                  เลขประจำตัวผู้เสียภาษี
                </div>
                <div className={cn(STYLE_PDF_TEXT_MEDIUM_11, "text-maincyan")}>
                  {userData?.tax_id ? userData?.tax_id : "-"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </PdfCard>

      {/* STRUCTURE FAMILY */}
      <PdfCard
        className="space-y-2.5"
        classNameTitle={cn(STYLE_PDF_TEXT_MEDIUM_12)}
        title={"โครงสร้างครอบครัว"}
      >
        <PdfCardWhite>
          <div
            className={cn("grid grid-cols-4 px-2", STYLE_PDF_TEXT_MEDIUM_11)}
          >
            <div>ชื่อ</div>
            <div>นามสกุล</div>
            <div className="text-right">ความสัมพันธ์</div>
            <div className="text-right">อายุ</div>
          </div>

          {userData?.family_tree?.map((item, index) => {
            return (
              <div
                key={index}
                className={cn(
                  "grid grid-cols-4 px-2 mt-1 text-mainblue",
                  STYLE_PDF_TEXT_MEDIUM_11
                )}
              >
                <div>{item?.firstname}</div>
                <div>{item?.lastname}</div>
                <div className="text-right">{item?.relation.label}</div>
                <div className="text-right">{item?.age}</div>
              </div>
            );
          })}
        </PdfCardWhite>
      </PdfCard>

      {/* ASSUME REPORT */}
      <PdfHeaderText title="สมมติฐานรายงาน" icon="/images/diagram.svg" />

      <PdfCard className={""}>
        <div className="flex px-4 justify-between">
          <div className="flex items-center space-x-2">
            <div className="flex bg-mainyellow rounded-full overflow-hidden w-[32px] h-[32px]">
              <img src="/images/present_age2.png" />
            </div>
            <div className={cn(STYLE_PDF_TEXT_MEDIUM_13)}>อายุปัจจุบัน</div>
            <div className={cn(STYLE_PDF_TEXT_MEDIUM_13, "text-mainblue")}>
              {retireData?.age_current} ปี
            </div>
          </div>
          <div className="flex items-center space-x-2">
            <div className="flex bg-mainyellow rounded-full overflow-hidden w-[32px] h-[32px]">
              <img src="/images/old_age2.png" />
            </div>
            <div className={cn(STYLE_PDF_TEXT_MEDIUM_13)}>อายุเกษียณ</div>
            <div className={cn(STYLE_PDF_TEXT_MEDIUM_13, "text-mainblue")}>
              {retireData?.age_retire} ปี
            </div>
          </div>
          <div className="flex items-center space-x-2">
            <div className="flex bg-mainyellow rounded-full overflow-hidden w-[32px] h-[32px]">
              <img src="/images/dead2.png" />
            </div>
            <div className={cn(STYLE_PDF_TEXT_MEDIUM_13)}>สิ้นอายุขัย</div>
            <div className={cn(STYLE_PDF_TEXT_MEDIUM_13, "text-mainblue")}>
              {retireData?.age_dead} ปี
            </div>
          </div>
        </div>

        <PdfCardWhite
          className={cn(
            STYLE_PDF_TEXT_MEDIUM_12,
            "px-3 mt-2 flex flex-col space-y-1"
          )}
        >
          <div className="flex justify-between">
            <div>ผลตอบแทนคาดหวังก่อนเกษียณ</div>
            <div>{retireData?.investment_bf_retirement}%</div>
          </div>
          <div className="flex justify-between">
            <div>ผลตอบแทนคาดหวังหลังเกษียณ</div>
            <div>{retireData?.investment_af_retirement}%</div>
          </div>
          <div className="flex justify-between">
            <div>
              รายจ่ายหลังเกษียณไม่รวมค่าใช้จ่ายไม่พึงประสงค์ เช่น ค่ารักษาพยาบาล
              ภาระการค้ำประกัน เป็นต้น
            </div>
          </div>
          <div className="flex justify-between">
            <div>
              รายจ่ายหลังเกษียณจะลดลงเหลือร้อยละ 70 ของเงินเดือนงวดเดือนสุดท้าย
            </div>
          </div>
        </PdfCardWhite>

        <div className={cn(STYLE_PDF_TEXT_MEDIUM_12, "my-3 text-center")}>
          ทดสอบความอ่อนไหว
        </div>

        <PdfCardWhite className={cn(STYLE_PDF_TEXT_MEDIUM_12, "px-3")}>
          <div className="mt-2 space-y-1 flex flex-col">
            {getDetailSensitive().map((item, index) => {
              return (
                <div key={index} className="flex items-center gap-2.5">
                  {item?.render && item?.render()}
                </div>
              );
            })}
          </div>
        </PdfCardWhite>
      </PdfCard>
      <PdfFooter page={page} />
    </div>
  );
}

export default PdfPageAssumeOne;
