import { cn } from "../../../utils/useFunctions";
import { STYLE_PAGE_LANDING } from "../styles/pdf.style";

function PageLanding() {
  return (
    <div className={cn(STYLE_PAGE_LANDING)}>
      <img className="bg-cover" src={"/images/pdf_one_svg.svg"} />
    </div>
  );
}

export default PageLanding;
