import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

const investStore = (set) => ({
  investData: {
    one_question_choice_one: false,
    one_question_choice_two: false,
    one_question_choice_three: false,
    one_question_choice_four: false,

    two_question_choice_one: false,
    two_question_choice_two: false,
    two_question_choice_three: false,
    two_question_choice_four: false,

    three_question_choice_one: false,
    three_question_choice_two: false,
    three_question_choice_three: false,
    three_question_choice_four: false,

    four_question_choice_one: false,
    four_question_choice_two: false,
    four_question_choice_three: false,
    four_question_choice_four: false,

    five_question_choice_one: false,
    five_question_choice_two: false,
    five_question_choice_three: false,
    five_question_choice_four: false,

    six_question_choice_one: false,
    six_question_choice_two: false,
    six_question_choice_three: false,
    six_question_choice_four: false,

    seven_question_choice_one: false,
    seven_question_choice_two: false,
    seven_question_choice_three: false,
    seven_question_choice_four: false,

    eigth_question_choice_one: false,
    eigth_question_choice_two: false,
    eigth_question_choice_three: false,
    eigth_question_choice_four: false,

    nine_question_choice_one: false,
    nine_question_choice_two: false,
    nine_question_choice_three: false,
    nine_question_choice_four: false,

    ten_question_choice_one: false,
    ten_question_choice_two: false,
    ten_question_choice_three: false,
    ten_question_choice_four: false,

    sum_score: 0,
  },
  setInvestData: (investData) => set(() => ({ investData })),
  clearInvestData: () => set(() => ({ investData: null })),
});

export const useInvestStore = create(
  persist(investStore, {
    name: "investStore",
    storage: createJSONStorage(() => localStorage), // (optional) by default the 'localStorage' is used
  })
);
