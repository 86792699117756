import numeral from "numeral";
import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useBalanceStore } from "../../../_store/balanceStore";
import { MTButton } from "../../../components/button/MTButton";
import { DonutChartIncome } from "../../../components/chart/DonutChartMain";
import { HeaderTitle } from "../../../components/header/Header";
import Container from "../../../components/layout/Container";
import { cn } from "../../../utils/useFunctions";
import { STYLE_REPORT_CONTAIN } from "../../../utils/useStyle";
import { first_reload } from "../../tax_plan/functions/firstLoad";
import { CardReportAverage } from "../components/cardMenu";
import { HeaderReport } from "../components/header.main";
import {
  HeaderTableReport,
  HeaderTitleTableReport,
} from "../components/table.main";

const ChartSection = ({ balanceData, items }) => {
  // console.log("balanceData", balanceData);

  return (
    <div className=" flex flex-col items-center">
      <DonutChartIncome
        className=" -mt-14"
        classNameText={
          " text-sm  absolute top-[60%] left-1/2 -translate-x-1/2 -translate-y-1/2 text-black font-IBMPlexSansThai text-center"
        }
        classNameValue={" text-base"}
        classNameDonut=" w-[220px] pb-3"
        text={`รายได้`}
        classNameGray="bg-white"
        value={numeral(balanceData?.salary_propotion).format("0,0")}
        data1={balanceData?.sum_salary_peryear || 0}
        data2={balanceData?.sum_interest_peryear || 0}
        data3={balanceData?.sum_other_income || 0}
      />

      <div className=" flex flex-col justify-center ">
        {items.map((item, index) => {
          return (
            <div key={index} className=" flex items-center gap-2.5 font-medium">
              <div className={` ${item?.color} h-2 w-5`}></div>
              <div className="flex items-center text-xs gap-1">
                <div>
                  {item?.title}{" "}
                  <span className=" font-bold">
                    {item?.sum_per_year
                      ? numeral(item?.sum_per_year || 0)?.format("0,0")
                      : 0}
                  </span>{" "}
                  บาท คิดเป็น{" "}
                </div>
                <div className={` ${item?.textcolor} font-bold text-sm  `}>
                  {item?.sum_percent ? item?.sum_percent?.toFixed(2) : 0} %
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const TableIncome = ({ items }) => {
  return (
    <div className="bg-gray50 rounded-[20px] w-full h-ful p-3">
      <HeaderTableReport title="แหล่งที่มาของรายได้" textjusify="text-center" />
      {items.map((item, index) => {
        return (
          <div key={index} className={cn("  items-center")}>
            <div className=" col-span-full">
              <HeaderTitleTableReport
                img={`/images/${item?.icon}`}
                title={item?.title}
              />
            </div>

            <div
              className={`bg-[#F9F9F9] py-1 rounded-[10px] grid grid-cols-8 px-2 items-center font-semibold text-mainblue text-sm`}
            >
              <div className=" col-span-3 ">
                {/* <HeaderTitleTableReport
                  img={`/images/${item?.icon}`}
                  title={item?.title}
                /> */}
              </div>
              <div className=" col-span-2 text-right">
                {numeral(item.sum_per_month).format("0,0")}
              </div>
              <div className="col-span-3 text-right ">
                {numeral(item.sum_per_year).format("0,0")} บาท
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const ExplanSection = ({ balanceData }) => {
  return (
    <div className=" text-center flex flex-col gap-[6px]">
      <div className=" text-base font-medium ">
        หลังจากแจกแจงรายได้แล้วเราจะได้ภาพรวม ดังนี้
      </div>
      <div className="text-gray90 text-xs">
        (เงินเดือน + ดอกเบี้ย + รายได้อื่น ๆ = รายได้รวมต่อเดือน)
      </div>
      <div className=" text-base font-medium text-maincyan">
        รายได้รวมต่อเดือน{" "}
        <span className=" text-mainblue">
          {numeral(balanceData?.salary_propotion)?.format("0,0")}
        </span>
      </div>
    </div>
  );
};

const ReportIncomePage = () => {
  const navigate = useNavigate();
  const balanceData = useBalanceStore((state) => state.balanceData);

  const createSumObject = () => ({
    sum_per_month: 0,
    sum_per_year: 0,
    sum_percent: 0,
  });

  const calculateSums = (dataList, keyPermonth, keyPerYear) => {
    let sumObject = createSumObject();
    if (dataList?.length > 0) {
      for (const item of dataList) {
        if (keyPermonth) {
          sumObject.sum_per_month += item[keyPermonth] || 0;
        }
        if (keyPerYear) {
          sumObject.sum_per_year += item[keyPerYear] || 0;
        }
      }
    }
    return sumObject;
  };

  const sumSalary = useMemo(
    () =>
      calculateSums(
        balanceData?.salary_peryear_list,
        "salary_permonth",
        "amount"
      ),
    [balanceData]
  );
  const sumInterest = useMemo(
    () =>
      calculateSums(balanceData?.interest_peryear_list, "", "interest_peryear"),
    [balanceData]
  );
  const sumOther = useMemo(
    () =>
      calculateSums(balanceData?.other_income_list, "", "other_income_peryear"),
    [balanceData]
  );

  const sumTotal = useMemo(
    () =>
      sumSalary.sum_per_year + sumInterest.sum_per_year + sumOther.sum_per_year,
    [sumSalary, sumInterest, sumOther]
  );

  const items = useMemo(() => {
    return [
      {
        title: "เงินเดือน",
        icon: "salary_pdf.svg",
        ...sumSalary,
        sum_percent: (sumSalary.sum_per_year / sumTotal) * 100,
        color: "bg-green400",
        textcolor: "text-green400",
      },
      {
        title: "ดอกเบี้ย",
        icon: "interest.svg",
        ...sumInterest,
        sum_percent: (sumInterest.sum_per_year / sumTotal) * 100,
        color: "bg-green300 ",
        textcolor: "text-green300",
      },
      {
        title: "รายได้อื่นๆ",
        icon: "baht.svg",
        classNameIcon: "w-5",
        ...sumOther,
        sum_percent: (sumOther.sum_per_year / sumTotal) * 100,
        color: "bg-green600",
        textcolor: "text-green600",
      },
    ];
  }, [sumSalary, sumInterest, sumOther, sumTotal]);

  useEffect(() => {
    let ignore = false;
    first_reload(ignore);
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <Container>
      <div id="first_reload" className="pt-[14px]">
        <HeaderTitle
          title="รายงาน"
          isGoback
          onClickGoBack={() => {
            navigate("/report");
          }}
        />

        <div className={`${STYLE_REPORT_CONTAIN} flex flex-col gap-5`}>
          <HeaderReport
            icon={"/images/money-report.png"}
            title={"สัดส่วนรายได้ของคุณ"}
            className={" z-20"}
          />
          <ChartSection balanceData={balanceData} items={items} />
          <CardReportAverage
            value={numeral(balanceData?.salary_propotion / 12)?.format("0,0")}
          />
          <TableIncome items={items} />
          <ExplanSection balanceData={balanceData} />

          <div className=" flex justify-center ">
            <MTButton
              onClick={() => {
                navigate("/report");
              }}
              text="ย้อนกลับ"
              color="bg-white"
              border="border border-maincyan"
              colortext="text-maincyan group-hover:text-white"
              hovercolor="hover:bg-maincyan group "
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ReportIncomePage;
