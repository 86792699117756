import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

const store = (set) => ({
  retireData: null,
  setRetireData: (retireData) => set(() => ({ retireData })),
  clearRetireData: () => set(() => ({ retireData: null })),
});

export const useRetireStore = create(
  persist(store, {
    name: "retireData",
    storage: createJSONStorage(() => localStorage), // (optional) by default the 'localStorage' is used
  })
);
