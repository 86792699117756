import { useEffect, useState } from "react";
import {
  STYLE_BODY_TAX,
  STYLE_BORDER_BODY_TAX,
  STYLE_TABLE_TAX,
} from "../../../utils/useStyle";
import numeral from "numeral";
import { useTaxStore } from "../../../_store/taxplanStore";

export const VITaxTable = ({totalIncome=0}) => {
  const taxDataList = [
    {
      row: "1",
      tax_progressive_rate: "0 - 150,000",
      income_cal_tax: "",
      tax_rate: "ยกเว้น",
      tax_amount: "",
      max: 150000,
      rate: 0,
    },
    {
      row: "2",
      tax_progressive_rate: "เกิน 150,000 - 300,000",
      income_cal_tax: "",
      tax_rate: "5%",
      tax_amount: "",
      max: 150000,
      rate: 0.05,
    },
    {
      row: "3",
      tax_progressive_rate: "เกิน 300,000 - 500,000",
      income_cal_tax: "",
      tax_rate: "10%",
      tax_amount: "",
      rate: 0.1,
      max: 200000,
    },
    {
      row: "4",
      tax_progressive_rate: "เกิน 500,000 - 750,000",
      income_cal_tax: "",
      tax_rate: "15%",
      tax_amount: "",
      rate: 0.15,
      max: 250000,
    },
    {
      row: "5",
      tax_progressive_rate: "เกิน 750,000 - 1,000,000",
      income_cal_tax: "",
      tax_rate: "20%",
      tax_amount: "",
      rate: 0.2,
      max: 250000,
    },
    {
      row: "6",
      tax_progressive_rate: "เกิน 1,000,000 - 2,000,000",
      income_cal_tax: "",
      tax_rate: "25%",
      tax_amount: "",
      rate: 0.25,
      max: 1000000,
    },
    {
      row: "7",
      tax_progressive_rate: "เกิน 2,000,000 - 5,000,000",
      income_cal_tax: "",
      tax_rate: "30%",
      tax_amount: "",
      rate: 0.3,
      max: 3000000,
    },
    {
      row: "8",
      tax_progressive_rate: "เกิน 5,000,000",
      income_cal_tax: "",
      tax_rate: "35%",
      tax_amount: "",
      rate: 0.35,
      max: Infinity,
    },
  ];

  const [tax, setTax] = useState(0);
  const [dataStepCalTaxList, setDataStepCalTaxList] = useState([]);
  const taxData = useTaxStore((state) => state.taxData);

  const calculateTax = () => {
    const netIncome = totalIncome;
    let newTaxList = [];
    let remain = netIncome;
    for (const thisTax of taxDataList) {
      let thisItem = { ...thisTax };
      if (thisTax.max === Infinity && remain > 0) {
        thisItem.income_cal_tax = remain;
      } else if (remain > thisTax.max) {
        thisItem.income_cal_tax = thisTax.max;
      } else {
        thisItem.income_cal_tax = remain > 0 ? remain : 0;
      }

      if (thisItem.income_cal_tax > 0) {
        thisItem.tax_amount = thisItem.income_cal_tax * thisItem.rate;
      }
      remain -= thisItem.income_cal_tax;
      newTaxList.push(thisItem);
    }
    return newTaxList;
  };

  useEffect(() => {
    const taxList = calculateTax();
    const totalTax = taxList?.reduce((acc, curr) => acc + curr.tax_amount, 0);
    setTax(totalTax);
    setDataStepCalTaxList(taxList);
  }, [taxData]);

  return (
    <div className=" w-full overflow-hidden overflow-x-scroll rounded-t-[20px]  ">
      {/*-------------------- Header --------------------*/}
      <div className=" bg-[#F9F9F9] h-[100px] flex  items-center w-full">
        <div className={STYLE_TABLE_TAX}>คิดภาษีแบบอัตราก้าวหน้า</div>
        <div className={STYLE_TABLE_TAX}>เงินได้ที่ใช้คิดภาษี</div>
        <div className={STYLE_TABLE_TAX}>อัตราภาษี</div>
        <div className={STYLE_TABLE_TAX}>ค่าภาษี</div>
      </div>

      {/*-------------------- Body --------------------*/}
      {dataStepCalTaxList?.map((row, index) => {
        return (
          <div key={index} className={STYLE_BORDER_BODY_TAX}>
            <div className={`${STYLE_BODY_TAX} text-maincyan`}>
              {row.tax_progressive_rate}
            </div>
            <div className={`${STYLE_BODY_TAX} text-maincyan`}>
              {row.income_cal_tax
                ? numeral(row.income_cal_tax)?.format("0,0")
                : ""}
            </div>
            <div className={`${STYLE_BODY_TAX} text-mainblue`}>
              {row.tax_rate}
            </div>
            <div className={`${STYLE_BODY_TAX} text-maincyan`}>
              {row.tax_amount ? numeral(row.tax_amount)?.format("0,0") : ""}
            </div>
          </div>
        );
      })}

      {/* ------- รวมภาษีที่ต้องชำระ ------ */}
      <div className={STYLE_BORDER_BODY_TAX}>
        <div
          className={` whitespace-nowrap  flex-none w-[180px] text-sm text-center text-maincyan`}
        ></div>
        <div
          className={`whitespace-nowrap  flex-none w-[180px] text-sm text-center`}
        ></div>
        <div
          className={`whitespace-nowrap  flex-none w-[180px] text-sm text-center h-[60px] flex justify-center items-center px-3  text-mainblue font-semibold`}
        >
          รวมภาษีที่ต้องชำระ
        </div>
        <div
          className={`whitespace-nowrap  flex-none w-[180px]  text-center h-[60px] flex justify-center items-center px-3  text-maincyan font-semibold text-xl`}
        >
          {numeral(tax)?.format("0,0")}
        </div>
      </div>
    </div>
  );
};
