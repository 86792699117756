import { useNavigate } from "react-router-dom";
import { HeaderTitle } from "../../../components/header/Header";
import { STYLE_REPORT_CONTAIN } from "../../../utils/useStyle";
import { HeaderReport } from "../components/header.main";
import ViewSumTest from "../../invest/component/ViewSumTest.Main";
import ViewMyPortResult from "../../invest/component/VIMyPortResult.Main";
import { MTButton } from "../../../components/button/MTButton";
import { useEffect } from "react";
import { first_reload } from "../../tax_plan/functions/firstLoad";

const { default: Container } = require("../../../components/layout/Container");

const ReportInvestPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    let ignore = false;
    first_reload(ignore);
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <Container>
      <div id="first_reload" className="pt-[14px]">
        <HeaderTitle
          title="รายงาน"
          isGoback
          onClickGoBack={() => {
            navigate("/report");
          }}
        />
        <div
          className={`${STYLE_REPORT_CONTAIN} flex flex-col gap-5 mb-[30px]`}
        >
          <HeaderReport
            icon={"/images/revenue-report.png"}
            title={"สัดส่วนการลงทุน"}
            className={" z-20"}
          />
          <ViewSumTest isReport result={"formInvest.sum_score"} />
          <ViewMyPortResult status={"status"} />
          <div className=" flex justify-center ">
            <MTButton
              onClick={() => {
                navigate("/report");
              }}
              text="ย้อนกลับ"
              color="bg-white"
              border="border border-maincyan"
              colortext="text-maincyan group-hover:text-white"
              hovercolor="hover:bg-maincyan group "
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ReportInvestPage;
