import _ from "lodash";
import { calDebtCreditCard, toFloat2 } from "../../utils/useFunctions";

export const Short_Term_Debt = (data, index, age) => {
  let allPeriods = [];
  let pay_sum_alls = Array.from({ length: age }).fill(0);
  for (const each_data of data?.short_terms) {
    let pay_periods = Array.from({ length: age }).fill(0);
    let period = Math.floor(each_data?.period / 12);
    let month_left = each_data?.period % 12;

    for (let item = 0; item < period; item++) {
      pay_periods[item] = each_data?.pay_permonth * 12;
      pay_sum_alls[item] += each_data?.pay_permonth * 12;
    }
    pay_periods[period] = each_data?.pay_permonth * month_left;
    pay_sum_alls[period] += each_data?.pay_permonth * month_left;

    allPeriods.push({ name: each_data?.creditor_name, pay_periods });
  }

  return { allPeriods, pay_sum_alls };
};

export const Credit_Debt = (data, index, age) => {
  let pay_sum_alls = Array.from({ length: age }).fill(0);
  let cardNames =
    data && data.credit_cards
      ? data.credit_cards.map((item, index) => {
          return {
            name: item && item.creditor_name,
            pay_periods: Array.from({ length: age }).fill(0),
          };
        })
      : [];

  data &&
    data.credit_cards &&
    data.credit_cards.map((item, index) => {
      switch (item && item.type_payment) {
        case "pay_full":
          let foundCard = cardNames.find(
            (item2) => item2 && item2.name === item.creditor_name
          );
          if (foundCard) {
            foundCard.pay_periods[0] = item.pay_peryear;
            pay_sum_alls[0] += item.pay_peryear;
          }
          break;
        case "pay_other":
          let foundCard2 = cardNames.find(
            (item2) => item2 && item2.name === item.creditor_name
          );
          let creditor = calDebtCreditCard(item);

          let chunk = _.chunk(creditor.creditor_items, 12);
          let totalData = chunk.map((item, index) => {
            return item
              .map(
                (item, index) =>
                  item?.interest_26_5_5_6 +
                  item?.interest_25_6_5_7 +
                  item?.interest_6_6_24_6
              )
              .reduce((total, num) => total + num, 0);
          });

          foundCard2.pay_periods.map((item, index) => {
            foundCard2.pay_periods[index] = toFloat2(totalData[index]) || 0;
            pay_sum_alls[index] += toFloat2(totalData[index]) || 0;
          });

          break;
        case "pay_minimum":
          let foundCard3 = cardNames.find(
            (item2) => item2 && item2.name === item.creditor_name
          );

          let creditor2 = calDebtCreditCard(item);

          let chunk2 = _.chunk(creditor2.creditor_items, 12);
          let totalData2 = chunk2.map((item, index) => {
            return item
              .map(
                (item, index) =>
                  item?.interest_26_5_5_6 +
                  item?.interest_25_6_5_7 +
                  item?.interest_6_6_24_6
              )
              .reduce((total, num) => total + num, 0);
          });

          foundCard3.pay_periods.map((item, index) => {
            foundCard3.pay_periods[index] = toFloat2(totalData2[index]) || 0;
            pay_sum_alls[index] += toFloat2(totalData2[index]) || 0;
          });

          // console.log("foundCard3", foundCard3);

          break;
      }
    });

  // return data?.credit_cards;
  // console.log("cardNames", cardNames);
  return { allPeriods: cardNames, pay_sum_alls };
};
