import { Checkbox, Radio } from "@material-tailwind/react";
import { STYLE_TITLE_CARD_DRAWER_BL } from "../../utils/useStyle";
import { ICON_CHECK } from "../../utils/useIcons";
import { ImageMain } from "../img/Img.Main";
import { cn } from "../../utils/useFunctions";

export const CheckBoxSecond = ({
  label_title,
  label_title_2 = "",
  value,
  checked,
  name,
  onClick,
  isLeft,
  isRight,
  onChange,
  disable
}) => {
  return (
    <label className=" flex items-center -mb-3">
      {isLeft ? (
        <div className=" -ml-3">
          <Radio
            name={name}
            icon={
              <ImageMain
                src={`../images/check_box.svg`}
                alt="icon"
                className={`w-5`}
              />
            }
            value={value}
            onChange={onChange}
            onClick={onClick}
            checked={checked}
            size={"sm"}
            className=" p-0 !rounded-[6px] "
            color="cyan"
            id="ripple-on"
            ripple={false}
            disabled={disable}
          />
        </div>
      ) : (
        ""
      )}

      <div className={cn(`${STYLE_TITLE_CARD_DRAWER_BL} -m-2`,{
        "text-maingray":disable
      })}>{label_title}</div>

      {isRight ? (
        <Radio
          name={name}
          icon={
            <ImageMain
              src={`../images/check_box.svg`}
              alt="icon"
              className={`w-5`}
            />
          }
          value={value}
          onChange={onChange}
          onClick={onClick}
          checked={checked}
          size={"sm"}
          className=" p-0 !rounded-[6px] "
          color="cyan"
          id="ripple-on"
          ripple={false}
          disable={disable}
        />
      ) : (
        ""
      )}
    </label>
  );
};
