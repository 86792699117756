const NavigateGoBack = (navigate) => {
  try {
    navigate(-1);
  } catch (error) {
    console.error("NavigateGoBack  :", error);
  }
};

export default NavigateGoBack;

export const NavigatePath = (navigate, newpath) => {
  navigate(newpath);
};

// export const formatNumberWithComma = (value) => {
//   if (!value) return value;

//   const clearValue = clearNumber(value);
//   let formattedValue = "";

//   for (let i = 0; i < clearValue.length; i++) {
//     formattedValue += clearValue[i];
//     if ((i + 1) % 3 === 0 && i + 1 !== clearValue.length) {
//       formattedValue += ",";
//     }
//   }

//   return formattedValue.trim();
// };

// function clearNumber(value = "") {
//   return value.replace(/\D+/g, "");
// }

export const formatNumberWithComma = (value) => {
  if (!value) return value;

  const clearValue = clearNumber(value);
  let formattedValue = "";

  for (let i = 0; i < clearValue.length; i++) {
    formattedValue += clearValue[i];
    if ((clearValue.length - i - 1) % 3 === 0 && i + 1 !== clearValue.length) {
      formattedValue += ",";
    }
  }

  return formattedValue.trim();
};

function clearNumber(value = "") {
  return value.replace(/\D+/g, "");
}
