import { fileToBase64 } from "../../utils/useFunctions";
import { ICON_UPLOAD } from "../../utils/useIcons";

export const UploadMain = ({
  value,
  setValue,
  setValueDisplay,
  name,
  thisId,
}) => {
  const onClickUpLoadImage = async ({ target: { files } }) => {
    const file = await files[0];
    setValue(file);
    if (setValueDisplay) {
      setValueDisplay(await fileToBase64(file));
    }
  };

  return (
    <div>
      <label className=" border border-maincyan bg-white rounded-[5px] flex px-2.5">
        <div className=" flex items-center gap-1 h-7 cursor-pointer ">
          <ICON_UPLOAD className={` w-[17px] h-[17px] text-maincyan `} />
          <div className=" text-maincyan font-semibold text-sm">
            {" "}
            upload profile{" "}
          </div>
        </div>
        <input
          // style={{
          //   display: "none",
          // }}
          hidden
          accept={`image/jpeg, image/png`}
          name={name}
          id={thisId}
          type="file"
          onChange={onClickUpLoadImage}
        />
      </label>
    </div>
  );
};
