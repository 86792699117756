import { useNavigate } from "react-router-dom";
import { HeaderTitle } from "../../../components/header/Header";
import Container from "../../../components/layout/Container";
import { useEffect } from "react";
import { first_reload } from "../../tax_plan/functions/firstLoad";
import { HeaderReport } from "../components/header.main";
import {
  STYLE_REPORT_CONTAIN,
  STYLE_TEXT_BLUE,
  STYLE_TEXT_CYAN,
} from "../../../utils/useStyle";
import { useBalanceStore } from "../../../_store/balanceStore";
import { ViewTableDebt } from "../../debt/component/VIDebtTable";
import numeral from "numeral";
import { CardMain, CardTips } from "../../../components/card/CardMain";
import { TableReport } from "../components/table.main";
import { head, result } from "lodash";
import { useDebtStore } from "../../../_store/debtplanStore";
import { BarChartGroupDebt } from "../../debt/styles/ChartDebt";
import ViewFooterDebt from "../../debt/component/VIFooterDebt";
import { MTButton } from "../../../components/button/MTButton";

const ShortDebtSection = ({ balanceData }) => {
  const sumStartUnpaidAmount = () => {
    // บัตรเครดิต
    const sumCreditCard = () => {
      let result = 0;
      balanceData?.debt_short_list?.map((item) => {
        item?.credit_cards?.map((row) => {
          result += row?.start_unpaid_amount ? row?.start_unpaid_amount : 0;
        });
      });
      return result;
    };
    // เงินกู้ระยะสั้น
    const sumShortTerm = () => {
      let result = 0;
      balanceData?.debt_short_list?.map((item) => {
        item?.short_terms?.map((row) => {
          result += row?.start_unpaid_amount ? row?.start_unpaid_amount : 0;
        });
      });
      return result;
    };
    // เงินกู้นอกระบบ
    const sumInformalShortTerm = () => {
      let result = 0;
      balanceData?.debt_short_list?.map((item) => {
        item?.informal_short_terms?.map((row) => {
          result += row?.start_unpaid_amount ? row?.start_unpaid_amount : 0;
        });
      });
      return result;
    };
    // เงินกู้ระยะสั้นอื่นๆ
    const sumShortTermOther = () => {
      let result = 0;
      balanceData?.debt_short_list?.map((item) => {
        item?.short_term_others?.map((row) => {
          result += row?.start_unpaid_amount ? row?.start_unpaid_amount : 0;
        });
      });
      return result;
    };

    const finalResult =
      sumCreditCard() +
      sumShortTerm() +
      sumInformalShortTerm() +
      sumShortTermOther();

    return finalResult;
  };

  return (
    <div className=" bg-gray50 rounded-[10px] p-3 font-IBMPlexSansThai ">
      <div className="text-sm font-semibold mb-2.5">
        1. รวมหนี้ระยะสั้นเป็นก้อนเดียวกัน{" "}
      </div>
      <div className="font-semibold text-sm">จำนวนเงินค้างชำระ</div>
      <div className=" border-b border-mainyellow mt-2.5"></div>
      <div className=" col-span-full flex items-center gap-[5px] my-2.5 text-sm">
        <img
          src="/images/invest_insuarance_all.png"
          alt=""
          className={`w-6 h-fit`}
        />
        <div className=" font-semibold ">สินทรัพย์ไม่มีหลักประกัน</div>
      </div>

      <div className="">
        {/*----------- รายการหนี้ระยะสั้น -----------*/}
        {balanceData?.debt_short_list?.length > 0 ? (
          <div className=" text-sm bg-[#F9F9F9] px-2 py-1 rounded-[10px] space-y-1">
            {/*---- บัตรเครดิต ----*/}
            {balanceData?.debt_short_list?.map((item, index) => {
              if (item?.options_debt_short === "debt_creditcard")
                return (
                  <div key={index} className="">
                    {item?.credit_cards?.map((item2, index) => {
                      return (
                        <div
                          key={index}
                          className={`flex justify-between items-center`}
                        >
                          <div className=" font-medium line-clamp-1">
                            บัตรเครดิต {item2?.creditor_name}
                          </div>
                          <div className="text-right text-mainblue">
                            {numeral(item2?.start_unpaid_amount)?.format("0,0")}{" "}
                            บาท
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
            })}
            {/*---- เงินกู้ระยะสั้น ----*/}
            {balanceData?.debt_short_list?.map((item, index) => {
              if (item?.options_debt_short === "debt_shortterm")
                return (
                  <div key={index} className="">
                    {item?.short_terms?.map((item2, index) => {
                      return (
                        <div
                          key={index}
                          className={`flex justify-between items-center`}
                        >
                          <div className="line-clamp-1">
                            {item2?.creditor_name}
                          </div>
                          <div className=" text-mainblue ">
                            {numeral(item2?.start_unpaid_amount)?.format("0,0")}{" "}
                            บาท
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
            })}
            {/*---- หนี้นอกระบบ ----*/}
            {balanceData?.debt_short_list?.map((item, index) => {
              if (item?.options_debt_short === "debt_informal_shortterm")
                return (
                  <div key={index} className="">
                    {item?.informal_short_terms?.map((item2, index) => {
                      return (
                        <div
                          key={index}
                          className={`flex justify-between items-center`}
                        >
                          <div className="  line-clamp-1">
                            {item2?.creditor_name}
                          </div>
                          <div className=" text-mainblue">
                            {numeral(item2?.start_unpaid_amount)?.format("0,0")}{" "}
                            บาท
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
            })}
            {/*---- เงินกู้ระยะสั้นอื่น ๆ ----*/}
            {balanceData?.debt_short_list?.map((item, index) => {
              if (item?.options_debt_short === "debt_shortterm_other")
                return (
                  <div key={index} className="">
                    {item?.short_term_others?.map((item2, index) => {
                      return (
                        <div
                          key={index}
                          className={`flex justify-between items-center `}
                        >
                          <div className="  line-clamp-1">
                            {item2?.creditor_name}
                          </div>
                          <div className=" text-mainblue ">
                            {numeral(item2?.start_unpaid_amount)?.format("0,0")}{" "}
                            บาท
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
            })}
            <div className=" flex justify-between  mt-3 text-base">
              <div className=" font-semibold ">รวม</div>
              <div className="text-right text-mainblue text-base ">
                <span className=" text-maincyan font-bold">
                  {numeral(sumStartUnpaidAmount())?.format("0,0")}
                </span>{" "}
                บาท
              </div>
            </div>
          </div>
        ) : (
          <div className=" text-maingray text-center">ไม่มีรายการ</div>
        )}
      </div>
    </div>
  );
};

const ShortDebtSectionTwo = ({ balanceData, formDebtPlan }) => {
  return (
    <div className=" bg-gray50 rounded-[10px] p-3 font-IBMPlexSansThai ">
      <div className="text-sm font-semibold mb-2.5">
        2. วางแผนขอสินเชื่อส่วนบุคคลจากสถาบันการเงินที่
        คิดอัตราดอกเบี้ยต่อปีถูกกว่าเดิมแล้วมาชำระหนี้สินเดิม
      </div>
      <div className="font-semibold text-sm">จำนวนเงินค้างชำระ</div>
      <div className=" border-b border-mainyellow mt-2.5"></div>
      <div className=" col-span-full flex items-center gap-[5px] my-2.5 text-sm">
        <img
          src="/images/invest_insuarance_all.png"
          alt=""
          className={`w-6 h-fit`}
        />
        <div className=" font-semibold ">สินทรัพย์ไม่มีหลักประกัน</div>
      </div>

      <div className="">
        {/*----------- รายการหนี้ระยะสั้น -----------*/}
        {balanceData?.debt_short_list?.length > 0 ? (
          <div className=" text-sm bg-[#F9F9F9] px-2 py-1 rounded-[10px] space-y-1">
            {/*---- บัตรเครดิต ----*/}
            {balanceData?.debt_short_list?.map((item, index) => {
              if (item?.options_debt_short === "debt_creditcard")
                return (
                  <div key={index} className="">
                    {item?.credit_cards?.map((item2, index) => {
                      return (
                        <div
                          key={index}
                          className={`flex justify-between items-center`}
                        >
                          <div>
                            <div className=" font-medium line-clamp-1">
                              บัตรเครดิต {item2?.creditor_name}
                            </div>
                            <div className=" text-mainblue font-medium text-xs">
                              ({item2?.interest}%)
                            </div>
                          </div>
                          <div className=" flex items-center text-sm gap-1">
                            <div className=" font-medium text-xs">
                              ทำให้มีภาระหนี้สิน
                            </div>
                            <div className="text-right text-mainblue">
                              {numeral(item2?.start_unpaid_amount)?.format(
                                "0,0"
                              )}{" "}
                              บาท
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
            })}
            {/*---- เงินกู้ระยะสั้น ----*/}
            {balanceData?.debt_short_list?.map((item, index) => {
              if (item?.options_debt_short === "debt_shortterm")
                return (
                  <div key={index} className="">
                    {item?.short_terms?.map((item2, index) => {
                      return (
                        <div
                          key={index}
                          className={`flex justify-between items-center`}
                        >
                          <div>
                            <div className="line-clamp-1">
                              {item2?.creditor_name}
                            </div>

                            <div className=" text-mainblue font-medium text-xs">
                              ({item2?.interest}%)
                            </div>
                          </div>

                          <div className=" text-mainblue ">
                            {numeral(item2?.start_unpaid_amount)?.format("0,0")}{" "}
                            บาท
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
            })}
            {/*---- หนี้นอกระบบ ----*/}
            {balanceData?.debt_short_list?.map((item, index) => {
              if (item?.options_debt_short === "debt_informal_shortterm")
                return (
                  <div key={index} className="">
                    {item?.informal_short_terms?.map((item2, index) => {
                      return (
                        <div
                          key={index}
                          className={`flex justify-between items-center`}
                        >
                          <div>
                            <div className="  line-clamp-1">
                              {item2?.creditor_name}
                            </div>
                            <div className=" text-mainblue font-medium text-xs">
                              ({item2?.interest}%)
                            </div>
                          </div>

                          <div className=" flex items-center text-sm gap-1">
                            <div className=" font-medium text-xs">
                              ทำให้มีภาระหนี้สิน
                            </div>
                            <div className="text-right text-mainblue">
                              {numeral(item2?.start_unpaid_amount)?.format(
                                "0,0"
                              )}{" "}
                              บาท
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
            })}
            {/*---- เงินกู้ระยะสั้นอื่น ๆ ----*/}
            {balanceData?.debt_short_list?.map((item, index) => {
              if (item?.options_debt_short === "debt_shortterm_other")
                return (
                  <div key={index} className="">
                    {item?.short_term_others?.map((item2, index) => {
                      return (
                        <div
                          key={index}
                          className={`flex justify-between items-center `}
                        >
                          <div className="  line-clamp-1">
                            {item2?.creditor_name}
                          </div>
                          <div className=" flex items-center text-sm gap-1">
                            <div className=" font-medium text-xs">
                              ทำให้มีภาระหนี้สิน
                            </div>
                            <div className="text-right text-mainblue">
                              {numeral(item2?.start_unpaid_amount)?.format(
                                "0,0"
                              )}{" "}
                              บาท
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
            })}
            <div className=" flex justify-between  mt-3 text-base">
              <div className=" font-semibold ">ประหยัดเงินได้</div>
              <div className="text-right text-mainblue text-base ">
                <span className=" text-maincyan font-bold">
                  {numeral(formDebtPlan?.total_save_amount_af)?.format("0,0")}
                </span>{" "}
                บาท
              </div>
            </div>
          </div>
        ) : (
          <div className=" text-maingray text-center">ไม่มีรายการ</div>
        )}
      </div>
    </div>
  );
};

const GraphDebtSection = ({ formDebtPlan }) => {
  return (
    <div className="-mt-5">
      <BarChartGroupDebt
        data1={formDebtPlan?.sum_unpaid_amount}
        data2={formDebtPlan?.sum_unpaid_amount_af}
        data3={formDebtPlan?.total_save_amount_af}
      />
      <div
        className={` font-medium text-center text-sm flex gap-2 items-center justify-center mt-5`}
      >
        จะประหยัดเงินได้
        <div className={`${STYLE_TEXT_CYAN}  text-center text-xl`}>
          {numeral(formDebtPlan?.total_save_amount_af)?.format("0,0")} บาท
        </div>
      </div>
      <div className=" text-gray90 text-center text-xs mt-2.5">
        ({numeral(formDebtPlan?.sum_unpaid_amount)?.format("0,0")} -{" "}
        {numeral(formDebtPlan?.sum_unpaid_amount_af)?.format("0,0")} ={" "}
        {numeral(formDebtPlan?.total_save_amount_af)?.format("0,0")} บาท)
      </div>
    </div>
  );
};

const DebtSectionThree = ({ balanceData, formDebtPlan }) => {
  return (
    <div className="bg-gray50 rounded-[10px] p-3 font-IBMPlexSansThai">
      <div className="text-sm font-semibold mb-2.5">
        3. ถ้าหากมีรถยนต์ที่เข้า 3 คุณสมบัตินี้
      </div>
      <div className=" text-gray100 text-xs space-y-1 ">
        <div className="">1.งดการสร้างหนี้ที่ไม่ก่อให้เกิดรายได้</div>
        <div>2.หาทางลดอัตราดอกเบี้ยด้วยการต่อรองกับ สถาบันการเงิน</div>
        <div>
          3.เพิ่มจำนวนเงินผ่อนชำระในแต่ละงวด ในหนี้ที่ไม่ Fixed Rate
          เพื่อให้ยอดหนี้สินรวมหมดเร็วขึ้น และเสียดอกเบี้ยน้อยลง
        </div>
      </div>
      <div className="text-sm font-semibold text-center my-2.5">
        สามารถนำไปรีไฟแนนซ์ได้
      </div>
      <div className="bg-[#F9F9F9] px-2 py-1 rounded-[10px] space-y-1 ">
        <div className=" flex gap-1 items-center text-sm justify-center ">
          <div className=" font-medium">กรณีรถยนต์รีไฟแนนซ์ได้</div>
          <div className=" text-maincyan font-semibold text-base">
            xxx,xxx บาท
          </div>
        </div>
        <div className=" flex gap-1 items-center text-sm justify-center ">
          <div className=" font-medium">ดอกเบี้ย</div>
          <div className=" text-maincyan font-semibold text-base">
            xxx,xxx บาท
          </div>
        </div>
        <div className=" flex gap-1 items-center text-sm justify-center ">
          <div className=" font-medium">จะประหยัดเงินได้</div>
          <div className=" text-maincyan font-semibold text-base">
            xxx,xxx บาท
          </div>
        </div>
      </div>
    </div>
  );
};

const ReportDebtPlanPage = () => {
  const navigate = useNavigate();
  const balanceData = useBalanceStore((state) => state.balanceData);
  const formDebtPlan = useDebtStore((state) => state.debtData);

  useEffect(() => {
    let ignore = false;
    first_reload(ignore);
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <Container>
      <div id="first_reload" className=" pt-[14px]">
        <HeaderTitle
          title="รายงาน"
          isGoback
          onClickGoBack={() => {
            navigate("/report");
          }}
        />

        <div className={`${STYLE_REPORT_CONTAIN} flex flex-col gap-5`}>
          <HeaderReport
            icon={"/images/wealth-report.png"}
            title={"วางแผนหนี้สิน"}
            className={" z-20"}
          />
          <ViewTableDebt
            debtShortList={balanceData?.debt_short_list}
            debtLongList={balanceData?.debt_long_list}
          />
          <ShortDebtSection
            balanceData={balanceData}
            debtShortList={balanceData?.debt_short_list}
          />
          <ShortDebtSectionTwo
            balanceData={balanceData}
            formDebtPlan={formDebtPlan}
            debtShortList={balanceData?.debt_short_list}
          />
          <GraphDebtSection formDebtPlan={formDebtPlan} />
          <DebtSectionThree
            balanceData={balanceData}
            formDebtPlan={formDebtPlan}
          />
          <ViewFooterDebt />
          <CardTips
            title="กลยุทธ์การรับมือหนี้"
            panel={
              <div className=" space-y-1">
                <div>1.งดการสร้างหนี้ที่ไม่ก่อให้เกิดรายได้</div>
                <div>2.หาทางลดอัตราดอกเบี้ยด้วยการต่อรองกับ สถาบันการเงิน</div>
                <div>
                  3.เพิ่มจำนวนเงินผ่อนชำระในแต่ละงวด ในหนี้ที่ไม่ Fixed Rate
                  เพื่อให้ยอดหนี้สินรวมหมดเร็วขึ้น และเสียดอกเบี้ยน้อยลง
                </div>
              </div>
            }
          />

          <div className=" flex justify-center ">
            <MTButton
              onClick={() => {
                navigate("/report");
              }}
              text="ย้อนกลับ"
              color="bg-white"
              border="border border-maincyan"
              colortext="text-maincyan group-hover:text-white"
              hovercolor="hover:bg-maincyan group "
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ReportDebtPlanPage;
