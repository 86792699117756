import _ from "lodash";

export const calPropertyLocalOption = (localOption, item) => {
  return +item?.amount;
};

export const calDebtLocalOption = (localOption, item) => {
  if (localOption?.label === "หนี้นอกระบบ (รายวัน)") {
    return +item?.start_amount;
  }
  return +item?.unpaid_amount;
};

export const calIncomeInterestLocalOption = (localOption, item) => {
  return +item?.interest_peryear;
};

export const calIncomeInterestOtherOption = (localOption, item) => {
  return +item?.other_income_peryear;
};

export const calExpenseFixedOption = (localOption, item) => {
  return +item?.insurance_fund;
};

export const calExpenseVariableOption = (localOption, item) => {
  return +item?.pay_peryear;
};

export const calExpenseVariableMonthOption = (localOption, item) => {
  return +item?.pay_permonth;
};

export const getBalanceItems = (
  balanceData,
  mergeOptions,
  calLocalOption,
  calLocalMonthOption
) => {
  let newPropertyItems = [];

  for (const mergeOption of mergeOptions) {
    let localProperty = {
      label: mergeOption.label,
      items: [],
      sum_total: 0,
      icon: mergeOption.icon,
    };
    for (const option of mergeOption.options) {
      let localOption = {
        label: option.label,
        amount_per_year: 0,
        amount_per_month: 0,
        returns: 0,
        reward_amount_per_year: 0,
      };
      if (balanceData?.[mergeOption?.key]?.length > 0) {
        for (const balanceDataPropertyItem of balanceData?.[mergeOption?.key]) {
          const balanceDataPropertyItemKeys = Object.keys(
            balanceDataPropertyItem
          );

          if (
            [
              "interest_peryear_list",
              "other_income_list",
              "expenses_invest_saving_list",
              "expenses_variable_list",
            ].includes(mergeOption.key) &&
            balanceDataPropertyItem?.[mergeOption?.key_option] === option.value
          ) {
            if (calLocalMonthOption) {
              localOption.amount_per_month += calLocalMonthOption(
                localOption,
                balanceDataPropertyItem
              );
            } else if (mergeOption?.calLocalMonthOption) {
              localOption.amount_per_month += mergeOption?.calLocalMonthOption(
                localOption,
                balanceDataPropertyItem
              );
            }

            if (mergeOption.calLocalOption) {
              localOption.amount_per_year += mergeOption.calLocalOption(
                localOption,
                balanceDataPropertyItem
              );
            } else {
              localOption.amount_per_year += calLocalOption(
                localOption,
                balanceDataPropertyItem
              );
            }
          } else {
            for (const balanceDataPropertyItemKey of balanceDataPropertyItemKeys) {
              if (
                _.isArray(
                  balanceDataPropertyItem[balanceDataPropertyItemKey]
                ) &&
                balanceDataPropertyItem?.[mergeOption?.key_option] ===
                  option.value
              ) {
                for (const item of balanceDataPropertyItem[
                  balanceDataPropertyItemKey
                ]) {
                  if (
                    ["property_invest_list"].includes(mergeOption?.key) &&
                    item?.reward_amount_per_year
                  ) {
                    localOption.reward_amount_per_year +=
                      item?.reward_amount_per_year;
                  }

                  if (calLocalMonthOption) {
                    localOption.amount_per_month += calLocalMonthOption(
                      localOption,
                      item
                    );
                  } else if (mergeOption.calLocalMonthOption) {
                    localOption.amount_per_month +=
                      mergeOption.calLocalMonthOption(localOption, item);
                  }

                  if (mergeOption.calLocalOption) {
                    localOption.amount_per_year += mergeOption.calLocalOption(
                      localOption,
                      item
                    );
                  } else {
                    localOption.amount_per_year += calLocalOption(
                      localOption,
                      item
                    );
                  }
                }
              }
            }
          }
        }
      }
      localProperty.items.push(localOption);
    }
    localProperty.sum_total = localProperty.items.reduce(
      (acc, cur) => acc + cur.amount_per_year,
      0
    );

    const createLastItem = {
      label: "รวม",
      amount_per_year: localProperty.sum_total,
      amount_per_month: 0,
      returns: "",
      reward_amount_per_year: 0,
    };

    let left = 0;
    let right = 0;
    for (const item of localProperty.items) {
      left += item?.amount_per_year * item?.reward_amount_per_year;
      right += item?.amount_per_year;
    }

    createLastItem.reward_amount_per_year = left / right;

    localProperty.items.push(createLastItem);

    // cal returns
    for (const item of localProperty.items) {
      item.returns = (item.amount_per_year / localProperty.sum_total) * 100;
    }
    newPropertyItems.push(localProperty);
  }
  return newPropertyItems;
};

export const getAllBalanceTotal = (balanceData, mergeOptions) => {
  let sum_total = 0;
  for (const mergeOption of mergeOptions) {
    const key = mergeOption.key;
    if (balanceData?.[key]?.length > 0) {
      for (const item of balanceData?.[key]) {
        const keys = Object.keys(item);
        for (const key of keys) {
          if (_.isArray(item[key])) {
            sum_total += item[key].reduce((acc, cur) => acc + cur.amount, 0);
          }
        }
      }
    }
  }
  return sum_total;
};

export const getAllPropertyTotal = (propertyItems = []) => {
  let sum_total = 0;
  for (const propertyItem of propertyItems) {
    sum_total += propertyItem.sum_total;
  }
  return sum_total;
};

export const mapStatus = (value) => {
  if (value === "good") {
    return "ยอดเยี่ยม";
  } else if (value === "normal") {
    return "พอใช้";
  } else if (value === "bad") {
    return "ปรับปรุง";
  } else if (value === "chance") {
    return "เพิ่มโอกาส";
  } else if (value === "no_data") {
    return "ไม่มีข้อมูล";
  }
};

export const mapIcon = (value) => {
  if (value === "bad") {
    return "/bad_finance.svg";
  } else
  if (value === "good") {
    return "/smile.svg";
  } else if (value === "normal") {
    return "/normalpdf.svg";
  }
};

export const getNotPlan = (checked = false) => {
  if (checked) {
    return "(ยังไม่ได้วางแผน)";
  }
  return "";
};
