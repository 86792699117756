import { NumericFormat } from "react-number-format";
import {
  STYLE_INPUT_CARD_BALANCE,
  STYLE_TITLE_FORM_BALANCE,
} from "../../../utils/useStyle";
import { InputMain } from "../../../components/input/InputMain";
import { CardTable } from "../../../components/card/CardMain";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import th from "date-fns/locale/th";
import {
  CustomInputBirthday,
  CustomInputBirthdayHearder,
} from "../styles/Card";
import { cn } from "../../../utils/useFunctions";
import NumbericValidateRed from "../../../components/DebtBalance/numericRed";
registerLocale("th", th);

export const FormExpenseFixed = ({
  form,
  value,
  onChange,
  onChangeStatusAIAVitlaity,
  onChangeTypeOfPayment,
  onChangeTotalYear,
  onChangeAIATotalCareMore,
  onChangeLifeInsurance,
  onChangeLifeAllowance,
  onChangeExceptAllowanceWP,
  onChangeExceptAllowancePB,
  onChangeContractTPD,
  onChangeProtectionLifeLT,
  onChangeExceptAllowanceLT,
  onChangeAccidentAI,
  onChangeAccidentADB,
  onChangeAccidentADD,
  onChangeHospitalTreatmentSurgery,
  onChangeHospitalTreatmentSurgeryLumpSumPayment,
  onChangeDailyCompensationForTreatment,
  onChangeDailyCompensationForTreatmentSpecial,
  onChangeCompensationForCancerTreatmentCR,
  onChangeCompensationECIR,
  onChangeCompensationAIASupercare,
  onChangeCompensationAIACIPlusGold,
  onChangeCompensationAIACIPlus,
  onChangeCompensationMutipleCareCi,
  onChangeCriticalIllnessInsuranceYearlyCi,
  onChangeMedicalExpensesAccidentPA,
  onChangeAllowanceLastTime,
  onChangeDatePayAllowanceLastTime,
  onChangeDateDueAllowanceNextTime,
  onChangeDateDueAllowanceFinalTime,
  onChangeBeneficiaryNameOne,
  onChangeBeneficiaryNameTwo,
  onChangeBeneficiaryRelationshipOne,
  onChangeBeneficiaryRelationshipTwo,
  onChangeAnnotation,
  onChangeAIAMutiplayCIMore,
  onChangePolicySurrenderValue,
  onChangeContractualRefund,
  //mark
}) => {
  return (
    <div className=" space-y-2">
      <div>
        <div className={STYLE_TITLE_FORM_BALANCE}>
          ชีวิตตามแบบประกันภัยข้างต้น
        </div>
       <NumbericValidateRed
          maxLength={11}
          value={form?.life_insurance}
          placeholder="0"
          onChange={onChangeLifeInsurance}
          thousandSeparator=","
          inputMode="decimal"
          pattern="[0-9],*"
          className={`${STYLE_INPUT_CARD_BALANCE} w-full `}
        />
      </div>
      <div>
        <div className={STYLE_TITLE_FORM_BALANCE}>
          ชีวิตสำหรับเบี้ยประกันภัยลงทุน
        </div>
       <NumbericValidateRed
          maxLength={11}
          value={form?.life_allowance}
          placeholder="0"
          onChange={onChangeLifeAllowance}
          thousandSeparator=","
          inputMode="decimal"
          pattern="[0-9],*"
          className={`${STYLE_INPUT_CARD_BALANCE} w-full `}
        />
      </div>
      <div>
        <div className={STYLE_TITLE_FORM_BALANCE}>
          ยกเว้นเบี้ยกรมธรรม์ประกันภัยหลัก (WP)
        </div>
       <NumbericValidateRed
          maxLength={11}
          value={form?.except_allowance_wp}
          placeholder="0"
          onChange={onChangeExceptAllowanceWP}
          thousandSeparator=","
          inputMode="decimal"
          pattern="[0-9],*"
          className={`${STYLE_INPUT_CARD_BALANCE} w-full `}
        />
      </div>
      <div>
        <div className={STYLE_TITLE_FORM_BALANCE}>
          ยกเว้นเบี้ยกรมธรรม์ประกันภัยหลัก (PB)
        </div>
       <NumbericValidateRed
          maxLength={11}
          value={form?.except_allowance_pb}
          placeholder="0"
          onChange={onChangeExceptAllowancePB}
          thousandSeparator=","
          inputMode="decimal"
          pattern="[0-9],*"
          className={`${STYLE_INPUT_CARD_BALANCE} w-full `}
        />
      </div>
      <div>
        <div className={STYLE_TITLE_FORM_BALANCE}>
          สัญญาเพิ่มเติมกรณีทุพพลภาพ (TPD)
        </div>
       <NumbericValidateRed
          maxLength={11}
          value={form?.contract_tpd}
          placeholder="0"
          onChange={onChangeContractTPD}
          thousandSeparator=","
          inputMode="decimal"
          pattern="[0-9],*"
          className={`${STYLE_INPUT_CARD_BALANCE} w-full `}
        />
      </div>
      <div>
        <div className={STYLE_TITLE_FORM_BALANCE}>
          สัญญาเพิ่มเติม เอไอเอ มัลติเพย์ ซีไอ
        </div>
       <NumbericValidateRed
          maxLength={11}
          value={form?.aia_mutiplay_ci_more}
          placeholder="0"
          onChange={onChangeAIAMutiplayCIMore}
          thousandSeparator=","
          inputMode="decimal"
          pattern="[0-9],*"
          className={`${STYLE_INPUT_CARD_BALANCE} w-full `}
        />
      </div>
      <div>
        <div className={STYLE_TITLE_FORM_BALANCE}>
          สัญญาเพิ่มเติม เอไอเอ โทเทิล แคร์
        </div>
       <NumbericValidateRed
          maxLength={11}
          value={form?.aia_total_care_more}
          placeholder="0"
          onChange={onChangeAIATotalCareMore}
          thousandSeparator=","
          inputMode="decimal"
          pattern="[0-9],*"
          className={`${STYLE_INPUT_CARD_BALANCE} w-full `}
        />
      </div>
      <div>
        <div className={STYLE_TITLE_FORM_BALANCE}>
          คุ้มครองชีวิตแบบมีระยะเวลา (LT)
        </div>
       <NumbericValidateRed
          maxLength={11}
          value={form?.protection_life_lt}
          placeholder="0"
          onChange={onChangeProtectionLifeLT}
          thousandSeparator=","
          inputMode="decimal"
          pattern="[0-9],*"
          className={`${STYLE_INPUT_CARD_BALANCE} w-full `}
        />
      </div>
      <div>
        <div className={STYLE_TITLE_FORM_BALANCE}>
          ยกเว้นเบี้ยหลัก(LT) กรณีทุพพลภาพ
        </div>
       <NumbericValidateRed
          maxLength={11}
          value={form?.except_allowance_lt}
          placeholder="0"
          onChange={onChangeExceptAllowanceLT}
          thousandSeparator=","
          inputMode="decimal"
          pattern="[0-9],*"
          className={`${STYLE_INPUT_CARD_BALANCE} w-full `}
        />
      </div>
      <div>
        <div className={STYLE_TITLE_FORM_BALANCE}>
          อุบัติเหตุ (เอ.ไอ)พร้อมด้วยบันทึกเพิ่มเติม
        </div>
       <NumbericValidateRed
          maxLength={11}
          value={form?.accident_ai}
          placeholder="0"
          onChange={onChangeAccidentAI}
          thousandSeparator=","
          inputMode="decimal"
          pattern="[0-9],*"
          className={`${STYLE_INPUT_CARD_BALANCE} w-full `}
        />
      </div>
      <div>
        <div className={STYLE_TITLE_FORM_BALANCE}>
          อุบัติเหตุ (เอ.ดี.บี) พร้อมด้วยบันทึกเพิ่มเติม
        </div>
       <NumbericValidateRed
          maxLength={11}
          value={form?.accident_adb}
          placeholder="0"
          onChange={onChangeAccidentADB}
          thousandSeparator=","
          inputMode="decimal"
          pattern="[0-9],*"
          className={`${STYLE_INPUT_CARD_BALANCE} w-full `}
        />
      </div>
      <div>
        <div className={STYLE_TITLE_FORM_BALANCE}>
          อุบัติเหตุ (เอ.ดี.ดี) พร้อมด้วยบันทึกเพิ่มเติม
        </div>
       <NumbericValidateRed
          maxLength={11}
          value={form?.accident_add}
          placeholder="0"
          onChange={onChangeAccidentADD}
          thousandSeparator=","
          inputMode="decimal"
          pattern="[0-9],*"
          className={`${STYLE_INPUT_CARD_BALANCE} w-full `}
        />
      </div>
      <div>
        <div className={STYLE_TITLE_FORM_BALANCE}>
          การรักษาในโรงพยาบาลและศัลยกรรม
        </div>
        <InputMain
          className={`${STYLE_INPUT_CARD_BALANCE} w-full `}
          placeholder="ระบุ"
          value={form?.hospital_treatment_surgery}
          onChange={onChangeHospitalTreatmentSurgery}
        />
      </div>
      <div>
        <div className={STYLE_TITLE_FORM_BALANCE}>
          การรักษาในโรงพยาบาลและศัลยกรรมแบบเหมาจ่าย
        </div>
        <InputMain
          className={`${STYLE_INPUT_CARD_BALANCE} w-full `}
          placeholder="ระบุ"
          value={form?.hospital_treatment_surgery_lump_sum_payment}
          onChange={onChangeHospitalTreatmentSurgeryLumpSumPayment}
        />
      </div>
      <div>
        <div className={STYLE_TITLE_FORM_BALANCE}>
          ค่าชดเชยรายวันในการเข้ารักษาตัวในโรงพยาบาล
        </div>
       <NumbericValidateRed
          maxLength={11}
          value={form?.daily_compensation_for_treatment}
          placeholder="0"
          onChange={onChangeDailyCompensationForTreatment}
          thousandSeparator=","
          inputMode="decimal"
          pattern="[0-9],*"
          className={`${STYLE_INPUT_CARD_BALANCE} w-full `}
        />
      </div>
      <div>
        <div className={STYLE_TITLE_FORM_BALANCE}>
          ค่าชดเชยรายวันในการเข้ารักษาตัวในโรงพยาบาล แบบพิเศษ
        </div>
       <NumbericValidateRed
          maxLength={11}
          value={form?.daily_compensation_for_treatment_special}
          placeholder="0"
          onChange={onChangeDailyCompensationForTreatmentSpecial}
          thousandSeparator=","
          inputMode="decimal"
          pattern="[0-9],*"
          className={`${STYLE_INPUT_CARD_BALANCE} w-full `}
        />
      </div>
      <div>
        <div className={STYLE_TITLE_FORM_BALANCE}>
          ค่าชดเชยการรักษาโรคมะเร็งในโรงพยาบาล (CR)
        </div>
       <NumbericValidateRed
          maxLength={11}
          value={form?.compensation_for_cancer_treatment_cr}
          placeholder="0"
          onChange={onChangeCompensationForCancerTreatmentCR}
          thousandSeparator=","
          inputMode="decimal"
          pattern="[0-9],*"
          className={`${STYLE_INPUT_CARD_BALANCE} w-full `}
        />
      </div>
      <div>
        <div className={STYLE_TITLE_FORM_BALANCE}>
          ค่าชดเชยโรคร้ายแรงต่อเนื่อง (ECIR) ไม่มีเงินคืน
        </div>
       <NumbericValidateRed
          maxLength={11}
          value={form?.compensation_ecir}
          placeholder="0"
          onChange={onChangeCompensationECIR}
          thousandSeparator=","
          inputMode="decimal"
          pattern="[0-9],*"
          className={`${STYLE_INPUT_CARD_BALANCE} w-full `}
        />
      </div>
      <div>
        <div className={STYLE_TITLE_FORM_BALANCE}>
          ค่าชดเชยโรคร้ายแรง (AIA Supercare) มีเงินคืน
        </div>
       <NumbericValidateRed
          maxLength={11}
          value={form?.compensation_aia_supercare}
          placeholder="0"
          onChange={onChangeCompensationAIASupercare}
          thousandSeparator=","
          inputMode="decimal"
          pattern="[0-9],*"
          className={`${STYLE_INPUT_CARD_BALANCE} w-full `}
        />
      </div>
      <div>
        <div className={STYLE_TITLE_FORM_BALANCE}>
          ค่าชดเชยโรยร้ายแรง (AIA CI Plus Gold) ไม่มีเงินคืน
        </div>
       <NumbericValidateRed
          maxLength={11}
          value={form?.compensation_aia_ci_plus_gold}
          placeholder="0"
          onChange={onChangeCompensationAIACIPlusGold}
          thousandSeparator=","
          inputMode="decimal"
          pattern="[0-9],*"
          className={`${STYLE_INPUT_CARD_BALANCE} w-full `}
        />
      </div>
      <div>
        <div className={STYLE_TITLE_FORM_BALANCE}>
          ค่าชดเชยโรยร้ายแรง (AIA CI Plus) ไม่มีเงินคืน
        </div>
       <NumbericValidateRed
          maxLength={11}
          value={form?.compensation_aia_ci_plus}
          placeholder="0"
          onChange={onChangeCompensationAIACIPlus}
          thousandSeparator=","
          inputMode="decimal"
          pattern="[0-9],*"
          className={`${STYLE_INPUT_CARD_BALANCE} w-full `}
        />
      </div>
      <div>
        <div className={STYLE_TITLE_FORM_BALANCE}>
          ค่าชดเชยโรยร้ายแรง (Mutiple Care CI) ไม่มีเงินคืน
        </div>
       <NumbericValidateRed
          maxLength={11}
          value={form?.compensation_mutiple_care_ci}
          placeholder="0"
          onChange={onChangeCompensationMutipleCareCi}
          thousandSeparator=","
          inputMode="decimal"
          pattern="[0-9],*"
          className={`${STYLE_INPUT_CARD_BALANCE} w-full `}
        />
      </div>
      <div>
        <div className={STYLE_TITLE_FORM_BALANCE}>
          ประกันภัยโรคร้ายแรงแบบซีไอรายปี
        </div>
       <NumbericValidateRed
          maxLength={11}
          value={form?.critical_illness_insurance_yearly_ci}
          placeholder="0"
          onChange={onChangeCriticalIllnessInsuranceYearlyCi}
          thousandSeparator=","
          inputMode="decimal"
          pattern="[0-9],*"
          className={`${STYLE_INPUT_CARD_BALANCE} w-full `}
        />
      </div>
      <div>
        <div className={STYLE_TITLE_FORM_BALANCE}>
          ค่ารักษาพยาบาลเนื่องจากอุบัติเหตุ (PA)
        </div>
       <NumbericValidateRed
          maxLength={11}
          value={form?.medical_expenses_accident_pa}
          placeholder="0"
          onChange={onChangeMedicalExpensesAccidentPA}
          thousandSeparator=","
          inputMode="decimal"
          pattern="[0-9],*"
          className={`${STYLE_INPUT_CARD_BALANCE} w-full `}
        />
      </div>
      <div>
        <div className={STYLE_TITLE_FORM_BALANCE}>สถานะสิทธิประโยชน์ เช่น AIA Vitlaity</div>
        <InputMain
          maxLength={11}
          value={form?.status_aia_vitlaity}
          placeholder="ระบุ"
          onChange={onChangeStatusAIAVitlaity}
          thousandSeparator=","
          inputMode="decimal"
          pattern="[0-9],*"
          className={`${STYLE_INPUT_CARD_BALANCE} w-full `}
        />
      </div>
      <div>
        <div className={STYLE_TITLE_FORM_BALANCE}>มูลค่าเวนคืนกรมธรรม์ /มูลค่าหน่วยลงทุน</div>
        <InputMain
          maxLength={11}
          type="number"
          value={form?.policy_surrender_value}
          placeholder="ระบุ"
          onChange={onChangePolicySurrenderValue}
          thousandSeparator=","
          inputMode="decimal"
          pattern="[0-9],*"
          className={`${STYLE_INPUT_CARD_BALANCE} w-full `}
        />
      </div>
      <div>
        <div className={STYLE_TITLE_FORM_BALANCE}>เงินคืนตามสัญญาและเงินปันผลสะสมที่ฝากกับบริษัท</div>
        <InputMain
          maxLength={11}
          value={form?.contractual_refund}
          placeholder="ระบุ"
          onChange={onChangeContractualRefund}
          thousandSeparator=","
          inputMode="decimal"
          pattern="[0-9],*"
          className={`${STYLE_INPUT_CARD_BALANCE} w-full `}
        />
      </div>
      <div>
        <div className={STYLE_TITLE_FORM_BALANCE}>ประเภทงวดชำระเบี้ย</div>
        <InputMain
          maxLength={11}
          value={form?.type_of_payment}
          placeholder="ระบุ"
          onChange={onChangeTypeOfPayment}
          thousandSeparator=","
          inputMode="decimal"
          pattern="[0-9],*"
          className={`${STYLE_INPUT_CARD_BALANCE} w-full `}
        />
      </div>
      <div>
        <div className={STYLE_TITLE_FORM_BALANCE}>
          ค่าเบี้ยประกันครั้งล่าสุด
        </div>
       <NumbericValidateRed
          maxLength={11}
          value={form?.allowance_last_time}
          placeholder="0"
          onChange={onChangeAllowanceLastTime}
          thousandSeparator=","
          inputMode="decimal"
          pattern="[0-9],*"
          className={`${STYLE_INPUT_CARD_BALANCE} w-full `}
        />
      </div>
      <div>
        <div className={STYLE_TITLE_FORM_BALANCE}>ค่าเบี้้ยประกันรวมรายปี</div>
       <NumbericValidateRed
          maxLength={11}
          value={form?.total_year}
          placeholder="0"
          onChange={onChangeTotalYear}
          thousandSeparator=","
          inputMode="decimal"
          pattern="[0-9],*"
          className={`${STYLE_INPUT_CARD_BALANCE} w-full `}
        />
      </div>
      <div>
        <div className={STYLE_TITLE_FORM_BALANCE}>
          วันที่ชำระเบี้ยประกันล่าสุด
        </div>
        <DatePicker
          selected={
            form?.date_pay_allowance_last_time
              ? new Date(form?.date_pay_allowance_last_time)
              : ""
          }
          locale={th}
          dateFormat="dd/MM/yyyy"
          renderCustomHeader={CustomInputBirthdayHearder}
          onKeyDown={(e) => {
            e.preventDefault();
          }}
          wrapperClassName="w-full"
          customInput={
            <CustomInputBirthday
              className={cn("bg-white border border-gray60 !h-8")}
            />
          }
          // showTimeSelect
          onChange={(e) =>
            onChangeDatePayAllowanceLastTime({ target: { value: e } })
          }
        />
        {/* <InputMain
          type="date"
          className={`${STYLE_INPUT_CARD_BALANCE} w-full `}
          placeholder="ระบุ"
          value={form?.date_pay_allowance_last_time}
          onChange={onChangeDatePayAllowanceLastTime}
        /> */}
      </div>
      <div>
        <div className={STYLE_TITLE_FORM_BALANCE}>
          วันครบกำหนดชำระเบี้ยครั้งถัดไป
        </div>
        <DatePicker
          selected={
            form?.date_due_allowance_next_time
              ? new Date(form?.date_due_allowance_next_time)
              : ""
          }
          locale={th}
          dateFormat="dd/MM/yyyy"
          renderCustomHeader={CustomInputBirthdayHearder}
          onKeyDown={(e) => {
            e.preventDefault();
          }}
          wrapperClassName="w-full"
          customInput={
            <CustomInputBirthday
              className={cn("bg-white border border-gray60 !h-8")}
            />
          }
          // showTimeSelect
          onChange={(e) =>
            onChangeDateDueAllowanceNextTime({ target: { value: e } })
          }
        />
        {/* <InputMain
          type="date"
          className={`${STYLE_INPUT_CARD_BALANCE} w-full `}
          placeholder="ระบุ"
          value={form?.date_due_allowance_next_time}
          onChange={onChangeDateDueAllowanceNextTime}
        /> */}
      </div>
      <div>
        <div className={STYLE_TITLE_FORM_BALANCE}>
          วันครบกำหนดชำระเบี้ยครั้งสุดท้าย
        </div>
        <DatePicker
          selected={
            form?.date_due_allowance_final_time
              ? new Date(form?.date_due_allowance_final_time)
              : ""
          }
          locale={th}
          dateFormat="dd/MM/yyyy"
          renderCustomHeader={CustomInputBirthdayHearder}
          onKeyDown={(e) => {
            e.preventDefault();
          }}
          wrapperClassName="w-full"
          customInput={
            <CustomInputBirthday
              className={cn("bg-white border border-gray60 !h-8")}
            />
          }
          // showTimeSelect
          onChange={(e) =>
            onChangeDateDueAllowanceFinalTime({ target: { value: e } })
          }
        />
        {/* <InputMain
          type="date"
          className={`${STYLE_INPUT_CARD_BALANCE} w-full `}
          placeholder="ระบุ"
          value={form?.date_due_allowance_final_time}
          onChange={onChangeDateDueAllowanceFinalTime}
        /> */}
      </div>
      {/*mark*/}
      <CardTable
        head="ผู้รับผลประโยชน์"
        title_1="ชื่อ-นามสกุล"
        title_2="ความสัมพันธ์"
        name1={form?.beneficiary_name_one}
        name2={form?.beneficiary_name_two}
        relationship1={form?.beneficiary_relationship_one}
        relationship2={form?.beneficiary_relationship_two}
        onChangeName1={onChangeBeneficiaryNameOne}
        onChangeName2={onChangeBeneficiaryNameTwo}
        onChangeRelationship1={onChangeBeneficiaryRelationshipOne}
        onChangeRelationship2={onChangeBeneficiaryRelationshipTwo}
      />
      <div>
        <div className={STYLE_TITLE_FORM_BALANCE}>หมายเหตุ</div>
        <InputMain
          className={`${STYLE_INPUT_CARD_BALANCE} w-full `}
          placeholder="ระบุ"
          value={form?.annotation}
          onChange={onChangeAnnotation}
        />
      </div>
    </div>
  );
};
