export const styleTable = (status) => {
  if (status === "pass") {
    return "bg-greenopacity border-b-2 border-b-maingreen ";
  } else if (status === "warning") {
    return "bg-yellowopacity border-b-2 border-b-mainyellow";
  } else if (status === "danger") {
    return " bg-redopacity border-b-2 border-b-mainred";
  }
};
export const styleTableBody = (status) => {
  if (status === "pass") {
    return "bg-greenopacity ";
  } else if (status === "warning") {
    return "bg-yellowopacity";
  } else if (status === "danger") {
    return " bg-redopacity";
  }
};
