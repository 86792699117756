export const FormLegacyManulOne = () => {
  return (
    <div className=" bg-gray40 w-full h-full p-5 text-black font-medium text-[11px] space-y-[6px] ">
      <div className=" text-center">
        ตัวอย่างพินัยกรรมแบบเองทั้งฉบับ แบบที่ 1
      </div>
      <div className=" text-center">พินัยกรรม</div>
      <div className=" text-right">
        ทำที่ ..................................
        <div>
          วันที่ ................ เดือน ....................... พ.ศ. .........
        </div>
      </div>
      <div className=" space-y-3">
        <div>
          <div className="indent-8">
            ข้าพเจ้า .................................... อายุ .........ปี
          </div>
          <div>
            อยู่บ้านเลขที่ ...................หมู่ที่
            ...............ถนน............................
          </div>
          <div>
            ตำบล/แขวง ...............................อำเภอ/เขต
            ....................... จังหวัด .................................
          </div>
        </div>
        <div className="indent-8">
          ได้ทำพินัยกรรมฉบับนี้ขึ้นไว้เพื่อแสดงว่าเมื่อข้าพเจ้าถึงแก่กรรมให้แบ่งทรัพย์สินต่างๆ
          ของข้าพเจ้าให้บุคคลดังต่อไปนี้ 
        </div>

        <div>
          <div className="">
            1. ที่ดินโฉนดเลขที่  ....................... อยู่ที่ตำบล
            ......................... อำเภอ .................. จังหวัด
            ..................... ขอมอบให้แก่ ...............................
          </div>
          <div className="">
            2. ที่ดินโฉนดเลขที่ ..................
            พร้อมสิ่งปลูกสร้างที่อยู่ที่ตำบล ............. อำเภอ
            ................จังหวัด...........มอบให้แก่
            ....................................
          </div>
          <div className="">
            3. เงินสดจำนวน .......................... บาท ซึ่งฝากไว้ที่ธนาคาร
            .......................... สาขา ...............................
            ตามสมุดเงินฝากประเภท ............................หมายเลขบัญชี
            .............................ขอมอบให้แก่..............................
          </div>
          <div className="">
            4. เงินสดจำนวน ........................... บาท ซึ่งฝากไว้ที่ธนาคาร
            ............................สาขา...........................ตามสมุดเงินฝากประเภท
            ............................. หมายเลขบัญชี
            .............................. ขอมอบให้แก่
            .............................
          </div>
          <div className="">
            5. เครื่องเพชร พลอย ทอง นาค เงิน (ทั้งหมดหรือบางส่วน) ขอมอบให้แก่
            ............................................
          </div>
          <div className="">
            6. ทรัพย์สินของข้าพเจ้านอกจากที่ระบุตามข้อ 1, 2, 3, 4, 5
            นี้แล้วขอมอบให้แก่ ..............................
          </div>
          <div className="">
            7. ขอให้ .........................................
            เป็นผู้จัดการศพข้าพเจ้า โดยให้หักเงินค่าทำศพไว้จากทรัพย์สินใน ข้อ 6
            จำนวน ............................... บาท
            มอบให้เป็นผู้จัดการศพข้าพเจ้า
          </div>
          <div className="">
            8. ขอให้
            ...................................เป็นผู้จัดการมรดกของข้าพเจ้า
            เพื่อจัดการแบ่งปันทรัพย์มรดกของข้าพเจ้าตามที่ได้แสดงเจตนาไว้แล้วข้างต้น
            พินัยกรรมนี้ข้าพเจ้าเขียนด้วยลายมือของข้าพเจ้าทั้งฉบับ ได้ทำไว้ 2
            ฉบับ มีข้อความถูกต้องตรงกันทุกประการ
            ฉบับหนึ่งเก็บไว้ที่.......................................
            อีกฉบับหนึ่งเก็บไว้ที่.......................
          </div>
        </div>
        <div className="indent-8">
          ขณะทำพินัยกรรมข้าพเจ้ามีสติสัมปชัญญะปกติ
          <div>ข้าพเจ้าได้อ่านและเข้าใจข้อความโดยตลอดแล้ว</div>
        </div>
        <div className="text-right">
          ลงชื่อ .............................................. ผู้ทำนิติกรรม
          <div>(                                   )</div>
        </div>
      </div>
    </div>
  );
};

export const FormLegacyManulTwo = () => {
  return (
    <div className=" bg-gray40 w-full h-full p-5 text-black font-medium text-[11px] space-y-[6px] ">
      <div className=" text-center">
        ตัวอย่างพินัยกรรมแบบเองทั้งฉบับ แบบที่ 2
      </div>
      <div className=" text-center">พินัยกรรม</div>
      <div className=" text-right">
        วันที่ ................ เดือน ....................... พ.ศ. .........
      </div>
      <div className=" space-y-3">
        <div>
          <div className="indent-8">
            ข้าพเจ้า .................................... อายุ .........ปี
          </div>
          <div>
            อยู่บ้านเลขที่ ...................หมู่ที่
            ...............ถนน............................
          </div>
          <div>
            ตำบล/แขวง ...............................อำเภอ/เขต
            ....................... จังหวัด .................................
          </div>
        </div>
        <div className="indent-8">
          ได้ทำพินัยกรรมฉบับนี้ขึ้นไว้ เพื่อแสดงเจตนาว่า
          เมื่อข้าพเจ้าถึงแก่กรรมให้ทรัพย์สินของข้าพเจ้า ทั้งหมด
          ทั้งที่มีอยู่ในปัจจุบันและที่จะมีต่อไปในอนาคตตกได้แก่
          .........................................แต่ผู้เดียว
        </div>
        <div className="indent-8">
          พินัยกรรมนี้ ข้าพเจ้าเขียนด้วยลายมือของข้าพเจ้าทั้งฉบับ ได้ทำไว้ 2
          ฉบับ มีข้อความถูกต้องตรงกันทุกประการ
          ฉบับหนึ่งเก็บไว้ที่...........................อีกฉบับหนึ่งเก็บไว้ที่
          ........................... ขณะทำพินัยกรรมข้าพเจ้ามีสติสัมปชัญญะปกติ
        </div>

        <div className="indent-8">
          ข้าพเจ้าได้อ่านและเข้าใจข้อความโดยตลอดแล้ว
        </div>
        <div className="text-right">
          ลงชื่อ .............................................. ผู้ทำพินัยกรรม
          <div>(                                   )</div>
        </div>
      </div>
    </div>
  );
};

export const FormLegacyManulThree = () => {
  return (
    <div className=" bg-gray40 w-full h-full p-5 text-black font-medium text-[11px] space-y-[6px] ">
      <div className=" text-center">
        ตัวอย่างพินัยกรรมแบบเองทั้งฉบับ แบบที่ 3
      </div>
      <div className=" text-center">พินัยกรรม</div>
      <div className=" text-right">
        ทำที่ .................................. วันที่ ................ เดือน
        ....................... พ.ศ. .........
      </div>
      <div className=" space-y-3">
        <div>
          <div className="indent-8">
            ข้าพเจ้า .................................... อายุ .........ปี
          </div>
          <div>
            อยู่บ้านเลขที่ ...................หมู่ที่
            ...............ถนน............................
          </div>
          <div>
            ตำบล/แขวง ...............................อำเภอ/เขต
            ....................... จังหวัด .................................
          </div>
        </div>
        <div className="indent-8">
          ได้ทำพินัยกรรมฉบับนี้ขึ้นไว้ เพื่อแสดงเจตนาว่า
          เมื่อข้าพเจ้าถึงแก่กรรมให้ทรัพย์สินของข้าพเจ้า ทั้งหมด
          ทั้งที่มีอยู่ในปัจจุบันและที่จะมีต่อไปในอนาคตตกได้แก่
          .........................................แต่ผู้เดียว
        </div>
        <div className="">
          <div>
            1.
            ..........................................................................................
          </div>
          <div>
            2.
            ..........................................................................................
          </div>
          <div>
            3.
            ..........................................................................................
          </div>
          <div>
            4.
            ..........................................................................................
          </div>
        </div>
        <div className="indent-8">
          พินัยกรรมนี้ ข้าพเจ้าเขียนด้วยลายมือของข้าพเจ้าทั้งฉบับ ได้ทำไว้ 2
          ฉบับ มีข้อความถูกต้องตรงกันทุกประการ ฉบับหนึ่งเก็บไว้ที่
          ................. อีกฉบับหนึ่ง เก็บไว้ที่
          ............................................
        </div>

        <div className="indent-8">
          <div> ขณะทำพินัยกรรมข้าพเจ้ามีสติสัมปชัญญะปกติ</div>
          ข้าพเจ้าได้อ่านและเข้าใจข้อความโดยตลอดแล้ว
        </div>
        <div className="text-right">
          ลงชื่อ .............................................. ผู้ทำพินัยกรรม
          <div>(                                   )</div>
        </div>
      </div>
    </div>
  );
};

export const FormLegacyNormalOne = () => {
  return (
    <div className=" bg-gray40 w-full h-full p-5 text-black font-medium text-[11px] space-y-[6px] ">
      <div className=" text-center">
        ตัวอย่างแบบฟอร์มพินัยกรรมแบบธรรมดา แบบที่ 1
      </div>
      <div className=" text-center">พินัยกรรม</div>
      <div className=" text-right">
        ทำที่ ..................................
        <div>
          วันที่ ................ เดือน ....................... พ.ศ. .........
        </div>
      </div>
      <div className=" space-y-3">
        <div>
          <div className="indent-8">
            ข้าพเจ้า .................................... อายุ .........ปี
          </div>
          <div>
            อยู่บ้านเลขที่ ...................หมู่ที่
            ...............ถนน............................
          </div>
          <div>
            ตำบล/แขวง ...............................อำเภอ/เขต
            ....................... จังหวัด .................................
          </div>
        </div>
        <div className="indent-8">
          ได้ทำพินัยกรรมฉบับนี้ขึ้นไว้ เพื่อแสดงเจตนาว่า
          เมื่อข้าพเจ้าถึงแก่กรรมให้ทรัพย์สินของข้าพเจ้า ทั้งหมด
          ทั้งที่มีอยู่ในปัจจุบันและที่จะมีต่อไปในอนาคตตกได้แก่
          .........................................แต่ผู้เดียว
        </div>
        <div className="text-right">
          ลงชื่อ .............................................. ผู้ทำพินัยกรรม
          <div>(                                   )</div>
        </div>
        <div className="indent-8">
          ข้าพเจ้าผู้มีนามข้างท้ายนี้ขอรับรองว่า ....................
          ผู้ทำพินัยกรรมฉบับนี้ได้ทำพินัยกรรม และพิมพ์นิ้วมือต่อหน้าข้าพเจ้า
          และได้สังเกตเห็นว่าผู้ทำพินัยกรรมมีสติสัมปชัญญะบริบูรณ์ทุกประการ
          ข้าพเจ้าจึงได้ลงลายมือชื่อเป็นพยานไว้ในพินัยกรรม
        </div>

        <div className="text-right">
          ลงชื่อ .............................................. พยาน
          <div>(                                   )</div>
        </div>
        <div className="text-right">
          ลงชื่อ  ............................................พยานและผู้เขียน
          <div>(                                   )</div>
        </div>
      </div>
    </div>
  );
};

export const FormLegacyNormalTwo = () => {
  return (
    <div className=" bg-gray40 w-full h-full p-5 text-black font-medium text-[11px] space-y-[6px] ">
      <div className=" text-center">
        ตัวอย่างแบบฟอร์มพินัยกรรมแบบธรรมดา แบบที่ 2
      </div>
      <div className=" text-center">พินัยกรรม</div>
      <div className=" text-right">
        ทำที่ ..................................
        <div>
          วันที่ ................ เดือน ....................... พ.ศ. .........
        </div>
      </div>
      <div className=" space-y-3">
        <div>
          <div className="indent-8">
            ข้าพเจ้า .................................... อายุ .........ปี
          </div>
          <div>
            อยู่บ้านเลขที่ ...................หมู่ที่
            ...............ถนน............................
          </div>
          <div>
            ตำบล/แขวง ...............................อำเภอ/เขต
            ....................... จังหวัด .................................
          </div>
        </div>
        <div className="indent-8">
          ได้ทำพินัยกรรมฉบับนี้ขึ้นไว้ เพื่อแสดงเจตนาว่า
          เมื่อข้าพเจ้าถึงแก่กรรมให้แบ่งทรัพย์สินของข้าพเจ้าทั้งหมด
          ทั้งที่มีอยู่ในปัจจุบัน
          และที่จะมีต่อไปในอนาคตให้แก่บุคคลที่มีชื่อ ต่อไปนี้ คนละหนึ่งส่วนเท่า
          ๆ กันคือ
        </div>
        <div className="">
          <div>
            1.
            ..........................................................................................
          </div>
          <div>
            2.
            ..........................................................................................
          </div>
          <div>
            3.
            ..........................................................................................
          </div>
          <div>
            4.
            ..........................................................................................
          </div>
        </div>
        <div className="indent-8">
          และขอให้ ....................เป็นผู้จัดการมรดกของข้าพเจ้าเพื่อจัดการ
          แบ่งปันทรัพย์มรดกให้เป็นไปตามเจตนาของข้าพเจ้า
        </div>
        <div className="indent-8">
          ข้าพเจ้าเห็นว่าพินัยกรรมฉบับนี้มีข้อความถูกต้องตรงตามเจตนาของ
          ข้าพเจ้าแล้ว จึงได้ลงลายมือชื่อไว้เป็นสำคัญต่อหน้าพยาน
        </div>
        <div className="text-right">
          ลงชื่อ .............................................. ผู้ทำพินัยกรรม
          <div>( ลายพิมพ์นิ้วมือของ                                  )</div>
        </div>
        <div className="indent-8">
          ข้าพเจ้าผู้มีนามข้างท้ายนี้ขอรับรองว่า
          .....................................
          ผู้ทำพินัยกรรมฉบับนี้ได้ทำพินัยกรรมต่อหน้าข้าพเจ้า และได้สังเกตเห็นว่า
          ผู้ทำพินัยกรรมมีสติสัมปชัญญะบริบูรณ์ทุกประการข้าพเจ้าจึงได้ลงลายมือ
          ชื่อเป็นพยานไว้ในพินัยกรรม
        </div>

        <div className="text-right">
          ลงชื่อ .............................................. พยาน
          <div>(                                   )</div>
        </div>
        <div className="text-right">
          ลงชื่อ  ............................................พยานและผู้เขียน
          <div>(                                   )</div>
        </div>
        <div>
          <div>ข้อสังเกตในการทำพินัยกรรมแบบธรรมดา</div>
          <div>
            1. การทำพินัยกรรมแบบนี้ จะทำโดยใช้วิธีเขียนหรือพิมพ์ก็ได้
            หากใช้วิธีเขียน ก็ต้องเขียนทั้งฉบับ
            หากใช้วิธีพิมพ์ก็ต้องพิมพ์ทั้งฉบับ{" "}
          </div>
          <div>
            2. ผู้เขียนหรือผู้พิมพ์พินัยกรรม ใครจะเป็นผู้เขียน หรือผู้พิมพ์ก็ได้
            แต่ในการเขียนต้องใช้คน ๆ เดียวเขียนพินัยกรรมทั้งฉบับ
            และในการพิมพ์ก็ต้องใช้เครื่องพิมพ์เครื่องเดียวกันทั้งฉบับ
            เพื่อมิให้เกิด ปัญหาแก่ทายาทเมื่อเจ้ามรดกตายแล้ว
          </div>
          <div>
            3. ต้องลงวันเดือนปีขณะที่ทำพินัยกรรม
            ถ้าไม่ลงวันเดือนปีที่ทำพินัยกรรม
            แล้วย่อมไม่ถือว่าเป็นพินัยกรรมตามกฎหมาย
          </div>
          <div>
            4.ผู้ทำพินัยกรรมต้องลงลายมือชื่อในพินัยกรรมต่อหน้าพยานอย่างน้อย
            สองคนพร้อมกัน
          </div>
          <div>
            5. พยานทั้งสองคนตามข้อ 4 ต้องลงลายมือชื่อรับรองลายมือชื่อของ
            ผู้ทำพินัยกรรมต่อหน้าผู้ทำพินัยกรรมและต่อหน้าพยานด้วยกัน
            ในขณะนั้นด้วย
          </div>
          <div>
            6. พยานในพินัยกรรม จะต้องบรรลุนิติภาวะแล้วคืออายุครบ 20 ปีบริบูรณ์
            หรือกรณีสมรสกันเมื่ออายุครบ 17 ปีบริบูรณ์
            กฎหมายก็ถือว่าบรรลุนิติภาวะ แล้ว แม้อายุยังไม่ครบ 20 ปี บริบูรณ์
            และพยานดังกล่าวต้องไม่เป็น
            บุคคลวิกลจริตหรือบุคคลที่ศาลสั่งให้เป็นคนเสมือนไร้ความสามารถทั้งต้อง
            ไม่เป็นคนหูหนวก เป็นใบ้หรือตาบอดทั้งสองข้าง
          </div>
          <div>
            7.ผู้เขียนและพยานในพินัยกรรมทั้งคู่สมรถของผู้เขียนและพยาน
            ในพินัยกรรม จะเป็นผู้รับทรัพย์ตามพินัยกรรมไม่ได
          </div>
        </div>
      </div>
    </div>
  );
};

export const FormLegacyNormalThree = () => {
  return (
    <div className=" bg-gray40 w-full h-full p-5 text-black font-medium text-[11px] space-y-[6px] ">
      <div className=" text-center">
        ตัวอย่างแบบฟอร์มพินัยกรรมแบบธรรมดา แบบที่ 3
      </div>
      <div className=" text-center">พินัยกรรม</div>
      <div className=" text-right">
        ทำที่ ..................................
        <div>
          วันที่ ................ เดือน ....................... พ.ศ. .........
        </div>
      </div>
      <div className=" space-y-3">
        <div>
          <div className="indent-8">
            ข้าพเจ้า .................................... อายุ .........ปี
          </div>
          <div>
            อยู่บ้านเลขที่ ...................หมู่ที่
            ...............ถนน............................
          </div>
          <div>
            ตำบล/แขวง ...............................อำเภอ/เขต
            ....................... จังหวัด .................................
          </div>
        </div>
        <div className="indent-8">
          ได้ทำพินัยกรรมฉบับนี้ขึ้นไว้ เพื่อแสดงเจตนาว่า เมื่อข้าพเจ้าถึงแก่กรรม
          ให้แบ่งทรัพย์สินต่าง ๆ ของข้าพเจ้าให้บุคคลดังต่อไปนี้
        </div>

        <div>
          1.ที่ดินโฉนดเลขที่..................พร้อมสิ่งปลูกสร้างอยู่ที่ตำบล.................
          อำเภอ ..................จังหวัด ............................
          ขอมอบให้แก่ .........................................
        </div>
        <div>
          2.
          เงินสดจำนวน..........................บาทซึ่งฝากไว้ที่ธนาคาร......................
          สาขา ............... ตามสมุดเงินฝากประเภท ............................
          หมายเลขบัญชี ................................... มอบให้แก่
          ...............................
        </div>
        <div>
          3. เครื่องเพชร พลอย ทอง และขอให้
          ................................เป็นผู้จัดการ
          มรดกของข้าพเจ้าเพื่อจัดการแบ่งปันทรัพย์มรดกให้เป็นไป
          ตามเจตนาของข้าพเจ้า
        </div>
        <div className="indent-8">
          ข้าพเจ้าเห็นว่าพินัยกรรมฉบับนี้มีข้อความถูกต้องตรงตามเจตนา
          ของข้าพเจ้าแล้ว จึงได้ลงลายมือชื่อไว้เป็นสำคัญต่อหน้าพยาน
        </div>
        <div className="text-right">
          ลงชื่อ .............................................. ผู้ทำพินัยกรรม
          <div>( ลายพิมพ์นิ้วมือ                                  )</div>
        </div>
        <div className="indent-8">
          ข้าพเจ้าผู้มีนามข้างท้ายนี้ขอรับรองว่า ....................
          ผู้ทำพินัยกรรมฉบับนี้ได้ทำพินัยกรรม และพิมพ์นิ้วมือต่อหน้าข้าพเจ้า
          และได้สังเกตเห็นว่าผู้ทำพินัยกรรมมีสติสัมปชัญญะบริบูรณ์ทุกประการ
          ข้าพเจ้าจึงได้ลงลายมือชื่อเป็นพยานไว้ในพินัยกรรม
        </div>

        <div className="text-right">
          ลงชื่อ .............................................. พยาน
          <div>(                                   )</div>
        </div>
        <div className="text-right">
          ลงชื่อ  ............................................พยานและผู้เขียน
          <div>(                                   )</div>
        </div>
      </div>
    </div>
  );
};
