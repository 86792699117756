import { useCallback, useEffect, useRef, useState } from "react";
import classnames from "classnames";
import "./styleDefault.css";
import ReactSlider from "react-slider";
export const MiltiSlider = ({ current, retire, onChange, dead }) => {
  const [value, setValue] = useState([current, retire, dead]);
  return (
    <div className=" flex gap-1 items-center w-full justify-center">
      {/* <div className=" text-bluegray50 font-semibold text-xl font-IBMPlexSansThai">
        0
      </div> */}
      <ReactSlider
        value={[current, retire, dead]}
        className="horizontal-slider"
        thumbClassName="example-thumb"
        trackClassName="example-track"
        defaultValue={[current, retire, dead]}
        // onAfterChange={[onChangeCurrent, onChangeRetire, onChangeDead]}
        ariaLabel={["Leftmost thumb", "Middle thumb", "Rightmost thumb"]}
        renderThumb={(props, state) => <div {...props}>{state?.valueNow}</div>}
        pearling
        minDistance={1}
        min={18}
        max={120}
        onChange={onChange}
      />

      {/* <div className=" ml-1 text-mainblue font-semibold text-sm font-IBMPlexSansThai">
        120
        <input
          className=" w-10 px-1 py-[7px] text-mainblue placeholder:text-maingray font-semibold text-sm font-IBMPlexSansThai shadowinner rounded-md text-center focus:outline-none"
          value={120}
          onChange={onChangeMaxDead}
        />
      </div> */}
    </div>
  );
};
