export const calPMT = (rate, nperiod, pv, fv, type) => {
  if (!fv) fv = 0;
  if (!type) type = 0;

  if (rate === 0) return -(pv + fv) / nperiod;

  var pvif = Math.pow(1 + rate, nperiod);
  var pmt = (rate / (pvif - 1)) * -(pv * pvif + fv);

  if (type === 1) {
    pmt /= 1 + rate;
  }

  return pmt;
};

export const calMonthlyPayment = (
  loanAmount,
  monthlyInterestRate,
  numberOfPayments
) => {
  const numerator =
    loanAmount *
    (monthlyInterestRate * Math.pow(1 + monthlyInterestRate, numberOfPayments));
  const denominator = Math.pow(1 + monthlyInterestRate, numberOfPayments) - 1;
  return numerator / denominator;
};

export function calPMTFVA(FVA, r, n) {
  const PMT = (FVA * r) / (Math.pow(1 + r, n) - 1);
  return PMT;
}
