import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

const familyStore = (set) => ({
  ishasIncome: true,
  setIshasIncome: (ishasIncome) => set(() => ({ ishasIncome })),

  isMarriage: true,
  setIsMarriage: (isMarriage) => set(() => ({ isMarriage })),

  isSpouseDisable: true,
  setIsSpouseDisable: (isSpouseDisable) => set(() => ({ isSpouseDisable })),

  ishasChild: false,
  setIshasChild: (ishasChild) => set(() => ({ ishasChild })),
  chlidList: [],
  setChildList: (chlidList) => set(() => ({ chlidList })),
});

export const useFamilyStore = create(
  persist(familyStore, {
    name: "familyStore",
    storage: createJSONStorage(() => localStorage), // (optional) by default the 'localStorage' is used
  })
);
