import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

const store = (set) => ({
  riskData: null,
  setRiskData: (riskData) => set(() => ({ riskData })),
  clearRiskData: () => set(() => ({ riskData: null })),
});

export const useRiskStore = create(
  persist(store, {
    name: "riskStore",
    storage: createJSONStorage(() => localStorage), // (optional) by default the 'localStorage' is used
  })
);
