export const STYLE_PAGE_LANDING = `page w-[595px] h-[842px] mx-auto box-border bg-white `;
export const STYLE_PAGE = `page w-[595px] h-[842px] mx-auto box-border border-0 bg-white px-6 py-6 font-['IBM Plex Sans Thai'] relative`;
export const STYLE_PAGE_HORIZONTAL = `page page-horizontal w-[842px] h-[595px] mx-auto box-border border-0 bg-white px-6 py-6 font-['IBM Plex Sans Thai'] relative`;
export const STYLE_PDF_CONTENT =
  "flex flex-col justify-center items-center space-y-2 box-border bg-black border-0";

export const STYLE_PDF_ITEM_TOTAL = "text-mainblue font-[600] text-[12px]";
export const STYLE_PDF_TEXT_MEDIUM_11 = "text-[11px] font-[500]";
export const STYLE_PDF_TEXT_MEDIUM_12 = "text-[12px] font-[500]";
export const STYLE_PDF_TEXT_MEDIUM_13 = "text-[13px] font-[500]";
export const STYLE_PDF_TEXT_NORMAL_11 = "text-[11px] font-[400]";
export const STYLE_PDF_TEXT_NORMAL_10 = "text-[10px] font-[400]";
export const STYLE_PDF_TEXT_NORMAL_12 = "text-[12px] font-[400]";
export const STYLE_PDF_TEXT_NORMAL_GRAY_11 =
  "text-[11px] font-[400] text-[#00A9A0]";
export const STYLE_PDF_TEXT_NORMAL_GRAY_13 =
  "text-[11px] font-[400] text-[#00A9A0]";
export const STYLE_PDF_TEXT_NORMAL_BLUE_11 =
  "text-[11px] font-[400]  text-[#18488A]";
export const STYLE_PDF_TEXT_NORMAL_BLUE_12 =
  "text-[12px] font-[400] text-[#18488A]";
export const STYLE_PDF_TEXT_NORMAL_BLUE_13 =
  "text-[12px] font-[400] text-[#18488A]";
export const STYLE_PDF_TEXT_NORMAL_BLUE_10 = "text-[10px] font-[400]";
export const STYLE_PDF_TEXT_SEMIBOLD_BLUE_14 =
  "text-[14px] font-[600] text-[#18488A]";
export const STYLE_PDF_TEXT_SEMIBOLD_BLUE_12 =
  "text-[12px] font-[600] text-[#18488A]";
export const STYLE_PDF_TEXT_MEDIUM_BLUE_11 =
  "text-[11px] font-[500] text-[#18488A]";
export const STYLE_PDF_TEXT_MEDIUM_BLUE_12 =
  "text-[12px] font-[500] text-[#18488A]";
export const STYLE_PDF_TEXT_MEDIUM_BLUE_13 =
  "text-[12px] font-[500] text-[#18488A]";
export const STYLE_PDF_TEXT_MEDIUM_GRAY_11 =
  "text-[11px] font-[500] text-[#7E8393]";
  export const STYLE_PDF_TEXT_MEDIUM_GRAY_13 =
  "text-[11px] font-[500] text-[#7E8393]";
export const STYLE_PDF_BORDER_TABLE =
  "border-b border-b-mainblue border-r border-r-[#E0DFDF] p-2";
