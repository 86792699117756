import { cn, mapStatus, toFloat2 } from "../../../utils/useFunctions";
import { PdfCard, PdfCardGray, PdfCardWhite } from "../component/pdf.c.card";
import PdfHeader from "../component/pdf.c.header";
import {
  PdfHeaderCardText,
  PdfHeaderText,
} from "../component/pdf.c.headerText";
import PdfLine from "../component/pdf.c.line";
import {
  STYLE_PAGE,
  STYLE_PDF_TEXT_MEDIUM_11,
  STYLE_PDF_TEXT_MEDIUM_12,
  STYLE_PDF_TEXT_MEDIUM_13,
  STYLE_PDF_TEXT_MEDIUM_BLUE_13,
  STYLE_PDF_TEXT_NORMAL_10,
  STYLE_PDF_TEXT_NORMAL_11,
} from "../styles/pdf.style";
import { useBalanceStore } from "../../../_store/balanceStore";
import numeral from "numeral";
import { useMemo } from "react";
import HalfChartMain from "../../../components/chart/HalfChartMain";
import {
  ICON_BAHT,
  ICON_CHECK,
  ICON_CLOSE,
  ICON_GRAPH,
  ICON_SAVING,
  ICON_WARNING,
} from "../../../utils/useIcons";
import { STYLE_CARD_BG } from "../../../utils/useStyle";
import PdfFooter from "../component/pdf.c.footer";
import LineChartMain from "../../../components/chart/LineChartMain";
import { ImageMain } from "../../../components/img/Img.Main";
import {
  DonutChartDebt,
  DonutChartEmpty,
  DonutChartIncome,
  DonutChartInvestMain,
  DonutChartInvestMain3,
} from "../../../components/chart/DonutChartMain";
import HorizonLineChartMain from "../../../components/chart/HorizonLineChartMain";
import { avgWeight } from "../../retirement_plan/functions/functionMain";
import {
  lastResultInvestmentAssetsAFSensitive,
  loopInvestmentAssetsAFSensitive,
} from "../../retirement_plan/functions/functionRetrementSensitivePlan";
import { calPMT } from "../../education/functions/function";
import {
  dataAgeAF,
  loopInvestmentAssets,
  loopInvestmentAssetsAF,
} from "../../retirement_plan/functions/functionRetriementPlan";
import { calculateTax, checkSum } from "../../tax_plan/functions";
import { useStrategyStore } from "../../../_store/strategyRetireStore";
import { useTaxStore } from "../../../_store/taxplanStore";
import { useRetireStore } from "../../../_store/retireStore";
import _, { get, sum } from "lodash";
import { key_property_invest_category } from "../../balance/functions";
import FamilyBarChart from "../component/pdf.c.familyChart";
import { useInsuranceStore } from "../../../_store/insuranceStore";
import { useFuturePlanStore } from "../../../_store/futurePlanStore";
import { ChartCashFlow } from "../../future_plan/components/VIChartCashFlow.Main";
import { useEducationStore } from "../../../_store/educationStore";
import { useDebtStore } from "../../../_store/debtplanStore";
import { useUserStore } from "../../../_store/userStore";
import { DateTime } from "luxon";

function PdfPageOVDebtOne({ page }) {
  const futurePlanData = useFuturePlanStore((state) => state.futurePlanData);
  const educationData = useEducationStore((state) => state.educationData);
  const balanceData = useBalanceStore((state) => state.balanceData);
  const debtData = useDebtStore((state) => state.debtData);
  const userData = useUserStore((state) => state.userData);
  const retireData = useRetireStore((state) => state.retireData);

  const createSumObject = (sum_per_month, sum_per_year, sum_percent) => ({
    sum_per_month: sum_per_month || 0,
    sum_per_year: sum_per_year || 0,
    sum_percent: sum_percent || 0,
  });

  const sumLong = useMemo(() => {
    return createSumObject(
      balanceData?.sum_debt_long_permonth,
      balanceData?.sum_debt_long,
      (balanceData?.sum_debt_long / balanceData?.debt_propotion) * 100
    );
  }, [balanceData]);

  const sumShort = useMemo(() => {
    return createSumObject(
      balanceData?.sum_debt_short_permonth,
      balanceData?.sum_debt_short,
      (balanceData?.sum_debt_short / balanceData?.debt_propotion) * 100
    );
  }, [balanceData]);

  const sumTotal = useMemo(
    () => sumLong.sum_per_year + sumShort.sum_per_year,
    [sumLong, sumShort]
  );

  const items = useMemo(() => {
    return [
      {
        title: "หนี้สินระยะสั้น",
        icon: "credit_card.svg",
        ...sumShort,
      },
      {
        title: "หนี้สินระยะยาว",
        icon: "home_loan.svg",
        ...sumLong,
      },
    ];
  }, [sumTotal]);

  return (
    <div
      style={{
        backgroundImage: `url(/images/bg_pdf.svg)`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "bottom right",
      }}
      className={cn(STYLE_PAGE)}
    >
      <PdfHeader />
      <PdfLine />
      <PdfHeaderText title="สัดส่วนหนี้สินของคุณ" icon="/images/cash.svg" />
      <PdfCard>
        <PdfCardWhite>
          {/* <div className={cn("text-center", STYLE_PDF_TEXT_MEDIUM_12)}>
            คุณมีภาระหนี้สินเฉลี่ยต่อเดือน
          </div> */}

          <div className="">
            {balanceData?.debt_propotion <= 0 ||
            balanceData?.debt_propotion === undefined ? (
              <DonutChartEmpty text={`รายได้`} />
            ) : (
              <DonutChartDebt
                text={`หนี้สิน`}
                value={numeral(balanceData?.debt_propotion).format("0,0")}
                // defalut={getDebtBalance > 0 ? 0 : 100}
                data1={balanceData?.sum_debt_short}
                data2={balanceData?.sum_debt_long}
                classNameGray={"bg-white"}
                isRemoveGray
                isRemoveLegend
                classNameDonut={"w-[200px] top-4 mb-2"}
                classNameText={cn("text-lg")}
                classNameValue={cn("text-lg")}
              />
            )}
          </div>

          <div
            className={cn(
              "grid grid-cols-3 px-3 items-center",
              STYLE_PDF_TEXT_MEDIUM_12
            )}
          >
            <div>หนี้สิน</div>
            {/* <div className={cn("text-right")}>ต่อเดือน (บาท)</div> */}
            <div className={cn("text-right")}>จำนวนเงินค้างชำระ (บาท)</div>
            <div className={cn("text-right")}>%</div>
          </div>
          <PdfLine className={"mb-1 mt-1"} />

          <div className="flex flex-col space-y-2">
            {items.map((item, index) => {
              return (
                <div
                  key={index}
                  className={cn(
                    "grid grid-cols-3 px-3 items-center",
                    STYLE_PDF_TEXT_MEDIUM_12
                  )}
                >
                  <div className="flex space-x-2">
                    <div>
                      <img
                        className={cn(item?.classNameIcon)}
                        src={`/images/${item.icon}`}
                      />
                    </div>
                    <div>{item.title}</div>
                  </div>
                  {/* <div className="text-right">
                    {numeral(item.sum_per_month).format("0,0")}
                  </div> */}
                  <div className="text-right">
                    {numeral(item.sum_per_year).format("0,0")}
                  </div>
                  <div className="text-right">
                    {numeral(item.sum_percent).format("00.00")}
                  </div>
                </div>
              );
            })}
          </div>

          <div className="flex justify-center items-center space-x-1 mt-2">
            <div className={cn(STYLE_PDF_TEXT_MEDIUM_13)}>รวมหนี้สินทั้งหมด</div>
            <div className={cn(STYLE_PDF_TEXT_MEDIUM_BLUE_13)}>
              {numeral(sumTotal).format("0,0")}
            </div>
            <div className={cn(STYLE_PDF_TEXT_MEDIUM_BLUE_13)}>บาท</div>
          </div>
        </PdfCardWhite>
      </PdfCard>
      <PdfFooter page={page} />
    </div>
  );
}

export default PdfPageOVDebtOne;
