import { CardTips } from "../../../components/card/CardMain";
import { ImageMain } from "../../../components/img/Img.Main";
import { ICON_LINK, ICON_LOGIN } from "../../../utils/useIcons";
import { STYLE_TEXT_BLUE } from "../../../utils/useStyle";

const ViewFooterDebt = (props) => {
  return (
    <div className=" space-y-2.5">
      <div className=" text-sm text-black text-center">
        หากคุณเป็นพนักงานในองค์กรและบริษัทยินยอม
        <div>ให้ทำระบบหักเงินเดือนเพื่อชำระหนี้สถาบันการเงิน</div>
        <div>เราให้สินเชื่อส่วนบุคคลพิเศษ</div>
      </div>
      <div className=" flex gap-1 justify-center text-mainblue ">
        <ICON_LINK className={` w-5 h-5`} />
        <div
          onClick={() => {
            window?.open(
              "https://liff.line.me/1645278921-kWRPP32q/?accountId=assurewealth"
            );
          }}
          className=" text-sm underline underline-offset-2 hover:font-semibold duration-100"
        >
          ติดต่อเรา
        </div>
      </div>
      <div className=" space-y-3">
        <ImageMain
          src="../images/money_hand.png"
          className=" w-[60px] h-[60px] mx-auto"
        />
        <div className=" text-xs text-maingray text-center">
          เป็นหนี้เสียบัตรเครดิต บัตรกดเงินสดหรือสินเชื่อส่วนบุคคล
          <div>
            ที่ไม่มีหลักประกันของสถาบันที่เข้าร่วมโครงการ{" "}
            <span className=" font-semibold text-black">แล้วทำอย่างไรดี ?</span>
          </div>
        </div>
        <div className={`${STYLE_TEXT_BLUE} font-semibold text-center`}>
          ปรึกษาคลินิคแก้หนี้ “ CLINIC SAM”
        </div>
        <div className=" flex gap-1 justify-center text-mainblue    ">
          <ICON_LINK className={` w-5 h-5`} />
          <div
            onClick={() => {
              window?.open("https://www.debtclinicbysam.com");
            }}
            className=" text-sm underline underline-offset-2 hover:font-semibold duration-100"
          >
            www.debtclinicbysam.com
          </div>
        </div>

        <CardTips
          title="คุณสมบัติผู้สมัคร"
          panel={
            <div className=" space-y-1">
              <div>1. เป็นบุคคลธรรมดาที่มีรายได้ อายุไม่เกิน 70 ปี</div>
              <div>
                2. เป็นหนี้เสียบัตรเครดิต บัตรกดเงินสด หรือ
                สินเชื่อส่วนบุคคลที่ไม่มี
              </div>
              <div>3. เป็นหนี้ค้างชำระ (NPL) มากว่า 120 วัน</div>
              <div>4. หนี้รวมไม่เกิน 2ล้านบาท</div>
              <div>5. ไม่เป็นบุคคลล้มละลาย</div>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default ViewFooterDebt;
