import { useMemo } from "react";
import { useBalanceStore } from "../../../_store/balanceStore";
import { cn, toFloat2 } from "../../../utils/useFunctions";
import {
  PdfBalanceHeaderItemCard,
  PdfBalanceItemCard,
  PdfCard,
  PdfCardGray,
  PdfCardWhite,
  PdfFamilyHeaderItemCard,
} from "../component/pdf.c.card";
import PdfHeader from "../component/pdf.c.header";
import {
  PdfHeaderCardText,
  PdfHeaderText,
} from "../component/pdf.c.headerText";
import PdfLine from "../component/pdf.c.line";
import {
  STYLE_PAGE,
  STYLE_PDF_ITEM_TOTAL,
  STYLE_PDF_TEXT_MEDIUM_11,
  STYLE_PDF_TEXT_MEDIUM_12,
  STYLE_PDF_TEXT_MEDIUM_13,
  STYLE_PDF_TEXT_MEDIUM_BLUE_11,
  STYLE_PDF_TEXT_MEDIUM_BLUE_12,
  STYLE_PDF_TEXT_NORMAL_10,
  STYLE_PDF_TEXT_NORMAL_11,
  STYLE_PDF_TEXT_NORMAL_BLUE_10,
  STYLE_PDF_TEXT_NORMAL_BLUE_11,
  STYLE_PDF_TEXT_NORMAL_BLUE_12,
  STYLE_PDF_TEXT_SEMIBOLD_BLUE_12,
  STYLE_PDF_TEXT_SEMIBOLD_BLUE_14,
} from "../styles/pdf.style";
import {
  key_property_invest_category,
  options_property_asset,
  options_property_intangible,
  options_property_invest,
  options_property_private,
} from "../../balance/functions";
import _ from "lodash";
import numeral from "numeral";
import {
  calPropertyLocalOption,
  getAllBalanceTotal,
  getBalanceItems,
} from "../lib/pdf.lib";
import PdfFooter from "../component/pdf.c.footer";
import { useFuturePlanStore } from "../../../_store/futurePlanStore";
import FamilyBarChart from "../component/pdf.c.familyChart";
import ViewTableFamily from "../../insuarance_plan/component/VITableFamily.Main";
import { StatusCard } from "../../insuarance_plan/styles/Card";
import { ImageMain } from "../../../components/img/Img.Main";
import { DonutChartInvestMain } from "../../../components/chart/DonutChartMain";
import LineChartSavingMain from "../../saving_plan/components/LineChartSaving.Main";
import { useSavingStore } from "../../../_store/savingStore";

function PdfPageFutureTwo({page}) {
  const futurePlanData = useFuturePlanStore((state) => state.futurePlanData);

  const futureExpensesItems = useMemo(() => {
    const newFutureItems = [];
    const mergeItems = [
      {
        key: "ท่องเที่ยว",
        label: "ท่องเที่ยว",
        icon: "car_tour.png",
        title: "-",
        year: "-",
        amount: "",
      },
      {
        key: "วางแผนมีลูก",
        label: "วางแผนมีลูก",
        icon: "baby.png",
        title: "",
        year: "",
        amount: "",
      },
      {
        key: "ซื้อของสะสม",
        label: "ซื้อของสะสม",
        icon: "buy.png",
        title: "",
        year: "",
        amount: "",
      },
      {
        key: "อื่น ๆ",
        label: "อื่น ๆ",
        icon: "smile_open.png",
        title: "",
        year: "",
        amount: "",
      },
    ];

    if (futurePlanData?.expenese_travel_items?.length > 0) {
      const findIndex = mergeItems.findIndex(
        (item) => item.key === "ท่องเที่ยว"
      );
      mergeItems[findIndex] = {
        ...mergeItems[findIndex],
        ...futurePlanData?.expenese_travel_items[0],
      };
    }
    if (futurePlanData?.expenese_children_items?.length > 0) {
      const findIndex = mergeItems.findIndex(
        (item) => item.key === "วางแผนมีลูก"
      );
      mergeItems[findIndex] = {
        ...mergeItems[findIndex],
        ...futurePlanData?.expenese_children_items[0],
      };
    }
    if (futurePlanData?.expenese_collection_items?.length > 0) {
      const findIndex = mergeItems.findIndex(
        (item) => item.key === "ซื้อของสะสม"
      );
      mergeItems[findIndex] = {
        ...mergeItems[findIndex],
        ...futurePlanData?.expenese_collection_items[0],
      };
    }
    if (futurePlanData?.expenese_other_items?.length > 0) {
      const findIndex = mergeItems.findIndex((item) => item.key === "อื่น ๆ");
      mergeItems[findIndex] = {
        ...mergeItems[findIndex],
        ...futurePlanData?.expenese_other_items[0],
      };
    }

    if (mergeItems.length > 0) {
      for (const mergeItem of mergeItems) {
        newFutureItems.push({
          label: mergeItem.label,
          icon: mergeItem.icon,
          ...mergeItem,
          options: [
            {
              label: `ชื่อรายการ${mergeItem.key}`,
              value: `${mergeItem.title}`,
            },
            {
              label: "คาดการณ์ไว้อีก",
              value: `${mergeItem.year} ปี`,
            },
          ],
        });
      }
    }
    return newFutureItems;
  }, [futurePlanData]);

  return (
    <div className={cn(STYLE_PAGE)}>
      <PdfHeader />
      <PdfLine />
      <PdfHeaderText
        title="แผนอนาคต"
        icon="/images/personal.svg"
        classNameIcon="w-[24px] h-[27px]"
      />
      {/* แผนอนาคตรายจ่าย */}
      <PdfCard className={" grid grid-cols-2 gap-3"} title={"รายจ่ายในอนาคต"}>
        {futureExpensesItems.map((item, index) => {
          return (
            <PdfCardWhite
              key={index}
              className={cn("flex flex-col space-y-2 px-3")}
            >
              <div className={cn(STYLE_PDF_TEXT_MEDIUM_12, "text-left")}>
                รายการ
              </div>
              <PdfLine />
              <div className="flex items-center space-x-2">
                <div>
                  <img
                    src={`/images/${item.icon}`}
                    className="w-[20px] h-[20px]"
                  />
                </div>
                <div className={cn(STYLE_PDF_TEXT_MEDIUM_11)}>{item.label}</div>
              </div>
              <PdfCardGray className={cn("px-2")}>
                {item.options.map((item, index) => {
                  return (
                    <div key={index} className="flex justify-between space-y-1">
                      <div className={cn(STYLE_PDF_TEXT_MEDIUM_11)}>
                        {item.label}
                      </div>
                      <div
                        className={cn(
                          STYLE_PDF_TEXT_MEDIUM_BLUE_11,
                          "flex space-x-2"
                        )}
                      >
                        {item.value}
                      </div>
                    </div>
                  );
                })}
              </PdfCardGray>
              <div className={cn("mt-2 flex justify-between")}>
                <div className={cn(STYLE_PDF_TEXT_MEDIUM_12)}>
                  ต้องชำระงวดละ
                </div>
                <div className={cn(STYLE_PDF_TEXT_MEDIUM_BLUE_12)}>
                  {numeral(item.amount).format("0,0")} บาท
                </div>
              </div>
            </PdfCardWhite>
          );
        })}
      </PdfCard>
      <PdfFooter page={page}/>
    </div>
  );
}

export default PdfPageFutureTwo;
