import { useMemo } from "react";
import { useBalanceStore } from "../../../_store/balanceStore";
import { cn, toFloat2 } from "../../../utils/useFunctions";
import {
  PdfBalanceHeaderItemCard,
  PdfBalanceItemCard,
  PdfCard,
  PdfCardGray,
  PdfCardWhite,
  PdfFamilyHeaderItemCard,
} from "../component/pdf.c.card";
import PdfHeader from "../component/pdf.c.header";
import {
  PdfHeaderCardText,
  PdfHeaderText,
} from "../component/pdf.c.headerText";
import PdfLine from "../component/pdf.c.line";
import {
  STYLE_PAGE,
  STYLE_PAGE_HORIZONTAL,
  STYLE_PDF_BORDER_TABLE,
  STYLE_PDF_ITEM_TOTAL,
  STYLE_PDF_TEXT_MEDIUM_11,
  STYLE_PDF_TEXT_MEDIUM_12,
  STYLE_PDF_TEXT_MEDIUM_13,
  STYLE_PDF_TEXT_MEDIUM_BLUE_11,
  STYLE_PDF_TEXT_MEDIUM_BLUE_12,
  STYLE_PDF_TEXT_MEDIUM_GRAY_13,
  STYLE_PDF_TEXT_NORMAL_10,
  STYLE_PDF_TEXT_NORMAL_11,
  STYLE_PDF_TEXT_NORMAL_12,
  STYLE_PDF_TEXT_NORMAL_BLUE_10,
  STYLE_PDF_TEXT_NORMAL_BLUE_11,
  STYLE_PDF_TEXT_NORMAL_BLUE_12,
  STYLE_PDF_TEXT_NORMAL_BLUE_13,
  STYLE_PDF_TEXT_NORMAL_GRAY_11,
  STYLE_PDF_TEXT_NORMAL_GRAY_13,
  STYLE_PDF_TEXT_SEMIBOLD_BLUE_12,
  STYLE_PDF_TEXT_SEMIBOLD_BLUE_14,
} from "../styles/pdf.style";
import {
  key_property_invest_category,
  options_property_asset,
  options_property_intangible,
  options_property_invest,
  options_property_private,
} from "../../balance/functions";
import _ from "lodash";
import numeral from "numeral";
import {
  calPropertyLocalOption,
  getAllBalanceTotal,
  getBalanceItems,
} from "../lib/pdf.lib";
import PdfFooter from "../component/pdf.c.footer";
import { useFuturePlanStore } from "../../../_store/futurePlanStore";
import FamilyBarChart from "../component/pdf.c.familyChart";
import ViewTableFamily from "../../insuarance_plan/component/VITableFamily.Main";
import { StatusCard } from "../../insuarance_plan/styles/Card";
import { ImageMain } from "../../../components/img/Img.Main";
import { DonutChartInvestMain } from "../../../components/chart/DonutChartMain";
import LineChartSavingMain from "../../saving_plan/components/LineChartSaving.Main";
import { useTaxStore } from "../../../_store/taxplanStore";
import HalfChartMain from "../../../components/chart/HalfChartMain";
import { calculateTax, checkSum } from "../../tax_plan/functions";
import { ICON_BAHT, ICON_GRAPH } from "../../../utils/useIcons";
import { STYLE_CARD_BG } from "../../../utils/useStyle";
import { useEducationStore } from "../../../_store/educationStore";
import {
  options_education_rank,
  options_under_education,
} from "../../education/functions/options";
import {
  LineChartEduExpensesMain,
  LineChartEduInvestMain,
} from "../../education/components/EduLineChart";
import ViewTableExpenses from "../../education/components/ViewTableExpenses";
import ViewTableExpensesPdf from "../../education/components/ViewTableExpensesPdf";

function PdfPageAppendixEducationOne({ page }) {
  const educationData = useEducationStore((state) => state.educationData);

  return (
    <div className="flex flex-col space-y-2">
      {educationData.map((educationItem, index) => {
        return (
          <div key={index} className="flex flex-col space-y-2">
            {/* INFO */}
            <div className={cn(STYLE_PAGE_HORIZONTAL)}>
              <ViewTableExpensesPdf index={index} localEducationData={educationItem} />
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default PdfPageAppendixEducationOne;
