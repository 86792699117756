import _ from "lodash";
import { useTaxStore } from "../../../_store/taxplanStore";
import { numberWithoutComma } from "../../../pages/balance/component/VIDrawerDebt.Main";

export const TabTwoSumIncomeCalculation = () => {
  const taxData = useTaxStore((state) => state.taxData);

  let salary_Income = numberWithoutComma(_.get(taxData, "tax_income", 0) || 0);
  let freeLance_Income = Number(taxData?.freelance?.tax_income || 0);
  let freeJob_Income = taxData?.freeJob?.reduce((total, num) => {
    return total + Number(num?.tax_income || 0);
  }, 0);

  let income_tax_403 = taxData?.tax_403?.reduce((total, num) => {
    return total + Number(num?.tax_income || 0);
  }, 0);

  let income_tax_405 = taxData?.tax_405?.reduce((total, num) => {
    return total + Number(num?.tax_income || 0);
  }, 0);

  let income_tax_407 = taxData?.tax_407?.reduce((total, num) => {
    return total + Number(num?.tax_income || 0);
  }, 0);

  let income_tax_408 = taxData?.tax_408?.reduce((total, num) => {
    return total + Number(num?.tax_income || 0);
  }, 0);

  let income_tax_408_property = taxData?.tax_408_property?.reduce(
    (total, num) => {
      return total + Number(num?.tax_income || 0);
    },
    0
  );

  let income_tax_404 = taxData?.tax_404?.reduce((total, num) => {
    return total + Number(num?.tax_income || 0);
  }, 0);

  // tax_404_stock
  let income_tax_404_stock = taxData?.tax_404_stock?.reduce((total, num) => {
    if (num?.type_job?.label === "หุ้น") {
      return total + Number(num?.dividend || 0) + Number(num?.tax_credit || 0);
    }
    return total + Number(num?.dividend || 0);
  }, 0);

  let income_not_include_tax = _.get(taxData, "income_not_include_tax", 0);

  let RMF_Profit =
    Number(taxData?.RMF_Profit?.selling_price || 0) -
    Number(taxData?.RMF_Profit?.cost_price || 0);
  let SSF_Profit =
    Number(taxData?.SSF_Profit?.selling_price || 0) -
    Number(taxData?.SSF_Profit?.cost_price || 0);

  // console.log("heritage", taxData?.heritage);
  let Income_42_26 = 0;
  let Income_42_27 = 0;
  let Income_42_28 = 0;

  if (!taxData?.heritage?.tax_calc_include) {
    Income_42_26 = Number(taxData?.heritage?.income_42_26 || 0);
    Income_42_27 = Number(taxData?.heritage?.income_42_27 || 0);
    Income_42_28 = Number(taxData?.heritage?.income_42_28 || 0);
  }

  let tax408_property = taxData?.tax_408_property?.reduce((total, num) => {
    // if (num?.calculating_way === "ไม่แยกคำนวณ") {
    //   return total + Number(num?.tax_income || 0);
    // }
    // return total;

    return total + Number(num?.tax_income || 0);
  }, 0);

  return (
    Number(salary_Income || 0) +
    Number(freeJob_Income || 0) +
    Number(freeLance_Income || 0) +
    Number(income_tax_403 || 0) +
    Number(income_tax_405 || 0) +
    Number(income_tax_407 || 0) +
    Number(income_tax_408 || 0) +
    // income_tax_408_property +
    Number(income_tax_404 || 0) +
    Number(income_tax_404_stock || 0) +
    Number(income_not_include_tax || 0) +
    Number(RMF_Profit || 0) +
    Number(SSF_Profit || 0) +
    Number(Income_42_26 || 0) +
    Number(Income_42_27 || 0) +
    Number(Income_42_28 || 0) +
    Number(tax408_property || 0)
  );
};

export const TabTwoExpenseCalculation = () => {
  const taxData = useTaxStore((state) => state.taxData);

  let salary_expense =
    numberWithoutComma(_.get(taxData, "tax_income", 0) || 0) / 2 > 100000
      ? 100000
      : numberWithoutComma(_.get(taxData, "tax_income", 0) || 0) / 2;

  let freelance =
    numberWithoutComma(taxData?.freelance?.tax_income || 0) / 2 > 100000
      ? 100000
      : numberWithoutComma(taxData?.freelance?.tax_income || 0) / 2;

  //   วิชาชีพอิสระ
  let freeJob_expense = taxData?.freeJob?.reduce((total, num) => {
    if (num?.calculating_method === "หักแบบเหมา") {
      return (
        total +
        (Number(num?.tax_income || 0) -
          Number(num?.income_for_calculating_all_in || 0))
      );
    }
    if (num?.calculating_method === "หักตามจริง") {
      return (
        total +
        (Number(num?.tax_income || 0) -
          Number(num?.income_for_calculating || 0))
      );
    }
    return total + 0;
  }, 0);

  //   40(3)
  let expense_tax_403 = taxData?.tax_403?.reduce((total, num) => {
    if (num?.calculating_method === "หักแบบเหมา") {
      return (
        total +
        (Number(num?.tax_income || 0) -
          Number(num?.income_for_calculating_all_in || 0))
      );
    }
    if (num?.calculating_method === "หักตามจริง") {
      return (
        total +
        (Number(num?.tax_income || 0) -
          Number(num?.income_for_calculating || 0))
      );
    }
    return total + 0;
  }, 0);

  //   40(5)
  let expense_tax_405 = taxData?.tax_405?.reduce((total, num) => {
    if (num?.calculating_method === "หักแบบเหมา") {
      return (
        total +
        (Number(num?.tax_income || 0) -
          Number(num?.income_for_calculating_all_in || 0))
      );
    }
    if (num?.calculating_method === "หักตามจริง") {
      return (
        total +
        (Number(num?.tax_income || 0) -
          Number(num?.income_for_calculating || 0))
      );
    }
    return total + 0;
  }, 0);

  //   40(7)
  let expense_tax_407 = taxData?.tax_407?.reduce((total, num) => {
    if (num?.calculating_method === "หักแบบเหมา") {
      return (
        total +
        (Number(num?.tax_income || 0) -
          Number(num?.income_for_calculating_all_in || 0))
      );
    }
    if (num?.calculating_method === "หักตามจริง") {
      return (
        total +
        (Number(num?.tax_income || 0) -
          Number(num?.income_for_calculating || 0))
      );
    }
    return total + 0;
  }, 0);

  //   40(8)
  let expense_tax_408 = taxData?.tax_408?.reduce((total, num) => {
    if (num?.calculating_method === "หักแบบเหมา") {
      return (
        total +
        (Number(num?.tax_income || 0) -
          Number(num?.income_for_calculating_all_in || 0))
      );
    }
    if (num?.calculating_method === "หักตามจริง") {
      return (
        total +
        (Number(num?.tax_income || 0) -
          Number(num?.income_for_calculating || 0))
      );
    }
    return total + 0;
  }, 0);

  //   40(8)_property
  let expense_tax_408_property = taxData?.tax_408_property?.reduce(
    (total, num) => {
      if (num?.calculating_way === "ไม่แยกคำนวณ") {
        if (num?.calculating_method === "หักแบบเหมา") {
          return (
            total +
            (Number(num?.tax_income || 0) -
              Number(num?.income_for_calculating_all_in || 0))
          );
        }
        if (num?.calculating_method === "หักตามจริง") {
          return (
            total +
            (Number(num?.tax_income || 0) -
              Number(num?.income_for_calculating || 0))
          );
        }
      }

      return total + 0;
    },
    0
  );

  let tax408_property = taxData?.tax_408_property?.reduce((total, num) => {
    return total + Number(num?.tax_income);
  }, 0);

  let sumExpense =
    Number(salary_expense || 0) +
    Number(freelance || 0) +
    Number(freeJob_expense || 0) +
    Number(expense_tax_403 || 0) +
    Number(expense_tax_405 || 0) +
    Number(expense_tax_407 || 0) +
    Number(expense_tax_408 || 0) +
    Number(expense_tax_408_property || 0);

  return sumExpense;
};
