import { useNavigate } from "react-router-dom";
import { MTButton } from "../button/MTButton";
import { ImageMain } from "../img/Img.Main";
import { useUserStore } from "../../_store/userStore";

export const FooterAfterSum = () => {
  const navigate = useNavigate();
  const userData = useUserStore((state) => state.userData);

  return (
    <div className=" flex flex-col gap-2.5 my-[18px]">
      <div className="text-2xl text-center text-maincyan font-semibold">
        มาทำแผนให้เป็นจริงกันเถอะ !
      </div>
      <div className=" w-full space-y-2.5">
        {" "}
        {userData?.is_premium ? (
          ""
        ) : (
          <MTButton
            iconleft
            icon={
              <ImageMain src="/images/workwithus.png" className=" w-6 h-6 " />
            }
            text="สมัครโปรแกรมวางแผนการเงิน"
            textsize="text-base font-semibold"
            width="w-full h-12 "
            onClick={() => {
              window?.open(
                "https://liff.line.me/1645278921-kWRPP32q/?accountId=assurewealth"
              );
            }}
          />
        )}
        <div className="  flex  md:grid grid-cols-2 gap-1 ">
          <MTButton
            color="bg-mainblue"
            colortext="text-white group-hover:text-white font-semibold"
            hovercolor="hover:bg-maincyan group "
            variant="outlined"
            iconleft
            icon={
              <ImageMain
                src="/images/goodthumb.png"
                className=" w-5 h-ถ mr-0"
              />
            }
            text="เริ่มลงทุนวันนี้เลย"
            textsize="text-xs font-semibold"
            width=" w-full h-12 px-1 gap-0 "
            // onClick={() => {
            //   window?.open(
            //     "https://liff.line.me/1645278921-kWRPP32q/?accountId=assurewealth"
            //   );
            // }}
          />
          <MTButton
            color="border-maingreen bg-white"
            colortext="text-maingreen group-hover:text-white font-semibold"
            hovercolor="hover:bg-maingreen group "
            variant="outlined"
            iconleft
            icon={
              <ImageMain
                src="/images/saving_expenses.png"
                className=" w-5 h-5"
              />
            }
            text="ปกป้องความมั่งคั่งด้วยประกัน"
            textsize="text-xs font-semibold flex-none text-nowrap md:text-wrap"
            width=" w-fit md:w-full h-12 px-1 gap-0"
            onClick={() => {
              navigate("/allInsuarance");
            }}
          />
        </div>
      </div>
    </div>
  );
};
