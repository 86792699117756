import {
  ICON_NAVIGATE,
  ICON_NAVIGATE_RIGHT,
  ICON_RIGHT,
} from "../../../utils/useIcons";

export const CardMenu = ({ img, title, onClick, imgsize }) => {
  return (
    <div
      onClick={onClick}
      className="bg-gray50 rounded-[20px] p-[18px] w-full h-fit  cursor-pointer"
    >
      <div className=" flex space-x-[6px] w-full items-center">
        <img src={img} className={imgsize} />
        <div className=" flex items-center pt-2 w-full">
          <div className="text-black text-xl font-semibold ">{title}</div>
        </div>
        <ICON_RIGHT className=" text-black w-6 h-6 ml-auto mt-1 flex-none" />
      </div>
    </div>
  );
};
