import {
  Accordion,
  AccordionBody,
  AccordionHeader,
} from "@material-tailwind/react";
import {
  CardFinancialResult,
  CardTips,
} from "../../../components/card/CardMain";
import { useBalanceContext } from "../Balance.Main";
import { useMemo, useState } from "react";
import numeral from "numeral";
import { PopupBalanceMain } from "../styles/PopupBalance";
import { ImageMain } from "../../../components/img/Img.Main";
import {
  STYLE_BOXCAL_ETC,
  STYLE_INFODRAWER_ETC,
  STYLE_SPACE_SUM_NETFLOW,
  STYLE_TITE_DRAWER_INFO,
  STYLE_TITLE_SUM_NETFLOW,
} from "../../../utils/useStyle";
import { InfoDrawer } from "../../../components/drawer/InfoDrawer";

const SumFinancial = ({
  formBL,
  open,
  onClick,
  openPopupIncome,
  setOpenPopupIncome,
  openPopupOutcome,
  setOpenPopupOutcome,
  openPopupProperty,
  setOpenPopupProperty,
  openPopupDebtToAsset,
  setOpenPopupDebtToAsset,
  openPopupDebtToSalary,
  setOpenPopupDebtToSalary,
  openPopupConservation,
  setOpenPopupConservation,
  openPopupInvest,
  setOpenPopupInvest,
  openInfoSumFinancial,
  setOpenInfoSumFinancial,
}) => {
  //======================================== รายได้ ========================================//
  const countPositiveValues = () => {
    const positiveValues = [
      formBL?.sum_salary_peryear,
      formBL?.sum_interest_peryear,
      formBL?.sum_other_income,
    ]?.filter((value) => value > 0);
    return positiveValues?.length;
  };
  const getResult = () => {
    const numPositiveValues = countPositiveValues();
    if (numPositiveValues === 1) {
      return "normal";
    } else if (numPositiveValues > 1) {
      return "good";
    } else {
      return "false";
    }
  };
  const checkIncomeTitle = () => {
    const status = getResult();
    if (status === "normal") {
      return (
        <div className=" bg-yellowopacity text-mainyellow  px-3 py-1 rounded-[6px] text-xl font-semibold w-[108px] text-center">
          พอใช้
        </div>
      );
    }
    if (status === "good") {
      return (
        <div className="bg-greenopacity text-maingreen px-3 py-1 rounded-[6px] text-xl font-semibold w-[108px] text-center">
          ยอดเยี่ยม
        </div>
      );
    }
  };
  const checkIncomeIcon = () => {
    const status = getResult();
    if (status === "normal") {
      return "../images/medium_finance.svg";
    }
    if (status === "good") {
      return "../images/happy_finance.svg";
    }
  };

  const checkIncomeSubtitle = () => {
    const status = getResult();
    if (status === "normal") {
      return (
        <div className="text-xl font-semibold text-center my-2.5  text-mainyellow">
          คุณมีช่องทางรายได้ไม่หลากหลาย
        </div>
      );
    }
    if (status === "good") {
      return (
        <div className="text-xl font-semibold text-center my-2.5  text-maingreen">
          คุณมีรายได้หลากหลายช่องทาง
        </div>
      );
    }
  };
  const checkIncomeDes = () => {
    const status = getResult();
    if (status === "normal") {
      return (
        <div>
          คุณน่าจะเพิ่มช่องทางการหารายได้
          <div>
            เช่น การลงทุนให้ได้เงินปันผล, การออมเงินเพื่อดอกเบี้ย
            หรือเพิ่มอาชีพที่ 2 เพื่อช่วยให้คุณบรรลุเป้าหมายทางการเงิน
          </div>
        </div>
      );
    }
    if (status === "good") {
      return (
        <div>
          <div>คุณมีรายได้มากกว่า 1 ช่องทาง</div>
          รายได้เหล่านี้สามารถช่วยให้คุณบรรลุเป้าหมายทางการเงินได้แน่นอน
        </div>
      );
    }
  };


  //======================================== รายจ่าย ========================================//
  const checkResultExpenses = () => {
    // รายรับ
    const salaryValue = formBL?.salary_propotion ? formBL?.salary_propotion : 0;
    // ค่าใช้จ่ายการออมและลงทุน
    const sumExpensesInvestSaving = formBL?.sum_expenses_invest_saving
      ? formBL?.sum_expenses_invest_saving
      : 0;
    // รายจ่ายที่ไม่รวมค่าใช้จ่ายเพื่อการออม
    const expenseValue = formBL?.expenses_propotion
      ? formBL?.expenses_propotion - sumExpensesInvestSaving
      : 0;
    if (expenseValue > salaryValue / 2) {
      return "normal";
    } else {
      return "good";
    }
  };
  const checkOutcomeTitle = () => {
    const status = checkResultExpenses();
    if (status === "normal") {
      return (
        <div className=" bg-yellowopacity text-mainyellow  px-3 py-1 rounded-[6px] text-xl font-semibold w-[108px] text-center">
          พอใช้
        </div>
      );
    }
    if (status === "good") {
      return (
        <div className="bg-greenopacity text-maingreen px-3 py-1 rounded-[6px] text-xl font-semibold w-[108px] text-center">
          ยอดเยี่ยม
        </div>
      );
    }
  };
  const checkOutcomeIcon = () => {
    const status = checkResultExpenses();
    if (status === "normal") {
      return "/images/medium_finance.svg";
    }
    if (status === "good") {
      return "/images/happy_finance.svg";
    }
  };
  const checkOutcomeSubtitle = (formBL) => {
    const status = checkResultExpenses();
    // รายรับ
    const salaryValue = formBL?.salary_propotion ? formBL?.salary_propotion : 0;

    // ค่าใช้จ่ายการออมและลงทุน
    const sumExpensesInvestSaving = formBL?.sum_expenses_invest_saving
      ? formBL?.sum_expenses_invest_saving
      : 0;

    // รายจ่ายที่ไม่รวมค่าใช้จ่ายเพื่อการออม

    const expenseValue = formBL?.expenses_propotion
      ? formBL?.expenses_propotion - sumExpensesInvestSaving
      : 0;

    // formBL?.expenses_propotion > sumExpensesInvestSaving
    //   ? formBL?.expenses_propotion - sumExpensesInvestSaving
    //   : formBL?.expenses_propotion;

    // หาเปอร์เซ็นรายจ่ายต่อรายรับ
    const expensesPerSalary = ((expenseValue / salaryValue) * 100)?.toFixed(2);

    if (status === "normal") {
      return (
        <div className="text-xl font-semibold text-center my-2.5  text-mainyellow">
          คุณมีรายจ่ายรวม{" "}
          <span className=" font-semibold ">
            {numeral(expenseValue)?.format("0,0")}
          </span>{" "}
          บาท
          <div>
            หรือ {String(expensesPerSalary) === "Infinity" ? 0 : expensesPerSalary} %
            ซึ่งมากกว่าครึ่งนึงของรายรับ{" "}
          </div>
        </div>
      );
    }
    if (status === "good") {
      return (
        <div className="text-xl font-semibold text-center my-2.5  text-maingreen">
          คุณมีรายจ่ายรวม{" "}
          <span className=" font-semibold ">
            {numeral(expenseValue)?.format("0,0")}
          </span>{" "}
          บาท
          <div>หรือ {expensesPerSalary} % ซึ่งน้อยกว่าครึ่งนึงของรายรับ </div>
        </div>
      );
    }
  };
  const checkOutcomeDes = () => {
    const status = checkResultExpenses();
    //รายรับ
    const salaryValue = formBL?.salary_propotion ? formBL?.salary_propotion : 0;

    // ค่าใช้จ่ายการออมและลงทุน
    const sumExpensesInvestSaving = formBL?.sum_expenses_invest_saving
      ? formBL?.sum_expenses_invest_saving
      : 0;
    // รายจ่ายที่ไม่รวมค่าใช้จ่ายเพื่อการออม
    const expenseValue = formBL?.expenses_propotion
      ? formBL?.expenses_propotion - sumExpensesInvestSaving
      : 0;

    // formBL?.expenses_propotion > sumExpensesInvestSaving
    //   ? formBL?.expenses_propotion - sumExpensesInvestSaving
    //   : formBL?.expenses_propotion;

    // ลดค่าใช้จ่าย
    const reduceExpense = expenseValue - salaryValue / 2;

    if (status === "normal") {
      return (
        <div>
          คุณควรประหยัดและลดค่าใช้จ่าย{" "}
          <span className=" text-mainblue font-bold">
            {numeral(reduceExpense).format("0,0")} บาท
          </span>
          <div>เพื่อให้บรรลุเป้าหมายทางการเงินของคุณ</div>
          <div className=" text-maingray text-sm mt-3">
            *รายรับ{" "}
            <span className=" text-mainblue font-bold">
              {numeral(salaryValue)?.format("0,0")} บาท
            </span>
            <div>
              ครึ่งหนึ่งของรายรับคือ{" "}
              <span className=" text-mainblue font-bold">
                {numeral(salaryValue / 2)?.format("0,0")} บาท
              </span>
            </div>
          </div>
        </div>
      );
    }
    if (status === "good") {
      return (
        <div>
          คุณมีการใช้จ่ายในสัดส่วนที่ดี
          <div className=" text-maingray text-sm mt-3">
            *รายรับ{" "}
            <span className=" text-mainblue font-bold">
              {numeral(salaryValue)?.format("0,0")} บาท
            </span>
            <div>
              ครึ่งหนึ่งของรายรับคือ{" "}
              <span className=" text-mainblue font-bold">
                {numeral(salaryValue / 2)?.format("0,0")} บาท
              </span>
            </div>
          </div>
        </div>
      );
    }
  };

  //======================================== สภาพคล่องพื้นฐาน ========================================//
  const checkProperty = useMemo(() => {
    let sum = 0;
    const expenses_permonth = formBL?.expenses_propotion / 12;
    if (formBL?.sum_property_asset) {
      sum =
        expenses_permonth > 0
          ? formBL?.sum_property_asset / expenses_permonth
          : 0;
    }
    return Math.round(sum);
  });
  const checkResultProperty = () => {
    if (checkProperty < 6) {
      return "bad";
    } else if (checkProperty === 6) {
      return "good";
    } else {
      return "chance";
    }
  };
  const checkPropertyIcon = () => {
    const status = checkResultProperty();
    if (status === "bad") {
      return "/images/bad_finance.svg";
    }
    if (status === "good") {
      return "/images/happy_finance.svg";
    }
    return "/images/chance_finance.svg";
  };
  const checkAmouthProperty = () => {
    const status = checkResultProperty();
    if (status === "bad") {
      return `${checkProperty} เท่า`;
    }
    if (status === "good") {
      return `${checkProperty} เท่า`;
    }
    return `${checkProperty} เท่า`;
  };
  const checkPropertyTitle = () => {
    const status = checkResultProperty();
    if (status === "bad") {
      return (
        <div className=" bg-[#FF0000]/30 text-[#FF0000]  px-3 py-1 rounded-[6px] text-xl font-semibold w-[108px] text-center">
          ปรับปรุง
        </div>
      );
    }
    if (status === "good") {
      return (
        <div className="bg-greenopacity text-maingreen px-3 py-1 rounded-[6px] text-xl font-semibold w-[108px] text-center">
          ยอดเยี่ยม
        </div>
      );
    }
    return (
      <div className="bg-yellowopacity text-mainyellow px-3 py-1 rounded-[6px] text-xl font-semibold w-fit text-center">
        เพิ่มโอกาส
      </div>
    );
  };
  const checkPropertySubtitle = () => {
    const status = checkResultProperty();
    const value = checkAmouthProperty();
    const expenses_permonth = formBL?.expenses_propotion / 12;

    const MethodBox = ({ title, subtitle, des, icon, onClick }) => {
      return (
        <div className="">
          <div className=" text-maingray font-normal text-sm text-center">
            <span className=" font-semibold text-mainblue">
              อัตราส่วนสภาพคล่อง
            </span>{" "}
            = สินทรัพย์สภาพคล่อง/รายจ่ายต่อเดือน
          </div>
          <div className=" text-maingray text-xs text-center mt-1">
            ({" "}
            <span className=" text-mainblue font-semibold">
              {numeral(value)?.format("0,0")}
            </span>{" "}
            = {numeral(formBL?.sum_property_asset).format("0,0")} /{" "}
            {numeral(expenses_permonth).format("0,0")} )
          </div>
        </div>
      );
    };

    if (status === "bad") {
      return (
        <div className="text-lg font-semibold text-center my-2.5  text-[#FF0000]">
          คุณมีอัตราส่วนสภาพคล่อง {numeral(value)?.format("0,0")} เท่า
          ซึ่งน้อยกว่า 6 เท่า
          <MethodBox />
        </div>
      );
    }
    if (status === "good") {
      return (
        <div className="text-xl font-semibold text-center my-2.5  text-maingreen">
          คุณมีอัตราส่วนสภาพคล่องพื้นฐาน 6 เท่า
          <MethodBox />
        </div>
      );
    }
    return (
      <div className="text-lg font-semibold text-center my-2.5  text-mainyellow ">
        คุณมีอัตราส่วนสภาพคล่อง {numeral(value)?.format("0,0")} เท่า ซึ่งมากกว่า
        6 เท่า
        <MethodBox />
      </div>
    );
  };
  const checkPropertyDes = () => {
    const status = checkResultProperty();
    const value = checkAmouthProperty();
    const expenses_permonth = formBL?.expenses_propotion / 12;
    const asset_value = +expenses_permonth * (6 - checkProperty);
    const expenses_permonth_six = parseInt(expenses_permonth) * 6;
    let remain = formBL?.sum_property_asset - expenses_permonth_six;
    if (remain < 0) {
      remain = 0;
    }

    if (status === "bad") {
      return (
        <div className=" mb-4 font-medium">
          คุณต้องหาเงินสำรองเพิ่มอีก
          <span className=" px-1 font-bold text-red-500 ">
            {numeral(asset_value).format("0,0")}
          </span>
          บาท*
          <div>เพื่อความไม่ประมาทในการวางแผนการเงิน</div>
          <div className=" text-sm text-maingray mt-4 font-normal ">
            * ค่าใช้จ่ายแต่ละเดือน x ( 6 - สภาพคล่อง )
            <div className=" text-xs mt-1">
              (
              <span className=" text-mainblue font-semibold pl-1">
                {numeral(formBL?.sum_property_asset).format("0,0")}
              </span>{" "}
              x ( 6 - {numeral(value)?.format("0,0")}) ={" "}
              {numeral(asset_value).format("0,0")} )
            </div>
          </div>
        </div>
      );
    }

    if (status === "good") {
      return (
        <div className=" mb-10">
          คุณมีความสามารถในการรับมือกับ
          <div>
            เหตุการณ์ไม่คาดฝันได้ดี เช่น ตกงาน อุบัติเหตุทำให้สูญเสียรายได้
            เป็นต้น
          </div>
        </div>
      );
    }
    return (
      <div className="mb-4 font-medium">
        คุณมีเงินสำรองจำนวนมาก
        <div>
          จะดีกว่าไหมหากนำเงิน
          <span className=" px-1 font-bold text-mainyellow ">
            {numeral(remain).format("0,0")}
          </span>
          บาท*
          <div> ไปลงทุนเพิ่มเติม เพื่อให้บรรลุเป้าหมายทางการเงิน</div>
        </div>
        <div className=" text-sm text-maingray mt-4 font-normal ">
          * สินทรัพย์สภาพคล่อง - 6เท่าของค่าใช้จ่ายแต่ละเดือน
          <div className=" text-xs mt-1">
            (
            <span className=" text-mainblue font-semibold pl-1">
              {numeral(formBL?.sum_property_asset).format("0,0")}
            </span>{" "}
            - {numeral(expenses_permonth_six).format("0,0")} ={" "}
            {numeral(remain).format("0,0")} )
          </div>
        </div>
      </div>
    );
  };

  //======================================== หนี้สินต่อทรัพย์สิน ========================================//
  const getDebttoTotalAsset = useMemo(() => {
    let sum = 0;
    if (formBL?.debt_propotion) {
      sum =
        formBL?.property_propotion > 0
          ? (formBL?.debt_propotion / formBL?.property_propotion) * 100
          : 0;
    }
    return sum;
  });
  const checkDebttoAssetIcon = () => {
    const status = getDebttoTotalAsset;
    if (status > 50) {
      return "/images/bad_finance.svg";
    } else {
      return "/images/happy_finance.svg";
    }
  };
  const checkDebttoAssetTitle = () => {
    const status = getDebttoTotalAsset;
    if (status > 50) {
      return (
        <div className=" bg-[#FF0000]/30 text-[#FF0000]  px-3 py-1 rounded-[6px] text-xl font-semibold w-[108px] text-center">
          ปรับปรุง
        </div>
      );
    } else {
      return (
        <div className="bg-greenopacity text-maingreen px-3 py-1 rounded-[6px] text-xl font-semibold w-[150px] text-center">
          อยู่ในเกณฑ์
        </div>
      );
    }
  };
  const checkDebttoAssetSubtitle = () => {
    const status = getDebttoTotalAsset;
    if (status > 50) {
      return (
        <div className="text-xl font-semibold text-center my-2.5  text-[#FF0000]">
          คุณมีอัตราส่วนหนี้สินต่อทรัพย์สิน {getDebttoTotalAsset?.toFixed(2)} %
          <div>ซึ่งมากกว่า 50%</div>
        </div>
      );
    } else {
      return (
        <div className="text-xl font-semibold text-center my-2.5  text-maingreen">
          คุณมีอัตราส่วนหนี้สินต่อทรัพย์สิน {getDebttoTotalAsset?.toFixed(2)}%
          <div>ซึ่งน้อยกว่า 50% </div>
        </div>
      );
    }
  };
  const checkDebttoAssetDes = () => {
    const status = getDebttoTotalAsset;

    if (status > 50) {
      return (
        <div>
          ภาระหนี้สินมากเกินไป
          <div>โดยหาทางเพิ่มรายได้ ลดรายจ่ายที่ไม่จำเป็น</div>
          <div className=" text-maingray text-sm mt-3">
            <span className=" text-mainblue font-semibold">
              อัตราส่วนหนี้สินต่อทรัพย์สิน =
            </span>{" "}
            (หนี้สิน/ทรัพย์สิน)x100
            <div className=" text-xs mt-1">
              <span className=" text-mainblue font-bold">
                {getDebttoTotalAsset?.toFixed(2)} % =
              </span>{" "}
              (( {numeral(formBL?.debt_propotion)?.format("0,0")} /{" "}
              {numeral(formBL?.property_propotion)?.format("0,0")}) * 100 )
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          คุณมีอัตราส่วนหนี้สินต่อทรัพย์สินในสัดส่วนที่ดี
          <div className=" text-maingray text-sm mt-3">
            <span className=" text-mainblue font-semibold">
              อัตราส่วนหนี้สินต่อทรัพย์สิน =
            </span>{" "}
            (หนี้สิน/ทรัพย์สิน)x100
            <div className=" text-xs mt-1">
              <span className=" text-mainblue font-bold">
                {getDebttoTotalAsset?.toFixed(2)} % =
              </span>{" "}
              (( {numeral(formBL?.debt_propotion)?.format("0,0")} /{" "}
              {numeral(formBL?.property_propotion)?.format("0,0")}) * 100 )
            </div>
          </div>
        </div>
      );
    }
  };

  //======================================== ชำระหนี้สินต่อรายได้ ========================================//
  const getDebttoSalary = useMemo(() => {
    let sum = 0;
    // const debtPermonth = formBL?.sum_debt_short_permonth
    //   ? formBL?.sum_debt_short_permonth
    //   : 0 + formBL?.sum_debt_long_permonth
    //   ? formBL?.sum_debt_long_permonth
    //   : 0;
    const debtPermonth =
      formBL?.sum_debt_short_permonth + formBL?.sum_debt_long_permonth;
    const salaryPermonth = formBL?.salary_propotion
      ? formBL?.salary_propotion / 12
      : 0;
    sum = salaryPermonth > 0 ? (debtPermonth / salaryPermonth) * 100 : 0;
    return sum;
  });
  const checkDebttoSalaryIcon = () => {
    const status = getDebttoSalary;
    if (status > 50) {
      return "/images/bad_finance.svg";
    } else {
      return "/images/happy_finance.svg";
    }
  };
  const checkDebttoSalaryTitle = () => {
    const status = getDebttoSalary;
    if (status > 50) {
      return (
        <div className=" bg-[#FF0000]/30 text-[#FF0000]  px-3 py-1 rounded-[6px] text-xl font-semibold w-[108px] text-center">
          ปรับปรุง
        </div>
      );
    } else {
      return (
        <div className="bg-greenopacity text-maingreen px-3 py-1 rounded-[6px] text-xl font-semibold w-[108px] text-center">
          ยอดเยี่ยม
        </div>
      );
    }
  };
  const checkDebttoSalarySubtitle = () => {
    const status = getDebttoSalary;
    if (status > 50) {
      return (
        <div className="text-xl font-semibold text-center my-2.5  text-[#FF0000]">
          คุณมีอัตราส่วนการชำระหนี้ต่อต่อรายได้ {getDebttoSalary?.toFixed(2)} %
          ซึ่งมากกว่า 50% *
        </div>
      );
    } else {
      return (
        <div className="text-xl font-semibold text-center my-2.5  text-maingreen">
          คุณมีอัตราส่วนการชำระหนี้ต่อต่อรายได้ {getDebttoSalary?.toFixed(2)} %
          ซึ่งน้อยกว่า 35% - 50% *
        </div>
      );
    }
  };
  const checkDebttoSalaryDes = () => {
    const status = getDebttoSalary;
    const salary_propotion_per_month = formBL?.salary_propotion / 12;

    if (status > 50) {
      return (
        <div>
          ควรวางแผนบริหารหนี้เช่น หาทางเพิ่มรายได้ ลดรายจ่ายไม่จำเป็น
          รักษาสภาพคล่อง
          <div className=" text-maingray text-sm mt-3">
            <span className=" text-mainblue font-semibold pr-1">
              อัตราส่วนการชำระหนี้ต่อรายได้ =
            </span>
            (เงินชำระหนี้สินต่อเดือน/รายได้ต่อเดือน)x100
            <div className=" text-xs mt-1">
              <span className=" text-mainblue font-bold">
                {getDebttoSalary?.toFixed(2)} % =
              </span>{" "}
              ({" "}
              {numeral(
                formBL?.sum_debt_short_permonth + formBL?.sum_debt_long_permonth
              ).format("0,0")}{" "}
              / {numeral(salary_propotion_per_month).format("0,0")} * 100 )
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          ภาระหนี้สินไม่มากเกิน หนี้สินที่ดีก่อให้เกิดรายได้และ สินทรัพย์ในอนาคต
          <div className=" text-maingray text-sm mt-3">
            <span className=" text-mainblue font-semibold pr-1">
              อัตราส่วนการชำระหนี้ต่อรายได้ =
            </span>
            (เงินชำระหนี้สินต่อเดือน/รายได้ต่อเดือน)x100
            <div className=" text-xs mt-1">
              ({" "}
              <span className=" text-mainblue font-bold">
                {getDebttoSalary?.toFixed(2)} % =
              </span>{" "}
              {numeral(
                formBL?.sum_debt_short_permonth + formBL?.sum_debt_long_permonth
              ).format("0,0")}{" "}
              / {numeral(salary_propotion_per_month).format("0,0")} * 100 )
            </div>
          </div>
        </div>
      );
    }
  };

  //======================================== การออม ========================================//
  const getConservation = useMemo(() => {
    let sum = 0;
    if (formBL?.sum_expenses_invest_saving) {
      sum = formBL?.sum_expenses_invest_saving;
    }
    const finalResult = sum > 0 ? (sum / formBL?.salary_propotion) * 100 : 0;
    return finalResult;
  });
  const getInvestSavingMore = useMemo(() => {
    // หาเปอเซ็นคงเหลือการออมใน 10%
    const salaryValue = formBL?.salary_propotion ? formBL?.salary_propotion : 0;
    const sumExpensesInvestSaving = formBL?.sum_expenses_invest_saving
      ? formBL?.sum_expenses_invest_saving
      : 0;
    const conservation = (salaryValue * 10) / 100;
    const result = conservation - sumExpensesInvestSaving;
    return result;
  });
  const checkConservationIcon = () => {
    const status = getConservation;
    if (status > 10) {
      return "/images/happy_finance.svg";
    } else {
      return "/images/bad_finance.svg";
    }
  };
  const checkConservationTitle = () => {
    const status = getConservation;
    if (status > 10) {
      return (
        <div className="bg-greenopacity text-maingreen px-3 py-1 rounded-[6px] text-xl font-semibold w-[108px] text-center">
          ยอดเยี่ยม
        </div>
      );
    } else {
      return (
        <div className=" bg-[#FF0000]/30 text-[#FF0000]  px-3 py-1 rounded-[6px] text-xl font-semibold w-[108px] text-center">
          ปรับปรุง
        </div>
      );
    }
  };
  const checkConservationSubtitle = () => {
    const status = getConservation;

    if (status > 10) {
      return (
        <div className="text-xl font-semibold text-center my-2.5  text-maingreen">
          คุณมีอัตราส่วนการออม{" "}
          {numeral(formBL?.sum_expenses_invest_saving).format("0,0")} บาท หรือ{" "}
          {getConservation?.toFixed(2)} % ซึ่งมากกว่า 10 % *
        </div>
      );
    } else {
      return (
        <div className="text-xl font-semibold text-center my-2.5  text-[#FF0000]">
          คุณมีอัตราส่วนการออม
          {numeral(formBL?.sum_expenses_invest_saving).format("0,0.00")} บาท
          หรือ {getConservation?.toFixed(2)} % ซึ่งน้อยกว่า 10 % *
        </div>
      );
    }
  };
  const checkConservationDes = () => {
    const status = getConservation;
    const salaryValue = formBL?.salary_propotion ? formBL?.salary_propotion : 0;
    const salaryValuePercent = (salaryValue * 10) / 100;

    if (status > 10) {
      return (
        <div>
          คุณมีอัตราส่วนการออมและการลงทุนในสัดส่วนที่ดี
          <div className=" text-maingray text-sm mt-3">
            <div className="font-semibold"> อัตราส่วนการออม = </div>
            <div className=" mb-1">
              (ค่าใช้จ่ายเพื่อการออมและการลงทุน/รายได้รวม) x 100 *รายรับ{" "}
            </div>

            <span className=" text-mainblue font-bold">
              {numeral(salaryValue)?.format("0,0")} บาท
            </span>
            <div>
              10% ของรายรับคือ{" "}
              <span className=" text-mainblue font-bold">
                {numeral(salaryValuePercent)?.format("0,0")} บาท
              </span>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          คุณควรเพิ่มการออมอีก{" "}
          <span className=" font-bold text-red-500 ">
            {numeral(getInvestSavingMore).format("0,0.00")}
          </span>{" "}
          บาท
          <div className="font-semibold"> อัตราส่วนการออม = </div>
          <div className=" mb-1">
            (ค่าใช้จ่ายเพื่อการออมและการลงทุน/รายได้รวม) x 100 *รายรับ{" "}
          </div>
          <div className=" text-maingray text-sm mt-3">
            *รายรับ{" "}
            <span className=" text-mainblue font-bold">
              {numeral(salaryValue)?.format("0,0")} บาท
            </span>
            <div>
              10% ของรายรับคือ{" "}
              <span className=" text-mainblue font-bold">
                {numeral(salaryValuePercent)?.format("0,0")} บาท
              </span>
            </div>
          </div>
        </div>
      );
    }
  };

  //======================================== การลงทุน ========================================//
  const getInvest = useMemo(() => {
    let sum = 0;
    if (formBL?.sum_property_invest) {
      sum =
        formBL?.net_wealth > 0
          ? (formBL?.sum_property_invest / formBL?.net_wealth) * 100
          : 0;
    }
    return sum;
  });
  const checkInvestIcon = () => {
    const status = getInvest;
    if (status > 50) {
      return "/images/happy_finance.svg";
    } else {
      return "/images/bad_finance.svg";
    }
  };
  const checkInvestTitle = () => {
    const status = getInvest;
    if (status > 50) {
      return (
        <div className="bg-greenopacity text-maingreen px-3 py-1 rounded-[6px] text-xl font-semibold w-[108px] text-center">
          ยอดเยี่ยม
        </div>
      );
    } else {
      return (
        <div className=" bg-[#FF0000]/30 text-[#FF0000]  px-3 py-1 rounded-[6px] text-xl font-semibold w-[108px] text-center">
          ปรับปรุง
        </div>
      );
    }
  };
  const checkInvestSubtitle = () => {
    const status = getInvest;

    const MethodBox = ({ value }) => {
      return (
        <div className="">
          <div className=" text-maingray font-normal text-sm text-center">
            <span className=" font-semibold text-mainblue">
              อัตราส่วนการลงทุน
            </span>{" "}
            = (สินทรัพย์ลงทุน/ความมั่งคั่งสุทธิ) x 100
          </div>
          <div className=" text-maingray text-xs text-center mt-1">
            ( <span className=" text-mainblue font-semibold">{value}</span> =
            &nbsp;{numeral(formBL?.sum_property_invest).format("0,0")} /{" "}
            {numeral(formBL?.net_wealth).format("0,0")} * 100 )
          </div>
        </div>
      );
    };

    if (status > 50) {
      return (
        <div className="text-xl font-semibold text-center my-2.5  text-maingreen">
          คุณมีอัตราส่วนการลงทุน {getInvest?.toFixed(2)} %
          <div>ซึ่งมากกว่า 50 %</div>
          <MethodBox value={getInvest?.toFixed(2)} />
        </div>
      );
    } else {
      return (
        <div className="text-xl font-semibold text-center my-2.5  text-[#FF0000]">
          คุณมีอัตราส่วนการลงทุน {getInvest?.toFixed(2)} %
          <div>ซึ่งน้อยกว่า 50 %</div>
          <MethodBox value={getInvest?.toFixed(2)} />
        </div>
      );
    }
  };
  const checkInvestDes = () => {
    const status = getInvest;
    if (status > 50) {
      return (
        <div>
          คุณมีแนวโน้มที่จะบรรลุเป้าหมายการเงินที่ต้องการ
          จัดสรรการลงทุนให้เหมาะสมกับลำดับความสำคัญ
        </div>
      );
    } else {
      return (
        <div>
          คุณมีสินทรัพย์ที่ก่อให้เกิดรายได้น้อยเกินไป
          ควรจัดสรรการลงทุนเพิ่มพูนมั่งคั่ง
        </div>
      );
    }
  };

  // console.log("formBL : ", formBL);
  // console.log("formBL : ", formBL);

  return (
    <div className="bg-gray50 rounded-[20px] p-4 font-IBMPlexSansThai">
      <Accordion
        animate={{
          transition: "all .100s ease-in-out",
          height: "auto",
          overflow: "hidden",
        }}
        open={open}
        icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 17 16"
            className={`${
              open ? "" : "rotate-180"
            } transition-transform h-4 w-4`}
            fill="none"
          >
            <path
              d="M3.83366 10L8.50033 5.33336L13.167 10"
              stroke="#919191"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        }
        className="group"
      >
        <AccordionHeader
          className={` border-b-0 transition-colors text-md text-gray-800 font-IBMPlexSansThai font-medium w-full p-0 `}
          onClick={onClick}
        >
          <div className=" font-semibold">{open ? "" : "วิเคราะห์การเงิน"}</div>
        </AccordionHeader>
        <AccordionBody className="p-0 ">
          <div className=" space-y-5">
            <div className=" ">
              <div className={STYLE_TITLE_SUM_NETFLOW}>
                <ImageMain
                  src="/images/income_insuarance_all.png"
                  className=" w-8 h-8"
                />
                รายได้และรายจ่าย
              </div>
              <div className={STYLE_SPACE_SUM_NETFLOW}>
                <CardFinancialResult
                  openInfo={() => {
                    setOpenInfoSumFinancial("income");
                  }}
                  title={`รายได้`}
                  value={formBL?.salary_propotion ? true : false}
                  status={getResult()}
                  openPopup={() =>
                    setOpenPopupIncome(formBL?.salary_propotion ? true : false)
                  }
                />
                <CardFinancialResult
                  openInfo={() => {
                    setOpenInfoSumFinancial("outcome");
                  }}
                  title={`รายจ่าย`}
                  value={
                    formBL?.expenses_propotion -
                    formBL?.sum_expenses_invest_saving
                      ? true
                      : false
                  }
                  status={checkResultExpenses()}
                  openPopup={() =>
                    setOpenPopupOutcome(
                      formBL?.expenses_propotion ? true : false
                    )
                  }
                />
              </div>
            </div>
            <div>
              <div className={STYLE_TITLE_SUM_NETFLOW}>
                <ImageMain
                  src="/images/invest_insuarance_all.png"
                  className=" w-8 h-8"
                />
                สรุปอัตราส่วนทางการเงิน
              </div>
              <div className={STYLE_SPACE_SUM_NETFLOW}>
                <CardFinancialResult
                  openInfo={() => {
                    setOpenInfoSumFinancial("property");
                  }}
                  title={`อัตราส่วนสภาพคล่องพื้นฐาน`}
                  value={
                    formBL?.property_propotion && formBL?.expenses_propotion
                      ? true
                      : false
                  }
                  status={checkResultProperty()}
                  amount={`${numeral(checkAmouthProperty())?.format("0")} เท่า`}
                  openPopup={() =>
                    setOpenPopupProperty(
                      formBL?.property_propotion && formBL?.expenses_propotion
                        ? true
                        : false
                    )
                  }
                />
                <CardFinancialResult
                  openInfo={() => {
                    setOpenInfoSumFinancial("debt_to_asset");
                  }}
                  title={`อัตราส่วนหนี้สินต่อทรัพย์สิน`}
                  value={
                    formBL?.debt_propotion && formBL?.property_propotion
                      ? true
                      : false
                  }
                  status={getDebttoTotalAsset > 50 ? "bad" : "good"}
                  amount={` ${getDebttoTotalAsset?.toFixed(2)} %`}
                  openPopup={() =>
                    setOpenPopupDebtToAsset(
                      formBL?.debt_propotion && formBL?.property_propotion
                        ? true
                        : false
                    )
                  }
                />
                <CardFinancialResult
                  openInfo={() => {
                    setOpenInfoSumFinancial("debt_to_salary");
                  }}
                  title={
                    <div>
                      อัตราส่วนการชำระหนี้
                      <div>ต่อรายได้</div>
                    </div>
                  }
                  value={
                    formBL?.debt_propotion && formBL?.salary_propotion
                      ? true
                      : false
                  }
                  status={getDebttoSalary > 50 ? "bad" : "good_2"}
                  amount={`${getDebttoSalary?.toFixed(2)} %`}
                  openPopup={() =>
                    setOpenPopupDebtToSalary(
                      formBL?.debt_propotion && formBL?.salary_propotion
                        ? true
                        : false
                    )
                  }
                />
                <CardFinancialResult
                  openInfo={() => {
                    setOpenInfoSumFinancial("conservation");
                  }}
                  title={`อัตราส่วนการออม`}
                  value={
                    formBL?.salary_propotion && formBL?.expenses_propotion
                      ? true
                      : false
                  }
                  status={getConservation > 10 ? "good" : "bad"}
                  amount={`${getConservation?.toFixed(2)}  %`}
                  openPopup={() =>
                    setOpenPopupConservation(
                      formBL?.salary_propotion && formBL?.expenses_propotion
                        ? true
                        : false
                    )
                  }
                />
                <CardFinancialResult
                  openInfo={() => {
                    setOpenInfoSumFinancial("invest");
                  }}
                  title={`อัตราส่วนการลงทุน`}
                  value={
                    formBL?.sum_property_invest && formBL?.net_wealth
                      ? true
                      : false
                  }
                  status={getInvest > 50 ? "good" : "bad"}
                  amount={`${getInvest?.toFixed(2)} %`}
                  openPopup={() =>
                    setOpenPopupInvest(
                      formBL?.sum_property_invest && formBL?.net_wealth
                        ? true
                        : false
                    )
                  }
                />
              </div>
            </div>
          </div>
        </AccordionBody>
      </Accordion>

      {/*============================== Popup ==============================*/}
      {openPopupIncome && (
        <PopupBalanceMain
          icon={checkIncomeIcon()}
          open={openPopupIncome}
          onClose={() => {
            setOpenPopupIncome(false);
          }}
          head={checkIncomeTitle()}
          subtitle={checkIncomeSubtitle()}
          discription={checkIncomeDes()}
        />
      )}

      {openInfoSumFinancial === "income" && (
        <InfoDrawer
          bottom="bottom-[-60%]"
          head={"รายได้"}
          text={
            <div className=" space-y-2">
              <li>
                รายได้ หมายถึง ผลตอบแทนที่ได้รับจากการทำงานหรืออื่น ๆ เช่น
                ดอกเบี้ย การลงทุน เป็นต้น
              </li>
              <li>
                เกณฑ์พอใช้ หมายถึง
                มีช่องทางรายได้เพียงช่องทางเดียวซึ่งอาจจะไม่เหมาะกับสถานการณ์เศรษฐกิจปัจจุบัน
                เช่น การระบาดของโรคโควิด19
              </li>
              <li>
                เกณฑ์ยอดเยี่ยม หมายถึง มีช่องทางรายได้มากกว่า 1
                ช่องทางซึ่งเป็นเรื่องที่ดีสำหรับสถานการณ์ปัจจุบัน
              </li>
            </div>
          }
          open={openInfoSumFinancial === "income"}
          onClose={() => {
            setOpenInfoSumFinancial(false);
          }}
        />
      )}

      {openPopupOutcome && (
        <PopupBalanceMain
          padding="px-4"
          icon={checkOutcomeIcon()}
          open={openPopupOutcome}
          onClose={() => {
            setOpenPopupOutcome(false);
          }}
          head={checkOutcomeTitle()}
          subtitle={checkOutcomeSubtitle(formBL)}
          discription={checkOutcomeDes()}
        />
      )}

      {openInfoSumFinancial === "outcome" && (
        <InfoDrawer
          bottom="bottom-[-50%]"
          head={"รายจ่าย"}
          text={
            <div className=" space-y-2">
              <li>
                รายจ่าย หมายถึง มูลค่าเงินที่ใช้เป็นค่าอุปโภค
                บริโภคเมื่อทำกิจกรรมใด ๆ ในโปรแกรมนี้หมายถึง
                รายจ่ายคงที่และรายจ่ายผันแปร
              </li>
              <li>
                เกณฑ์ยอดเยี่ยม หมายถึง มีค่าใช้จ่ายไม่เกินร้อยละ 50 ของรายได้
                เช่น รายได้ 1,000,000 บาท/ปี รายจ่าย 400,000 บาท/ปี
              </li>
              <li>
                เกณฑ์พอใช้ หมายถึง มีค่าใช้จ่ายเกินร้อยละ 50 ของรายได้ เช่น
                รายได้ 1,000,000 บาท/ปี รายจ่าย 990,000 บาท/ปี
              </li>
            </div>
          }
          open={openInfoSumFinancial === "outcome"}
          onClose={() => {
            setOpenInfoSumFinancial(false);
          }}
        />
      )}

      {openPopupProperty && (
        <PopupBalanceMain
          padding="px-4"
          icon={checkPropertyIcon()}
          open={openPopupProperty}
          onClose={() => {
            setOpenPopupProperty(false);
          }}
          head={checkPropertyTitle()}
          subtitle={checkPropertySubtitle()}
          discription={checkPropertyDes()}
        />
      )}

      {openInfoSumFinancial === "property" && (
        <InfoDrawer
          bottom="bottom-[-60%]"
          fontHead="text-2xl"
          head={"อัตราส่วนสภาพคล่องพื้นฐาน"}
          text={
            <div className=" space-y-2">
              <div>
                แสดงถึงสินทรัพย์สภาพคล่องที่มีอยู่ว่าสามารถใช้ในการดำรงชีวิตได้กี่เดือน
                แม้ว่าเราอาจจะไม่มีรายได้ ซึ่งอัตราส่วนนี้ควรมีค่า 6
                เท่าของค่าใช้จ่ายต่อเดือน
              </div>

              <div className={STYLE_BOXCAL_ETC}>
                <ImageMain
                  src="../images/lamp.png"
                  className=" w-4 h-4 absolute top-2  left-2"
                />
                <div className="font-semibold">
                  {" "}
                  อัตราส่วนสภาพคล่องพื้นฐาน ={" "}
                </div>
                สินทรัพย์สภาพคล่อง/ค่าใช้จ่ายต่อเดือน
              </div>
              <div className={STYLE_INFODRAWER_ETC}>
                <div>
                  ตัวอย่าง มีสินทรัพย์สภาคล่อง(เงินสด) 300,000 บาท
                  มีค่าใช้จ่ายต่อเดือน 50,000 บาท/เดือน
                </div>
                <div className="">
                  วิธีคิด 300,000/50,000{" "}
                  <span className=" text-mainblue font-semibold">= 6</span>
                </div>
                <div>
                  ดังนั้น สภาพคล่องพื้นฐานคือ 6 เท่า ซึ่งอยู่ในเกณฑ์ที่ดี
                </div>
              </div>
            </div>
          }
          open={openInfoSumFinancial === "property"}
          onClose={() => {
            setOpenInfoSumFinancial(false);
          }}
        />
      )}

      {openPopupDebtToAsset && (
        <PopupBalanceMain
          padding="px-4"
          icon={checkDebttoAssetIcon()}
          open={openPopupDebtToAsset}
          onClose={() => {
            setOpenPopupDebtToAsset(false);
          }}
          head={checkDebttoAssetTitle()}
          subtitle={checkDebttoAssetSubtitle()}
          discription={checkDebttoAssetDes()}
        />
      )}

      {openInfoSumFinancial === "debt_to_asset" && (
        <InfoDrawer
          bottom="bottom-[-80%]"
          head={"อัตราส่วนหนี้สินต่อทรัพย์สิน "}
          text={
            <div className=" space-y-2">
              <div>
                แสดงถึงปริมาณหนี้สินเมื่อเทียบกับทรัพย์สินที่มี
                ซึ่งอัตราส่วนนี้ควรมีค่าน้อยกว่า 50%
              </div>
              <div className={STYLE_BOXCAL_ETC}>
                <ImageMain
                  src="../images/lamp.png"
                  className=" w-4 h-4 absolute top-2  left-2"
                />
                <div className="font-semibold">
                  {" "}
                  อัตราส่วนหนี้สินต่อทรัพย์สิน ={" "}
                </div>
                (หนี้สิน/ทรัพย์สิน)x100
              </div>

              <div className={STYLE_INFODRAWER_ETC}>
                <div>
                  {" "}
                  ตัวอย่าง มีหนี้สิน 70,000 บาท มีทรัพย์สิน 100,000 บาท
                </div>
                <div className=" mt-1">
                  วิธีคิด (70,000/100,000) x100 = 70 %
                </div>
                <div>
                  ดังนั้น หนี้สินต่อทรัพย์สินคือ 70 % ซึ่งอยู่ในเกณฑ์ที่ปรับปรุง
                </div>
              </div>
            </div>
          }
          open={openInfoSumFinancial === "debt_to_asset"}
          onClose={() => {
            setOpenInfoSumFinancial(false);
          }}
        />
      )}

      {openPopupDebtToSalary && (
        <PopupBalanceMain
          padding="px-4"
          icon={checkDebttoSalaryIcon()}
          open={openPopupDebtToSalary}
          onClose={() => {
            setOpenPopupDebtToSalary(false);
          }}
          head={checkDebttoSalaryTitle()}
          subtitle={checkDebttoSalarySubtitle()}
          discription={checkDebttoSalaryDes()}
        />
      )}

      {openInfoSumFinancial === "debt_to_salary" && (
        <InfoDrawer
          bottom="bottom-[-60%]"
          fontHead="text-2xl"
          head={"อัตราส่วนการชำระหนี้ต่อรายได้ "}
          text={
            <div className=" space-y-2">
              <div>
                แสดงถึงความสามารถในการชำระคืนหนี้สินจากรายได้รวมที่ได้รับ
                ซึ่งอัตราส่วนนี้ควรมีค่าระหว่าง 35% - 45% หากมีค่ามาก ๆ
                อาจมีความเสี่ยงเรื่องการผ่อนชำระหนี้ในอนาคต
              </div>
              <div className={STYLE_BOXCAL_ETC}>
                <ImageMain
                  src="../images/lamp.png"
                  className=" w-4 h-4 absolute top-2  left-2"
                />
                <div className="font-semibold">
                  {" "}
                  อัตราส่วนการชำระหนี้ต่อรายได้ ={" "}
                </div>
                (เงินชำระหนี้สินต่อเดือน/รายได้ต่อเดือน)x100
              </div>
              <div className={STYLE_INFODRAWER_ETC}>
                <div>
                  {" "}
                  ตัวอย่าง มีเงินชำระหนี้ 40,000 บาท มีรายได้ต่อเดือน 100,000
                  บาท/เดือน
                </div>
                <div className=" mt-1">
                  วิธีคิด (40,000/100,000) x100 = 40 %
                </div>
                <div>
                  ดังนั้น การชำระหนี้ต่อรายได้คือ 40% ซึ่งอยู่ในเกณฑ์ที่ดี
                </div>
              </div>
            </div>
          }
          open={openInfoSumFinancial === "debt_to_salary"}
          onClose={() => {
            setOpenInfoSumFinancial(false);
          }}
        />
      )}

      {openPopupConservation && (
        <PopupBalanceMain
          padding="px-4"
          icon={checkConservationIcon()}
          open={openPopupConservation}
          onClose={() => {
            setOpenPopupConservation(false);
          }}
          head={checkConservationTitle()}
          subtitle={checkConservationSubtitle()}
          discription={checkConservationDes()}
        />
      )}

      {openInfoSumFinancial === "conservation" && (
        <InfoDrawer
          bottom="bottom-[-60%]"
          head={"อัตราส่วนการออม"}
          text={
            <div className=" space-y-2">
              <div>
                แสดงถึงความสามารถในการออมเงินจากรายได้
                เพื่อใช้สำหรับเป้าหมายทางการเงินในอนาคต เช่น ศึกษาต่อ ดาวน์บ้าน
                ซื้อรถ ท่องเที่ยว เกษียณ ฯลฯ
                ซึ่งอัตราส่วนนี้ควรมากกว่าหรือเท่ากับ 10%
              </div>

              <div className={STYLE_BOXCAL_ETC}>
                <ImageMain
                  src="../images/lamp.png"
                  className=" w-4 h-4 absolute top-2  left-2"
                />
                <div className="font-semibold"> อัตราส่วนการออม = </div>
                (ค่าใช้จ่ายเพื่อการออมและการลงทุน/รายได้รวม) x 100
              </div>
              <div className={STYLE_INFODRAWER_ETC}>
                <div>
                  ตัวอย่าง มีรายได้รวม 100,000 บาท
                  มีค่าใช้จ่ายเพื่อการออมและการลงทุน 30,000 บาท
                </div>
                <div className=" mt-1">
                  วิธีคิด (30,000 /100,000)x100 = 30 %
                </div>
                <div>
                  ดังนั้น มีการออมมากกว่า 10% ของรายได้ ซึ่งอยู่ในเกณฑ์ที่ดี
                </div>
              </div>
            </div>
          }
          open={openInfoSumFinancial === "conservation"}
          onClose={() => {
            setOpenInfoSumFinancial(false);
          }}
        />
      )}

      {openPopupInvest && (
        <PopupBalanceMain
          padding="px-4"
          icon={checkInvestIcon()}
          open={openPopupInvest}
          onClose={() => {
            setOpenPopupInvest(false);
          }}
          head={checkInvestTitle()}
          subtitle={checkInvestSubtitle()}
          discription={checkInvestDes()}
        />
      )}

      {openInfoSumFinancial === "invest" && (
        <InfoDrawer
          bottom="bottom-[-60%]"
          head={"อัตราส่วนการลงทุน"}
          text={
            <div className=" space-y-2">
              <div>
                แสดงถึงสถานภาพทางการเงินที่มีความมั่นคง
                ซึ่งอัตราส่วนนี้ควรมากกว่าหรือเท่ากับ 50%
              </div>

              <div className={STYLE_BOXCAL_ETC}>
                <ImageMain
                  src="../images/lamp.png"
                  className=" w-4 h-4 absolute top-2  left-2"
                />
                <div className="font-semibold"> อัตราส่วนการลงทุน = </div>
                (สินทรัพย์ลงทุน/ความมั่งคั่งสุทธิ) x 100
              </div>
              <div className={STYLE_INFODRAWER_ETC}>
                <div>
                  {" "}
                  ตัวอย่าง มีสินทรัพย์ลงทุน 300,000 บาท มีความมั่งคั่งสุทธิ
                  900,000 บาท
                </div>
                <div className=" mt-1">
                  วิธีคิด ( 300,000/900,000)x100 = 33.3 %
                </div>
                <div>ดังนั้น มีการลงทุน 33.33% ซึ่งอยู่ในเกณฑ์ที่ไม่ดี</div>
              </div>
            </div>
          }
          open={openInfoSumFinancial === "invest"}
          onClose={() => {
            setOpenInfoSumFinancial(false);
          }}
        />
      )}
    </div>
  );
};

export default SumFinancial;
