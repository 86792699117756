import { CardTest } from "../styles/Card";

const ViewNumberSix = ({ formInvest, setFormInvest }) => {
  // Choice One
  const onChangeChoiceOne = (e) => {
    let value = e.target.value;
    if (value === "one") {
      setFormInvest({
        ...formInvest,
        six_question_choice_one: true,
        six_question_choice_two: false,
        six_question_choice_three: false,
        six_question_choice_four: false,
      });
    }
  };

  // Choice Two
  const onChangeChoiceTwo = (e) => {
    let value = e.target.value;
    if (value === "two") {
      setFormInvest({
        ...formInvest,
        six_question_choice_one: false,
        six_question_choice_two: true,
        six_question_choice_three: false,
        six_question_choice_four: false,
      });
    }
  };

  // Choice Three
  const onChangeChoiceThree = (e) => {
    let value = e.target.value;
    if (value === "three") {
      setFormInvest({
        ...formInvest,
        six_question_choice_one: false,
        six_question_choice_two: false,
        six_question_choice_three: true,
        six_question_choice_four: false,
      });
    }
  };

  // Choice Four
  const onChangeChoiceFour = (e) => {
    let value = e.target.value;
    if (value === "four") {
      setFormInvest({
        ...formInvest,
        six_question_choice_one: false,
        six_question_choice_two: false,
        six_question_choice_three: false,
        six_question_choice_four: true,
      });
    }
  };

  return (
    <div className=" bg-gray50 p-4 mt-5 rounded-[10px] ">
      <div className=" text-maincyan text-xs">คำถามที่ 6 จาก 10</div>
      <CardTest
        choice1={
          <div>
            เน้นเงินต้นต้องปลอดภัยและ <div>ได้รับผลตอบแทนสม่ำเสมอแต่ต่ำได้</div>
          </div>
        }
        choice2={
          <div>
            เน้นโอกาสได้รับผลตอบแทนที่สม่าเสมอ
            <div>แต่อาจเสี่ยงที่จะสูญเสียเงินต้นได้บ้าง</div>
          </div>
        }
        choice3={
          <div>
            เน้นโอกาสได้รับผลตอบแทนที่สูงขึ้น
            <div>แต่อาจเสี่ยงที่จะสูญเสียเงินต้นได้มากขึ้น</div>
          </div>
        }
        choice4={
          <div>
            เน้นผลตอบแทนสูงสุดในระยะยาว{" "}
            <div>แต่อาจเสี่ยงที่จะสูญเงินต้นส่วนใหญ่ได้</div>
          </div>
        }
        value1={"one"}
        value2={"two"}
        value3={"three"}
        value4={"four"}
        onChange1={onChangeChoiceOne}
        onChange2={onChangeChoiceTwo}
        onChange3={onChangeChoiceThree}
        onChange4={onChangeChoiceFour}
        checked1={formInvest?.six_question_choice_one}
        checked2={formInvest?.six_question_choice_two}
        checked3={formInvest?.six_question_choice_three}
        checked4={formInvest?.six_question_choice_four}
        question="6. วัตถุประสงค์หลักในการลงทุนของท่าน คือ"
      />
    </div>
  );
};

export default ViewNumberSix;
