import numeral from "numeral";
import {
  STYLE_BODY_EDU_FUND,
  STYLE_TABLE_EDU,
  STYLE_TABLE_EDU_FUND,
} from "../../../utils/useStyle";
import { useMemo } from "react";

const ViewTableInsuarance = ({ localEducationData }) => {
  const rows = useMemo(() => {
    return localEducationData?.education_items;
  }, [localEducationData]);

  return (
    <div className="w-full overflow-hidden overflow-x-scroll rounded-t-[20px]">
      {/* Header */}
      <div className=" bg-[#F9F9F9] h-11 grid grid-cols-2 items-center w-full">
        <div className={STYLE_TABLE_EDU_FUND}>เริ่มแผน</div>
        <div className={`${STYLE_TABLE_EDU_FUND} border-r-0`}>
          ควรมีทุนประกัน
        </div>
      </div>
      {/* Body */}
      {rows?.map((row, index) => {
        return (
          <div key={index} className=" grid grid-cols-2 items-center justify-center">
            <div className={`${STYLE_BODY_EDU_FUND} text-mainblue`}>
              {row.education.label}
            </div>

            <div className={`${STYLE_BODY_EDU_FUND} text-maincyan`}>
              {numeral(row.sum_insurance)?.format?.("0,0")}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ViewTableInsuarance;
