import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

const balanceStore = (set) => ({
  balanceData: null,
  setBalanceData: (balanceData) => set(() => ({ balanceData })),
  clearBalanceData: () => set(() => ({ balanceData: null })),
});

export const useBalanceStore = create(
  persist(balanceStore, {
    name: "balanceStore",
    storage: createJSONStorage(() => localStorage), // (optional) by default the 'localStorage' is used
  })
);
