import { cn, mapStatus } from "../../../utils/useFunctions";
import { PdfCard, PdfCardWhite } from "../component/pdf.c.card";
import PdfHeader from "../component/pdf.c.header";
import {
  PdfHeaderCardText,
  PdfHeaderText,
} from "../component/pdf.c.headerText";
import PdfLine from "../component/pdf.c.line";
import { STYLE_PAGE } from "../styles/pdf.style";
import { useBalanceStore } from "../../../_store/balanceStore";
import numeral from "numeral";
import { useMemo } from "react";
import HalfChartMain from "../../../components/chart/HalfChartMain";
import {
  ICON_BAHT,
  ICON_CHECK,
  ICON_CLOSE,
  ICON_GRAPH,
  ICON_SAVING,
  ICON_STAR,
  ICON_WARNING,
} from "../../../utils/useIcons";
import { STYLE_CARD_BG } from "../../../utils/useStyle";
import PdfFooter from "../component/pdf.c.footer";
import LineChartMain from "../../../components/chart/LineChartMain";
import { useRetireStore } from "../../../_store/retireStore";
import {
  dataAge,
  dataAgeAF,
  loopInvestmentAssets,
  loopInvestmentAssetsAF,
} from "../../retirement_plan/functions/functionRetriementPlan";
import { calPMT, calPMTFVA } from "../../education/functions/function";
import { calculateTax, checkSum } from "../../tax_plan/functions";
import { useTaxStore } from "../../../_store/taxplanStore";
import _ from "lodash";

function PdfPageOverviewOne({ page }) {
  const balanceData = useBalanceStore((state) => state.balanceData);
  const retireData = useRetireStore((state) => state.retireData);
  const taxData = useTaxStore((state) => state.taxData);

  const getCaltax = () => {
    let result = 0;
    result =
      taxData?.tax_income -
      taxData?.deduction_expenses -
      taxData?.deduct_summary;
    return calculateTax(result);
  };

  const displaySumAfterTaxPaid = () => {
    let result = 0;
    result =
      Number(taxData?.tax_income) -
      Number(taxData?.deduction_expenses) -
      Number(taxData?.deduct_summary_af);

    return calculateTax(result);
  };

  const displayTaxPaid = useMemo(
    () => calculateTax(taxData?.tax_income),
    [taxData?.tax_income]
  );

  const displaySumTax = useMemo(
    () => checkSum(getCaltax(), taxData?.after_tax_paid, 0, 0),
    [getCaltax(), taxData?.after_tax_paid, 0, 0]
  );

  const checkPercent = () => {
    let result = 0;
    result = ((getCaltax() - taxData?.after_tax_paid) / getCaltax()) * 100;
    if (isNaN(result)) {
      return 0;
    }
    return Number(result)?.toFixed(2);
  };

  const dataPlanBf = retireData?.bf_investment_assets?.map(
    (bf_investment_assets) => {
      return bf_investment_assets?.results;
    }
  );

  //ความมั่งคั่ง

  const getNetWealth = useMemo(() => {
    let result = 0;
    if (balanceData?.property_propotion) {
      result = balanceData?.debt_propotion
        ? Number(balanceData?.property_propotion) -
          Number(balanceData?.debt_propotion)
        : 0;
    }
    if (result > 0) {
      return result;
    } else {
      return 0;
    }
  }, [balanceData?.property_propotion, balanceData?.debt_propotion]);

  // สภาพคล่อง
  const valueProperty = useMemo(() => {
    let sum = 0;
    const expenses_permonth = balanceData?.expenses_propotion / 12;
    if (balanceData?.sum_property_asset) {
      sum =
        expenses_permonth > 0
          ? balanceData?.sum_property_asset / expenses_permonth
          : 0;
    }
    return Math.round(sum);
  }, [balanceData]);
  const getPropertyStatus = () => {
    if (!balanceData?.sum_property_asset) return;

    if (valueProperty < 6) {
      return "bad";
    } else if (valueProperty === 6) {
      return "good";
    } else {
      return "chance";
    }
  };

  // หนี้สินต่อทรัพย์สิน
  const valueDebttoTotalAsset = useMemo(() => {
    let sum = 0;
    if (balanceData?.debt_propotion) {
      sum =
        balanceData?.property_propotion > 0
          ? (balanceData?.debt_propotion / balanceData?.property_propotion) *
            100
          : 0;
    }
    return sum;
  }, [balanceData]);
  const getDebttoTotalAssetStatus = () => {
    if (!balanceData?.debt_propotion) return;

    if (valueDebttoTotalAsset > 50) {
      return "bad";
    }
    return "good";
  };

  // ชำระหนี้ต่อรายได้
  const valueDebttoSalary = useMemo(() => {
    let sum = 0;
    const debtPermonth =
      balanceData?.sum_debt_short_permonth +
      balanceData?.sum_debt_long_permonth;
    const salaryPermonth = balanceData?.salary_propotion
      ? balanceData?.salary_propotion / 12
      : 0;
    sum = salaryPermonth > 0 ? (debtPermonth / salaryPermonth) * 100 : 0;
    return sum;
  }, [balanceData]);
  const getDebttoSalaryStatus = () => {
    if (
      !balanceData?.sum_debt_short_permonth &&
      !balanceData?.sum_debt_long_permonth
    )
      return;

    if (valueDebttoSalary >= 50) {
      return "good_2";
    }
    return "bad";
  };

  // การออม
  const valueConservation = useMemo(() => {
    let sum = 0;
    if (balanceData?.sum_expenses_invest_saving) {
      sum = balanceData?.sum_expenses_invest_saving;
    }
    const finalResult =
      sum > 0 ? (sum / balanceData?.salary_propotion) * 100 : 0;
    return finalResult;
  }, [balanceData]);

  const getConservationStatus = () => {
    if (!balanceData?.sum_expenses_invest_saving) return "bad";
    if (valueConservation <= 10) {
      return "bad";
    }
    return "good";
  };



  // การลงทุน
  const valueInvest = useMemo(() => {
    let sum = 0;
    if (balanceData?.sum_property_invest) {
      sum =
        getNetWealth > 0
          ? (balanceData?.sum_property_invest / getNetWealth) * 100
          : 0;
    }
    return sum;
  }, [balanceData]);

  const getInvestStatus = () => {
    if (!balanceData?.sum_property_invest) return;

    if (valueInvest > 50) {
      return "good";
    }
    return "bad";
  };

  // วางแผนเกษียณ
  const getInvestSum = loopInvestmentAssets();
  const lastResultBF = getInvestSum[getInvestSum?.length - 1];
  const retirementAge = retireData?.age_retire;
  const retireBF = getInvestSum.find(
    (result) => result.age === retirementAge
  )?.results;
  const getremainBF = getInvestSum?.filter((thisRemain, i) => {
    if (thisRemain?.results <= 0) return { ...thisRemain };
  })?.[0];
  const remainBF = getremainBF?.age - 1;
  const beforeDiffYear = retireData?.age_retire - retireData?.age_current;
  const sumBeforeRetireInvest = useMemo(() => {
    const convertedMonth = beforeDiffYear * 12;
    const sumPMT = calPMTFVA(
      // retireData?.investment_bf_retirement / 100 / 12,
      // convertedMonth,
      // -lastResultBF?.results * -1
      lastResultBF?.results < 0
        ? Math.abs(lastResultBF?.results)
        : lastResultBF?.results,
      retireData?.investment_bf_retirement / 100 / 12,
      convertedMonth
    );
    return sumPMT;
  }, [retireData]);
  const strategyChange = retireData?.strategy_sum ? true : false;

  const dataPlanAF = retireData?.af_investment_assets?.map(
    (af_investment_assets) => {
      return af_investment_assets?.results;
    }
  );

  const getInvestSumAF = loopInvestmentAssetsAF();
  const lastResultAF = getInvestSumAF[getInvestSumAF?.length - 1];
  const getremainAF = getInvestSumAF?.filter((thisRemain, i) => {
    if (thisRemain?.results <= 0) return { ...thisRemain };
  })?.[0];
  const remainAF = Number(getremainAF?.age) - 1;
  const afterDiffYear =
    retireData?.strategy_age_retire - retireData?.age_current;

  const convertYearToMonth = (year) => {
    return year * 12;
  };

  const sumAfterRetireInvest = useMemo(() => {
    const convertedMonth = convertYearToMonth(afterDiffYear);
    const sumPMT = calPMTFVA(
      // retireData?.strategy_investment_bf_retirement / 100 / 12,
      // convertedMonth,
      // -lastResultAF?.results * -1
      lastResultAF?.results < 0
        ? Math.abs(lastResultAF?.results)
        : lastResultAF?.results,
      retireData?.investment_af_retirement / 100 / 12,
      convertedMonth
    );
    return sumPMT;
  }, [retireData]);

  const getDetailBF = () => {
    if (strategyChange) {
      if (lastResultBF?.results > 0) {
        return [
          {
            icon: <ICON_CHECK className=" w-[14px] h-[14px] text-maincyan" />,
            render: () => {
              return (
                <div className=" flex gap-1">
                  <div className="text-[10px] ">
                    {/* คาดการณ์สินทรัพย์เพื่อการลงทุน ณ อายุ */}
                    เริ่มวางแผนเกษียณตอนอายุ{" "}
                  </div>
                  <div
                    className={cn("text-[10px] font-semibold flex space-x-1")}
                  >
                    <div className="">
                      {_.isNaN(retireData?.age_current)
                        ? "-"
                        : retireData?.age_current}
                    </div>
                    <span className="text-mainblue font-normal">ปี</span>
                  </div>
                </div>
              );
            },
          },
          {
            icon: (
              <ICON_WARNING className=" w-[14px] h-[14px] text-mainyellow" />
            ),
            render: () => {
              return (
                <div className=" flex gap-1">
                  <div className="text-[10px] ">สามารถใช้จนได้ถึงอายุ</div>
                  <div className={cn("text-[10px] flex space-x-1")}>
                    <div className="font-semibold">
                      {retireData?.strategy_age_dead
                        ? retireData?.strategy_age_dead
                        : retireData?.age_dead || "-"}
                    </div>
                    <span className="text-mainblue font-normal">ปี</span>
                    <div>แต่อย่าประมาทนะ</div>
                  </div>
                </div>
              );
            },
            // title: "สามารถใช้จนได้ถึงอายุ",
            // value: retireData?.strategy_age_dead
            //   ? retireData?.strategy_age_dead
            //   : retireData?.age_dead,
            // unit: "ปี แต่อย่าประมาทนะ",
          },
        ];
      }
      return [
        {
          icon: (
            <ICON_CHECK className=" w-[14px] h-[14px] text-maincyan flex-none" />
          ),
          render: () => {
            return (
              <div className=" flex gap-1">
                <div className="text-[10px] ">
                  {/* คาดการณ์สินทรัพย์เพื่อการลงทุน ณ อายุ{" "} */}
                  เริ่มวางแผนเกษียณตอนอายุ{" "}
                </div>
                <div className={cn("text-[10px] flex space-x-1")}>
                  <div className="font-semibold">
                    {_.isNaN(retireData?.age_current)
                      ? "-"
                      : retireData?.age_current}{" "}
                    ปี
                  </div>
                </div>
              </div>
            );
          },
        },
        {
          icon: <ICON_WARNING className=" w-[14px] h-[14px] text-mainyellow" />,
          render: () => {
            return (
              <div className=" flex flex-col">
                <div className="flex gap-1">
                  <div className="text-[10px] ">สามารถใช้จนได้ถึงอายุ</div>
                  <div className={cn("text-[10px] flex space-x-1")}>
                    <div className="font-semibold">
                      {_.isNaN(remainBF) ? "-" : remainBF} ปี
                    </div>
                  </div>
                </div>
              </div>
            );
          },
        },
        {
          icon: <ICON_CLOSE className=" w-[14px] h-[14px] text-maincyan" />,
          render: () => {
            return (
              <div className="flex flex-col">
                <div className=" flex gap-1">
                  <div className="text-[10px] ">
                    ถ้าต้องการมีเงินใช้จ่ายจนถึงอายุ{" "}
                  </div>
                  <div className={cn("text-[10px] font-semibold")}>
                    {_.isNaN(retireData?.age_dead) ? "-" : retireData?.age_dead}{" "}
                    ปี
                  </div>
                </div>
                <div>
                  <div className="text-[10px] ">
                    ยังขาดอยู่ทั้งหมด{" "}
                    <span className=" font-medium text-black">
                      {lastResultBF?.results < 0
                        ? numeral(lastResultBF?.results * -1)?.format("0,0")
                        : numeral(lastResultBF?.results)?.format("0,0")}
                      {""} บาท
                    </span>
                  </div>
                </div>
              </div>
            );
          },
        },
        {
          icon: <ICON_STAR className=" w-[14px] h-[14px] text-maingreen" />,
          render: () => {
            return (
              <div className="flex flex-col">
                <div className=" flex gap-1">
                  <div className="text-[10px] ">ควรวางแผนลงทุนผลตอบแทน </div>
                  <div className={cn("text-[10px] font-semibold")}>
                    {_.isNaN(retireData?.investment_bf_retirement)
                      ? ""
                      : retireData?.investment_bf_retirement}{" "}
                    %
                  </div>
                </div>
                <div className="text-[10px] flex space-x-1">
                  <div>เป็นระยะเวลา</div>
                  <span className=" font-semibold">
                    {_.isNaN(beforeDiffYear) ? "-" : beforeDiffYear} ปี
                  </span>{" "}
                  <div>เดือนละ</div>
                  <span className=" font-semibold text-mainblue">
                    {numeral(sumBeforeRetireInvest)?.format("0,0")} บาท
                  </span>
                </div>
              </div>
            );
          },
        },
      ];
    } else {
      if (lastResultBF?.results > 0) {
        return [
          {
            icon: <ICON_CHECK className=" w-[14px] h-[14px] text-maincyan" />,
            render: () => {
              return (
                <div className="text-[10px] flex flex-col gap-1">
                  <div className=" ">
                    คาดการณ์สินทรัพย์เพื่อการลงทุน ณ วันเกษียณอายุ
                  </div>
                  <div className="flex">
                    <span className="font-medium mr-1">
                      {_.isNaN(retireData?.age_retire)
                        ? "-"
                        : retireData?.age_retire}{" "}
                      ปี
                    </span>
                    <div className="flex space-x-1">
                      <div>จำนวน</div>
                      <span className=" font-medium text-mainblue">
                        {numeral(retireBF)?.format("0,0.00")}
                      </span>
                    </div>
                  </div>
                </div>
              );
            },
          },
          {
            icon: (
              <ICON_WARNING className=" w-[14px] h-[14px] text-mainyellow" />
            ),
            render: () => {
              return (
                <div className=" flex gap-1">
                  <div className="text-[10px] ">สามารถใช้จนได้ถึงอายุ</div>
                  <div className={cn("text-[10px] flex space-x-1")}>
                    <div className="font-semibold">
                      {_.isNaN(retireData?.age_dead)
                        ? "-"
                        : retireData?.age_dead}
                    </div>
                    <span className="text-mainblue font-normal">ปี</span>
                    <div>แต่อย่าประมาทนะ</div>
                  </div>
                </div>
              );
            },
          },
        ];
      }
      return [
        {
          icon: <ICON_WARNING className=" w-[14px] h-[14px] text-mainyellow" />,
          render: () => {
            return (
              <div className=" flex gap-1">
                <div className="text-[10px] ">สามารถใช้จนได้ถึงอายุ</div>
                <div className={cn("text-[10px] flex space-x-1")}>
                  <div className="font-semibold">
                    {" "}
                    {_.isNaN(remainBF) ? "-" : remainBF} ปี
                  </div>
                </div>
              </div>
            );
          },
        },
        {
          icon: <ICON_CLOSE className=" w-[14px] h-[14px] text-mainred" />,
          render: () => {
            return (
              <div className="flex flex-col">
                <div className=" flex gap-1 flex-wrap ">
                  <div className="text-[10px] ">
                    ถ้าต้องการมีเงินใช้จ่ายจนถึงอายุ
                  </div>
                  <div className={cn("text-[10px] flex space-x-1")}>
                    <div className="font-semibold">
                      {_.isNaN(retireData?.age_dead)
                        ? "-"
                        : retireData?.age_dead}{" "}
                      ปี
                    </div>
                  </div>
                </div>
                <div className="flex gap-1 text-[10px]">
                  <div className="  text-[10px]">ยังขาดอยู่ทั้งหมด</div>
                  <div className={cn("text-[10px] font-semibold text-black")}>
                    {_.isNaN(lastResultBF?.results)
                      ? "-"
                      : numeral(
                          lastResultBF?.results < 0
                            ? lastResultBF?.results * -1
                            : lastResultBF?.results
                        ).format("0,0")}{" "}
                    บาท
                  </div>
                </div>
              </div>
            );
          },
        },
        {
          icon: <ICON_STAR className=" w-[14px] h-[14px] text-maincyan" />,
          render: () => {
            return (
              <div className="flex flex-col">
                <div className=" flex gap-1">
                  <div className="text-[10px] ">ควรวางแผนลงทุนผลตอบแทน</div>
                </div>
                <div className={cn("text-[10px] font-semibold")}>
                  {retireData?.investment_bf_retirement}
                  <span className=" font-normal"> เป็นระยะเวลา </span>
                  <span className="font-semibold">
                    {_.isNaN(beforeDiffYear) ? "-" : beforeDiffYear} ปี{" "}
                  </span>
                  <span className="font-normal">เดือนละ </span>
                  <span className="text-mainblue">
                    {_.isNaN(sumBeforeRetireInvest)
                      ? "-"
                      : numeral(sumBeforeRetireInvest)?.format("0,0")}
                  </span>
                </div>
              </div>
            );
          },
        },
      ];
    }
  };

  const getDetailAF = () => {
    if (strategyChange) {
      if (lastResultAF?.results > 0) {
        return [
          {
            icon: <ICON_CHECK className=" w-[14px] h-[14px] text-maincyan" />,
            render: () => {
              return (
                <div className=" flex gap-1">
                  <div className="text-[10px] ">
                    {/* คาดการณ์สินทรัพย์เพื่อการลงทุน ณ อายุ */}
                    เริ่มวางแผนเกษียณตอนอายุ{" "}
                  </div>
                  <div
                    className={cn("text-[10px] font-semibold flex space-x-1")}
                  >
                    <div className="">
                      {_.isNaN(retireData?.age_current)
                        ? "-"
                        : retireData?.age_current}{" "}
                      ปี
                    </div>
                  </div>
                </div>
              );
            },
          },
          {
            icon: (
              <ICON_WARNING className=" w-[14px] h-[14px] text-mainyellow" />
            ),
            render: () => {
              return (
                <div className=" flex gap-1">
                  <div className="text-[10px] ">สามารถใช้จนได้ถึงอายุ</div>
                  <div className={cn("text-[10px] flex space-x-1")}>
                    <div className="font-semibold">
                      {retireData?.strategy_age_dead
                        ? retireData?.strategy_age_dead
                        : retireData?.age_dead || "-"}
                    </div>
                    <span className="text-mainblue font-normal">ปี</span>
                    <div>แต่อย่าประมาทนะ</div>
                  </div>
                </div>
              );
            },
          },
        ];
      }
      return [
        {
          icon: (
            <ICON_CHECK className=" w-[14px] h-[14px] text-maincyan flex-none" />
          ),
          render: () => {
            return (
              <div className=" flex gap-1">
                <div className="text-[10px] ">
                  {/* คาดการณ์สินทรัพย์เพื่อการลงทุน ณ อายุ{" "} */}
                  เริ่มวางแผนเกษียณตอนอายุ{" "}
                </div>
                <div className={cn("text-[10px] flex space-x-1")}>
                  <div className="font-semibold">
                    {_.isNaN(retireData?.age_current)
                      ? "-"
                      : retireData?.age_current}{" "}
                    ปี
                  </div>
                </div>
              </div>
            );
          },
        },
        {
          icon: <ICON_WARNING className=" w-[14px] h-[14px] text-mainyellow" />,
          render: () => {
            return (
              <div className=" flex flex-col">
                <div className="flex gap-1">
                  <div className="text-[10px] ">สามารถใช้จนได้ถึงอายุ</div>
                  <div className={cn("text-[10px] flex space-x-1")}>
                    <div className="font-semibold">
                      {_.isNaN(remainAF) ? "-" : remainAF} ปี
                    </div>
                  </div>
                </div>
              </div>
            );
          },
        },
        {
          icon: <ICON_CLOSE className=" w-[14px] h-[14px] text-maincyan" />,
          render: () => {
            return (
              <div className="flex flex-col">
                <div className=" flex gap-1">
                  <div className="text-[10px] ">
                    ถ้าต้องการมีเงินใช้จ่ายจนถึงอายุ{" "}
                  </div>
                  <div className={cn("text-[10px] font-semibold")}>
                    {_.isNaN(retireData?.age_dead) ? "-" : retireData?.age_dead}{" "}
                    ปี
                  </div>
                </div>
                <div>
                  <div className="text-[10px] ">
                    ยังขาดอยู่ทั้งหมด{" "}
                    <span className=" font-medium text-black">
                      {lastResultAF?.results < 0
                        ? numeral(lastResultAF?.results * -1)?.format("0,0")
                        : numeral(lastResultAF?.results)?.format("0,0")}
                      {""} บาท
                    </span>
                  </div>
                </div>
              </div>
            );
          },
        },
        {
          icon: <ICON_STAR className=" w-[14px] h-[14px] text-maingreen" />,
          render: () => {
            return (
              <div className="flex flex-col">
                <div className=" flex gap-1">
                  <div className="text-[10px] ">ควรวางแผนลงทุนผลตอบแทน </div>
                  <div className={cn("text-[10px] font-semibold")}>
                    {_.isNaN(retireData?.investment_af_retirement)
                      ? ""
                      : retireData?.investment_af_retirement}{" "}
                    %
                  </div>
                </div>
                <div className="text-[10px] flex space-x-1">
                  <div>เป็นระยะเวลา</div>
                  <span className=" font-semibold">
                    {_.isNaN(afterDiffYear) ? "-" : afterDiffYear} ปี
                  </span>{" "}
                  <div>เดือนละ</div>
                  <span className=" font-semibold text-mainblue">
                    {numeral(sumAfterRetireInvest)?.format("0,0")} บาท
                  </span>
                </div>
              </div>
            );
          },
        },
      ];
    } else {
      if (lastResultAF?.results > 0) {
        return [
          {
            icon: <ICON_CHECK className=" w-[14px] h-[14px] text-maincyan" />,
            render: () => {
              return (
                <div className="text-[10px] flex flex-col gap-1">
                  <div className=" ">
                    คาดการณ์สินทรัพย์เพื่อการลงทุน ณ วันเกษียณอายุ
                  </div>
                  <div className="flex">
                    <span className="font-medium mr-1">
                      {_.isNaN(retireData?.age_retire)
                        ? "-"
                        : retireData?.age_retire}{" "}
                      ปี
                    </span>
                    <div className="flex space-x-1">
                      <div>จำนวน</div>
                      <span className=" font-medium text-mainblue">
                        {numeral(retireBF)?.format("0,0.00")}
                      </span>
                    </div>
                  </div>
                </div>
              );
            },
          },
          {
            icon: (
              <ICON_WARNING className=" w-[14px] h-[14px] text-mainyellow" />
            ),
            render: () => {
              return (
                <div className=" flex gap-1">
                  <div className="text-[10px] ">สามารถใช้จนได้ถึงอายุ</div>
                  <div className={cn("text-[10px] flex space-x-1")}>
                    <div className="font-semibold">
                      {_.isNaN(retireData?.age_dead)
                        ? "-"
                        : retireData?.age_dead}
                    </div>
                    <span className="text-mainblue font-normal">ปี</span>
                    <div>แต่อย่าประมาทนะ</div>
                  </div>
                </div>
              );
            },
          },
        ];
      }
      return [
        {
          icon: <ICON_WARNING className=" w-[14px] h-[14px] text-mainyellow" />,
          render: () => {
            return (
              <div className=" flex gap-1">
                <div className="text-[10px] ">สามารถใช้จนได้ถึงอายุ</div>
                <div className={cn("text-[10px] flex space-x-1")}>
                  <div className="font-semibold">
                    {" "}
                    {_.isNaN(remainAF) ? "-" : remainAF} ปี
                  </div>
                </div>
              </div>
            );
          },
        },
        {
          icon: <ICON_CLOSE className=" w-[14px] h-[14px] text-mainred" />,
          render: () => {
            return (
              <div className="flex flex-col">
                <div className=" flex gap-1 flex-wrap ">
                  <div className="text-[10px] ">
                    ถ้าต้องการมีเงินใช้จ่ายจนถึงอายุ
                  </div>
                  <div className={cn("text-[10px] flex space-x-1")}>
                    <div className="font-semibold">
                      {_.isNaN(retireData?.age_dead)
                        ? "-"
                        : retireData?.age_dead}{" "}
                      ปี
                    </div>
                  </div>
                </div>
                <div className="flex gap-1 text-[10px]">
                  <div className="  text-[10px]">ยังขาดอยู่ทั้งหมด</div>
                  <div className={cn("text-[10px] font-semibold text-black")}>
                    {_.isNaN(lastResultAF?.results)
                      ? "-"
                      : numeral(
                          lastResultAF?.results < 0
                            ? lastResultAF?.results * -1
                            : lastResultAF?.results
                        ).format("0,0")}{" "}
                    บาท
                  </div>
                </div>
              </div>
            );
          },
        },
        {
          icon: <ICON_STAR className=" w-[14px] h-[14px] text-maincyan" />,
          render: () => {
            return (
              <div className="flex flex-col">
                <div className=" flex gap-1">
                  <div className="text-[10px] ">ควรวางแผนลงทุนผลตอบแทน</div>
                </div>
                <div className={cn("text-[10px] font-semibold")}>
                  {retireData?.investment_af_retirement}
                  <span className=" font-normal"> เป็นระยะเวลา </span>
                  <span className="font-semibold">
                    {_.isNaN(afterDiffYear) ? "-" : afterDiffYear} ปี{" "}
                  </span>
                  <span className="font-normal">เดือนละ </span>
                  <span className="text-mainblue">
                    {_.isNaN(sumAfterRetireInvest)
                      ? "-"
                      : numeral(sumAfterRetireInvest)?.format("0,0")}
                  </span>
                </div>
              </div>
            );
          },
        },
      ];
    }
  };

  const getCanRetireText = (lastResult) => {
    if (!lastResult) {
      return "(ยังไม่ได้วางแผน)";
    }
    if (lastResult?.results > 0) {
      return "สามารถเกษียณได้";
    }
    return "ไม่สามารถเกษียณได้";
  };

  return (
    <div
      style={{
        backgroundImage: `url(/images/bg_pdf.svg)`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "bottom right",
      }}
      className={cn(STYLE_PAGE)}
    >
      <PdfHeader />
      <PdfLine />
      <PdfHeaderText />
      <PdfCard className="space-y-2.5">
        {/* อัตราส่วนทางการเงิน */}
        <PdfCardWhite className={"flex flex-col pt-0"}>
          <div className="grid grid-cols-2 gap-6 mx-2.5 mt-2.5 ">
            {/* LEFT ITEMS */}
            <div className="flex flex-col space-y-1">
              <PdfHeaderCardText
                title="รายรับ - รายจ่าย"
                icon="/images/financial-analysis 1.svg"
              />
              {[
                {
                  title: "รายรับรวมทั้งหมด",
                  value: balanceData?.salary_propotion,
                  classNameValue: "text-maingreen",
                },
                {
                  title: "รายจ่ายรวมทั้งหมด",
                  value: balanceData?.expenses_propotion,
                  classNameValue: "text-mainorange",
                },
                {
                  title: "กระแสเงินสดสุทธิ",
                  value: balanceData?.temp_net_cash_flow
                    ? balanceData?.temp_net_cash_flow
                    : balanceData?.net_cash_flow,
                  classNameValue: "text-mainpurple",
                },
              ].map((item, index) => {
                return (
                  <div key={index} className="flex justify-between">
                    <div className="text-[12px] font-medium">{item?.title}</div>
                    <div
                      className={cn(
                        "text-[12px] font-bold ",
                        item?.classNameValue
                      )}
                    >
                      {numeral(item?.value).format("0,0")} บาท
                    </div>
                  </div>
                );
              })}
            </div>

            {/* RIGHT ITEMS */}
            <div className="flex flex-col space-y-1">
              <PdfHeaderCardText
                title="อัตราส่วนทางการเงิน"
                icon="/images/financial-analysis 1.svg"
              />
              {[
                {
                  title: "สภาพคล่อง",
                  value: valueProperty,
                  status: getPropertyStatus(),
                  prefixValue: "เท่า",
                },
                {
                  title: "หนี้สินต่อทรัพย์สิน",
                  value: valueDebttoTotalAsset,
                  status: getDebttoTotalAssetStatus(),
                  prefixValue: "%",
                  formatValue: "0.00",
                },
                {
                  title: "การชำระหนี้ต่อรายได้",
                  value: valueDebttoSalary,
                  status: getDebttoSalaryStatus(),
                  prefixValue: "%",
                  formatValue: "0.00",
                },
                {
                  title: "การออม",
                  value: valueConservation,
                  status: getConservationStatus(),
                  prefixValue: "%",
                  formatValue: "0.00",
                },
                {
                  title: "การลงทุน",
                  value: valueInvest,
                  status: getInvestStatus(),
                  prefixValue: "%",
                  formatValue: "0.00",
                },
              ].map((item, index) => {
                return (
                  <div key={index} className="flex justify-between">
                    <div className="flex space-x-2">
                      <div className="text-[12px] font-[500]">
                        {item?.title}
                      </div>
                      <div className="text-[12px] text-mainblue font-bold ">
                        {numeral(item?.value).format(item.formatValue ?? "0")}{" "}
                        {item?.prefixValue}
                      </div>
                    </div>

                    <div
                      className={cn(
                        "text-[12px] font-bold ",
                        {
                          "text-[#FF0000]": item?.status === "bad",
                          "text-maingreen": ["good", "good_2"].includes(
                            item?.status
                          ),
                          "text-mainblue": item?.status === "chance",
                          "text-maingray":
                            mapStatus(item?.status) === "ไม่มีข้อมูล",
                        },
                        item?.classNameValue
                      )}
                    >
                      {mapStatus(item?.status)}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </PdfCardWhite>

        {/* ความั่งคั่งสุทธิ & วางแผนภาษี & ก่อนเกษียณ หลังเกษียณ*/}
        <div className=" grid grid-cols-2 gap-2.5 ">
          {/* ความั่งคั่งสุทธิ */}
          <PdfCardWhite className={"flex flex-col  "}>
            <PdfHeaderCardText
              title="งบดุล"
              icon="/images/wealth.svg"
            />
            <div className=" grid grid-cols-5 gap-2 w-full h-full">
              <div className=" w-full h-full bg-[#C4F3BD] rounded-[4px]  col-span-3">
                <div className=" flex flex-col justify-center items-center h-full ">
                  <img
                    src="/images/wealth_green.svg"
                    className="w-6 h-6"
                    alt=""
                  />
                  <div className=" text-mainblue font-medium text-[12px] mt-[3px] ">
                    สินทรัพย์
                  </div>
                  <div className="text-mainblue font-semibold text-[12px] leading-3 pt-1">
                    {numeral(balanceData?.property_propotion).format("0,0")} บาท
                  </div>
                </div>
              </div>
              <div className=" grid grid-rows-2 gap-[6px]  col-span-2">
                <div className=" bg-[#FFCCCC] w-full h-full  rounded-[4px] py-2">
                  <div className=" flex flex-col justify-center items-center h-full ">
                    <img
                      src="/images/debt_pdf2.svg"
                      className="w-6 h-6"
                      alt=""
                    />
                    <div className=" text-mainblue font-medium text-[12px] mt-[3px] ">
                      หนี้สิน
                    </div>
                    <div className="text-mainblue font-semibold text-[12px] leading-3 pt-1">
                      {numeral(balanceData?.debt_propotion).format("0,0")} บาท
                    </div>
                  </div>
                </div>
                <div className=" bg-[#CEE3FF] w-full h-full rounded-[4px] py-2">
                  <div className=" flex flex-col justify-center items-center h-full ">
                    <img
                      src="/images/wealth_blue.svg"
                      className="w-6 h-6"
                      alt=""
                    />
                    <div className=" text-mainblue font-medium text-[12px] mt-[3px] ">
                      ความมั่งคั่งสุทธิ
                    </div>
                    <div className="text-mainblue font-semibold text-[12px] leading-3 pt-1">
                      {numeral(getNetWealth).format("0,0")} บาท
                      {/* {numeral(balanceData?.net_wealth).format("0,0")} บาท */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </PdfCardWhite>

          {/* วางแผนภาษี */}
          <PdfCardWhite className={" flex flex-col "}>
            <PdfHeaderCardText title="วางแผนภาษี" icon="/images/tax.svg" />
            <div className=" px-2.5">
              <HalfChartMain
                // className="  mt-10"
                // margin=" -mt-96 "
                chartWidth={"w-[118px] mt-10"}
                fontSize="text-sm"
                text={false}
                value={`0`}
                beforevalue={getCaltax()}
                aftervalue={taxData?.after_tax_paid}
                maxvalue={displayTaxPaid}
                // chartWidth="w-[170px]"
                // fontSize="text-[15px]"
                mtText="-mt-12"
              />
              <div className=" flex justify-center gap-2.5 w-full -mt-[60px]">
                <div
                  className={`bg-gray50 text-[10px] rounded-md py-[7px] px-2 w-20 text-mainblue font-semibold mt-1 h-4 flex gap-[5px] justify-center items-center`}
                >
                  <ICON_GRAPH className="w-[14px] h-[14px] text-maincyan " />
                  <span className=" flex-none"> {checkPercent()} %</span>
                </div>
                <div
                  className={`bg-gray50 text-[10px] rounded-md py-[7px] px-2 w-20 text-mainblue font-semibold mt-1 h-4 flex gap-[5px] justify-center items-center`}
                >
                  <ICON_BAHT className="w-[14px] h-[14px] text-maincyan " />
                  {numeral(displaySumTax).format("0,0")}
                </div>
              </div>
              <div className="flex flex-col space-y-1 mt-1">
                {[
                  {
                    title: "ก่อนวางแผน",
                    value:
                      String(getCaltax()) === "Infinity" ? "0" : getCaltax(),
                    classNameValue: "text-mainred",
                  },
                  {
                    title: "หลังวางแผน",
                    value:
                      String(displaySumAfterTaxPaid()) === "Infinity"
                        ? "0"
                        : displaySumAfterTaxPaid(),
                    classNameValue: "text-maingreen",
                  },
                  {
                    title: "ภาษีหัก ณ ที่จ่าย",
                    value: taxData?.tax_withholding,
                    classNameValue: "text-mainblue",
                  },
                ].map((item, index) => {
                  return (
                    <div key={index} className="flex justify-between">
                      <div className="text-[10px] font-medium">
                        {item?.title}
                      </div>
                      <div
                        className={cn("text-[10px] font-medium  text-mainblue")}
                      >
                        <span
                          className={cn(
                            " mr-2.5 font-semibold",
                            item?.classNameValue
                          )}
                        >
                          {" "}
                          {numeral(item?.value).format("0,0")}
                        </span>{" "}
                        บาท
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className=" flex justify-center items-center gap-[5px] font-semibold text-xs mt-1">
                <div className=" text-maincyan">
                  {taxData?.final_tax < 0 ? "ขอคืนได้อีก" : "ภาษีที่ต้องจ่าย"}
                </div>
                <div className=" text-mainorange">
                  {numeral(Math.abs(taxData?.final_tax))?.format("0,0")} บาท
                </div>
              </div>
            </div>
          </PdfCardWhite>

          {/* ก่อนวางแผน */}
          <PdfCardWhite className={"flex flex-col  "}>
            <PdfHeaderCardText
              title={`ก่อนวางแผน (${getCanRetireText(lastResultBF)})`}
              icon="/images/retire_bf.svg"
            />
            <div className=" px-4 w-[220px] mx-auto">
              <LineChartMain
                // labels={labelsbf}
                // dataPlan={databf}
                // height={130}
                axisSize={6}
                labels={dataAge()}
                dataPlan={dataPlanBf}
                fontSize="text-[10px]"
                head={`สินทรัพย์เพื่อการลงทุนสุทธิ`}
              />
            </div>
            <div className="flex flex-col space-y-1 mt-1">
              {getDetailBF().map((item, index) => {
                return (
                  <div key={index} className="flex gap-2.5">
                    <div>{item?.icon}</div>
                    {item?.render && item?.render()}
                  </div>
                );

                // return (
                //   <div key={index} className="flex gap-2.5 ">
                //     <div>{item?.icon}</div>
                //     <div>
                //       <div className=" flex gap-1">
                //         <div className="text-[10px] ">{item?.title}</div>
                //         <div className={cn("text-[10px] font-semibold")}>
                //           {numeral(item?.value).format("0,0")}
                //           <span className="text-mainblue font-normal">
                //             {" "}
                //             {item?.unit}
                //           </span>
                //         </div>
                //       </div>
                //       {item?.title2 && (
                //         <div className=" flex gap-1 text-[10px]">
                //           <div>{item?.title2}</div>
                //           {item?.value2 && (
                //             <div className={cn("text-[10px] font-semibold")}>
                //               {numeral(item?.value2).format("0,0")}
                //               <span className="text-mainblue font-normal">
                //                 {" "}
                //                 {item?.unit2}
                //               </span>
                //             </div>
                //           )}
                //         </div>
                //       )}
                //     </div>
                //   </div>
                // );
              })}
            </div>
          </PdfCardWhite>

          {/* หลังวางแผน */}
          <PdfCardWhite className={"flex flex-col  "}>
            <PdfHeaderCardText
              title={`หลังปรับกลยุทธ์ (${getCanRetireText(lastResultAF)})`}
              icon="/images/retire_bf.svg"
            />
            <div className=" px-4 w-[220px] mx-auto">
              <LineChartMain
                axisSize={6}
                labels={dataAgeAF()}
                dataPlan={dataPlanAF}
                fontSize="text-[10px]"
                head={`สินทรัพย์เพื่อการลงทุนสุทธิ`}
              />
            </div>
            <div className="flex flex-col space-y-1 mt-1">
              {getDetailAF().map((item, index) => {
                return (
                  <div key={index} className="flex gap-2.5">
                    <div>{item?.icon}</div>
                    {item?.render && item?.render()}
                  </div>
                );
                // return (
                //   <div key={index} className="flex gap-2.5 ">
                //     <div>{item?.icon}</div>
                //     <div>
                //       <div className=" flex gap-1">
                //         <div className="text-[10px] ">{item?.title}</div>
                //         <div className={cn("text-[10px] font-semibold")}>
                //           {numeral(item?.value).format("0,0")}
                //           <span className="text-mainblue font-normal">
                //             {" "}
                //             {item?.unit}
                //           </span>
                //           <span className=" font-normal ml-1">
                //             {item?.title2}
                //           </span>
                //         </div>
                //       </div>
                //     </div>
                //   </div>
                // );
              })}
            </div>
          </PdfCardWhite>
        </div>
      </PdfCard>
      <PdfFooter page={page} />
    </div>
  );
}

export default PdfPageOverviewOne;
