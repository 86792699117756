import { cn } from "../../../utils/useFunctions";

function PdfLine({ className }) {
  return (
    <div
      className={cn("w-full h-[0.5px] bg-mainyellow mt-3 mb-4", className)}
    ></div>
  );
}

export default PdfLine;
