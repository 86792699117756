import { Drawer } from "@material-tailwind/react";
import { STYLE_MAX_W_DRAWER_TAX } from "../../../utils/useStyle";
import { MTButton } from "../../../components/button/MTButton";
import { ImageMain } from "../../../components/img/Img.Main";
import { ICON_CLOSE, ICON_CLOSE_NO_CIIRCLE } from "../../../utils/useIcons";
import { DonutChartDebtMain } from "../styles/ChartDebt";

export const VIDrawerDebtDetails = ({ openInfo, setOpenInfo, onClick }) => {
  return (
    <Drawer
      placement="bottom"
      open={openInfo}
      className=" !max-h-screen overflow-y-scroll"
    >
      <div>
        <div className={STYLE_MAX_W_DRAWER_TAX}>
          <ICON_CLOSE_NO_CIIRCLE
            className={` w-6 h-6 absolute top-6 right-6`}
            onClick={() => {
              setOpenInfo(false);
            }}
          />
          <ImageMain
            className=" w-[144px] h-[140px] mx-auto"
            src="../images/debt_img.png"
          />
          <div className=" text-mainblue font-semibold text-center">
            ผลลัพธ์วางแผนหนี้สิน
          </div>
          <div className="flex gap-[11px] items-center justify-center ">
            <div className=" text-black font-semibold text-sm">
              เจ้าหนี้ได้เงินทั้งหมด
            </div>
            <div className=" text-maincyan font-medium text-xl">24,000</div>
          </div>
          <DonutChartDebtMain
            data1={20000}
            data2={4000}
            titleData1={"เงินต้น"}
            value_data1={20000}
            titleData2={"ดอกเบี้ย"}
            value_data2={4000}
          />
          <div className=" w-full h-fit">
            <div className="bg-[#f6f6f6] flex gap-[3px] px-3 py-2 mt-5 rounded-t-[20px]">
              <ImageMain src="../images/lamp.png" className=" w-5 h-5" />
              <div className=" text-black font-semibold ">
                กลยุทธ์การรับมือหนี้
              </div>
            </div>
            <div className=" bg-[#fcfcfc] rounded-b-[20px] text-black text-sm p-4 space-y-1">
              <div className=" ">1. งดการสร้างหนี้ที่ไม่ก่อให้เกิดรายได้</div>
              <div className=" ">
                2. หาทางลดอัตราดอกเบี้ยด้วยการต่อรองกับ สถาบันการเงิน
              </div>
              <div className=" ">
                3. เพิ่มจำนวนเงินผ่อนชำระในแต่ละงวด ในหนี้ที่ไม่ Fixed Rate
                เพื่อให้ยอดหนี้สินรวมหมดเร็วขึ้น และเสียดอกเบี้ยน้อยลง
              </div>
            </div>
          </div>
          <div className={`flex gap-3 items-center justify-center mt-4`}>
            <MTButton
              width="w-fit"
              text="ตารางการผ่อนชำระ"
              color={`border-maincyan bg-white `}
              colortext={`text-maincyan font-medium  `}
              variant="outlined"
              onClick={onClick}
            />
          </div>
        </div>
      </div>
      <ImageMain
        src="/images/bgblue.png"
        className={`w-full absolute -bottom-10 -z-10`}
      />
    </Drawer>
  );
};
