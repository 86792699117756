import { cn } from "../../utils/useFunctions";

const Container = ({ children, className }) => {
  return (
    <div className={cn(className)}>
      <div className={`  pt-[56px] h-full `}>{children}</div>
    </div>
  );
};

export default Container;
