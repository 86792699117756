import { useRetireStore } from "../../../_store/retireStore";
import { useSettingStore } from "../../../_store/settingStore";
import { avgWeight } from "./functionMain";

// ------------------------------------------ ทดสอบความอ่อนไหว ก่อนวางแผน ------------------------------------------ //
//ปีที่
export const getYearAFSensitive = () => {
  const retireData = useRetireStore.getState().retireData;
  let sum = 0;
  sum =
    (retireData?.strategy_age_dead
      ? Number(retireData?.strategy_age_dead)
      : Number(retireData?.age_dead)) - retireData?.age_current;
  return sum;
};

//อายุ
export const dataAgeAFSensitive = () => {
  const retireData = useRetireStore.getState().retireData;
  let age = [];
  for (
    let i = retireData?.age_current;
    i <=
    (retireData?.strategy_age_dead
      ? Number(retireData?.strategy_age_dead)
      : Number(retireData?.age_dead));
    i++
  ) {
    age.push(i);
  }
  return age;
};

//รายได้หลังปรับกลยุทธ์
export const loopIncomeAFSensitive = () => {
  const retireData = useRetireStore.getState().retireData;
  let results = [];
  let income = Number(retireData?.strategy_salary)
    ? Number(retireData?.strategy_salary)
    : Number(retireData?.salary);

  for (let i = 0; i <= getYearAFSensitive(); i++) {
    const monthlyInterest = Math.pow(
      1 +
        (Number(retireData?.strategy_salary_peryear)
          ? Number(retireData?.strategy_salary_peryear)
          : Number(retireData?.salary_peryear)) /
          100,
      i
    );
    const monthlyPayment =
      (income + retireData?.strategy_income) * 12 * monthlyInterest;
    if (
      i >
      (retireData?.strategy_age_retire
        ? retireData?.strategy_age_retire
        : retireData?.age_retire) -
        retireData?.age_current
    ) {
      results.push(
        (Number(retireData?.strategy_passive_income_retire)
          ? Number(retireData?.pension) +
            Number(retireData?.strategy_passive_income_retire)
          : Number(retireData?.pension)) * 12
      );
      continue;
    }
    results.push(monthlyPayment);
  }

  return results;
};

//รายจ่ายหลังปรับกลยุทธ์
export const loopOutcomeAFSensitive = () => {
  const retireData = useRetireStore.getState().retireData;
  const settingData = useSettingStore.getState().settingData;
  let results = [];

  const price_one_million = retireData?.check_imagine_two
    ? settingData?.situation_two?.medical_expenses ?? 1000000
    : 0;
  const price_five_million = retireData?.check_imagine_three
    ? settingData?.situation_three?.debt ?? 5000000
    : 0;

  for (let i = 0; i <= getYearAFSensitive(); i++) {
    const monthlyInterest = Math.pow(
      1 +
        (retireData?.strategy_control_rate_outcome
          ? retireData?.strategy_control_rate_outcome
          : retireData?.out_come_peryear) /
          100,
      i
    );

    // ทดสอบความอ่อนไหวปีที่ 5 นับจากอายุปัจจุบัน กรณีป่วยร้ายแรง รายจ่ายเพิ่ม 1ล้านบาท
    const monthlyPayment =
      i < settingData?.sensitive_test?.situation_two.year ?? 5
        ? (Number(retireData?.out_come) -
            Number(retireData?.strategy_out_come)) *
          12 *
          monthlyInterest
        : // ทดสอบความอ่อนไหวปีที่ 10 นับจากอายุปัจจุบัน กรณีค้ำประกัน รายจ่ายเพิ่ม 5ล้านบาท
        i < settingData?.sensitive_test?.situation_three?.year ?? 10
        ? (Number(retireData?.out_come) -
            Number(retireData?.strategy_out_come)) *
            12 *
            monthlyInterest +
          price_one_million
        : (Number(retireData?.out_come) -
            Number(retireData?.strategy_out_come)) *
            12 *
            monthlyInterest +
          price_one_million +
          price_five_million;

    const afterRetire =
      (Number(retireData?.out_come) - Number(retireData?.strategy_out_come)) *
        0.7 *
        12 *
        monthlyInterest +
      price_one_million +
      price_five_million;

    // หลังเกษียณ
    if (
      i >
      (retireData?.strategy_age_retire
        ? retireData?.strategy_age_retire
        : retireData?.age_retire) -
        retireData?.age_current
    ) {
      results.push(afterRetire);
      continue;
    }
    results.push(monthlyPayment);
  }

  return results;
};

//คงเหลือหลังปรับ
export const loopRemainAFSensitive = () => {
  const retireData = useRetireStore.getState().retireData;
  let results = [];
  let income = loopIncomeAFSensitive();
  let outcome = loopOutcomeAFSensitive();
  let age = dataAgeAFSensitive();

  for (let i = 0; i <= getYearAFSensitive(); i++) {
    const remain = income[i] - outcome[i];
    results.push({
      income: income[i],
      outcome: outcome[i],
      results: remain,
      year: i + 1,
      age: age[i],
    });
  }

  return results;
};

export const weightValue = () => {
  const retireData = useRetireStore.getState().retireData;
  const result = avgWeight(
    retireData?.investment_bf_retirement,
    retireData?.investment_assets,
    retireData?.strategy_assets_generate,
    retireData?.strategy_assets_generate_per_year
  );
  return result;
};

//ผลตอบแทนการลงทุนหลังปรับ
export const loopReturnInvestmentAFSensitive = () => {
  const retireData = useRetireStore.getState().retireData;
  const settingData = useSettingStore.getState().settingData;

  let results = [];
  let remain = loopRemainAFSensitive();
  let income = loopIncomeAFSensitive();
  let outcome = loopOutcomeAFSensitive();
  let asset = retireData?.strategy_assets_generate
    ? Number(retireData?.strategy_assets_generate) +
      Number(retireData?.investment_assets)
    : Number(retireData?.investment_assets);

  let assetAndStg =
    Number(retireData?.investment_assets) +
    Number(retireData?.strategy_assets_generate);

  let checkAsset =
    retireData?.strategy_assets_generate_per_year &&
    retireData?.strategy_investment_bf_retirement;

  let beforeSTG = weightValue();

  if (retireData?.openAssetGen && retireData?.openInvestment) {
    beforeSTG = weightValue();
  } else {
    beforeSTG = retireData?.strategy_investment_bf_retirement
      ? retireData?.strategy_investment_bf_retirement
      : retireData?.investment_bf_retirement;
  }

  // เหลือ 1 เพราะทดสอบความอ่อนไหว
  let afterSTG = retireData?.check_imagine_one
    ? settingData?.sensitive_test?.situation_one?.return_invest ?? 1
    : 0;

  //   const afterSTG = retireData?.strategy_investment_af_retirement
  //     ? Number(retireData?.strategy_investment_af_retirement)
  //     : Number(retireData?.investment_af_retirement);

  for (let i = 0; i <= getYearAFSensitive(); i++) {
    if (i < 1) {
      //first 25
      const returninvest = asset * (beforeSTG / 100);
      const invest = assetAndStg + returninvest + remain[i]?.results;
      results.push({ results: returninvest, invest });
    } else {
      const ageRetie = retireData?.strategy_age_retire
        ? retireData?.strategy_age_retire
        : retireData?.age_retire;

      //หลังเกษียณ
      const remain = income[i] - outcome[i];
      const CG =
        results[i - 1]?.invest *
        (i + retireData.age_current > retireData?.age_retire
          ? afterSTG / 100
          : beforeSTG / 100);

      const newInvest = results[i - 1]?.invest + remain + CG;
      results.push({ results: CG, invest: newInvest });
    }
  }

  return results;
};

//สินทรัพย์เพื่อการลงทุนหลังปรับ
export const loopInvestmentAssetsAFSensitive = () => {
  const settingData = useSettingStore.getState().settingData;
  const retireData = useRetireStore.getState().retireData;
  let age = dataAgeAFSensitive();
  let results = [];
  let remain = loopRemainAFSensitive();
  let asset = retireData?.strategy_assets_generate
    ? Number(retireData?.strategy_assets_generate) +
      Number(retireData?.investment_assets)
    : Number(retireData?.investment_assets);
  let assetAndStg =
    Number(retireData?.investment_assets) +
    Number(retireData?.strategy_assets_generate);

  let checkAsset =
    retireData?.strategy_assets_generate_per_year &&
    retireData?.strategy_investment_af_retirement;

  let beforeSTG = weightValue();

  if (retireData?.openAssetGen && retireData?.openInvestment) {
    beforeSTG = weightValue();
  } else {
    beforeSTG = retireData?.strategy_investment_bf_retirement
      ? retireData?.strategy_investment_bf_retirement
      : retireData?.investment_bf_retirement;
  }

  // เหลือ 1 เพราะทดสอบความอ่อนไหว
  let afterSTG = retireData?.check_imagine_one
    ? settingData?.sensitive_test?.situation_one?.return_invest ?? 1
    : 0;
  //   const afterSTG = retireData?.strategy_investment_af_retirement
  //     ? Number(retireData?.strategy_investment_af_retirement)
  //     : Number(retireData?.investment_af_retirement);

  for (let i = 0; i <= getYearAFSensitive(); i++) {
    //ตัวแรก
    if (i < 1) {
      const returninvest = asset * (beforeSTG / 100);
      const invest = assetAndStg + returninvest + remain[i]?.results;
      results.push({ results: invest });
    } else {
      //ก่อนเกษียณ
      const newReturn = results[i - 1]?.results * (beforeSTG / 100);
      const prev_invest = results[i - 1]?.results;
      const invest = prev_invest + newReturn + remain[i]?.results;

      //หลังเกษียณ
      const newReturnaf = results[i - 1]?.results * (afterSTG / 100);
      const investaf = prev_invest + newReturnaf + remain[i]?.results;

      const sum =
        i >
        (retireData?.strategy_age_retire
          ? Number(retireData?.strategy_age_retire)
          : retireData?.age_retire) -
          retireData?.age_current
          ? investaf
          : invest;

      results.push({ results: sum, age: age[i] });
    }
  }

  return results;
};

//สินทรัพย์เพื่อการลงทุนหลังปรับค่าสุดท้าย
export const lastResultInvestmentAssetsAFSensitive = () => {
  const getInvestSum = loopInvestmentAssetsAFSensitive();
  if (getInvestSum.length === 0) {
    return 0;
  } else {
    return getInvestSum[getInvestSum.length - 1]?.results;
  }
};
