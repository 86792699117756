import { numberWithoutComma } from "../../../pages/balance/component/VIDrawerDebt.Main";

export const tax405Percent = [
  { label: "การให้เช่าบ้าน โรงเรือน สิ่งปลูกสร้างอย่างอื่น หรือแพ", value: 30 },
  { label: "การให้เช่าที่ดินที่ใช้ในการเกษตรกรรม", value: 20 },
  { label: "การให้เช่าที่ดินที่มิได้ใช้ในการเกษตรกรรม", value: 15 },
  { label: "การให้เช่ายานพาหนะ", value: 30 },
  { label: "การให้เช่าทรัพย์สินอื่น ๆ ", value: 10 },
  { label: "การผิดสัญญาเช่าซื้อทรัพย์สิน/ซื้อขายเงินผ่อน", value: 0 },
];

export const onChangeFreelane = (value, field, formTax, setFormTax) => {
  // let value = e.target.value;
  setFormTax((prev) => {
    return { ...prev, freelance: { ...formTax?.freelance, [field]: value } };
  });
};

export const onChangeTax403 = (value, field, index, formTax, setFormTax) => {
  let listOrder = [...formTax?.tax_403];
  let tempForm = { ...formTax?.tax_403?.[index] };
  let allInTax = 0;

  if (value === "หักแบบเหมา" && field === "calculating_method") {
    allInTax = tempForm?.tax_income - tempForm?.tax_income * 0.5;
    allInTax = allInTax > 100000 ? 100000 : allInTax;

    tempForm.income_for_calculating_all_in = allInTax;
  }

  if (field === "tax_income" && tempForm?.calculating_method === "หักแบบเหมา") {
    allInTax = value - value * 0.5;
    allInTax = allInTax > 100000 ? 100000 : allInTax;

    tempForm.income_for_calculating_all_in = allInTax;
  }

  if (field === "type_job" && value?.value === " tax_income_annual") {
    tempForm.income_for_calculating_all_in = 0;
    tempForm.Tax_Real = 0;
  }

  tempForm[field] = value;
  listOrder[index] = tempForm;

  setFormTax((prev) => {
    return { ...prev, tax_403: [...listOrder] };
  });
};

export const onChangeTax405 = (value, field, index, formTax, setFormTax) => {
  let listOrder = [...formTax?.tax_405];
  let tempForm = { ...formTax?.tax_405?.[index] };
  let allInTax = 0;

  let business_category = formTax?.tax_405[index]?.type_job?.label;

  let percent =
    tax405Percent.find((item) => item?.label === business_category)?.value || 0;

  if (value === "หักแบบเหมา" && field === "calculating_method" && percent) {
    allInTax =
      Number(tempForm?.tax_income || 0) -
      Number(tempForm?.tax_income || 0) * (percent / 100);

    tempForm.income_for_calculating_all_in = allInTax;
  }

  if (
    tempForm?.calculating_method === "หักแบบเหมา" &&
    field === "type_job" &&
    percent
  ) {
    percent =
      tax405Percent.find((item) => item?.label === value?.label)?.value || 0;
    allInTax =
      Number(tempForm?.tax_income || 0) -
      Number(tempForm?.tax_income || 0) * (percent / 100);

    tempForm.income_for_calculating_all_in = allInTax;
  }

  if (field === "type_job" && value?.value === " tax_income_annual") {
    tempForm.income_for_calculating_all_in = 0;
    tempForm.Tax_Real = 0;
  }

  if (
    field === "type_job" &&
    value?.label === "การผิดสัญญาเช่าซื้อทรัพย์สิน/ซื้อขายเงินผ่อน"
  ) {
    tempForm.income_for_calculating_all_in = 0;
    tempForm.Tax_Real = 0;
  }

  tempForm[field] = value;
  listOrder[index] = tempForm;

  setFormTax({ ...formTax, tax_405: [...listOrder] });
};

export const onChangeTax407 = (value, field, index, formTax, setFormTax) => {
  let listOrder = [...formTax?.tax_407];
  let tempForm = { ...formTax?.tax_407?.[index] };
  let allInTax = 0;

  if (tempForm?.calculating_method === "หักแบบเหมา") {
    allInTax = tempForm?.tax_income - tempForm?.tax_income * 0.6;

    tempForm.income_for_calculating_all_in = allInTax;
  }

  if (field === "type_job" && value?.value === " tax_income_annual") {
    tempForm.income_for_calculating_all_in = 0;
    tempForm.Tax_Real = 0;
  }

  tempForm[field] = value;
  listOrder[index] = tempForm;

  setFormTax((prev) => {
    return { ...prev, tax_407: [...listOrder] };
  });
};

export const onChangeTax408 = (value, field, index, formTax, setFormTax) => {
  let listOrder = [...formTax?.tax_408];
  let tempForm = { ...formTax?.tax_408?.[index] };
  let allInTax = 0;

  if(field === "type_job" && value?.label !== "เงินส่วนแบ่งของกำไรจากกองทุนรวมตามประกาศคณะปฏิวัติฯ" && tempForm?.calculating_method === "หักแบบเหมา"  ){
    allInTax = tempForm?.tax_income - (tempForm?.tax_income * 0.6);

    tempForm.income_for_calculating_all_in = allInTax;
  }


  if (tempForm?.calculating_method === "หักแบบเหมา" && field === "tax_income") {
    allInTax = value - value * 0.6;

    tempForm.income_for_calculating_all_in = allInTax;
  }

  if (
    field === "type_job" &&
    value?.label === "เงินส่วนแบ่งของกำไรจากกองทุนรวมตามประกาศคณะปฏิวัติฯ"
  ) {
    tempForm.income_for_calculating_all_in = 0;
    tempForm.Tax_Real = 0;
  }

  if (field === "type_job" && value?.label === "เงินได้อื่นๆ") {
    tempForm.income_for_calculating_all_in = 0;
    tempForm.calculating_method = "หักตามจริง";
  }

  tempForm[field] = value;
  listOrder[index] = tempForm;

  setFormTax((prev) => {
    return { ...prev, tax_408: [...listOrder] };
  });
};

export const onChangeTax404 = (value, field, index, formTax, setFormTax) => {
  let listOrder = [...formTax?.tax_404];
  let tempForm = { ...formTax?.tax_404?.[index] };
  let allInTax = 0;

  tempForm[field] = value;
  listOrder[index] = tempForm;

  setFormTax((prev) => {
    return { ...prev, tax_404: [...listOrder] };
  });
};

export const onChangeTax404Stock = (
  value,
  field,
  index,
  formTax,
  setFormTax
) => {
  let listOrder = [...formTax?.tax_404_stock];
  let tempForm = { ...formTax?.tax_404_stock?.[index] };
  let allInTax = 0;

  if (field === "type_job" && value?.label === "กองทุน") {
    tempForm.tax_credit = 0
    tempForm.interest_rate = ""
  }

  if (tempForm?.type_job === "กองทุน") {
    if (field === "dividend") {
      tempForm.with_holding_tax = value * 0.1 < 1 ? 0 : value * 0.1;
      tempForm.tax_credit = 0;
    }
  } else {
    if (field === "dividend" && tempForm?.interest_rate) {
      tempForm.with_holding_tax = value * 0.1 < 1 ? 0 : value * 0.1;
      tempForm.tax_credit =
        (value * (tempForm?.interest_rate?.value)) /
        (100 - tempForm?.interest_rate?.value);
    }

    if (field === "interest_rate" && tempForm?.dividend) {
      tempForm.tax_credit =
        (tempForm?.dividend * (value?.value)) / (100 - value?.value);
    }

    if (field === "dividend") {
      tempForm.with_holding_tax = value * 0.1 < 1 ? 0 : value * 0.1;

    }
  }

  tempForm[field] = value;
  listOrder[index] = tempForm;

  setFormTax((prev) => {
    return { ...prev, tax_404_stock: [...listOrder] };
  });
};

export const onChangeRMFProfit = (value, field, formTax, setFormTax) => {
  // let listOrder = [...formTax?.tax_404_stock];
  let tempForm = { ...formTax?.RMF_Profit };
  // let allInTax = 0;
  tempForm[field] = value;
  // listOrder[index] = tempForm;

  setFormTax((prev) => {
    return { ...prev, RMF_Profit: { ...tempForm } };
  });
};

export const onChangeSSFProfit = (value, field, formTax, setFormTax) => {
  // let listOrder = [...formTax?.tax_404_stock];
  let tempForm = { ...formTax?.SSF_Profit };
  // let allInTax = 0;

  tempForm[field] = value;
  // listOrder[index] = tempForm;

  setFormTax((prev) => {
    return { ...prev, SSF_Profit: { ...tempForm } };
  });
};

export const Tax408PropertyPercent = (year) => {
  let fullPercent = 100;
  let realReduce = 0;

  if (year <= 1) {
    return fullPercent - 8;
  }

  fullPercent = fullPercent - 8;

  for (let index = year; index >= 2; index--) {
    realReduce =
      Math.floor(fullPercent / 10) - 1 < 5
        ? 5
        : Math.floor(fullPercent / 10) - 1;
    fullPercent -= realReduce;
  }

  return fullPercent <= 50 ? 50 : fullPercent;
};

export const onChangeTax408_property = (
  value,
  field,
  index,
  formTax,
  setFormTax
) => {
  let listOrder = [...formTax?.tax_408_property];
  let tempForm = { ...formTax?.tax_408_property?.[index] };
  let allInTax = 0;

  if (tempForm?.calculating_method === "หักแบบเหมา") {
    allInTax =
      tempForm?.tax_income -
      tempForm?.tax_income *
        (Tax408PropertyPercent(tempForm?.tax_holding_year) / 100);

    tempForm.income_for_calculating_all_in = allInTax;
  }

  // console.log("value",value)
  // console.log("field",field)

  if (field === "calculating_way" && value === "แยกคำนวณ") {
    tempForm.calculating_method = "หักตามจริง"
  }

  if (
    field === "type_job" &&
    value?.label ===
      "การขายอสังหาริมทรัพย์อันเป็นมรดก หรือที่ได้รับจากการให้โดยเสน่หา"
  ) {
    allInTax = tempForm?.tax_income - tempForm?.tax_income * 0.5;

    tempForm.income_for_calculating_all_in = allInTax;
  }

  if (
    field === "tax_income" &&
    tempForm?.calculating_method === "หักแบบเหมา" &&
    tempForm?.type_job?.label ===
      "การขายอสังหาริมทรัพย์อันเป็นมรดก หรือที่ได้รับจากการให้โดยเสน่หา"
  ) {
    allInTax = value - value * 0.5;

    tempForm.income_for_calculating_all_in = allInTax;
  }

  tempForm[field] = value;
  listOrder[index] = tempForm;

  setFormTax((prev) => {
    return { ...prev, tax_408_property: [...listOrder] };
  });
};

export const Tax408PropertyAllInPercent = (
  value,
  field,
  index,
  formTax,
  setFormTax
) => {
  let listOrder = [...formTax?.tax_408_property];
  let tempForm = { ...formTax?.tax_408_property?.[index] };
  let allInTax = 0;

  if (tempForm?.calculating_method === "หักแบบเหมา") {
    allInTax = tempForm?.tax_income - tempForm?.tax_income * 0.6;

    tempForm.income_for_calculating_all_in = allInTax;
  }

  if (
    field === "type_job" &&
    value?.label === "เงินส่วนแบ่งของกำไรจากกองทุนรวมตามประกาศคณะปฏิวัติฯ"
  ) {
    tempForm.income_for_calculating_all_in = 0;
    tempForm.Tax_Real = 0;
  }

  tempForm[field] = value;
  listOrder[index] = tempForm;

  setFormTax((prev) => {
    return { ...prev, tax_408_property: [...listOrder] };
  });
};

export const AddOrder = (field, formTax, setFormTax) => {
  let listOrder = [];
  listOrder = [...(formTax[field] || [])];
  let tempForm = {
    type_job: "",
    tax_income: "",
    with_holding_tax: "",
    tax_payer_ID: "",
    calculating_method: "หักแบบเหมา",
  };

  listOrder.push(tempForm);
  setFormTax((prev) => {
    return { ...prev, [field]: listOrder };
  });
};

export const DeleteOrder = (index, formTax, setFormTax, field) => {
  let listOrder = [...formTax?.[field]];
  listOrder.splice(index, 1);
  setFormTax((prev) => {
    return { ...prev, [field]: listOrder };
  });
};

export const TotalExpense = (formTax) => {
  let tempTotalExpense = 0;
  tempTotalExpense = formTax?.tax_403
    ?.filter((item) => {
      return item?.calculating_method !== "หักแบบเหมา";
    })
    .reduce((total, num) => {
      return total + Number(num?.Tax_Real || 0);
    }, 0);
  return tempTotalExpense;
};

export const onChangeExpensTaxDetail = (value, field, setFormTax) => {
  setFormTax((prev) => {
    return { ...prev, [field]: value };
  });
};

export const Submit = (
  formTax,
  setPopuperror,
  setTaxData,
  NavigateGoBack,
  navigate
) => {
  let sumExpense =
    numberWithoutComma(formTax?.free_job_expense_cost || 0) +
    numberWithoutComma(formTax?.free_job_expense_salary || 0) +
    numberWithoutComma(formTax?.free_job_expense_other || 0);
  let checkTaxPayerId = formTax?.tax_403
    ?.filter((item) => item?.tax_income !== "")
    ?.map((item) => String(item?.tax_payer_ID))
    .some((item) => item === "" || item.length !== 13);

  if (TotalExpense() !== sumExpense) {
    setPopuperror((prev) => {
      return { ...prev, expense: true };
    });
    return;
  }

  if ((checkTaxPayerId, setPopuperror)) {
    setPopuperror((prev) => {
      return { ...prev, Tax_Payer_ID: true };
    });
    return;
  }

  setTaxData({ ...formTax });
  NavigateGoBack(navigate);
};
