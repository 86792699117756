import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  RadialLinearScale,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { useEffect } from "react";
import { useRetireStore } from "../../_store/retireStore";

ChartJS.register(
  RadialLinearScale,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Filler,
  Title,
  Tooltip,
  Legend
);

const DATA_COUNT = 7;
const NUMBER_CFG = { count: DATA_COUNT, min: -100, max: 100 };

// const data = (canvas) => {
//     const ctx = canvas.getContext("2d")
//     const gradient = ctx.createLinearGradient(0,0,100,0);
//     ...
//       return {
//           ...
//           backgroundColor: gradient
//           ...
//       }
//     }

const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const LineChartMainAfter = ({ dataPlan, labels, head }) => {
  const retireData = useRetireStore((state) => state.retireData);
  const setRetireData = useRetireStore((state) => state.setRetireData);

  const getInvestSum = retireData?.bf_investment_assets;
  const getInvestSumAF = retireData?.af_investment_assets;
  const lastResultBF = getInvestSum[getInvestSum?.length - 1];
  const lastResultAF = getInvestSumAF[getInvestSumAF?.length - 1];

  const checkStatus = () => {
    const numericLastResultBF = Number(lastResultBF?.results);
    const numericLastResultAF = Number(lastResultAF?.results);
    // console.log(lastResultAF);

    if (isNaN(numericLastResultAF)) {
      return "#7E8393"; // Default color
    } else if (numericLastResultAF > 0) {
      return "#16AA37";
    } else if (numericLastResultAF < 0) {
      return "#AA1616";
    } else {
      return "#FFD200";
    }
  };

  const data = {
    labels: labels,
    datasets: [
      {
        label: head,
        data: dataPlan,
        fill: true,

        borderColor: ({ chart: { ctx } }) => {
          const borderColor = checkStatus();
          return borderColor;
        },

        // pointBackgroundColor: checkStatus(),
        backgroundColor: ({ chart: { ctx } }) => {
          const gradient = ctx.createLinearGradient(0, 0, 0, 300);
          gradient.addColorStop(0, checkStatus());
          gradient.addColorStop(0.8, "rgba(0, 0, 0, 0)");
          return gradient;
        },
        tension: 0.2,
      },
    ],
  };

  const config = {
    type: "line",
    data,
    options: {
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    },
  };
  return (
    <div className=" flex items-end">
      <Line data={data} config={config} />
      {/* <div className=" -ml-3 flex justify-end items-end font-IBMPlexSansThai text-xs pb-6">
        อายุ
      </div> */}
    </div>
  );
};

export default LineChartMainAfter;
