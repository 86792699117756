import { useEffect, useState } from "react";
import { CheckTest, PopupResult, QuestionCard } from "../styles/TestCard.Main";
import { MTButton } from "../../../components/button/MTButton";
import RiskPlanList from "./Risk_Plan_List";
import { ICON_INFO } from "../../../utils/useIcons";

const ViewSickCase = ({ formRisk, setFormRisk, setMenu, setRiskData }) => {
  const [openResult, setOpenResult] = useState(false);

  //---------- ข้อหนึ่ง ----------//
  const onChangeSickLevelPassAway = (e) => {
    let value = e.target.value;
    if (value === "high") {
      setFormRisk({
        ...formRisk,
        sick_level_pass_away_high: true,
        sick_level_pass_away_medium: false,
        sick_level_pass_away_low: false,
      });
    } else if (value === "medium") {
      setFormRisk({
        ...formRisk,
        sick_level_pass_away_high: false,
        sick_level_pass_away_medium: true,
        sick_level_pass_away_low: false,
      });
    } else {
      setFormRisk({
        ...formRisk,
        sick_level_pass_away_high: false,
        sick_level_pass_away_medium: false,
        sick_level_pass_away_low: true,
      });
    }
  };

  //---------- ข้อสอง ----------//
  const onChangeSickPassAwayYesterday = (e) => {
    let value = e.target.value;
    if (value === "high") {
      setFormRisk({
        ...formRisk,
        sick_pass_away_yesterday_high: true,
        sick_pass_away_yesterday_medium: false,
        sick_pass_away_yesterday_low: false,
      });
    } else if (value === "medium") {
      setFormRisk({
        ...formRisk,
        sick_pass_away_yesterday_high: false,
        sick_pass_away_yesterday_medium: true,
        sick_pass_away_yesterday_low: false,
      });
    } else {
      setFormRisk({
        ...formRisk,
        sick_pass_away_yesterday_high: false,
        sick_pass_away_yesterday_medium: false,
        sick_pass_away_yesterday_low: true,
      });
    }
  };

  //---------- ข้อสาม ----------//
  const onChangeSickManageRisk = (e) => {
    let value = e.target.value;
    if (value === "avoid") {
      setFormRisk({
        ...formRisk,
        sick_manage_risk_avoidance: true,
        sick_manage_risk_control: false,
        sick_manage_risk_accept: false,
        sick_manage_risk_transfer: false,
      });
    } else if (value === "control") {
      setFormRisk({
        ...formRisk,
        sick_manage_risk_avoidance: false,
        sick_manage_risk_control: true,
        sick_manage_risk_accept: false,
        sick_manage_risk_transfer: false,
      });
    } else if (value === "accept") {
      setFormRisk({
        ...formRisk,
        sick_manage_risk_avoidance: false,
        sick_manage_risk_control: false,
        sick_manage_risk_accept: true,
        sick_manage_risk_transfer: false,
      });
    } else {
      setFormRisk({
        ...formRisk,
        sick_manage_risk_avoidance: false,
        sick_manage_risk_control: false,
        sick_manage_risk_accept: false,
        sick_manage_risk_transfer: true,
      });
    }
  };

  //---------------------------//

  const getSum = () => {
    let sumScore = 0;
    if (formRisk?.sick_pass_away_yesterday_high) {
      sumScore += 1;
    }
    if (formRisk?.sick_pass_away_yesterday_medium) {
      sumScore += 1;
    }
    if (formRisk?.sick_manage_risk_avoidance) {
      sumScore += 1;
    }
    if (formRisk?.sick_manage_risk_control) {
      sumScore += 1;
    }
    if (formRisk?.sick_manage_risk_accept) {
      sumScore += 1;
    }
    if (formRisk?.sick_manage_risk_transfer) {
      sumScore += 1.5;
    }

    return sumScore;
  };

  useEffect(() => {
    setFormRisk({
      ...formRisk,
      sick_sum_score: getSum(),
    });
    setRiskData({
      ...formRisk,
      sick_sum_score: getSum(),
    });
  }, [getSum()]);

  useEffect(() => {
    if (openResult) {
      document?.getElementById("result_sick")?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [openResult]);

  const onClickSubmit = () => {
    setOpenResult(!openResult);
  };

  // console.log("formRisk : ", formRisk);

  return (
    <div className="mt-[14px] px-[25px]">
      <div className=" flex flex-col justify-center items-center gap-1">
        <img
          src="/images/risk_sick.svg"
          className=" w-[50px] h-[50px]"
          alt=""
        />
        <div className=" text-center text-black font-semibold ">
          กรณีเจ็บป่วย
        </div>
      </div>
      <div className=" flex flex-col gap-4 mt-[14px]">
        <QuestionCard
          name={"one"}
          checkedHigh={formRisk?.sick_level_pass_away_high}
          checkedMedium={formRisk?.sick_level_pass_away_medium}
          checkedLow={formRisk?.sick_level_pass_away_low}
          formRisk={formRisk}
          onChange={onChangeSickLevelPassAway}
          question="1. ท่านคิดว่าโอกาสในการเจ็บป่วยของท่านอยู่ในระดับใด "
          choice1={`สูง`}
          choice2={`กลาง`}
          choice3={`ต่ำ`}
        />
        <QuestionCard
          name={"two"}
          checkedHigh={formRisk?.sick_pass_away_yesterday_high}
          checkedMedium={formRisk?.sick_pass_away_yesterday_medium}
          checkedLow={formRisk?.sick_pass_away_yesterday_low}
          formRisk={formRisk}
          onChange={onChangeSickPassAwayYesterday}
          question="2. ถ้าเมื่อวานท่านเกิดเจ็บป่วยมีค่ารักษาสูง
          จะมีผลกระทบการเงินกับครอบครัวในระดับใด"
          choice1={`สูง`}
          choice2={`กลาง`}
          choice3={`ต่ำ`}
        />
        <QuestionCard
          name={"three"}
          checkedAvoidance={formRisk?.sick_manage_risk_avoidance}
          checkedControl={formRisk?.sick_manage_risk_control}
          checkedAccept={formRisk?.sick_manage_risk_accept}
          checkedTransfer={formRisk?.sick_manage_risk_transfer}
          formRisk={formRisk}
          onChange={onChangeSickManageRisk}
          position={"verticle"}
          question={
            <div className="flex gap-1.5">
              3.
              ท่านมีวิธีการในการจัดการความเสี่ยงกรณีเจ็บป่วยร้ายแรงในปัจจุบันอย่างไร
              <a href="https://www.youtube.com/watch?v=PlqpT01BoIk">
                <ICON_INFO className={`flex-none text-gray90 w-6 h-6`} />
              </a>
            </div>
          }
          choice1={`หลีกเลี่ยงความเสี่ยง`}
          choice2={`ควบคุมความเสี่ยง`}
          choice3={`รับความเสี่ยง`}
          choice4={`โอนความเสี่ยง`}
        />
      </div>

      <div className="flex justify-center my-6">
        <MTButton
          text="แสดงผลลัพธ์"
          // loading={loading}
          onClick={onClickSubmit}
        />
      </div>

      {openResult && (
        <div className="flex flex-col">
          <div className=" text-mainblue font-semibold text-center mt-6 mb-4 ">
            ผลลัพธ์วางแผนกรณีเจ็บป่วย
          </div>

          {formRisk?.sick_sum_score === 1 && (
            <PopupResult
              img="/images/healing_shield.png"
              classNameImg={`h-[52px] mt-[7px]`}
              text={`แนะนำให้ท่านโอนความเสี่ยงด้วยการทำ`}
              text2={`ประกันสุขภาพ ถ้าท่านสุขภาพไม่ดี`}
              text3={`เกิดการเจ็บป่วยก่อนการทำประกัน บริษัทประกัน`}
              text4={`จะปฏิเสธการรับประกันท่านในโรคที่เป็นมาก่อน`}
              text5={`และภาวะสืบเนื่อง`}
            />
          )}

          {formRisk?.sick_sum_score === 1.5 && (
            <PopupResult
              img="/images/good_shield.png"
              classNameImg={`h-[52px] mt-[7px]`}
              text={`แนะนำให้ท่านโอนความเสี่ยงด้วยการทำ`}
              text2={`ประกันสุขภาพ ถ้าท่านสุขภาพไม่ดีเกิดการเจ็บ`}
              text3={`ป่วยก่อนการทำประกัน บริษัทประกันจะปฏิเสธ`}
              text4={`การรับประกันท่านในโรคที่เป็นมาก่อน`}
              text5={`และภาวะสืบเนื่อง`}
            />
          )}

          {formRisk?.sick_sum_score === 2 && (
            <PopupResult
              img="/images/food_healing.png"
              classNameImg={`h-[52px] mt-[7px]`}
              text={`แนะนำให้ท่านโอนความเสี่ยงด้วยการทำประกัน`}
              text2={`สุขภาพ ดูแลสุขภาพตนเอง ทานอาหารให้ครบ 5 หมู่`}
              text3={`ออกกำลังกายอย่างสม่ำเสมอ พักผ่อนให้เพียงพอ`}
              text4={`อยู่ในสภาพแวดล้อมที่ดี ฝึกทำสมาธิ`}
              text5={`ดูแลสติให้อยู่ในปัจจุบัน ทำจิตใจให้เบิกบาน`}
            />
          )}

          {formRisk?.sick_sum_score === 2.5 && (
            <PopupResult
              img="/images/good_food.png"
              classNameImg={`h-[52px] mt-[7px]`}
              text={`'โอนความเสี่ยง' ท่านเลือกวิธีจัดการความเสี่ยง`}
              text2={`ได้เหมาะสมแนะนำให้ท่านโอนความเสี่ยงด้วย`}
              text3={`การทำประกันสุขภาพดูแลสุขภาพตนเอง`}
              text4={`ทานอาหารให้ครบ 5 หมู่ ออกกำลังกายอย่าง`}
              text5={`สม่ำเสมอ พักผ่อนให้เพียงพอ อยู่ในสภาพ`}
              text6={`แวดล้อมที่ดี ฝึกทำสมาธิ ดูแลสติให้อยู่ในปัจจุบัน`}
              text7={`ทำจิตใจให้เบิกบาน`}
            />
          )}

          <div className=" mt-6">
            <div className=" text-black text-center font-medium">
              แบบทดสอบวิธีจัดการความเสี่ยงของท่าน
            </div>
            <RiskPlanList formRisk={formRisk} setMenu={setMenu} />
          </div>
        </div>
      )}
      <div className="mt-6" id="result_sick"></div>
    </div>
  );
};
export default ViewSickCase;
