import React, { useEffect, useState } from "react";
import { MTButton } from "../../components/button/MTButton";
import { useLocation, useNavigate } from "react-router-dom";
import { ICON_PLUS } from "../../utils/useIcons";
import { InputMain } from "../../components/input/InputMain";
import ErrorMain from "../../components/error/Error.Main";
import { STYLE_INPUT_SLIDER, STYLE_TEXT_BLUE } from "../../utils/useStyle";
import DropDownMain from "../../components/dropdown/DropDownMain";
import { SliderSingle } from "../../components/slider/SliderSingle";
import { NumericFormat } from "react-number-format";
import { PopupMain } from "../../components/popup/PopupMain";
import { useFamilytreeStore } from "../../_store/familytreeStore";
import Api from "../../api/api.main";
import { useUserStore } from "../../_store/userStore";
import NumbericValidateRed from "../../components/DebtBalance/numericRed";

const RELATION_LIST = [
  {
    value: "husband",
    label: "สามี",
  },
  {
    value: "wife",
    label: "ภรรยา",
  },
  {
    value: "child",
    label: "บุตร",
  },
  {
    value: "father",
    label: "บิดา",
  },
  {
    value: "mother",
    label: "มารดา",
  },
];

const FamilyTreeMain = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const currentEmailLogin = params.get("email");
  const navigate = useNavigate();

  // UserData Logined
  const userData = useUserStore((state) => state.userData);
  const setUserData = useUserStore((state) => state.setUserData);

  // Family Tree Data
  const familytreeData = useFamilytreeStore((state) => state.familytreeData);
  const setFamilytreeData = useFamilytreeStore(
    (state) => state.setFamilytreeData
  );
  const clearFamilytreeData = useFamilytreeStore(
    (state) => state.clearFamilytreeData
  );

  const [step, setStep] = useState(0);
  const [openAlert, setOpenAlert] = useState(false);

  const [loading, setLoading] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  const [indexEdit, setIndexEdit] = useState(null);

  const [dataFamilyTree, setDataFamilyTree] = useState([]);

  // Form Data Family Tree
  const [formFamilyTree, setFormFamilyTree] = useState({
    firstname: "",
    lastname: "",
    relation: {
      value: "",
      label: "",
    },
    age: 25,
  });

  const maxAge = 150;

  // Check Error
  const [errorForm, setErrorForm] = useState({
    firstname: { isError: false, message: "" },
    lastname: { isError: false, message: "" },
    relation: { isError: false, message: "" },
    age: { isError: false, message: "" },
  });

  //------------------------------------------------------------------------//

  const validateFormFamilyTree = () => {
    let checkError = false;
    let localErrorForm = { ...errorForm };

    if (!formFamilyTree.firstname) {
      localErrorForm.firstname = {
        isError: true,
        message: "กรุณากรอกชื่อ",
      };
      checkError = true;
    }

    if (!formFamilyTree.lastname) {
      localErrorForm.lastname = {
        isError: true,
        message: "กรุณากรอกนามสกุล",
      };
      checkError = true;
    }
    if (!formFamilyTree.relation.value) {
      localErrorForm.relation = {
        isError: true,
        message: "กรุณาระบุความสัมพันธ์",
      };
      checkError = true;
    }

    if (formFamilyTree.age <= 0) {
      localErrorForm.age = {
        isError: true,
        message: "กรุณากรอกอายุให้ถูกต้อง",
      };
      checkError = true;
    }

    setErrorForm(localErrorForm);
    return checkError;
  };

  //------------------------------------------------------------------------//

  // Firstname
  const onChangeFirstname = (e) => {
    let value = e.target.value;
    setErrorForm({
      ...errorForm,
      firstname: false,
    });
    return setFormFamilyTree({ ...formFamilyTree, firstname: value });
  };

  // Lastname
  const onChangeLastname = (e) => {
    let value = e.target.value;
    setErrorForm({
      ...errorForm,
      lastname: false,
    });
    return setFormFamilyTree({ ...formFamilyTree, lastname: value });
  };

  // Relation
  const onChangeSelectedRelation = (data) => {
    let value = data;
    setErrorForm({
      ...errorForm,
      relation: false,
    });
    return setFormFamilyTree({ ...formFamilyTree, relation: value });
  };

  // Age
  const onChangeAge = (e) => {
    let value = Number(e.target.value);
    setErrorForm({
      ...errorForm,
      age: false,
    });
    if (value > maxAge) {
      return setFormFamilyTree({ ...formFamilyTree, age: maxAge });
    }
    return setFormFamilyTree({ ...formFamilyTree, age: value });
  };

  //------------------------------------------------------------------------//

  const onClickAddFamily = () => {
    setFormFamilyTree({
      firstname: "",
      lastname: "",
      relation: {
        value: "",
        label: "",
      },
      age: 0,
    });
    setStep(1);
  };

  // Add List
  const onSubmitForm = () => {
    const isError = validateFormFamilyTree();
    if (isError) {
      return;
    }
    let tempDataFamilyTree = [...dataFamilyTree, formFamilyTree];
    setDataFamilyTree(tempDataFamilyTree);
    setFamilytreeData(tempDataFamilyTree);
    setStep(step - 1);
  };

  const onClickEdit = (data, index) => {
    setIsEdit(true);
    setIndexEdit(index);
    setStep(1);
    setFormFamilyTree(data);
  };

  // Edit List
  const onSubmitFormEdit = () => {
    const isError = validateFormFamilyTree();
    if (isError) {
      return;
    }
    let tempDataFamilyTree = [...dataFamilyTree];
    tempDataFamilyTree[indexEdit] = formFamilyTree;
    setDataFamilyTree(tempDataFamilyTree);
    setFamilytreeData(tempDataFamilyTree);
    setIndexEdit(null);
    setIsEdit(false);
    setStep(step - 1);
  };

  // Delete
  const onClickDeleteFamily = () => {
    let tempDataFamilyTree = [...dataFamilyTree];
    tempDataFamilyTree.splice(indexEdit, 1);
    setDataFamilyTree(tempDataFamilyTree);
    setFamilytreeData(tempDataFamilyTree);
    setIndexEdit(null);
    setIsEdit(false);
    setStep(step - 1);
  };

  const onSubmitLogin = async () => {
    try {
      setLoading(true);

      let payload = {
        first_login: false,
        family_tree: familytreeData,
      };

      const paramFilter = {
        filter: {
          email: currentEmailLogin,
        },
      };
      const { data: dataProfile } = await Api.get("/profiles", {
        params: paramFilter,
      });

      if (dataProfile?.profiles?.length > 0) {
        const profileByEmail = dataProfile?.profiles[0];
        const id = profileByEmail._id;

        // update profile table by id
        await Api.put(`profiles/${id}`, payload);

        setUserData({ ...profileByEmail, ...payload });
        setOpenAlert(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onClickFinish = () => {
    clearFamilytreeData();
    navigate("/");
    setOpenAlert(false);
  };

  useEffect(() => {
    if (familytreeData?.length > 0) {
      setDataFamilyTree([...familytreeData]);
    }
  }, [familytreeData]);

  //   console.log("formFamilyTree : ", formFamilyTree);
  //   console.log("dataFamilyTree : ", dataFamilyTree);
  //   console.log("familytreeDataStore : ", familytreeData);
  //   console.log("---------------------------->");

  return (
    <React.Fragment>
      <div className=" font-IBMPlexSansThai flex flex-col relative min-h-screen mx-auto max-w-3xl">
        <div className=" relative pt-[105px] px-[32px]">
          <img
            src="/images/circle_login1.svg"
            className=" absolute top-0 right-0"
            alt=""
          />
          <div className="flex flex-col gap-[13px]">
            <img
              src="/images/logo_assure.png"
              className=" w-fit h-[37px]"
              alt=""
            />
            <div className=" text-maincyan font-bold text-[32px] leading-relaxed">
              ข้อมูลครอบครัว
            </div>
          </div>

          {/*---------- Step 0 คือ ยังไม่กรอกใครเลย ----------*/}
          {step === 0 && (
            <div className="mt-[13px]">
              <div className="flex items-center justify-between">
                <div className="text-mainblue text-sm font-medium">
                  ข้อมูลของคนที่คุณรักและห่วงใย
                </div>
                <MTButton
                  text={"เพิ่ม"}
                  color="bg-maincyan"
                  iconleft
                  icon={<ICON_PLUS className={`w-5 h-5 -mr-4`} />}
                  width="w-[78px]"
                  onClick={onClickAddFamily}
                />
              </div>
              <div className="grid grid-cols-4 my-5 px-2.5 py-2.5 gap-[5px]  bg-[#DCEBEB] rounded-[10px] text-mainblue text-sm font-medium">
                <div>ชื่อ</div>
                <div>นามสกุล</div>
                <div className="text-nowrap text-center">ความสัมพันธ์</div>
                <div className="text-right">อายุ</div>
              </div>

              {/*-- เช็คว่ามีรายการหรือไม่ --*/}
              {dataFamilyTree?.length > 0 ? (
                <div className="-mt-2.5">
                  {dataFamilyTree?.map((item, index) => {
                    return (
                      <div
                        onClick={() => onClickEdit(item, index)}
                        className="grid grid-cols-4 mb-2.5 px-2.5 py-2.5 gap-[5px] rounded-[10px] text-mainblue text-xs font-normal border-b hover:bg-greenopacity"
                      >
                        <div>{item.firstname}</div>
                        <div>{item.lastname}</div>
                        <div className="text-nowrap text-center">
                          {item.relation.label}
                        </div>
                        <div className="text-right font-medium">{item.age}</div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="flex flex-col items-center bg-white py-5 border border-gray55 rounded-[10px]">
                  <img
                    src="/images/family_tree.png"
                    className="w-[140px] h-[85px]"
                  />
                  <div className="text-mainblue text-sm font-medium">
                    กรุณาเพิ่มข้อมูลของคนที่คุณรักและห่วงใย
                  </div>
                </div>
              )}
              <div className="flex justify-between gap-2.5 w-full mt-[26px] mb-[38px]">
                <MTButton
                  text="ย้อนกลับ"
                  color="bg-white"
                  border="border border-maincyan"
                  colortext="text-maincyan group-hover:text-white"
                  hovercolor="hover:bg-maincyan group "
                  width="w-full"
                  onClick={() =>
                    navigate(`/firstlogin?email=${currentEmailLogin}`)
                  }
                />
                <MTButton
                  text={"ลงทะเบียน"}
                  color="bg-maincyan"
                  width="w-full"
                  onClick={onSubmitLogin}
                />
              </div>
            </div>
          )}

          {/*---------- Step 1 คือ หน้ากรอกข้อมูล ----------*/}
          {step === 1 && (
            <div className="mt-[32px]">
              {/*--------- Is Edit ----------*/}
              {isEdit ? (
                <div className=" flex flex-col gap-4">
                  {/*--- ชื่อ ---*/}
                  <div>
                    <label className={STYLE_TEXT_BLUE}>ชื่อ</label>
                    <InputMain
                      placeholder="ระบุ"
                      className={
                        errorForm.firstname.isError && "border-red-500"
                      }
                      value={formFamilyTree.firstname}
                      onChange={onChangeFirstname}
                    />
                    {errorForm.firstname.isError && (
                      <ErrorMain errortext={errorForm.firstname.message} />
                    )}
                  </div>

                  {/*--- นามสกุล ---*/}
                  <div>
                    <label className={STYLE_TEXT_BLUE}>นามสกุล</label>
                    <InputMain
                      placeholder="ระบุ"
                      className={errorForm.lastname.isError && "border-red-500"}
                      value={formFamilyTree.lastname}
                      onChange={onChangeLastname}
                    />
                    {errorForm.lastname.isError && (
                      <ErrorMain errortext={errorForm.lastname.message} />
                    )}
                  </div>

                  {/*--- ความสัมพันธ์ ---*/}
                  <div>
                    <label className={STYLE_TEXT_BLUE}>ความสัมพันธ์</label>
                    <div
                      className={`border rounded-full ${
                        errorForm.relation.isError
                          ? "border-red-500"
                          : "border-gray-200"
                      }`}
                    >
                      <DropDownMain
                        placeholder={`กรุณาเลือกความสัมพันธ์`}
                        options={RELATION_LIST}
                        value={RELATION_LIST?.filter((item) => {
                          if (item.value === formFamilyTree.relation.value)
                            return item.label;
                        })}
                        onChange={onChangeSelectedRelation}
                      />
                    </div>
                    {errorForm.relation.isError && (
                      <ErrorMain errortext={errorForm.relation.message} />
                    )}
                  </div>

                  {/*--- อายุ ---*/}
                  <div>
                    <label className={STYLE_TEXT_BLUE}>อายุ</label>
                    <div className="flex justify-center">
                     <NumbericValidateRed
                        maxLength={3}
                        thousandSeparator=","
                        inputMode="decimal"
                        pattern="[0-9],*"
                        placeholder="0"
                        className={`${STYLE_INPUT_SLIDER} mb-1.5`}
                        value={formFamilyTree.age > 0 ? formFamilyTree.age : ""}
                        onChange={onChangeAge}
                      />
                    </div>
                    <SliderSingle
                      year
                      placeholder="0"
                      valuemax={maxAge}
                      showInputMax={false}
                      value={formFamilyTree.age}
                      onChange={onChangeAge}
                    />
                    {errorForm.age.isError && (
                      <ErrorMain errortext={errorForm.age.message} />
                    )}
                  </div>
                  <div className="flex justify-between gap-2.5 w-full my-[16px]">
                    <MTButton
                      text="ย้อนกลับ"
                      color="bg-white"
                      border="border border-maincyan"
                      colortext="text-maincyan group-hover:text-white"
                      hovercolor="hover:bg-maincyan group "
                      width="w-full"
                      onClick={() => {
                        setIsEdit(false);
                        setStep(step - 1);
                      }}
                    />
                    <MTButton
                      text={"บันทึก"}
                      color="bg-maincyan"
                      width="w-full"
                      onClick={onSubmitFormEdit}
                    />
                  </div>
                  <div className="mb-[38px]">
                    <MTButton
                      text={"ลบ"}
                      color="bg-gray60"
                      hovercolor="hover:bg-mainred"
                      width="w-full"
                      rounded="rounded-[10px]"
                      onClick={onClickDeleteFamily}
                    />
                  </div>
                </div>
              ) : (
                //*--------- Is Add ----------*/
                <div className=" flex flex-col gap-4">
                  {/*--- ชื่อ ---*/}
                  <div>
                    <label className={STYLE_TEXT_BLUE}>ชื่อ</label>
                    <InputMain
                      placeholder="ระบุ"
                      className={
                        errorForm.firstname.isError && "border-red-500"
                      }
                      value={formFamilyTree.firstname}
                      onChange={onChangeFirstname}
                    />
                    {errorForm.firstname.isError && (
                      <ErrorMain errortext={errorForm.firstname.message} />
                    )}
                  </div>

                  {/*--- นามสกุล ---*/}
                  <div>
                    <label className={STYLE_TEXT_BLUE}>นามสกุล</label>
                    <InputMain
                      placeholder="ระบุ"
                      className={errorForm.lastname.isError && "border-red-500"}
                      value={formFamilyTree.lastname}
                      onChange={onChangeLastname}
                    />
                    {errorForm.lastname.isError && (
                      <ErrorMain errortext={errorForm.lastname.message} />
                    )}
                  </div>

                  {/*--- ความสัมพันธ์ ---*/}
                  <div>
                    <label className={STYLE_TEXT_BLUE}>ความสัมพันธ์</label>
                    <div
                      className={`border rounded-full ${
                        errorForm.relation.isError
                          ? "border-red-500"
                          : "border-gray-200"
                      }`}
                    >
                      <DropDownMain
                        placeholder={`กรุณาเลือกความสัมพันธ์`}
                        options={RELATION_LIST}
                        // value={formFamilyTree.relation}
                        onChange={onChangeSelectedRelation}
                      />
                    </div>
                    {errorForm.relation.isError && (
                      <ErrorMain errortext={errorForm.relation.message} />
                    )}
                  </div>

                  {/*--- อายุ ---*/}
                  <div>
                    <label className={STYLE_TEXT_BLUE}>อายุ</label>
                    <div className="flex justify-center">
                     <NumbericValidateRed
                        // type="number"
                        maxLength={3}
                        thousandSeparator=","
                        inputMode="decimal"
                        pattern="[0-9],*"
                        placeholder="0"
                        className={`${STYLE_INPUT_SLIDER} mb-1.5`}
                        value={formFamilyTree.age > 0 ? formFamilyTree.age : ""}
                        onChange={onChangeAge}
                      />
                    </div>
                    <SliderSingle
                      year
                      placeholder="0"
                      valuemax={maxAge}
                      showInputMax={false}
                      value={formFamilyTree.age}
                      onChange={onChangeAge}
                    />
                    {errorForm.age.isError && (
                      <ErrorMain errortext={errorForm.age.message} />
                    )}
                  </div>
                  <div className="flex justify-between gap-2.5 w-full mt-[16px] mb-[38px]">
                    <MTButton
                      text="ย้อนกลับ"
                      color="bg-white"
                      border="border border-maincyan"
                      colortext="text-maincyan group-hover:text-white"
                      hovercolor="hover:bg-maincyan group "
                      width="w-full"
                      onClick={() => setStep(step - 1)}
                    />
                    <MTButton
                      text={"บันทึก"}
                      color="bg-maincyan"
                      width="w-full"
                      onClick={onSubmitForm}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <PopupMain
          open={openAlert}
          text={`ลงทะเบียนเสร็จสิ้น`}
          img={"/images/homeicon_smile_1.png"}
          onClick={onClickFinish}
        />
      </div>
    </React.Fragment>
  );
};

export default FamilyTreeMain;
