import { Step, Stepper } from "@material-tailwind/react";
import { HeaderTitle } from "../../components/header/Header";
import Container from "../../components/layout/Container";
import {
  STYLE_HEADER_LEGACY,
  STYLE_MARGIN_TOP_PARENT,
} from "../../utils/useStyle";
import { CardMenu } from "./styles/Card";
import ViewSavingPlanMain from "./components/VISavingPlan.Main";
import ViewExpensesPlanMain from "./components/VIExpensesPlan.Main";
import { createContext, useContext, useEffect, useState } from "react";
import NavigateGoBack, { NavigatePath } from "../../functions/Navigate.Goback";
import { useNavigate } from "react-router-dom";
import { getFirstLoad } from "./functions/functionsMain";
import { useIsMobile } from "../../utils/useIsMobile";
import { useUserStore } from "../../_store/userStore";
import Api from "../../api/api.main";

const SavingContext = createContext();
export const useSavingContext = () => useContext(SavingContext);

const SavingPlanMain = () => {
  // UserData Logined
  const userData = useUserStore((state) => state.userData);
  const setUserData = useUserStore((state) => state.setUserData);

  const [show, setShow] = useState("");
  const [formSaving, setFormSaving] = useState({
    saving_money_month: "",
    saving_money_year: "",
    saving_yield: 5,
    saving_yield_max: 10,
    saving_per_year: 10,
    saving_per_year_max: 20,
    saving_amount: "",

    expenses_reduce_month: "",
    expenses_reduce_year: "",
    expenses_invest_yield: 5,
    expenses_invest_yield_max: 10,
    expenses_per_year: 10,
    expenses_per_year_max: 20,
  });

  const navigate = useNavigate();

  const loadSavingPlanData = async ({ paramFilter }) => {
    try {
      //---------- เช็คว่ามีตารางแผนการออมหรือยัง ----------//
      const { data: dataSavingPlan } = await Api.get("/savingPlans", {
        params: paramFilter,
      });

      if (dataSavingPlan?.savingPlans?.length > 0) {
        const data = dataSavingPlan?.savingPlans[0];
        setFormSaving({
          saving_money_month: data?.saving_money_month,
          saving_money_year: data?.saving_money_year,
          saving_yield: data?.saving_yield,
          saving_yield_max: data?.saving_yield_max,
          saving_per_year: data?.saving_per_year,
          saving_per_year_max: data?.saving_per_year_max,
          saving_amount: data?.saving_amount,

          expenses_reduce_month: data?.expenses_reduce_month,
          expenses_reduce_year: data?.expenses_reduce_year,
          expenses_invest_yield: data?.expenses_invest_yield,
          expenses_invest_yield_max: data?.expenses_invest_yield_max,
          expenses_per_year: data?.expenses_per_year,
          expenses_per_year_max: data?.expenses_per_year_max,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (window != undefined) {
      window.scrollTo(0, 0);
    }

    // เช็คว่า User Online อยู่ไหม
    if (userData) {
      const userId = userData._id;
      const paramFilter = {
        filter: {
          user_id: userId,
        },
      };
      loadSavingPlanData({ paramFilter, userId });
    }
  }, []);

  const isMobile = useIsMobile();

  return (
    <SavingContext.Provider value={{ formSaving, setFormSaving }}>
      <Container>
        <div>
          <div className="">
            <div className={`${STYLE_MARGIN_TOP_PARENT} `}>
              <HeaderTitle
                isGoback
                onClickGoBack={() => {
                  show
                    ? show === "saving"
                      ? setShow("")
                      : setShow("")
                    : NavigatePath(navigate, "/");
                }}
                title={
                  show
                    ? `${
                        show === "saving"
                          ? "วางแผนการออม"
                          : "วางแผนลดค่าใช้จ่าย"
                      }`
                    : isMobile
                    ? "วางแผนการออม"
                    : "วางแผนการออมและวางแผนลดค่าใช้จ่าย"
                }
                title_2={isMobile ? (show ? "" : "และวางแผนลดค่าใช้จ่าย") : ""}
              />
              {!show && (
                <div
                  // style={{
                  //   height: `calc(100vh - 300px)`,
                  // }}
                  className=" px-[25px] mt-5 space-y-5"
                >
                  <CardMenu
                    title={"วางแผนการออม"}
                    img={"/images/saving_save.png"}
                    onClick={() => {
                      setShow("saving");
                    }}
                    imgsize={`w-[60px] h-[60px] `}
                  />
                  <CardMenu
                    title={"วางแผนลดค่าใช้จ่าย"}
                    img={"/images/saving_expenses.png"}
                    imgsize={`w-[60px] h-[60px] `}
                    onClick={() => {
                      setShow(" expenses ");
                    }}
                  />
                </div>
              )}

              {/*---------- วางแผนการออม ----------*/}
              {show === "saving" && (
                <ViewSavingPlanMain
                  formSaving={formSaving}
                  setFormSaving={setFormSaving}
                  setShow={setShow}
                />
              )}

              {/*---------- วางแผนลดค่าใช้จ่าย ----------*/}
              {show === " expenses " && (
                <ViewExpensesPlanMain
                  formSaving={formSaving}
                  setFormSaving={setFormSaving}
                  setShow={setShow}
                />
              )}
            </div>
          </div>
        </div>
      </Container>
    </SavingContext.Provider>
  );
};
export default SavingPlanMain;
