import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@material-tailwind/react";
import api from "./api/api.main";
import { useSettingStore } from "./_store/settingStore";

const initApp = async () => {
  try {
    const settingState = useSettingStore.getState();
    const { data: resData } = await api.get("/settings");
    if (resData?.settings?.length > 0) {
      const setting = resData.settings[0];
      settingState.setSettingData(setting);
    }
  } catch (error) {
    console.log("error", error);
  } finally {
  }
};

initApp();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <ThemeProvider>
    <App />
  </ThemeProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
