import React, { useMemo } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

// Register necessary components for Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

// Chart options
const options = {
  indexAxis: "y",
  elements: {
    bar: {
      borderWidth: 0,
    },
  },
  responsive: true,
  plugins: {
    // legend: {
    //   position: "bottom", // Adjusted position to bottom
    // },
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
  scales: {
    x: {
      beginAtZero: true,
      // max: 300000,
      min: 0,
      ticks: {
        font: {
          size: 10,
        },
      },
    },
    y: {
      stacked: true,
    },
  },
  // Set the height of the bars
  barThickness: 15, // Adjust the value as needed
};

const CustomHorizontalBarChart = ({
  yourData,
  recomenedData,
  width,
  barThickness = 15,
  height = 70,
}) => {
  // Chart data
  const data = useMemo(() => {
    return {
      labels: ["ทุนประกันของคุณ", "ทุนประกันที่แนะนำ"],
      datasets: [
        {
          label: "ทุนประกันของคุณ",
          data: yourData, // 'null' or 'undefined' to skip plotting
          backgroundColor: "#ff9900",
        },
        {
          label: "ทุนประกันที่แนะนำ",
          data: recomenedData, // 'null' or 'undefined' to skip plotting
          backgroundColor: "#00b3b3",
        },
      ],
    };
  }, [yourData, recomenedData]);

  const options = {
    indexAxis: "y",
    elements: {
      bar: {
        borderWidth: 0,
      },
    },
    responsive: true,
    plugins: {
      // legend: {
      //   position: "bottom", // Adjusted position to bottom
      // },
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        // max: 300000,
        min: 0,
        ticks: {
          font: {
            size: 10,
          },
          // stepSize: 50000, // Set the step size here
        },
      },
      y: {
        stacked: true,
      },
    },
    // Set the height of the bars
    barThickness, // Adjust the value as needed
  };

  return <Bar width={width} height={height} data={data} options={options} />;
};

export default CustomHorizontalBarChart;
