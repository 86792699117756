import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Checkbox,
} from "@material-tailwind/react";
import { ICON_INFO } from "../../utils/useIcons";
import { useState } from "react";
import { useStrategyStore } from "../../_store/strategyRetireStore";

const CheckBoxMain = ({
  label,
  label2,
  value,
  onChange,
  info,
  onClickInfo,
  panel,

  index,
  disabled = false,
  setChecked,
  handleOpen,
  setOpen,
  retireData,
  setRetireData,
  field,
  field2,
  open,
  icon,
  icon_width = "w-7 h-7",
  ishasRightLabel = true,
}) => {
  const clearvalue = () => {

    setRetireData({
      ...retireData,
      [field]: "",
      [field2]: "",
    });
  };

  const handleChange = (e) => {
    const value = e.target.checked;
    setOpen(value);
    if (!value) {
      clearvalue();
    }
  };


  return (
    <div key={index} className=" ">
      <div className=" -ml-3 ">
        <Checkbox
          onChange={handleChange}
          size={"sm"}
          className="p-0"
          color="cyan"
          checked={open}
          label={
            ishasRightLabel ? (
              <div className=" flex gap-2 items-center ">
                {icon && <img src={icon} className={icon_width} alt="" />}
                <div className=" text-md md:text-lg text-black  font-medium  hover:none ">
                  {label}
                  {label2}
                </div>
              </div>
            ) : (
              ""
            )
          }
          ripple={false}
        />
        {open && <div>{panel}</div>}
      </div>
    </div>
  );
};
export default CheckBoxMain;
