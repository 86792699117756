import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  RadialLinearScale,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { useEffect } from "react";
import { useRetireStore } from "../../_store/retireStore";
import { useIsMobile } from "../../utils/useIsMobile";
import numeral from "numeral";
import { nFormatter } from "../../utils/useFunctions";

ChartJS.register(
  RadialLinearScale,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Filler,
  Title,
  Tooltip,
  Legend
);

const DATA_COUNT = 7;
const NUMBER_CFG = { count: DATA_COUNT, min: -100, max: 100 };

// const data = (canvas) => {
//     const ctx = canvas.getContext("2d")
//     const gradient = ctx.createLinearGradient(0,0,100,0);
//     ...
//       return {
//           ...
//           backgroundColor: gradient
//           ...
//       }
//     }

const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const LineChart2Main = ({ dataPlan, labels, head, head2, dataPlan2 }) => {
  const retireData = useRetireStore((state) => state.retireData);
  const setRetireData = useRetireStore((state) => state.setRetireData);

  const isMobile = useIsMobile();

  const green = [];
  const yellow = [];
  const red = [];
  const greenAf = [];
  const yellowAf = [];
  const redAf = [];

  var isGreenEnd = false;
  var isGreenEnd2 = false;

  const getInvestSum = retireData?.bf_investment_assets;
  const getInvestSumAF = retireData?.af_investment_assets;
  const lastResultBF = getInvestSum[getInvestSum?.length - 1];
  const lastResultAF = getInvestSumAF[getInvestSumAF?.length - 1];

  // for (let index = 0; index < dataPlan.length; index++) {
  //   if (dataPlan[index] <= 0) {
  //     if (dataPlan[index - 1] > 0) {
  //       yellow.push(dataPlan[index]);
  //       red.push(dataPlan[index]);
  //       red.push(dataPlan[index + 1]);
  //     }
  //     red.push(dataPlan[index]);
  //   } else red.push(null);

  //   if (dataPlan[index] >= 0) {
  //     if (isGreenEnd) {
  //       /// normal yellow
  //       yellow.push(dataPlan[index]);
  //       green.push(null);
  //     } else {
  //       if (index > 0 && dataPlan[index] < dataPlan[index - 1]) {
  //         /// change to yellow
  //         isGreenEnd = true;
  //         green.push(dataPlan[index]);
  //         yellow.push(dataPlan[index]);
  //         // red.push(dataPlan[index]);
  //       } else {
  //         /// normal green
  //         green.push(dataPlan[index]);
  //         yellow.push(null);
  //       }
  //     }
  //   } else {
  //     green.push(null);
  //     yellow.push(null);
  //   }
  // }

  for (let index = 0; index < dataPlan.length; index++) {
    const currentValue = dataPlan[index];
    const nextValue = dataPlan[index + 1];

    //จุดแรก
    if (index === 0) {
      if (dataPlan[index] <= 0) {
        red.push(dataPlan[index]);
        yellow.push(null);
        green.push(null);
      } else {
        if (dataPlan[0] > dataPlan[1]) {
          yellow.push(dataPlan[index]);
          red.push(null);
          green.push(null);
        } else {
          green.push(dataPlan[index]);
          yellow.push(null);
          red.push(null);
        }
      }
      continue;
    }

    // red
    if (dataPlan[index] <= 0) {
      if (nextValue > currentValue && currentValue < 0 && nextValue > 0) {
        red.push(dataPlan[index]);
        green.push(dataPlan[index]);
        yellow.push(null);
        continue;
      }
      red.push(dataPlan[index]);
      yellow.push(null);
      green.push(null);
      continue;
    }

    if (dataPlan[index] < dataPlan[index - 1]) {
      // yellow => green
      if (nextValue > currentValue) {
        green.push(dataPlan[index]);
        yellow.push(dataPlan[index]);
        // yellow.push(null);
        red.push(null);
        continue;
      }

      //yellow => red
      if (nextValue < currentValue && nextValue < 0) {
        red.push(dataPlan[index]);
        green.push(null);
        yellow.push(dataPlan[index]);
        // yellow.push(null);
        continue;
      }

      yellow.push(dataPlan[index]);
      green.push(null);
      red.push(null);

      // console.log(`currentValue: `, currentValue);
      // console.log(`nextValue: `, nextValue);
    } else {
      //green => yellow

      if (nextValue < currentValue && nextValue > 0) {
        red.push(null);
        green.push(dataPlan[index]);
        yellow.push(dataPlan[index]);
        // yellow.push(null);
        continue;
      }
      //green
      green.push(dataPlan[index]);
      yellow.push(null);
      red.push(null);
    }
  }

  for (let index = 0; index < dataPlan2.length; index++) {
    const currentValue = dataPlan2[index];
    const nextValue = dataPlan2[index + 1];

    //จุดแรก
    if (index === 0) {
      if (dataPlan2[index] <= 0) {
        redAf.push(dataPlan2[index]);
        yellowAf.push(null);
        greenAf.push(null);
      } else {
        if (dataPlan2[0] > dataPlan2[1]) {
          yellowAf.push(dataPlan2[index]);
          redAf.push(null);
          greenAf.push(null);
        } else {
          greenAf.push(dataPlan2[index]);
          yellowAf.push(null);
          redAf.push(null);
        }
      }
      continue;
    }

    // redAf
    if (dataPlan2[index] <= 0) {
      if (nextValue > currentValue && currentValue < 0 && nextValue > 0) {
        redAf.push(dataPlan2[index]);
        greenAf.push(dataPlan2[index]);
        yellowAf.push(null);
        continue;
      }
      redAf.push(dataPlan2[index]);
      yellowAf.push(null);
      greenAf.push(null);
      continue;
    }

    if (dataPlan2[index] < dataPlan2[index - 1]) {
      // yellowAf => greenAf
      if (nextValue > currentValue) {
        greenAf.push(dataPlan2[index]);
        yellowAf.push(dataPlan2[index]);
        // yellowAf.push(null);
        redAf.push(null);
        continue;
      }

      //yellowAf => redAf
      if (nextValue < currentValue && nextValue < 0) {
        redAf.push(dataPlan2[index]);
        greenAf.push(null);
        yellowAf.push(dataPlan2[index]);
        // yellowAf.push(null);
        continue;
      }

      yellowAf.push(dataPlan2[index]);
      greenAf.push(null);
      redAf.push(null);

      // console.log(`currentValue: `, currentValue);
      // console.log(`nextValue: `, nextValue);
    } else {
      //greenAf => yellowAf

      if (nextValue < currentValue && nextValue > 0) {
        redAf.push(null);
        greenAf.push(dataPlan2[index]);
        yellowAf.push(dataPlan2[index]);
        // yellowAf.push(null);
        continue;
      }
      //greenAf
      greenAf.push(dataPlan2[index]);
      yellowAf.push(null);
      redAf.push(null);
    }
  }

  const data = {
    labels: labels,
    datasets: [
      {
        label: "",
        data: green,
        fill: true,

        borderColor: ({ chart: { ctx } }) => {
          const borderColor = "#16AA37";
          return borderColor;
        },

        pointBackgroundColor: "#16AA37",
        pointRadius: 0,
        backgroundColor: ({ chart: { ctx } }) => {
          const gradient = ctx.createLinearGradient(0, 0, 0, 300);
          gradient.addColorStop(0.2, "#16AA37");
          gradient.addColorStop(0.6, "rgba(255, 255, 255, 0.0)");

          return gradient;
        },
        tension: 0.2,
      },
      {
        label: "",
        data: yellow,
        fill: true,
        pointRadius: 0,
        borderColor: ({ chart: { ctx } }) => {
          const borderColor = "#FFD200";
          return borderColor;
        },

        pointBackgroundColor: "#FFD200",
        backgroundColor: ({ chart: { ctx } }) => {
          const gradient = ctx.createLinearGradient(0, 0, 0, 300);
          gradient.addColorStop(0.2, "#FFD200");
          gradient.addColorStop(0.6, "rgba(255, 255, 255, 0.0)");
          return gradient;
        },
        tension: 0.2,
      },
      {
        label: "",
        data: red,
        fill: true,
        pointRadius: 0,
        borderColor: ({ chart: { ctx } }) => {
          const borderColor = "#AA1616";
          return borderColor;
        },

        pointBackgroundColor: "#AA1616",

        backgroundColor: ({ chart: { ctx } }) => {
          const gradient = ctx.createLinearGradient(0, 0, 0, 300);
          gradient.addColorStop(0.1, "#AA1616");
          gradient.addColorStop(0.9, "rgba(255, 255, 255, 0)");

          return gradient;
        },
        tension: 0.2,
      },

      // after

      {
        label: "",
        data: greenAf,
        fill: true,
        pointRadius: 0,
        borderColor: ({ chart: { ctx } }) => {
          const borderColor = "#16AA37";
          return borderColor;
        },

        pointBackgroundColor: "#16AA37",
        backgroundColor: ({ chart: { ctx } }) => {
          const gradient = ctx.createLinearGradient(0, 0, 0, 300);
          gradient.addColorStop(0.2, "#16AA37");
          gradient.addColorStop(0.6, "rgba(255, 255, 255, 0.0)");

          return gradient;
        },
        tension: 0.2,
      },
      {
        label: "",
        data: yellowAf,
        fill: true,
        pointRadius: 0,
        borderColor: ({ chart: { ctx } }) => {
          const borderColor = "#FFD200";
          return borderColor;
        },

        pointBackgroundColor: "#FFD200",
        backgroundColor: ({ chart: { ctx } }) => {
          const gradient = ctx.createLinearGradient(0, 0, 0, 300);
          gradient.addColorStop(0.2, "#FFD200");
          gradient.addColorStop(0.6, "rgba(255, 255, 255, 0.0)");
          return gradient;
        },
        tension: 0.2,
      },
      {
        label: "",
        data: redAf,
        fill: true,
        pointRadius: 0,

        borderColor: ({ chart: { ctx } }) => {
          const borderColor = "#AA1616";
          return borderColor;
        },

        pointBackgroundColor: "#AA1616",

        backgroundColor: ({ chart: { ctx } }) => {
          const gradient = ctx.createLinearGradient(0, 0, 0, 300);
          gradient.addColorStop(0.1, "#AA1616");
          gradient.addColorStop(0.6, "rgba(255, 255, 255, 0)");

          return gradient;
        },
        tension: 0.2,
      },
    ],
  };

  const yScaleText = {
    id: "yScaleText",
    afterDraw(chart, args, options) {
      const {
        ctx,
        chartArea: { top },
        scales: { x, y },
      } = chart;
      ctx.save();
      ctx.font = "12px Arial";
      ctx.fillStyle = "#000";
      ctx.fillText("Y Scale Title", 0, top);
      ctx.restore();
    },
  };
  // const yScaleText = {
  //   id: "yScaleText",
  //   afterDraw(chart, args, options) {
  //     const {
  //       ctx,
  //       chartArea: { top },
  //       scales: { x, y },
  //     } = chart;
  //     ctx.save();
  //     ctx.font = "12px Arial";
  //     ctx.fillStyle = "#000";
  //     ctx.fillText("Y Scale Title", 0, top);
  //     ctx.restore();
  //   },
  // };

  const config = {
    type: "line",
    data,
    // plugins: [yScaleText],
  };

  const options = {
    legend: {
      // only required when importing chart.js/auto
      display: false,
    },
    scales: {
      x: {
        ticks: {
          maxTicksLimit: isMobile ? 10 : 20,
        },
      },
      y: {
        ticks: {
          callback: function (value, index, values) {
            return nFormatter(value, 2);

            // return value > 1000000
            //   ? `${numeral(value / 1000000)?.format("0,0")}M`
            //   : value > 10000
            //   ? `${numeral(value / 10000)?.format("0,0")}k`
            //   : value < 1000
            //   ? `${numeral(value)?.format("0,0")}`
            //   : value === 0
            //   ? 0
            //   : value > -1000
            //   ? `${numeral(value)?.format("0,0")}`
            //   : value < -10000
            //   ? `${numeral(value / 10000)?.format("0,0")}k`
            //   : value < -1000000
            //   ? `${numeral(value / 1000000)?.format("0,0")}M`
            //   : value;
          },
        },
        title: {
          beginAtZero: true,
          display: true,
          font: {
            size: isMobile ? 14 : 17,
            fontFamily: "IBMPlexSansThai",
          },
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          boxWidth: 0,
        },
      },
    },
  };

  return (
    <div className=" w-full relative ">
      <div className=" absolute top-0 md:-top-0 left-[15%] md:left-[6%] text-sm text-maingray font-semibold">
        {`บาท`}
      </div>
      <div className=" w-full flex-none -ml-[5%] ">
        <Line
          data={data}
          config={config}
          options={options}
          // dataPlan2={dataPlan2}
          // dataPlan={dataPlan}
        />
      </div>
      <div className=" absolute bottom-0 -right-2 md:-right-2 text-sm text-maingray font-semibold">
        {`อายุ`}
      </div>
    </div>
  );
};

export default LineChart2Main;
