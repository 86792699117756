import classNames from "classnames";
import {
  STYLE_TAB_HEADER_CLASSNAME,
  STYLE_TAB_HEDER_PROPS,
  STYLE_TAB_INACTIVE,
} from "../../../utils/useStyle";

const TabPanelReport = ({
  tab1,
  tab2,
  onTab1,
  onTab2,
  title_tab1,
  title_tab2,
}) => {
  return (
    <div>
      <div className={`${STYLE_TAB_HEADER_CLASSNAME} flex w-full `}>
        <div className={` w-full`}>
          <div
            onClick={onTab1}
            className={classNames(
              tab1
                ? `bg-white shadowtab text-mainblue rounded-[10px]  px-[22px] py-1 font-semibold font-IBMPlexSansThai text-md md:text-lg  w-full text-center  cursor-pointer `
                : `${STYLE_TAB_INACTIVE} text-center text-gray100 flex justify-center items-center py-1 cursor-pointer `
            )}
          >
            {title_tab1}
          </div>
        </div>
        <div className={` w-full`}>
          <div
            onClick={onTab2}
            className={classNames(
              tab2
                ? `bg-white shadowtab text-mainblue rounded-[10px]  px-[22px] py-1 font-semibold font-IBMPlexSansThai text-md md:text-lg  w-full text-center  cursor-pointer`
                : `${STYLE_TAB_INACTIVE} text-center text-gray100 flex justify-center items-center py-1 cursor-pointer `
            )}
          >
            {title_tab2}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TabPanelReport;
