import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const SkeletonMain = ({ height, count }) => {
  return (
    <SkeletonTheme baseColor="#E1EEFF" highlightColor="#fff">
      <p>
        <Skeleton count={count} height={height} />
      </p>
    </SkeletonTheme>
  );
};
