import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

const store = (set) => ({
  strategy: null,
  setStrategy: (strategy) => set(() => ({ strategy })),
  clearStrategy: () => set(() => ({ strategy: null })),
});

export const useStrategyStore = create(
  persist(store, {
    name: "strategy",
    storage: createJSONStorage(() => localStorage), // (optional) by default the 'localStorage' is used
  })
);
