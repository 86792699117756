import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  RadialLinearScale,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { useEffect } from "react";
import { useRetireStore } from "../../_store/retireStore";

ChartJS.register(
  RadialLinearScale,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Filler,
  Title,
  Tooltip,
  Legend
);

const DATA_COUNT = 7;
const NUMBER_CFG = { count: DATA_COUNT, min: -100, max: 100 };

// const data = (canvas) => {
//     const ctx = canvas.getContext("2d")
//     const gradient = ctx.createLinearGradient(0,0,100,0);
//     ...
//       return {
//           ...
//           backgroundColor: gradient
//           ...
//       }
//     }

const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const datatest = [3, 2, 0, -1, -2, -3, -2, -1, 0];
// const green = [null, 0, 0, 1, 2, 3, null, null];
// const yellow = [null, null, null, null, null, 2, 1, 0];
// const red = [-2, -1, 0, null, null, null, null, null, null];
const green = [];
const yellow = [];
const red = [];

var isGreenEnd = false;
let lastNullIndex = null;
let checkgreenIndices = [];

for (let index = 0; index < datatest.length; index++) {
  if (datatest[index] <= 0) {
    red.push(datatest[index]);
  } else red.push(null);

  if (datatest[index] >= 0) {
    if (isGreenEnd) {
      /// normal yellow
      yellow.push(datatest[index]);
      green.push(null);
    } else {
      if (index > 0 && datatest[index] < datatest[index - 1]) {
        /// change to yellow
        isGreenEnd = true;
        green.push(datatest[index]);
        yellow.push(datatest[index]);
      } else {
        /// normal green
        green.push(datatest[index]);
        yellow.push(null);
      }
    }
  } else {
    green.push(null);
    yellow.push(null);
  }
}

// for (let i = 0; i < datatest.length; i++) {
//   let checkgreen = green[green.length - 1];
//   let checkLenthGreen = green?.length;

//   console.log("green", green);
//   console.log("yellow", yellow);
//   console.log("red", red);
//   // console.log(checkLenthGreen);
//   // console.log(checkred);

//   if (datatest[i] < 0) {
//     /// red case
//     red.push(datatest[i]);
//     green.push(null);
//     yellow.push(null);
//   } else {
//     if (isGreenEnd) {
//       green.push(null);
//       red.push(null);
//       yellow.push(datatest[i]);
//     } else {
//       if (i > 0 && datatest[i] < datatest[i - 1]) {
//         /// it end and begin yellow
//         isGreenEnd = true;
//         green.push(null);
//         red.push(null);
//         yellow.push(datatest[i]);
//         lastNullIndex = green.length - 1;
//       } else {
//         // if (lastNullIndex !== null) {
//         //   green[lastNullIndex] = datatest[i];
//         // }
//         /// begin green
//         if (green.length == 0) {
//           red.push(datatest[i]);
//         } else {
//           red.push(null);
//         }
//         green.push(datatest[i]);
//         yellow.push(null);
//       }
//     }
//     /// green case
//   }
// }

const TestMain = ({ dataPlan, labels, head }) => {
  const data = {
    labels: ["A", "B", "C", "D", "E", "F", "G", "H"],
    datasets: [
      {
        label: "",
        data: green,
        fill: true,

        borderColor: ({ chart: { ctx } }) => {
          const borderColor = "#16AA37";
          return borderColor;
        },

        pointBackgroundColor: "#16AA37",

        backgroundColor: ({ chart: { ctx } }) => {
          const gradient = ctx.createLinearGradient(0, 0, 0, 300);
          gradient.addColorStop(0.2, "#16AA37");
          gradient.addColorStop(0.6, "rgba(255, 255, 255, 0.0)");

          return gradient;
        },
        tension: 0.2,
      },
      {
        label: "",
        data: yellow,
        fill: true,

        borderColor: ({ chart: { ctx } }) => {
          const borderColor = "#FFD200";
          return borderColor;
        },

        pointBackgroundColor: "#FFD200",
        backgroundColor: ({ chart: { ctx } }) => {
          const gradient = ctx.createLinearGradient(0, 0, 0, 300);
          gradient.addColorStop(0.2, "#FFD200");
          gradient.addColorStop(0.6, "rgba(255, 255, 255, 0.0)");
          return gradient;
        },
        tension: 0.2,
      },
      {
        label: "",
        data: red,
        fill: true,

        borderColor: ({ chart: { ctx } }) => {
          const borderColor = "#AA1616";
          return borderColor;
        },

        pointBackgroundColor: "#AA1616",

        backgroundColor: ({ chart: { ctx } }) => {
          const gradient = ctx.createLinearGradient(0, 0, 0, 300);
          gradient.addColorStop(0.5, "#AA1616");
          gradient.addColorStop(0.9, "rgba(255, 255, 255, 0)");

          return gradient;
        },
        tension: 0.2,
      },
    ],
  };
  const config = {
    type: "line",
    data,
    options: {
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    },
  };
  return (
    <div className=" flex items-end">
      <Line data={data} config={config} />
      {/* <div className=" -ml-3 flex justify-end items-end font-IBMPlexSansThai text-xs pb-6">
        อายุ
      </div> */}
    </div>
  );
};

export default TestMain;
