import React from "react";
import logo from "./logo.svg";
import HomeMain from "./pages/home/Home.Main";
import Navbar from "./components/layout/Navbar";
import MainRouter from "./router";
import { BrowserRouter } from "react-router-dom";
import api from "./api/api.main";
import { Amplify } from "aws-amplify";
import awsConfig from "./config/aws.config";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Helmet } from "react-helmet";
import { PdfProvider } from "./pages/pdf/context/pdf.ctx";
import numeral from "numeral";

Amplify.configure(awsConfig);

// Register a number format
numeral.register('format', 'number', {
  regexps: {
    format: /number/,
    unformat: /number/
  },
  format: function(value, format, roundingFunction) {
    // Check if the number is an integer
    if (value % 1 === 0) {
      return numeral(value).format('0,0');
    } else {
      return numeral(value).format('0,0.00');
    }
  },
  unformat: function(string) {
    return numeral().format(string);
  }
});

function App() {

  return (
    <>
      <Helmet>
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-GG3N25JMLF"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-GG3N25JMLF');
          `}
        </script>
      </Helmet>
      <GoogleOAuthProvider clientId="823155410992-38mh3uve6s15l58g9b3358lo381s0oei.apps.googleusercontent.com">
        <BrowserRouter>
          <PdfProvider>
            <MainRouter />
          </PdfProvider>
        </BrowserRouter>
      </GoogleOAuthProvider>
    </>
  );
}

export default App;
