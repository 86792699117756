import { useEffect } from "react";
import { useUserStore } from "../../../_store/userStore";
import api from "../../../api/api.main";
import { ImageMain } from "../../../components/img/Img.Main";
import {
  ViewLevelFive,
  ViewLevelFour,
  ViewLevelOne,
  ViewLevelThree,
  ViewLevelTwo,
} from "./ViewLevel.Main";

const ViewSumTest = ({ result, isReport }) => {
  const user = useUserStore((state) => state?.userData);
  const setUserData = useUserStore((state) => state.setUserData);

  useEffect(() => {
    if (result < 15) {
      addUserInvestData([
        {
          label: "ตราสารทุน",
          value: 20,
        },
        {
          label: "ตราสารหนี้",
          value: 80,
        },
      ]);
    } else if (result > 15 && result < 21) {
      addUserInvestData([
        {
          label: "ตราสารทุน",
          value: 30,
        },
        {
          label: "ตราสารหนี้",
          value: 70,
        },
      ]);
    } else if (result > 22 && result < 29) {
      addUserInvestData([
        {
          label: "ตราสารทุน",
          value: 30,
        },
        {
          label: "ตราสารหนี้",
          value: 60,
        },
        {
          label: "สินทรัพย์ทางเลือก",
          value: 10,
        },
      ]);
    } else if (result > 30 && result < 36) {
      addUserInvestData([
        {
          label: "ตราสารทุน",
          value: 40,
        },
        {
          label: "ตราสารหนี้",
          value: 40,
        },
        {
          label: "สินทรัพย์ทางเลือก",
          value: 20,
        },
      ]);
    } else {
      addUserInvestData([
        {
          label: "ตราสารทุน",
          value: 80,
        },
        {
          label: "สินทรัพย์ทางเลือก",
          value: 20,
        },
      ]);
    }
  }, []);

  const checkLevelTitle = () => {
    if (result < 15) {
      return "รับความเสี่ยงระดับต่ำ";
    } else if (result > 15 && result < 21) {
      return "รับความเสี่ยงระดับต่ำ-ปานกลาง";
    } else if (result > 22 && result < 29) {
      return "รับความเสี่ยงระดับปานกลาง";
    } else if (result > 30 && result < 36) {
      return "รับความเสี่ยงระดับปานกลาง-สูง";
    } else {
      return "รับความเสี่ยงระดับสูง";
    }
  };

  const checkLevel = (result) => {
    if (result < 15) {
      return "../images/number1.png";
    } else if (result > 15 && result < 21) {
      return "../images/number2.png";
    } else if (result > 22 && result < 29) {
      return "../images/number3.png";
    } else if (result > 30 && result < 36) {
      return "../images/number4.png";
    } else {
      return "../images/number5.png";
    }
  };

  const checkResult = (result) => {
    if (result < 15) {
      return <ViewLevelOne />;
    } else if (result > 15 && result < 21) {
      return <ViewLevelTwo />;
    } else if (result > 22 && result < 29) {
      return <ViewLevelThree />;
    } else if (result > 30 && result < 36) {
      return <ViewLevelFour />;
    } else {
      return <ViewLevelFive />;
    }
  };

  const addUserInvestData = async (data) => {
    try {
      await api
        .put(`/profiles/${user?._id}`, {
          balance: { invest: { expected: data } },
        })
        .then((res) => {
          setUserData(res?.data?.profile);
        });
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div>
      {isReport ? (
        <div className=" flex flex-col gap-5">
          <div>
            <div className=" font-medium text-sm text-center">
              ระดับความสามารถในการรับความเสี่ยงของคุณ คือ{" "}
            </div>
            <div className=" flex justify-center items-center gap-1 text-maincyan font-medium">
              <div className="">ระดับ</div>
              <ImageMain
                src={checkLevel(result)}
                className="w-[25px] h-[25px]"
              />
              <div className=""> ประเภทนักลงทุน{checkLevelTitle(result)}</div>
            </div>
          </div>

          {checkResult(result)}
        </div>
      ) : (
        <div>
          <ImageMain
            src="../images/invest_icon.png"
            className="  w-[165px] h-[84px] mx-auto "
          />
          <div className=" text-maincyan font-semibold text-center  text-2xl mt-2.5 ">
            {checkLevelTitle(result)}
          </div>
          <div className=" flex justify-center items-center gap-2 mb-5">
            <div className=" text-mainblue font-semibold ">
              ประเภทนักลงทุน ระดับ
            </div>
            <ImageMain src={checkLevel(result)} className="w-[25px] h-[25px]" />
          </div>
          {checkResult(result)}
        </div>
      )}
    </div>
  );
};

export default ViewSumTest;
