import { CardTest } from "../styles/Card";

const ViewNumberTen = ({ formInvest, setFormInvest }) => {
  // Choice One
  const onChangeChoiceOne = (e) => {
    let value = e.target.value;
    if (value === "one") {
      setFormInvest({
        ...formInvest,
        ten_question_choice_one: true,
        ten_question_choice_two: false,
        ten_question_choice_three: false,
        ten_question_choice_four: false,
      });
    }
  };

  // Choice Two
  const onChangeChoiceTwo = (e) => {
    let value = e.target.value;
    if (value === "two") {
      setFormInvest({
        ...formInvest,
        ten_question_choice_one: false,
        ten_question_choice_two: true,
        ten_question_choice_three: false,
        ten_question_choice_four: false,
      });
    }
  };

  // Choice Three
  const onChangeChoiceThree = (e) => {
    let value = e.target.value;
    if (value === "three") {
      setFormInvest({
        ...formInvest,
        ten_question_choice_one: false,
        ten_question_choice_two: false,
        ten_question_choice_three: true,
        ten_question_choice_four: false,
      });
    }
  };

  // Choice Four
  const onChangeChoiceFour = (e) => {
    let value = e.target.value;
    if (value === "four") {
      setFormInvest({
        ...formInvest,
        ten_question_choice_one: false,
        ten_question_choice_two: false,
        ten_question_choice_three: false,
        ten_question_choice_four: true,
      });
    }
  };

  return (
    <div className=" bg-gray50 p-4 mt-5 rounded-[10px] ">
      <div className=" text-maincyan text-xs">คำถามที่ 10 จาก 10</div>
      <CardTest
        choice1={<div>ตกใจ และต้องการขายการลงทุน ที่เหลือทิ้ง</div>}
        choice2={
          <div>
            กังวลใจ และจะปรับเปลี่ยนการลงทุน บางส่วนไปในทรัพย์สินที่เสี่ยงน้อยลง
          </div>
        }
        choice3={<div>อดทนถือต่อไปได้ และรอผลตอบแทนปรับตัวกลับมา</div>}
        choice4={
          <div>
            ยังมั่นใจ เพราะเข้าใจว่าต้องลงทุน ระยะยาวและจะเพิ่มเงินลงทุนใน
            แบบเดิมเพื่อเฉลี่ยต้นทุน
          </div>
        }
        value1={"one"}
        value2={"two"}
        value3={"three"}
        value4={"four"}
        onChange1={onChangeChoiceOne}
        onChange2={onChangeChoiceTwo}
        onChange3={onChangeChoiceThree}
        onChange4={onChangeChoiceFour}
        checked1={formInvest?.ten_question_choice_one}
        checked2={formInvest?.ten_question_choice_two}
        checked3={formInvest?.ten_question_choice_three}
        checked4={formInvest?.ten_question_choice_four}
        question="10. หากปีที่แล้วท่านลงทุนไป 100,000 บาท ปีนี้ท่านพบว่ามูลค่าเงินลงทุนลดลงเหลือ 85,000 บาท ท่านจะทำอย่างไร"
      />
    </div>
  );
};

export default ViewNumberTen;
