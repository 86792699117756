import React from "react";
import Select from "react-select";
import { cn } from "../../utils/useFunctions";

const DropDownMain = ({
  options,
  onChange,
  isSubmited = false,
  value,
  placeholder = "เลือกหัวข้อที่ต้องการ",
  disable,
  zIndex = null,
  border = "none",
  height = 36,
  className = "",
  rounded = 20,
  color,
  isDisabled = false
}) => {
  if (disable) {
    optionProps = {
      menuIsOpen: false,
    };
  }

  let optionProps = {};

  return (
    <Select
      className={cn(className,{
        "focus:outline-none focus:ring-0 focus:ring-red-500 border-red-500 border-2 ": isSubmited && value?.length <= 0
      })}
      {...optionProps}
      isDisabled={isDisabled}
      placeholder={placeholder}
      // isClearable={isClearable}
      // isSearchable={isSearchable}
      // isMulti={isMulti}
      options={options}
      value={value}
      onChange={onChange}
      components={{
        IndicatorSeparator: () => null,
      }}
      styles={{
        singleValue: (base) => ({
          ...base,
          color: color ?? "#18488A",
        }),
        control: (base) => ({
          ...base,
          menuPosition: "fixed",
          minHeight: height,
          fontSize: 14,
          color: "#18488A",
          border: border,
          backgroundColor: "#FFFFFF",
          outline: "none",
          cursor: "pointer",
          borderRadius: rounded,
        }),
        option: (styles, ...args) => {
          return {
            // ...styles,
            zIndex: zIndex,
            cursor: "pointer",
            borderRadius: rounded,
            border: "none",
            fontSize: 14,
            padding: "5px 20px",
            color: "#18488A",
          };
        },
      }}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: "none",
          primary: disable ? "none" : "",
        },
        borderRadius: 7,
      })}
    />
  );
};

export default DropDownMain;
