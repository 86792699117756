import {
  Accordion,
  AccordionBody,
  AccordionHeader,
} from "@material-tailwind/react";
import numeral from "numeral";
import { useEffect, useMemo, useState } from "react";
import { useFamilyStore } from "../../../_store/familyStore";
import { CardDeduction, CardMain } from "../../../components/card/CardMain";
import { InfoDrawer } from "../../../components/drawer/InfoDrawer";
import TaxHeirachy from "../../../functions/taxFucntion/taxHeirachy";
import { ICON_PLUS, ICON_RIGHT } from "../../../utils/useIcons";
import { useTaxPlanContext } from "../Tax_Plan.Main";
import { calculateTax } from "../functions";
import { TabTwoExpenseCalculation, TabTwoSumIncomeCalculation } from "../../../functions/taxFucntion/premium/tab2function";

const initialForm = {
  deduct_spouse: 0,
  deduct_child: 0,
  deduct_mother: 0,
  deduct_father: 0,
  deduct_disable_people: 0,
  deduct_disabilities_people: 0,
  deduct_insurance_premiums_parents: 0,
  insurance_life: 0,
  insurance_health: 0,
  insurance_annuity: 0,
  fund_provident: 0,
  fund_nsf: 0,
  fund_provident_rmf_value: 0,
  fund_provident_ssf_value: 0,
  home_loan: 0,
  fund_social_security: 0,
  prenatal_care_cost: 0,
  political_donate: 0,
  special_discount_gov: 0,
};

export const ReduceInfo = ({ openReduceInfo, setOpenReduceInfo }) => {
  return (
    <InfoDrawer
      head={"หักค่าใช้จ่าย (ต่อปี)"}
      text={
        " 50% แต่ไม่เกิน 100,000 บาท หากมีเงินได้ประเภทที่ 1 และ 2 ให้นำเงินได้ทั้ง 2 ประเภทรวมกัน หักค่าใช้จ่ายได้ 50% แต่รวมกันไม่เกิน 100,000 บาท "
      }
      open={openReduceInfo}
      onClose={() => {
        setOpenReduceInfo(false);
      }}
    />
  );
};

export const DeductionDrawer = ({ openDeductInfo, setOpenDeductInfo }) => {
  return (
    <InfoDrawer
      head={"ค่าลดหย่อน"}
      text={
        <div className=" space-y-2">
          <div>
            ค่าลดหย่อนและยกเว้นเป็นอีกหนึ่งองค์ประกอบในการคำนวณภาษีที่กฎหมายกำหนดให้นำไปหักออกจากเงินได้ได้อีกหลังจากหักค่าใช้จ่ายแล้ว
            โดยมีการหักลดหย่อนกรณีต่าง ๆ
          </div>
          <li>กรณีบุคคลธรรมดา หรือผู้ถึงแก่ความตายระหว่างปีภาษี </li>
          <li>
            กรณีห้างหุ้นส่วนสามัญที่มิใช่นิติบุคคล
            หรือคณะบุคคลที่ไม่ใช่นิติบุคคล หักค่าลดหย่อนได้คนละ 60,000 บาท
            แต่รวมกันต้องไม่เกิน 120,000 บาท{" "}
          </li>
          <li> กรณีกองมรดกที่ยังไม่ได้แบ่ง หักค่าลดหย่อนได้ 60,000 บาท </li>
        </div>
      }
      open={openDeductInfo}
      onClose={() => {
        setOpenDeductInfo(false);
      }}
    />
  );
};

const ViewStepTwoTaxPlan = ({
  onClick,
  form,
  setForm,
  openInfo,
  setOpenInfo,
  openReduceInfo,
  setOpenReduceInfo,
  openDeductInfo,
  setOpenDeductInfo,
  setTab,
  userData,
}) => {
  const [open, setOpen] = useState(true);
  const [isDeductionDetail, setIsDeductionDetail] = useState(false);
  const { deduction, setDeduction } = useTaxPlanContext();

  const ishasIncome = useFamilyStore((state) => state.ishasIncome);
  const setIshasIncome = useFamilyStore((state) => state.setIshasIncome);

  const isMarriage = useFamilyStore((state) => state.isMarriage);
  const setIsMarriage = useFamilyStore((state) => state.setIsMarriage);

  const isSpouseDisable = useFamilyStore((state) => state.isSpouseDisable);
  const setIsSpouseDisable = useFamilyStore(
    (state) => state.setIsSpouseDisable
  );

  const ishasChild = useFamilyStore((state) => state.ishasChild);
  const setIshasChild = useFamilyStore((state) => state.setIshasChild);

  const chlidList = useFamilyStore((state) => state.chlidList);
  const setChildList = useFamilyStore((state) => state.setChildList);

  const displayDeducExpenses = useMemo(() => {
    let sum = 0;
    if (form?.tax_income / 2 < 100000) {
      sum = form?.tax_income / 2;
      return sum;
    } else return 100000;
  }, [form?.deduction_expenses]);

  const displayTaxPaid = useMemo(() => {
    if (userData?.is_premium) {
      return calculateTax(form.tax_sum_income);
    }
    return calculateTax(form.tax_income);
  }, [form?.tax_income]);

  // รวมค่าลดหย่อนใน เพิ่มค่าลดหย่อน
  const displaySumDeduction = useMemo(() => {
    let sum = 0;

    sum += TaxHeirachy(form).reduce((sum, item) => {
      return sum + Number(item?.value2 || 0);
    }, 0);
    return Number(sum);
  }, [deduction]);

  const displaySumAmount = useMemo(() => {
    let sum = 0;

    deduction.map((item) => {
      sum += Number(item.amount || 0);
    });

    return Number(sum);
  }, [deduction]);

  const getCaltax = () => {
    let result = 0;
    result = Number(form.tax_income) - 100000 - Number(displaySumDeduction);

    if (userData?.is_premium) {
      // console.log("TabTwoSumIncomeCalculation",TabTwoSumIncomeCalculation())
      // console.log("TabTwoExpenseCalculation",TabTwoExpenseCalculation())
      // console.log("displaySumDeduction",displaySumDeduction)
      // console.log("--------------------")
      // result = TabTwoSumIncomeCalculation() - TabTwoExpenseCalculation() - Number(displaySumDeduction)
      result = TabTwoSumIncomeCalculation()-TabTwoExpenseCalculation() - Number(displaySumDeduction)

    }
    
    return calculateTax(result);
  };

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      setForm({
        ...form,
        deduction_expenses: displayDeducExpenses,
        deduct_summary: displaySumDeduction,
      });
    }
    return () => {
      ignore = true;
    };
  }, [displayDeducExpenses, displaySumDeduction]);

  return (
    <>
      <div className="my-9 ">
        <div className=" flex flex-col justify-center gap-1 items-center font-IBMPlexSansThai font-medium mb-[15px]">
          <img
            src="/images/clarity_coin_bag_yellow.svg"
            className=" w-[61px] h-[61px]"
            alt=""
          />
          <div className=" text-black text-sm text-center ">
            ภาษีที่ต้องจ่าย
          </div>
          <div className=" text-maincyan text-center text-md ">
            {numeral(getCaltax()).format("0,0")} บาท
          </div>
          <div className=" text-xs font-normal text-maingray ">
            จาก {userData?.is_premium ? numeral(calculateTax(TabTwoSumIncomeCalculation())).format("0,0"):numeral(displayTaxPaid).format("0,0")} บาท
            {/* TabTwoSumIncomeCalculation() */}
          </div>
          <div className=" text-xs font-normal text-maingray ">
            *หักค่าใช้จ่ายและค่าลดหย่อนส่วนตัวแล้ว
          </div>
          <div className="w-fit flex mt-[12px] ">
              <div className="text-center text-md mr-[20px] ">
                รายได้ต่อปีรวม
              </div>
              <div className=" text-maincyan text-center text-md ">
                {numeral(TabTwoSumIncomeCalculation()).format("0,0")} บาท
              </div>
            </div>
        </div>
        <div className="space-y-5">
          <CardMain
            onClick={() => {
              setOpenReduceInfo(true);
            }}
            icon={true}
            title="หักค่าใช้จ่าย (ต่อปี)"
            panel={
              <div className="mt-5 px-3 text-xl font-semibold text-maincyan text-center">
                {userData?.is_premium
                  ? <>{numeral(TabTwoExpenseCalculation()).format("0,0")}</>
                  : numeral(displayDeducExpenses).format("0,0")}{" "}
                <span>บาท</span>
              </div>
            }
          />
          <CardMain
            icon={true}
            onClick={() => {
              setOpenDeductInfo(true);
            }}
            title="ค่าลดหย่อน"
            panel={
              <div className="mt-5 ">
                <div className=" flex justify-center items-center gap-5 mb-4">
                  <img
                    src="/images/tax_icon.png"
                    className=" w-[45px] h-[45px] "
                    alt=""
                  />
                  <div className=" flex flex-col justify-center items-center">
                    <div className=" text-sm text-maingray font-medium ">
                      รวมใช้สิิทธิลดหย่อน
                    </div>
                    <div className="text-xl font-semibold text-maincyan text-center">
                      {numeral(displaySumDeduction).format("0,0")} บาท
                    </div>
                    <div className=" text-sm text-maingray font-medium ">
                      จากทั้งหมด
                    </div>
                    <div className="text-sm font-semibold text-maingray text-center">
                      {numeral(displaySumAmount).format("0,0")} บาท
                    </div>
                  </div>
                  <ICON_RIGHT
                    className={` w-6 h-6 text-maingray hover:text-maincyan cursor-pointer `}
                    onClick={() => {
                      setIsDeductionDetail(!isDeductionDetail);
                      setTab("firstDeduction");
                    }}
                  />
                </div>
                <Accordion
                  icon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 17 16"
                      className={`${
                        open ? "" : "rotate-180"
                      } transition-transform h-4 w-4`}
                      fill="none"
                    >
                      <path
                        d="M3.83366 10L8.50033 5.33336L13.167 10"
                        stroke="#919191"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  }
                  open={open}
                  className="group"
                >
                  <AccordionHeader
                    className={` border-b-0 transition-colors text-xs  font-IBMPlexSansThai flex justify-center  items-center w-full border-t border-t-[#E0DFDF] antialiased text-left pt-1`}
                    onClick={() => {
                      setOpen(!open);
                    }}
                  >
                    <div className="text-[#909090] font-normal">รายละเอียด</div>
                  </AccordionHeader>

                  {/*---------- เพิ่มค่าลดหย่อน ----------*/}
                  <div
                    onClick={onClick}
                    className=" text-center text-lg underline text-maincyan hover:text-secondcyan duration-300 font-semibold flex items-center justify-center gap-2 cursor-pointer"
                  >
                    <ICON_PLUS className={`w-5 h-5`} /> เพิ่มค่าลดหย่อน
                  </div>
                  <AccordionBody className=" px-6 sm:px-9 space-y-2.5 font-IBMPlexSansThai">
                    {deduction?.map((row, index) => {
                      if (row?.amount > 0) {
                        return (
                          <CardDeduction
                            key={index}
                            title={row?.title}
                            amount={numeral(row?.amount).format("0,0")}
                            onClick={() => {
                              setOpenInfo(openInfo);
                            }}
                            minus={index !== 0 ? true : false}
                            onClickDelete={() => {
                              let temp = [...deduction];
                              let temp_form = temp[index];

                              if (temp_form?.key === "deduct_spouse") {
                                temp.splice(index, 1);
                                setDeduction(temp);
                                setIshasIncome(true);
                                setIsMarriage(true);
                                setIsSpouseDisable(true);
                              } else if (temp_form?.key === "deduct_child") {
                                temp.splice(index, 1);
                                setDeduction(temp);
                                setIshasChild(false);
                                setChildList([]);
                              } else {
                                temp.splice(index, 1);
                                setDeduction(temp);
                              }

                              if (temp_form.key) {
                                return setForm({
                                  ...form,
                                  [temp_form.key]: 0,
                                });
                              }
                            }}
                          />
                        );
                      }
                    })}
                  </AccordionBody>
                </Accordion>
              </div>
            }
          />
        </div>
        {openReduceInfo && (
          <ReduceInfo
            openReduceInfo={openReduceInfo}
            setOpenReduceInfo={setOpenReduceInfo}
          />
        )}

        {openDeductInfo && (
          <DeductionDrawer
            openDeductInfo={openDeductInfo}
            setOpenDeductInfo={setOpenDeductInfo}
          />
        )}
      </div>
    </>
  );
};
export default ViewStepTwoTaxPlan;
