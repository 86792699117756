import { useEffect } from "react";
import { useRetireStore } from "../../../_store/retireStore";
import { MTButton } from "../../../components/button/MTButton";
import LineChartMain from "../../../components/chart/LineChartMain";
import {
  ICON_CHECK,
  ICON_CLOSE,
  ICON_STAR,
  ICON_WARNING,
} from "../../../utils/useIcons";
import {
  STYLE_FLEX_STATUS,
  STYLE_HEAD_STRAGADY,
} from "../../../utils/useStyle";
import numeral from "numeral";
import { useIsMobile } from "../../../utils/useIsMobile";
import { useNavigate } from "react-router-dom";
import { ImageMain } from "../../../components/img/Img.Main";

const ViewBeforeRetirePlan_Tab = ({
  setRetireData,
  sumBeforeRetireInvest,
  labelsbf,
  databf,
  lastResult,
  retireResult,
  remain,
  strategyChange,
  onClickSTG,
  beforeDiffYear,
}) => {
  const retireData = useRetireStore((state) => state.retireData);
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  return (
    <div>
      <div className=" flex flex-col gap-2 mb-[8px] mt-4">
        <div className={STYLE_HEAD_STRAGADY}>ความเคลื่อนไหวทางการเงิน</div>
        <div className={`border-b border-mainyellow w-[295px] -mx-4 `}></div>
      </div>
      <div className=" px-4">
        <LineChartMain
          labels={labelsbf}
          dataPlan={databf}
          head={`สินทรัพย์เพื่อการลงทุนสุทธิ`}
        />
      </div>
      {strategyChange ? (
        <>
          {lastResult?.results > 0 ? (
            <>
              <div className=" space-y-[10px] mb-3 mt-5">
                <div className={STYLE_FLEX_STATUS}>
                  <ICON_CHECK className=" w-6 text-maincyan " />
                  <div className="  text-sm flex-none">
                  เริ่มวางแผนเกษียณตอนอายุ{" "}
                    <span className="font-medium">
                      {retireData?.age_current} ปี
                    </span>
                  </div>
                </div>
                <div className={STYLE_FLEX_STATUS}>
                  <ICON_WARNING className=" w-6 text-mainyellow" />
                  <div className="  text-sm flex-none">
                    สามารถใช้จนได้ถึงอายุ{" "}
                    <span className="font-medium">
                      {retireData?.strategy_age_dead
                        ? retireData?.strategy_age_dead
                        : retireData?.age_dead}{" "}
                      ปี
                    </span>{" "}
                    แต่อย่าประมาทนะ
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className=" mt-[26px] mb-6 space-y-2.5">
              <div className={STYLE_FLEX_STATUS}>
                <ICON_CHECK className=" w-6 text-maincyan flex-none" />
                <div className="  text-sm flex-none">
                  เริ่มวางแผนเกษียณตอนอายุ{" "}
                  <span className="font-medium">
                    {retireData?.age_current} ปี
                  </span>
                </div>
              </div>
              <div className={STYLE_FLEX_STATUS}>
                <ICON_WARNING className=" w-6 text-mainyellow" />
                <div className="  text-sm flex-none">
                  สามารถใช้จนได้ถึงอายุ
                  <span className=" font-medium"> {remain} ปี </span>
                </div>
              </div>
              <div className={STYLE_FLEX_STATUS}>
                <ICON_CLOSE className=" w-6 text-mainred" />
                {isMobile ? (
                  <div className="  text-sm flex-none">
                    <div>
                      ถ้าต้องการมีเงินใช้จ่ายจนถึงอายุ{" "}
                      <span className=" font-medium">
                        {retireData?.age_dead} ปี
                      </span>{" "}
                    </div>
                    ยังขาดอยู่ทั้งหมด{" "}
                    <span className=" font-medium text-mainblue">
                      {lastResult?.results < 0
                        ? numeral(lastResult?.results * -1)?.format("0,0")
                        : numeral(lastResult?.results)?.format("0,0")}
                      {""} บาท
                    </span>
                  </div>
                ) : (
                  <div className="  text-sm flex-none">
                    ถ้าต้องการมีเงินใช้จ่ายจนถึงอายุ{" "}
                    <span className=" font-medium">
                      {retireData?.age_dead} ปี
                    </span>{" "}
                    ยังขาดอยู่ทั้งหมด{" "}
                    <span className=" font-medium text-mainblue">
                      {lastResult?.results < 0
                        ? numeral(lastResult?.results * -1)?.format("0,0")
                        : numeral(lastResult?.results)?.format("0,0")}
                      {""} บาท
                    </span>
                  </div>
                )}
              </div>
              <div className={STYLE_FLEX_STATUS}>
                <ICON_STAR className=" w-6 text-maingreen flex-none " />
                <div className="  text-sm ">
                  ควรวางแผนลงทุนผลตอบแทน{" "}
                  <span className=" font-semibold">
                    {" "}
                    {retireData?.investment_bf_retirement} %{" "}
                  </span>
                  เป็นระยะเวลา{" "}
                  <span className=" font-semibold">{beforeDiffYear} ปี</span>{" "}
                  เดือนละ{" "}
                  <span className=" font-semibold text-mainblue">
                    {numeral(sumBeforeRetireInvest)?.format("0,0")} บาท
                  </span>
                </div>
              </div>

              <div className=" text-center font-semibold text-lg text-maincyan ">
                และเพื่อให้แผนเกษียณเป็นจริง
                <div className=" flex justify-center w-full">
                  <div>มาปรับกลยุทธ์กันเถอะ</div>
                  <ImageMain
                    src="../images/goodthumb.png"
                    className=" h-8 w-8 "
                  />
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className=" space-y-[10px] mb-3 mt-5">
          {lastResult?.results > 0 ? (
            <>
              <div className={STYLE_FLEX_STATUS}>
                <ICON_CHECK className=" w-6 text-maincyan " />
                <div className="  text-sm flex-none">
                  เริ่มวางแผนเกษียณตอนอายุ
                  <div>
                    <span className="font-medium">
                      {retireData?.age_retire} ปี
                    </span>{" "}
                    จำนวน{" "}
                    <span className=" font-medium text-mainblue">
                      {numeral(retireResult)?.format("0,0.00")}
                      {/* {lastResult} บาท */}
                    </span>
                  </div>
                </div>
              </div>
              <div className={STYLE_FLEX_STATUS}>
                <ICON_WARNING className=" w-6 text-mainyellow" />
                <div className="  text-sm flex-none">
                  สามารถใช้จนได้ถึงอายุ{" "}
                  <span className="font-medium">
                    {retireData?.age_dead} ปี{" "}
                  </span>
                  แต่อย่าประมาทนะ
                </div>
              </div>
            </>
          ) : (
            <>
              <div className={STYLE_FLEX_STATUS}>
                <ICON_WARNING className=" w-6 text-mainyellow" />
                <div className="  text-sm flex-none">
                  สามารถใช้จนได้ถึงอายุ
                  <span className=" font-medium"> {remain} ปี </span>
                </div>
              </div>
              <div className={STYLE_FLEX_STATUS}>
                <ICON_CLOSE className=" w-6 text-mainred" />
                <div className="  text-sm flex-none">
                  <div>
                    ถ้าต้องการมีเงินใช้จ่ายจนถึงอายุ{" "}
                    <span className=" font-medium">
                      {retireData?.age_dead} ปี
                    </span>{" "}
                  </div>
                  ยังขาดอยู่ทั้งหมด{" "}
                  <span className=" font-medium text-mainblue">
                    {lastResult?.results < 0
                      ? numeral(lastResult?.results * -1)?.format("0,0")
                      : numeral(lastResult?.results)?.format("0,0")}
                    {""} บาท
                  </span>
                </div>
              </div>
              <div className={STYLE_FLEX_STATUS}>
                <div className={STYLE_FLEX_STATUS}>
                  <ICON_STAR className=" w-6 text-maingreen flex-none " />
                  <div className="  text-sm ">
                    ควรวางแผนลงทุนผลตอบแทน{" "}
                    <span className=" font-semibold">
                      {" "}
                      {retireData?.investment_bf_retirement} %{" "}
                    </span>
                    เป็นระยะเวลา{" "}
                    <span className=" font-semibold">{beforeDiffYear} ปี</span>{" "}
                    เดือนละ{" "}
                    <span className=" font-semibold text-mainblue">
                      {numeral(sumBeforeRetireInvest)?.format("0,0")} บาท
                    </span>
                  </div>
                </div>
              </div>
              <div className=" text-center font-semibold text-lg text-maincyan ">
                และเพื่อให้แผนเกษียณเป็นจริง
                <div className=" flex justify-center w-full">
                  <div>มาปรับกลยุทธ์กันเถอะ</div>
                  <ImageMain
                    src="../images/goodthumb.png"
                    className=" h-8 w-8 "
                  />
                </div>
              </div>
            </>
          )}
        </div>
      )}
      <MTButton
        onClick={onClickSTG}
        text="ปรับกลยุทธ์"
        color="bg-maincyan "
        colortext="text-white font-medium"
        width="w-full"
      />

      {/* <MTButton
        width="w-full"
        onClick={() => navigate("/summarytablemovements?before")}
        text="ดูรายละเอียด"
        color="border-maincyan bg-white"
        colortext="text-maincyan group-hover:text-white font-medium"
        hovercolor="hover:bg-maincyan group "
        variant="outlined"
      /> */}
    </div>
  );
};
export default ViewBeforeRetirePlan_Tab;
