import { numberWithoutComma } from "../../../pages/balance/component/VIDrawerDebt.Main";
import { calculatePersonalIncomeTax, calculateTax } from "../../../pages/tax_plan/functions";

export const LeavingCalc = (
  year_work = 0,
  // เงินบำเหน็จฯ ข้าราชการ
  pension_money = 0,
  // เงินที่จ่ายจากกองทุน เช่น กองทุนสำรองเลี้ยงชีพ กบข.
  fund_money = 0,
  // เงินชดเชยตามกฎหมายแรงงานที่ได้รับการยกเว้นแล้ว
  Compensation_money = 0,
  // เงินที่จ่ายให้ครั้งเดียวมีวิธีการดำเนินการต่างจาก บำเหน็จฯ ข้าราชการ
  single_pay_money = 0,
  calculate_method = "",
  // เงินเดือนรับจากนายจ้าง
  salary_from_employer = 0,
  // จำนวนปี
  year_from_employer = 0,
  // ค่าใช้จ่ายส่วนแรกคำนวณจาก
  calc_default = 0,
  // ภาษีหัก ณ ที่จ่าย
  with_holding_tax = 0
) => {
  // สรุปการคำนวณภาษีเงินได้สุทธิ
  let totalIcome = 0;

  // ภาษีคำนวณจากเงินได้สุทธิ
  let taxCalc = 0;

  // ภาษีที่ชำระไว้เกิน
  let excessTax = 0;

  // ภาษีที่ต้องชำระเพิ่ม
  let moreTax = 0;

  let sumIncome =
    Number(numberWithoutComma(pension_money)) +
    Number(numberWithoutComma(fund_money)) +
    Number(numberWithoutComma(Compensation_money)) +
    Number(numberWithoutComma(single_pay_money));


  const totalExpense = () => {
    // จำนวนเงินได้ที่ถือเป็นฐาน เพื่อคำนวณหาค่าใช้จ่าย
    let baseIncome =
      Number(numberWithoutComma(pension_money)) +
      Number(numberWithoutComma(fund_money)) +
      Number(numberWithoutComma(Compensation_money)) 

      // เงินเดือนรับจากนายจ้างคำนวณจาก
    let calcSalary = Number(numberWithoutComma(salary_from_employer)) * Number(numberWithoutComma(year_from_employer)) 

    // จำนวนเงินได้ที่ถือเป็นฐาน เพื่อคำนวณหาค่าใช้จ่าย
    if (Number(numberWithoutComma(single_pay_money))>calcSalary) {
      baseIncome += calcSalary
    }else{
      baseIncome += Number(numberWithoutComma(single_pay_money))
    }

    // ค่าใช้จ่ายส่วนแรก
    let firstExpense = Number(numberWithoutComma(calc_default)) * Number(numberWithoutComma(year_from_employer))

    // คงเหลือ (จำนวนเงินที่เป็นฐานเพื่อคำนวณหาค่าใช้จ่าย - ค่าใช้จ่ายส่วนแรก)
    let ramain = baseIncome - firstExpense

    // ค่าใช้จ่าย ส่วนที่ 2 ร้อยละ 50
    let secondExpense = ramain/2

    // รวมค่าใช้จ่ายที่หักได้ทั้งหมด
    let allRemainIncome = baseIncome - secondExpense

    return allRemainIncome
  };

  totalIcome = sumIncome - totalExpense()
  taxCalc = calculatePersonalIncomeTax(totalIcome)

  if (Number(numberWithoutComma(with_holding_tax)) > taxCalc ) {
    excessTax = Number(numberWithoutComma(with_holding_tax)) - taxCalc;
    moreTax = 0;
  }else{
    excessTax = 0;
    moreTax = taxCalc - Number(numberWithoutComma(with_holding_tax));
  }

  return {
    totalIcome,
    taxCalc,
    excessTax,
    moreTax,
  };
  // return totalExpense();
};
