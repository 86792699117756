import React from "react";
import { useBalanceStore } from "../../../_store/balanceStore";
import numeral from "numeral";
import { OPTIONS_TYPE_CAR, OPTIONS_TYPE_PLACE } from "../functions";

const ListDebtMain = ({ sumExpensesDebtPerYear, showTotal = true }) => {
  const setBalanceData = useBalanceStore((state) => state.setBalanceData);
  const balanceData = useBalanceStore((state) => state.balanceData);

  // หายอดรวมค่าใช้จ่ายหนี้สินรายเดือนทั้งหมด
  const sumExpensesDebtPermonth = () => {
    let result = 0;
    if (balanceData?.sum_debt_short_permonth) {
      result += balanceData?.sum_debt_short_permonth;
    }
    if (balanceData?.sum_debt_long_permonth) {
      result += balanceData?.sum_debt_long_permonth;
    }
    return result;
  };

  return (
    <div className="mb-1.5">
      <div className=" border-b border-gray60 pb-3 mb-3"></div>
      <div className="flex flex-col items-center gap-2.5">
        <div className={`text-mainblue text-xs font-medium`}>
          เงินผ่อนชำระงวดหนี้สินคงค้างรายเดือน (บาท/เดือน)
        </div>
        <div className="flex justify-between p-2 bg-secoundblue rounded-[10px] w-full text-sm text-mainblue font-medium">
          <div>หัวข้อ</div>
          <div>บาท/เดือน</div>
        </div>
        {/*รายการหนี้สิน*/}
        {balanceData?.debt_short_list?.length > 0 ||
        balanceData?.debt_long_list?.length > 0 ? (
          <>
            {/*---------- หนี้ระยะสั้น ----------*/}
            {balanceData?.debt_short_list?.length > 0 && (
              <div className="w-full pb-2.5 border-b border-gray60">
                <div className="text-sm text-mainblue font-medium mb-1.5">
                  หนี้สินระยะสั้น
                </div>
                <div className="flex flex-col gap-1.5">
                  {/*---- บัตรเครดิต ----*/}
                  {balanceData?.debt_short_list?.map((item, index) => {
                    if (item?.options_debt_short === "debt_creditcard")
                      return (
                        <div key={index} className="">
                          <div className="text-xs text-mainblue font-medium">
                            บัตรเครดิต
                          </div>
                          {item?.credit_cards?.map((item2, index) => {
                            return (
                              <div className="flex flex-col">
                                <div
                                  key={index}
                                  className="flex justify-between items-center text-xs font-normal"
                                >
                                  <div>
                                    {item2?.creditor_name}{" "}
                                    {item2?.type_payment === "pay_minimum"
                                      ? "(ชำระขั้นต่ำ)"
                                      : ""}
                                  </div>
                                  <div className="text-mainblue">
                                    {numeral(item2?.pay_permonth).format("0,0")} บาท
                                  </div>
                                </div>
                                {item2?.type_payment === "pay_minimum" && (
                                  <div className="text-xs font-medium">
                                    * เป็นการประมาณค่า 8% ของยอดค้างชำระต่อเดือน
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      );
                  })}

                  {/*---- เงินกู้ระยะสั้น ----*/}
                  {balanceData?.debt_short_list?.map((item, index) => {
                    if (item?.options_debt_short === "debt_shortterm")
                      return (
                        <div key={index} className="">
                          <div className="text-xs text-mainblue font-medium">
                            เงินกู้ระยะสั้น
                          </div>
                          {item?.short_terms?.map((item2, index) => {
                            return (
                              <div
                                key={index}
                                className="flex justify-between items-center text-xs font-normal"
                              >
                                <div>{item2?.creditor_name}</div>
                                <div className="text-mainblue">
                                  {numeral(item2?.pay_permonth).format("0,0")} บาท
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      );
                  })}

                  {/*---- หนี้นอกระบบ ----*/}
                  {balanceData?.debt_short_list?.map((item, index) => {
                    if (item?.options_debt_short === "debt_informal_shortterm")
                      return (
                        <div key={index} className="">
                          <div className="text-xs text-mainblue font-medium">
                            หนี้นอกระบบ
                          </div>
                          {item?.informal_short_terms?.map((item2, index) => {
                            return (
                              <div
                                key={index}
                                className="flex justify-between items-center text-xs font-normal"
                              >
                                <div>{item2?.creditor_name}</div>
                                <div className="text-mainblue">
                                  {numeral(item2?.pay_permonth).format("0,0")} บาท
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      );
                  })}

                  {/*---- เงินกู้ระยะสั้นอื่น ๆ ----*/}
                  {balanceData?.debt_short_list?.map((item, index) => {
                    if (item?.options_debt_short === "debt_shortterm_other")
                      return (
                        <div key={index} className="">
                          <div className="text-xs text-mainblue font-medium">
                            เงินกู้ระยะสั้นอื่น ๆ
                          </div>
                          {item?.short_term_others?.map((item2, index) => {
                            return (
                              <div
                                key={index}
                                className="flex justify-between items-center text-xs font-normal"
                              >
                                <div>{item2?.creditor_name}</div>
                                <div className="text-mainblue">
                                  {numeral(item2?.pay_permonth).format("0,0")} บาท
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      );
                  })}
                </div>
              </div>
            )}
            {/*---------- หนี้ระยะยาว ----------*/}
            {balanceData?.debt_long_list?.length > 0 && (
              <div className="w-full pb-2.5 border-b border-gray60">
                <div className="text-sm text-mainblue font-medium mb-1.5">
                  หนี้สินระยะยาว
                </div>
                <div className="flex flex-col gap-1.5">
                  {/*---- สินเชื่อรถยนต์ / จักรยานยนต์ ----*/}
                  {balanceData?.debt_long_list?.map((item, index) => {
                    if (item?.options_debt_long === "debt_car")
                      return (
                        <div key={index} className="">
                          <div className="text-xs text-mainblue font-medium">
                            สินเชื่อรถยนต์ / จักรยานยนต์
                          </div>
                          {item?.debt_long_cars?.map((item2, index) => {
                            const findTypeCar = OPTIONS_TYPE_CAR?.filter(
                              (thisType) => {
                                if (thisType.value === item2?.type_car)
                                  return thisType;
                              }
                            );
                            const typeCar = findTypeCar?.[0]?.label;
                            return (
                              <div
                                key={index}
                                className="flex justify-between items-center text-xs font-normal"
                              >
                                <div>
                                  {typeCar} {item2?.title_car}
                                </div>
                                <div className="text-mainblue">
                                  {numeral(item2?.pay_permonth).format("0,0")} บาท
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      );
                  })}

                  {/*---- สินเชื่อที่อยู่อาศัย ----*/}
                  {balanceData?.debt_long_list?.map((item, index) => {
                    if (item?.options_debt_long === "debt_place")
                      return (
                        <div key={index} className="">
                          <div className="text-xs text-mainblue font-medium">
                            สินเชื่อที่อยู่อาศัย
                          </div>
                          {item?.debt_long_places?.map((item2, index) => {
                            const findTypePlace = OPTIONS_TYPE_PLACE?.filter(
                              (thisType) => {
                                if (thisType.value === item2?.type_place)
                                  return thisType;
                              }
                            );
                            const typePlace = item2?.type_place_other
                              ? item2?.type_place_other
                              : findTypePlace?.[0]?.label;
                            return (
                              <div
                                key={index}
                                className="flex justify-between items-center text-xs font-normal"
                              >
                                <div className=" line-clamp-1 pr-5">
                                  {typePlace} ({item2?.address_place})
                                </div>
                                <div className="text-mainblue">
                                  {numeral(item2?.pay_permonth).format("0,0")} บาท
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      );
                  })}

                  {/*---- หนี้สินระยะยาวอื่น ๆ ----*/}
                  {balanceData?.debt_long_list?.map((item, index) => {
                    if (item?.options_debt_long === "debt_long_other")
                      return (
                        <div key={index} className="">
                          <div className="text-xs text-mainblue font-medium">
                            หนี้สินระยะยาวอื่น ๆ
                          </div>
                          {item?.debt_long_others?.map((item2, index) => {
                            return (
                              <div
                                key={index}
                                className="flex justify-between items-center text-xs font-normal"
                              >
                                <div className=" line-clamp-1 pr-5">
                                  {item2?.title}
                                </div>
                                <div className="text-mainblue">
                                  {numeral(item2?.pay_permonth).format("0,0")} บาท
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      );
                  })}
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="w-full flex flex-col items-center text-xs text-gray100 font-normal pb-2.5 border-b border-gray60">
            <div>ขณะนี้ไม่มีรายการ</div>
            <div>หากท่านมีเงินผ่อนชำระงวดหนี้สินคงค้างรายเดือน</div>
            <div>กรุณากรอกข้อมูลที่สัดส่วนหนี้สิน</div>
          </div>
        )}
        {showTotal && (
          <div className=" flex justify-center gap-2 text-sm items-center mt-2.5">
            <span>รวม</span>
            <div className="font-semibold text-center text-maincyan">
              {numeral(sumExpensesDebtPermonth()).format("0,0")}
            </div>
            <div>บาท/เดือน</div>
          </div>
        )}
        {showTotal && (
          <div className=" flex justify-center gap-2 text-sm items-center -mt-1">
            <span>ปีละ</span>
            <div className="font-semibold text-center text-maincyan">
              {numeral(sumExpensesDebtPerYear()).format("0,0")}
            </div>
            <div>บาท</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ListDebtMain;
