import React, { useState, useEffect } from "react";
import { useWindowSize } from "./useWindowSize";

export function useIsMobile() {
  const [isMobile, setIsMobile] = useState(true);
  const thisSize = useWindowSize();
  useEffect(() => {
    if (thisSize.width < 767) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [thisSize]);
  return isMobile;
}
