import { useNavigate } from "react-router-dom";
import { HeaderTitle } from "../../../components/header/Header";
import Container from "../../../components/layout/Container";
import { useEffect } from "react";
import { first_reload } from "../../tax_plan/functions/firstLoad";
import { HeaderReport } from "../components/header.main";
import { useRetireStore } from "../../../_store/retireStore";
import { ImageMain } from "../../../components/img/Img.Main";
import { MTButton } from "../../../components/button/MTButton";
import { STYLE_REPORT_CONTAIN } from "../../../utils/useStyle";

const ReportHypothesisPage = () => {
  const retireData = useRetireStore((state) => state.retireData);
  const navigate = useNavigate();

  const hypothesis = [
    {
      title: "ผลตอบแทนคาดหวังไว้ก่อนเกษียณ",
      percent: "5 %",
    },
    {
      title: "ผลตอบแทนคาดหวังไว้หลังเกษียณ",
      percent: "2 %",
    },
    {
      title:
        "รายจ่ายหลังเกษียณไม่รวมค่าใช้จ่ายไม่พึงประสงค์ เช่น ค่ารักษาพยาบาล ภาระการค้ำ ประกัน เป็นต้น",
    },
    {
      title:
        "รายจ่ายหลังเกษียณจะลดลงเหลือร้อยละ 70 ของเงินเดือน งวดเดือนสุดท้าย",
    },
  ];

  const sensitive = [
    {
      title: "เศรษฐกิจถดถอยผลตอบแทนการลงทุนหลังเกษียณ ลดลงเหลือ ",
      percent: "1 %",
    },
    {
      title:
        "เจ็บป่วยเรื่องร้ายแรงมีค่ารักษาพยาบาล 1 ล้านบาทในอีก 5 ปีข้างหน้าหรืออายุ ",
      percent: retireData?.age_current ? retireData?.age_current + 5 : "-",
      isHasyear: true,
    },
    {
      title:
        "ค้ำประกันให้ผู้อื่นเกิดหนี้ 5 ล้านบาท ในอีก 10 ปีข้างหน้า หรือ อายุ ",
      percent: retireData?.age_current ? retireData?.age_current + 10 : "-",
      isHasyear: true,
    },
  ];

  useEffect(() => {
    let ignore = false;
    first_reload(ignore);
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <Container>
      <div id="first_reload" className="pt-[14px]">
        <HeaderTitle
          title="รายงาน"
          isGoback
          onClickGoBack={() => {
            navigate("/report");
          }}
        />
        <div className={`${STYLE_REPORT_CONTAIN} flex flex-col gap-5`}>
          <HeaderReport
            icon={"/images/diagram-report.png"}
            title={"สมมติฐานรายงาน"}
          />
          <div className=" grid grid-cols-3 justify-center items-center">
            <div className=" mx-auto flex flex-col justify-center items-center gap-1">
              <img
                alt="present_age"
                src="/images/present_age2.png"
                className=" mx-auto w-[50px] h-[50px]"
              />
              <div className="mt-2 text-sm font-semibold text-maincyan">
                อายุปัจจุบัน
              </div>
              <div className="text-mainblue font-bold">
                {retireData?.age_current || 25} ปี
              </div>
            </div>
            <div className=" mx-auto flex flex-col justify-center items-center gap-1">
              <img
                alt=""
                src="/images/old_age2.png"
                className=" mx-auto w-[50px] h-[50px]"
              />
              <div className="mt-2 text-sm font-semibold text-[#B95940]">
                อายุเกษียณ
              </div>
              <div className="text-mainblue font-bold">
                {retireData?.age_retire || 60} ปี
              </div>
            </div>
            <div className=" mx-auto flex flex-col justify-center items-center gap-1">
              <img
                alt=""
                src="/images/dead2.png"
                className=" mx-auto w-[50px] h-[50px]"
              />
              <div className="mt-2 text-sm font-semibold text-mainorange">
                สิ้นอายุขัย
              </div>
              <div className="text-mainblue font-bold text-center">
                {retireData?.age_dead || 80} ปี
              </div>
            </div>
          </div>

          {hypothesis?.map((item, index) => (
            <div
              key={index}
              className=" bg-gray50 w-full rounded-[20px] flex gap-2.5 item p-3"
            >
              <div className=" bg-white rounded-[10px] px-2.5 py-3 w-[65px] h-[70px] flex justify-center items-center">
                <ImageMain
                  src="/images/lagacy_icon.png"
                  className="w-[43px] h-[43px]"
                />
              </div>
              <div className=" col-span-4">
                <div className=" font-medium">{item?.title}</div>
                <div className=" text-mainblue font-semibold text-[28px] ">
                  {item?.percent}
                </div>
              </div>
            </div>
          ))}

          <div className=" text-maincyan text-center font-medium">
            ทดสอบความอ่อนไหว
          </div>

          {sensitive?.map((item, index) => (
            <div
              key={index}
              className=" bg-gray50 w-full rounded-[20px] flex gap-2.5 item p-3"
            >
              <div className=" bg-white rounded-[10px] px-2.5 py-3 w-[65px] h-[70px] flex justify-center items-center">
                <ImageMain
                  src="/images/lagacy_icon.png"
                  className="w-[43px] h-[43px]"
                />
              </div>
              <div className=" col-span-4">
                <div className=" font-medium">{item?.title}</div>
                <div className=" text-mainblue font-semibold text-[28px] ">
                  {item?.percent} {item?.isHasyear ? "ปี" : "%"}
                </div>
              </div>
            </div>
          ))}
          <div className=" flex justify-center">
            <MTButton
              onClick={() => {
                navigate("/report");
              }}
              text="ย้อนกลับ"
              color="bg-white"
              border="border border-maincyan"
              colortext="text-maincyan group-hover:text-white"
              hovercolor="hover:bg-maincyan group "
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ReportHypothesisPage;
