import { createContext, useState } from "react";

const PdfContext = createContext();

const PdfProvider = ({ children }) => {
  const [pdfDate, setPdfDate] = useState(null);
  const [page, setPage] = useState(0);

  return (
    <PdfContext.Provider
      value={{
        pdfDate,
        setPdfDate,
        page,
        setPage
      }}
    >
      {children}
    </PdfContext.Provider>
  );
};

export { PdfContext, PdfProvider };
