import { Radio } from "@material-tailwind/react";
import numeral from "numeral";

export const RadioMain = ({
  label = "label",
  name = "name",
  value = "value",
  onChange = () => {},
  onClick = () => {},
  checked,
  price=0,
  disabledDesc = false
}) => {
  return (
    <div>
     <Radio
      color="cyan"
      className="bg-white"
      label={label}
      name={name}
      value={value}
      onChange={onChange}
      onClick={onClick}
      checked={checked}
      />
      {!disabledDesc && (
        <div className="text-red-500 pl-[10px]">
          {value !== "value" && `(${numeral(price).format("0,0")}) บาทต่อปี`}
        </div>
      )}
    </div>
   
  );
};
