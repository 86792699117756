import { Bar, Doughnut } from "react-chartjs-2";
import {
  STYLE_TITLE_DONUTCHART,
  STYLE_WIDTH_DONUT_BALANCE,
} from "../../../utils/useStyle";
import numeral from "numeral";

export const DonutChartDebtMain = ({
  data1,
  data2,
  titleData1,
  value_data1,
  titleData2,
  value_data2,
}) => {
  const data = {
    labels: false,
    datasets: [
      {
        data: [data1, data2],
        backgroundColor: ["#AA1616", "#E4AAAA"],
        hoverOffset: 4,
        cutout: "0%",
        borderRadius: 0,
        borderWidth: 0,
      },
    ],
  };

  const config = {
    type: "pie",
    data,
  };

  return (
    <div>
      <div className={` w-[200px] mx-auto `}>
        <Doughnut config={config} data={data} />
      </div>
      <div className=" flex items-center justify-center gap-2 mt-5">
        <div className=" flex items-center gap-2.5 ">
          <div className=" bg-[#AA1616] h-2 w-5"></div>
          <div className={STYLE_TITLE_DONUTCHART}>
            {titleData1} :{" "}
            <span className="text-gray400">
              {numeral(value_data1)?.format("0,0")} บาท
            </span>
          </div>
        </div>
        <div className=" flex items-center gap-2.5 ">
          <div className=" bg-[#E4AAAA] h-2 w-5"></div>
          <div className={STYLE_TITLE_DONUTCHART}>
            {titleData2} :{" "}
            <span className="text-gray400">
              {numeral(value_data2)?.format("0,0")} บาท
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export const BarChartGroupDebt = ({
  data1,
  data2,
  data3,
  width = 200,
  height = 150,
}) => {
  const data = {
    labels: ["หนี้เดิม", "หลังปรับโครงสร้างหนี้", "ประหยัดเงินได้"],
    datasets: [
      {
        data: [data1, data2, data3],
        backgroundColor: ["#FF0000", "#00B61D", "#47A3F7"],
        stack: "Stack 0",
      },
      // {
      //   data: [data2],
      //   backgroundColor: ["#00B61D"],
      //   stack: "Stack 1",
      // },
      // {
      //   data: [data3],
      //   backgroundColor: ["#47A3F7"],
      //   stack: "Stack 2",
      // },
    ],
  };

  const config = {
    type: "bar",
    data: data,
    options: {
      plugins: {
        legend: {
          display: false,
        },
      },
      responsive: true,
      interaction: {
        intersect: false,
      },
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true,
        },
      },
    },
  };

  return (
    <div className=" relative bg-white">
      <div className=" bg-white w-full h-10 z-10 absolute top-0 "></div>
      <div className=" ">
        <Bar data={data} options={config} width={width} height={height}/>
      </div>
      <div className="  flex  justify-center mt-2 gap-2.5">
        <div className=" flex items-center gap-1 ">
          <div className=" bg-[#FF0000] h-2 w-5"></div>
          <div className={STYLE_TITLE_DONUTCHART}>{` หนี้เดิม `}</div>
        </div>
        <div className=" flex items-center gap-1 ">
          <div className=" bg-[#00B61D] h-2 w-5"></div>
          <div
            className={STYLE_TITLE_DONUTCHART}
          >{` หลังปรับโครงสร้างหนี้`}</div>
        </div>
        <div className=" flex items-center gap-1 ">
          <div className=" bg-[#47A3F7] h-2 w-5"></div>
          <div className={STYLE_TITLE_DONUTCHART}>{` ประหยัดเงินได้`}</div>
        </div>
      </div>
    </div>
  );
};

export const BarChartGroupDebtRandomColor = ({
  dataDebt,
  labels,
  // head
}) => {
  const randomColorInRange = (startColor, endColor) => {
    // Convert start and end colors to numbers
    const startColorNumber = parseInt(startColor.substring(1), 16);
    const endColorNumber = parseInt(endColor.substring(1), 16);

    // Calculate range from start color to end color
    const randomRange = endColorNumber - startColorNumber;

    // Generate random offset within the range
    const randomOffset = Math.floor(Math.random() * randomRange);

    // Add offset to start color number
    const colorNumber = startColorNumber + randomOffset;

    // Convert back to hex color string
    const color = colorNumber.toString(16);

    // Pad with zeros if necessary
    return `#${color.padStart(6, "0")}`;
  };
  const startingColor = "#D21329";
  const endingColor = "#ECBC3A";
  const randomColor = randomColorInRange(startingColor, endingColor);

  let i = 0;

  const generateNumber = (arrayLength) => {
    return Math.floor(Math.random() * arrayLength);
  };

  const colors = [
    "#ECBC3A",
    "#DE9254",
    "#FD7B69",
    "#EA6F2C",
    "#880C1A",
    "#D21329",
    randomColor,
  ]; // Define your color array

  // const dataDebt = [-100, -200, -300, -400, -500, 100, 200];
  const datasets = [];

  const arrayLength = dataDebt.length;

  for (let j = 0; j < dataDebt.length; j++) {
    // Assuming you want 3 datasets
    const randomNumber = generateNumber(arrayLength);
    datasets.push({
      label: "",
      data: [dataDebt[j]],
      backgroundColor: [colors[j]],
      stack: "Stack 0",
    });
  }

  const data = {
    labels: labels,
    datasets: [
      {
        label: "",
        data: [dataDebt[i]],
        backgroundColor: randomColor,
        stack: "Stack 0",
      },
      //Add the dynamically generated datasets here
      ...datasets,
    ],
  };

  const config = {
    type: "bar",
    data: data,
  };

  const options = {
    responsive: true,
    interaction: {
      intersect: false,
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
    plugins: {
      legend: {
        labels: {
          boxWidth: 0,
        },
      },
    },
  };

  // console.log("datasets : ", datasets);

  return (
    <div className=" relative bg-white">
      {/* <div className=" bg-white w-full h-11 z-10 absolute top-0 "></div> */}
      <div className="  ">
        <Bar data={data} config={config} options={options} />
      </div>
      {/* <div className="  flex  justify-center mt-2 gap-2.5">
        <div className=" flex items-center gap-1 ">
          <div className=" bg-[#FF0000] h-2 w-5"></div>
          <div className={STYLE_TITLE_DONUTCHART}>{` หนี้เดิม `}</div>
        </div>
        <div className=" flex items-center gap-1 ">
          <div className=" bg-[#00B61D] h-2 w-5"></div>
          <div className={STYLE_TITLE_DONUTCHART}>{` หนี้ใหม่`}</div>
        </div>
        <div className=" flex items-center gap-1 ">
          <div className=" bg-[#47A3F7] h-2 w-5"></div>
          <div className={STYLE_TITLE_DONUTCHART}>{` ประหยัดเงินได้`}</div>
        </div>
      </div> */}
    </div>
  );
};
