import { useMemo } from "react";
import { useBalanceStore } from "../../../_store/balanceStore";
import {
  calDebtCreditCard,
  cn,
  mapDebtConditionColor,
} from "../../../utils/useFunctions";
import {
  PdfBalanceHeaderItemCard,
  PdfBalanceItemCard,
  PdfCard,
  PdfCardGray,
  PdfCardWhite,
} from "../component/pdf.c.card";
import PdfHeader from "../component/pdf.c.header";
import {
  PdfHeaderCardText,
  PdfHeaderText,
} from "../component/pdf.c.headerText";
import PdfLine from "../component/pdf.c.line";
import {
  STYLE_PAGE,
  STYLE_PDF_ITEM_TOTAL,
  STYLE_PDF_TEXT_MEDIUM_11,
  STYLE_PDF_TEXT_MEDIUM_12,
  STYLE_PDF_TEXT_MEDIUM_BLUE_11,
  STYLE_PDF_TEXT_MEDIUM_BLUE_12,
  STYLE_PDF_TEXT_MEDIUM_BLUE_13,
  STYLE_PDF_TEXT_NORMAL_12,
  STYLE_PDF_TEXT_NORMAL_BLUE_11,
  STYLE_PDF_TEXT_NORMAL_GRAY_11,
} from "../styles/pdf.style";
import {
  OPTIONS_TYPE_CAR,
  OPTIONS_TYPE_PLACE,
  options_property_asset,
  options_property_intangible,
  options_property_invest,
  options_property_private,
} from "../../balance/functions";
import _ from "lodash";
import numeral from "numeral";
import {
  calPropertyLocalOption,
  getAllBalanceTotal,
  getBalanceItems,
} from "../lib/pdf.lib";
import PdfFooter from "../component/pdf.c.footer";
import {
  formatPeriod,
  formatPeriodTime,
  mapDebtStatus,
} from "../../debt/component/VIDebtTable";
import { options_debt } from "../../balance/styles/Card";

function PdfPageDebtOne({ page }) {
  const balanceData = useBalanceStore((state) => state.balanceData);

  const debtShortItems = useMemo(() => {
    const newDebtShortItems = [];

    if (balanceData?.debt_short_list?.length > 0) {
      for (const debt_short_item of balanceData?.debt_short_list) {
        const keys = Object.keys(debt_short_item);
        for (const key of keys) {
          if (_.isArray(debt_short_item[key])) {
            for (const item of debt_short_item[key]) {
              let createItem = {
                title: "",
                unpaid_amount: item?.unpaid_amount,
                pay_permonth: item?.pay_permonth,
                interest: item?.interest,
                period: item?.period,
                period_time: "",
                type_payment: "",
                type_payment_ori: item?.type_payment,
                label: item?.creditor_name,
                status: options_debt.find(
                  (option) => option?.value === item?.status
                ),
                unit_interest: "ต่อปี",
                sort: 0,
              };

              // เครดิตการ์ด
              if (debt_short_item?.options_debt_short === "debt_creditcard") {
                const creditor = calDebtCreditCard(item);
                const period = creditor.creditor_items.length;
                const typePayment = !item?.type_payment
                  ? ""
                  : item?.type_payment === "pay_minimum"
                  ? "จ่ายขั้นต่ำ(รายเดือน)"
                  : item?.type_payment === "pay_full"
                  ? "จ่ายเต็มจำนวน"
                  : "จ่ายแบบระบุจำนวน(รายเดือน)";
                createItem.period = !item?.type_payment ? "" : period;
                createItem.period_time = !item?.type_payment
                  ? ""
                  : formatPeriodTime(period);
                createItem.type_payment = typePayment;
                createItem.title = "บัตรเครดิต";
                createItem.sort = 1;
              }

              if (debt_short_item?.options_debt_short === "debt_shortterm") {
                createItem.sort = 2;
                createItem.type_payment = "รายเดือน";
              } else if (
                debt_short_item?.options_debt_short ===
                "debt_informal_shortterm"
              ) {
                createItem.sort = 3;
                createItem.type_payment = "รายวัน";
                createItem.period = `${item?.number_of_days} วัน`;
              } else if (
                debt_short_item?.options_debt_short === "debt_shortterm_other"
              ) {
                createItem.sort = 4;
                createItem.type_payment = "รายเดือน";
                createItem.unit_interest = "ต่อเดือน";
              }

              // เงินกู้ระยะสั้น, หนี้นอกระบบ, เงินกู้ระยะสั้นอื่นๆ
              if (
                [
                  "debt_shortterm",
                  "debt_informal_shortterm",
                  "debt_shortterm_other",
                ].includes(debt_short_item?.options_debt_short)
              ) {
                createItem.period_time = formatPeriodTime(item?.period);
              }

              if (
                debt_short_item?.options_debt_short ===
                "debt_informal_shortterm"
              ) {
                createItem.unpaid_amount = item?.start_amount;
              }
              newDebtShortItems.push(createItem);
            }
          }
        }
      }
    }

    return newDebtShortItems.sort((a, b) => a.sort - b.sort);
  }, [balanceData]);

  const debtLongItems = useMemo(() => {
    const newDebtLongItems = [];

    if (balanceData?.debt_long_list?.length > 0) {
      for (const debt_long_item of balanceData.debt_long_list) {
        const keys = Object.keys(debt_long_item);
        for (const key of keys) {
          if (_.isArray(debt_long_item[key])) {
            for (const item of debt_long_item[key]) {
              let createItem = {
                unpaid_amount: item?.unpaid_amount,
                pay_permonth: item?.pay_permonth,
                interest: item?.interest,
                period: item?.period,
                period_time: formatPeriodTime(item?.period),
                type_payment: "",
                label: item.title,
              };

              // รถยนต์
              if (debt_long_item?.options_debt_long === "debt_car") {
                const findTypeCar = OPTIONS_TYPE_CAR?.filter((thisType) => {
                  if (thisType.value === item?.type_car) return thisType;
                });
                if (findTypeCar) {
                  createItem.label = `${findTypeCar[0]?.label} ${item?.title_car}`;
                }
              }

              // สินเชื่อที่อยู่อาศัย
              if (debt_long_item?.options_debt_long === "debt_place") {
                const findTypePlace = OPTIONS_TYPE_PLACE?.filter((thisType) => {
                  if (thisType.value === item?.type_place) return thisType;
                });
                const typePlace = item?.type_place_other
                  ? item?.type_place_other
                  : findTypePlace?.[0]?.label;
                if (typePlace) {
                  createItem.label = `${typePlace} (${item?.address_place})`;
                }
              }

              newDebtLongItems.push(createItem);
            }
          }
        }
      }
    }
    return newDebtLongItems;
  }, [balanceData]);

  const mergeDebtItems = useMemo(() => {
    const newMergeDebtItems = [
      {
        label: "หนี้ระยะสั้น",
        items: debtShortItems,
      },
      // {
      //   label: "หนี้ระยะยาว",
      //   items: debtLongItems,
      // },
    ];
    return newMergeDebtItems;
  }, [balanceData]);

  const totalItem = useMemo(() => {
    let total = 0;
    for (const item of [...debtShortItems, ...debtLongItems]) {
      total += item.unpaid_amount;
    }
    return total;
  }, [balanceData]);

  const totalShortItem = useMemo(() => {
    let total = 0;
    for (const item of debtShortItems) {
      total += item.unpaid_amount;
    }
    return total;
  }, [balanceData]);

  return (
    <div className={cn(STYLE_PAGE)}>
      <PdfHeader />
      <PdfLine />
      <PdfHeaderText title="แผนจัดการหนี้สิน" icon="/images/wealth_1.svg" />

      {/* แผนจัดการหนี้สิน FIRST */}
      <PdfCard>
        <div className={"flex flex-col py-2"}>
          <div
            className={cn(
              "grid grid-cols-8 text-center rounded-t-md bg-[#F4F2F3]",
              STYLE_PDF_TEXT_MEDIUM_BLUE_11,
              "text-[8px]"
            )}
          >
            <div>ประเภท</div>
            <div>จำนวนค้างชำระ (บาท)</div>
            <div>ชำระต่องวด</div>
            <div className={cn("flex flex-col")}>
              <div>อัตราดอกเบี้ย (%)</div>
            </div>
            <div className={cn("flex flex-col")}>
              <div>ระยะเวลาหนี้สิน (งวด)</div>
            </div>
            <div className={cn("flex flex-col")}>
              <div>ระยะเวลาหนี้สิน (ปี)</div>
            </div>
            <div>รูปแบบการชำระ</div>
            <div>สถานภาพของหนี้</div>
          </div>

          {/* DATA */}
          <div className="bg-white rounded-b-md py-2">
            {mergeDebtItems.map((debtItem, index) => {
              return (
                <div key={index} className="flex flex-col mb-1">
                  <div
                    className={cn(
                      STYLE_PDF_TEXT_NORMAL_GRAY_11,
                      "text-left ml-2 text-[10px]"
                    )}
                  >
                    {debtItem.label}
                  </div>

                  {debtItem.items.map((subItem, subIndex) => {
                    return (
                      <div
                        key={subIndex}
                        className={cn(
                          "grid grid-cols-8 text-center mt-1",
                          STYLE_PDF_TEXT_MEDIUM_BLUE_11,
                          "text-[8px]"
                        )}
                      >
                        <div
                          className={cn(
                            STYLE_PDF_TEXT_NORMAL_BLUE_11,
                            "text-center text-[8px] flex flex-col"
                          )}
                        >
                          <div>{subItem.label}</div>
                        </div>

                        <div
                          className={cn(
                            STYLE_PDF_TEXT_NORMAL_BLUE_11,
                            "text-center text-[8px]"
                          )}
                        >
                          {numeral(subItem.unpaid_amount).format("number")}
                        </div>

                        {subItem?.type_payment_ori === "pay_minimum" ? (
                          <div
                            className={cn(
                              STYLE_PDF_TEXT_NORMAL_BLUE_11,
                              "text-center text-[8px] flex flex-col items-center"
                            )}
                          >
                            <div className="flex gap-1 justify-center">
                              {numeral(subItem.pay_permonth).format("number")}{" "}
                              (8%)
                            </div>
                            <div className="text-[6px]">
                              ของยอดค้างชำระต่อเดือน
                            </div>
                          </div>
                        ) : (
                          <div
                            className={cn(
                              STYLE_PDF_TEXT_NORMAL_BLUE_11,
                              "text-center text-[8px]"
                            )}
                          >
                            {numeral(subItem.pay_permonth).format("number")}
                          </div>
                        )}

                        <div
                          className={cn(
                            STYLE_PDF_TEXT_NORMAL_BLUE_11,
                            "text-center text-[8px]"
                          )}
                        >
                          {numeral(subItem.interest).format("number")}%{" "}
                          {subItem?.unit_interest}
                        </div>
                        <div
                          className={cn(
                            STYLE_PDF_TEXT_NORMAL_BLUE_11,
                            "text-center text-[8px]"
                          )}
                        >
                          {String(subItem?.period).endsWith("วัน")
                            ? subItem?.period
                            : subItem?.period
                            ? formatPeriod(subItem?.period)
                            : ""}
                        </div>
                        <div
                          className={cn(
                            STYLE_PDF_TEXT_NORMAL_BLUE_11,
                            "text-center text-[8px]"
                          )}
                        >
                          {subItem?.period_time}
                        </div>
                        <div
                          className={cn(
                            STYLE_PDF_TEXT_NORMAL_BLUE_11,
                            "text-center text-[8px]"
                          )}
                        >
                          {subItem?.type_payment}
                        </div>
                        <div
                          className={cn(
                            STYLE_PDF_TEXT_NORMAL_BLUE_11,
                            "text-center text-[8px]"
                          )}
                          style={{
                            color: mapDebtConditionColor(
                              subItem?.status?.value
                            ),
                          }}
                        >
                          {subItem?.status?.label}
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}

            <div className="grid grid-cols-7">
              <div
                className={cn(
                  STYLE_PDF_TEXT_NORMAL_GRAY_11,
                  "  text-left ml-2 text-[10px]"
                )}
              >
                รวม
              </div>

              <div className={cn(STYLE_PDF_TEXT_NORMAL_BLUE_11, "text-[8px]")}>
                {numeral(balanceData?.debt_propotion).format("0,0")}
              </div>
            </div>
          </div>
        </div>
      </PdfCard>
      <PdfFooter page={page} />
    </div>
  );
}

export default PdfPageDebtOne;
