import numeral from "numeral";
import {
  STYLE_BODY_EDU,
  STYLE_BORDER_BODY_TAX,
  STYLE_TABLE_EDU,
} from "../../../utils/useStyle";
import { useMemo } from "react";
import _ from "lodash";
import { key_education_rank, key_education_intial } from "../functions/options";
import { cn } from "../../../utils/useFunctions";

const ViewTableExpensesPdf = ({
  primary_value,
  secondery_value = 457783,
  college_value = 685044,
  localEducationData,
  index = 0,
}) => {
  const labelHeaders = useMemo(() => {
    const group_label = _.groupBy(
      localEducationData?.education_items,
      "education.key"
    );
    const group_label_keys = Object.keys(group_label);
    let label_items = [];
    let latest_item = {
      label: "ออมเงิน / ลงทุน เพื่อเป้าหมาย",
      total: 0,
    };
    for (const group_label_key of group_label_keys) {
      const items = group_label[group_label_key];
      let create_label_item = {
        label: key_education_rank?.[group_label_key],
        total: 0,
        is_invset: false,
      };
      for (const item of items) {
        create_label_item.total += item.sum_under_education;
        create_label_item.is_invset = item.is_invset;
      }
      label_items.push(create_label_item);
    }
    label_items.push(latest_item);
    return label_items;
  }, [localEducationData]);

  const rows = useMemo(() => {
    return localEducationData?.education_items;
  }, [localEducationData]);

  const footer = useMemo(() => {
    let create_footer = {
      sum_total_education: localEducationData?.sum_total_education,
      sum_total_education_accumulate:
        localEducationData?.sum_total_education_accumulate,
      sum_total_invest: localEducationData?.sum_total_invest,
      invest_keys: [],
    };
    const first_education_item = localEducationData?.education_items[0];
    create_footer.invest_keys = first_education_item?.invest_keys;
    return create_footer;
  }, [localEducationData]);

  const columnWidth = 50; // Assuming each column has a width of 128 pixels
  const width = (columns) => {
    let numColumns = 0; // Initialize numColumns

    // Find the maximum number of columns among all rows
    columns.forEach((row) => {
      const currentNumColumns = row?.invest_keys?.length || 0;
      if (currentNumColumns > numColumns) {
        numColumns = currentNumColumns;
      }
    });
    // Calculate total width based on the maximum number of columns
    const sum = (numColumns + 1) * columnWidth;
    return `${sum}px`;
  };

  // const numColumns = rows?.row?.invest_keys.length;
  // console.log("AAA", numColumns);

  // const width = (columns) => {
  //   let width = numColumns
  //   const sum = width * columnWidth;

  //   return `${sum}px`;
  // };

  // console.log("2", rows?.invest_keys);

  return (
    <div className="flex justify-center flex-col items-center">
      <div className="text-[11px] mb-2">
        ตารางสรุปค่าใช้จ่ายแต่ละปี และสรุปยอดการอม/ลงทุน เพื่อการศึกษาบุตร
        บุตรคนที่ {index + 1} : {localEducationData?.name}
      </div>
      <div className=" rounded-t-[20px]">
        {/* Header */}

        <div className="bg-[#F9F9F9] w-full">
          <div className="bg-[#F9F9F9]   flex ">
            <div
              className={`bg-[#F9F9F9] h-6 w-[200px] text-[8px]  text-mainblue flex-none  border-b border-mainblue font-bold text-center border-r border-r-mainblue flex justify-center items-center px-0`}
            >
              ค่าใช้จ่ายเพื่อการศึกษาและค่าใช้จ่ายในชีวิตประจำวัน
            </div>
            <div
              style={{
                width: width(rows),
              }}
              className={`bg-[#F9F9F9] h-6 w-[150px] text-[8px]  text-mainblue flex-none border-b border-mainblue font-bold text-center border-r border-r-gray60 flex justify-center items-center px-0`}
            >
              ออมเงิน / ลงทุน
            </div>
          </div>
          <div className="flex">
            <div
              className={cn(STYLE_TABLE_EDU, "w-[40px] text-[7px] h-6 px-0")}
            >
              ระดับชั้น
            </div>
            <div
              className={cn(STYLE_TABLE_EDU, "w-[80px] text-[7px] h-6 px-0")}
            >
              ค่าใช้จ่าย(ต่อปี)
            </div>
            <div
              className={cn(
                `${STYLE_TABLE_EDU}`,
                "border-r border-r-mainblue w-[80px] text-[7px] h-6 px-0"
              )}
            >
              ค่าใช้จ่ายสะสม(ต่อปี)
            </div>
            {labelHeaders.map((header, index) => {
              if (index === labelHeaders.length - 1) {
                return (
                  <div
                    key={index}
                    className={cn(
                      STYLE_TABLE_EDU,
                      "w-[50px] text-[7px] h-6 px-0"
                    )}
                    // className={` bg-[#F9F9F9]  text-mainblue font-semibold text-nowrap w-[128px] text-sm text-center border-r border-r-gray60 flex justify-center items-center px-3 border-b  border-mainblue h-full `}
                  >
                    ออมเงิน / ลงทุน เพื่อเป้าหมาย
                  </div>
                );
              }

              return (
                <div
                  key={index}
                  className={cn(
                    `${STYLE_TABLE_EDU}`,
                    "px-0 flex-col w-[50px] text-[7px] h-6"
                  )}
                >
                  {header?.label}
                  {header?.is_invset ? (
                    <div className=" text-maincyan text-[6px]">
                      ({numeral(header.total)?.format("0,0")})
                    </div>
                  ) : (
                    <div className=" text-mainblue text-[6px]"></div>
                  )}
                  {/* {header?.is_invset ? (
                  <div className=" text-maincyan text-[7px]">
                    ({numeral(header.total)?.format("0,0")})
                  </div>
                ) : (
                  <div className=" text-mainblue text-[8px]">
                    (ไม่ได้วางแผนออม)
                  </div>
                )} */}
                </div>
              );
            })}
          </div>
        </div>

        {/* Body */}
        {rows
          .concat([
            {
              education: { key: "total" },
              sum_under_education: footer.sum_total_education,
              sum_under_education_accumulate: "",
              sum_total_invest: footer.sum_total_invest,
              invest_keys: footer.invest_keys,
              invest_dynamic_education_item_5: {
                invest_dynamic_education_item_5: footer.sum_total_invest,
              },
            },
          ])
          .map((row, index) => {
            return (
              <div key={index} className={STYLE_BORDER_BODY_TAX}>
                <div
                  className={cn(
                    `${STYLE_BODY_EDU}`,
                    "w-[40px] text-[7px] h-3 text-mainblue"
                  )}
                >
                  {row?.education?.key === "total"
                    ? "รวม"
                    : key_education_intial[row.education?.key]}
                </div>
                <div
                  className={cn(
                    `${STYLE_BODY_EDU}`,
                    "w-[80px] text-[7px] h-3 text-maincyan"
                  )}
                >
                  {numeral(row?.sum_under_education)?.format?.("0,0")}
                </div>
                <div
                  className={cn(
                    `${STYLE_BODY_EDU}`,
                    "w-[80px] text-[7px] h-3 text-maincyan border-r border-r-mainblue"
                  )}
                >
                  {" "}
                  {row?.sum_under_education_accumulate
                    ? numeral(row?.sum_under_education_accumulate)?.format?.(
                        "0,0"
                      )
                    : ""}
                </div>

                {row?.invest_keys?.map((key, index) => {
                  const value = row.invest_dynamic_education_item_5[`${key}_5`];

                  return (
                    <div
                      key={index}
                      className={cn(
                        `${STYLE_BODY_EDU}`,
                        "text-maincyan px-0 w-[50px] text-[7px] h-3"
                      )}
                    >
                      {value ? numeral(value)?.format?.("0,0") : ""}
                    </div>
                  );
                })}

                <div
                  className={cn(
                    `bg-white  w-[50px] flex-none text-[7px]  h-3 text-center border-r border-r-gray60 flex justify-center items-center px-0 border-b border-b-gray60 text-maincyan`
                  )}
                >
                  {row?.education?.key === "total"
                    ? numeral(footer.sum_total_invest)?.format?.("0,0")
                    : row?.sum_invest
                    ? numeral(row?.sum_invest)?.format?.("0,0")
                    : ""}
                </div>
              </div>
            );
          })}

        {/* ---------  ยอดรวม ---------- */}
        {/* <div className={`${STYLE_BORDER_BODY_TAX} `}>
        <div
          className={`${STYLE_BODY_EDU} text-mainblue font-semibold border-t border-mainblue`}
        >
          รวม
        </div>

        <div
          className={`${STYLE_BODY_EDU} text-maincyan border-t border-mainblue `}
        >
          {numeral(footer.sum_total_education)?.format?.("0,0")}
        </div>

        <div
          className={`${STYLE_BODY_EDU} text-maincyan border-t border-mainblue border-r border-r-mainblue`}
        ></div>

        {footer.invest_keys.map((key, index) => {
          return (
            <div
              key={index}
              className={`${STYLE_BODY_EDU} text-maincyan border-t border-mainblue`}
            >
              {""}
            </div>
          );
        })}

        <div
          className={`${STYLE_BODY_EDU} text-maincyan border-t border-mainblue w-[128px]`}
        >
          {numeral(footer.sum_total_invest)?.format?.("0,0")}
        </div>
      </div> */}
      </div>

      <div className=" text-center text-[12px] mt-2">
        ถ้าหากคุณวางแผนการศึกษาบุตรโดยนำเงินไปออม หรือลงทุนให้มีผลตอบแทน
        <div className=" mt-2 text-mainblue font-medium">
          คุณจะประหยัดเงินไปทั้งสิ้น{" "}
          <span className=" text-maincyan font-semibold">
            {numeral(localEducationData.sum_total_save).format("0,0")} บาท
          </span>
        </div>
        <div className="flex justify-center">
          <div className=" mt-2 text-[10px] text-maingray text-center w-[80%]">
            ( *หมายเหตุ สมมุติฐานหากจำนวนการเก็บออม/ลงทุน น้อยกว่าหรือเท่ากับ 3
            ปี อัตราผลตอบแทนจะอยู่ที่ 2 % ถ้ามากกว่า 3 ปีจะอยู่ที่ 5 % )
          </div>
        </div>
      </div>
      {/* <div className="text-[11px] mt-2 w-[50%] text-center">
        ( *หมายเหตุ สมมุติฐานหากจำนวนการเก็บออม/ลงทุน น้อยกว่าหรือเท่ากับ 3 ปี
        อัตราผลตอบแทนจะอยู่ที่ 2 % ถ้ามากกว่า 3 ปีจะอยู่ที่ 5 % )
      </div> */}
    </div>
  );
};

export default ViewTableExpensesPdf;
