import { useNavigate } from "react-router-dom";
import { HeaderTitle } from "../../../components/header/Header";
import Container from "../../../components/layout/Container";
import { useEffect } from "react";
import { first_reload } from "../../tax_plan/functions/firstLoad";
import { HeaderReport } from "../components/header.main";
import { STYLE_REPORT_CONTAIN } from "../../../utils/useStyle";
import { useInsuranceStore } from "../../../_store/insuranceStore";
import { CardMain, CardTips } from "../../../components/card/CardMain";
import numeral from "numeral";
import { defaultValue } from "../../../functions/main";
import ViewTableFamily from "../../insuarance_plan/component/VITableFamily.Main";
import CustomHorizontalBarChart from "../../pdf/component/pdf.c.familyChart";
import { StatusCard } from "../../insuarance_plan/styles/Card";
import { MTButton } from "../../../components/button/MTButton";

const ReportFamilyInsurancePage = () => {
  const navigate = useNavigate();
  const insuranceData = useInsuranceStore((state) => state.insuranceData);

  const getStatus = () => {
    if (insuranceData.sum_insurance_more === 0) {
      return "normal";
    } else if (insuranceData.sum_insurance_more < 0) {
      return "toomuch";
    }
    return "more";
  };

  useEffect(() => {
    let ignore = false;
    first_reload(ignore);
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <Container>
      <div id="first_reload">
        <HeaderTitle
          title="รายงาน"
          isGoback
          onClickGoBack={() => {
            navigate("/report");
          }}
        />

        <div className={`${STYLE_REPORT_CONTAIN} flex flex-col gap-5`}>
          <HeaderReport
            icon={"/images/family-report.png"}
            title={"แผนทุนประกันคุ้มครองครอบครัว "}
            className={" z-20"}
          />
          <CardMain
            title={
              <div className=" text-mainblue font-semibold ">
                ผลลัพธ์วางแผนทุนประกันคุ้มครองค่าใช้จ่าย
              </div>
            }
            panel={
              <div className=" px-3  space-y-5 font-medium">
                <div>
                  <div>ทุนประกันขั้นต่ำที่ต้องการให้ครอบครัวอยู่รอด 5 ปี</div>
                  <div className=" text-maincyan font-semibold text-2xl">
                    {numeral(
                      (defaultValue(insuranceData.sum_income) -
                        defaultValue(insuranceData.sum_personal_expenses)) *
                        5
                    ).format("0,0")}{" "}
                    บาท
                  </div>
                </div>

                <div>
                  <div>
                    ทุนประกันที่ควรมีเพื่อคุ้มครองครอบครัว{" "}
                    {insuranceData?.family_year_survive} ปี
                  </div>
                  <div className=" text-maincyan font-semibold text-2xl">
                    {numeral(insuranceData?.sum_family_remain)?.format("0,0")}{" "}
                    บาท
                  </div>
                </div>

                {insuranceData?.asset_is_debt === "Y" && (
                  <div className="flex flex-col">
                    <div className="mb-4">
                      <div>หนี้สินทั้งหมดของคุณ</div>
                      <div className=" text-maincyan font-semibold text-2xl">
                        {numeral(insuranceData.sum_family_debt).format("0,0")}
                      </div>
                    </div>

                    <div>
                      <div className=" mb-2">ทุนประกันเพื่อการศึกษาบุตร</div>
                      <ViewTableFamily />
                    </div>
                  </div>
                )}

                <div>
                  <div>ทุนประกันสุทธิ</div>
                  <div className=" text-maincyan font-semibold text-2xl">
                    {numeral(insuranceData.sum_insurance_net)?.format("0,0")}{" "}
                    บาท
                  </div>
                </div>
                <div>
                  <div>ทุนประกันที่คุณมี ณ ปัจจุบัน</div>
                  <div className=" text-maincyan font-semibold text-2xl">
                    {numeral(insuranceData?.sum_insurance_current)?.format(
                      "0,0"
                    )}{" "}
                    บาท
                  </div>
                </div>
                <div>
                  <div>มูลค่าทรัพย์สินที่มีในปัจจุบัน</div>
                  <div className=" text-maincyan font-semibold text-2xl">
                    {numeral(insuranceData?.sum_asset_current)?.format("0,0")}{" "}
                    บาท
                  </div>
                </div>
                <div className="w-[300px] flex justify-center mx-auto">
                  <CustomHorizontalBarChart
                    width={120}
                    yourData={[insuranceData?.sum_insurance_current, 0]}
                    recomenedData={[0, insuranceData.sum_insurance_more]}
                    barThickness={40}
                  />
                </div>

                <StatusCard
                  status={getStatus()}
                  value={insuranceData.sum_insurance_more}
                />
              </div>
            }
          />
          <CardTips
            title="แนะนำแบบประกันชีวิต"
            panel={
              <div className=" space-y-1">
                <div>
                  1. ประกันชั่วระยะเวลา เบี้ยประกันสูง ทุนประกันสูง
                  ไม่เน้นเงินคืน ใช้คุ้มครองชีวิตวิตามระยะเวลาเป้าหมาย ที่เรากำ
                  หนด เช่น 5 ปี, 10 ปี, 20 ปี 
                </div>
                <div>
                  2. ประกันแบบตลอดชีพชำระเบี้ยเพื่อให้มีความคุ้มครอง
                  ตลอดตลอดระยะเวลาที่เรามีชีวิต
                </div>
                <div>3. ประกันแบบสะสมทรัพย์ จุดประสงค์เพื่อให้มีเงินก้อน</div>
                <div>
                  4. ตามเป้าหมายระยะเวลาที่เรากำหนด
                  โดยมีความคุ้มครองชีวิตประกันแบบ UNIT LINK
                  เป็นประกันที่ให้ความคุ้มครองชีวิตวิพร้อมกับโอกาส
                  ในการรับผลตอบแทนจากกองทุนรวม
                </div>
              </div>
            }
          />

          <div className=" flex justify-center ">
            <MTButton
              onClick={() => {
                navigate("/report");
              }}
              text="ย้อนกลับ"
              color="bg-white"
              border="border border-maincyan"
              colortext="text-maincyan group-hover:text-white"
              hovercolor="hover:bg-maincyan group "
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ReportFamilyInsurancePage;
