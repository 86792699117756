import React from "react";
import { ImageMain } from "../../../../components/img/Img.Main";
import numeral from "numeral";

export const CardConclusionTax = ({ form, TabThreeWithHoldingTax = 0, userData }) => {

  const isPremium = userData?.is_premium

  return (
    <React.Fragment>
      {/*----------- พอดี ----------*/}
      {(form.final_tax === 0 ) && (
        <>
          {form.tax_withholding === 0 ? (
            <div>
              {/*----------- ไม่เสียภาษี และ ไม่มีภาษีหัก ณ ที่จ่าย ----------*/}
              <div className="flex justify-center items-center gap-1 mb-1">
                <ImageMain src="../images/taxicon1.png" className=" w-8 h-8 " />
                <div className=" text-mainblue font-semibold text-[20px]">
                  คุณไม่ต้องเสียภาษี
                </div>
              </div>
              <div className="flex flex-col items-center text-base text-mainblue font-normal">
                แต่อย่าลืมยื่นภาษีต่อกรมสรรพากร
              </div>
            </div>
          ) : (
            <div>
              <div className="flex justify-center items-center gap-1 mb-1">
                <ImageMain src="../images/taxicon1.png" className=" w-8 h-8 " />
                <div className=" text-mainblue font-semibold text-[20px]">
                  คุณหักภาษีได้พอดี
                </div>
              </div>
              <div className="flex flex-col items-center text-base text-mainblue font-normal">
                <div>ถึงแม้คุณไม่ต้องเสียภาษีเพิ่ม</div>
                <div>แต่คุณสามารถได้รับภาษีคืนได้ด้วยการวางแผนภาษี</div>
              </div>
            </div>
          )}
        </>
      )}

      {/*----------- ขอคืน ----------*/}
      {form.final_tax < 0 && (
        <>
          <div className="bg-gray50 flex flex-col items-center py-4 mb-4">
            <div className="flex items-center gap-2">
              <div className="text-mainblue font-semibold text-sm">
                ขอคืนภาษีได้
              </div>
              <div className="text-maincyan font-semibold text-[20px]">
                {numeral(form.final_tax * -1)?.format("0,0")} บาท
              </div>
            </div>
            <div className="text-xs text-gray100">
              * ( {numeral( isPremium ? TabThreeWithHoldingTax:form.tax_withholding)?.format("0,0")} -{" "}
              {numeral(form.after_tax_paid)?.format("0,0")} ={" "}
              {numeral(form.final_tax * -1)?.format("0,0")} )
            </div>
          </div>
          <div className="flex justify-center items-center gap-1 mb-1">
            <ImageMain src="../images/taxicon2.png" className=" w-8 h-8 " />
            <div className=" text-mainblue font-semibold text-[20px]">
              ขอคืนได้อีกรู้ไหม !
            </div>
          </div>
          <div className="flex flex-col items-center text-base text-mainblue font-normal">
            <div>คุณใช้สิทธิลดหย่อนภาษีเต็มสิทธิหรือยัง</div>
            <div>เพื่อให้เสียภาษีอย่างเหมาะสม</div>
          </div>
        </>
      )}

      {/*----------- จ่ายเพิ่ม ----------*/}
      {form.final_tax > 0 && (
        <>
          <div className="bg-gray50 flex flex-col items-center py-4 mb-4">
            <div className="flex items-center gap-2">
              <div className="text-mainblue font-semibold text-sm">
                ภาษีต้องจ่ายเพิ่ม
              </div>
              <div className="text-maincyan font-semibold text-[20px]">
                {numeral(form.final_tax)?.format("0,0")} บาท
              </div>
            </div>
            <div className="text-xs text-gray100">
              * ( {numeral(form.after_tax_paid)?.format("0,0")} -{" "}
              {numeral(isPremium ? TabThreeWithHoldingTax:form.tax_withholding)?.format(0, 0)} ={" "}
              {numeral(form.final_tax)?.format("0,0")} )
            </div>
          </div>
          <div className="flex justify-center items-center gap-1 mb-1">
            <ImageMain src="../images/taxicon3.png" className=" w-8 h-8 " />
            <div className=" text-mainblue font-semibold text-[20px]">
              อย่าตกใจ !
            </div>
          </div>
          <div className="flex flex-col items-center text-base text-mainblue font-normal">
            <div>คุณใช้สิทธิลดหย่อนภาษีเต็มสิทธิหรือยัง</div>
            <div>เพื่อให้เสียภาษีอย่างเหมาะสม</div>
          </div>
        </>
      )}
    </React.Fragment>
  );
};
