import numeral from "numeral";
import {
  STYLE_BODY_TABLE_DEBT,
  STYLE_BODY_TAHBLE_DEBT,
  STYLE_TITLE_TABLE_DEBT,
  STYLE_WHITE_TABLE_RETIRE,
} from "../../../utils/useStyle";
import { OPTIONS_TYPE_CAR, OPTIONS_TYPE_PLACE } from "../../balance/functions";
import { useMemo } from "react";
import {
  calDebtCreditCard,
  cn,
  mapDebtConditionColor,
  toFloat2,
} from "../../../utils/useFunctions";
import _ from "lodash";
import { calAmountInterest } from "./VIDebtPlan.Main";
import { options_debt } from "../../balance/styles/Card";

const WhiteSpace = () => {
  return (
    <div
      // key={index}
      className={` font-semibold text-mainblue grid grid-cols-6  items-center w-full border-b
        `}
    >
      <div className="">
        {" "}
        <div
          className={`whitespace-nowrap h-10 flex justify-center items-center  bg-white`}
        ></div>
      </div>
      <div className="">
        {" "}
        <div
          className={`whitespace-nowrap h-10 flex justify-center items-center  bg-white`}
        ></div>
      </div>
      <div className="">
        {" "}
        <div
          className={`whitespace-nowrap h-10 flex justify-center items-center  bg-white`}
        ></div>
      </div>
      <div className="">
        {" "}
        <div
          className={`whitespace-nowrap h-10 flex justify-center items-center  bg-white`}
        ></div>
      </div>
      <div className="">
        <div
          className={`whitespace-nowrap h-10 flex justify-center items-center  bg-white`}
        ></div>
      </div>
      <div className="">
        <div
          className={`whitespace-nowrap h-10 flex justify-center items-center  bg-white`}
        ></div>
      </div>
    </div>
  );
};

export const formatPeriod = (period) => {
  if (String(period) === "Infinity") {
    return "ไม่มีที่สิ้นสุด";
  }
  return numeral(period).format("0,0");
};

// แปลงจำนวนงวดเป็น กี่ปีกี่เดือน
export const formatPeriodTime = (period) => {
  if (String(period) === "Infinity") {
    return "ไม่มีที่สิ้นสุด";
  }

  let years = Math.floor(period / 12);
  let remainingMonths = period % 12;
  if (years > 0) {
    return `${years} ปี ${Math.ceil(remainingMonths)} เดือน`;
  } else {
    return `${Math.ceil(remainingMonths)} เดือน`;
  }
};

export const mapDebtStatus = {
  normal: "ปกติ",
  more_90: "ชำระล่าช้าไม่เกิน 90 วัน",
  less_90: "ไม่ได้ชำระเกิน 90 วัน",
};

export const ViewTableDebt = ({ debtShortList, debtLongList }) => {
  // list Body ระยะสั้น
  const shortDebtList = useMemo(() => {
    const result = [];
    // บัตรเครดิต
    debtShortList?.map((item) => {
      if (item?.options_debt_short === "debt_creditcard") {
        item?.credit_cards?.map((item2) => {
          const creditor = calDebtCreditCard(item2);

          const typePayment = !item2?.type_payment
            ? ""
            : item2?.type_payment === "pay_minimum"
            ? "จ่ายขั้นต่ำ(รายเดือน)"
            : item2?.type_payment === "pay_full"
            ? "จ่ายเต็มจำนวน"
            : "จ่ายแบบระบุจำนวน(รายเดือน)";

          // let period = Math.ceil(item2?.unpaid_amount / item2?.pay_permonth);
          // if (item2?.type_payment === "pay_minimum") {
          //   period = creditor?.creditor_items?.length;
          // }

          let period = creditor.creditor_items.length;

          result.push({
            type_payment_ori: item2?.type_payment,
            unpaid_amount: item2?.unpaid_amount,
            unpaid_amount: item2?.unpaid_amount,
            pay_permonth: item2?.pay_permonth,
            interest: item2?.interest,
            period: !item2?.type_payment ? "" : period,
            period_time: !item2?.type_payment ? "" : formatPeriodTime(period),
            type_payment: typePayment,
            option: item?.options_debt_short,
            status: options_debt.find(
              (option) => option?.value === item2?.status
            ),
          });
        });
      }
    });
    // เงินกู้ระยะสั้น
    debtShortList?.map((item) => {
      if (item?.options_debt_short === "debt_shortterm") {
        item?.short_terms?.map((item2) => {
          result.push({
            unpaid_amount: item2?.unpaid_amount,
            unpaid_amount: item2?.unpaid_amount,
            pay_permonth: item2?.pay_permonth,
            interest: item2?.interest,
            period: item2?.period,
            period_time: formatPeriodTime(item2?.period),
            // type_payment: item2?.normal_payment ? "จ่ายปกติ" : "",
            // type_payment: mapDebtStatus?.[item2?.status],
            type_payment: "รายเดือน",
            option: item?.options_debt_short,
            status: options_debt.find(
              (option) => option?.value === item2?.status
            ),
          });
        });
      }
    });
    // หนี้นอกระบบ
    debtShortList?.map((item) => {
      if (item?.options_debt_short === "debt_informal_shortterm") {
        item?.informal_short_terms?.map((item2) => {
          result.push({
            number_of_days: item2?.number_of_days,
            start_amount: item2?.start_amount,
            unpaid_amount: item2?.unpaid_amount,
            pay_permonth: item2?.pay_permonth,
            interest: item2?.interest,
            period: item2?.period,
            period_time: formatPeriodTime(item2?.period),
            type_payment: "รายวัน",
            option: item?.options_debt_short,
            status: options_debt.find(
              (option) => option?.value === item2?.status
            ),
          });
        });
      }
    });
    // เงินกู้ระยะสั้นอื่นๆ
    debtShortList?.map((item) => {
      if (item?.options_debt_short === "debt_shortterm_other") {
        item?.short_term_others?.map((item2) => {
          result.push({
            unpaid_amount: item2?.unpaid_amount,
            unpaid_amount: item2?.unpaid_amount,
            pay_permonth: item2?.pay_permonth,
            interest: item2?.interest,
            period: item2?.period,
            period_time: formatPeriodTime(item2?.period),
            type_payment: "รายเดือน",
            interest_unit: "ต่อเดือน",
            option: item?.options_debt_short,
            status: options_debt.find(
              (option) => option?.value === item2?.status
            ),
          });
        });
      }
    });
    return result;
  }, [debtShortList]);

  // list Body ระยะยาว
  const longDebtList = useMemo(() => {
    const result = [];
    // สินเชื่อรถยนต์ / จักรยานยนต์
    debtLongList?.map((item) => {
      if (item?.options_debt_long === "debt_car") {
        item?.debt_long_cars?.map((item2) => {
          result.push({
            unpaid_amount: item2?.unpaid_amount,
            unpaid_amount: item2?.unpaid_amount,
            pay_permonth: item2?.pay_permonth,
            interest: item2?.interest,
            period: item2?.period,
            period_time: formatPeriodTime(item2?.period),
            type_payment: "รายเดือน",
            status: options_debt.find(
              (option) => option?.value === item2?.status
            ),
          });
        });
      }
    });
    // สินเชื่อที่อยู่อาศัย
    debtLongList?.map((item) => {
      if (item?.options_debt_long === "debt_place") {
        item?.debt_long_places?.map((item2) => {
          result.push({
            unpaid_amount: item2?.unpaid_amount,
            unpaid_amount: item2?.unpaid_amount,
            pay_permonth: item2?.pay_permonth,
            interest: item2?.interest,
            period: item2?.period,
            period_time: formatPeriodTime(item2?.period),
            type_payment: "รายเดือน",
            status: options_debt.find(
              (option) => option?.value === item2?.status
            ),
          });
        });
      }
    });
    // หนี้สินระยะยาวอื่น ๆ
    debtLongList?.map((item) => {
      if (item?.options_debt_long === "debt_long_other") {
        item?.debt_long_others?.map((item2) => {
          result.push({
            unpaid_amount: item2?.unpaid_amount,
            unpaid_amount: item2?.unpaid_amount,
            pay_permonth: item2?.pay_permonth,
            interest: item2?.interest,
            period: item2?.period,
            period_time: formatPeriodTime(item2?.period),
            type_payment: 'รายเดือน',
            status: options_debt.find(
              (option) => option?.value === item2?.status
            ),
          });
        });
      }
    });
    return result;
  }, [debtLongList]);

  // รวมยอดเงินต้นค้างชำระ
  const sumStartUnpaidAmount = () => {
    const sumDebtShort = () => {
      let result = 0;
      shortDebtList?.map((item) => {
        if (item.option === "debt_informal_shortterm") {
          result += item?.start_amount ? item?.start_amount : 0;
        } else {
          result += item?.unpaid_amount ? item?.unpaid_amount : 0;
        }
      });
      return result;
    };
    const sumDebtLong = () => {
      let result = 0;
      longDebtList?.map((item) => {
        result += item?.unpaid_amount ? item?.unpaid_amount : 0;
      });
      return result;
    };
    return sumDebtShort() + sumDebtLong();
  };

  // console.log("shortDebtList : ", shortDebtList);
  // console.log("longDebtList : ", longDebtList);
  // console.log("sumStartUnpaidAmount : ", sumStartUnpaidAmount());

  return (
    <div className="flex w-full bg-white">
      {/*--------------------  Header --------------------*/}
      <div className=" shadow-sm rounded-tl-[20px] rounded-bl-[20px] shadow-gray-500 w-[240px]  sticky z-10">
        <div className="  bg-gray50 h-14 px-3 font-bold text-mainblue rounded-t-[20px]  rounded-tr-none w-full flex gap-[38px] items-center ">
          <div>ประเภท</div>
        </div>
        {/*---------- หนี้สินระยะสั้น ----------*/}
        {debtShortList?.length > 0 ? (
          <div className=" px-3 ">
            <div
              className={` text-lg font-medium text-maincyan  h-full pt-3  `}
            >
              <div className=" h-10 w-full">หนี้สินระยะสั้น</div>
            </div>
            {/*---- บัตรเครดิต ----*/}
            {debtShortList?.map((item, index) => {
              if (item?.options_debt_short === "debt_creditcard")
                return (
                  <div key={index} className="">
                    {/* <div className={`text-sm font-medium text-maingray h-10`}>
                      บัตรเครดิต
                    </div> */}
                    {item?.credit_cards?.map((item2, index) => {
                      return (
                        <div key={index} className={STYLE_TITLE_TABLE_DEBT}>
                          <div className=" line-clamp-1">
                            บัตรเครดิต {item2?.creditor_name}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
            })}
            {/*---- เงินกู้ระยะสั้น ----*/}
            {debtShortList?.map((item, index) => {
              if (item?.options_debt_short === "debt_shortterm")
                return (
                  <div key={index} className="">
                    {/* <div className={`text-sm font-medium text-maingray h-10`}>
                      เงินกู้ระยะสั้น
                    </div> */}
                    {item?.short_terms?.map((item2, index) => {
                      return (
                        <div key={index} className={STYLE_TITLE_TABLE_DEBT}>
                          <div className=" line-clamp-1">
                            {item2?.creditor_name}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
            })}
            {/*---- หนี้นอกระบบ ----*/}
            {debtShortList?.map((item, index) => {
              if (item?.options_debt_short === "debt_informal_shortterm")
                return (
                  <div key={index} className="">
                    {/* <div className={`text-sm font-medium text-maingray h-10`}>
                      หนี้นอกระบบ
                    </div> */}
                    {item?.informal_short_terms?.map((item2, index) => {
                      return (
                        <div key={index} className={STYLE_TITLE_TABLE_DEBT}>
                          <div className=" line-clamp-1">
                            {item2?.creditor_name}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
            })}
            {/*---- เงินกู้ระยะสั้นอื่น ๆ ----*/}
            {debtShortList?.map((item, index) => {
              if (item?.options_debt_short === "debt_shortterm_other")
                return (
                  <div key={index} className="">
                    {/* <div className={`text-sm font-medium text-maingray h-10`}>
                      เงินกู้ระยะสั้นอื่น ๆ
                    </div> */}
                    {item?.short_term_others?.map((item2, index) => {
                      return (
                        <div key={index} className={STYLE_TITLE_TABLE_DEBT}>
                          <div className=" line-clamp-1">
                            {item2?.creditor_name}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
            })}
          </div>
        ) : (
          ""
        )}
        {/*---------- หนี้สินระยะยาว ---------*/}
        {debtLongList?.length > 0 ? (
          <div className=" px-3 ">
            <div className={` text-lg font-medium text-maincyan  h-full pt-3`}>
              <div className=" h-10 w-full">หนี้สินระยะยาว</div>
            </div>
            {/*---- สินเชื่อรถยนต์ / จักรยานยนต์ ----*/}
            {debtLongList?.map((item, index) => {
              if (item?.options_debt_long === "debt_car")
                return (
                  <div key={index} className="">
                    {/* <div className="text-xs text-mainblue font-medium">
                            สินเชื่อรถยนต์ / จักรยานยนต์
                          </div> */}
                    {item?.debt_long_cars?.map((item2, index) => {
                      const findTypeCar = OPTIONS_TYPE_CAR?.filter(
                        (thisType) => {
                          if (thisType.value === item2?.type_car)
                            return thisType;
                        }
                      );
                      const typeCar = findTypeCar?.[0]?.label;
                      return (
                        <div key={index} className={STYLE_TITLE_TABLE_DEBT}>
                          <div className=" line-clamp-1">
                            {typeCar} {item2?.title_car}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
            })}

            {/*---- สินเชื่อที่อยู่อาศัย ----*/}
            {debtLongList?.map((item, index) => {
              if (item?.options_debt_long === "debt_place")
                return (
                  <div key={index} className="">
                    {/* <div className="text-xs text-mainblue font-medium">
                      สินเชื่อที่อยู่อาศัย
                    </div> */}
                    {item?.debt_long_places?.map((item2, index) => {
                      const findTypePlace = OPTIONS_TYPE_PLACE?.filter(
                        (thisType) => {
                          if (thisType.value === item2?.type_place)
                            return thisType;
                        }
                      );
                      const typePlace = item2?.type_place_other
                        ? item2?.type_place_other
                        : findTypePlace?.[0]?.label;
                      return (
                        <div key={index} className={STYLE_TITLE_TABLE_DEBT}>
                          <div className=" line-clamp-1">
                            {typePlace} ({item2?.address_place})
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
            })}

            {/*---- หนี้สินระยะยาวอื่น ๆ ----*/}
            {debtLongList?.map((item, index) => {
              if (item?.options_debt_long === "debt_long_other")
                return (
                  <div key={index} className="">
                    {/* <div className="text-xs text-mainblue font-medium">
                            หนี้สินระยะยาวอื่น ๆ
                          </div> */}
                    {item?.debt_long_others?.map((item2, index) => {
                      return (
                        <div key={index} className={STYLE_TITLE_TABLE_DEBT}>
                          <div className=" line-clamp-1">{item2?.title}</div>
                        </div>
                      );
                    })}
                  </div>
                );
            })}
          </div>
        ) : (
          ""
        )}

        <div
          className={`whitespace-nowrap h-10 flex justify-center items-center  bg-white`}
        ></div>
        <div className={` text-lg font-medium text-maincyan px-3`}>
          <div className=" h-10 w-full">รวม</div>
        </div>
      </div>

      {/*-------------------- body --------------------*/}
      <div className=" w-full max-w-2xl overflow-hidden overflow-x-scroll  h-full rounded-tr-[20px] rounded-br-[20px] bg-white ">
        <div className=" w-[100vh] ">
          <div className=" h-14 font-semibold text-mainblue grid grid-cols-7 items-center w-full bg-gray50 pt-1 text-sm">
            <div className=" pl-1.5 h-10 flex justify-center items-center">
              จำนวนค้างชำระ (บาท)
            </div>
            <div className=" whitespace-nowrap h-10 flex justify-center items-center w-full ">
              ชำระต่องวด
            </div>
            <div className=" px-1 h-10 flex justify-center items-center w-full">
              อัตราดอกเบี้ย (%)
            </div>
            <div className=" px-1 h-10 flex justify-center items-center w-full ">
              ระยะเวลาหนี้สิน (งวด)
            </div>
            <div className=" px-1 h-10 flex justify-center items-center w-full">
              ระยะเวลาหนี้สิน (ปี)
            </div>
            <div className=" px-1 h-10 flex justify-center items-center w-full">
              รูปแบบการชำระ
            </div>
            <div className=" whitespace-nowrap h-10 flex justify-center items-center w-full">
              สถานภาพของหนี้
            </div>
          </div>
        </div>
        <div className={`w-[100vh] pt- `}>
          {/*============================== Body หนี้ระยะสั้น ==============================*/}
          <WhiteSpace />
          {shortDebtList?.length > 0 ? (
            <>
              {shortDebtList?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={` font-semibold text-sm text-mainblue grid grid-cols-7  items-center w-full`}
                  >
                    <div className={STYLE_BODY_TABLE_DEBT}>
                      {item?.option === "debt_informal_shortterm"
                        ? numeral(item?.start_amount).format("0,0")
                        : numeral(item?.unpaid_amount).format("0,0")}
                    </div>
                    <div className={STYLE_BODY_TABLE_DEBT}>
                      {item?.type_payment_ori === "pay_minimum" ? (
                        <div className="flex flex-col justify-center items-center">
                          <div>
                            {numeral(item?.pay_permonth).format("number")} (8%)
                          </div>
                          <div className="text-[.6rem]">
                            ของยอดค้างชำระต่อเดือน
                          </div>
                        </div>
                      ) : (
                        numeral(item?.pay_permonth).format("number")
                      )}
                    </div>
                    <div className={STYLE_BODY_TABLE_DEBT}>
                      {numeral(item?.interest).format("number")}% {item?.interest_unit ?? "ต่อปี"}
                    </div>
                    <div className={STYLE_BODY_TABLE_DEBT}>
                      {item?.option === "debt_informal_shortterm"
                        ? `${item?.number_of_days} วัน`
                        : item?.period
                        ? formatPeriod(item?.period)
                        : ""}
                    </div>
                    <div className={cn(STYLE_BODY_TABLE_DEBT)}>
                      {item?.option === "debt_informal_shortterm"
                        ? `${item?.number_of_days} วัน`
                        : item?.period_time}
                    </div>
                    <div className={cn(STYLE_BODY_TABLE_DEBT, "text-xs flex-wrap text-wrap px-2 text-center")}>
                      {item?.type_payment}
                    </div>
                    <div
                      className={cn(STYLE_BODY_TABLE_DEBT, "")}
                      style={{
                        color: mapDebtConditionColor(item?.status?.value),
                      }}
                    >
                      {item?.status?.label}
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            ""
          )}

          {/*============================== Body หนี้ระยะยาว ==============================*/}
          <div className="mt-2.5">
            <WhiteSpace />
          </div>
          {longDebtList?.length > 0 ? (
            <>
              {longDebtList?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={` font-semibold text-sm text-mainblue grid grid-cols-7  items-center w-full`}
                  >
                    <div className={STYLE_BODY_TABLE_DEBT}>
                      {numeral(item?.unpaid_amount).format("0,0")}
                    </div>
                    <div className={STYLE_BODY_TABLE_DEBT}>
                      {numeral(item?.pay_permonth).format("number")}
                    </div>
                    <div className={STYLE_BODY_TABLE_DEBT}>
                      {numeral(item?.interest).format("number")}% ต่อปี
                    </div>
                    <div className={STYLE_BODY_TABLE_DEBT}>
                      {numeral(item?.period).format("0,0")}
                    </div>
                    <div className={STYLE_BODY_TABLE_DEBT}>{item?.period_time}</div>
                    <div className={STYLE_BODY_TABLE_DEBT}>
                      {item?.type_payment}
                    </div>
                    <div
                      className={cn(STYLE_BODY_TABLE_DEBT, "")}
                      style={{
                        color: mapDebtConditionColor(item?.status?.value),
                      }}
                    >
                      {item?.status?.label}
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            ""
          )}

          {/*============================== Body รวม ==============================*/}
          <div className="mt-2.5">
            <WhiteSpace />
          </div>
          <div
            className={` font-semibold text-mainblue grid grid-cols-6  items-center w-full border-b pb-1 border-r`}
          >
            <div
              className={`whitespace-nowrap h-10 flex justify-center items-center  bg-white font-semibold text-maincyan text-lg `}
            >
              {numeral(sumStartUnpaidAmount()).format("0,0")}
            </div>
          </div>
          {/** */}
        </div>
      </div>
    </div>
  );
};
