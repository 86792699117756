export const first_reload = () => {
  let ignore = false;

  const targetElement = document.getElementById("first_reload");
  if (targetElement && !ignore) {
    const targetOffset =
      targetElement.getBoundingClientRect().top + window.scrollY + -150;

    window.scrollTo({
      top: targetOffset,
      behavior: "smooth",
    });
  }
};
