import { Step, Stepper } from "@material-tailwind/react";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HeaderTitle } from "../../../components/header/Header";
import Container from "../../../components/layout/Container";
import {
  STYLE_MARGIN_TOP_PARENT,
  STYLE_PADDING_MAIN,
  STYLE_TAB,
} from "../../../utils/useStyle";

import { useBalanceStore } from "../../../_store/balanceStore";
import { useInsuranceStore } from "../../../_store/insuranceStore";
import { useRetireStore } from "../../../_store/retireStore";
import { useUserStore } from "../../../_store/userStore";
import { MTButton } from "../../../components/button/MTButton";
import { defaultValue } from "../../../functions/main";
import { numberWithoutComma } from "../../balance/component/VIDrawerDebt.Main";
import ViewDisable_Final from "./VIDisable_Final";
import ViewDisable_StepOne from "./VIDisable_StepOne";
import ViewDisable_StepThree from "./VIDisable_StepThree";
import ViewDisable_StepTwo from "./ViDisable_StepTwo";
import { calculateRateDifference, PV } from "../../../utils/useFunctions";

const ViewDisableInsurance = () => {
  const navigate = useNavigate();
  const [tab, setTab] = useState(0);
  const [final, setFinal] = useState(false);
  const [isLastStep, setIsLastStep] = useState(false);
  const [isFirstStep, setIsFirstStep] = useState(false);
  const [firstLoading, setFirstLoading] = useState(true);
  const [reload, setReload] = useState(0);

  const insuranceData = useInsuranceStore((state) => state.insuranceData);
  const setInsuranceData = useInsuranceStore((state) => state.setInsuranceData);
  const balanceData = useBalanceStore((state) => state.balanceData);
  const retireData = useRetireStore((state) => state.retireData);
  const userData = useUserStore((state) => state.userData);

  useEffect(() => {
    if (!firstLoading) return;
    let localInsuranceData = { ...insuranceData };
    let disabled_income_salary = 0;
    let disabled_income_bonus = 0;
    let disabled_personal_expenses_saving = 0;
    let disabled_personal_expenses_tax = 0;
    let disabled_personal_expenses_life_fund = 0;
    let disabled_personal_expenses_social_insurance = 0;
    let sum_disabled_insurance_current = 0;
    let disabled_asset_all = 0;
    let age =
      new Date().getFullYear() - new Date(userData.birthday).getFullYear();

    // สินทรัพย์สภาพคล่อง
    if (balanceData?.property_asset_list?.length > 0) {
      disabled_asset_all += balanceData?.sum_property_asset;
    }

    // สินทรัพย์ไม่มีตัวตน
    if (balanceData?.property_intangible_list?.length > 0) {
      disabled_asset_all += balanceData?.sum_property_intangible;
    }

    // สินทรัพย์เพื่อการลงทุน
    if (balanceData?.property_invest_list?.length > 0) {
      disabled_asset_all += balanceData?.sum_property_invest;
    }

    if (balanceData?.expenses_fixed_list?.length > 0) {
      for (const expenses_fixed_item of balanceData?.expenses_fixed_list) {
        if (expenses_fixed_item?.expenses_insuarant_accidents?.length > 0) {
          for (const expenses_insuarant_accident of expenses_fixed_item?.expenses_insuarant_accidents) {
            sum_disabled_insurance_current +=
              expenses_insuarant_accident.insurance_fund;
          }
        }
        if (expenses_fixed_item?.expenses_insuarant_lifes?.length > 0) {
          for (const expenses_insuarant_life of expenses_fixed_item?.expenses_insuarant_lifes) {
            sum_disabled_insurance_current +=
              expenses_insuarant_life.insurance_fund;
          }
        }
      }
    }

    if (balanceData?.salary_peryear_list?.length > 0) {
      for (const salary_peryear_item of balanceData?.salary_peryear_list) {
        disabled_income_salary += defaultValue(
          salary_peryear_item?.salary_permonth,
          0
        );
      }
    }

    if (balanceData?.other_income_list?.length > 0) {
      for (const other_income_item of balanceData?.other_income_list) {
        disabled_income_bonus += defaultValue(
          other_income_item?.other_income_peryear,
          0
        );
      }
    }

    if (balanceData?.interest_peryear_list?.length > 0) {
      for (const interest_peryear_item of balanceData?.interest_peryear_list) {
        disabled_income_bonus += defaultValue(
          interest_peryear_item?.interest_peryear,
          0
        );
      }
    }

    if (balanceData?.expenses_variable_list?.length > 0) {
      for (const expenses_variable_item of balanceData?.expenses_variable_list) {
        if (
          expenses_variable_item.options_expenses_variable === "expenses_tax"
        ) {
          disabled_personal_expenses_tax += expenses_variable_item.pay_peryear;
        }
      }
    }

    if (balanceData?.expenses_fixed_list?.length > 0) {
      for (const expenses_fixed_item of balanceData?.expenses_fixed_list) {
        if (expenses_fixed_item?.expenses_provide_funds?.length > 0) {
          for (const expenses_provide_func_item of expenses_fixed_item.expenses_provide_funds) {
            if (
              expenses_fixed_item.options_expenses_fixed ===
              "expenses_provide_fund"
            ) {
              disabled_personal_expenses_life_fund +=
                expenses_provide_func_item.pay_peryear;
            }
          }
        }
        if (expenses_fixed_item?.expenses_insuarant_socials?.length > 0) {
          for (const expenses_insuarant_socials_item of expenses_fixed_item?.expenses_insuarant_socials) {
            if (
              expenses_fixed_item.options_expenses_fixed ===
              "expenses_insuarant_social"
            ) {
              disabled_personal_expenses_social_insurance +=
                expenses_insuarant_socials_item.pay_peryear;
            }
          }
        }
      }
    }

    if (!insuranceData?.disabled_income_salary) {
      localInsuranceData.disabled_income_salary = disabled_income_salary;
    }
    if (!insuranceData?.disabled_income_bonus) {
      localInsuranceData.disabled_income_bonus = disabled_income_bonus;
    }
    if (!insuranceData?.disabled_personal_expenses_saving) {
      localInsuranceData.disabled_personal_expenses_saving =
        disabled_personal_expenses_saving;
    }
    if (!insuranceData?.disabled_personal_expenses_tax) {
      localInsuranceData.disabled_personal_expenses_tax =
        disabled_personal_expenses_tax;
    }
    if (!insuranceData?.disabled_personal_expenses_life_fund) {
      localInsuranceData.disabled_personal_expenses_life_fund =
        disabled_personal_expenses_life_fund;
    }
    if (!insuranceData?.disabled_personal_expenses_social_insurance) {
      localInsuranceData.disabled_personal_expenses_social_insurance =
        disabled_personal_expenses_social_insurance;
    }

    // if (!insuranceData?.disabled_current_age) {
    localInsuranceData.disabled_current_age =
      age || retireData?.age_current || 25;
    // }
    // if (!insuranceData?.disabled_dead_age) {
    localInsuranceData.disabled_dead_age = retireData?.age_dead || 90;
    // }
    // if (!insuranceData?.disabled_asset_all) {
    localInsuranceData.disabled_asset_all = disabled_asset_all;
    // }

    setInsuranceData(localInsuranceData);
    setFirstLoading(false);
  }, [balanceData]);

  useEffect(() => {
    if (!firstLoading) {
      onChangeForm("")({
        target: { value: "" },
      });
    }
  }, [firstLoading, reload]);

  const onChangeForm =
    (key, type = "number") =>
    (e) => {
      let value = e.target.value;
      if (type === "number") {
        value = numberWithoutComma(value);
      }

      if (_.isNaN(value)) {
        value = "";
      }

      const newInsurance = key
        ? { ...insuranceData, [key]: value }
        : { ...insuranceData };
      const keys = Object.keys(newInsurance);
      let sum_disabled_income = 0;
      let sum_disabled_personal_expenses = 0;
      let sum_disabled_family_expenses = 0;
      let sum_disabled_protect = 0;
      let sum_disabled_have_to_money = 0;
      let sum_disabled_return = 0;
      let sum_disabled_remain = 0;
      let sum_disabled_family_five_year = 0;
      let sum_disabled_insurance_net = 0;
      let sum_disabled_insurance_current = 0;
      let sum_disabled_insurance_more = 0;
      let disabled_asset_social_dead_pv = 0;
      let disabled_diff_year = 0;
      let sum_disabled_asset_current = 0;

      for (const key of keys) {
        if (key.startsWith("disabled_personal_expenses")) {
          sum_disabled_personal_expenses += defaultValue(newInsurance[key], 0);
        }
      }

      if (balanceData?.expenses_fixed_list?.length > 0) {
        for (const expenses_fixed_item of balanceData?.expenses_fixed_list) {
          const keys = Object.keys(expenses_fixed_item);
          for (const key of keys) {
            if (_.isArray(expenses_fixed_item[key])) {
              for (const item_of_key of expenses_fixed_item[key]) {
                // sum_disabled_insurance_current += +item_of_key.insurance_fund;
                sum_disabled_insurance_current += +_.get(
                  item_of_key.more_details,
                  "contract_tpd",
                  0
                );
                sum_disabled_insurance_current += +_.get(
                  item_of_key.more_details,
                  "compensation_ecir",
                  0
                );
                sum_disabled_insurance_current += +_.get(
                  item_of_key.more_details,
                  "compensation_aia_supercare",
                  0
                );
                sum_disabled_insurance_current += +_.get(
                  item_of_key.more_details,
                  "compensation_aia_ci_plus_gold",
                  0
                );
                sum_disabled_insurance_current += +_.get(
                  item_of_key.more_details,
                  "compensation_aia_ci_plus",
                  0
                );
                sum_disabled_insurance_current += +_.get(
                  item_of_key.more_details,
                  "compensation_mutiple_care_ci",
                  0
                );
              }
            }
          }
        }
      }

      if (newInsurance.disabled_asset_is_social_insurance === "Y") {
        newInsurance.disabled_asset_social_dead = 90000;
      } else {
        newInsurance.disabled_asset_social_dead = 0;
      }

      newInsurance.disabled_income_salary_per_year =
        defaultValue(newInsurance.disabled_income_salary, 0) * 12;
      sum_disabled_income =
        defaultValue(newInsurance.disabled_income_salary_per_year, 0) +
        defaultValue(newInsurance.disabled_income_bonus, 0);
      sum_disabled_family_expenses =
        defaultValue(sum_disabled_income) -
        defaultValue(sum_disabled_personal_expenses);
      // sum_disabled_family_five_year = sum_disabled_family_expenses * 5;
      sum_disabled_family_five_year = sum_disabled_family_expenses;
      sum_disabled_protect =
        sum_disabled_family_expenses *
        (newInsurance.disabled_dead_age - newInsurance.disabled_current_age);
      const diff_rate = calculateRateDifference(
        newInsurance.disabled_return_year,
        newInsurance.disabled_income_year
      );
      disabled_diff_year =
        newInsurance.disabled_dead_age - newInsurance.disabled_current_age;
      sum_disabled_have_to_money = PV(
        diff_rate / 100,
        disabled_diff_year,
        sum_disabled_family_expenses
      );
      disabled_asset_social_dead_pv = PV(
        diff_rate / 100,
        disabled_diff_year,
        newInsurance.disabled_asset_social_dead
      );
      sum_disabled_insurance_current =
        defaultValue(newInsurance.disabled_asset_all, 0) +
        defaultValue(newInsurance.disabled_asset_insurance_private, 0) +
        defaultValue(newInsurance.disabled_asset_invest_rmf, 0) +
        defaultValue(disabled_asset_social_dead_pv, 0) +
        defaultValue(newInsurance.disabled_asset_social_retire, 0);
      sum_disabled_asset_current = defaultValue(
        newInsurance.disabled_asset_all,
        0
      );

      // sum_disabled_return = PV(
      //   newInsurance.disabled_return_year / 100,
      //   diff_year,
      //   sum_disabled_family_expenses
      // );

      if (_.isNaN(sum_disabled_have_to_money)) {
        sum_disabled_have_to_money = 0;
      }
      if (_.isNaN(sum_disabled_remain)) {
        sum_disabled_remain = 0;
      }
      if (_.isNaN(sum_disabled_protect)) {
        sum_disabled_protect = 0;
      }
      if (_.isNaN(sum_disabled_return)) {
        sum_disabled_return = 0;
      }

      // if (
      //   newInsurance.disabled_return_year > newInsurance.disabled_income_year
      // ) {
      //   sum_disabled_remain =
      //     sum_disabled_have_to_money -
      //     (sum_disabled_return - sum_disabled_have_to_money);
      // } else {
      //   sum_disabled_remain =
      //     sum_disabled_have_to_money -
      //     sum_disabled_return +
      //     sum_disabled_have_to_money;
      // }

      // // sum_disabled_have_to_money C63
      // // disabled_return_year C64
      // // disabled_income_year C61
      // // sum_disabled_protect  C65
      // sum_disabled_insurance_net = sum_disabled_remain;

      sum_disabled_insurance_net = sum_disabled_have_to_money;
      sum_disabled_insurance_more =
        sum_disabled_have_to_money - sum_disabled_insurance_current;
      // +sum_disabled_insurance_net -
      // +sum_disabled_insurance_current -
      // +sum_disabled_asset_current;

      setInsuranceData({
        ...newInsurance,
        disabled_asset_social_dead_pv,
        disabled_diff_year,
        sum_disabled_income,
        sum_disabled_family_expenses,
        sum_disabled_personal_expenses,
        sum_disabled_protect,
        sum_disabled_have_to_money,
        sum_disabled_return,
        sum_disabled_remain,
        sum_disabled_family_five_year,
        sum_disabled_insurance_net,
        sum_disabled_insurance_current,
        sum_disabled_insurance_more,
        sum_disabled_asset_current,
      });
    };

  const onChangeAge = (e) => {
    const [current, dead] = e;
    const newInsurance = { ...insuranceData };
    let sum_disabled_protect = 0;
    newInsurance.disabled_current_age = current;
    newInsurance.disabled_dead_age = dead;
    sum_disabled_protect =
      insuranceData.sum_disabled_family_expenses * (dead - current);
    setInsuranceData({ ...newInsurance, sum_disabled_protect });
    setReload((prev) => prev + 1);
  };

  return (
    <div>
      <Container>
        <div className=" mb-10">
          <div className={`${STYLE_MARGIN_TOP_PARENT} `}>
            <HeaderTitle
              title="ทุนประกันคุ้มครองกรณีทุพพลภาพ"
              isGoback
              onClickGoBack={() => {
                if (final) {
                  setFinal(false);
                  setTab(2);
                  return;
                }
                navigate("/insuranceplan");
              }}
            />
            {final ? (
              <ViewDisable_Final setFinal={setFinal} setTab={setTab} />
            ) : (
              <>
                <div className={` ${STYLE_PADDING_MAIN}`}>
                  {/* ------ หัวข้อ Step ------ */}
                  <div className=" px-4">
                    <Stepper
                      activeStep={tab}
                      lineClassName="bg-gray-300"
                      activeLineClassName="bg-maincyan"
                      className={`${STYLE_TAB} `}
                      isLastStep={(value) => setIsLastStep(value)}
                      isFirstStep={(value) => setIsFirstStep(value)}
                    >
                      <Step
                        className=" !bg-maincyan text-lg"
                        activeClassName="!bg-maincyan "
                        onClick={() => setTab(0)}
                      >
                        <div className=" mt-0.5">1</div>
                      </Step>
                      <Step
                        onClick={() => setTab(1)}
                        className={`${
                          tab === 0 ? "!bg-gray300" : "!bg-maincyan"
                        }`}
                        activeClassName="!bg-maincyan"
                      >
                        <div className=" mt-0.5">2</div>
                      </Step>

                      <Step
                        className="!bg-gray-300"
                        activeClassName="!bg-maincyan"
                        onClick={() => setTab(2)}
                      >
                        <div className=" mt-0.5">3</div>
                      </Step>
                    </Stepper>
                  </div>
                </div>
                {tab === 0 && (
                  <ViewDisable_StepOne onChangeForm={onChangeForm} />
                )}
                {tab === 1 && (
                  <ViewDisable_StepTwo onChangeForm={onChangeForm} />
                )}
                {tab === 2 && (
                  <ViewDisable_StepThree
                    onChangeForm={onChangeForm}
                    onChangeAge={onChangeAge}
                  />
                )}
                <div className=" flex justify-center gap-4 mb-10 px-5">
                  {tab === 0 ? (
                    <div>
                      <MTButton
                        // loading={loading}
                        text={"ถัดไป"}
                        color="bg-maincyan"
                        onClick={(e) => {
                          setTab((prev) => prev + 1);
                        }}
                      />
                    </div>
                  ) : (
                    <div>
                      {/* {tab === 2 ? (
                        <div className=" flex justify-center items-center gap-2">
                          <MTButton
                            text="ย้อนกลับ"
                            color="border border-maincyan "
                            colortext="text-maincyan group-hover:text-white"
                            hovercolor="hover:bg-maincyan group "
                            onClick={() => {
                              setTab((prev) => prev - 1);
                            }}
                          />
                        </div>
                      ) : ( */}
                      <div className=" flex justify-center items-center gap-2">
                        <MTButton
                          // loading={loading}
                          text="ย้อนกลับ"
                          color="border border-maincyan "
                          colortext="text-maincyan group-hover:text-white"
                          hovercolor="hover:bg-maincyan group "
                          onClick={() => {
                            setTab((prev) => prev - 1);
                          }}
                        />
                        <MTButton
                          // loading={loading}
                          text={"ถัดไป"}
                          color="bg-maincyan"
                          onClick={(e) => {
                            if (tab === 2) {
                              setFinal(true);
                              setInsuranceData({
                                ...insuranceData,
                                is_plan: true,
                              });
                              return;
                            }
                            setTab((prev) => prev + 1);
                          }}
                        />
                      </div>
                      {/* )} */}
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ViewDisableInsurance;
