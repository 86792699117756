import React from "react";
import { numberWithoutComma } from "../../../../balance/component/VIDrawerDebt.Main";
import {
  STYLE_INPUT_CARD_BALANCE,
  STYLE_PARENT_CARD_BALANCE,
  STYLE_TITLE_CARD_DRAWER_BL,
} from "../../../../../utils/useStyle";
import numeral from "numeral";
import { NumericFormat } from "react-number-format";

const DetailCost = ({
  data = [],
  TotalExpense = 0,
  onChangeExpenstaxeDetail,
  cost = { service: "", salary: "", others: "" },
  field = { service: "expense_cost", salary: "expense_salary", others:  "expense_other" },
  sum = 0
}) => {
  return (
    <>
      <div className=" text-center text-black font-medium text-sm">
        แจกแจงค่าใช้จ่ายที่ขอหัก
      </div>
      <div className=" text-xs text-maingray my-1">
        *กรณีเงินได้/การขอหัก มากกว่า 1 รายการ ตอนกรอก ให้เติมทุกรายการ
      </div>
      <div className=" flex justify-between items-center">
        <div className=" text-black text-sm font-semibold">
          ยอดค่าใช้จ่ายที่ขอหัก
        </div>
        <div className=" text-maincyan text-xl font-medium">
          {" "}
          {numeral(TotalExpense)?.format("0,0")} บาท
        </div>
      </div>
      <div className=" bg-white p-3 rounded-[20px] my-[15px]">
        <div className=" text-center text-black font-medium text-sm">
          แจกแจงค่าใช้จ่าย
        </div>
        <div className={STYLE_PARENT_CARD_BALANCE}>
          <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-3 `}>
            1. ต้นทุนการให้บริการ
          </div>
          <NumericFormat
            maxLength={11}
            placeholder="0"
            value={numeral(cost?.service)?.format('0,0')}
              onChange={(e) => {
                onChangeExpenstaxeDetail(
                  e.target.value,
                  field?.service
                );
              }}
            thousandSeparator=","
            inputMode="decimal"
            pattern="[0-9],*"
            className={`${STYLE_INPUT_CARD_BALANCE} col-span-2 w-full`}
          />
        </div>
        <div className={STYLE_PARENT_CARD_BALANCE}>
          <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-3 `}>
            2.เงินเดือนค่าจ้าง
          </div>
          <NumericFormat
            maxLength={11}
            placeholder="0"
            value={numeral(cost?.salary)?.format('0,0')}
              onChange={(e) => {
                onChangeExpenstaxeDetail(
                  e.target.value,
                  field?.salary
                );
              }}
            thousandSeparator=","
            inputMode="decimal"
            pattern="[0-9],*"
            className={`${STYLE_INPUT_CARD_BALANCE} col-span-2 w-full`}
          />
        </div>
        <div className={STYLE_PARENT_CARD_BALANCE}>
          <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-3 `}>
            3.ค่าใช้จ่ายอื่นๆ
          </div>
          <NumericFormat
            maxLength={11}
            placeholder="0"
            value={numeral(cost?.others)?.format('0,0')}
              onChange={(e) => {
                onChangeExpenstaxeDetail(
                  e.target.value,
                  field?.others
                );
              }}
            thousandSeparator=","
            inputMode="decimal"
            pattern="[0-9],*"
            className={`${STYLE_INPUT_CARD_BALANCE} col-span-2 w-full`}
          />
        </div>
      </div>
      <div className=" flex justify-between items-center ">
        <div className=" text-black text-sm font-semibold">
          รวมค่าใช้จ่ายทั้งหมด
        </div>
        <div className=" text-maincyan text-xl font-medium">
          {" "}
          {numeral(sum)?.format("0,0")} บาท
        </div>
      </div>
    </>
  );
};

export default DetailCost;
