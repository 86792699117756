import React from "react";
import { isEmpty, styleEmpyCard } from "../../utils/util";
import { NumericFormat } from "react-number-format";
import { STYLE_INPUT_CARD_BALANCE } from "../../utils/useStyle";
import { cn } from "../../utils/useFunctions";

const NumbericValidateRed = ({
  value,
  onChange,
  max = 11,
  placeholder = "0",
  shouldValdiate = false,
  className = `${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1 border-red-500 border-2`,
  disabled = false,
  isSubmited = false,
  name = "",
}) => {
  const validate = isEmpty(isSubmited, value);

  return (
    <NumericFormat
      maxLength={max}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      thousandSeparator=","
      inputMode="decimal"
      pattern="[0-9],*"
      disabled={disabled}
      className={cn(styleEmpyCard(validate, shouldValdiate, className))}
    />
  );
};

export default NumbericValidateRed;
