import { cn } from "../../../utils/useFunctions";
import PdfFooter from "../component/pdf.c.footer";
import PdfHeader from "../component/pdf.c.header";
import PdfLine from "../component/pdf.c.line";
import { STYLE_PAGE, STYLE_PAGE_LANDING } from "../styles/pdf.style";

function PdfPageAppendix({ page }) {
  return (
    <div className={cn(STYLE_PAGE)}>
      <PdfHeader />
      <PdfLine />
      <img className="bg-cover" src={"/images/appendix.svg"} />
      <PdfFooter page={page} />
    </div>
  );
}

export default PdfPageAppendix;
