import { useNavigate } from "react-router-dom";
import { HeaderTitle } from "../../../components/header/Header";
import Container from "../../../components/layout/Container";
import React, { useEffect, useMemo, useState } from "react";
import { first_reload } from "../../tax_plan/functions/firstLoad";
import { HeaderReport } from "../components/header.main";
import {
  STYLE_REPORT_CONTAIN,
  STYLE_TEXT_BLUE_EDU,
} from "../../../utils/useStyle";
import { CardKidsInfo } from "../../education/styles/CardMain";
import { ImageMain } from "../../../components/img/Img.Main";
import TabPanelReport from "../components/tab.main";
import { MTButton } from "../../../components/button/MTButton";
import { CardTips } from "../../../components/card/CardMain";
import ViewTableExpenses from "../../education/components/ViewTableExpenses";
import { useEducationStore } from "../../../_store/educationStore";
import {
  LineChartEduExpensesMain,
  LineChartEduInvestMain,
} from "../../education/components/EduLineChart";
import ViewExpensesEDU from "../../education/components/ViewExpenses.EDU";
import ViewTableInsuarance from "../../education/components/ViewTableInsuarance";
import {
  options_education_rank,
  options_under_education,
} from "../../education/functions/options";

const CardKidsSection = ({ educationItem }) => {
  const getCurrentEducationTH = (valueEducation) => {
    const current_education_th = options_education_rank?.find((rankItem) => {
      return rankItem.value === valueEducation;
    });
    return current_education_th?.label;
  };

  const getUnderEducationTH = (valueEducation) => {
    const under_education_th = options_under_education?.find((underItem) => {
      return underItem?.value === valueEducation;
    });
    return under_education_th?.label;
  };

  return (
    <div className=" cursor-pointer bg-gray50 w-full rounded-[20px] px-4 py-3 relative">
      <div className={`grid grid-cols-12 space-x-3 mt-1  `}>
        <label className="col-span-4 md:col-span-3 relative">
          <div className=" bg-white rounded-[10px] overflow-hidden w-[100px] h-[100px] flex justify-center items-center">
            <ImageMain
              src={
                educationItem?.img
                  ? educationItem?.img
                  : "../images/user_circle.png"
              }
              className="w-[100px] h-[100px] md:w-full md:h-full object-cover "
            />
          </div>
        </label>
        <div
          className={`col-span-8 md:col-span-9 flex flex-col space-y-[9px] `}
        >
          <div className="  grid grid-cols-5  md:flex md:gap-5  items-center">
            <div className={STYLE_TEXT_BLUE_EDU}>ชื่อ</div>
            <div
              className={` text-sm  font-medium text-black col-span-4 md:col-span-none `}
            >
              {educationItem?.name}
            </div>
          </div>
          <div className=" flex flex-row items-center w-full">
            <div className={`${STYLE_TEXT_BLUE_EDU}  `}>ปีเกิด{""}</div>
            <div className={` text-sm  font-medium text-black col-span-4 `}>
              {educationItem?.year}
            </div>
          </div>

          <div className="flex space-x-2">
            <div className={STYLE_TEXT_BLUE_EDU}>อายุ{""}</div>
            <div className={` text-sm  font-medium text-black col-span-4 `}>
              {educationItem?.age}
            </div>
          </div>
          <div className="flex space-x-2">
            <div className={STYLE_TEXT_BLUE_EDU}>การศึกษาปัจจุบัน</div>
            <div className={` text-sm  font-medium text-black col-span-4 `}>
              {getCurrentEducationTH(educationItem?.current_education)}
            </div>
          </div>
          <div className="flex space-x-2">
            <div className={STYLE_TEXT_BLUE_EDU}>เป้าหมายการศึกษา</div>
            <div className={` text-sm  font-medium text-black col-span-4 `}>
              {getCurrentEducationTH(educationItem?.target_education)}
            </div>
          </div>
          <div className="flex space-x-2">
            <div className={STYLE_TEXT_BLUE_EDU}>สังกัดการศึกษา</div>
            <div className={` text-sm  font-medium text-black col-span-4 `}>
              {getUnderEducationTH(educationItem?.under_education)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ExpenseEduSection = ({ educationItem }) => {
  const dataExpenses = useMemo(() => {
    const expenses_items = educationItem?.education_items?.map(
      (education_item) => {
        return education_item.sum_under_education_accumulate;
      }
    );
    return expenses_items;
  }, [educationItem]);

  const dataLabels = useMemo(() => {
    const expenses_items = educationItem?.education_items?.map(
      (education_item) => {
        return education_item?.education?.label;
      }
    );
    return expenses_items;
  }, [educationItem]);

  const heightTable = educationItem?.education_items?.length * 50 + 260;
  const height = `${heightTable}px`;

  const checkHeight = (height) => {
    let value = heightTable;
    // console.log(value);

    if (value > 600) {
      return 600;
    } else {
      return value;
    }
  };

  return (
    <div>
      <div className=" mt-2">
        <LineChartEduExpensesMain
          dataExpenses={dataExpenses}
          labels={dataLabels}
        />
      </div>
      <div
        style={{
          height: `${checkHeight(height)}px`,
        }}
        className="px-6 rounded-[20px] overflow-auto  mt-[14px]"
      >
        <ViewTableExpenses localEducationData={educationItem} />
      </div>
    </div>
  );
};
const FundSection = ({ educationItem }) => {
  const dataInsurance = useMemo(() => {
    const expenses_items = educationItem?.education_items?.map(
      (education_item) => {
        return education_item.sum_insurance;
      }
    );
    return expenses_items;
  }, [educationItem]);

  const dataLabels = useMemo(() => {
    const expenses_items = educationItem?.education_items?.map(
      (education_item) => {
        return education_item?.education?.label;
      }
    );
    return expenses_items;
  }, [educationItem]);

  const heightTable = educationItem?.education_items?.length * 50 + 100;
  const height = `${heightTable}px`;

  const checkHeight = (height) => {
    let value = heightTable;
    // console.log(value);

    if (value > 600) {
      return 600;
    } else {
      return value;
    }
  };

  return (
    <div>
      <div className=" mt-2 ">
        <LineChartEduInvestMain dataFund={dataInsurance} labels={dataLabels} />
      </div>
      {/* ตารางสรุปค่าใช้จ่ายเพื่อการศึกษา */}
      <div
        style={{
          height: `${checkHeight(height)}px`,
        }}
        className="rounded-[20px] overflow-auto  mt-[14px]"
      >
        <ViewTableInsuarance localEducationData={educationItem} />
      </div>
    </div>
  );
};

const ReportEducationPage = () => {
  const navigate = useNavigate();
  const [isTab, setTab] = useState("expense");
  const educationData = useEducationStore((state) => state.educationData);

  useEffect(() => {
    let ignore = false;
    first_reload(ignore);
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <Container>
      <div id="first_reload" className=" pt-[14px]">
        <HeaderTitle
          title="รายงาน"
          isGoback
          onClickGoBack={() => {
            navigate("/report");
          }}
        />

        <div className={`${STYLE_REPORT_CONTAIN} flex flex-col gap-5`}>
          <HeaderReport
            icon={"/images/graduate-report.png"}
            title={"แผนการศึกษาบุตร "}
            className={" z-20"}
          />

          {/* // แสดงข้อมูลบุตร */}

          {educationData?.map((educationItem, index) => {
            return (
              <React.Fragment key={index}>
                {/* การ์ดข้อมูลบุตร */}
                <CardKidsSection educationItem={educationItem} />

                {/* วางแผนการออม */}

                <div className="bg-gray50 rounded-[20px] w-full h-ful">
                  <div className=" flex items-center gap-1 p-3">
                    <ImageMain src="/images/document.png" className="w-6 h-6" />
                    <div className=" font-medium text-sm">
                      วางแผนออมเงินเพื่อเป้าหมายทางการศึกษาบุตร
                    </div>
                  </div>

                  <div className="bg-white w-full h-full px-2.5 py-[14px]">
                    <TabPanelReport
                      title_tab1={
                        <div className=" text-nowrap">
                          ค่าใช้จ่ายเพื่อการศึกษา
                        </div>
                      }
                      title_tab2={"ทุนประกัน"}
                      onTab1={() => setTab("expense")}
                      tab1={isTab === "expense" ? true : false}
                      onTab2={() => setTab("fund")}
                      tab2={isTab === "fund" ? true : false}
                    />
                    {isTab === "expense" ? (
                      <div>
                        <ExpenseEduSection educationItem={educationItem} />
                      </div>
                    ) : (
                      <FundSection educationItem={educationItem} />
                    )}
                  </div>
                </div>
              </React.Fragment>
            );
          })}

          <CardTips
            title="กลยุทธ์การวางแผน"
            panel={
              <div className=" space-y-1">
                แนะนำ ให้ ปรับลดทุนประกันชีวิตวิ ให้สอดคล้อง
                กับงบประมาณค่าใช้จ่ายในแต่ละระดับชั้นการศึกษา ตามที่โปรแกรม คำ
                นวณได้ และพิจารณาค่าเบี้ยประกัน ไม่ควรเกิน 10-18%
                ของรายได้ต่อปี 
              </div>
            }
          />

          <div className=" flex justify-center ">
            <MTButton
              onClick={() => {
                navigate("/report");
              }}
              text="ย้อนกลับ"
              color="bg-white"
              border="border border-maincyan"
              colortext="text-maincyan group-hover:text-white"
              hovercolor="hover:bg-maincyan group "
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ReportEducationPage;
