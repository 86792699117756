import { useContext, useEffect, useMemo, useState } from "react";
import { cn } from "../../../utils/useFunctions";
import { STYLE_PDF_TEXT_NORMAL_10 } from "../styles/pdf.style";
import { PdfContext } from "../context/pdf.ctx";

function PdfFooter({ page = 0 }) {
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    const createTimer = setTimeout(() => {
      const allFooters = getFooters();
      setTotalPages(allFooters.length);
    }, 1000)
    return () => clearTimeout(createTimer);
  }, []);

  const getFooters = () => {
    const allFooters = document.querySelectorAll("#pdf_footer");
    return allFooters;
  };

  return (
    <div
      id="pdf_footer"
      className="absolute bottom-2.5 w-full left-1/2 -translate-x-1/2 "
    >
      <div className="grid grid-cols-3 w-full justify-center items-center p-2.5">
        <div></div>
        <div className="text-mainblue  text-[10px]">
          สงวนลิขสิทธิ โดย บริษัทแอสชัวเวลธ์ จำกัด
        </div>
        <div className={cn(STYLE_PDF_TEXT_NORMAL_10, "ml-auto mr-6")}>
          {page}/{totalPages}
        </div>
      </div>
    </div>
  );
}

export default PdfFooter;
