import { ImageMain } from "../../../components/img/Img.Main";
import { STYLE_HEADER_LEGACY } from "../../../utils/useStyle";

export const CardStepperLegacy = ({
  img,
  details,
  img_className = "w-44 h-44 mx-auto",
}) => {
  return (
    <div>
      <div className={`${STYLE_HEADER_LEGACY} mb-4`}>การเขียนพินัยกรรม</div>
      <div className={img_className}>
        <ImageMain className={` w-full h-full`} src={img} />
      </div>
      <div className=" bg-gray50 rounded-[20px] p-4 text-mainblue text-center my-6 font-medium text-sm ">
        {details}
      </div>
    </div>
  );
};
