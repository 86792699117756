import { useEffect, useMemo, useState } from "react";
import { useBalanceStore } from "../../../_store/balanceStore";
import {
  STYLE_ACCORDION_BODY,
  STYLE_BODY_INVEST,
  STYLE_BUTTON_DELETE,
  STYLE_ICON_PLUS,
  STYLE_IMG_BALANCE,
  STYLE_INPUT,
  STYLE_INPUT_INSET,
  STYLE_INPUT_INVEST,
  STYLE_INPUT_PROPERTY_PROPORTION,
  STYLE_MAX_W_DRAWER,
  STYLE_PADDING_BALANCE_DRAWER,
  STYLE_TITLE_DRAWER,
} from "../../../utils/useStyle";
import {
  ICON_CONTRACT,
  ICON_DELETE,
  ICON_EDIT_PENCIL,
  ICON_MINUS_CIRCLE,
  ICON_NAVIGATE,
  ICON_PLUS,
} from "../../../utils/useIcons";
import numeral from "numeral";
import DropDownMain from "../../../components/dropdown/DropDownMain";
import {
  OPTIONS_TARGET_INVEST,
  options_property_asset,
  options_property_intangible,
  options_property_invest,
  options_property_private,
} from "../functions";
import { Drawer } from "@material-tailwind/react";
import {
  ButtonCounter,
  DeleteButtonCard,
  MTButton,
} from "../../../components/button/MTButton";
import {
  CardAccordian,
  CardPropertyAsset,
  HeaderInvest,
  SelectTargetInvest,
} from "../styles/Card";
import { NumericFormat } from "react-number-format";
import { HeaderDrawer } from "../../../components/header/Header";
import { InputMain } from "../../../components/input/InputMain";
import { set } from "lodash";
import { avgWeight } from "../../retirement_plan/functions/functionMain";
import { CardYearInput } from "../../../components/card/CardMain";
import { PopupMain } from "../../../components/popup/PopupMain";
import ListPrivateMain, { ListInvestmain } from "./VIListPrivate";
import { CheckBoxSecond } from "../../../components/checkbox/CheckboxSecond";
import { SumDebtInvestValue } from "../../../functions/Balance/sumValueBalance";

const numberWithoutComma = (value) => {
  let result = parseInt(value?.replace(/,/g, ""), 10);
  return result;
};

const PRIVATE_LIST = [
  "other_lists",
  "collection_lists",
  "home_lists",
  "car_lists",
  "accessories_lists",
];

export const DrawerBalanceProperty = ({
  open,
  onclose,
  formBL,
  setFormBL,
  setOpenDrawer,
  openAccordion,
  setOpenAccordion,
  addAsset,
  setAddAsset,
  addInvest,
  setAddInvest,
  addPrivate,
  setAddPrivate,
  addIntangible,
  setAddIntangible,
}) => {
  const setBalanceData = useBalanceStore((state) => state.setBalanceData);
  const balanceData = useBalanceStore((state) => state.balanceData);

  const [openAlert, setOpenAlert] = useState(false);
  const [openAlert2, setOpenAlert2] = useState(false);
  const [openAlertNoDebt, setOpenAlertNoDebt] = useState(false);

  const [dataIndexDeleteOne, setDataIndexDeleteOne] = useState(null);
  const [dataIndexDeleteAll, setDataIndexDeleteAll] = useState(null);

  const [tempBalance, setTempBalance] = useState(balanceData);
  const [ReturnInsurance, setReturnInsurance] = useState([]);

  useEffect(() => {
    if (tempBalance) {
      let tempInsuranceReturn = tempBalance?.expenses_fixed_list
        ?.filter(
          (item) => item.options_expenses_fixed === "expenses_insuarant_life"
        )
        ?.map((item) => item.expenses_insuarant_lifes)
        ?.flat();

      let tempBalanceData = {};
      //=============เงินลงทุนอื่น ๆ (สินทรัพย์ทางเลือก)=============//

      if (
        tempBalance?.property_invest_list
          ?.map((item) => item.options_property_invest_title)
          .includes("เงินลงทุนอื่น ๆ (สินทรัพย์ทางเลือก)") &&
        tempInsuranceReturn.length === 0
      ) {
        // ถ้ามีหัวข้อนี้อยู่แล้ว
        let tempNameList = tempBalance?.property_invest_list
          .find(
            (item) =>
              item?.options_property_invest_title ===
              "เงินลงทุนอื่น ๆ (สินทรัพย์ทางเลือก)"
          )
          ?.invest_other_lists?.filter((item) => item !== null)
          .map((item) => item?.title);

        let tempInvestList = tempBalance?.property_invest_list?.map((item) => {
          if (item?.invest_other_lists) {
            let tempData = tempInsuranceReturn
              .map((item2) => {
                if (!tempNameList?.includes(item2.title))
                  return {
                    amount: item2?.more_details?.policy_surrender_value,
                    created: item2.created,
                    reward_amount_per_year: item?.reward_amount_per_year || 1,
                    target_invest: {},
                    title: item2.title,
                    editable: false,
                  };
              })
              .filter((item) => item !== undefined);
            return {
              ...item,
              invest_other_lists: [...item.invest_other_lists, ...tempData],
            };
          }
          return item;
        });

        tempBalanceData = tempInvestList;
      } else {
        // ถ้ายังไม่มีหัวข้อนี้อยู่
        let tempInvestList = tempInsuranceReturn?.map((item) => {
          return {
            amount: item?.more_details?.policy_surrender_value,
            created: item.created,
            reward_amount_per_year: item?.reward_amount_per_year || 1,
            target_invest: item?.target_invest || {},
            title: item.title,
            editable: true,
          };
        });

        tempBalanceData = tempInvestList;
      }
      setReturnInsurance(tempBalanceData);
    }
    //==============================================//
  }, [balanceData]);

  const handleOpen = (value) => {
    setOpenAccordion(open === value ? 0 : value);
  };

  //======================================== สินทรัพย์สภาพคล่อง ========================================//
  const onChangeSelectedAsset = (created, index, e) => {
    setAddAsset((prev) => {
      let value = e.value;
      const result = [...prev];

      //---- เงินสด ----//
      if (value === "property_cash") {
        const findTitle = options_property_asset?.filter((item) => {
          if (item.value === value) return item;
        });
        result[index].options_property_asset = value;
        result[index].options_property_asset_title = findTitle?.[0]?.label;
        result[index].property_cash_lists = [
          {
            created: created,
            title: "",
            account_number: "",
            amount: "",
          },
        ];
        delete result[index].property_saving_lists;
        delete result[index].property_deposit_lists;
        delete result[index].property_saving_cooperative_lists;
        delete result[index].property_fund_debt_lists;
      }

      //---- บัญชีเงินฝากออมทรัพย์ ----//
      if (value === "property_saving") {
        const findTitle = options_property_asset?.filter((item) => {
          if (item.value === value) return item;
        });
        result[index].options_property_asset = value;
        result[index].options_property_asset_title = findTitle?.[0]?.label;
        result[index].property_saving_lists = [
          {
            created: created,
            title: "",
            account_number: "",
            amount: "",
          },
        ];
        delete result[index].property_cash_lists;
        delete result[index].property_deposit_lists;
        delete result[index].property_saving_cooperative_lists;
        delete result[index].property_fund_debt_lists;
      }

      //---- บัญชีเงินฝากประจำ ----//
      if (value === "property_deposit") {
        const findTitle = options_property_asset?.filter((item) => {
          if (item.value === value) return item;
        });
        result[index].options_property_asset = value;
        result[index].options_property_asset_title = findTitle?.[0]?.label;
        result[index].property_deposit_lists = [
          {
            created: created,
            title: "",
            account_number: "",
            amount: "",
          },
        ];
        delete result[index].property_cash_lists;
        delete result[index].property_saving_lists;
        delete result[index].property_saving_cooperative_lists;
        delete result[index].property_fund_debt_lists;
      }

      //---- เงินฝากสหกรณ์ออมทรัพย์ ----//
      if (value === "property_saving_cooperative") {
        const findTitle = options_property_asset?.filter((item) => {
          if (item.value === value) return item;
        });
        result[index].options_property_asset = value;
        result[index].options_property_asset_title = findTitle?.[0]?.label;
        result[index].property_saving_cooperative_lists = [
          {
            created: created,
            title: "",
            account_number: "",
            amount: "",
          },
        ];
        delete result[index].property_cash_lists;
        delete result[index].property_saving_lists;
        delete result[index].property_deposit_lists;
        delete result[index].property_fund_debt_lists;
      }

      //---- กองทุนตราสารหนี้สินระยะสั้น ----//
      if (value === "property_fund_debt") {
        const findTitle = options_property_asset?.filter((item) => {
          if (item.value === value) return item;
        });
        result[index].options_property_asset = value;
        result[index].options_property_asset_title = findTitle?.[0]?.label;
        result[index].property_fund_debt_lists = [
          {
            created: created,
            title: "",
            title_fund: "",
            account_number: "",
            amount: "",
          },
        ];
        delete result[index].property_cash_lists;
        delete result[index].property_saving_lists;
        delete result[index].property_deposit_lists;
        delete result[index].property_saving_cooperative_lists;
      }

      return result;
    });
  };

  //---------------------------------------- เงินสด ----------------------------------------//
  const handleNextCash = (index, e) => {
    setAddAsset((prev) => {
      let result = [...prev];
      const date = new Date();
      const created = date.toISOString();
      const newList = {
        created: created,
        title: "",
        account_number: "",
        amount: "",
      };
      result[index] = {
        ...result[index],
        property_cash_lists: [
          ...result[index].property_cash_lists,
          { ...newList },
        ],
      };
      return result;
    });
  };

  const onClickDeleteCash = (index, indexCard, e) => {
    setDataIndexDeleteOne({
      index,
      indexCard,
    });
    setOpenAlert(true);
  };

  const onClickConfirmDeleteCash = () => {
    const index = dataIndexDeleteOne?.index;

    const result = [...addAsset];
    result.splice(index);
    setAddAsset(result);

    setDataIndexDeleteOne(null);
    setOpenAlert(false);
  };

  const onChangeTitleCash = (index, indexCard, e) => {
    const value = e.target.value;
    setAddAsset((prev) => {
      let result = [...prev];
      result[index].property_cash_lists[indexCard].title = value;
      return result;
    });
  };
  const onChangeAccountNumberCash = (index, indexCard, e) => {
    const value = e.target.value;
    setAddAsset((prev) => {
      let result = [...prev];
      result[index].property_cash_lists[indexCard].account_number = value;
      return result;
    });
  };

  const onChangeAmountCash = (e) => {
    const value = e.target.value;
    let output = parseFloat(value?.replace(/,/g, ""))
      ? parseFloat(value?.replace(/,/g, ""))
      : null;
    if (isNaN(output)) return;

    setAddAsset((prev) => {
      const result = [...prev];
      result[0].property_cash_lists[0].amount = output;
      return result;
    });
  };

  //---------------------------------------- บัญชีเงินฝากออมทรัพย์ ----------------------------------------//
  const handleNextSaving = (index, e) => {
    setAddAsset((prev) => {
      let result = [...prev];
      const date = new Date();
      const created = date.toISOString();
      const newList = {
        created: created,
        title: "",
        account_number: "",
        amount: "",
      };
      result[index] = {
        ...result[index],
        property_saving_lists: [
          ...result[index].property_saving_lists,
          { ...newList },
        ],
      };
      return result;
    });
  };

  const onClickDeleteSaving = (index, indexCard, e) => {
    setDataIndexDeleteOne({
      index,
      indexCard,
    });
    setOpenAlert(true);
  };

  const onClickConfirmDeleteSaving = () => {
    setAddAsset((prev) => {
      const result = [...prev];
      const index = dataIndexDeleteOne?.index;
      const indexCard = dataIndexDeleteOne?.indexCard;
      const updatedSaving = [...result[index].property_saving_lists];
      updatedSaving.splice(indexCard, 1);
      result[index] = {
        ...result[index],
        property_saving_lists: updatedSaving,
      };
      return result;
    });

    setDataIndexDeleteOne(null);
    setOpenAlert(false);
  };

  const onChangeTitleSaving = (index, indexCard, e) => {
    const value = e.target.value;
    setAddAsset((prev) => {
      let result = [...prev];
      result[index].property_saving_lists[indexCard].title = value;
      return result;
    });
  };
  const onChangeAccountNumberSaving = (index, indexCard, e) => {
    const value = e.target.value;
    setAddAsset((prev) => {
      let result = [...prev];
      result[index].property_saving_lists[indexCard].account_number = value;
      return result;
    });
  };
  const onChangeAmountSaving = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddAsset((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].property_saving_lists[indexCard].amount = "";
      } else {
        result[index].property_saving_lists[indexCard].amount = value;
      }
      return result;
    });
  };

  //---------------------------------------- บัญชีเงินฝากประจำ ----------------------------------------//
  const handleNextDeposit = (index, e) => {
    setAddAsset((prev) => {
      let result = [...prev];
      const date = new Date();
      const created = date.toISOString();
      const newList = {
        created: created,
        title: "",
        account_number: "",
        amount: "",
      };
      result[index] = {
        ...result[index],
        property_deposit_lists: [
          ...result[index].property_deposit_lists,
          { ...newList },
        ],
      };
      return result;
    });
  };

  const onClickDeleteDeposit = (index, indexCard, e) => {
    setDataIndexDeleteOne({
      index,
      indexCard,
    });
    setOpenAlert(true);
  };

  const onClickConfirmDeleteDeposit = () => {
    setAddAsset((prev) => {
      const result = [...prev];
      const index = dataIndexDeleteOne?.index;
      const indexCard = dataIndexDeleteOne?.indexCard;
      const updatedDeposit = [...result[index].property_deposit_lists];
      updatedDeposit.splice(indexCard, 1);
      result[index] = {
        ...result[index],
        property_deposit_lists: updatedDeposit,
      };
      return result;
    });

    setDataIndexDeleteOne(null);
    setOpenAlert(false);
  };

  const onChangeTitleDeposit = (index, indexCard, e) => {
    const value = e.target.value;
    setAddAsset((prev) => {
      let result = [...prev];
      result[index].property_deposit_lists[indexCard].title = value;
      return result;
    });
  };
  const onChangeAccountNumberDeposit = (index, indexCard, e) => {
    const value = e.target.value;
    setAddAsset((prev) => {
      let result = [...prev];
      result[index].property_deposit_lists[indexCard].account_number = value;
      return result;
    });
  };
  const onChangeAmountDeposit = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddAsset((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].property_deposit_lists[indexCard].amount = "";
      } else {
        result[index].property_deposit_lists[indexCard].amount = value;
      }
      return result;
    });
  };

  //---------------------------------------- เงินฝากสหกรณ์ออมทรัพย์ ----------------------------------------//
  const handleNextSavingCooperative = (index, e) => {
    setAddAsset((prev) => {
      let result = [...prev];
      const date = new Date();
      const created = date.toISOString();
      const newList = {
        created: created,
        title: "",
        account_number: "",
        amount: "",
      };
      result[index] = {
        ...result[index],
        property_saving_cooperative_lists: [
          ...result[index].property_saving_cooperative_lists,
          { ...newList },
        ],
      };
      return result;
    });
  };

  const onClickDeleteSavingCooperative = (index, indexCard, e) => {
    setDataIndexDeleteOne({
      index,
      indexCard,
    });
    setOpenAlert(true);
  };

  const onClickConfirmDeleteSavingCooperative = () => {
    setAddAsset((prev) => {
      const result = [...prev];
      const index = dataIndexDeleteOne?.index;
      const indexCard = dataIndexDeleteOne?.indexCard;
      const updatedSavingCooperative = [
        ...result[index].property_saving_cooperative_lists,
      ];
      updatedSavingCooperative.splice(indexCard, 1);
      result[index] = {
        ...result[index],
        property_saving_cooperative_lists: updatedSavingCooperative,
      };
      return result;
    });
    setDataIndexDeleteOne(null);
    setOpenAlert(false);
  };

  const onChangeTitleSavingCooperative = (index, indexCard, e) => {
    const value = e.target.value;
    setAddAsset((prev) => {
      let result = [...prev];
      result[index].property_saving_cooperative_lists[indexCard].title = value;
      return result;
    });
  };
  const onChangeAccountNumberSavingCooperative = (index, indexCard, e) => {
    const value = e.target.value;
    setAddAsset((prev) => {
      let result = [...prev];
      result[index].property_saving_cooperative_lists[
        indexCard
      ].account_number = value;
      return result;
    });
  };
  const onChangeAmountSavingCooperative = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddAsset((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].property_saving_cooperative_lists[indexCard].amount = "";
      } else {
        result[index].property_saving_cooperative_lists[indexCard].amount =
          value;
      }
      return result;
    });
  };

  //---------------------------------------- กองทุนตราสารหนี้สินระยะสั้น ----------------------------------------//
  const handleNextFundDebt = (index, e) => {
    setAddAsset((prev) => {
      let result = [...prev];
      const date = new Date();
      const created = date.toISOString();
      const newList = {
        created: created,
        title: "",
        title_fund: "",
        account_number: "",
        amount: "",
      };
      result[index] = {
        ...result[index],
        property_fund_debt_lists: [
          ...result[index].property_fund_debt_lists,
          { ...newList },
        ],
      };
      return result;
    });
  };

  const onClickDeleteFundDebt = (index, indexCard, e) => {
    setDataIndexDeleteOne({
      index,
      indexCard,
    });
    setOpenAlert(true);
  };

  const onClickConfirmDeleteFundDebt = () => {
    setAddAsset((prev) => {
      const result = [...prev];
      const index = dataIndexDeleteOne?.index;
      const indexCard = dataIndexDeleteOne?.indexCard;
      const updatedSavingCooperative = [
        ...result[index].property_fund_debt_lists,
      ];
      updatedSavingCooperative.splice(indexCard, 1);
      result[index] = {
        ...result[index],
        property_fund_debt_lists: updatedSavingCooperative,
      };
      return result;
    });
    setDataIndexDeleteOne(null);
    setOpenAlert(false);
  };

  const onChangeTitleFundDebt = (index, indexCard, e) => {
    const value = e.target.value;
    setAddAsset((prev) => {
      let result = [...prev];
      result[index].property_fund_debt_lists[indexCard].title = value;
      return result;
    });
  };
  const onChangeTitleFundFundDebt = (index, indexCard, e) => {
    const value = e.target.value;
    setAddAsset((prev) => {
      let result = [...prev];
      result[index].property_fund_debt_lists[indexCard].title_fund = value;
      return result;
    });
  };
  const onChangeAmountFundDebt = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddAsset((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].property_fund_debt_lists[indexCard].amount = "";
      } else {
        result[index].property_fund_debt_lists[indexCard].amount = value;
      }
      return result;
    });
  };

  //======================================== สินทรัพย์เพื่อการลงทุน ========================================//
  const onChangeSelectedInvest = (created, index, e) => {
    setAddInvest((prev) => {
      let value = e.value;
      const result = [...prev];

      //---- พันธบัตร/หุ้นกู้ ----//
      if (value === "property_invest_bond") {
        const findTitle = options_property_invest?.filter((item) => {
          if (item.value === value) return item;
        });
        result[index].options_property_invest = value;
        result[index].options_property_invest_title = findTitle?.[0]?.label;
        result[index].options_property_invest_category =
          findTitle?.[0]?.category;
        result[index].invest_bond_lists = [
          {
            created: created,
            title: "",
            amount: "",
            reward_amount_per_year: "",
          },
        ];
        delete result[index].invest_preferred_lists;
        delete result[index].invest_normal_lists;
        delete result[index].invest_market_lists;
        delete result[index].all_invest_lists;
        delete result[index].invest_life_lists;
        delete result[index].invest_mixed_lists;
        delete result[index].invest_provident_fund_lists;
        delete result[index].invest_other_retirement_savings_lists;
        delete result[index].invest_money_market_other_lists;
        delete result[index].invest_derivative_instruments_lists;
        delete result[index].invest_life_insurance_policy_lists;
        delete result[index].aomsin_bond_policy_lists;
        delete result[index].invest_real_estate_lists;
        delete result[index].invest_gold_cost_lists;
        delete result[index].invest_owner_lists;
        delete result[index].invest_other_lists;
        delete result[index].invest_equity_instruments_lists;
        delete result[index].property_debt_instruments_lists;
      }

      //---- หุ้นบุริมสิทธิ ----//
      if (value === "property_invest_preferred") {
        const findTitle = options_property_invest?.filter((item) => {
          if (item.value === value) return item;
        });
        result[index].options_property_invest = value;
        result[index].options_property_invest_title = findTitle?.[0]?.label;
        result[index].options_property_invest_category =
          findTitle?.[0]?.category;
        result[index].invest_preferred_lists = [
          {
            created: created,
            title: "",
            amount: "",
            reward_amount_per_year: "",
          },
        ];
        delete result[index].invest_bond_lists;
        delete result[index].invest_normal_lists;
        delete result[index].invest_market_lists;
        delete result[index].all_invest_lists;
        delete result[index].invest_life_lists;
        delete result[index].invest_mixed_lists;
        delete result[index].invest_provident_fund_lists;
        delete result[index].invest_other_retirement_savings_lists;
        delete result[index].invest_money_market_other_lists;
        delete result[index].invest_derivative_instruments_lists;
        delete result[index].invest_life_insurance_policy_lists;
        delete result[index].aomsin_bond_policy_lists;
        delete result[index].invest_real_estate_lists;
        delete result[index].invest_gold_cost_lists;
        delete result[index].invest_owner_lists;
        delete result[index].invest_other_lists;
        delete result[index].invest_equity_instruments_lists;
        delete result[index].property_debt_instruments_lists;
      }

      //---- หุ้นสามัญ ----//
      if (value === "property_invest_normal") {
        const findTitle = options_property_invest?.filter((item) => {
          if (item.value === value) return item;
        });
        result[index].options_property_invest = value;
        result[index].options_property_invest_title = findTitle?.[0]?.label;
        result[index].options_property_invest_category =
          findTitle?.[0]?.category;
        result[index].invest_normal_lists = [
          {
            created: created,
            title: "",
            amount: "",
            reward_amount_per_year: "",
          },
        ];
        delete result[index].invest_bond_lists;
        delete result[index].invest_preferred_lists;
        delete result[index].invest_market_lists;
        delete result[index].all_invest_lists;
        delete result[index].invest_life_lists;
        delete result[index].invest_mixed_lists;
        delete result[index].invest_provident_fund_lists;
        delete result[index].invest_other_retirement_savings_lists;
        delete result[index].invest_money_market_other_lists;
        delete result[index].invest_derivative_instruments_lists;
        delete result[index].invest_life_insurance_policy_lists;
        delete result[index].aomsin_bond_policy_lists;
        delete result[index].invest_real_estate_lists;
        delete result[index].invest_gold_cost_lists;
        delete result[index].invest_owner_lists;
        delete result[index].invest_other_lists;
        delete result[index].invest_equity_instruments_lists;
        delete result[index].property_debt_instruments_lists;
      }

      //---- กองทุนรวมตลาดเงิน ----//
      if (value === "property_invest_market") {
        const findTitle = options_property_invest?.filter((item) => {
          if (item.value === value) return item;
        });
        result[index].options_property_invest = value;
        result[index].options_property_invest_title = findTitle?.[0]?.label;
        result[index].options_property_invest_category =
          findTitle?.[0]?.category;
        result[index].invest_market_lists = [
          {
            created: created,
            title: "",
            amount: "",
            reward_amount_per_year: "",
          },
        ];
        delete result[index].invest_bond_lists;
        delete result[index].invest_preferred_lists;
        delete result[index].invest_normal_lists;
        delete result[index].all_invest_lists;
        delete result[index].invest_life_lists;
        delete result[index].invest_mixed_lists;
        delete result[index].invest_provident_fund_lists;
        delete result[index].invest_other_retirement_savings_lists;
        delete result[index].invest_money_market_other_lists;
        delete result[index].invest_derivative_instruments_lists;
        delete result[index].invest_life_insurance_policy_lists;
        delete result[index].aomsin_bond_policy_lists;
        delete result[index].invest_real_estate_lists;
        delete result[index].invest_gold_cost_lists;
        delete result[index].invest_owner_lists;
        delete result[index].invest_other_lists;
        delete result[index].invest_equity_instruments_lists;
        delete result[index].property_debt_instruments_lists;
      }

      //---- กองทุนรวมหุ้น ----//
      if (value === "property_all_invest") {
        const findTitle = options_property_invest?.filter((item) => {
          if (item.value === value) return item;
        });
        result[index].options_property_invest = value;
        result[index].options_property_invest_title = findTitle?.[0]?.label;
        result[index].options_property_invest_category =
          findTitle?.[0]?.category;
        result[index].all_invest_lists = [
          {
            created: created,
            title: "",
            amount: "",
            reward_amount_per_year: "",
          },
        ];
        delete result[index].invest_bond_lists;
        delete result[index].invest_preferred_lists;
        delete result[index].invest_normal_lists;
        delete result[index].invest_market_lists;
        delete result[index].invest_life_lists;
        delete result[index].invest_mixed_lists;
        delete result[index].invest_provident_fund_lists;
        delete result[index].invest_other_retirement_savings_lists;
        delete result[index].invest_money_market_other_lists;
        delete result[index].invest_derivative_instruments_lists;
        delete result[index].invest_life_insurance_policy_lists;
        delete result[index].aomsin_bond_policy_lists;
        delete result[index].invest_real_estate_lists;
        delete result[index].invest_gold_cost_lists;
        delete result[index].invest_owner_lists;
        delete result[index].invest_other_lists;
        delete result[index].invest_equity_instruments_lists;
        delete result[index].property_debt_instruments_lists;
      }

      //---- กองทุนรวมเพื่อการเลี้ยงชีพ ----//
      if (value === "property_invest_life") {
        const findTitle = options_property_invest?.filter((item) => {
          if (item.value === value) return item;
        });
        result[index].options_property_invest = value;
        result[index].options_property_invest_title = findTitle?.[0]?.label;
        result[index].options_property_invest_category =
          findTitle?.[0]?.category;
        result[index].invest_life_lists = [
          {
            created: created,
            title: "",
            amount: "",
            reward_amount_per_year: "",
          },
        ];
        delete result[index].invest_bond_lists;
        delete result[index].invest_preferred_lists;
        delete result[index].invest_normal_lists;
        delete result[index].invest_market_lists;
        delete result[index].all_invest_lists;
        delete result[index].invest_mixed_lists;
        delete result[index].invest_provident_fund_lists;
        delete result[index].invest_other_retirement_savings_lists;
        delete result[index].invest_money_market_other_lists;
        delete result[index].invest_derivative_instruments_lists;
        delete result[index].invest_life_insurance_policy_lists;
        delete result[index].aomsin_bond_policy_lists;
        delete result[index].invest_real_estate_lists;
        delete result[index].invest_gold_cost_lists;
        delete result[index].invest_owner_lists;
        delete result[index].invest_other_lists;
        delete result[index].invest_equity_instruments_lists;
        delete result[index].property_debt_instruments_lists;
      }

      //---- กองทุนรวมผสม ----//
      if (value === "property_invest_mixed") {
        const findTitle = options_property_invest?.filter((item) => {
          if (item.value === value) return item;
        });
        result[index].options_property_invest = value;
        result[index].options_property_invest_title = findTitle?.[0]?.label;
        result[index].options_property_invest_category =
          findTitle?.[0]?.category;
        result[index].invest_mixed_lists = [
          {
            created: created,
            title: "",
            amount: "",
            reward_amount_per_year: "",
          },
        ];
        delete result[index].invest_bond_lists;
        delete result[index].invest_preferred_lists;
        delete result[index].invest_normal_lists;
        delete result[index].invest_market_lists;
        delete result[index].all_invest_lists;
        delete result[index].invest_life_lists;
        delete result[index].invest_provident_fund_lists;
        delete result[index].invest_other_retirement_savings_lists;
        delete result[index].invest_money_market_other_lists;
        delete result[index].invest_derivative_instruments_lists;
        delete result[index].invest_life_insurance_policy_lists;
        delete result[index].aomsin_bond_policy_lists;
        delete result[index].invest_real_estate_lists;
        delete result[index].invest_gold_cost_lists;
        delete result[index].invest_owner_lists;
        delete result[index].invest_other_lists;
        delete result[index].invest_equity_instruments_lists;
        delete result[index].property_debt_instruments_lists;
      }

      //---- กองทุนสำรองเลี้ยงชีพ ----//
      if (value === "property_invest_provident_fund") {
        const findTitle = options_property_invest?.filter((item) => {
          if (item.value === value) return item;
        });
        result[index].options_property_invest = value;
        result[index].options_property_invest_title = findTitle?.[0]?.label;
        result[index].options_property_invest_category =
          findTitle?.[0]?.category;
        result[index].invest_provident_fund_lists = [
          {
            created: created,
            title: "",
            amount: "",
            reward_amount_per_year: "",
          },
        ];
        delete result[index].invest_bond_lists;
        delete result[index].invest_preferred_lists;
        delete result[index].invest_normal_lists;
        delete result[index].invest_market_lists;
        delete result[index].all_invest_lists;
        delete result[index].invest_life_lists;
        delete result[index].invest_mixed_lists;
        delete result[index].invest_other_retirement_savings_lists;
        delete result[index].invest_money_market_other_lists;
        delete result[index].invest_derivative_instruments_lists;
        delete result[index].invest_life_insurance_policy_lists;
        delete result[index].aomsin_bond_policy_lists;
        delete result[index].invest_real_estate_lists;
        delete result[index].invest_gold_cost_lists;
        delete result[index].invest_owner_lists;
        delete result[index].invest_other_lists;
        delete result[index].invest_equity_instruments_lists;
        delete result[index].property_debt_instruments_lists;
      }

      //---- เงินออมเพื่อวัยเกษียณอื่น ๆ ----//
      if (value === "property_invest_other_retirement_savings") {
        const findTitle = options_property_invest?.filter((item) => {
          if (item.value === value) return item;
        });
        result[index].options_property_invest = value;
        result[index].options_property_invest_title = findTitle?.[0]?.label;
        result[index].options_property_invest_category =
          findTitle?.[0]?.category;
        result[index].invest_other_retirement_savings_lists = [
          {
            created: created,
            title: "",
            amount: "",
            reward_amount_per_year: "",
          },
        ];
        delete result[index].invest_bond_lists;
        delete result[index].invest_preferred_lists;
        delete result[index].invest_normal_lists;
        delete result[index].invest_market_lists;
        delete result[index].all_invest_lists;
        delete result[index].invest_life_lists;
        delete result[index].invest_mixed_lists;
        delete result[index].invest_provident_fund_lists;
        delete result[index].invest_derivative_instruments_lists;
        delete result[index].invest_life_insurance_policy_lists;
        delete result[index].invest_money_market_other_lists;
        delete result[index].aomsin_bond_policy_lists;
        delete result[index].invest_real_estate_lists;
        delete result[index].invest_gold_cost_lists;
        delete result[index].invest_owner_lists;
        delete result[index].invest_other_lists;
        delete result[index].invest_equity_instruments_lists;
        delete result[index].property_debt_instruments_lists;
      }

      //---- ตลาดเงินอื่นๆ ----//
      if (value === "property_money_market_other") {
        const findTitle = options_property_invest?.filter((item) => {
          if (item.value === value) return item;
        });
        result[index].options_property_invest = value;
        result[index].options_property_invest_title = findTitle?.[0]?.label;
        result[index].options_property_invest_category =
          findTitle?.[0]?.category;
        result[index].invest_money_market_other_lists = [
          {
            created: created,
            title: "",
            amount: "",
            reward_amount_per_year: "",
          },
        ];
        delete result[index].invest_bond_lists;
        delete result[index].invest_preferred_lists;
        delete result[index].invest_normal_lists;
        delete result[index].invest_market_lists;
        delete result[index].all_invest_lists;
        delete result[index].invest_life_lists;
        delete result[index].invest_mixed_lists;
        delete result[index].invest_provident_fund_lists;
        delete result[index].invest_other_retirement_savings_lists;
        delete result[index].invest_derivative_instruments_lists;
        delete result[index].invest_life_insurance_policy_lists;
        delete result[index].aomsin_bond_policy_lists;
        delete result[index].invest_real_estate_lists;
        delete result[index].invest_gold_cost_lists;
        delete result[index].invest_owner_lists;
        delete result[index].invest_other_lists;
        delete result[index].invest_equity_instruments_lists;
        delete result[index].property_debt_instruments_lists;
      }

      //---- ตราสารอนุพันธ์ ----//
      if (value === "property_invest_derivative_instruments") {
        const findTitle = options_property_invest?.filter((item) => {
          if (item.value === value) return item;
        });
        result[index].options_property_invest = value;
        result[index].options_property_invest_title = findTitle?.[0]?.label;
        result[index].options_property_invest_category =
          findTitle?.[0]?.category;
        result[index].invest_derivative_instruments_lists = [
          {
            created: created,
            title: "",
            amount: "",
            reward_amount_per_year: "",
          },
        ];
        delete result[index].invest_bond_lists;
        delete result[index].invest_preferred_lists;
        delete result[index].invest_normal_lists;
        delete result[index].invest_market_lists;
        delete result[index].all_invest_lists;
        delete result[index].invest_life_lists;
        delete result[index].invest_mixed_lists;
        delete result[index].invest_provident_fund_lists;
        delete result[index].invest_other_retirement_savings_lists;
        delete result[index].invest_money_market_other_lists;
        delete result[index].invest_life_insurance_policy_lists;
        delete result[index].aomsin_bond_policy_lists;
        delete result[index].invest_real_estate_lists;
        delete result[index].invest_gold_cost_lists;
        delete result[index].invest_owner_lists;
        delete result[index].invest_other_lists;
        delete result[index].invest_equity_instruments_lists;
      }

      //---- มูลค่าเงินสดในกรมธรรม์ประกันชีวิต ----//
      if (value === "property_invest_life_insurance_policy") {
        const findTitle = options_property_invest?.filter((item) => {
          if (item.value === value) return item;
        });
        result[index].options_property_invest = value;
        result[index].options_property_invest_title = findTitle?.[0]?.label;
        result[index].options_property_invest_category =
          findTitle?.[0]?.category;
        result[index].invest_life_insurance_policy_lists = [
          {
            created: created,
            title: "",
            amount: "",
            reward_amount_per_year: "",
          },
        ];
        delete result[index].invest_bond_lists;
        delete result[index].invest_preferred_lists;
        delete result[index].invest_normal_lists;
        delete result[index].invest_market_lists;
        delete result[index].all_invest_lists;
        delete result[index].invest_life_lists;
        delete result[index].invest_mixed_lists;
        delete result[index].invest_provident_fund_lists;
        delete result[index].invest_other_retirement_savings_lists;
        delete result[index].invest_money_market_other_lists;
        delete result[index].invest_derivative_instruments_lists;
        delete result[index].aomsin_bond_policy_lists;
        delete result[index].invest_real_estate_lists;
        delete result[index].invest_gold_cost_lists;
        delete result[index].invest_owner_lists;
        delete result[index].invest_other_lists;
        delete result[index].invest_equity_instruments_lists;
      }

      //---- สลากออมสิน ----//
      if (value === "property_aomsin_bond_policy") {
        const findTitle = options_property_invest?.filter((item) => {
          if (item.value === value) return item;
        });
        result[index].options_property_invest = value;
        result[index].options_property_invest_title = findTitle?.[0]?.label;
        result[index].options_property_invest_category =
          findTitle?.[0]?.category;
        result[index].aomsin_bond_policy_lists = [
          {
            created: created,
            title: "",
            amount: "",
            reward_amount_per_year: "",
          },
        ];
        delete result[index].invest_bond_lists;
        delete result[index].invest_preferred_lists;
        delete result[index].invest_normal_lists;
        delete result[index].invest_market_lists;
        delete result[index].all_invest_lists;
        delete result[index].invest_life_lists;
        delete result[index].invest_mixed_lists;
        delete result[index].invest_provident_fund_lists;
        delete result[index].invest_other_retirement_savings_lists;
        delete result[index].invest_money_market_other_lists;
        delete result[index].invest_derivative_instruments_lists;
        delete result[index].invest_life_insurance_policy_lists;
        delete result[index].invest_real_estate_lists;
        delete result[index].invest_gold_cost_lists;
        delete result[index].invest_owner_lists;
        delete result[index].invest_other_lists;
        delete result[index].invest_equity_instruments_lists;
        delete result[index].property_debt_instruments_lists;
      }

      //---- ตราสารหนี้อื่นๆ ----//
      if (value === "property_debt_instruments") {
        const findTitle = options_property_invest?.filter((item) => {
          if (item.value === value) return item;
        });
        result[index].options_property_invest = value;
        result[index].options_property_invest_title = findTitle?.[0]?.label;
        result[index].options_property_invest_category =
          findTitle?.[0]?.category;
        result[index].property_debt_instruments_lists = [
          {
            created: created,
            title: "",
            amount: "",
            reward_amount_per_year: "",
          },
        ];
        delete result[index].invest_bond_lists;
        delete result[index].invest_preferred_lists;
        delete result[index].invest_normal_lists;
        delete result[index].invest_market_lists;
        delete result[index].all_invest_lists;
        delete result[index].invest_life_lists;
        delete result[index].invest_mixed_lists;
        delete result[index].invest_provident_fund_lists;
        delete result[index].invest_other_retirement_savings_lists;
        delete result[index].invest_money_market_other_lists;
        delete result[index].invest_derivative_instruments_lists;
        delete result[index].invest_life_insurance_policy_lists;
        delete result[index].invest_real_estate_lists;
        delete result[index].invest_gold_cost_lists;
        delete result[index].invest_owner_lists;
        delete result[index].invest_other_lists;
        delete result[index].invest_equity_instruments_lists;
      }

      //---- อสังหาริมทรัพย์ ----//
      if (value === "property_invest_real_estate") {
        const findTitle = options_property_invest?.filter((item) => {
          if (item.value === value) return item;
        });
        result[index].options_property_invest = value;
        result[index].options_property_invest_title = findTitle?.[0]?.label;
        result[index].options_property_invest_category =
          findTitle?.[0]?.category;
        // here
        result[index].invest_real_estate_lists = [
          {
            created: created,
            title: "",
            amount: "",
            reward_amount_per_year: "",
            debt_free: true,
          },
        ];
        delete result[index].invest_bond_lists;
        delete result[index].invest_preferred_lists;
        delete result[index].invest_normal_lists;
        delete result[index].invest_market_lists;
        delete result[index].all_invest_lists;
        delete result[index].invest_life_lists;
        delete result[index].invest_mixed_lists;
        delete result[index].invest_provident_fund_lists;
        delete result[index].invest_other_retirement_savings_lists;
        delete result[index].invest_money_market_other_lists;
        delete result[index].invest_derivative_instruments_lists;
        delete result[index].invest_life_insurance_policy_lists;
        delete result[index].aomsin_bond_policy_lists;
        delete result[index].invest_gold_cost_lists;
        delete result[index].invest_owner_lists;
        delete result[index].invest_other_lists;
        delete result[index].invest_equity_instruments_lists;
        delete result[index].property_debt_instruments_lists;
      }

      //---- มูลค่าทองคำ ----//
      if (value === "property_invest_gold_cost") {
        const findTitle = options_property_invest?.filter((item) => {
          if (item.value === value) return item;
        });
        result[index].options_property_invest = value;
        result[index].options_property_invest_title = findTitle?.[0]?.label;
        result[index].options_property_invest_category =
          findTitle?.[0]?.category;
        result[index].invest_gold_cost_lists = [
          {
            created: created,
            title: "",
            amount: "",
            reward_amount_per_year: "",
          },
        ];
        delete result[index].invest_bond_lists;
        delete result[index].invest_preferred_lists;
        delete result[index].invest_normal_lists;
        delete result[index].invest_market_lists;
        delete result[index].all_invest_lists;
        delete result[index].invest_life_lists;
        delete result[index].invest_mixed_lists;
        delete result[index].invest_provident_fund_lists;
        delete result[index].invest_other_retirement_savings_lists;
        delete result[index].invest_money_market_other_lists;
        delete result[index].invest_derivative_instruments_lists;
        delete result[index].invest_life_insurance_policy_lists;
        delete result[index].aomsin_bond_policy_lists;
        delete result[index].invest_real_estate_lists;
        delete result[index].invest_owner_lists;
        delete result[index].invest_other_lists;
        delete result[index].invest_equity_instruments_lists;
        delete result[index].property_debt_instruments_lists;
      }

      //---- หุ้นในส่วนที่เป็นเจ้าของ ----//
      if (value === "property_invest_owner") {
        const findTitle = options_property_invest?.filter((item) => {
          if (item.value === value) return item;
        });
        result[index].options_property_invest = value;
        result[index].options_property_invest_title = findTitle?.[0]?.label;
        result[index].options_property_invest_category =
          findTitle?.[0]?.category;
        result[index].invest_owner_lists = [
          {
            created: created,
            title: "",
            amount: "",
            reward_amount_per_year: "",
          },
        ];
        delete result[index].invest_bond_lists;
        delete result[index].invest_preferred_lists;
        delete result[index].invest_normal_lists;
        delete result[index].invest_market_lists;
        delete result[index].all_invest_lists;
        delete result[index].invest_life_lists;
        delete result[index].invest_mixed_lists;
        delete result[index].invest_provident_fund_lists;
        delete result[index].invest_other_retirement_savings_lists;
        delete result[index].invest_money_market_other_lists;
        delete result[index].invest_derivative_instruments_lists;
        delete result[index].invest_life_insurance_policy_lists;
        delete result[index].aomsin_bond_policy_lists;
        delete result[index].invest_real_estate_lists;
        delete result[index].invest_gold_cost_lists;
        delete result[index].invest_other_lists;
        delete result[index].invest_equity_instruments_lists;
        delete result[index].property_debt_instruments_lists;
      }

      //---- ตราสารทุนอื่นๆ ----//
      if (value === "property_equity_instruments") {
        const findTitle = options_property_invest?.filter((item) => {
          if (item.value === value) return item;
        });
        result[index].options_property_invest = value;
        result[index].options_property_invest_title = findTitle?.[0]?.label;
        result[index].options_property_invest_category =
          findTitle?.[0]?.category;
        result[index].invest_equity_instruments_lists = [
          {
            created: created,
            title: "",
            amount: "",
            reward_amount_per_year: "",
          },
        ];
        delete result[index].invest_bond_lists;
        delete result[index].invest_preferred_lists;
        delete result[index].invest_normal_lists;
        delete result[index].invest_market_lists;
        delete result[index].all_invest_lists;
        delete result[index].invest_life_lists;
        delete result[index].invest_mixed_lists;
        delete result[index].invest_provident_fund_lists;
        delete result[index].invest_other_retirement_savings_lists;
        delete result[index].invest_money_market_other_lists;
        delete result[index].invest_derivative_instruments_lists;
        delete result[index].invest_life_insurance_policy_lists;
        delete result[index].aomsin_bond_policy_lists;
        delete result[index].invest_real_estate_lists;
        delete result[index].invest_gold_cost_lists;
        delete result[index].invest_other_lists;
        delete result[index].property_debt_instruments_lists;
      }

      //---- เงินลงทุนอื่น ๆ ----//
      if (value === "property_invest_other") {
        const findTitle = options_property_invest?.filter((item) => {
          if (item.value === value) return item;
        });
        result[index].options_property_invest = value;
        result[index].options_property_invest_title = findTitle?.[0]?.label;
        result[index].options_property_invest_category =
          findTitle?.[0]?.category;
        result[index].invest_other_lists = [
          {
            created: created,
            title: "",
            amount: "",
            reward_amount_per_year: "",
          },
        ];
        delete result[index].invest_bond_lists;
        delete result[index].invest_preferred_lists;
        delete result[index].invest_normal_lists;
        delete result[index].invest_market_lists;
        delete result[index].all_invest_lists;
        delete result[index].invest_life_lists;
        delete result[index].invest_mixed_lists;
        delete result[index].invest_provident_fund_lists;
        delete result[index].invest_other_retirement_savings_lists;
        delete result[index].invest_money_market_other_lists;
        delete result[index].invest_derivative_instruments_lists;
        delete result[index].invest_life_insurance_policy_lists;
        delete result[index].aomsin_bond_policy_lists;
        delete result[index].invest_real_estate_lists;
        delete result[index].invest_gold_cost_lists;
        delete result[index].invest_owner_lists;
        delete result[index].invest_equity_instruments_lists;
        delete result[index].property_debt_instruments_lists;
      }

      // หนี้ภาครัฐ
      if (value === "property_invest_goverment") {
        const findTitle = options_property_invest?.filter((item) => {
          if (item.value === value) return item;
        });
        result[index].options_property_invest = value;
        result[index].options_property_invest_title = findTitle?.[0]?.label;
        result[index].options_property_invest_category =
          findTitle?.[0]?.category;
        result[index].invest_goverment_lists = [
          {
            created: created,
            title: "",
            amount: "",
            reward_amount_per_year: "",
          },
        ];
        delete result[index].invest_bond_lists;
        delete result[index].invest_preferred_lists;
        delete result[index].invest_normal_lists;
        delete result[index].invest_market_lists;
        delete result[index].all_invest_lists;
        delete result[index].invest_life_lists;
        delete result[index].invest_mixed_lists;
        delete result[index].invest_provident_fund_lists;
        delete result[index].invest_other_retirement_savings_lists;
        delete result[index].invest_money_market_other_lists;
        delete result[index].invest_derivative_instruments_lists;
        delete result[index].invest_life_insurance_policy_lists;
        delete result[index].aomsin_bond_policy_lists;
        delete result[index].invest_real_estate_lists;
        delete result[index].invest_gold_cost_lists;
        delete result[index].invest_owner_lists;
        delete result[index].invest_equity_instruments_lists;
        delete result[index].property_debt_instruments_lists;
      }

      // หนี้ภาคเอกชน
      if (value === "property_invest_private") {
        const findTitle = options_property_invest?.filter((item) => {
          if (item.value === value) return item;
        });
        result[index].options_property_invest = value;
        result[index].options_property_invest_title = findTitle?.[0]?.label;
        result[index].options_property_invest_category =
          findTitle?.[0]?.category;
        result[index].invest_private_lists = [
          {
            created: created,
            title: "",
            amount: "",
            reward_amount_per_year: "",
          },
        ];
        delete result[index].invest_bond_lists;
        delete result[index].invest_preferred_lists;
        delete result[index].invest_normal_lists;
        delete result[index].invest_market_lists;
        delete result[index].all_invest_lists;
        delete result[index].invest_life_lists;
        delete result[index].invest_mixed_lists;
        delete result[index].invest_provident_fund_lists;
        delete result[index].invest_other_retirement_savings_lists;
        delete result[index].invest_money_market_other_lists;
        delete result[index].invest_derivative_instruments_lists;
        delete result[index].invest_life_insurance_policy_lists;
        delete result[index].aomsin_bond_policy_lists;
        delete result[index].invest_real_estate_lists;
        delete result[index].invest_gold_cost_lists;
        delete result[index].invest_owner_lists;
        delete result[index].invest_equity_instruments_lists;
        delete result[index].property_debt_instruments_lists;
      }

      // เงินฝาก
      if (value === "property_invest_deposit") {
        const findTitle = options_property_invest?.filter((item) => {
          if (item.value === value) return item;
        });
        result[index].options_property_invest = value;
        result[index].options_property_invest_title = findTitle?.[0]?.label;
        result[index].options_property_invest_category =
          findTitle?.[0]?.category;
        result[index].invest_deposit_lists = [
          {
            created: created,
            title: "",
            amount: "",
            reward_amount_per_year: "",
          },
        ];
        delete result[index].invest_bond_lists;
        delete result[index].invest_preferred_lists;
        delete result[index].invest_normal_lists;
        delete result[index].invest_market_lists;
        delete result[index].all_invest_lists;
        delete result[index].invest_life_lists;
        delete result[index].invest_mixed_lists;
        delete result[index].invest_provident_fund_lists;
        delete result[index].invest_other_retirement_savings_lists;
        delete result[index].invest_money_market_other_lists;
        delete result[index].invest_derivative_instruments_lists;
        delete result[index].invest_life_insurance_policy_lists;
        delete result[index].aomsin_bond_policy_lists;
        delete result[index].invest_real_estate_lists;
        delete result[index].invest_gold_cost_lists;
        delete result[index].invest_owner_lists;
        delete result[index].invest_equity_instruments_lists;
        delete result[index].property_debt_instruments_lists;
      }

      return result;
    });
  };
  const onChangeSelectedTargetInvest = (name, index, indexCard, e) => {
    setAddInvest((prev) => {
      const result = [...prev];
      result[index][name][indexCard].target_invest = e;
      return result;
    });
  };

  const [isRealEstateDebtFreeCheck, setIsRealEstateDebtFreeCheck] =
    useState(true);

  const handleCheckboxInvestChange = (
    name,
    index,
    indexCard,
    targetName,
    e
  ) => {
    setAddInvest((prev) => {
      const result = [...prev];
      result[index][name][indexCard][targetName] = isRealEstateDebtFreeCheck;
      setIsRealEstateDebtFreeCheck(!isRealEstateDebtFreeCheck);
      return result;
    });
  };

  //---------------------------------------- พันธบัตร/หุ้นกู้ ----------------------------------------//
  const handleNextInvestBond = (index, e) => {
    setAddInvest((prev) => {
      let result = [...prev];
      const date = new Date();
      const created = date.toISOString();
      const newList = {
        created: created,
        title: "",
        amount: "",
        reward_amount_per_year: "",
      };
      result[index] = {
        ...result[index],
        invest_bond_lists: [...result[index].invest_bond_lists, { ...newList }],
      };
      return result;
    });
  };

  const onClickDeleteInvestBond = (index, indexCard, e) => {
    setDataIndexDeleteOne({
      index,
      indexCard,
    });
    setOpenAlert(true);
  };

  const onClickConfirmDeleteInvestBond = () => {
    setAddInvest((prev) => {
      const result = [...prev];
      const index = dataIndexDeleteOne?.index;
      const indexCard = dataIndexDeleteOne?.indexCard;

      const updatedInvestBond = [...result[index].invest_bond_lists];
      updatedInvestBond.splice(indexCard, 1);
      result[index] = {
        ...result[index],
        invest_bond_lists: updatedInvestBond,
      };
      return result;
    });

    setDataIndexDeleteOne(null);
    setOpenAlert(false);
  };

  const onChangeTitleInvestBond = (index, indexCard, e) => {
    const value = e.target.value;
    setAddInvest((prev) => {
      let result = [...prev];
      result[index].invest_bond_lists[indexCard].title = value;
      return result;
    });
  };
  const onChangeAmountInvestBond = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddInvest((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].invest_bond_lists[indexCard].amount = "";
      } else {
        result[index].invest_bond_lists[indexCard].amount = value;
      }
      return result;
    });
  };
  const onChangeRewardAmountPerYearInvestBound = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddInvest((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].invest_bond_lists[indexCard].reward_amount_per_year = "";
      } else {
        result[index].invest_bond_lists[indexCard].reward_amount_per_year =
          value;
      }
      return result;
    });
  };
  //---------------------------------------- หุ้นบุริมสิทธิ ----------------------------------------//
  const handleNextInvestPreferred = (index, e) => {
    setAddInvest((prev) => {
      let result = [...prev];
      const date = new Date();
      const created = date.toISOString();
      const newList = {
        created: created,
        title: "",
        amount: "",
        reward_amount_per_year: "",
      };
      result[index] = {
        ...result[index],
        invest_preferred_lists: [
          ...result[index].invest_preferred_lists,
          { ...newList },
        ],
      };
      return result;
    });
  };

  const onClickDeleteInvestPreferred = (index, indexCard, e) => {
    setDataIndexDeleteOne({
      index,
      indexCard,
    });
    setOpenAlert(true);
  };

  const onClickConfirmDeleteInvestPreferred = () => {
    setAddInvest((prev) => {
      const result = [...prev];
      const index = dataIndexDeleteOne?.index;
      const indexCard = dataIndexDeleteOne?.indexCard;
      const updatedInvestPreferred = [...result[index].invest_preferred_lists];
      updatedInvestPreferred.splice(indexCard, 1);
      result[index] = {
        ...result[index],
        invest_preferred_lists: updatedInvestPreferred,
      };
      return result;
    });
    setDataIndexDeleteOne(null);
    setOpenAlert(false);
  };

  const onChangeTitleInvestPreferred = (index, indexCard, e) => {
    const value = e.target.value;
    setAddInvest((prev) => {
      let result = [...prev];
      result[index].invest_preferred_lists[indexCard].title = value;
      return result;
    });
  };
  const onChangeAmountInvestPreferred = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddInvest((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].invest_preferred_lists[indexCard].amount = "";
      } else {
        result[index].invest_preferred_lists[indexCard].amount = value;
      }
      return result;
    });
  };
  const onChangeRewardAmountPerYearInvestPreferred = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddInvest((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].invest_preferred_lists[indexCard].reward_amount_per_year =
          "";
      } else {
        result[index].invest_preferred_lists[indexCard].reward_amount_per_year =
          value;
      }
      return result;
    });
  };
  //---------------------------------------- หุ้นสามัญ ----------------------------------------//
  const handleNextInvestNormal = (index, e) => {
    setAddInvest((prev) => {
      let result = [...prev];
      const date = new Date();
      const created = date.toISOString();
      const newList = {
        created: created,
        title: "",
        amount: "",
        reward_amount_per_year: "",
      };
      result[index] = {
        ...result[index],
        invest_normal_lists: [
          ...result[index].invest_normal_lists,
          { ...newList },
        ],
      };
      return result;
    });
  };

  const onClickDeleteInvestNormal = (index, indexCard, e) => {
    setDataIndexDeleteOne({
      index,
      indexCard,
    });
    setOpenAlert(true);
  };

  const onClickConfirmDeleteInvestNormal = () => {
    setAddInvest((prev) => {
      const result = [...prev];
      const index = dataIndexDeleteOne?.index;
      const indexCard = dataIndexDeleteOne?.indexCard;
      const updatedInvestNormal = [...result[index].invest_normal_lists];
      updatedInvestNormal.splice(indexCard, 1);
      result[index] = {
        ...result[index],
        invest_normal_lists: updatedInvestNormal,
      };
      return result;
    });
    setDataIndexDeleteOne(null);
    setOpenAlert(false);
  };

  const onChangeTitleInvestNormal = (index, indexCard, e) => {
    const value = e.target.value;
    setAddInvest((prev) => {
      let result = [...prev];
      result[index].invest_normal_lists[indexCard].title = value;
      return result;
    });
  };
  const onChangeAmountInvestNormal = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddInvest((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].invest_normal_lists[indexCard].amount = "";
      } else {
        result[index].invest_normal_lists[indexCard].amount = value;
      }
      return result;
    });
  };
  const onChangeRewardAmountPerYearInvestNormal = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddInvest((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].invest_normal_lists[indexCard].reward_amount_per_year =
          "";
      } else {
        result[index].invest_normal_lists[indexCard].reward_amount_per_year =
          value;
      }
      return result;
    });
  };
  //---------------------------------------- กองทุนรวมตลาดเงิน ----------------------------------------//
  const handleNextInvestMarket = (index, e) => {
    setAddInvest((prev) => {
      let result = [...prev];
      const date = new Date();
      const created = date.toISOString();
      const newList = {
        created: created,
        title: "",
        amount: "",
        reward_amount_per_year: "",
      };
      result[index] = {
        ...result[index],
        invest_market_lists: [
          ...result[index].invest_market_lists,
          { ...newList },
        ],
      };
      return result;
    });
  };

  const onClickDeleteInvestMarket = (index, indexCard, e) => {
    setDataIndexDeleteOne({
      index,
      indexCard,
    });
    setOpenAlert(true);
  };

  // LATEST
  const onClickConfirmDeleteInvestMarket = () => {
    setAddInvest((prev) => {
      const result = [...prev];
      const index = dataIndexDeleteOne?.index;
      const indexCard = dataIndexDeleteOne?.indexCard;

      const updatedInvestMarket =
        result[index]?.invest_market_lists?.length > 0
          ? [...result[index].invest_market_lists]
          : [];

      updatedInvestMarket.splice(indexCard, 1);
      result[index] = {
        ...result[index],
        invest_market_lists: updatedInvestMarket,
      };
      return result;
    });
    setDataIndexDeleteOne(null);
    setOpenAlert(false);
  };

  const onChangeTitleInvestMarket = (index, indexCard, e) => {
    const value = e.target.value;
    setAddInvest((prev) => {
      let result = [...prev];
      result[index].invest_market_lists[indexCard].title = value;
      return result;
    });
  };
  const onChangeAmountInvestMarket = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddInvest((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].invest_market_lists[indexCard].amount = "";
      } else {
        result[index].invest_market_lists[indexCard].amount = value;
      }
      return result;
    });
  };
  const onChangeRewardAmountPerYearInvestMarket = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddInvest((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].invest_market_lists[indexCard].reward_amount_per_year =
          "";
      } else {
        result[index].invest_market_lists[indexCard].reward_amount_per_year =
          value;
      }
      return result;
    });
  };
  //---------------------------------------- กองทุนรวมหุ้น ----------------------------------------//
  const handleNextInvestAll = (index, e) => {
    setAddInvest((prev) => {
      let result = [...prev];
      const date = new Date();
      const created = date.toISOString();
      const newList = {
        created: created,
        title: "",
        amount: "",
        reward_amount_per_year: "",
      };
      result[index] = {
        ...result[index],
        all_invest_lists: [...result[index].all_invest_lists, { ...newList }],
      };
      return result;
    });
  };

  const onClickDeleteInvestAll = (
    index,
    indexCard,
    options_property_invest,
    e
  ) => {
    console.log("hello");
    setDataIndexDeleteOne({
      index,
      indexCard,
      options_property_invest,
    });
    setOpenAlert(true);
  };

  const onClickConfirmDeleteInvestAll = () => {
    setAddInvest((prev) => {
      const result = [...prev];
      const index = dataIndexDeleteOne?.index;
      const indexCard = dataIndexDeleteOne?.indexCard;
      const updatedInvestAll = [...result[index].all_invest_lists];
      updatedInvestAll.splice(indexCard, 1);
      result[index] = {
        ...result[index],
        all_invest_lists: updatedInvestAll,
      };
      return result;
    });
    setDataIndexDeleteOne(null);
    setOpenAlert(false);
  };

  const onChangeTitleInvestAll = (index, indexCard, e) => {
    const value = e.target.value;
    setAddInvest((prev) => {
      let result = [...prev];
      result[index].all_invest_lists[indexCard].title = value;
      return result;
    });
  };
  const onChangeAmountInvestAll = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddInvest((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].all_invest_lists[indexCard].amount = "";
      } else {
        result[index].all_invest_lists[indexCard].amount = value;
      }
      return result;
    });
  };
  const onChangeRewardAmountPerYearInvestAll = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddInvest((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].all_invest_lists[indexCard].reward_amount_per_year = "";
      } else {
        result[index].all_invest_lists[indexCard].reward_amount_per_year =
          value;
      }
      return result;
    });
  };
  //---------------------------------------- กองทุนรวมเพื่อการเลี้ยงชีพ ----------------------------------------//
  const handleNextInvestLife = (index, e) => {
    setAddInvest((prev) => {
      let result = [...prev];
      const date = new Date();
      const created = date.toISOString();
      const newList = {
        created: created,
        title: "",
        amount: "",
        reward_amount_per_year: "",
      };
      result[index] = {
        ...result[index],
        invest_life_lists: [...result[index].invest_life_lists, { ...newList }],
      };
      return result;
    });
  };

  const onClickDeleteInvestLife = (index, indexCard, e) => {
    setDataIndexDeleteOne({
      index,
      indexCard,
    });
    setOpenAlert(true);
  };

  const onClickConfirmDeleteInvestLife = () => {
    setAddInvest((prev) => {
      const result = [...prev];
      const index = dataIndexDeleteOne?.index;
      const indexCard = dataIndexDeleteOne?.indexCard;
      const updatedInvestLife = [...result[index].invest_life_lists];
      updatedInvestLife.splice(indexCard, 1);
      result[index] = {
        ...result[index],
        invest_life_lists: updatedInvestLife,
      };
      return result;
    });
    setDataIndexDeleteOne(null);
    setOpenAlert(false);
  };
  const onChangeTitleInvestLife = (index, indexCard, e) => {
    const value = e.target.value;
    setAddInvest((prev) => {
      let result = [...prev];
      result[index].invest_life_lists[indexCard].title = value;
      return result;
    });
  };
  const onChangeAmountInvestLife = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddInvest((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].invest_life_lists[indexCard].amount = "";
      } else {
        result[index].invest_life_lists[indexCard].amount = value;
      }
      return result;
    });
  };
  const onChangeRewardAmountPerYearInvestLife = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddInvest((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].invest_life_lists[indexCard].reward_amount_per_year = "";
      } else {
        result[index].invest_life_lists[indexCard].reward_amount_per_year =
          value;
      }
      return result;
    });
  };
  //---------------------------------------- กองทุนรวมผสม ----------------------------------------//
  const handleNextInvestMixed = (index, e) => {
    setAddInvest((prev) => {
      let result = [...prev];
      const date = new Date();
      const created = date.toISOString();
      const newList = {
        created: created,
        title: "",
        amount: "",
        reward_amount_per_year: "",
      };
      result[index] = {
        ...result[index],
        invest_mixed_lists: [
          ...result[index].invest_mixed_lists,
          { ...newList },
        ],
      };
      return result;
    });
  };

  const onClickDeleteInvestMixed = (index, indexCard, e) => {
    setDataIndexDeleteOne({
      index,
      indexCard,
    });
    setOpenAlert(true);
  };

  const onClickConfirmDeleteInvestMixed = () => {
    setAddInvest((prev) => {
      const result = [...prev];
      const index = dataIndexDeleteOne?.index;
      const indexCard = dataIndexDeleteOne?.indexCard;
      const updatedInvestMixed = [...result[index].invest_mixed_lists];
      updatedInvestMixed.splice(indexCard, 1);
      result[index] = {
        ...result[index],
        invest_mixed_lists: updatedInvestMixed,
      };
      return result;
    });
    setDataIndexDeleteOne(null);
    setOpenAlert(false);
  };

  const onChangeTitleInvestMixed = (index, indexCard, e) => {
    const value = e.target.value;
    setAddInvest((prev) => {
      let result = [...prev];
      result[index].invest_mixed_lists[indexCard].title = value;
      return result;
    });
  };
  const onChangeAmountInvestMixed = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddInvest((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].invest_mixed_lists[indexCard].amount = "";
      } else {
        result[index].invest_mixed_lists[indexCard].amount = value;
      }
      return result;
    });
  };
  const onChangeRewardAmountPerYearInvestMixed = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddInvest((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].invest_mixed_lists[indexCard].reward_amount_per_year = "";
      } else {
        result[index].invest_mixed_lists[indexCard].reward_amount_per_year =
          value;
      }
      return result;
    });
  };
  //---------------------------------------- กองทุนสำรองเลี้ยงชีพ ----------------------------------------//
  const handleNextInvestProvidentFund = (index, e) => {
    setAddInvest((prev) => {
      let result = [...prev];
      const date = new Date();
      const created = date.toISOString();
      const newList = {
        created: created,
        title: "",
        amount: "",
        reward_amount_per_year: "",
      };
      result[index] = {
        ...result[index],
        invest_provident_fund_lists: [
          ...result[index].invest_provident_fund_lists,
          { ...newList },
        ],
      };
      return result;
    });
  };

  const onClickDeleteInvestProvidentFund = (index, indexCard, e) => {
    setDataIndexDeleteOne({
      index,
      indexCard,
    });
    setOpenAlert(true);
  };

  const onClickConfirmDeleteInvestProvidentFund = () => {
    setAddInvest((prev) => {
      const result = [...prev];
      const index = dataIndexDeleteOne?.index;
      const indexCard = dataIndexDeleteOne?.indexCard;
      const updatedInvestProvidentFund = [
        ...result[index].invest_provident_fund_lists,
      ];
      updatedInvestProvidentFund.splice(indexCard, 1);
      result[index] = {
        ...result[index],
        invest_provident_fund_lists: updatedInvestProvidentFund,
      };
      return result;
    });
    setDataIndexDeleteOne(null);
    setOpenAlert(false);
  };

  const onChangeTitleInvestProvidentFund = (index, indexCard, e) => {
    const value = e.target.value;
    setAddInvest((prev) => {
      let result = [...prev];
      result[index].invest_provident_fund_lists[indexCard].title = value;
      return result;
    });
  };
  const onChangeAmountInvestProvidentFund = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddInvest((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].invest_provident_fund_lists[indexCard].amount = "";
      } else {
        result[index].invest_provident_fund_lists[indexCard].amount = value;
      }
      return result;
    });
  };
  const onChangeRewardAmountPerYearInvestProvidentFund = (
    index,
    indexCard,
    e
  ) => {
    let value = numberWithoutComma(e.target.value);
    setAddInvest((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].invest_provident_fund_lists[
          indexCard
        ].reward_amount_per_year = "";
      } else {
        result[index].invest_provident_fund_lists[
          indexCard
        ].reward_amount_per_year = value;
      }
      return result;
    });
  };
  //---------------------------------------- เงินออมเพื่อวัยเกษียณอื่น ๆ ----------------------------------------//
  const handleNextInvestOtherRetirementSavings = (index, e) => {
    setAddInvest((prev) => {
      let result = [...prev];
      const date = new Date();
      const created = date.toISOString();
      const newList = {
        created: created,
        title: "",
        amount: "",
        reward_amount_per_year: "",
      };
      result[index] = {
        ...result[index],
        invest_other_retirement_savings_lists: [
          ...result[index].invest_other_retirement_savings_lists,
          { ...newList },
        ],
      };
      return result;
    });
  };

  const onClickDeleteInvestOtherRetirementSavings = (index, indexCard, e) => {
    setDataIndexDeleteOne({
      index,
      indexCard,
    });
    setOpenAlert(true);
  };

  const onClickConfirmDeleteInvestOtherRetirementSavings = () => {
    setAddInvest((prev) => {
      const result = [...prev];
      const index = dataIndexDeleteOne?.index;
      const indexCard = dataIndexDeleteOne?.indexCard;
      const updatedInvestOtherRetirementSavings = [
        ...result[index].invest_other_retirement_savings_lists,
      ];
      updatedInvestOtherRetirementSavings.splice(indexCard, 1);
      result[index] = {
        ...result[index],
        invest_other_retirement_savings_lists:
          updatedInvestOtherRetirementSavings,
      };
      return result;
    });
    setDataIndexDeleteOne(null);
    setOpenAlert(false);
  };

  const onChangeTitleInvestOtherRetirementSavings = (index, indexCard, e) => {
    const value = e.target.value;
    setAddInvest((prev) => {
      let result = [...prev];
      result[index].invest_other_retirement_savings_lists[indexCard].title =
        value;
      return result;
    });
  };
  const onChangeAmountInvestOtherRetirementSavings = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddInvest((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].invest_other_retirement_savings_lists[indexCard].amount =
          "";
      } else {
        result[index].invest_other_retirement_savings_lists[indexCard].amount =
          value;
      }
      return result;
    });
  };
  const onChangeRewardAmountPerYearInvestOtherRetirementSavings = (
    index,
    indexCard,
    e
  ) => {
    let value = numberWithoutComma(e.target.value);
    setAddInvest((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].invest_other_retirement_savings_lists[
          indexCard
        ].reward_amount_per_year = "";
      } else {
        result[index].invest_other_retirement_savings_lists[
          indexCard
        ].reward_amount_per_year = value;
      }
      return result;
    });
  };

  //---------------------------------------- ตลาดเงินอื่นๆ  ----------------------------------------//
  const handleNextInvestMoneyMarketOther = (index, e) => {
    setAddInvest((prev) => {
      let result = [...prev];
      const date = new Date();
      const created = date.toISOString();
      const newList = {
        created: created,
        title: "",
        amount: "",
        reward_amount_per_year: "",
      };
      result[index] = {
        ...result[index],
        invest_money_market_other_lists: [
          ...result[index].invest_money_market_other_lists,
          { ...newList },
        ],
      };
      return result;
    });
  };

  const onClickDeleteInvestMoneyMarketOther = (index, indexCard, e) => {
    setDataIndexDeleteOne({
      index,
      indexCard,
    });
    setOpenAlert(true);
  };

  const onClickConfirmDeleteInvestMoneyMarketOther = () => {
    setAddInvest((prev) => {
      const result = [...prev];
      const index = dataIndexDeleteOne?.index;
      const indexCard = dataIndexDeleteOne?.indexCard;
      const updatedInvestMoneyMarketOther = [
        ...result[index].invest_money_market_other_lists,
      ];
      updatedInvestMoneyMarketOther.splice(indexCard, 1);
      result[index] = {
        ...result[index],
        invest_money_market_other_lists: updatedInvestMoneyMarketOther,
      };
      return result;
    });
    setDataIndexDeleteOne(null);
    setOpenAlert(false);
  };

  const onChangeTitleInvestMoneyMarketOther = (index, indexCard, e) => {
    const value = e.target.value;
    setAddInvest((prev) => {
      let result = [...prev];
      result[index].invest_money_market_other_lists[indexCard].title = value;
      return result;
    });
  };
  const onChangeAmountInvestMoneyMarketOther = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddInvest((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].invest_money_market_other_lists[indexCard].amount = "";
      } else {
        result[index].invest_money_market_other_lists[indexCard].amount = value;
      }
      return result;
    });
  };
  const onChangeRewardAmountPerYearInvestMoneyMarketOther = (
    index,
    indexCard,
    e
  ) => {
    let value = numberWithoutComma(e.target.value);
    setAddInvest((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].invest_money_market_other_lists[
          indexCard
        ].reward_amount_per_year = "";
      } else {
        result[index].invest_money_market_other_lists[
          indexCard
        ].reward_amount_per_year = value;
      }
      return result;
    });
  };

  //---------------------------------------- ตราสารอนุพันธ์ ----------------------------------------//
  const handleNextInvestDerivativeInstruments = (index, e) => {
    setAddInvest((prev) => {
      let result = [...prev];
      const date = new Date();
      const created = date.toISOString();
      const newList = {
        created: created,
        title: "",
        amount: "",
        reward_amount_per_year: "",
      };
      result[index] = {
        ...result[index],
        invest_derivative_instruments_lists: [
          ...result[index].invest_derivative_instruments_lists,
          { ...newList },
        ],
      };
      return result;
    });
  };

  const onClickDeleteInvestDerivativeInstruments = (index, indexCard, e) => {
    setDataIndexDeleteOne({
      index,
      indexCard,
    });
    setOpenAlert(true);
  };

  const onClickConfirmDeleteInvestDerivativeInstruments = () => {
    setAddInvest((prev) => {
      const result = [...prev];
      const index = dataIndexDeleteOne?.index;
      const indexCard = dataIndexDeleteOne?.indexCard;
      const updatedInvestDerivativeInstruments = [
        ...result[index].invest_derivative_instruments_lists,
      ];
      updatedInvestDerivativeInstruments.splice(indexCard, 1);
      result[index] = {
        ...result[index],
        invest_derivative_instruments_lists: updatedInvestDerivativeInstruments,
      };
      return result;
    });

    setDataIndexDeleteOne(null);
    setOpenAlert(false);
  };

  const onChangeTitleInvestDerivativeInstruments = (index, indexCard, e) => {
    const value = e.target.value;
    setAddInvest((prev) => {
      let result = [...prev];
      result[index].invest_derivative_instruments_lists[indexCard].title =
        value;
      return result;
    });
  };
  const onChangeAmountInvestDerivativeInstruments = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddInvest((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].invest_derivative_instruments_lists[indexCard].amount =
          "";
      } else {
        result[index].invest_derivative_instruments_lists[indexCard].amount =
          value;
      }
      return result;
    });
  };
  const onChangeRewardAmountPerYearInvestDerivativeInstruments = (
    index,
    indexCard,
    e
  ) => {
    let value = numberWithoutComma(e.target.value);
    setAddInvest((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].invest_derivative_instruments_lists[
          indexCard
        ].reward_amount_per_year = "";
      } else {
        result[index].invest_derivative_instruments_lists[
          indexCard
        ].reward_amount_per_year = value;
      }
      return result;
    });
  };
  //---------------------------------------- มูลค่าเงินสดในกรมธรรม์ประกันชีวิต ----------------------------------------//
  const handleNextInvestLifeInsurancePolicy = (index, e) => {
    setAddInvest((prev) => {
      let result = [...prev];
      const date = new Date();
      const created = date.toISOString();
      const newList = {
        created: created,
        title: "",
        amount: "",
        reward_amount_per_year: "",
      };
      result[index] = {
        ...result[index],
        invest_life_insurance_policy_lists: [
          ...result[index].invest_life_insurance_policy_lists,
          { ...newList },
        ],
      };
      return result;
    });
  };

  const onClickDeleteInvestLifeInsurancePolicy = (index, indexCard, e) => {
    setDataIndexDeleteOne({
      index,
      indexCard,
    });
    setOpenAlert(true);
  };

  const onClickConfirmDeleteInvestLifeInsurancePolicy = () => {
    setAddInvest((prev) => {
      const result = [...prev];
      const index = dataIndexDeleteOne?.index;
      const indexCard = dataIndexDeleteOne?.indexCard;
      const updatedInvestLifeInsurancePolicy = [
        ...result[index].invest_life_insurance_policy_lists,
      ];
      updatedInvestLifeInsurancePolicy.splice(indexCard, 1);
      result[index] = {
        ...result[index],
        invest_life_insurance_policy_lists: updatedInvestLifeInsurancePolicy,
      };
      return result;
    });
    setDataIndexDeleteOne(null);
    setOpenAlert(false);
  };
  const onChangeTitleInvestLifeInsurancePolicy = (index, indexCard, e) => {
    const value = e.target.value;
    setAddInvest((prev) => {
      let result = [...prev];
      result[index].invest_life_insurance_policy_lists[indexCard].title = value;
      return result;
    });
  };
  const onChangeAmountInvestLifeInsurancePolicy = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddInvest((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].invest_life_insurance_policy_lists[indexCard].amount = "";
      } else {
        result[index].invest_life_insurance_policy_lists[indexCard].amount =
          value;
      }
      return result;
    });
  };
  const onChangeRewardAmountPerYearInvestLifeInsurancePolicy = (
    index,
    indexCard,
    e
  ) => {
    let value = numberWithoutComma(e.target.value);
    setAddInvest((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].invest_life_insurance_policy_lists[
          indexCard
        ].reward_amount_per_year = "";
      } else {
        result[index].invest_life_insurance_policy_lists[
          indexCard
        ].reward_amount_per_year = value;
      }
      return result;
    });
  };

  //---------------------------------------- สลากออมสิน ----------------------------------------//
  const handleNextInvestAomsinBondPolicy = (index, e) => {
    setAddInvest((prev) => {
      let result = [...prev];
      const date = new Date();
      const created = date.toISOString();
      const newList = {
        created: created,
        title: "",
        amount: "",
        reward_amount_per_year: "",
      };
      result[index] = {
        ...result[index],
        aomsin_bond_policy_lists: [
          ...result[index].aomsin_bond_policy_lists,
          { ...newList },
        ],
      };
      return result;
    });
  };

  const onClickDeleteInvestLifeAomsinBondPolicy = (index, indexCard, e) => {
    setDataIndexDeleteOne({
      index,
      indexCard,
    });
    setOpenAlert(true);
  };

  const onClickConfirmDeleteInvestAomsinBondPolicy = () => {
    setAddInvest((prev) => {
      const result = [...prev];
      const index = dataIndexDeleteOne?.index;
      const indexCard = dataIndexDeleteOne?.indexCard;
      const updatedInvestAomsinBondPolicy = [
        ...result[index].aomsin_bond_policy_lists,
      ];
      updatedInvestAomsinBondPolicy.splice(indexCard, 1);
      result[index] = {
        ...result[index],
        aomsin_bond_policy_lists: updatedInvestAomsinBondPolicy,
      };
      return result;
    });
    setDataIndexDeleteOne(null);
    setOpenAlert(false);
  };
  const onChangeTitleInvestLifeAomsinBondPolicy = (index, indexCard, e) => {
    const value = e.target.value;
    setAddInvest((prev) => {
      let result = [...prev];
      result[index].aomsin_bond_policy_lists[indexCard].title = value;
      return result;
    });
  };
  const onChangeAmountInvestLifeAomsinBondPolicy = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddInvest((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].aomsin_bond_policy_lists[indexCard].amount = "";
      } else {
        result[index].aomsin_bond_policy_lists[indexCard].amount = value;
      }
      return result;
    });
  };
  const onChangeRewardAmountPerYearAomsinBondPolicy = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddInvest((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].aomsin_bond_policy_lists[
          indexCard
        ].reward_amount_per_year = "";
      } else {
        result[index].aomsin_bond_policy_lists[
          indexCard
        ].reward_amount_per_year = value;
      }
      return result;
    });
  };

  //---------------------------------------- ตราสารหนี้อื่นๆ ----------------------------------------//
  const handleNextInvestPropertyDebtInstruments = (index, e) => {
    setAddInvest((prev) => {
      let result = [...prev];
      const date = new Date();
      const created = date.toISOString();
      const newList = {
        created: created,
        title: "",
        amount: "",
        reward_amount_per_year: "",
      };
      result[index] = {
        ...result[index],
        property_debt_instruments_lists: [
          ...result[index].property_debt_instruments_lists,
          { ...newList },
        ],
      };
      return result;
    });
  };

  const onClickDeleteInvestPropertyDebtInstruments = (index, indexCard, e) => {
    setDataIndexDeleteOne({
      index,
      indexCard,
    });
    setOpenAlert(true);
  };

  const onClickConfirmDeleteInvestPropertyDebtInstruments = () => {
    setAddInvest((prev) => {
      const result = [...prev];
      const index = dataIndexDeleteOne?.index;
      const indexCard = dataIndexDeleteOne?.indexCard;
      const updatedInvestAomsinBondPolicy = [
        ...result[index].property_debt_instruments_lists,
      ];
      updatedInvestAomsinBondPolicy.splice(indexCard, 1);
      result[index] = {
        ...result[index],
        property_debt_instruments_lists: updatedInvestAomsinBondPolicy,
      };
      return result;
    });
    setDataIndexDeleteOne(null);
    setOpenAlert(false);
  };

  const onChangeTitleInvestPropertyDebtInstruments = (index, indexCard, e) => {
    const value = e.target.value;
    setAddInvest((prev) => {
      let result = [...prev];
      result[index].property_debt_instruments_lists[indexCard].title = value;
      return result;
    });
  };

  const onChangeAmountInvestPropertyDebtInstruments = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddInvest((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].property_debt_instruments_lists[indexCard].amount = "";
      } else {
        result[index].property_debt_instruments_lists[indexCard].amount = value;
      }
      return result;
    });
  };

  const onChangeRewardAmountPerYearPropertyDebtInstruments = (
    index,
    indexCard,
    e
  ) => {
    let value = numberWithoutComma(e.target.value);
    setAddInvest((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].property_debt_instruments_lists[
          indexCard
        ].reward_amount_per_year = "";
      } else {
        result[index].property_debt_instruments_lists[
          indexCard
        ].reward_amount_per_year = value;
      }
      return result;
    });
  };

  //---------------------------------------- อสังหาริมทรัพย์ ----------------------------------------//
  const handleNextInvestRealEstate = (index, e) => {
    setAddInvest((prev) => {
      let result = [...prev];
      const date = new Date();
      const created = date.toISOString();
      const newList = {
        created: created,
        title: "",
        amount: "",
        reward_amount_per_year: "",
        debt_free: true,
      };
      result[index] = {
        ...result[index],
        invest_real_estate_lists: [
          ...result[index].invest_real_estate_lists,
          { ...newList },
        ],
      };
      return result;
    });
  };

  const onClickDeleteInvestRealEstate = (index, indexCard, e) => {
    setDataIndexDeleteOne({
      index,
      indexCard,
    });
    setOpenAlert(true);
  };

  const onClickConfirmDeleteInvestRealEstate = () => {
    setAddInvest((prev) => {
      const result = [...prev];
      const index = dataIndexDeleteOne?.index;
      const indexCard = dataIndexDeleteOne?.indexCard;
      const updatedInvestRealEstate = [
        ...result[index].invest_real_estate_lists,
      ];
      updatedInvestRealEstate.splice(indexCard, 1);
      result[index] = {
        ...result[index],
        invest_real_estate_lists: updatedInvestRealEstate,
      };
      return result;
    });
    setDataIndexDeleteOne(null);
    setOpenAlert(false);
  };

  const onChangeTitleInvestRealEstate = (index, indexCard, e) => {
    const value = e.target.value;
    setAddInvest((prev) => {
      let result = [...prev];
      result[index].invest_real_estate_lists[indexCard].title = value;
      return result;
    });
  };
  const onChangeAmountInvestRealEstate = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddInvest((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].invest_real_estate_lists[indexCard].amount = "";
      } else {
        result[index].invest_real_estate_lists[indexCard].amount = value;
      }
      return result;
    });
  };
  const onChangeRewardAmountPerYearInvestRealEstate = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddInvest((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].invest_real_estate_lists[
          indexCard
        ].reward_amount_per_year = "";
      } else {
        result[index].invest_real_estate_lists[
          indexCard
        ].reward_amount_per_year = value;
      }
      return result;
    });
  };
  //---------------------------------------- มูลค่าทองคำ ----------------------------------------//
  const handleNextInvestGoldCost = (index, e) => {
    setAddInvest((prev) => {
      let result = [...prev];
      const date = new Date();
      const created = date.toISOString();
      const newList = {
        created: created,
        title: "",
        amount: "",
        reward_amount_per_year: "",
      };
      result[index] = {
        ...result[index],
        invest_gold_cost_lists: [
          ...result[index].invest_gold_cost_lists,
          { ...newList },
        ],
      };
      return result;
    });
  };

  const onClickDeleteInvestGoldCost = (index, indexCard, e) => {
    setDataIndexDeleteOne({
      index,
      indexCard,
    });
    setOpenAlert(true);
  };

  const onClickConfirmDeleteInvestGoldCost = () => {
    setAddInvest((prev) => {
      const result = [...prev];
      const index = dataIndexDeleteOne?.index;
      const indexCard = dataIndexDeleteOne?.indexCard;
      const updatedInvestGoldCost = [...result[index].invest_gold_cost_lists];
      updatedInvestGoldCost.splice(indexCard, 1);
      result[index] = {
        ...result[index],
        invest_gold_cost_lists: updatedInvestGoldCost,
      };
      return result;
    });
    setDataIndexDeleteOne(null);
    setOpenAlert(false);
  };

  const onChangeTitleInvestGoldCost = (index, indexCard, e) => {
    const value = e.target.value;
    setAddInvest((prev) => {
      let result = [...prev];
      result[index].invest_gold_cost_lists[indexCard].title = value;
      return result;
    });
  };
  const onChangeAmountInvestGoldCost = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddInvest((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].invest_gold_cost_lists[indexCard].amount = "";
      } else {
        result[index].invest_gold_cost_lists[indexCard].amount = value;
      }
      return result;
    });
  };
  const onChangeRewardAmountPerYearInvestGoldCost = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddInvest((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].invest_gold_cost_lists[indexCard].reward_amount_per_year =
          "";
      } else {
        result[index].invest_gold_cost_lists[indexCard].reward_amount_per_year =
          value;
      }
      return result;
    });
  };

  //---------------------------------------- หุ้นในส่วนที่เป็นเจ้าของ ----------------------------------------//
  const handleNextInvestOwner = (index, e) => {
    setAddInvest((prev) => {
      let result = [...prev];
      const date = new Date();
      const created = date.toISOString();
      const newList = {
        created: created,
        title: "",
        amount: "",
        reward_amount_per_year: "",
      };
      result[index] = {
        ...result[index],
        invest_owner_lists: [
          ...result[index].invest_owner_lists,
          { ...newList },
        ],
      };
      return result;
    });
  };

  const onClickDeleteInvestOwner = (index, indexCard, e) => {
    setDataIndexDeleteOne({
      index,
      indexCard,
    });
    setOpenAlert(true);
  };

  const onClickConfirmDeleteInvestOwner = () => {
    setAddInvest((prev) => {
      const result = [...prev];
      const index = dataIndexDeleteOne?.index;
      const indexCard = dataIndexDeleteOne?.indexCard;
      const updatedInvestOwner = [...result[index].invest_owner_lists];
      updatedInvestOwner.splice(indexCard, 1);
      result[index] = {
        ...result[index],
        invest_owner_lists: updatedInvestOwner,
      };
      return result;
    });
    setDataIndexDeleteOne(null);
    setOpenAlert(false);
  };
  const onChangeTitleInvestOwner = (index, indexCard, e) => {
    const value = e.target.value;
    setAddInvest((prev) => {
      let result = [...prev];
      result[index].invest_owner_lists[indexCard].title = value;
      return result;
    });
  };
  const onChangeAmountInvestOwner = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddInvest((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].invest_owner_lists[indexCard].amount = "";
      } else {
        result[index].invest_owner_lists[indexCard].amount = value;
      }
      return result;
    });
  };
  const onChangeRewardAmountPerYearInvestOwner = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddInvest((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].invest_owner_lists[indexCard].reward_amount_per_year = "";
      } else {
        result[index].invest_owner_lists[indexCard].reward_amount_per_year =
          value;
      }
      return result;
    });
  };

  //---------------------------------------- ตราสารทุนอื่นๆ ----------------------------------------//
  const handleNextInvestPropertyEquityInstruments = (index, e) => {
    setAddInvest((prev) => {
      let result = [...prev];
      const date = new Date();
      const created = date.toISOString();
      const newList = {
        created: created,
        title: "",
        amount: "",
        reward_amount_per_year: "",
      };
      result[index] = {
        ...result[index],
        invest_equity_instruments_lists: [
          ...result[index].invest_equity_instruments_lists,
          { ...newList },
        ],
      };
      return result;
    });
  };

  const onClickDeletePropertyEquityInstruments = (index, indexCard, e) => {
    setDataIndexDeleteOne({
      index,
      indexCard,
    });
    setOpenAlert(true);
  };

  const onClickConfirmDeletePropertyEquityInstruments = () => {
    setAddInvest((prev) => {
      const result = [...prev];
      const index = dataIndexDeleteOne?.index;
      const indexCard = dataIndexDeleteOne?.indexCard;
      const updatedInvestOwner = [
        ...result[index].invest_equity_instruments_lists,
      ];
      updatedInvestOwner.splice(indexCard, 1);
      result[index] = {
        ...result[index],
        invest_equity_instruments_lists: updatedInvestOwner,
      };
      return result;
    });
    setDataIndexDeleteOne(null);
    setOpenAlert(false);
  };

  const onChangeTitlePropertyEquityInstruments = (index, indexCard, e) => {
    const value = e.target.value;
    setAddInvest((prev) => {
      let result = [...prev];
      result[index].invest_equity_instruments_lists[indexCard].title = value;
      return result;
    });
  };
  const onChangeAmountPropertyEquityInstruments = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddInvest((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].invest_equity_instruments_lists[indexCard].amount = "";
      } else {
        result[index].invest_equity_instruments_lists[indexCard].amount = value;
      }
      return result;
    });
  };

  const onChangeAmountPerYearPropertyEquityInstruments = (
    index,
    indexCard,
    e
  ) => {
    let value = numberWithoutComma(e.target.value);
    setAddInvest((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].invest_equity_instruments_lists[indexCard].amount = "";
      } else {
        result[index].invest_equity_instruments_lists[indexCard].amount = value;
      }
      return result;
    });
  };

  //---------------------------------------- เงินลงทุนอื่น ๆ ----------------------------------------//
  const handleNextInvestOther = (index, e) => {
    setAddInvest((prev) => {
      let result = [...prev];
      const date = new Date();
      const created = date.toISOString();
      const newList = {
        created: created,
        title: "",
        amount: "",
        reward_amount_per_year: "",
      };
      result[index] = {
        ...result[index],
        invest_other_lists: [
          ...result[index].invest_other_lists,
          { ...newList },
        ],
      };
      return result;
    });
  };

  const onClickDeleteInvestOther = (index, indexCard, e) => {
    setDataIndexDeleteOne({
      index,
      indexCard,
    });
    setOpenAlert(true);
  };

  const onClickConfirmDeleteInvestOther = () => {
    setAddInvest((prev) => {
      const result = [...prev];
      const index = dataIndexDeleteOne?.index;
      const indexCard = dataIndexDeleteOne?.indexCard;
      const updatedInvestOther = [...result[index].invest_other_lists];
      updatedInvestOther.splice(indexCard, 1);
      result[index] = {
        ...result[index],
        invest_other_lists: updatedInvestOther,
      };
      return result;
    });
    setDataIndexDeleteOne(null);
    setOpenAlert(false);
  };

  const onChangeTitleInvestOther = (index, indexCard, e) => {
    const value = e.target.value;
    setAddInvest((prev) => {
      let result = [...prev];
      result[index].invest_other_lists[indexCard].title = value;
      return result;
    });
  };
  const onChangeAmountInvestOther = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddInvest((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].invest_other_lists[indexCard].amount = "";
      } else {
        result[index].invest_other_lists[indexCard].amount = value;
      }
      return result;
    });
  };
  const onChangeRewardAmountPerYearInvestOther = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddInvest((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].invest_other_lists[indexCard].reward_amount_per_year = "";
      } else {
        result[index].invest_other_lists[indexCard].reward_amount_per_year =
          value;
      }
      return result;
    });
  };

  const onChangeRewardInsurance = (row, title, created, value) => {
    let whereLifeInsuranceOrder = tempBalance?.expenses_fixed_list?.findIndex(
      (item) => item?.options_expenses_fixed === "expenses_insuarant_life"
    );

    let whichLifeInsuranceOrder = tempBalance?.expenses_fixed_list[
      whereLifeInsuranceOrder
    ]?.expenses_insuarant_lifes?.findIndex(
      (item) => item?.created === created && item?.title === title
    );

    let tempExpenseList = tempBalance;
    tempExpenseList.expenses_fixed_list[
      whereLifeInsuranceOrder
    ].expenses_insuarant_lifes[whichLifeInsuranceOrder].reward_amount_per_year =
      value;
    setTempBalance({ ...tempExpenseList });
  };

  const onChangeSelectedInsuranceTargetInvest = (
    row,
    title,
    created,
    value
  ) => {
    let whereLifeInsuranceOrder = tempBalance?.expenses_fixed_list?.findIndex(
      (item) => item?.options_expenses_fixed === "expenses_insuarant_life"
    );

    let whichLifeInsuranceOrder = tempBalance?.expenses_fixed_list[
      whereLifeInsuranceOrder
    ]?.expenses_insuarant_lifes?.findIndex(
      (item) => item?.created === created && item?.title === title
    );

    let tempExpenseList = tempBalance;
    tempExpenseList.expenses_fixed_list[
      whereLifeInsuranceOrder
    ].expenses_insuarant_lifes[whichLifeInsuranceOrder].target_invest = value;
    setTempBalance({ ...tempExpenseList });
  };

  // console.log("tempBalance",tempBalance)

  // หนี้ภาครัฐ //
  const handleNextInvestGoverment = (index, e) => {
    setAddInvest((prev) => {
      let result = [...prev];
      const date = new Date();
      const created = date.toISOString();
      const newList = {
        created: created,
        title: "",
        amount: "",
        reward_amount_per_year: "",
      };
      result[index] = {
        ...result[index],
        invest_goverment_lists: [
          ...result[index].invest_goverment_lists,
          { ...newList },
        ],
      };
      return result;
    });
  };

  const onClickDeleteInvestGoverment = (index, indexCard, e) => {
    setDataIndexDeleteOne({
      index,
      indexCard,
    });
    setOpenAlert(true);
  };

  const onClickConfirmDeleteInvestGorverment = () => {
    setAddInvest((prev) => {
      const result = [...prev];
      const index = dataIndexDeleteOne?.index;
      const indexCard = dataIndexDeleteOne?.indexCard;
      const updatedInvestOther = [...result[index].invest_goverment_lists];
      updatedInvestOther.splice(indexCard, 1);
      result[index] = {
        ...result[index],
        invest_goverment_lists: updatedInvestOther,
      };
      return result;
    });
    setDataIndexDeleteOne(null);
    setOpenAlert(false);
  };

  const onChangeTitleInvestGoverment = (index, indexCard, e) => {
    const value = e.target.value;
    setAddInvest((prev) => {
      let result = [...prev];
      result[index].invest_goverment_lists[indexCard].title = value;
      return result;
    });
  };
  const onChangeAmountInvestGoverment = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddInvest((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].invest_goverment_lists[indexCard].amount = "";
      } else {
        result[index].invest_goverment_lists[indexCard].amount = value;
      }
      return result;
    });
  };
  const onChangeRewardAmountPerYearInvestGoverment = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddInvest((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].invest_goverment_lists[indexCard].reward_amount_per_year =
          "";
      } else {
        result[index].invest_goverment_lists[indexCard].reward_amount_per_year =
          value;
      }
      return result;
    });
  };

  // หนี้ภาคเอกชน //
  const handleNextInvestPrivate = (index, e) => {
    setAddInvest((prev) => {
      let result = [...prev];
      const date = new Date();
      const created = date.toISOString();
      const newList = {
        created: created,
        title: "",
        amount: "",
        reward_amount_per_year: "",
      };
      result[index] = {
        ...result[index],
        invest_private_lists: [
          ...result[index].invest_private_lists,
          { ...newList },
        ],
      };
      return result;
    });
  };

  const onClickDeleteInvestPrivate = (index, indexCard, e) => {
    setDataIndexDeleteOne({
      index,
      indexCard,
    });
    setOpenAlert(true);
  };

  const onClickConfirmDeleteInvestPrivate = () => {
    setAddInvest((prev) => {
      const result = [...prev];
      const index = dataIndexDeleteOne?.index;
      const indexCard = dataIndexDeleteOne?.indexCard;
      const updatedInvestOther = [...result[index].invest_private_lists];
      updatedInvestOther.splice(indexCard, 1);
      result[index] = {
        ...result[index],
        invest_private_lists: updatedInvestOther,
      };
      return result;
    });
    setDataIndexDeleteOne(null);
    setOpenAlert(false);
  };

  const onChangeTitleInvestPrivate = (index, indexCard, e) => {
    const value = e.target.value;
    setAddInvest((prev) => {
      let result = [...prev];
      result[index].invest_private_lists[indexCard].title = value;
      return result;
    });
  };
  const onChangeAmountInvestPrivate = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddInvest((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].invest_private_lists[indexCard].amount = "";
      } else {
        result[index].invest_private_lists[indexCard].amount = value;
      }
      return result;
    });
  };
  const onChangeRewardAmountPerYearInvestPrivate = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddInvest((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].invest_private_lists[indexCard].reward_amount_per_year =
          "";
      } else {
        result[index].invest_private_lists[indexCard].reward_amount_per_year =
          value;
      }
      return result;
    });
  };

  // เงินฝาก
  const handleNextInvestDeposit = (index, e) => {
    setAddInvest((prev) => {
      let result = [...prev];
      const date = new Date();
      const created = date.toISOString();
      const newList = {
        created: created,
        title: "",
        amount: "",
        reward_amount_per_year: "",
      };
      result[index] = {
        ...result[index],
        invest_deposit_lists: [
          ...result[index].invest_deposit_lists,
          { ...newList },
        ],
      };
      return result;
    });
  };

  const onClickDeleteInvestDeposit = (index, indexCard, e) => {
    setDataIndexDeleteOne({
      index,
      indexCard,
    });
    setOpenAlert(true);
  };

  const onClickConfirmDeleteInvestDeposit = () => {
    setAddInvest((prev) => {
      const result = [...prev];
      const index = dataIndexDeleteOne?.index;
      const indexCard = dataIndexDeleteOne?.indexCard;
      const updatedInvestOther = [...result[index].invest_deposit_lists];
      updatedInvestOther.splice(indexCard, 1);
      result[index] = {
        ...result[index],
        invest_deposit_lists: updatedInvestOther,
      };
      return result;
    });
    setDataIndexDeleteOne(null);
    setOpenAlert(false);
  };

  const onChangeTitleInvestDeposit = (index, indexCard, e) => {
    const value = e.target.value;
    setAddInvest((prev) => {
      let result = [...prev];
      result[index].invest_deposit_lists[indexCard].title = value;
      return result;
    });
  };
  const onChangeAmountInvestDeposit = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddInvest((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].invest_deposit_lists[indexCard].amount = "";
      } else {
        result[index].invest_deposit_lists[indexCard].amount = value;
      }
      return result;
    });
  };
  const onChangeRewardAmountPerYearInvestDeposit = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddInvest((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].invest_deposit_lists[indexCard].reward_amount_per_year =
          "";
      } else {
        result[index].invest_deposit_lists[indexCard].reward_amount_per_year =
          value;
      }
      return result;
    });
  };

  //======================================== สินทรัพย์ใช้ส่วนตัว ========================================//
  const onChangeSelectedPrivate = (created, index, e) => {
    setAddPrivate((prev) => {
      let value = e.value;
      const result = [...prev];

      //---- เครื่องประดับ ----//
      if (value === "property_accessories") {
        const findTitle = options_property_private?.filter((item) => {
          if (item.value === value) return item;
        });
        result[index].options_property_private = value;
        result[index].options_property_private_title = findTitle?.[0]?.label;
        result[index].accessories_lists = [
          {
            created: created,
            title: "",
            amount: "",
            debt_free: true,
          },
        ];
        delete result[index].car_lists;
        delete result[index].home_lists;
        delete result[index].collection_lists;
        delete result[index].other_lists;
      }

      //---- รถยนต์ ----//
      if (value === "property_car") {
        const findTitle = options_property_private?.filter((item) => {
          if (item.value === value) return item;
        });
        result[index].options_property_private = value;
        result[index].options_property_private_title = findTitle?.[0]?.label;
        result[index].car_lists = [
          {
            created: created,
            title: "",
            registration_car: "",
            amount: "",
            debt_free: true,
          },
        ];
        delete result[index].accessories_lists;
        delete result[index].home_lists;
        delete result[index].collection_lists;
        delete result[index].other_lists;
      }

      //---- บ้านที่อยู่อาศัย ----//
      if (value === "property_home") {
        const findTitle = options_property_private?.filter((item) => {
          if (item.value === value) return item;
        });
        result[index].options_property_private = value;
        result[index].options_property_private_title = findTitle?.[0]?.label;
        result[index].home_lists = [
          {
            created: created,
            title: "",
            address: "",
            type_place: "",
            address_other: "",
            amount: "",
            debt_free: true,
          },
        ];
        delete result[index].accessories_lists;
        delete result[index].car_lists;
        delete result[index].collection_lists;
        delete result[index].other_lists;
      }

      //---- ของสะสมมีราคา ----//
      if (value === "property_collection") {
        const findTitle = options_property_private?.filter((item) => {
          if (item.value === value) return item;
        });
        result[index].options_property_private = value;
        result[index].options_property_private_title = findTitle?.[0]?.label;
        result[index].collection_lists = [
          {
            created: created,
            title: "",
            amount: "",
            debt_free: true,
          },
        ];
        delete result[index].accessories_lists;
        delete result[index].car_lists;
        delete result[index].home_lists;
        delete result[index].other_lists;
      }

      //---- อื่น ๆ ----//
      if (value === "property_other") {
        const findTitle = options_property_private?.filter((item) => {
          if (item.value === value) return item;
        });
        result[index].options_property_private = value;
        result[index].options_property_private_title = findTitle?.[0]?.label;
        result[index].other_lists = [
          {
            created: created,
            title: "",
            amount: "",
            debt_free: true,
          },
        ];
        delete result[index].accessories_lists;
        delete result[index].car_lists;
        delete result[index].home_lists;
        delete result[index].collection_lists;
      }

      return result;
    });
  };
  //---------------------------------------- เครื่องประดับ ----------------------------------------//
  const handleNextAccessories = (index, e) => {
    setAddPrivate((prev) => {
      let result = [...prev];
      const date = new Date();
      const created = date.toISOString();
      const newList = {
        created: created,
        title: "",
        amount: "",
        debt_free: true,
      };
      result[index] = {
        ...result[index],
        accessories_lists: [...result[index].accessories_lists, { ...newList }],
      };
      return result;
    });
  };

  const onClickDeleteAccessories = (index, indexCard, e) => {
    setDataIndexDeleteOne({
      index,
      indexCard,
    });
    setOpenAlert(true);
  };

  const onClickConfirmDeleteAccessories = () => {
    setAddPrivate((prev) => {
      const result = [...prev];
      const index = dataIndexDeleteOne?.index;
      const indexCard = dataIndexDeleteOne?.indexCard;
      const updatedAccessories = [...result[index].accessories_lists];
      updatedAccessories.splice(indexCard, 1);
      result[index] = {
        ...result[index],
        accessories_lists: updatedAccessories,
      };
      return result;
    });

    setDataIndexDeleteOne(null);
    setOpenAlert(false);
  };

  const onChangeTitleAccessories = (index, indexCard, e) => {
    const value = e.target.value;
    setAddPrivate((prev) => {
      let result = [...prev];
      result[index].accessories_lists[indexCard].title = value;
      return result;
    });
  };
  const onChangeAmountAccessories = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddPrivate((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].accessories_lists[indexCard].amount = "";
      } else {
        result[index].accessories_lists[indexCard].amount = value;
      }
      return result;
    });
  };
  // ปลอดภาระหนี้
  const [isCheckedDebtFreeAccessories, setIsCheckedDebtFreeAccessories] =
    useState(false);
  const handleCheckboxChangeDebtFreeAccessories = (index, indexCard, e) => {
    setAddPrivate((prev) => {
      const result = [...prev];
      result[index].accessories_lists[indexCard].debt_free =
        !isCheckedDebtFreeAccessories;
      return result;
    });
    setIsCheckedDebtFreeAccessories(!isCheckedDebtFreeAccessories);
  };
  //---------------------------------------- รถยนต์ ----------------------------------------//
  const handleNextCar = (index, e) => {
    setAddPrivate((prev) => {
      let result = [...prev];
      const date = new Date();
      const created = date.toISOString();
      const newList = {
        created: created,
        title: "",
        registration_car: "",
        amount: "",
        debt_free: true,
      };
      result[index] = {
        ...result[index],
        car_lists: [...result[index].car_lists, { ...newList }],
      };
      return result;
    });
  };

  const onClickDeleteCar = (index, indexCard, e) => {
    setDataIndexDeleteOne({
      index,
      indexCard,
    });
    setOpenAlert(true);
  };

  const onClickConfirmDeleteCar = () => {
    setAddPrivate((prev) => {
      const result = [...prev];
      const index = dataIndexDeleteOne?.index;
      const indexCard = dataIndexDeleteOne?.indexCard;
      const updatedCar = [...result[index].car_lists];
      updatedCar.splice(indexCard, 1);
      result[index] = {
        ...result[index],
        car_lists: updatedCar,
      };
      return result;
    });
    setDataIndexDeleteOne(null);
    setOpenAlert(false);
  };
  const onChangeTitleCar = (index, indexCard, e) => {
    const value = e.target.value;
    setAddPrivate((prev) => {
      let result = [...prev];
      result[index].car_lists[indexCard].title = value;
      return result;
    });
  };
  const onChangeRegistrationCar = (index, indexCard, e) => {
    const value = e.target.value;
    setAddPrivate((prev) => {
      let result = [...prev];
      result[index].car_lists[indexCard].registration_car = value;
      return result;
    });
  };
  const onChangeAmountCar = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddPrivate((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].car_lists[indexCard].amount = "";
      } else {
        result[index].car_lists[indexCard].amount = value;
      }
      return result;
    });
  };
  // ปลอดภาระหนี้
  const [isCheckedDebtFreeCar, setIsCheckedDebtFreeCar] = useState(false);
  const handleCheckboxChangeDebtFreeCar = (index, indexCard, e) => {
    setAddPrivate((prev) => {
      const result = [...prev];
      result[index].car_lists[indexCard].debt_free = !isCheckedDebtFreeCar;
      return result;
    });
    setIsCheckedDebtFreeCar(!isCheckedDebtFreeCar);
  };
  //---------------------------------------- ที่อยู่ ----------------------------------------//
  const handleNextHome = (index, e) => {
    setAddPrivate((prev) => {
      let result = [...prev];
      const date = new Date();
      const created = date.toISOString();
      const newList = {
        created: created,
        title: "",
        address: "",
        type_place: "",
        address_other: "",
        amount: "",
        debt_free: false,
      };
      result[index] = {
        ...result[index],
        home_lists: [...result[index].home_lists, { ...newList }],
      };
      return result;
    });
  };

  const onClickDeleteHome = (index, indexCard, e) => {
    setDataIndexDeleteOne({
      index,
      indexCard,
    });
    setOpenAlert(true);
  };

  const onClickComfirmDeleteHome = () => {
    setAddPrivate((prev) => {
      const result = [...prev];
      const index = dataIndexDeleteOne?.index;
      const indexCard = dataIndexDeleteOne?.indexCard;
      const updatedHome = [...result[index].home_lists];
      updatedHome.splice(indexCard, 1);
      result[index] = {
        ...result[index],
        home_lists: updatedHome,
      };
      return result;
    });
    setDataIndexDeleteOne(null);
    setOpenAlert(false);
  };
  const onChangeTitleHome = (index, indexCard, e) => {
    const value = e.target.value;
    setAddPrivate((prev) => {
      let result = [...prev];
      result[index].home_lists[indexCard].title = value;
      return result;
    });
  };
  const onChangeAddressHome = (index, indexCard, e) => {
    const value = e.target.value;
    setAddPrivate((prev) => {
      let result = [...prev];
      result[index].home_lists[indexCard].address = value;
      return result;
    });
  };
  const onChangeAmountHome = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddPrivate((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].home_lists[indexCard].amount = "";
      } else {
        result[index].home_lists[indexCard].amount = value;
      }
      return result;
    });
  };
  // ประเภทที่อยู่
  const handleCheckboxChangeTypeHome = (index, indexCard, e) => {
    setAddPrivate((prev) => {
      let value = e.target.value;
      const result = [...prev];
      result[index].home_lists[indexCard].type_place = value;
      return result;
    });
  };
  const onChangeAddressOtherHome = (index, indexCard, e) => {
    const value = e.target.value;
    setAddPrivate((prev) => {
      let result = [...prev];
      result[index].home_lists[indexCard].address_other = value;
      return result;
    });
  };
  // ปลอดภาระหนี้
  const [isCheckedDebtFreeHome, setIsCheckedDebtFreeHome] = useState(false);
  const handleCheckboxChangeDebtFreeHome = (index, indexCard, e) => {
    setAddPrivate((prev) => {
      const result = [...prev];
      result[index].home_lists[indexCard].debt_free = !isCheckedDebtFreeHome;
      return result;
    });
    setIsCheckedDebtFreeHome(!isCheckedDebtFreeHome);
  };
  //---------------------------------------- ของสะสมมีราคา ----------------------------------------//
  const handleNextCollection = (index, e) => {
    setAddPrivate((prev) => {
      let result = [...prev];
      const date = new Date();
      const created = date.toISOString();
      const newList = {
        created: created,
        title: "",
        amount: "",
        debt_free: false,
      };
      result[index] = {
        ...result[index],
        collection_lists: [...result[index].collection_lists, { ...newList }],
      };
      return result;
    });
  };

  const onClickDeleteCollection = (index, indexCard, e) => {
    setDataIndexDeleteOne({
      index,
      indexCard,
    });
    setOpenAlert(true);
  };

  const onClickConfirmDeleteCollection = () => {
    setAddPrivate((prev) => {
      const result = [...prev];
      const index = dataIndexDeleteOne?.index;
      const indexCard = dataIndexDeleteOne?.indexCard;
      const updatedCollection = [...result[index].collection_lists];
      updatedCollection.splice(indexCard, 1);
      result[index] = {
        ...result[index],
        collection_lists: updatedCollection,
      };
      return result;
    });
    setDataIndexDeleteOne(null);
    setOpenAlert(false);
  };
  const onChangeTitleCollection = (index, indexCard, e) => {
    const value = e.target.value;
    setAddPrivate((prev) => {
      let result = [...prev];
      result[index].collection_lists[indexCard].title = value;
      return result;
    });
  };
  const onChangeAmountCollection = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddPrivate((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].collection_lists[indexCard].amount = "";
      } else {
        result[index].collection_lists[indexCard].amount = value;
      }
      return result;
    });
  };
  // ปลอดภาระหนี้
  const [isCheckedDebtFreeCollection, setIsCheckedDebtFreeCollection] =
    useState(false);
  const handleCheckboxChangeDebtFreeCollection = (index, indexCard, e) => {
    setAddPrivate((prev) => {
      const result = [...prev];
      result[index].collection_lists[indexCard].debt_free =
        !isCheckedDebtFreeCollection;
      return result;
    });
    setIsCheckedDebtFreeCollection(!isCheckedDebtFreeCollection);
  };
  //---------------------------------------- อื่น ๆ ----------------------------------------//
  const handleNextOther = (index, e) => {
    setAddPrivate((prev) => {
      let result = [...prev];
      const date = new Date();
      const created = date.toISOString();
      const newList = {
        created: created,
        title: "",
        amount: "",
        debt_free: false,
      };
      result[index] = {
        ...result[index],
        other_lists: [...result[index].other_lists, { ...newList }],
      };
      return result;
    });
  };

  const onClickDeleteOther = (index, indexCard, e) => {
    setDataIndexDeleteOne({
      index,
      indexCard,
    });
    setOpenAlert(true);
  };

  const onClickConfirmDeleteOther = () => {
    setAddPrivate((prev) => {
      const result = [...prev];
      const index = dataIndexDeleteOne?.index;
      const indexCard = dataIndexDeleteOne?.indexCard;
      const updatedOther = [...result[index].other_lists];
      updatedOther.splice(indexCard, 1);
      result[index] = {
        ...result[index],
        other_lists: updatedOther,
      };
      return result;
    });
    setDataIndexDeleteOne(null);
    setOpenAlert(false);
  };

  const onChangeTitleOther = (index, indexCard, e) => {
    const value = e.target.value;
    setAddPrivate((prev) => {
      let result = [...prev];
      result[index].other_lists[indexCard].title = value;
      return result;
    });
  };
  const onChangeAmountOther = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddPrivate((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].other_lists[indexCard].amount = "";
      } else {
        result[index].other_lists[indexCard].amount = value;
      }
      return result;
    });
  };
  // ปลอดภาระหนี้
  const [isCheckedDebtFreeOther, setIsCheckedDebtFreeOther] = useState(false);
  const handleCheckboxChangeDebtFreeOther = (index, indexCard, e) => {
    setAddPrivate((prev) => {
      const result = [...prev];
      result[index].other_lists[indexCard].debt_free = !isCheckedDebtFreeOther;
      return result;
    });
    setIsCheckedDebtFreeOther(!isCheckedDebtFreeOther);
  };

  //======================================== สินทรัพย์ไม่มีตัวตน ========================================//
  const onChangeSelectedIntangible = (created, index, e) => {
    setAddIntangible((prev) => {
      let value = e.value;
      const result = [...prev];

      //---- ลิขสิทธิ ----//
      if (value === "property_copyright") {
        const findTitle = options_property_intangible?.filter((item) => {
          if (item.value === value) return item;
        });
        result[index].options_property_intangible = value;
        result[index].options_property_intangible_title = findTitle?.[0]?.label;
        result[index].copyright_lists = [
          {
            created: created,
            title: "",
            amount: "",
            debt_free: false,
          },
        ];
        delete result[index].patent_lists;
        delete result[index].intangible_other_lists;
      }

      //---- สิทธิบัตร ----//
      if (value === "property_patent") {
        const findTitle = options_property_intangible?.filter((item) => {
          if (item.value === value) return item;
        });
        result[index].options_property_intangible = value;
        result[index].options_property_intangible_title = findTitle?.[0]?.label;
        result[index].patent_lists = [
          {
            created: created,
            title: "",
            amount: "",
            debt_free: false,
          },
        ];
        delete result[index].copyright_lists;
        delete result[index].intangible_other_lists;
      }

      //---- อื่น ๆ ----//
      if (value === "property_intangible_other") {
        const findTitle = options_property_intangible?.filter((item) => {
          if (item.value === value) return item;
        });
        result[index].options_property_intangible = value;
        result[index].options_property_intangible_title = findTitle?.[0]?.label;
        result[index].intangible_other_lists = [
          {
            created: created,
            title: "",
            amount: "",
            debt_free: false,
          },
        ];
        delete result[index].copyright_lists;
        delete result[index].patent_lists;
      }

      return result;
    });
  };
  //---------------------------------------- ลิขสิทธิ ----------------------------------------//
  const handleNextCopyright = (index, e) => {
    setAddIntangible((prev) => {
      let result = [...prev];
      const date = new Date();
      const created = date.toISOString();
      const newList = {
        created: created,
        title: "",
        amount: "",
        debt_free: false,
      };
      result[index] = {
        ...result[index],
        copyright_lists: [...result[index].copyright_lists, { ...newList }],
      };
      return result;
    });
  };

  const onClickDeleteCopyright = (index, indexCard, e) => {
    setDataIndexDeleteOne({
      index,
      indexCard,
    });
    setOpenAlert(true);
  };

  const onClickConfirmDeleteCopyright = () => {
    setAddIntangible((prev) => {
      const result = [...prev];
      const index = dataIndexDeleteOne?.index;
      const indexCard = dataIndexDeleteOne?.indexCard;
      const updatedCopyright = [...result[index].copyright_lists];
      updatedCopyright.splice(indexCard, 1);
      result[index] = {
        ...result[index],
        copyright_lists: updatedCopyright,
      };
      return result;
    });
    setDataIndexDeleteOne(null);
    setOpenAlert(false);
  };

  const onChangeTitleCopyright = (index, indexCard, e) => {
    const value = e.target.value;
    setAddIntangible((prev) => {
      let result = [...prev];
      result[index].copyright_lists[indexCard].title = value;
      return result;
    });
  };
  const onChangeAmountCopyright = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddIntangible((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].copyright_lists[indexCard].amount = "";
      } else {
        result[index].copyright_lists[indexCard].amount = value;
      }
      return result;
    });
  };
  // ปลอดภาระหนี้
  const [isCheckedDebtFreeCopyright, setIsCheckedDebtFreeCopyright] =
    useState(false);
  const handleCheckboxChangeDebtFreeCopyright = (index, indexCard, e) => {
    setAddIntangible((prev) => {
      const result = [...prev];
      result[index].copyright_lists[indexCard].debt_free =
        !isCheckedDebtFreeCopyright;
      return result;
    });
    setIsCheckedDebtFreeCopyright(!isCheckedDebtFreeCopyright);
  };
  //---------------------------------------- สิทธิบัตร ----------------------------------------//
  const handleNextPatent = (index, e) => {
    setAddIntangible((prev) => {
      let result = [...prev];
      const date = new Date();
      const created = date.toISOString();
      const newList = {
        created: created,
        title: "",
        amount: "",
        debt_free: false,
      };
      result[index] = {
        ...result[index],
        patent_lists: [...result[index].patent_lists, { ...newList }],
      };
      return result;
    });
  };

  const onClickDeletePatent = (index, indexCard, e) => {
    setDataIndexDeleteOne({
      index,
      indexCard,
    });
    setOpenAlert(true);
  };

  const onClickConfirmDeletePatent = () => {
    setAddIntangible((prev) => {
      const result = [...prev];
      const index = dataIndexDeleteOne?.index;
      const indexCard = dataIndexDeleteOne?.indexCard;
      const updatedPatent = [...result[index].patent_lists];
      updatedPatent.splice(indexCard, 1);
      result[index] = {
        ...result[index],
        patent_lists: updatedPatent,
      };
      return result;
    });
    setDataIndexDeleteOne(null);
    setOpenAlert(false);
  };

  const onChangeTitlePatent = (index, indexCard, e) => {
    const value = e.target.value;
    setAddIntangible((prev) => {
      let result = [...prev];
      result[index].patent_lists[indexCard].title = value;
      return result;
    });
  };
  const onChangeAmountPatent = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddIntangible((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].patent_lists[indexCard].amount = "";
      } else {
        result[index].patent_lists[indexCard].amount = value;
      }
      return result;
    });
  };
  // ปลอดภาระหนี้
  const [isCheckedDebtFreePatent, setIsCheckedDebtFreePatent] = useState(false);
  const handleCheckboxChangeDebtFreePatent = (index, indexCard, e) => {
    setAddIntangible((prev) => {
      const result = [...prev];
      result[index].patent_lists[indexCard].debt_free =
        !isCheckedDebtFreePatent;
      return result;
    });
    setIsCheckedDebtFreePatent(!isCheckedDebtFreePatent);
  };
  //---------------------------------------- อื่น ๆ ----------------------------------------//
  const handleNextIntangibleOther = (index, e) => {
    setAddIntangible((prev) => {
      let result = [...prev];
      const date = new Date();
      const created = date.toISOString();
      const newList = {
        created: created,
        title: "",
        amount: "",
        debt_free: false,
      };
      result[index] = {
        ...result[index],
        intangible_other_lists: [
          ...result[index].intangible_other_lists,
          { ...newList },
        ],
      };
      return result;
    });
  };

  const onClickDeleteIntangibleOther = (index, indexCard, e) => {
    setDataIndexDeleteOne({
      index,
      indexCard,
    });
    setOpenAlert(true);
  };

  const onClickConfirmDeleteIntangibleOther = () => {
    setAddIntangible((prev) => {
      const result = [...prev];
      const index = dataIndexDeleteOne?.index;
      const indexCard = dataIndexDeleteOne?.indexCard;
      const updatedIntangibleOther = [...result[index].intangible_other_lists];
      updatedIntangibleOther.splice(indexCard, 1);
      result[index] = {
        ...result[index],
        intangible_other_lists: updatedIntangibleOther,
      };
      return result;
    });
    setDataIndexDeleteOne(null);
    setOpenAlert(false);
  };
  const onChangeTitleIntangibleOther = (index, indexCard, e) => {
    const value = e.target.value;
    setAddIntangible((prev) => {
      let result = [...prev];
      result[index].intangible_other_lists[indexCard].title = value;
      return result;
    });
  };
  const onChangeAmountIntangibleOther = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddIntangible((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].intangible_other_lists[indexCard].amount = "";
      } else {
        result[index].intangible_other_lists[indexCard].amount = value;
      }
      return result;
    });
  };
  // ปลอดภาระหนี้
  const [
    isCheckedDebtFreeIntangibleOther,
    setIsCheckedDebtFreeIntangibleOther,
  ] = useState(false);
  const handleCheckboxChangeDebtFreeIntangibleOther = (index, indexCard, e) => {
    setAddIntangible((prev) => {
      const result = [...prev];
      result[index].intangible_other_lists[indexCard].debt_free =
        !isCheckedDebtFreeIntangibleOther;
      return result;
    });
    setIsCheckedDebtFreeIntangibleOther(!isCheckedDebtFreeIntangibleOther);
  };

  //====================================== Sum สินทรัพย์สภาพคล่อง ============================================//
  // เงินสด
  const sumCash = () => {
    let result = 0;
    addAsset?.map((item) => {
      item?.property_cash_lists?.map((row) => {
        result += row?.amount ? row?.amount : 0;
      });
    });
    return result;
  };
  // บัญชีเงินฝากออมทรัพย์
  const sumSaving = () => {
    let result = 0;
    addAsset?.map((item) => {
      item?.property_saving_lists?.map((row) => {
        result += row?.amount ? row?.amount : 0;
      });
    });
    return result;
  };
  // บัญชีเงินฝากประจำ
  const sumDeposit = () => {
    let result = 0;
    addAsset?.map((item) => {
      item?.property_deposit_lists?.map((row) => {
        result += row?.amount ? row?.amount : 0;
      });
    });
    return result;
  };
  // เงินฝากสหกรณ์ออมทรัพย์
  const sumSavingCooperative = () => {
    let result = 0;
    addAsset?.map((item) => {
      item?.property_saving_cooperative_lists?.map((row) => {
        result += row?.amount ? row?.amount : 0;
      });
    });
    return result;
  };
  // กองทุนตราสารหนี้สินระยะสั้น
  const sumFundDebt = () => {
    let result = 0;
    addAsset?.map((item) => {
      item?.property_fund_debt_lists?.map((row) => {
        result += row?.amount ? row?.amount : 0;
      });
    });
    return result;
  };
  // รวมค่าสินทรัพย์สภาพคล่องทั้งหมด
  const sumAllAsset = () => {
    let result = 0;
    result += sumCash();
    result += sumSaving();
    result += sumDeposit();
    result += sumSavingCooperative();
    result += sumFundDebt();
    return result;
  };

  //====================================== Sum สินทรัพย์เพื่อการลงทุน ============================================//
  // พันธบัตร/หุ้นกู้
  const sumInvestBond = () => {
    let result = 0;
    addInvest?.map((item) => {
      item?.invest_bond_lists?.map((row) => {
        result += row?.amount ? row?.amount : 0;
      });
    });
    return result;
  };
  // หุ้นบุริมสิทธิ
  const sumInvestPreferred = () => {
    let result = 0;
    addInvest?.map((item) => {
      item?.invest_preferred_lists?.map((row) => {
        result += row?.amount ? row?.amount : 0;
      });
    });
    return result;
  };
  // หุ้นสามัญ
  const sumInvestNormal = () => {
    let result = 0;
    addInvest?.map((item) => {
      item?.invest_normal_lists?.map((row) => {
        result += row?.amount ? row?.amount : 0;
      });
    });
    return result;
  };
  // กองทุนรวมตลาดเงิน
  const sumInvestMarket = () => {
    let result = 0;
    addInvest?.map((item) => {
      item?.invest_market_lists?.map((row) => {
        result += row?.amount ? row?.amount : 0;
      });
    });
    return result;
  };
  // เงินฝาก
  const sumInvestDeposit = () => {
    let result = 0;
    addInvest?.map((item) => {
      item?.invest_deposit_lists?.map((row) => {
        result += row?.amount ? row?.amount : 0;
      });
    });
    return result;
  };
  // กองทุนรวมหุ้น
  const sumInvestAll = () => {
    let result = 0;
    addInvest?.map((item) => {
      item?.all_invest_lists?.map((row) => {
        result += row?.amount ? row?.amount : 0;
      });
    });
    return result;
  };
  // กองทุนรวมเพื่อการเลี้ยงชีพ
  const sumInvestLife = () => {
    let result = 0;
    addInvest?.map((item) => {
      item?.invest_life_lists?.map((row) => {
        result += row?.amount ? row?.amount : 0;
      });
    });
    return result;
  };
  // กองทุนรวมผสม
  const sumInvestMixed = () => {
    let result = 0;
    addInvest?.map((item) => {
      item?.invest_mixed_lists?.map((row) => {
        result += row?.amount ? row?.amount : 0;
      });
    });
    return result;
  };
  // กองทุนสำรองเลี้ยงชีพ
  const sumInvestProvidentFund = () => {
    let result = 0;
    addInvest?.map((item) => {
      item?.invest_provident_fund_lists?.map((row) => {
        result += row?.amount ? row?.amount : 0;
      });
    });
    return result;
  };
  // เงินออมเพื่อวัยเกษียณอื่น ๆ
  const sumInvestOtherRetirementSavings = () => {
    let result = 0;
    addInvest?.map((item) => {
      item?.invest_other_retirement_savings_lists?.map((row) => {
        result += row?.amount ? row?.amount : 0;
      });
    });
    return result;
  };
  // ตลาดเงินอื่นๆ
  const sumInvestMoneyMarketOther = () => {
    let result = 0;
    addInvest?.map((item) => {
      item?.invest_money_market_other_lists?.map((row) => {
        result += row?.amount ? row?.amount : 0;
      });
    });
    return result;
  };
  // ตราสารอนุพันธ์
  const sumInvestDerivativeInstruments = () => {
    let result = 0;
    addInvest?.map((item) => {
      item?.invest_derivative_instruments_lists?.map((row) => {
        result += row?.amount ? row?.amount : 0;
      });
    });
    return result;
  };
  // มูลค่าเงินสดในกรมธรรม์ประกันชีวิต
  const sumInvestLifeInsurancePolicy = () => {
    let result = 0;
    addInvest?.map((item) => {
      item?.invest_life_insurance_policy_lists?.map((row) => {
        result += row?.amount ? row?.amount : 0;
      });
    });
    return result;
  };
  // สลากออมสิน
  const sumInvestAomsinBondPolicy = () => {
    let result = 0;
    addInvest?.map((item) => {
      item?.aomsin_bond_policy_lists?.map((row) => {
        result += row?.amount ? row?.amount : 0;
      });
    });
    return result;
  };
  // ตราสารหนี้อื่นๆ
  const sumInvestPropertyDebtInstruments = () => {
    let result = 0;
    addInvest?.map((item) => {
      item?.property_debt_instruments_lists?.map((row) => {
        result += row?.amount ? row?.amount : 0;
      });
    });
    return result;
  };
  // อสังหาริมทรัพย์
  const sumInvestRealEstate = () => {
    let result = 0;
    addInvest?.map((item) => {
      item?.invest_real_estate_lists?.map((row) => {
        result += row?.amount ? row?.amount : 0;
      });
    });
    return result;
  };
  // มูลค่าทองคำ
  const sumInvestGoldCost = () => {
    let result = 0;
    addInvest?.map((item) => {
      item?.invest_gold_cost_lists?.map((row) => {
        result += row?.amount ? row?.amount : 0;
      });
    });
    return result;
  };

  // หุ้นในส่วนที่เป็นเจ้าของ
  const sumInvestOwner = () => {
    let result = 0;
    addInvest?.map((item) => {
      item?.invest_owner_lists?.map((row) => {
        result += row?.amount ? row?.amount : 0;
      });
    });
    return result;
  };

  //ตราสารทุนอื่นๆ
  const sumInvestEquityInstruments = () => {
    let result = 0;
    addInvest?.map((item) => {
      item?.invest_equity_instruments_lists?.map((row) => {
        result += row?.amount ? row?.amount : 0;
      });
    });
    return result;
  };

  // เงินลงทุนอื่น ๆ
  const sumInvestOther = () => {
    let result = 0;
    addInvest?.map((item) => {
      item?.invest_other_lists?.map((row) => {
        result += row?.amount ? row?.amount : 0;
      });
    });
    return result;
  };

  // ภาครัฐ
  const sumInvestGoverment = () => {
    let result = 0;
    addInvest?.map((item) => {
      item?.invest_goverment_lists?.map((row) => {
        result += row?.amount ? row?.amount : 0;
      });
    });
    return result;
  };

  // ภาคเอกชน
  const sumInvestPrivate = () => {
    let result = 0;
    addInvest?.map((item) => {
      item?.invest_private_lists?.map((row) => {
        result += row?.amount ? row?.amount : 0;
      });
    });
    return result;
  };

  // รวมค่าสินทรัพย์เพื่อลงทุนทั้งหมด
  const sumAllInvest = () => {
    let result = 0;
    result += sumInvestBond();
    result += sumInvestPreferred();
    result += sumInvestNormal();
    result += sumInvestMarket();
    result += sumInvestDeposit();
    result += sumInvestAll();
    result += sumInvestLife();
    result += sumInvestMixed();
    result += sumInvestProvidentFund();
    result += sumInvestOtherRetirementSavings();
    result += sumInvestMoneyMarketOther();
    result += sumInvestDerivativeInstruments();
    result += sumInvestLifeInsurancePolicy();
    result += sumInvestAomsinBondPolicy();
    result += sumInvestPropertyDebtInstruments();
    result += sumInvestRealEstate();
    result += sumInvestGoldCost();
    result += sumInvestOwner();
    result += sumInvestEquityInstruments();
    result += sumInvestOther();
    result += sumInvestGoverment();
    result += sumInvestPrivate();
    return Number(result);
  };

  //====================================== Sum สินทรัพย์ใช้ส่วนตัว ============================================//
  // เครื่องประดับ
  const sumAccessories = () => {
    let result = 0;
    addPrivate?.map((item) => {
      item?.accessories_lists?.map((row) => {
        result += row?.amount ? row?.amount : 0;
      });
    });
    return result;
  };
  // รถยนต์
  const sumCar = () => {
    let result = 0;
    addPrivate?.map((item) => {
      item?.car_lists?.map((row) => {
        result += row?.amount ? row?.amount : 0;
      });
    });
    return result;
  };
  // บ้านที่อยู่อาศัย
  const sumHome = () => {
    let result = 0;
    addPrivate?.map((item) => {
      item?.home_lists?.map((row) => {
        result += row?.amount ? row?.amount : 0;
      });
    });
    return result;
  };
  // ของสะสมมีราคา
  const sumCollection = () => {
    let result = 0;
    addPrivate?.map((item) => {
      item?.collection_lists?.map((row) => {
        result += row?.amount ? row?.amount : 0;
      });
    });
    return result;
  };
  // อื่น ๆ
  const sumPrivateOther = () => {
    let result = 0;
    addPrivate?.map((item) => {
      item?.other_lists?.map((row) => {
        result += row?.amount ? row?.amount : 0;
      });
    });
    return result;
  };
  // รวมค่าสินทรัพย์ใช้ส่วนตัวทั้งหมด
  const sumAllPrivate = () => {
    let result = 0;
    result += sumAccessories();
    result += sumCar();
    result += sumHome();
    result += sumCollection();
    result += sumPrivateOther();

    return result;
  };

  //====================================== Sum สินทรัพย์ไม่มีตัวตน ============================================//
  // ลิขสิทธิ
  const sumCopyright = () => {
    let result = 0;
    addIntangible?.map((item) => {
      item?.copyright_lists?.map((row) => {
        result += row?.amount ? row?.amount : 0;
      });
    });
    return result;
  };
  // สิทธิบัตร
  const sumPatent = () => {
    let result = 0;
    addIntangible?.map((item) => {
      item?.patent_lists?.map((row) => {
        result += row?.amount ? row?.amount : 0;
      });
    });
    return result;
  };
  // อื่น ๆ
  const sumIntangibleOther = () => {
    let result = 0;
    addIntangible?.map((item) => {
      item?.intangible_other_lists?.map((row) => {
        result += row?.amount ? row?.amount : 0;
      });
    });
    return result;
  };
  // รวมค่าสินทรัพย์ไม่มีตัวตนทั้งหมด
  const sumAllIntangible = () => {
    let result = 0;
    result += sumCopyright();
    result += sumPatent();
    result += sumIntangibleOther();
    return result;
  };

  //======================================================================================================//
  //==================== Submit ====================//
  const onClickSubmit = () => {
    let tempAddPrivate = PRIVATE_LIST?.flatMap((item1) => {
      return addPrivate
        ?.flatMap((item2) => item2?.[item1])
        ?.filter((item3) => item3 !== undefined);
    }).every((item) => item?.debt_free);

    let tempAddInvest = addInvest
      ?.find(
        (item) =>
          item?.options_property_invest_title ===
          "อสังหาริมทรัพย์ (สินทรัพย์ทางเลือก)"
      )
      ?.invest_real_estate_lists?.map((item) => item?.debt_free)
      ?.every((item) => item === true);

    if (addPrivate) {
      if (!tempAddPrivate) {
        setOpenAlertNoDebt(true);
        return;
      }

      if (!tempAddInvest && tempAddInvest !== undefined) {
        setOpenAlertNoDebt(true);
        return;
      }
    }

    // console.log("tempAddInvest",tempAddInvest)

    setBalanceData({
      ...formBL,
      sum_property_invest: sumAllInvest(),
      sum_property_intangible: sumAllIntangible(),
      sum_property_asset: sumAllAsset(),
      sum_property_private: sumAllPrivate(),
      property_invest_list: addInvest,
      property_asset_list: addAsset,
      property_private_list: addPrivate,
      property_intangible_list: addIntangible,
      debt_long_list: tempBalance?.debt_long_list,
    });
    onclose();
  };

  // LOAD DATA
  useEffect(() => {
    let sum_debt_property_private = 0;

    if (balanceData?.debt_long_car_personal_list?.length > 0) {
      for (const debt_long_car_personal_item of balanceData?.debt_long_car_personal_list) {
        sum_debt_property_private += debt_long_car_personal_item?.amount;
      }
    }

    if (balanceData?.debt_long_place_personal_list?.length > 0) {
      for (const debt_long_place_personal_item of balanceData?.debt_long_place_personal_list) {
        sum_debt_property_private += debt_long_place_personal_item?.amount;
      }
    }

    setFormBL({
      ...formBL,
      property_asset_list: addAsset,
      sum_property_asset: sumAllAsset(),
      property_invest_list: addInvest,
      sum_property_invest: sumAllInvest(),
      property_private_list: addPrivate,
      sum_property_private: sumAllPrivate() + Number(sum_debt_property_private),
      property_intangible_list: addIntangible,
      sum_property_intangible: sumAllIntangible(),
      property_propotion:
        sumAllAsset() +
        sumAllInvest() +
        sumAllPrivate() +
        sumAllIntangible() +
        Number(sum_debt_property_private),
    });
  }, [
    balanceData,
    sumCash(),
    sumSaving(),
    sumDeposit(),
    sumSavingCooperative(),
    sumFundDebt(),
    sumInvestBond(),
    sumInvestPreferred(),
    sumInvestNormal(),
    sumInvestMarket(),
    sumInvestDeposit(),
    sumInvestAll(),
    sumInvestGoverment(),
    sumInvestPrivate(),
    sumInvestLife(),
    sumInvestMixed(),
    sumInvestProvidentFund(),
    sumInvestOtherRetirementSavings(),
    sumInvestMoneyMarketOther(),
    sumInvestDerivativeInstruments(),
    sumInvestLifeInsurancePolicy(),
    sumInvestAomsinBondPolicy(),
    sumInvestRealEstate(),
    sumInvestGoldCost(),
    sumInvestOwner(),
    sumInvestOther(),
    sumAccessories(),
    sumCar(),
    sumHome(),
    sumCollection(),
    sumPrivateOther(),
    sumCopyright(),
    sumPatent(),
    sumIntangibleOther(),
  ]);

  return (
    <Drawer
      placement="right"
      open={open}
      onClose={onclose}
      className=" overflow-y-scroll !max-w-[100%] "
    >
      <div
        style={{
          backgroundImage: `url(/images/circle.png)`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: " left  ",
        }}
        className={`min-h-screen  `}
      >
        <div className={STYLE_MAX_W_DRAWER}>
          <HeaderDrawer title={`สัดส่วนทรัพย์สิน`} onClickGoBack={onclose} />
          <div className={STYLE_PADDING_BALANCE_DRAWER}>
            <div className=" flex flex-col gap-[30px] relative">
              {/*---------------------------------------- สินทรัพย์สภาพคล่อง ----------------------------------------*/}
              <CardAccordian
                zIndex="4"
                add
                open={openAccordion === 1}
                onClickOpen={() => {
                  handleOpen(1);
                  if (openAccordion === 1) setOpenAccordion(false);
                }}
                title="สินทรัพย์สภาพคล่อง"
                textColor="text-purple700"
                icon="/images/proporty_asset2.png"
                value={formBL?.sum_property_asset}
                onClickAccordion={() => {
                  const date = new Date();
                  const created = date.toISOString();
                  const result = [
                    {
                      created: created,
                      options_property_asset: "",
                      options_property_asset_title: "",
                    },
                  ];
                  setAddAsset((prev) => {
                    if (prev) {
                      return [...prev, ...result];
                    }
                    return [...result];
                  });
                  handleOpen(1);
                }}
                panel={
                  <div>
                    {addAsset
                      ?.sort(
                        (a, b) => new Date(b.created) - new Date(a.created)
                      )
                      ?.map((row, index) => {
                        return (
                          <div key={index} className=" ">
                            <div className=" pt-3 ">
                              <DropDownMain
                                value={options_property_asset?.filter(
                                  (option) => {
                                    if (
                                      option?.value ===
                                      row?.options_property_asset
                                    )
                                      return option?.label;
                                  }
                                )}
                                onChange={onChangeSelectedAsset.bind(
                                  this,
                                  row?.created,
                                  index
                                )}
                                options={options_property_asset}
                                zIndex={4}
                              />
                            </div>
                            <div className=" text-black mt-2.5 "></div>

                            {/*---------- เงินสด ----------*/}
                            {row?.options_property_asset ===
                              "property_cash" && (
                              <div className=" mt-4">
                                {row?.property_cash_lists
                                  ?.sort(
                                    (a, b) =>
                                      new Date(b.created) - new Date(a.created)
                                  )
                                  ?.map((row, indexCard) => {
                                    return (
                                      <div key={indexCard}>
                                        <div className=" flex items-center gap-1 mt-3 w-full ">
                                          <div className=" text-black text-sm flex-none ">
                                            จำนวนเงิน
                                          </div>
                                          <NumericFormat
                                            maxLength={11}
                                            value={row?.amount}
                                            placeholder="5,000"
                                            onChange={onChangeAmountCash}
                                            thousandSeparator=","
                                            inputMode="decimal"
                                            pattern="[0-9],*"
                                            className={` rounded-full px-[14px] py-2 placeholder:text-gray80 text-mainblue text-sm font-semibold w-full focus:outline-none focus:ring-1 focus:ring-mainblue  `}
                                          />
                                          <ICON_DELETE
                                            className=" w-5 h-5 text-maincyan  flex-none "
                                            onClick={onClickDeleteCash}
                                          />
                                        </div>
                                      </div>
                                    );
                                  })}

                                <PopupMain
                                  title={"ยืนยันการลบข้อมูล"}
                                  text={
                                    <div className=" text-base">
                                      คุณต้องการลบข้อมูลรายการนี้ใช่หรือไม่
                                    </div>
                                  }
                                  button2={true}
                                  textButton={"ยืนยัน"}
                                  textButton2={"ยกเลิก"}
                                  open={openAlert}
                                  onClick={onClickConfirmDeleteCash}
                                  onClickSecond={() => {
                                    setDataIndexDeleteOne(null);
                                    setOpenAlert(false);
                                  }}
                                />
                              </div>
                            )}

                            {/*---------- บัญชีเงินฝากออมทรัพย์ ----------*/}
                            {row?.options_property_asset ===
                              "property_saving" && (
                              <div className=" mt-4">
                                <div className=" bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9 ">
                                  <div className=" text-sm text-black font-medium">
                                    จำนวนบัญชี (เล่ม)
                                  </div>
                                  <ButtonCounter
                                    value={row?.property_saving_lists?.length}
                                    handleNext={handleNextSaving?.bind(
                                      this,
                                      index
                                    )}
                                  />
                                </div>
                                {row?.property_saving_lists
                                  ?.sort(
                                    (a, b) =>
                                      new Date(b.created) - new Date(a.created)
                                  )
                                  ?.map((row, indexCard) => {
                                    return (
                                      <div key={indexCard}>
                                        <CardPropertyAsset
                                          head={`ชื่อสถาบันการเงิน`}
                                          title={row?.title}
                                          accountNumber={row?.account_number}
                                          amount={row?.amount}
                                          placeholder={`ระบุชื่อธนาคาร`}
                                          onClickDelete={onClickDeleteSaving?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeTitle={onChangeTitleSaving?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeAccountNumber={onChangeAccountNumberSaving?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeAmount={onChangeAmountSaving?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                        />
                                      </div>
                                    );
                                  })}

                                <PopupMain
                                  title={"ยืนยันการลบข้อมูล"}
                                  text={
                                    <div className=" text-base">
                                      คุณต้องการลบข้อมูลรายการนี้ใช่หรือไม่
                                    </div>
                                  }
                                  button2={true}
                                  textButton={"ยืนยัน"}
                                  textButton2={"ยกเลิก"}
                                  open={openAlert}
                                  onClick={onClickConfirmDeleteSaving}
                                  onClickSecond={() => {
                                    setDataIndexDeleteOne(null);
                                    setOpenAlert(false);
                                  }}
                                />
                              </div>
                            )}

                            {/*---------- บัญชีเงินฝากประจำ ----------*/}
                            {row?.options_property_asset ===
                              "property_deposit" && (
                              <div className=" mt-4">
                                <div className=" bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9 ">
                                  <div className=" text-sm text-black font-medium">
                                    จำนวนบัญชี (เล่ม)
                                  </div>
                                  <ButtonCounter
                                    value={row?.property_deposit_lists?.length}
                                    handleNext={handleNextDeposit?.bind(
                                      this,
                                      index
                                    )}
                                  />
                                </div>
                                {row?.property_deposit_lists
                                  ?.sort(
                                    (a, b) =>
                                      new Date(b.created) - new Date(a.created)
                                  )
                                  ?.map((row, indexCard) => {
                                    return (
                                      <div key={indexCard}>
                                        <CardPropertyAsset
                                          head={`ชื่อสถาบันการเงิน`}
                                          title={row?.title}
                                          accountNumber={row?.account_number}
                                          amount={row?.amount}
                                          placeholder={`ระบุชื่อธนาคาร`}
                                          onClickDelete={onClickDeleteDeposit?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeTitle={onChangeTitleDeposit?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeAccountNumber={onChangeAccountNumberDeposit?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeAmount={onChangeAmountDeposit?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                        />
                                      </div>
                                    );
                                  })}

                                <PopupMain
                                  title={"ยืนยันการลบข้อมูล"}
                                  text={
                                    <div className=" text-base">
                                      คุณต้องการลบข้อมูลรายการนี้ใช่หรือไม่
                                    </div>
                                  }
                                  button2={true}
                                  textButton={"ยืนยัน"}
                                  textButton2={"ยกเลิก"}
                                  open={openAlert}
                                  onClick={onClickConfirmDeleteDeposit}
                                  onClickSecond={() => {
                                    setDataIndexDeleteOne(null);
                                    setOpenAlert(false);
                                  }}
                                />
                              </div>
                            )}

                            {/*---------- เงินฝากสหกรณ์ออมทรัพย์ ----------*/}
                            {row?.options_property_asset ===
                              "property_saving_cooperative" && (
                              <div className=" mt-4">
                                <div className=" bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9 ">
                                  <div className=" text-sm text-black font-medium">
                                    จำนวนสหกรณ์ (ที่)
                                  </div>
                                  <ButtonCounter
                                    value={
                                      row?.property_saving_cooperative_lists
                                        ?.length
                                    }
                                    handleNext={handleNextSavingCooperative?.bind(
                                      this,
                                      index
                                    )}
                                  />
                                </div>
                                {row?.property_saving_cooperative_lists
                                  ?.sort(
                                    (a, b) =>
                                      new Date(b.created) - new Date(a.created)
                                  )
                                  ?.map((row, indexCard) => {
                                    return (
                                      <div key={indexCard}>
                                        <CardPropertyAsset
                                          head={`ชื่อสหกรณ์`}
                                          title={row?.title}
                                          accountNumber={row?.account_number}
                                          amount={row?.amount}
                                          placeholder={`ระบุชื่อธนาคาร`}
                                          onClickDelete={onClickDeleteSavingCooperative?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeTitle={onChangeTitleSavingCooperative?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeAccountNumber={onChangeAccountNumberSavingCooperative.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeAmount={onChangeAmountSavingCooperative?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                        />
                                      </div>
                                    );
                                  })}
                                <PopupMain
                                  title={"ยืนยันการลบข้อมูล"}
                                  text={
                                    <div className=" text-base">
                                      คุณต้องการลบข้อมูลรายการนี้ใช่หรือไม่
                                    </div>
                                  }
                                  button2={true}
                                  textButton={"ยืนยัน"}
                                  textButton2={"ยกเลิก"}
                                  open={openAlert}
                                  onClick={
                                    onClickConfirmDeleteSavingCooperative
                                  }
                                  onClickSecond={() => {
                                    setDataIndexDeleteOne(null);
                                    setOpenAlert(false);
                                  }}
                                />
                              </div>
                            )}

                            {/*---------- กองทุนตราสารหนี้สินระยะสั้น ----------*/}
                            {row?.options_property_asset ===
                              "property_fund_debt" && (
                              <div className=" mt-4">
                                <div className=" bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9 ">
                                  <div className=" text-xs text-black font-medium">
                                    จำนวนกองทุนตราสารหนี้ระยะสั้น (กอง)
                                  </div>
                                  <ButtonCounter
                                    value={
                                      row?.property_fund_debt_lists?.length
                                    }
                                    handleNext={handleNextFundDebt?.bind(
                                      this,
                                      index
                                    )}
                                  />
                                </div>
                                {row?.property_fund_debt_lists
                                  ?.sort(
                                    (a, b) =>
                                      new Date(b.created) - new Date(a.created)
                                  )
                                  ?.map((row, indexCard) => {
                                    return (
                                      <div key={indexCard}>
                                        <CardPropertyAsset
                                          isFundDebt={true}
                                          title={row?.title}
                                          titleFund={row?.title_fund}
                                          amount={row?.amount}
                                          onClickDelete={onClickDeleteFundDebt?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeTitle={onChangeTitleFundDebt?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeTitleFund={onChangeTitleFundFundDebt.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeAmount={onChangeAmountFundDebt?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                        />
                                      </div>
                                    );
                                  })}
                                <PopupMain
                                  title={"ยืนยันการลบข้อมูล"}
                                  text={
                                    <div className=" text-base">
                                      คุณต้องการลบข้อมูลรายการนี้ใช่หรือไม่
                                    </div>
                                  }
                                  button2={true}
                                  textButton={"ยืนยัน"}
                                  textButton2={"ยกเลิก"}
                                  open={openAlert}
                                  onClick={onClickConfirmDeleteFundDebt}
                                  onClickSecond={() => {
                                    setDataIndexDeleteOne(null);
                                    setOpenAlert(false);
                                  }}
                                />
                              </div>
                            )}

                            <DeleteButtonCard
                              onClick={() => {
                                setDataIndexDeleteAll(index);
                                setOpenAlert2(true);
                              }}
                            />
                            <PopupMain
                              title={"ยืนยันการลบข้อมูลทั้งหมด"}
                              text={
                                <div className=" text-base">
                                  คุณต้องการลบข้อมูลทั้งหมดนี้ใช่หรือไม่
                                </div>
                              }
                              button2={true}
                              textButton={"ยืนยัน"}
                              textButton2={"ยกเลิก"}
                              open={openAlert2}
                              onClick={() => {
                                const result = [...addAsset];
                                result.splice(dataIndexDeleteAll, 1);
                                setAddAsset(result);
                                setDataIndexDeleteAll(null);
                                setOpenAlert2(false);
                              }}
                              onClickSecond={() => {
                                setDataIndexDeleteAll(null);
                                setOpenAlert2(false);
                              }}
                            />
                            {addAsset?.length > 1 &&
                              index !== addAsset?.length - 1 && (
                                <div className=" border-b border-gray80 pb-3 my-2"></div>
                              )}
                          </div>
                        );
                      })}
                  </div>
                }
              />

              {/*---------------------------------------- สินทรัพย์เพื่อการลงทุน ----------------------------------------*/}
              <CardAccordian
                zIndex="3"
                add
                onClickOpen={() => {
                  handleOpen(2);
                  if (openAccordion === 2) setOpenAccordion(false);
                }}
                open={openAccordion === 2}
                title="สินทรัพย์เพื่อการลงทุน"
                textColor="text-purple400"
                icon="/images/proporty_invest3.svg"
                value={formBL?.sum_property_invest + SumDebtInvestValue()}
                onClickAccordion={() => {
                  const date = new Date();
                  const created = date.toISOString();
                  const result = [
                    {
                      created: created,
                      options_property_invest: "",
                      options_property_invest_title: "",
                    },
                  ];
                  setAddInvest((prev) => {
                    if (prev) {
                      return [...prev, ...result];
                    }
                    return [...result];
                  });
                  handleOpen(2);
                }}
                panel={
                  <div>
                    <div className="mb-10">
                      {balanceData?.debt_long_list
                        ?.map(
                          (item) =>
                            item?.debt_long_others ||
                            item?.debt_long_places ||
                            item?.debt_long_cars
                        )
                        .flat()
                        .filter(
                          (item) =>
                            item?.personal_or_investment === "investment"
                        )?.length > 0 && (
                        <ListInvestmain
                          tempBalance={tempBalance}
                          setTempBalance={setTempBalance}
                        />
                      )}
                    </div>
                    {addInvest
                      ?.sort(
                        (a, b) => new Date(b.created) - new Date(a.created)
                      )
                      ?.map((row, index) => {
                        return (
                          <div key={index} className=" ">
                            <div className={` pt-3`}>
                              <DropDownMain
                                value={options_property_invest?.filter(
                                  (option) => {
                                    if (
                                      option?.value ===
                                      row?.options_property_invest
                                    )
                                      return option?.label;
                                  }
                                )}
                                onChange={onChangeSelectedInvest.bind(
                                  this,
                                  row?.created,
                                  index
                                )}
                                options={options_property_invest}
                                zIndex={3}
                              />
                            </div>
                            {/*---------- พันธบัตร/หุ้นกู้ ----------*/}
                            {row?.options_property_invest ===
                              "property_invest_bond" && (
                              <div key={index}>
                                <div className=" bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9 my-2.5">
                                  <div className=" text-sm text-black font-medium">
                                    จำนวน
                                    {row?.options_property_invest_title}
                                  </div>
                                  <ButtonCounter
                                    value={row?.invest_bond_lists?.length}
                                    handleNext={handleNextInvestBond?.bind(
                                      this,
                                      index
                                    )}
                                  />
                                </div>
                                {row?.invest_bond_lists?.length >= 1 && (
                                  <HeaderInvest />
                                )}
                                {row?.invest_bond_lists
                                  ?.sort(
                                    (a, b) =>
                                      new Date(b.created) - new Date(a.created)
                                  )
                                  ?.map((row, indexCard) => {
                                    return (
                                      <div key={indexCard} className="my-2.5">
                                        <div className={STYLE_BODY_INVEST}>
                                          <div className="">
                                            <input
                                              placeholder="ระบุ"
                                              value={row?.title}
                                              className={`${STYLE_INPUT_INSET} w-full `}
                                              onChange={onChangeTitleInvestBond?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                            />
                                          </div>
                                          <div className="">
                                            <NumericFormat
                                              maxLength={11}
                                              value={row?.amount}
                                              placeholder="0"
                                              onChange={onChangeAmountInvestBond?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                              thousandSeparator=","
                                              inputMode="decimal"
                                              pattern="[0-9],*"
                                              className={`${STYLE_INPUT_INSET} w-full `}
                                            />
                                          </div>

                                          <div className=" flex items-center gap-1 w-full ">
                                            <NumericFormat
                                              maxLength={11}
                                              value={
                                                row?.reward_amount_per_year
                                              }
                                              placeholder="0"
                                              onChange={onChangeRewardAmountPerYearInvestBound?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                              thousandSeparator=","
                                              inputMode="decimal"
                                              pattern="[0-9],*"
                                              className={`${STYLE_INPUT_INSET} w-full `}
                                            />
                                            <ICON_DELETE
                                              className=" w-5 h-5 text-maincyan flex-none cursor-pointer"
                                              onClick={onClickDeleteInvestBond?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                            />
                                          </div>
                                        </div>

                                        <SelectTargetInvest
                                          options={OPTIONS_TARGET_INVEST}
                                          onChange={(e) =>
                                            onChangeSelectedTargetInvest(
                                              "invest_bond_lists",
                                              index,
                                              indexCard,
                                              e
                                            )
                                          }
                                          value={row?.target_invest}
                                        />
                                      </div>
                                    );
                                  })}
                                <PopupMain
                                  title={"ยืนยันการลบข้อมูล"}
                                  text={
                                    <div className=" text-base">
                                      คุณต้องการลบข้อมูลรายการนี้ใช่หรือไม่
                                    </div>
                                  }
                                  button2={true}
                                  textButton={"ยืนยัน"}
                                  textButton2={"ยกเลิก"}
                                  open={
                                    openAlert &&
                                    dataIndexDeleteOne.index === index
                                  }
                                  onClick={onClickConfirmDeleteInvestBond}
                                  onClickSecond={() => {
                                    setDataIndexDeleteOne(null);
                                    setOpenAlert(false);
                                  }}
                                />
                              </div>
                            )}

                            {/*---------- หุ้นบุริมสิทธิ ----------*/}
                            {row?.options_property_invest ===
                              "property_invest_preferred" && (
                              <div key={index}>
                                <div className=" bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9 my-2.5">
                                  <div className=" text-sm text-black font-medium">
                                    จำนวน
                                    {row?.options_property_invest_title}
                                  </div>
                                  <ButtonCounter
                                    value={row?.invest_preferred_lists?.length}
                                    handleNext={handleNextInvestPreferred?.bind(
                                      this,
                                      index
                                    )}
                                  />
                                </div>
                                {row?.invest_preferred_lists?.length >= 1 && (
                                  <HeaderInvest />
                                )}
                                {row?.invest_preferred_lists
                                  ?.sort(
                                    (a, b) =>
                                      new Date(b.created) - new Date(a.created)
                                  )
                                  ?.map((row, indexCard) => {
                                    return (
                                      <div key={indexCard} className="my-2.5">
                                        <div className={STYLE_BODY_INVEST}>
                                          <div className=" flex gap-[6px] items-center">
                                            <input
                                              placeholder="ระบุ"
                                              value={row?.title}
                                              className={`${STYLE_INPUT_INSET} w-full `}
                                              onChange={onChangeTitleInvestPreferred?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                            />
                                          </div>
                                          <NumericFormat
                                            maxLength={11}
                                            value={row?.amount}
                                            placeholder="0"
                                            onChange={onChangeAmountInvestPreferred?.bind(
                                              this,
                                              index,
                                              indexCard
                                            )}
                                            thousandSeparator=","
                                            inputMode="decimal"
                                            pattern="[0-9],*"
                                            className={`${STYLE_INPUT_INSET} w-full`}
                                          />
                                          <div className=" flex items-center w-full ">
                                            <NumericFormat
                                              maxLength={11}
                                              value={
                                                row?.reward_amount_per_year
                                              }
                                              placeholder="0"
                                              onChange={onChangeRewardAmountPerYearInvestPreferred?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                              thousandSeparator=","
                                              inputMode="decimal"
                                              pattern="[0-9],*"
                                              className={`${STYLE_INPUT_INSET} w-full`}
                                            />
                                            <ICON_DELETE
                                              className=" w-5 h-5 text-maincyan flex-none cursor-pointer"
                                              onClick={onClickDeleteInvestPreferred?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                            />
                                          </div>
                                        </div>
                                        <SelectTargetInvest
                                          options={OPTIONS_TARGET_INVEST}
                                          onChange={(e) =>
                                            onChangeSelectedTargetInvest(
                                              "invest_preferred_lists",
                                              index,
                                              indexCard,
                                              e
                                            )
                                          }
                                          value={row?.target_invest}
                                        />
                                      </div>
                                    );
                                  })}
                                <PopupMain
                                  title={"ยืนยันการลบข้อมูล"}
                                  text={
                                    <div className=" text-base">
                                      คุณต้องการลบข้อมูลรายการนี้ใช่หรือไม่
                                    </div>
                                  }
                                  button2={true}
                                  textButton={"ยืนยัน"}
                                  textButton2={"ยกเลิก"}
                                  open={
                                    openAlert &&
                                    dataIndexDeleteOne.index === index
                                  }
                                  onClick={onClickConfirmDeleteInvestPreferred}
                                  onClickSecond={() => {
                                    setDataIndexDeleteOne(null);
                                    setOpenAlert(false);
                                  }}
                                />
                              </div>
                            )}

                            {/*---------- หุ้นสามัญ ----------*/}
                            {row?.options_property_invest ===
                              "property_invest_normal" && (
                              <div key={index}>
                                <div className=" bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9 my-2.5">
                                  <div className=" text-sm text-black font-medium">
                                    จำนวน
                                    {row?.options_property_invest_title}
                                  </div>
                                  <ButtonCounter
                                    value={row?.invest_normal_lists?.length}
                                    handleNext={handleNextInvestNormal?.bind(
                                      this,
                                      index
                                    )}
                                  />
                                </div>
                                {row?.invest_normal_lists?.length >= 1 && (
                                  <HeaderInvest />
                                )}
                                {row?.invest_normal_lists
                                  ?.sort(
                                    (a, b) =>
                                      new Date(b.created) - new Date(a.created)
                                  )
                                  ?.map((row, indexCard) => {
                                    return (
                                      <div key={indexCard} className="my-2.5">
                                        <div className={STYLE_BODY_INVEST}>
                                          <div className=" flex gap-[6px] items-center">
                                            <input
                                              placeholder="ระบุ"
                                              value={row?.title}
                                              className={`${STYLE_INPUT_INSET} w-full `}
                                              onChange={onChangeTitleInvestNormal?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                            />
                                          </div>
                                          <NumericFormat
                                            maxLength={11}
                                            value={row?.amount}
                                            placeholder="0"
                                            onChange={onChangeAmountInvestNormal?.bind(
                                              this,
                                              index,
                                              indexCard
                                            )}
                                            thousandSeparator=","
                                            inputMode="decimal"
                                            pattern="[0-9],*"
                                            className={`${STYLE_INPUT_INSET} w-full`}
                                          />
                                          <div className=" flex items-center w-full ">
                                            <NumericFormat
                                              maxLength={11}
                                              value={
                                                row?.reward_amount_per_year
                                              }
                                              placeholder="0"
                                              onChange={onChangeRewardAmountPerYearInvestNormal?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                              thousandSeparator=","
                                              inputMode="decimal"
                                              pattern="[0-9],*"
                                              className={`${STYLE_INPUT_INSET} w-full`}
                                            />
                                            <ICON_DELETE
                                              className=" w-5 h-5 text-maincyan flex-none cursor-pointer"
                                              onClick={onClickDeleteInvestNormal?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                            />
                                          </div>
                                        </div>
                                        <SelectTargetInvest
                                          options={OPTIONS_TARGET_INVEST}
                                          onChange={(e) =>
                                            onChangeSelectedTargetInvest(
                                              "invest_normal_lists",
                                              index,
                                              indexCard,
                                              e
                                            )
                                          }
                                          value={row?.target_invest}
                                        />
                                      </div>
                                    );
                                  })}
                                <PopupMain
                                  title={"ยืนยันการลบข้อมูล"}
                                  text={
                                    <div className=" text-base">
                                      คุณต้องการลบข้อมูลรายการนี้ใช่หรือไม่
                                    </div>
                                  }
                                  button2={true}
                                  textButton={"ยืนยัน"}
                                  textButton2={"ยกเลิก"}
                                  open={
                                    openAlert &&
                                    dataIndexDeleteOne.index === index
                                  }
                                  onClick={onClickConfirmDeleteInvestNormal}
                                  onClickSecond={() => {
                                    setDataIndexDeleteOne(null);
                                    setOpenAlert(false);
                                  }}
                                />
                              </div>
                            )}

                            {/*---------- กองทุนรวมตลาดเงิน ----------*/}
                            {row?.options_property_invest ===
                              "property_invest_market" && (
                              <div key={index}>
                                <div className=" bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9 my-2.5">
                                  <div className=" text-sm text-black font-medium">
                                    จำนวน
                                    {row?.options_property_invest_title}
                                  </div>
                                  <ButtonCounter
                                    value={row?.invest_market_lists?.length}
                                    handleNext={handleNextInvestMarket?.bind(
                                      this,
                                      index
                                    )}
                                  />
                                </div>
                                {row?.invest_market_lists?.length >= 1 && (
                                  <HeaderInvest />
                                )}
                                {row?.invest_market_lists
                                  ?.sort(
                                    (a, b) =>
                                      new Date(b.created) - new Date(a.created)
                                  )
                                  ?.map((row, indexCard) => {
                                    return (
                                      <div key={indexCard} className="my-2.5">
                                        <div className={STYLE_BODY_INVEST}>
                                          <div className=" flex gap-[6px] items-center">
                                            <input
                                              placeholder="ระบุ"
                                              value={row?.title}
                                              className={`${STYLE_INPUT_INSET} w-full `}
                                              onChange={onChangeTitleInvestMarket?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                            />
                                          </div>
                                          <NumericFormat
                                            maxLength={11}
                                            value={row?.amount}
                                            placeholder="0"
                                            onChange={onChangeAmountInvestMarket?.bind(
                                              this,
                                              index,
                                              indexCard
                                            )}
                                            thousandSeparator=","
                                            inputMode="decimal"
                                            pattern="[0-9],*"
                                            className={`${STYLE_INPUT_INSET} w-full`}
                                          />
                                          <div className=" flex items-center w-full ">
                                            <NumericFormat
                                              maxLength={11}
                                              value={
                                                row?.reward_amount_per_year
                                              }
                                              placeholder="0"
                                              onChange={onChangeRewardAmountPerYearInvestMarket?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                              thousandSeparator=","
                                              inputMode="decimal"
                                              pattern="[0-9],*"
                                              className={`${STYLE_INPUT_INSET} w-full`}
                                            />
                                            <ICON_DELETE
                                              className=" w-5 h-5 text-maincyan flex-none cursor-pointer"
                                              onClick={onClickDeleteInvestMarket?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                            />
                                          </div>
                                        </div>
                                        <SelectTargetInvest
                                          options={OPTIONS_TARGET_INVEST}
                                          onChange={(e) =>
                                            onChangeSelectedTargetInvest(
                                              "invest_market_lists",
                                              index,
                                              indexCard,
                                              e
                                            )
                                          }
                                          value={row?.target_invest}
                                        />
                                      </div>
                                    );
                                  })}
                                <PopupMain
                                  title={"ยืนยันการลบข้อมูล"}
                                  text={
                                    <div className=" text-base">
                                      คุณต้องการลบข้อมูลรายการนี้ใช่หรือไม่
                                    </div>
                                  }
                                  button2={true}
                                  textButton={"ยืนยัน"}
                                  textButton2={"ยกเลิก"}
                                  open={
                                    openAlert &&
                                    dataIndexDeleteOne.index === index
                                  }
                                  onClick={onClickConfirmDeleteInvestMarket}
                                  onClickSecond={() => {
                                    setDataIndexDeleteOne(null);
                                    setOpenAlert(false);
                                  }}
                                />
                              </div>
                            )}

                            {/*---------- กองทุนรวมหุ้น ----------*/}
                            {row?.options_property_invest ===
                              "property_all_invest" && (
                              <div key={index}>
                                <div className=" bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9 my-2.5">
                                  <div className=" text-sm text-black font-medium">
                                    จำนวน
                                    {row?.options_property_invest_title}
                                  </div>
                                  <ButtonCounter
                                    value={row?.all_invest_lists?.length}
                                    handleNext={handleNextInvestAll?.bind(
                                      this,
                                      index
                                    )}
                                  />
                                </div>
                                {row?.all_invest_lists?.length >= 1 && (
                                  <HeaderInvest />
                                )}
                                {row?.all_invest_lists
                                  ?.sort(
                                    (a, b) =>
                                      new Date(b.created) - new Date(a.created)
                                  )
                                  ?.map((row, indexCard) => {
                                    return (
                                      <div key={indexCard} className="my-2.5">
                                        <div className={STYLE_BODY_INVEST}>
                                          <div className=" flex gap-[6px] items-center">
                                            <input
                                              placeholder="ระบุ"
                                              value={row?.title}
                                              className={`${STYLE_INPUT_INSET} w-full `}
                                              onChange={onChangeTitleInvestAll?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                            />
                                          </div>
                                          <NumericFormat
                                            maxLength={11}
                                            value={row?.amount}
                                            placeholder="0"
                                            onChange={onChangeAmountInvestAll?.bind(
                                              this,
                                              index,
                                              indexCard
                                            )}
                                            thousandSeparator=","
                                            inputMode="decimal"
                                            pattern="[0-9],*"
                                            className={`${STYLE_INPUT_INSET} w-full`}
                                          />
                                          <div className=" flex items-center w-full ">
                                            <NumericFormat
                                              maxLength={11}
                                              value={
                                                row?.reward_amount_per_year
                                              }
                                              placeholder="0"
                                              onChange={onChangeRewardAmountPerYearInvestAll?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                              thousandSeparator=","
                                              inputMode="decimal"
                                              pattern="[0-9],*"
                                              className={`${STYLE_INPUT_INSET} w-full`}
                                            />
                                            <ICON_DELETE
                                              className=" w-5 h-5 text-maincyan flex-none cursor-pointer"
                                              onClick={onClickDeleteInvestAll?.bind(
                                                this,
                                                index,
                                                indexCard,
                                                "property_all_invest"
                                              )}
                                            />
                                          </div>
                                        </div>
                                        <SelectTargetInvest
                                          options={OPTIONS_TARGET_INVEST}
                                          onChange={(e) =>
                                            onChangeSelectedTargetInvest(
                                              "all_invest_lists",
                                              index,
                                              indexCard,
                                              e
                                            )
                                          }
                                          value={row?.target_invest}
                                        />
                                      </div>
                                    );
                                  })}
                                <PopupMain
                                  title={"ยืนยันการลบข้อมูล"}
                                  text={
                                    <div className=" text-base">
                                      คุณต้องการลบข้อมูลรายการนี้ใช่หรือไม่
                                    </div>
                                  }
                                  button2={true}
                                  textButton={"ยืนยัน"}
                                  textButton2={"ยกเลิก"}
                                  open={
                                    openAlert &&
                                    dataIndexDeleteOne.index === index
                                  }
                                  onClick={onClickConfirmDeleteInvestAll}
                                  onClickSecond={() => {
                                    setDataIndexDeleteOne(null);
                                    setOpenAlert(false);
                                  }}
                                />
                              </div>
                            )}

                            {/*---------- กองทุนรวมเพื่อการเลี้ยงชีพ ----------*/}
                            {row?.options_property_invest ===
                              "property_invest_life" && (
                              <div key={index}>
                                <div className=" bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9 my-2.5">
                                  <div className=" text-sm text-black font-medium">
                                    จำนวน
                                    {row?.options_property_invest_title}
                                  </div>
                                  <ButtonCounter
                                    value={row?.invest_life_lists?.length}
                                    handleNext={handleNextInvestLife?.bind(
                                      this,
                                      index
                                    )}
                                  />
                                </div>
                                {row?.invest_life_lists?.length >= 1 && (
                                  <HeaderInvest />
                                )}
                                {row?.invest_life_lists
                                  ?.sort(
                                    (a, b) =>
                                      new Date(b.created) - new Date(a.created)
                                  )
                                  ?.map((row, indexCard) => {
                                    return (
                                      <div key={indexCard} className="my-2.5">
                                        <div className={STYLE_BODY_INVEST}>
                                          <div className=" flex gap-[6px] items-center">
                                            <input
                                              placeholder="ระบุ"
                                              value={row?.title}
                                              className={`${STYLE_INPUT_INSET} w-full `}
                                              onChange={onChangeTitleInvestLife?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                            />
                                          </div>
                                          <NumericFormat
                                            maxLength={11}
                                            value={row?.amount}
                                            placeholder="0"
                                            onChange={onChangeAmountInvestLife?.bind(
                                              this,
                                              index,
                                              indexCard
                                            )}
                                            thousandSeparator=","
                                            inputMode="decimal"
                                            pattern="[0-9],*"
                                            className={`${STYLE_INPUT_INSET} w-full`}
                                          />
                                          <div className=" flex items-center w-full ">
                                            <NumericFormat
                                              maxLength={11}
                                              value={
                                                row?.reward_amount_per_year
                                              }
                                              placeholder="0"
                                              onChange={onChangeRewardAmountPerYearInvestLife?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                              thousandSeparator=","
                                              inputMode="decimal"
                                              pattern="[0-9],*"
                                              className={`${STYLE_INPUT_INSET} w-full`}
                                            />
                                            <ICON_DELETE
                                              className=" w-5 h-5 text-maincyan flex-none cursor-pointer"
                                              onClick={onClickDeleteInvestLife?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                            />
                                          </div>
                                        </div>
                                        <SelectTargetInvest
                                          options={OPTIONS_TARGET_INVEST}
                                          onChange={(e) =>
                                            onChangeSelectedTargetInvest(
                                              "invest_life_lists",
                                              index,
                                              indexCard,
                                              e
                                            )
                                          }
                                          value={row?.target_invest}
                                        />
                                      </div>
                                    );
                                  })}
                                <PopupMain
                                  title={"ยืนยันการลบข้อมูล"}
                                  text={
                                    <div className=" text-base">
                                      คุณต้องการลบข้อมูลรายการนี้ใช่หรือไม่
                                    </div>
                                  }
                                  button2={true}
                                  textButton={"ยืนยัน"}
                                  textButton2={"ยกเลิก"}
                                  open={
                                    openAlert &&
                                    dataIndexDeleteOne.index === index
                                  }
                                  onClick={onClickConfirmDeleteInvestLife}
                                  onClickSecond={() => {
                                    setDataIndexDeleteOne(null);
                                    setOpenAlert(false);
                                  }}
                                />
                              </div>
                            )}

                            {/*---------- กองทุนรวมผสม ----------*/}
                            {row?.options_property_invest ===
                              "property_invest_mixed" && (
                              <div key={index}>
                                <div className=" bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9 my-2.5">
                                  <div className=" text-sm text-black font-medium">
                                    จำนวน
                                    {row?.options_property_invest_title}
                                  </div>
                                  <ButtonCounter
                                    value={row?.invest_mixed_lists?.length}
                                    handleNext={handleNextInvestMixed?.bind(
                                      this,
                                      index
                                    )}
                                  />
                                </div>
                                {row?.invest_mixed_lists?.length >= 1 && (
                                  <HeaderInvest />
                                )}
                                {row?.invest_mixed_lists
                                  ?.sort(
                                    (a, b) =>
                                      new Date(b.created) - new Date(a.created)
                                  )
                                  ?.map((row, indexCard) => {
                                    return (
                                      <div key={indexCard} className="my-2.5">
                                        <div className={STYLE_BODY_INVEST}>
                                          <div className=" flex gap-[6px] items-center">
                                            <input
                                              placeholder="ระบุ"
                                              value={row?.title}
                                              className={`${STYLE_INPUT_INSET} w-full `}
                                              onChange={onChangeTitleInvestMixed?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                            />
                                          </div>
                                          <NumericFormat
                                            maxLength={11}
                                            value={row?.amount}
                                            placeholder="0"
                                            onChange={onChangeAmountInvestMixed?.bind(
                                              this,
                                              index,
                                              indexCard
                                            )}
                                            thousandSeparator=","
                                            inputMode="decimal"
                                            pattern="[0-9],*"
                                            className={`${STYLE_INPUT_INSET} w-full`}
                                          />
                                          <div className=" flex items-center w-full ">
                                            <NumericFormat
                                              maxLength={11}
                                              value={
                                                row?.reward_amount_per_year
                                              }
                                              placeholder="0"
                                              onChange={onChangeRewardAmountPerYearInvestMixed?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                              thousandSeparator=","
                                              inputMode="decimal"
                                              pattern="[0-9],*"
                                              className={`${STYLE_INPUT_INSET} w-full`}
                                            />
                                            <ICON_DELETE
                                              className=" w-5 h-5 text-maincyan flex-none cursor-pointer"
                                              onClick={onClickDeleteInvestMixed?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                            />
                                          </div>
                                        </div>
                                        <SelectTargetInvest
                                          options={OPTIONS_TARGET_INVEST}
                                          onChange={(e) =>
                                            onChangeSelectedTargetInvest(
                                              "invest_mixed_lists",
                                              index,
                                              indexCard,
                                              e
                                            )
                                          }
                                          value={row?.target_invest}
                                        />
                                      </div>
                                    );
                                  })}

                                <PopupMain
                                  title={"ยืนยันการลบข้อมูล"}
                                  text={
                                    <div className=" text-base">
                                      คุณต้องการลบข้อมูลรายการนี้ใช่หรือไม่
                                    </div>
                                  }
                                  button2={true}
                                  textButton={"ยืนยัน"}
                                  textButton2={"ยกเลิก"}
                                  open={
                                    openAlert &&
                                    dataIndexDeleteOne.index === index
                                  }
                                  onClick={onClickConfirmDeleteInvestMixed}
                                  onClickSecond={() => {
                                    setDataIndexDeleteOne(null);
                                    setOpenAlert(false);
                                  }}
                                />
                              </div>
                            )}

                            {/*---------- กองทุนสำรองเลี้ยงชีพ ----------*/}
                            {row?.options_property_invest ===
                              "property_invest_provident_fund" && (
                              <div key={index}>
                                <div className=" bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9 my-2.5">
                                  <div className=" text-sm text-black font-medium">
                                    จำนวน
                                    {row?.options_property_invest_title}
                                  </div>
                                  <ButtonCounter
                                    value={
                                      row?.invest_provident_fund_lists?.length
                                    }
                                    handleNext={handleNextInvestProvidentFund?.bind(
                                      this,
                                      index
                                    )}
                                  />
                                </div>
                                {row?.invest_provident_fund_lists?.length >=
                                  1 && <HeaderInvest />}
                                {row?.invest_provident_fund_lists
                                  ?.sort(
                                    (a, b) =>
                                      new Date(b.created) - new Date(a.created)
                                  )
                                  ?.map((row, indexCard) => {
                                    return (
                                      <div key={indexCard} className="my-2.5">
                                        <div className={STYLE_BODY_INVEST}>
                                          <div className=" flex gap-[6px] items-center">
                                            <input
                                              placeholder="ระบุ"
                                              value={row?.title}
                                              className={`${STYLE_INPUT_INSET} w-full `}
                                              onChange={onChangeTitleInvestProvidentFund?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                            />
                                          </div>
                                          <NumericFormat
                                            maxLength={11}
                                            value={row?.amount}
                                            placeholder="0"
                                            onChange={onChangeAmountInvestProvidentFund?.bind(
                                              this,
                                              index,
                                              indexCard
                                            )}
                                            thousandSeparator=","
                                            inputMode="decimal"
                                            pattern="[0-9],*"
                                            className={`${STYLE_INPUT_INSET} w-full`}
                                          />
                                          <div className=" flex items-center w-full ">
                                            <NumericFormat
                                              maxLength={11}
                                              value={
                                                row?.reward_amount_per_year
                                              }
                                              placeholder="0"
                                              onChange={onChangeRewardAmountPerYearInvestProvidentFund?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                              thousandSeparator=","
                                              inputMode="decimal"
                                              pattern="[0-9],*"
                                              className={`${STYLE_INPUT_INSET} w-full`}
                                            />
                                            <ICON_DELETE
                                              className=" w-5 h-5 text-maincyan flex-none cursor-pointer"
                                              onClick={onClickDeleteInvestProvidentFund?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                            />
                                          </div>
                                        </div>
                                        <SelectTargetInvest
                                          options={OPTIONS_TARGET_INVEST}
                                          onChange={(e) =>
                                            onChangeSelectedTargetInvest(
                                              "invest_provident_fund_lists",
                                              index,
                                              indexCard,
                                              e
                                            )
                                          }
                                          value={row?.target_invest}
                                        />
                                      </div>
                                    );
                                  })}
                                <PopupMain
                                  title={"ยืนยันการลบข้อมูล"}
                                  text={
                                    <div className=" text-base">
                                      คุณต้องการลบข้อมูลรายการนี้ใช่หรือไม่
                                    </div>
                                  }
                                  button2={true}
                                  textButton={"ยืนยัน"}
                                  textButton2={"ยกเลิก"}
                                  open={
                                    openAlert &&
                                    dataIndexDeleteOne.index === index
                                  }
                                  onClick={
                                    onClickConfirmDeleteInvestProvidentFund
                                  }
                                  onClickSecond={() => {
                                    setDataIndexDeleteOne(null);
                                    setOpenAlert(false);
                                  }}
                                />
                              </div>
                            )}

                            {/*---------- เงินออมเพื่อวัยเกษียณอื่น ๆ ----------*/}
                            {row?.options_property_invest ===
                              "property_invest_other_retirement_savings" && (
                              <div key={index}>
                                <div className=" bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9 my-2.5">
                                  <div className=" text-sm text-black font-medium">
                                    จำนวน
                                    {row?.options_property_invest_title}
                                  </div>
                                  <ButtonCounter
                                    value={
                                      row?.invest_other_retirement_savings_lists
                                        ?.length
                                    }
                                    handleNext={handleNextInvestOtherRetirementSavings?.bind(
                                      this,
                                      index
                                    )}
                                  />
                                </div>
                                {row?.invest_other_retirement_savings_lists
                                  ?.length >= 1 && <HeaderInvest />}
                                {row?.invest_other_retirement_savings_lists
                                  ?.sort(
                                    (a, b) =>
                                      new Date(b.created) - new Date(a.created)
                                  )
                                  ?.map((row, indexCard) => {
                                    return (
                                      <div key={indexCard} className="my-2.5">
                                        <div className={STYLE_BODY_INVEST}>
                                          <div className=" flex gap-[6px] items-center">
                                            <input
                                              placeholder="ระบุ"
                                              value={row?.title}
                                              className={`${STYLE_INPUT_INSET} w-full `}
                                              onChange={onChangeTitleInvestOtherRetirementSavings?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                            />
                                          </div>
                                          <NumericFormat
                                            maxLength={11}
                                            value={row?.amount}
                                            placeholder="0"
                                            onChange={onChangeAmountInvestOtherRetirementSavings?.bind(
                                              this,
                                              index,
                                              indexCard
                                            )}
                                            thousandSeparator=","
                                            inputMode="decimal"
                                            pattern="[0-9],*"
                                            className={`${STYLE_INPUT_INSET} w-full`}
                                          />
                                          <div className=" flex items-center w-full ">
                                            <NumericFormat
                                              maxLength={11}
                                              value={
                                                row?.reward_amount_per_year
                                              }
                                              placeholder="0"
                                              onChange={onChangeRewardAmountPerYearInvestOtherRetirementSavings?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                              thousandSeparator=","
                                              inputMode="decimal"
                                              pattern="[0-9],*"
                                              className={`${STYLE_INPUT_INSET} w-full`}
                                            />
                                            <ICON_DELETE
                                              className=" w-5 h-5 text-maincyan flex-none cursor-pointer"
                                              onClick={onClickDeleteInvestOtherRetirementSavings?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                            />
                                          </div>
                                        </div>
                                        <SelectTargetInvest
                                          options={OPTIONS_TARGET_INVEST}
                                          onChange={(e) =>
                                            onChangeSelectedTargetInvest(
                                              "invest_other_retirement_savings_lists",
                                              index,
                                              indexCard,
                                              e
                                            )
                                          }
                                          value={row?.target_invest}
                                        />
                                      </div>
                                    );
                                  })}
                                <PopupMain
                                  title={"ยืนยันการลบข้อมูล"}
                                  text={
                                    <div className=" text-base">
                                      คุณต้องการลบข้อมูลรายการนี้ใช่หรือไม่
                                    </div>
                                  }
                                  button2={true}
                                  textButton={"ยืนยัน"}
                                  textButton2={"ยกเลิก"}
                                  open={
                                    openAlert &&
                                    dataIndexDeleteOne.index === index
                                  }
                                  onClick={
                                    onClickConfirmDeleteInvestOtherRetirementSavings
                                  }
                                  onClickSecond={() => {
                                    setDataIndexDeleteOne(null);
                                    setOpenAlert(false);
                                  }}
                                />
                              </div>
                            )}

                            {/*---------- ตลาดเงินอื่น ๆ ----------*/}
                            {row?.options_property_invest ===
                              "property_money_market_other" && (
                              <div key={index}>
                                <div className=" bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9 my-2.5">
                                  <div className=" text-sm text-black font-medium">
                                    จำนวน
                                    {row?.options_property_invest_title}
                                  </div>
                                  <ButtonCounter
                                    value={
                                      row?.invest_money_market_other_lists
                                        ?.length
                                    }
                                    handleNext={handleNextInvestMoneyMarketOther?.bind(
                                      this,
                                      index
                                    )}
                                  />
                                </div>
                                {row?.invest_money_market_other_lists?.length >=
                                  1 && <HeaderInvest />}
                                {row?.invest_money_market_other_lists
                                  ?.sort(
                                    (a, b) =>
                                      new Date(b.created) - new Date(a.created)
                                  )
                                  ?.map((row, indexCard) => {
                                    return (
                                      <div key={indexCard} className="my-2.5">
                                        <div className={STYLE_BODY_INVEST}>
                                          <div className=" flex gap-[6px] items-center">
                                            <input
                                              placeholder="ระบุ"
                                              value={row?.title}
                                              className={`${STYLE_INPUT_INSET} w-full `}
                                              onChange={onChangeTitleInvestMoneyMarketOther?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                            />
                                          </div>
                                          <NumericFormat
                                            maxLength={11}
                                            value={row?.amount}
                                            placeholder="0"
                                            onChange={onChangeAmountInvestMoneyMarketOther?.bind(
                                              this,
                                              index,
                                              indexCard
                                            )}
                                            thousandSeparator=","
                                            inputMode="decimal"
                                            pattern="[0-9],*"
                                            className={`${STYLE_INPUT_INSET} w-full`}
                                          />
                                          <div className=" flex items-center w-full ">
                                            <NumericFormat
                                              maxLength={11}
                                              value={
                                                row?.reward_amount_per_year
                                              }
                                              placeholder="0"
                                              onChange={onChangeRewardAmountPerYearInvestMoneyMarketOther?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                              thousandSeparator=","
                                              inputMode="decimal"
                                              pattern="[0-9],*"
                                              className={`${STYLE_INPUT_INSET} w-full`}
                                            />
                                            <ICON_DELETE
                                              className=" w-5 h-5 text-maincyan flex-none cursor-pointer"
                                              onClick={onClickDeleteInvestMoneyMarketOther?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                            />
                                          </div>
                                        </div>
                                        <SelectTargetInvest
                                          options={OPTIONS_TARGET_INVEST}
                                          onChange={(e) =>
                                            onChangeSelectedTargetInvest(
                                              "invest_money_market_other_lists",
                                              index,
                                              indexCard,
                                              e
                                            )
                                          }
                                          value={row?.target_invest}
                                        />
                                      </div>
                                    );
                                  })}
                                <PopupMain
                                  title={"ยืนยันการลบข้อมูล"}
                                  text={
                                    <div className=" text-base">
                                      คุณต้องการลบข้อมูลรายการนี้ใช่หรือไม่
                                    </div>
                                  }
                                  button2={true}
                                  textButton={"ยืนยัน"}
                                  textButton2={"ยกเลิก"}
                                  open={
                                    openAlert &&
                                    dataIndexDeleteOne.index === index
                                  }
                                  onClick={
                                    onClickConfirmDeleteInvestMoneyMarketOther
                                  }
                                  onClickSecond={() => {
                                    setDataIndexDeleteOne(null);
                                    setOpenAlert(false);
                                  }}
                                />
                              </div>
                            )}

                            {/*---------- ตราสารอนุพันธ์ ----------*/}
                            {row?.options_property_invest ===
                              "property_invest_derivative_instruments" && (
                              <div key={index}>
                                <div className=" bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9 my-2.5">
                                  <div className=" text-sm text-black font-medium">
                                    จำนวน
                                    {row?.options_property_invest_title}
                                  </div>
                                  <ButtonCounter
                                    value={
                                      row?.invest_derivative_instruments_lists
                                        ?.length
                                    }
                                    handleNext={handleNextInvestDerivativeInstruments?.bind(
                                      this,
                                      index
                                    )}
                                  />
                                </div>
                                {row?.invest_derivative_instruments_lists
                                  ?.length >= 1 && <HeaderInvest />}
                                {row?.invest_derivative_instruments_lists
                                  ?.sort(
                                    (a, b) =>
                                      new Date(b.created) - new Date(a.created)
                                  )
                                  ?.map((row, indexCard) => {
                                    return (
                                      <div key={indexCard} className="my-2.5">
                                        <div className={STYLE_BODY_INVEST}>
                                          <div className=" flex gap-[6px] items-center">
                                            <input
                                              placeholder="ระบุ"
                                              value={row?.title}
                                              className={`${STYLE_INPUT_INSET} w-full `}
                                              onChange={onChangeTitleInvestDerivativeInstruments?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                            />
                                          </div>
                                          <NumericFormat
                                            maxLength={11}
                                            value={row?.amount}
                                            placeholder="0"
                                            onChange={onChangeAmountInvestDerivativeInstruments?.bind(
                                              this,
                                              index,
                                              indexCard
                                            )}
                                            thousandSeparator=","
                                            inputMode="decimal"
                                            pattern="[0-9],*"
                                            className={`${STYLE_INPUT_INSET} w-full`}
                                          />
                                          <div className=" flex items-center w-full ">
                                            <NumericFormat
                                              maxLength={11}
                                              value={
                                                row?.reward_amount_per_year
                                              }
                                              placeholder="0"
                                              onChange={onChangeRewardAmountPerYearInvestDerivativeInstruments?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                              thousandSeparator=","
                                              inputMode="decimal"
                                              pattern="[0-9],*"
                                              className={`${STYLE_INPUT_INSET} w-full`}
                                            />
                                            <ICON_DELETE
                                              className=" w-5 h-5 text-maincyan flex-none cursor-pointer"
                                              onClick={onClickDeleteInvestDerivativeInstruments?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                            />
                                          </div>
                                        </div>
                                        <SelectTargetInvest
                                          options={OPTIONS_TARGET_INVEST}
                                          onChange={(e) =>
                                            onChangeSelectedTargetInvest(
                                              "invest_derivative_instruments_lists",
                                              index,
                                              indexCard,
                                              e
                                            )
                                          }
                                          value={row?.target_invest}
                                        />
                                      </div>
                                    );
                                  })}
                                <PopupMain
                                  title={"ยืนยันการลบข้อมูล"}
                                  text={
                                    <div className=" text-base">
                                      คุณต้องการลบข้อมูลรายการนี้ใช่หรือไม่
                                    </div>
                                  }
                                  button2={true}
                                  textButton={"ยืนยัน"}
                                  textButton2={"ยกเลิก"}
                                  open={
                                    openAlert &&
                                    dataIndexDeleteOne.index === index
                                  }
                                  onClick={
                                    onClickConfirmDeleteInvestDerivativeInstruments
                                  }
                                  onClickSecond={() => {
                                    setDataIndexDeleteOne(null);
                                    setOpenAlert(false);
                                  }}
                                />
                              </div>
                            )}

                            {/*---------- มูลค่าเงินสดในกรมธรรม์ประกันชีวิต ----------*/}
                            {row?.options_property_invest ===
                              "property_invest_life_insurance_policy" && (
                              <div key={index}>
                                <div className=" bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9 my-2.5">
                                  <div className=" text-sm text-black font-medium">
                                    จำนวน
                                    {row?.options_property_invest_title}
                                  </div>
                                  <ButtonCounter
                                    value={
                                      row?.invest_life_insurance_policy_lists
                                        ?.length
                                    }
                                    handleNext={handleNextInvestLifeInsurancePolicy?.bind(
                                      this,
                                      index
                                    )}
                                  />
                                </div>
                                {row?.invest_life_insurance_policy_lists
                                  ?.length >= 1 && <HeaderInvest />}
                                {row?.invest_life_insurance_policy_lists
                                  ?.sort(
                                    (a, b) =>
                                      new Date(b.created) - new Date(a.created)
                                  )
                                  ?.map((row, indexCard) => {
                                    return (
                                      <div key={indexCard} className="my-2.5">
                                        <div className={STYLE_BODY_INVEST}>
                                          <div className=" flex gap-[6px] items-center">
                                            <input
                                              placeholder="ระบุ"
                                              value={row?.title}
                                              className={`${STYLE_INPUT_INSET} w-full `}
                                              onChange={onChangeTitleInvestLifeInsurancePolicy?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                            />
                                          </div>
                                          <NumericFormat
                                            maxLength={11}
                                            value={row?.amount}
                                            placeholder="0"
                                            onChange={onChangeAmountInvestLifeInsurancePolicy?.bind(
                                              this,
                                              index,
                                              indexCard
                                            )}
                                            thousandSeparator=","
                                            inputMode="decimal"
                                            pattern="[0-9],*"
                                            className={`${STYLE_INPUT_INSET} w-full`}
                                          />
                                          <div className=" flex items-center w-full ">
                                            <NumericFormat
                                              maxLength={11}
                                              value={
                                                row?.reward_amount_per_year
                                              }
                                              placeholder="0"
                                              onChange={onChangeRewardAmountPerYearInvestLifeInsurancePolicy?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                              thousandSeparator=","
                                              inputMode="decimal"
                                              pattern="[0-9],*"
                                              className={`${STYLE_INPUT_INSET} w-full`}
                                            />
                                            <ICON_DELETE
                                              className=" w-5 h-5 text-maincyan flex-none cursor-pointer"
                                              onClick={onClickDeleteInvestLifeInsurancePolicy?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                            />
                                          </div>
                                        </div>
                                        <SelectTargetInvest
                                          options={OPTIONS_TARGET_INVEST}
                                          onChange={(e) =>
                                            onChangeSelectedTargetInvest(
                                              "invest_life_insurance_policy_lists",
                                              index,
                                              indexCard,
                                              e
                                            )
                                          }
                                          value={row?.target_invest}
                                        />
                                      </div>
                                    );
                                  })}
                                <PopupMain
                                  title={"ยืนยันการลบข้อมูล"}
                                  text={
                                    <div className=" text-base">
                                      คุณต้องการลบข้อมูลรายการนี้ใช่หรือไม่
                                    </div>
                                  }
                                  button2={true}
                                  textButton={"ยืนยัน"}
                                  textButton2={"ยกเลิก"}
                                  open={
                                    openAlert &&
                                    dataIndexDeleteOne.index === index
                                  }
                                  onClick={
                                    onClickConfirmDeleteInvestLifeInsurancePolicy
                                  }
                                  onClickSecond={() => {
                                    setDataIndexDeleteOne(null);
                                    setOpenAlert(false);
                                  }}
                                />
                              </div>
                            )}

                            {/*---------- สลากออมสิน ----------*/}
                            {row?.options_property_invest ===
                              "property_aomsin_bond_policy" && (
                              <div key={index}>
                                <div className=" bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9 my-2.5">
                                  <div className=" text-sm text-black font-medium">
                                    จำนวน
                                    {row?.options_property_invest_title}
                                  </div>
                                  <ButtonCounter
                                    value={
                                      row?.aomsin_bond_policy_lists?.length
                                    }
                                    handleNext={handleNextInvestAomsinBondPolicy?.bind(
                                      this,
                                      index
                                    )}
                                  />
                                </div>
                                {row?.aomsin_bond_policy_lists?.length >= 1 && (
                                  <HeaderInvest />
                                )}
                                {row?.aomsin_bond_policy_lists
                                  ?.sort(
                                    (a, b) =>
                                      new Date(b.created) - new Date(a.created)
                                  )
                                  ?.map((row, indexCard) => {
                                    return (
                                      <div key={indexCard} className="my-2.5">
                                        <div className={STYLE_BODY_INVEST}>
                                          <div className=" flex gap-[6px] items-center">
                                            <input
                                              placeholder="ระบุ"
                                              value={row?.title}
                                              className={`${STYLE_INPUT_INSET} w-full `}
                                              onChange={onChangeTitleInvestLifeAomsinBondPolicy?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                            />
                                          </div>
                                          <NumericFormat
                                            maxLength={11}
                                            value={row?.amount}
                                            placeholder="0"
                                            onChange={onChangeAmountInvestLifeAomsinBondPolicy?.bind(
                                              this,
                                              index,
                                              indexCard
                                            )}
                                            thousandSeparator=","
                                            inputMode="decimal"
                                            pattern="[0-9],*"
                                            className={`${STYLE_INPUT_INSET} w-full`}
                                          />
                                          <div className=" flex items-center w-full ">
                                            <NumericFormat
                                              maxLength={11}
                                              value={
                                                row?.reward_amount_per_year
                                              }
                                              placeholder="0"
                                              onChange={onChangeRewardAmountPerYearAomsinBondPolicy?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                              thousandSeparator=","
                                              inputMode="decimal"
                                              pattern="[0-9],*"
                                              className={`${STYLE_INPUT_INSET} w-full`}
                                            />
                                            <ICON_DELETE
                                              className=" w-5 h-5 text-maincyan flex-none cursor-pointer"
                                              onClick={onClickDeleteInvestLifeAomsinBondPolicy?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                            />
                                          </div>
                                        </div>
                                        <SelectTargetInvest
                                          options={OPTIONS_TARGET_INVEST}
                                          onChange={(e) =>
                                            onChangeSelectedTargetInvest(
                                              "aomsin_bond_policy_lists",
                                              index,
                                              indexCard,
                                              e
                                            )
                                          }
                                          value={row?.target_invest}
                                        />
                                      </div>
                                    );
                                  })}
                                <PopupMain
                                  title={"ยืนยันการลบข้อมูล"}
                                  text={
                                    <div className=" text-base">
                                      คุณต้องการลบข้อมูลรายการนี้ใช่หรือไม่
                                    </div>
                                  }
                                  button2={true}
                                  textButton={"ยืนยัน"}
                                  textButton2={"ยกเลิก"}
                                  open={
                                    openAlert &&
                                    dataIndexDeleteOne.index === index
                                  }
                                  onClick={
                                    onClickConfirmDeleteInvestAomsinBondPolicy
                                  }
                                  onClickSecond={() => {
                                    setDataIndexDeleteOne(null);
                                    setOpenAlert(false);
                                  }}
                                />
                              </div>
                            )}

                            {/*---------- ตราสารหนี้อื่นๆ ----------*/}
                            {row?.options_property_invest ===
                              "property_debt_instruments" && (
                              <div key={index}>
                                <div className=" bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9 my-2.5">
                                  <div className=" text-sm text-black font-medium">
                                    จำนวน
                                    {row?.options_property_invest_title}
                                  </div>
                                  <ButtonCounter
                                    value={
                                      row?.property_debt_instruments_lists
                                        ?.length
                                    }
                                    handleNext={handleNextInvestPropertyDebtInstruments?.bind(
                                      this,
                                      index
                                    )}
                                  />
                                </div>
                                {row?.property_debt_instruments_lists?.length >=
                                  1 && <HeaderInvest />}
                                {row?.property_debt_instruments_lists
                                  ?.sort(
                                    (a, b) =>
                                      new Date(b.created) - new Date(a.created)
                                  )
                                  ?.map((row, indexCard) => {
                                    return (
                                      <div key={indexCard} className="my-2.5">
                                        <div className={STYLE_BODY_INVEST}>
                                          <div className=" flex gap-[6px] items-center">
                                            <input
                                              placeholder="ระบุ"
                                              value={row?.title}
                                              className={`${STYLE_INPUT_INSET} w-full `}
                                              onChange={onChangeTitleInvestPropertyDebtInstruments?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                            />
                                          </div>
                                          <NumericFormat
                                            maxLength={11}
                                            value={row?.amount}
                                            placeholder="0"
                                            onChange={onChangeAmountInvestPropertyDebtInstruments?.bind(
                                              this,
                                              index,
                                              indexCard
                                            )}
                                            thousandSeparator=","
                                            inputMode="decimal"
                                            pattern="[0-9],*"
                                            className={`${STYLE_INPUT_INSET} w-full`}
                                          />
                                          <div className=" flex items-center w-full ">
                                            <NumericFormat
                                              maxLength={11}
                                              value={
                                                row?.reward_amount_per_year
                                              }
                                              placeholder="0"
                                              onChange={onChangeRewardAmountPerYearPropertyDebtInstruments?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                              thousandSeparator=","
                                              inputMode="decimal"
                                              pattern="[0-9],*"
                                              className={`${STYLE_INPUT_INSET} w-full`}
                                            />
                                            <ICON_DELETE
                                              className=" w-5 h-5 text-maincyan flex-none cursor-pointer"
                                              onClick={onClickDeleteInvestPropertyDebtInstruments?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                            />
                                          </div>
                                        </div>
                                        <SelectTargetInvest
                                          options={OPTIONS_TARGET_INVEST}
                                          onChange={(e) =>
                                            onChangeSelectedTargetInvest(
                                              "property_debt_instruments_lists",
                                              index,
                                              indexCard,
                                              e
                                            )
                                          }
                                          value={row?.target_invest}
                                        />
                                      </div>
                                    );
                                  })}
                                <PopupMain
                                  title={"ยืนยันการลบข้อมูล"}
                                  text={
                                    <div className=" text-base">
                                      คุณต้องการลบข้อมูลรายการนี้ใช่หรือไม่
                                    </div>
                                  }
                                  button2={true}
                                  textButton={"ยืนยัน"}
                                  textButton2={"ยกเลิก"}
                                  open={
                                    openAlert &&
                                    dataIndexDeleteOne.index === index
                                  }
                                  onClick={
                                    onClickConfirmDeleteInvestPropertyDebtInstruments
                                  }
                                  onClickSecond={() => {
                                    setDataIndexDeleteOne(null);
                                    setOpenAlert(false);
                                  }}
                                />
                              </div>
                            )}

                            {/*---------- อสังหาริมทรัพย์ ----------*/}
                            {row?.options_property_invest ===
                              "property_invest_real_estate" && (
                              <div key={index}>
                                <div className=" bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9 my-2.5">
                                  <div className=" text-sm text-black font-medium">
                                    จำนวน
                                    {row?.options_property_invest_title}
                                  </div>
                                  <ButtonCounter
                                    value={
                                      row?.invest_real_estate_lists?.length
                                    }
                                    handleNext={handleNextInvestRealEstate?.bind(
                                      this,
                                      index
                                    )}
                                  />
                                </div>
                                {row?.invest_real_estate_lists?.length >= 1 && (
                                  <HeaderInvest />
                                )}
                                {row?.invest_real_estate_lists
                                  ?.sort(
                                    (a, b) =>
                                      new Date(b.created) - new Date(a.created)
                                  )
                                  ?.map((row, indexCard) => {
                                    return (
                                      <div key={indexCard} className="my-2.5">
                                        <div className={STYLE_BODY_INVEST}>
                                          <div className=" flex gap-[6px] items-center">
                                            <input
                                              placeholder="ระบุ"
                                              value={row?.title}
                                              className={`${STYLE_INPUT_INSET} w-full `}
                                              onChange={onChangeTitleInvestRealEstate?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                            />
                                          </div>
                                          <NumericFormat
                                            maxLength={11}
                                            value={row?.amount}
                                            placeholder="0"
                                            onChange={onChangeAmountInvestRealEstate?.bind(
                                              this,
                                              index,
                                              indexCard
                                            )}
                                            thousandSeparator=","
                                            inputMode="decimal"
                                            pattern="[0-9],*"
                                            className={`${STYLE_INPUT_INSET} w-full`}
                                          />
                                          <div className=" flex items-center w-full ">
                                            <NumericFormat
                                              maxLength={11}
                                              value={
                                                row?.reward_amount_per_year
                                              }
                                              placeholder="0"
                                              onChange={onChangeRewardAmountPerYearInvestRealEstate?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                              thousandSeparator=","
                                              inputMode="decimal"
                                              pattern="[0-9],*"
                                              className={`${STYLE_INPUT_INSET} w-full`}
                                            />
                                            <ICON_DELETE
                                              className=" w-5 h-5 text-maincyan flex-none cursor-pointer"
                                              onClick={onClickDeleteInvestRealEstate?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                            />
                                          </div>
                                        </div>
                                        <SelectTargetInvest
                                          options={OPTIONS_TARGET_INVEST}
                                          onChange={(e) =>
                                            onChangeSelectedTargetInvest(
                                              "invest_real_estate_lists",
                                              index,
                                              indexCard,
                                              e
                                            )
                                          }
                                          value={row?.target_invest}
                                        />
                                        <div className="w-fit ">
                                          <CheckBoxSecond
                                            name={`debt_free_invest_real_estate_${indexCard}`}
                                            isRight={true}
                                            label_title={"ปลอดภาระหนี้"}
                                            checked={
                                              addInvest.find(
                                                (item) =>
                                                  item.options_property_invest_title ===
                                                  "อสังหาริมทรัพย์ (สินทรัพย์ทางเลือก)"
                                              )?.invest_real_estate_lists?.[
                                                indexCard
                                              ]?.debt_free
                                            }
                                            onClick={(e) => {
                                              handleCheckboxInvestChange(
                                                "invest_real_estate_lists",
                                                index,
                                                indexCard,
                                                "debt_free",
                                                e.target.checked
                                              );
                                            }}
                                          />
                                        </div>
                                      </div>
                                    );
                                  })}
                                <PopupMain
                                  title={"ยืนยันการลบข้อมูล"}
                                  text={
                                    <div className=" text-base">
                                      คุณต้องการลบข้อมูลรายการนี้ใช่หรือไม่
                                    </div>
                                  }
                                  button2={true}
                                  textButton={"ยืนยัน"}
                                  textButton2={"ยกเลิก"}
                                  open={
                                    openAlert &&
                                    dataIndexDeleteOne.index === index
                                  }
                                  onClick={onClickConfirmDeleteInvestRealEstate}
                                  onClickSecond={() => {
                                    setDataIndexDeleteOne(null);
                                    setOpenAlert(false);
                                  }}
                                />
                              </div>
                            )}

                            {/*---------- มูลค่าทองคำ ----------*/}
                            {row?.options_property_invest ===
                              "property_invest_gold_cost" && (
                              <div key={index}>
                                <div className=" bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9 my-2.5">
                                  <div className=" text-sm text-black font-medium">
                                    จำนวน
                                    {row?.options_property_invest_title}
                                  </div>
                                  <ButtonCounter
                                    value={row?.invest_gold_cost_lists?.length}
                                    handleNext={handleNextInvestGoldCost?.bind(
                                      this,
                                      index
                                    )}
                                  />
                                </div>
                                {row?.invest_gold_cost_lists?.length >= 1 && (
                                  <HeaderInvest />
                                )}
                                {row?.invest_gold_cost_lists
                                  ?.sort(
                                    (a, b) =>
                                      new Date(b.created) - new Date(a.created)
                                  )
                                  ?.map((row, indexCard) => {
                                    return (
                                      <div key={indexCard} className="my-2.5">
                                        <div className={STYLE_BODY_INVEST}>
                                          <div className=" flex gap-[6px] items-center">
                                            <input
                                              placeholder="ระบุ"
                                              value={row?.title}
                                              className={`${STYLE_INPUT_INSET} w-full `}
                                              onChange={onChangeTitleInvestGoldCost?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                            />
                                          </div>
                                          <NumericFormat
                                            maxLength={11}
                                            value={row?.amount}
                                            placeholder="0"
                                            onChange={onChangeAmountInvestGoldCost?.bind(
                                              this,
                                              index,
                                              indexCard
                                            )}
                                            thousandSeparator=","
                                            inputMode="decimal"
                                            pattern="[0-9],*"
                                            className={`${STYLE_INPUT_INSET} w-full`}
                                          />
                                          <div className=" flex items-center w-full ">
                                            <NumericFormat
                                              maxLength={11}
                                              value={
                                                row?.reward_amount_per_year
                                              }
                                              placeholder="0"
                                              onChange={onChangeRewardAmountPerYearInvestGoldCost?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                              thousandSeparator=","
                                              inputMode="decimal"
                                              pattern="[0-9],*"
                                              className={`${STYLE_INPUT_INSET} w-full`}
                                            />
                                            <ICON_DELETE
                                              className=" w-5 h-5 text-maincyan flex-none cursor-pointer"
                                              onClick={onClickDeleteInvestGoldCost?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                            />
                                          </div>
                                        </div>
                                        <SelectTargetInvest
                                          options={OPTIONS_TARGET_INVEST}
                                          onChange={(e) =>
                                            onChangeSelectedTargetInvest(
                                              "invest_gold_cost_lists",
                                              index,
                                              indexCard,
                                              e
                                            )
                                          }
                                          value={row?.target_invest}
                                        />
                                      </div>
                                    );
                                  })}
                                <PopupMain
                                  title={"ยืนยันการลบข้อมูล"}
                                  text={
                                    <div className=" text-base">
                                      คุณต้องการลบข้อมูลรายการนี้ใช่หรือไม่
                                    </div>
                                  }
                                  button2={true}
                                  textButton={"ยืนยัน"}
                                  textButton2={"ยกเลิก"}
                                  open={
                                    openAlert &&
                                    dataIndexDeleteOne.index === index
                                  }
                                  onClick={onClickConfirmDeleteInvestGoldCost}
                                  onClickSecond={() => {
                                    setDataIndexDeleteOne(null);
                                    setOpenAlert(false);
                                  }}
                                />
                              </div>
                            )}

                            {/*---------- หุ้นในส่วนที่เป็นเจ้าของ ----------*/}
                            {row?.options_property_invest ===
                              "property_invest_owner" && (
                              <div key={index}>
                                <div className=" bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9 my-2.5">
                                  <div className=" text-sm text-black font-medium">
                                    จำนวน
                                    {row?.options_property_invest_title}
                                  </div>
                                  <ButtonCounter
                                    value={row?.invest_owner_lists?.length}
                                    handleNext={handleNextInvestOwner?.bind(
                                      this,
                                      index
                                    )}
                                  />
                                </div>
                                {row?.invest_owner_lists?.length >= 1 && (
                                  <HeaderInvest />
                                )}
                                {row?.invest_owner_lists
                                  ?.sort(
                                    (a, b) =>
                                      new Date(b.created) - new Date(a.created)
                                  )
                                  ?.map((row, indexCard) => {
                                    return (
                                      <div key={indexCard} className="my-2.5">
                                        <div className={STYLE_BODY_INVEST}>
                                          <div className=" flex gap-[6px] items-center">
                                            <input
                                              placeholder="ระบุ"
                                              value={row?.title}
                                              className={`${STYLE_INPUT_INSET} w-full `}
                                              onChange={onChangeTitleInvestOwner?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                            />
                                          </div>
                                          <NumericFormat
                                            maxLength={11}
                                            value={row?.amount}
                                            placeholder="0"
                                            onChange={onChangeAmountInvestOwner?.bind(
                                              this,
                                              index,
                                              indexCard
                                            )}
                                            thousandSeparator=","
                                            inputMode="decimal"
                                            pattern="[0-9],*"
                                            className={`${STYLE_INPUT_INSET} w-full`}
                                          />
                                          <div className=" flex items-center w-full ">
                                            <NumericFormat
                                              maxLength={11}
                                              value={
                                                row?.reward_amount_per_year
                                              }
                                              placeholder="0"
                                              onChange={onChangeRewardAmountPerYearInvestOwner?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                              thousandSeparator=","
                                              inputMode="decimal"
                                              pattern="[0-9],*"
                                              className={`${STYLE_INPUT_INSET} w-full`}
                                            />
                                            <ICON_DELETE
                                              className=" w-5 h-5 text-maincyan flex-none cursor-pointer"
                                              onClick={onClickDeleteInvestOwner?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                            />
                                          </div>
                                        </div>
                                        <SelectTargetInvest
                                          options={OPTIONS_TARGET_INVEST}
                                          onChange={(e) =>
                                            onChangeSelectedTargetInvest(
                                              "invest_owner_lists",
                                              index,
                                              indexCard,
                                              e
                                            )
                                          }
                                          value={row?.target_invest}
                                        />
                                      </div>
                                    );
                                  })}
                                <PopupMain
                                  title={"ยืนยันการลบข้อมูล"}
                                  text={
                                    <div className=" text-base">
                                      คุณต้องการลบข้อมูลรายการนี้ใช่หรือไม่
                                    </div>
                                  }
                                  button2={true}
                                  textButton={"ยืนยัน"}
                                  textButton2={"ยกเลิก"}
                                  open={
                                    openAlert &&
                                    dataIndexDeleteOne.index === index
                                  }
                                  onClick={onClickConfirmDeleteInvestOwner}
                                  onClickSecond={() => {
                                    setDataIndexDeleteOne(null);
                                    setOpenAlert(false);
                                  }}
                                />
                              </div>
                            )}

                            {/*---------- ตราสารทุนอื่นๆ ----------*/}
                            {row?.options_property_invest ===
                              "property_equity_instruments" && (
                              <div key={index}>
                                <div className=" bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9 my-2.5">
                                  <div className=" text-sm text-black font-medium">
                                    จำนวน
                                    {row?.options_property_invest_title}
                                  </div>
                                  <ButtonCounter
                                    value={
                                      row?.invest_equity_instruments_lists
                                        ?.length
                                    }
                                    handleNext={handleNextInvestPropertyEquityInstruments?.bind(
                                      this,
                                      index
                                    )}
                                  />
                                </div>
                                {row?.invest_equity_instruments_lists?.length >=
                                  1 && <HeaderInvest />}
                                {row?.invest_equity_instruments_lists
                                  ?.sort(
                                    (a, b) =>
                                      new Date(b.created) - new Date(a.created)
                                  )
                                  ?.map((row, indexCard) => {
                                    return (
                                      <div key={indexCard} className="my-2.5">
                                        <div className={STYLE_BODY_INVEST}>
                                          <div className=" flex gap-[6px] items-center">
                                            <input
                                              placeholder="ระบุ"
                                              value={row?.title}
                                              className={`${STYLE_INPUT_INSET} w-full `}
                                              onChange={onChangeTitlePropertyEquityInstruments?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                            />
                                          </div>
                                          <NumericFormat
                                            maxLength={11}
                                            value={row?.amount}
                                            placeholder="0"
                                            onChange={onChangeAmountPropertyEquityInstruments?.bind(
                                              this,
                                              index,
                                              indexCard
                                            )}
                                            thousandSeparator=","
                                            inputMode="decimal"
                                            pattern="[0-9],*"
                                            className={`${STYLE_INPUT_INSET} w-full`}
                                          />
                                          <div className=" flex items-center w-full ">
                                            <NumericFormat
                                              maxLength={11}
                                              value={
                                                row?.reward_amount_per_year
                                              }
                                              placeholder="0"
                                              onChange={onChangeAmountPerYearPropertyEquityInstruments?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                              thousandSeparator=","
                                              inputMode="decimal"
                                              pattern="[0-9],*"
                                              className={`${STYLE_INPUT_INSET} w-full`}
                                            />
                                            <ICON_DELETE
                                              className=" w-5 h-5 text-maincyan flex-none cursor-pointer"
                                              onClick={onClickDeletePropertyEquityInstruments?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                            />
                                          </div>
                                        </div>
                                        <SelectTargetInvest
                                          options={OPTIONS_TARGET_INVEST}
                                          onChange={(e) =>
                                            onChangeSelectedTargetInvest(
                                              "invest_equity_instruments_lists",
                                              index,
                                              indexCard,
                                              e
                                            )
                                          }
                                          value={row?.target_invest}
                                        />
                                      </div>
                                    );
                                  })}
                                <PopupMain
                                  title={"ยืนยันการลบข้อมูล"}
                                  text={
                                    <div className=" text-base">
                                      คุณต้องการลบข้อมูลรายการนี้ใช่หรือไม่
                                    </div>
                                  }
                                  button2={true}
                                  textButton={"ยืนยัน"}
                                  textButton2={"ยกเลิก"}
                                  open={
                                    openAlert &&
                                    dataIndexDeleteOne.index === index
                                  }
                                  onClick={
                                    onClickConfirmDeletePropertyEquityInstruments
                                  }
                                  onClickSecond={() => {
                                    setDataIndexDeleteOne(null);
                                    setOpenAlert(false);
                                  }}
                                />
                              </div>
                            )}

                            {/*---------- เงินลงทุนอื่น ๆ ----------*/}
                            {row?.options_property_invest ===
                              "property_invest_other" && (
                              <div key={index}>
                                <div className=" bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9 my-2.5">
                                  <div className=" text-sm text-black font-medium">
                                    จำนวน
                                    {row?.options_property_invest_title}
                                  </div>
                                  <ButtonCounter
                                    value={
                                      row?.invest_other_lists?.length +
                                      ReturnInsurance?.length
                                    }
                                    handleNext={handleNextInvestOther?.bind(
                                      this,
                                      index
                                    )}
                                  />
                                </div>
                                {row?.invest_other_lists?.length >= 1 && (
                                  <HeaderInvest />
                                )}
                                {row?.invest_other_lists
                                  ?.sort(
                                    (a, b) =>
                                      new Date(b.created) - new Date(a.created)
                                  )
                                  ?.map((row, indexCard) => {
                                    return (
                                      <div key={indexCard} className="my-2.5">
                                        <div className={STYLE_BODY_INVEST}>
                                          <div className=" flex gap-[6px] items-center">
                                            <input
                                              placeholder="ระบุ"
                                              value={row?.title}
                                              className={`${STYLE_INPUT_INSET} w-full `}
                                              onChange={onChangeTitleInvestOther?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                            />
                                          </div>
                                          <NumericFormat
                                            maxLength={11}
                                            value={row?.amount}
                                            placeholder="0"
                                            onChange={onChangeAmountInvestOther?.bind(
                                              this,
                                              index,
                                              indexCard
                                            )}
                                            thousandSeparator=","
                                            inputMode="decimal"
                                            pattern="[0-9],*"
                                            className={`${STYLE_INPUT_INSET} w-full`}
                                          />
                                          <div className=" flex items-center w-full ">
                                            <NumericFormat
                                              maxLength={11}
                                              value={
                                                row?.reward_amount_per_year
                                              }
                                              placeholder="0"
                                              onChange={onChangeRewardAmountPerYearInvestOther?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                              thousandSeparator=","
                                              inputMode="decimal"
                                              pattern="[0-9],*"
                                              className={`${STYLE_INPUT_INSET} w-full`}
                                            />
                                            <ICON_DELETE
                                              className=" w-5 h-5 text-maincyan flex-none cursor-pointer"
                                              onClick={onClickDeleteInvestOther?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                            />
                                          </div>
                                        </div>
                                        <SelectTargetInvest
                                          options={OPTIONS_TARGET_INVEST}
                                          onChange={(e) =>
                                            onChangeSelectedTargetInvest(
                                              "invest_other_lists",
                                              index,
                                              indexCard,
                                              e
                                            )
                                          }
                                          value={row?.target_invest}
                                        />
                                      </div>
                                    );
                                  })}
                                {ReturnInsurance?.map((row, indexCard) => {
                                  return (
                                    <div key={indexCard} className="my-2.5">
                                      <div className={STYLE_BODY_INVEST}>
                                        <div className=" flex gap-[6px] items-center">
                                          <input
                                            placeholder="ระบุ"
                                            value={row?.title}
                                            className={`${STYLE_INPUT_INSET} w-full `}
                                            // onChange={onChangeTitleInvestOther?.bind(
                                            //   this,
                                            //   index,
                                            //   indexCard
                                            // )}
                                            disabled={row?.editable}
                                          />
                                        </div>
                                        <NumericFormat
                                          maxLength={11}
                                          value={row?.amount}
                                          placeholder="0"
                                          // onChange={onChangeAmountInvestOther?.bind(
                                          //   this,
                                          //   index,
                                          //   indexCard
                                          // )}
                                          thousandSeparator=","
                                          inputMode="decimal"
                                          pattern="[0-9],*"
                                          className={`${STYLE_INPUT_INSET} w-full`}
                                          disabled={row?.editable}
                                        />
                                        <div className=" flex items-center w-full ">
                                          <NumericFormat
                                            maxLength={11}
                                            value={row?.reward_amount_per_year}
                                            placeholder="0"
                                            onChange={(e) => {
                                              onChangeRewardInsurance(
                                                row,
                                                row.title,
                                                row.created,
                                                parseFloat(e.target.value)
                                              );
                                            }}
                                            thousandSeparator=","
                                            inputMode="decimal"
                                            pattern="[0-9],*"
                                            className={`${STYLE_INPUT_INSET} w-full`}
                                          />
                                          {/* <ICON_DELETE
                                              className=" w-5 h-5 text-maincyan flex-none cursor-pointer"
                                            /> */}
                                        </div>
                                      </div>
                                      <SelectTargetInvest
                                        options={OPTIONS_TARGET_INVEST}
                                        onChange={(e) =>
                                          onChangeSelectedInsuranceTargetInvest(
                                            row,
                                            row.title,
                                            row.created,
                                            e
                                          )
                                        }
                                        value={row?.target_invest}
                                      />
                                    </div>
                                  );
                                })}

                                <PopupMain
                                  title={"ยืนยันการลบข้อมูล"}
                                  text={
                                    <div className=" text-base">
                                      คุณต้องการลบข้อมูลรายการนี้ใช่หรือไม่
                                    </div>
                                  }
                                  button2={true}
                                  textButton={"ยืนยัน"}
                                  textButton2={"ยกเลิก"}
                                  open={
                                    openAlert &&
                                    dataIndexDeleteOne.index === index
                                  }
                                  onClick={onClickConfirmDeleteInvestOther}
                                  onClickSecond={() => {
                                    setDataIndexDeleteOne(null);
                                    setOpenAlert(false);
                                  }}
                                />
                              </div>
                            )}

                            {/*---------- ตราสารหนี้ภาครัฐ ----------*/}
                            {row?.options_property_invest ===
                              "property_invest_goverment" && (
                              <div key={index}>
                                <div className=" bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9 my-2.5">
                                  <div className=" text-sm text-black font-medium">
                                    จำนวน
                                    {row?.options_property_invest_title}
                                  </div>
                                  <ButtonCounter
                                    value={row?.invest_goverment_lists?.length}
                                    handleNext={handleNextInvestGoverment?.bind(
                                      this,
                                      index
                                    )}
                                  />
                                </div>
                                {row?.invest_goverment_lists?.length >= 1 && (
                                  <HeaderInvest />
                                )}
                                {row?.invest_goverment_lists
                                  ?.sort(
                                    (a, b) =>
                                      new Date(b.created) - new Date(a.created)
                                  )
                                  ?.map((row, indexCard) => {
                                    return (
                                      <div key={indexCard} className="my-2.5">
                                        <div className={STYLE_BODY_INVEST}>
                                          <div className=" flex gap-[6px] items-center">
                                            <input
                                              placeholder="ระบุ"
                                              value={row?.title}
                                              className={`${STYLE_INPUT_INSET} w-full `}
                                              onChange={onChangeTitleInvestGoverment?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                            />
                                          </div>
                                          <NumericFormat
                                            maxLength={11}
                                            value={row?.amount}
                                            placeholder="0"
                                            onChange={onChangeAmountInvestGoverment?.bind(
                                              this,
                                              index,
                                              indexCard
                                            )}
                                            thousandSeparator=","
                                            inputMode="decimal"
                                            pattern="[0-9],*"
                                            className={`${STYLE_INPUT_INSET} w-full`}
                                          />
                                          <div className=" flex items-center w-full ">
                                            <NumericFormat
                                              maxLength={11}
                                              value={
                                                row?.reward_amount_per_year
                                              }
                                              placeholder="0"
                                              onChange={onChangeRewardAmountPerYearInvestGoverment?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                              thousandSeparator=","
                                              inputMode="decimal"
                                              pattern="[0-9],*"
                                              className={`${STYLE_INPUT_INSET} w-full`}
                                            />
                                            <ICON_DELETE
                                              className=" w-5 h-5 text-maincyan flex-none cursor-pointer"
                                              onClick={onClickDeleteInvestGoverment?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                            />
                                          </div>
                                        </div>
                                        <SelectTargetInvest
                                          options={OPTIONS_TARGET_INVEST}
                                          onChange={(e) =>
                                            onChangeSelectedTargetInvest(
                                              "invest_goverment_lists",
                                              index,
                                              indexCard,
                                              e
                                            )
                                          }
                                          value={row?.target_invest}
                                        />
                                      </div>
                                    );
                                  })}

                                <PopupMain
                                  title={"ยืนยันการลบข้อมูล"}
                                  text={
                                    <div className=" text-base">
                                      คุณต้องการลบข้อมูลรายการนี้ใช่หรือไม่
                                    </div>
                                  }
                                  button2={true}
                                  textButton={"ยืนยัน"}
                                  textButton2={"ยกเลิก"}
                                  open={
                                    openAlert &&
                                    dataIndexDeleteOne.index === index
                                  }
                                  onClick={onClickConfirmDeleteInvestGorverment}
                                  onClickSecond={() => {
                                    setDataIndexDeleteOne(null);
                                    setOpenAlert(false);
                                  }}
                                />
                              </div>
                            )}

                            {/*---------- ตราสารหนี้ภาคเอกชน ----------*/}
                            {row?.options_property_invest ===
                              "property_invest_private" && (
                              <div key={index}>
                                <div className=" bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9 my-2.5">
                                  <div className=" text-sm text-black font-medium">
                                    จำนวน
                                    {row?.options_property_invest_title}
                                  </div>
                                  <ButtonCounter
                                    value={row?.invest_private_lists?.length}
                                    handleNext={handleNextInvestPrivate?.bind(
                                      this,
                                      index
                                    )}
                                  />
                                </div>
                                {row?.invest_private_lists?.length >= 1 && (
                                  <HeaderInvest />
                                )}
                                {row?.invest_private_lists
                                  ?.sort(
                                    (a, b) =>
                                      new Date(b.created) - new Date(a.created)
                                  )
                                  ?.map((row, indexCard) => {
                                    return (
                                      <div key={indexCard} className="my-2.5">
                                        <div className={STYLE_BODY_INVEST}>
                                          <div className=" flex gap-[6px] items-center">
                                            <input
                                              placeholder="ระบุ"
                                              value={row?.title}
                                              className={`${STYLE_INPUT_INSET} w-full `}
                                              onChange={onChangeTitleInvestPrivate?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                            />
                                          </div>
                                          <NumericFormat
                                            maxLength={11}
                                            value={row?.amount}
                                            placeholder="0"
                                            onChange={onChangeAmountInvestPrivate?.bind(
                                              this,
                                              index,
                                              indexCard
                                            )}
                                            thousandSeparator=","
                                            inputMode="decimal"
                                            pattern="[0-9],*"
                                            className={`${STYLE_INPUT_INSET} w-full`}
                                          />
                                          <div className=" flex items-center w-full ">
                                            <NumericFormat
                                              maxLength={11}
                                              value={
                                                row?.reward_amount_per_year
                                              }
                                              placeholder="0"
                                              onChange={onChangeRewardAmountPerYearInvestPrivate?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                              thousandSeparator=","
                                              inputMode="decimal"
                                              pattern="[0-9],*"
                                              className={`${STYLE_INPUT_INSET} w-full`}
                                            />
                                            <ICON_DELETE
                                              className=" w-5 h-5 text-maincyan flex-none cursor-pointer"
                                              onClick={onClickDeleteInvestPrivate?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                            />
                                          </div>
                                        </div>
                                        <SelectTargetInvest
                                          options={OPTIONS_TARGET_INVEST}
                                          onChange={(e) =>
                                            onChangeSelectedTargetInvest(
                                              "invest_private_lists",
                                              index,
                                              indexCard,
                                              e
                                            )
                                          }
                                          value={row?.target_invest}
                                        />
                                      </div>
                                    );
                                  })}

                                <PopupMain
                                  title={"ยืนยันการลบข้อมูล"}
                                  text={
                                    <div className=" text-base">
                                      คุณต้องการลบข้อมูลรายการนี้ใช่หรือไม่
                                    </div>
                                  }
                                  button2={true}
                                  textButton={"ยืนยัน"}
                                  textButton2={"ยกเลิก"}
                                  open={
                                    openAlert &&
                                    dataIndexDeleteOne.index === index
                                  }
                                  onClick={onClickConfirmDeleteInvestPrivate}
                                  onClickSecond={() => {
                                    setDataIndexDeleteOne(null);
                                    setOpenAlert(false);
                                  }}
                                />
                              </div>
                            )}

                            {/*---------- เงินฝาก ----------*/}
                            {row?.options_property_invest ===
                              "property_invest_deposit" && (
                              <div key={index}>
                                <div className=" bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9 my-2.5">
                                  <div className=" text-sm text-black font-medium">
                                    จำนวน
                                    {row?.options_property_invest_title}
                                  </div>
                                  <ButtonCounter
                                    value={row?.invest_deposit_lists?.length}
                                    handleNext={handleNextInvestDeposit?.bind(
                                      this,
                                      index
                                    )}
                                  />
                                </div>
                                {row?.invest_deposit_lists?.length >= 1 && (
                                  <HeaderInvest />
                                )}
                                {row?.invest_deposit_lists
                                  ?.sort(
                                    (a, b) =>
                                      new Date(b.created) - new Date(a.created)
                                  )
                                  ?.map((row, indexCard) => {
                                    return (
                                      <div key={indexCard} className="my-2.5">
                                        <div className={STYLE_BODY_INVEST}>
                                          <div className=" flex gap-[6px] items-center">
                                            <input
                                              placeholder="ระบุ"
                                              value={row?.title}
                                              className={`${STYLE_INPUT_INSET} w-full `}
                                              onChange={onChangeTitleInvestDeposit?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                            />
                                          </div>
                                          <NumericFormat
                                            maxLength={11}
                                            value={row?.amount}
                                            placeholder="0"
                                            onChange={onChangeAmountInvestDeposit?.bind(
                                              this,
                                              index,
                                              indexCard
                                            )}
                                            thousandSeparator=","
                                            inputMode="decimal"
                                            pattern="[0-9],*"
                                            className={`${STYLE_INPUT_INSET} w-full`}
                                          />
                                          <div className=" flex items-center w-full ">
                                            <NumericFormat
                                              maxLength={11}
                                              value={
                                                row?.reward_amount_per_year
                                              }
                                              placeholder="0"
                                              onChange={onChangeRewardAmountPerYearInvestDeposit?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                              thousandSeparator=","
                                              inputMode="decimal"
                                              pattern="[0-9],*"
                                              className={`${STYLE_INPUT_INSET} w-full`}
                                            />
                                            <ICON_DELETE
                                              className=" w-5 h-5 text-maincyan flex-none cursor-pointer"
                                              onClick={onClickDeleteInvestDeposit?.bind(
                                                this,
                                                index,
                                                indexCard
                                              )}
                                            />
                                          </div>
                                        </div>
                                        <SelectTargetInvest
                                          options={OPTIONS_TARGET_INVEST}
                                          onChange={(e) =>
                                            onChangeSelectedTargetInvest(
                                              "invest_deposit_lists",
                                              index,
                                              indexCard,
                                              e
                                            )
                                          }
                                          value={row?.target_invest}
                                        />
                                      </div>
                                    );
                                  })}

                                <PopupMain
                                  title={"ยืนยันการลบข้อมูล"}
                                  text={
                                    <div className=" text-base">
                                      คุณต้องการลบข้อมูลรายการนี้ใช่หรือไม่
                                    </div>
                                  }
                                  button2={true}
                                  textButton={"ยืนยัน"}
                                  textButton2={"ยกเลิก"}
                                  open={
                                    openAlert &&
                                    dataIndexDeleteOne.index === index
                                  }
                                  onClick={onClickConfirmDeleteInvestDeposit}
                                  onClickSecond={() => {
                                    setDataIndexDeleteOne(null);
                                    setOpenAlert(false);
                                  }}
                                />
                              </div>
                            )}

                            <DeleteButtonCard
                              onClick={() => {
                                setDataIndexDeleteAll(index);
                                setOpenAlert2(true);
                              }}
                            />

                            <PopupMain
                              title={"ยืนยันการลบข้อมูลทั้งหมด"}
                              text={
                                <div className=" text-base">
                                  คุณต้องการลบข้อมูลทั้งหมดนี้ใช่หรือไม่
                                </div>
                              }
                              button2={true}
                              textButton={"ยืนยัน"}
                              textButton2={"ยกเลิก"}
                              open={openAlert2}
                              onClick={() => {
                                const result = [...addInvest];
                                result.splice(dataIndexDeleteAll, 1);
                                setAddInvest(result);
                                setDataIndexDeleteAll(null);
                                setOpenAlert2(false);
                              }}
                              onClickSecond={() => {
                                setDataIndexDeleteAll(null);
                                setOpenAlert2(false);
                              }}
                            />

                            {addInvest?.length > 1 &&
                              index !== addInvest?.length - 1 && (
                                <div className=" border-b border-gray80 pb-3 my-2"></div>
                              )}
                          </div>
                        );
                      })}
                  </div>
                }
              />

              {/*---------------------------------------- สินทรัพย์ใช้ส่วนตัว ----------------------------------------*/}
              <CardAccordian
                zIndex="2"
                add
                open={openAccordion === 3}
                onClickOpen={() => {
                  handleOpen(3);
                  if (openAccordion === 3) setOpenAccordion(false);
                }}
                title="สินทรัพย์ใช้ส่วนตัว"
                textColor="text-purple400"
                icon="/images/proporty_private2.svg"
                value={formBL?.sum_property_private}
                onCLickMinus={() => {
                  setOpenAccordion(false);
                }}
                onClickAccordion={() => {
                  const date = new Date();
                  const created = date.toISOString();
                  const result = [
                    {
                      created: created,
                      options_property_private: "",
                      options_property_private_title: "",
                    },
                  ];
                  setAddPrivate((prev) => {
                    if (prev) {
                      return [...prev, ...result];
                    }
                    return [...result];
                  });
                  handleOpen(3);
                }}
                panel={
                  <div>
                    <div className="mb-10">
                      {formBL?.debt_long_car_personal_list &&
                        formBL?.debt_long_place_personal_list &&
                        [
                          ...formBL?.debt_long_car_personal_list,
                          ...formBL?.debt_long_place_personal_list,
                        ].length > 0 && <ListPrivateMain />}
                    </div>
                    {addPrivate
                      ?.sort(
                        (a, b) => new Date(b.created) - new Date(a.created)
                      )
                      ?.map((row, index) => {
                        return (
                          <div key={index} className=" ">
                            <div className=" pt-3 ">
                              <DropDownMain
                                value={options_property_private?.filter(
                                  (option) => {
                                    if (
                                      option?.value ===
                                      row?.options_property_private
                                    )
                                      return option?.label;
                                  }
                                )}
                                options={options_property_private}
                                zIndex={2}
                                onChange={onChangeSelectedPrivate?.bind(
                                  this,
                                  row?.created,
                                  index
                                )}
                              />
                            </div>
                            {/*---------- เครื่องประดับ ----------*/}
                            {row?.options_property_private ===
                              "property_accessories" && (
                              <div className=" mt-4">
                                <div className=" bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9 ">
                                  <div className=" text-sm text-black font-medium">
                                    จำนวนเครื่องประดับ (ชิ้น)
                                  </div>
                                  <ButtonCounter
                                    value={row?.accessories_lists?.length}
                                    handleNext={handleNextAccessories?.bind(
                                      this,
                                      index
                                    )}
                                  />
                                </div>
                                {row?.accessories_lists
                                  ?.sort(
                                    (a, b) =>
                                      new Date(b.created) - new Date(a.created)
                                  )
                                  ?.map((row, indexCard) => {
                                    return (
                                      <div key={indexCard}>
                                        <CardPropertyAsset
                                          keyIndex={index}
                                          indexCard={indexCard}
                                          isPropertyPrivate={true}
                                          head="ชื่อเครื่องประดับ"
                                          placeholder={`ระบุ`}
                                          title={row?.title}
                                          head2={`มูลค่าปัจจุบัน`}
                                          amount={row?.amount}
                                          isCheckedDebtFree={row?.debt_free}
                                          isDebtFree={true}
                                          onClickDelete={onClickDeleteAccessories?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeTitle={onChangeTitleAccessories?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeAmount={onChangeAmountAccessories?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          handleCheckboxChange={handleCheckboxChangeDebtFreeAccessories?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                        />
                                      </div>
                                    );
                                  })}
                                <PopupMain
                                  title={"ยืนยันการลบข้อมูล"}
                                  text={
                                    <div className=" text-base">
                                      คุณต้องการลบข้อมูลรายการนี้ใช่หรือไม่
                                    </div>
                                  }
                                  button2={true}
                                  textButton={"ยืนยัน"}
                                  textButton2={"ยกเลิก"}
                                  open={openAlert}
                                  onClick={onClickConfirmDeleteAccessories}
                                  onClickSecond={() => {
                                    setDataIndexDeleteOne(null);
                                    setOpenAlert(false);
                                  }}
                                />
                              </div>
                            )}

                            {/*---------- รถยนต์ ----------*/}
                            {row?.options_property_private ===
                              "property_car" && (
                              <div className=" mt-4">
                                <div className=" bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9 ">
                                  <div className=" text-sm text-black font-medium">
                                    จำนวนรถยนต์ (คัน)
                                  </div>
                                  <ButtonCounter
                                    value={row?.car_lists?.length}
                                    handleNext={handleNextCar?.bind(
                                      this,
                                      index
                                    )}
                                  />
                                </div>
                                {row?.car_lists
                                  ?.sort(
                                    (a, b) =>
                                      new Date(b.created) - new Date(a.created)
                                  )
                                  ?.map((row, indexCard) => {
                                    return (
                                      <div key={indexCard}>
                                        <CardPropertyAsset
                                          keyIndex={index}
                                          indexCard={indexCard}
                                          isPropertyPrivate={true}
                                          isCar={true}
                                          head="ยี่ห้อรถยนต์"
                                          placeholder={`ระบุ`}
                                          title={row?.title}
                                          registration={row?.registration_car}
                                          head2={`มูลค่าปัจจุบัน`}
                                          amount={row?.amount}
                                          isCheckedDebtFree={row?.debt_free}
                                          isDebtFree={true}
                                          onClickDelete={onClickDeleteCar?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeTitle={onChangeTitleCar?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeRegistration={onChangeRegistrationCar?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeAmount={onChangeAmountCar?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          handleCheckboxChange={handleCheckboxChangeDebtFreeCar?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                        />
                                      </div>
                                    );
                                  })}
                                <PopupMain
                                  title={"ยืนยันการลบข้อมูล"}
                                  text={
                                    <div className=" text-base">
                                      คุณต้องการลบข้อมูลรายการนี้ใช่หรือไม่
                                    </div>
                                  }
                                  button2={true}
                                  textButton={"ยืนยัน"}
                                  textButton2={"ยกเลิก"}
                                  open={openAlert}
                                  onClick={onClickConfirmDeleteCar}
                                  onClickSecond={() => {
                                    setDataIndexDeleteOne(null);
                                    setOpenAlert(false);
                                  }}
                                />
                              </div>
                            )}

                            {/*---------- ที่อยู่อาศัย ----------*/}
                            {row?.options_property_private ===
                              "property_home" && (
                              <div className=" mt-4">
                                <div className=" bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9 ">
                                  <div className=" text-sm text-black font-medium">
                                    จำนวนที่อยู่อาศัย (ที่)
                                  </div>
                                  <ButtonCounter
                                    value={row?.home_lists?.length}
                                    handleNext={handleNextHome?.bind(
                                      this,
                                      index
                                    )}
                                  />
                                </div>
                                {row?.home_lists
                                  ?.sort(
                                    (a, b) =>
                                      new Date(b.created) - new Date(a.created)
                                  )
                                  ?.map((row, indexCard) => {
                                    return (
                                      <div key={indexCard}>
                                        <CardPropertyAsset
                                          keyIndex={index}
                                          indexCard={indexCard}
                                          isPropertyPrivate={true}
                                          isHome={true}
                                          head="ที่อยู่"
                                          placeholder={`โปรดระบุ`}
                                          title={row?.title}
                                          address={row?.address}
                                          addressOther={row?.address_other}
                                          typePlace={row?.type_place}
                                          head2={`มูลค่าปัจจุบัน`}
                                          amount={row?.amount}
                                          isCheckedDebtFree={row?.debt_free}
                                          isDebtFree={true}
                                          onClickDelete={onClickDeleteHome?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeTitle={onChangeTitleHome?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeAddress={onChangeAddressHome?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          handleCheckboxChangeType={handleCheckboxChangeTypeHome?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeAddressOther={onChangeAddressOtherHome?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeAmount={onChangeAmountHome?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          handleCheckboxChange={handleCheckboxChangeDebtFreeHome?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                        />
                                      </div>
                                    );
                                  })}
                                <PopupMain
                                  title={"ยืนยันการลบข้อมูล"}
                                  text={
                                    <div className=" text-base">
                                      คุณต้องการลบข้อมูลรายการนี้ใช่หรือไม่
                                    </div>
                                  }
                                  button2={true}
                                  textButton={"ยืนยัน"}
                                  textButton2={"ยกเลิก"}
                                  open={openAlert}
                                  onClick={onClickComfirmDeleteHome}
                                  onClickSecond={() => {
                                    setDataIndexDeleteOne(null);
                                    setOpenAlert(false);
                                  }}
                                />
                              </div>
                            )}

                            {/*---------- ของสะสมมีราคา ----------*/}
                            {row?.options_property_private ===
                              "property_collection" && (
                              <div className=" mt-4">
                                <div className=" bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9 ">
                                  <div className=" text-sm text-black font-medium">
                                    จำนวนของสะสมมีราคา (ชิ้น)
                                  </div>
                                  <ButtonCounter
                                    value={row?.collection_lists?.length}
                                    handleNext={handleNextCollection?.bind(
                                      this,
                                      index
                                    )}
                                  />
                                </div>
                                {row?.collection_lists
                                  ?.sort(
                                    (a, b) =>
                                      new Date(b.created) - new Date(a.created)
                                  )
                                  ?.map((row, indexCard) => {
                                    return (
                                      <div key={indexCard}>
                                        <CardPropertyAsset
                                          keyIndex={index}
                                          indexCard={indexCard}
                                          isPropertyPrivate={true}
                                          head="ชื่อของสะสมมีราคา"
                                          placeholder={`ระบุ`}
                                          title={row?.title}
                                          head2={`มูลค่าปัจจุบัน`}
                                          amount={row?.amount}
                                          isCheckedDebtFree={row?.debt_free}
                                          isDebtFree={true}
                                          onClickDelete={onClickDeleteCollection?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeTitle={onChangeTitleCollection?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeAmount={onChangeAmountCollection?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          handleCheckboxChange={handleCheckboxChangeDebtFreeCollection?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                        />
                                      </div>
                                    );
                                  })}
                                <PopupMain
                                  title={"ยืนยันการลบข้อมูล"}
                                  text={
                                    <div className=" text-base">
                                      คุณต้องการลบข้อมูลรายการนี้ใช่หรือไม่
                                    </div>
                                  }
                                  button2={true}
                                  textButton={"ยืนยัน"}
                                  textButton2={"ยกเลิก"}
                                  open={openAlert}
                                  onClick={onClickConfirmDeleteCollection}
                                  onClickSecond={() => {
                                    setDataIndexDeleteOne(null);
                                    setOpenAlert(false);
                                  }}
                                />
                              </div>
                            )}

                            {/*---------- อื่น ๆ ----------*/}
                            {row?.options_property_private ===
                              "property_other" && (
                              <div className=" mt-4">
                                <div className=" bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9 ">
                                  <div className=" text-sm text-black font-medium">
                                    จำนวน
                                  </div>
                                  <ButtonCounter
                                    value={row?.other_lists?.length}
                                    handleNext={handleNextOther?.bind(
                                      this,
                                      index
                                    )}
                                  />
                                </div>
                                {row?.other_lists
                                  ?.sort(
                                    (a, b) =>
                                      new Date(b.created) - new Date(a.created)
                                  )
                                  ?.map((row, indexCard) => {
                                    return (
                                      <div key={indexCard}>
                                        <CardPropertyAsset
                                          keyIndex={index}
                                          indexCard={indexCard}
                                          isPropertyPrivate={true}
                                          head="โปรดระบุ"
                                          placeholder={`ระบุ`}
                                          title={row?.title}
                                          head2={`มูลค่าปัจจุบัน`}
                                          amount={row?.amount}
                                          isCheckedDebtFree={row?.debt_free}
                                          isDebtFree={true}
                                          onClickDelete={onClickDeleteOther?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeTitle={onChangeTitleOther?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeAmount={onChangeAmountOther?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          handleCheckboxChange={handleCheckboxChangeDebtFreeOther?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                        />
                                      </div>
                                    );
                                  })}
                                <PopupMain
                                  title={"ยืนยันการลบข้อมูล"}
                                  text={
                                    <div className=" text-base">
                                      คุณต้องการลบข้อมูลรายการนี้ใช่หรือไม่
                                    </div>
                                  }
                                  button2={true}
                                  textButton={"ยืนยัน"}
                                  textButton2={"ยกเลิก"}
                                  open={openAlert}
                                  onClick={onClickConfirmDeleteOther}
                                  onClickSecond={() => {
                                    setDataIndexDeleteOne(null);
                                    setOpenAlert(false);
                                  }}
                                />
                              </div>
                            )}

                            <DeleteButtonCard
                              onClick={() => {
                                setDataIndexDeleteAll(index);
                                setOpenAlert2(true);
                              }}
                            />
                            <PopupMain
                              title={"ยืนยันการลบข้อมูลทั้งหมด"}
                              text={
                                <div className=" text-base">
                                  คุณต้องการลบข้อมูลทั้งหมดนี้ใช่หรือไม่
                                </div>
                              }
                              button2={true}
                              textButton={"ยืนยัน"}
                              textButton2={"ยกเลิก"}
                              open={openAlert2}
                              onClick={() => {
                                const result = [...addPrivate];
                                result.splice(dataIndexDeleteAll, 1);
                                setAddPrivate(result);

                                setDataIndexDeleteAll(null);
                                setOpenAlert2(false);
                              }}
                              onClickSecond={() => {
                                setDataIndexDeleteAll(null);

                                setOpenAlert2(false);
                              }}
                            />
                            {addPrivate?.length > 1 &&
                              index !== addPrivate?.length - 1 && (
                                <div className=" border-b border-gray80 pb-3 my-2"></div>
                              )}
                          </div>
                        );
                      })}
                  </div>
                }
              />
              <PopupMain
                title={""}
                text={
                  <div className=" text-base">
                    ตรวจสอบว่าสินทรัพย์ทั้งหมดของคุณปลอดภาะระหนี้
                    หากไม่ปลอดภาระหนี้กรุณาย้ายไปกรอกที่หัวข้อหนี้สินด้วย
                  </div>
                }
                button2={false}
                textButton={"ตกลง"}
                textButton2={"ยกเลิก"}
                open={openAlertNoDebt}
                onClick={() => {
                  setOpenAlertNoDebt(false);
                }}
                onClickSecond={() => {}}
              />

              {/*---------------------------------------- สินทรัพย์ไม่มีตัวตน ----------------------------------------*/}
              <CardAccordian
                zIndex="1"
                add
                open={openAccordion === 4}
                onClickOpen={() => {
                  handleOpen(4);
                  if (openAccordion === 4) setOpenAccordion(false);
                }}
                title="สินทรัพย์ไม่มีตัวตน"
                textColor="text-purple600"
                icon={
                  <ICON_CONTRACT classNames=" w-8 h-8 fill-[#8D32E8] stroke-[#8D32E8] " />
                }
                value={formBL?.sum_property_intangible}
                onCLickMinus={() => {
                  setOpenAccordion(false);
                }}
                onClickAccordion={() => {
                  const date = new Date();
                  const created = date.toISOString();
                  const result = [
                    {
                      created: created,
                      options_property_intangible: "",
                      options_property_intangible_title: "",
                    },
                  ];
                  setAddIntangible((prev) => {
                    if (prev) {
                      return [...prev, ...result];
                    }
                    return [...result];
                  });
                  handleOpen(4);
                }}
                panel={
                  <div>
                    {addIntangible
                      ?.sort(
                        (a, b) => new Date(b.created) - new Date(a.created)
                      )
                      ?.map((row, index) => {
                        return (
                          <div key={index} className=" ">
                            <div className=" pt-3 ">
                              <DropDownMain
                                value={options_property_intangible?.filter(
                                  (option) => {
                                    if (
                                      option?.value ===
                                      row?.options_property_intangible
                                    )
                                      return option?.label;
                                  }
                                )}
                                onChange={onChangeSelectedIntangible.bind(
                                  this,
                                  row?.created,
                                  index
                                )}
                                options={options_property_intangible}
                                zIndex={1}
                              />
                            </div>
                            {/*---------- ลิขสิทธิ ----------*/}
                            {row?.options_property_intangible ===
                              "property_copyright" && (
                              <div className=" mt-4">
                                <div className=" bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9 ">
                                  <div className=" text-sm text-black font-medium">
                                    จำนวนลิขสิทธ์
                                  </div>
                                  <ButtonCounter
                                    value={row?.copyright_lists?.length}
                                    handleNext={handleNextCopyright?.bind(
                                      this,
                                      index
                                    )}
                                  />
                                </div>
                                {row?.copyright_lists
                                  ?.sort(
                                    (a, b) =>
                                      new Date(b.created) - new Date(a.created)
                                  )
                                  ?.map((row, indexCard) => {
                                    return (
                                      <CardPropertyAsset
                                        keyIndex={index}
                                        indexCard={indexCard}
                                        isPropertyPrivate={true}
                                        head="ชื่อลิขสิทธิ"
                                        placeholder={`ระบุชื่อลิขสิทธิ`}
                                        title={row?.title}
                                        head2={`มูลค่า`}
                                        amount={row?.amount}
                                        isCheckedDebtFree={row?.debt_free}
                                        isDebtFree={false}
                                        onClickDelete={onClickDeleteCopyright?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeTitle={onChangeTitleCopyright?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeAmount={onChangeAmountCopyright?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        handleCheckboxChange={handleCheckboxChangeDebtFreeCopyright?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                      />
                                    );
                                  })}
                                <PopupMain
                                  title={"ยืนยันการลบข้อมูล"}
                                  text={
                                    <div className=" text-base">
                                      คุณต้องการลบข้อมูลรายการนี้ใช่หรือไม่
                                    </div>
                                  }
                                  button2={true}
                                  textButton={"ยืนยัน"}
                                  textButton2={"ยกเลิก"}
                                  open={openAlert}
                                  onClick={onClickConfirmDeleteCopyright}
                                  onClickSecond={() => {
                                    setDataIndexDeleteOne(null);
                                    setOpenAlert(false);
                                  }}
                                />
                              </div>
                            )}

                            {/*---------- สิทธิบัตร ----------*/}
                            {row?.options_property_intangible ===
                              "property_patent" && (
                              <div className=" mt-4">
                                <div className=" bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9 ">
                                  <div className=" text-sm text-black font-medium">
                                    จำนวนสิทธิบัตร
                                  </div>
                                  <ButtonCounter
                                    value={row?.patent_lists?.length}
                                    handleNext={handleNextPatent?.bind(
                                      this,
                                      index
                                    )}
                                  />
                                </div>
                                {row?.patent_lists
                                  ?.sort(
                                    (a, b) =>
                                      new Date(b.created) - new Date(a.created)
                                  )
                                  ?.map((row, indexCard) => {
                                    return (
                                      <CardPropertyAsset
                                        keyIndex={index}
                                        indexCard={indexCard}
                                        isPropertyPrivate={true}
                                        head="ชื่อสิทธิบัตร"
                                        placeholder={`ระบุชื่อสิทธิบัตร`}
                                        title={row?.title}
                                        head2={`มูลค่า`}
                                        amount={row?.amount}
                                        isCheckedDebtFree={row?.debt_free}
                                        isDebtFree={false}
                                        onClickDelete={onClickDeletePatent?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeTitle={onChangeTitlePatent?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeAmount={onChangeAmountPatent?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        handleCheckboxChange={handleCheckboxChangeDebtFreePatent?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                      />
                                    );
                                  })}
                                <PopupMain
                                  title={"ยืนยันการลบข้อมูล"}
                                  text={
                                    <div className=" text-base">
                                      คุณต้องการลบข้อมูลรายการนี้ใช่หรือไม่
                                    </div>
                                  }
                                  button2={true}
                                  textButton={"ยืนยัน"}
                                  textButton2={"ยกเลิก"}
                                  open={openAlert}
                                  onClick={onClickConfirmDeletePatent}
                                  onClickSecond={() => {
                                    setDataIndexDeleteOne(null);
                                    setOpenAlert(false);
                                  }}
                                />
                              </div>
                            )}

                            {/*---------- อื่น ๆ ----------*/}
                            {row?.options_property_intangible ===
                              "property_intangible_other" && (
                              <div className=" mt-4">
                                <div className=" bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9 ">
                                  <div className=" text-sm text-black font-medium">
                                    จำนวน
                                  </div>
                                  <ButtonCounter
                                    value={row?.intangible_other_lists?.length}
                                    handleNext={handleNextIntangibleOther?.bind(
                                      this,
                                      index
                                    )}
                                  />
                                </div>
                                {row?.intangible_other_lists
                                  ?.sort(
                                    (a, b) =>
                                      new Date(b.created) - new Date(a.created)
                                  )
                                  ?.map((row, indexCard) => {
                                    return (
                                      <CardPropertyAsset
                                        keyIndex={index}
                                        indexCard={indexCard}
                                        isPropertyPrivate={true}
                                        head="โปรดระบุ"
                                        placeholder={`ระบุ`}
                                        title={row?.title}
                                        head2={`มูลค่า`}
                                        amount={row?.amount}
                                        isCheckedDebtFree={row?.debt_free}
                                        isDebtFree={false}
                                        onClickDelete={onClickDeleteIntangibleOther?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeTitle={onChangeTitleIntangibleOther?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeAmount={onChangeAmountIntangibleOther?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        handleCheckboxChange={handleCheckboxChangeDebtFreeIntangibleOther?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                      />
                                    );
                                  })}
                                <PopupMain
                                  title={"ยืนยันการลบข้อมูล"}
                                  text={
                                    <div className=" text-base">
                                      คุณต้องการลบข้อมูลรายการนี้ใช่หรือไม่
                                    </div>
                                  }
                                  button2={true}
                                  textButton={"ยืนยัน"}
                                  textButton2={"ยกเลิก"}
                                  open={openAlert}
                                  onClick={onClickConfirmDeleteIntangibleOther}
                                  onClickSecond={() => {
                                    setDataIndexDeleteOne(null);
                                    setOpenAlert(false);
                                  }}
                                />
                              </div>
                            )}

                            <DeleteButtonCard
                              onClick={() => {
                                setDataIndexDeleteAll(index);

                                setOpenAlert2(true);
                              }}
                            />
                            <PopupMain
                              title={"ยืนยันการลบข้อมูลทั้งหมด"}
                              text={
                                <div className=" text-base">
                                  คุณต้องการลบข้อมูลทั้งหมดนี้ใช่หรือไม่
                                </div>
                              }
                              button2={true}
                              textButton={"ยืนยัน"}
                              textButton2={"ยกเลิก"}
                              open={openAlert2}
                              onClick={() => {
                                const result = [...addIntangible];
                                result.splice(dataIndexDeleteAll, 1);
                                setAddIntangible(result);
                                setDataIndexDeleteAll(null);

                                setOpenAlert2(false);
                              }}
                              onClickSecond={() => {
                                setDataIndexDeleteAll(null);

                                setOpenAlert2(false);
                              }}
                            />
                            {addIntangible?.length > 1 &&
                              index !== addIntangible?.length - 1 && (
                                <div className=" border-b border-gray80 pb-3 my-2"></div>
                              )}
                          </div>
                        );
                      })}
                  </div>
                }
                isIconTypeImage={false}
              />
            </div>

            <div className="flex justify-center mt-[30px] mb-6">
              <MTButton text="บันทึก" onClick={onClickSubmit} />
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
};
