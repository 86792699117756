import { useEffect, useState } from "react";
import { MTButton } from "../../components/button/MTButton";
import { HeaderTitle } from "../../components/header/Header";
import Container from "../../components/layout/Container";
import {
  STYLE_HEAD_TITLE,
  STYLE_ICON_CLOSE_DW,
  STYLE_INPUT,
  STYLE_MARGIN_TOP_PARENT,
  STYLE_PARENT_MENU_HOME,
} from "../../utils/useStyle";
import { useNavigate } from "react-router-dom";
import { SkeletonMain } from "../../components/skeleton";
import { Dialog, DialogHeader, Typography } from "@material-tailwind/react";
import {
  ICON_CLOSE,
  ICON_CLOSE_NO_CIIRCLE,
  ICON_NAVIGATE_RIGHT,
  ICON_RIGHT,
  ICON_RIGHT_2,
  ICON_RLOCNG_RIGHT,
} from "../../utils/useIcons";
import { PopupMain } from "../../components/popup/PopupMain";
import { ImageMain } from "../../components/img/Img.Main";
import {
  CardMenu,
  CardMenu2,
  CardMenu3,
  CardProfile,
} from "./components/CardMenu";
import { PromotionCard } from "./components/PromotionCard";
import { useRetireStore } from "../../_store/retireStore";
import { useTaxStore } from "../../_store/taxplanStore";
import { useSavingStore } from "../../_store/savingStore";
import { useUserStore } from "../../_store/userStore";
import { useBalanceStore } from "../../_store/balanceStore";
import { useRiskStore } from "../../_store/riskStore";
import { useEducationStore } from "../../_store/educationStore";
import { useInsuranceStore } from "../../_store/insuranceStore";
import { useInvestStore } from "../../_store/investStore";
import api from "../../api/api.main";

const HomeMain = () => {
  // User Data
  const userData = useUserStore((state) => state.userData);

  //Data Plan
  const formInvest = useInvestStore((state) => state.investData);
  const retireData = useRetireStore((state) => state.retireData);
  const taxData = useTaxStore((state) => state.taxData);
  const savingData = useSavingStore((state) => state.savingData);
  const balanceData = useBalanceStore((state) => state.balanceData);
  const riskData = useRiskStore((state) => state.riskData);
  const educationData = useEducationStore((state) => state.educationData);
  const insuaranceData = useInsuranceStore((state) => state.insuranceData);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openPopup, setOpenPopup] = useState(
    userData?._id ? (userData?.is_premium ? false : true) : false
  );
  const [greeting, setGreeting] = useState("สวัสดีตอนเช้า");

  useEffect(() => {
    const today = new Date();
    const curHr = today?.getHours();

    if (curHr < 12) {
      setGreeting("สวัสดีตอนเช้า");
    } else if (curHr < 18) {
      setGreeting("สวัสดีตอนบ่าย");
    } else {
      setGreeting("สวัสดีตอนเย็น");
    }
  }, []);

  const Menu = ({
    dataUser,
    premium,
    dataRetire,
    dataTax,
    dataSaving,
    dataBalance,
    dataDebt,
    dataInsuarance,
    dataRisk,
    dataInvest,
    dataLegacy,
    dataKids,
    dataFuture,
  }) => {
    return (
      <>
        {/*---------- Logined ? ----------*/}
        {dataUser && premium ? (
          <div className="mt-5 ">
            {/*-- วางแผนแล้ว --*/}
            <>
              <div className="text-[20px] font-semibold text-center text-mainblue my-[15px]">
                วางแผนแล้ว
              </div>
              <div className={STYLE_PARENT_MENU_HOME}>
                <CardMenu
                  color={" bg-secoundblue"}
                  colormain={"text-maincyan hover:border-mainblue"}
                  icon={"/images/home_retire2.png"}
                  title={"วางแผนเกษียณ"}
                  onClick={() => {
                    navigate("/retireplan");
                  }}
                  planed={dataRetire}
                />
                <CardMenu
                  color={` bg-blue200 `}
                  colormain={" text-mainblue hover:border-mainblue"}
                  icon={"/images/home_tax2.png"}
                  title={"วางแผนภาษี"}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/taxplan");
                  }}
                  planed={dataTax}
                />
                <CardMenu
                  color={"bg-yellowopacity"}
                  colormain={"text-mainbrown hover:border-mainbrown"}
                  icon={"images/home_other5.png"}
                  title={"วางแผนออม"}
                  onClick={() => {
                    navigate("/savingsplan");
                  }}
                  planed={dataSaving}
                />
                {!premium && (
                  <CardMenu
                    color={"bg-[#E2CCFF]"}
                    colormain={"text-[#8D32E8] hover:border-[#8D32E8] text-xs"}
                    icon={"images/home_other6.png"}
                    title={"ปรึกษานักวางแผนการเงิน"}
                    padding="p-2"
                    onClick={() => {
                      window?.open(
                        "https://liff.line.me/1645278921-kWRPP32q/?accountId=assurewealth"
                      );
                    }}
                    planed={true}
                  />
                )}
                <CardMenu
                  color={" bg-[#16AA37]/30"}
                  colormain={"text-maingreen hover:border-maingreen"}
                  icon={"/images/home_balance2.png"}
                  title={"งบดุล"}
                  planed={dataBalance}
                  onClick={() => {
                    navigate("/balance");
                  }}
                />
                <CardMenu
                  color={" bg-red200"}
                  colormain={"text-red400 hover:border-red400"}
                  icon={"/images/home_debt2.png"}
                  title={"แผนจัดการหนี้สิน"}
                  planed={dataDebt}
                  onClick={() => {
                    navigate("/debtplan");
                  }}
                />
                <CardMenu
                  color={" bg-[#DFEFFD]"}
                  colormain={"text-blue400 hover:border-blue400"}
                  icon={"/images/shield_insuarance_menu.png"}
                  title={"แผนประกัน"}
                  planed={dataInsuarance}
                  onClick={() => {
                    navigate("/insuranceplan");
                  }}
                />
                <CardMenu
                  color={" bg-[#FFEDDF]"}
                  colormain={"text-orange300 hover:border-orange300 text-xs"}
                  icon={"/images/home_risk2.png"}
                  title={"แผนบริหารความเสี่ยง"}
                  onClick={() => {
                    navigate("/riskplan");
                  }}
                  planed={dataRisk}
                />
                <CardMenu
                  color={" bg-[#AFFCE9]"}
                  colormain={"text-maincyan hover:border-maincyan"}
                  icon={"/images/home_invest2.png"}
                  title={"แผนลงทุน"}
                  onClick={() => {
                    navigate("/investplan");
                  }}
                  planed={dataInvest}
                />
                <CardMenu
                  color={" bg-[#E6D5FB]"}
                  colormain={"text-purple600 hover:border-purple600"}
                  icon={"/images/home_legacy2.png"}
                  title={"แผนมรดก"}
                  planed={dataLegacy}
                  onClick={() => {
                    navigate("/legacyplan");
                  }}
                />
                <CardMenu
                  color={" bg-[#FFE0E0]"}
                  colormain={"text-[#CC8C8D] hover:border-[#CC8C8D]"}
                  icon={"/images/home_kids.png"}
                  title={"แผนการศึกษาบุตร"}
                  planed={dataKids}
                  onClick={() => {
                    navigate("/education");
                  }}
                />
                <CardMenu
                  color={" bg-[#FADF4D]"}
                  colormain={"text-[#B95940] hover:border-[#B95940]"}
                  icon={"/images/home_graph2.png"}
                  title={"แผนอนาคต"}
                  planed={dataFuture}
                  onClick={() => {
                    navigate("/futureplan");
                  }}
                />
              </div>
            </>
            {/*-- ยังไม่ได้วางแผน --*/}
            <>
              <div className="text-[20px] font-semibold text-center text-mainblue my-[15px]">
                ยังไม่ได้วางแผน
              </div>
              <div className={STYLE_PARENT_MENU_HOME}>
                <CardMenu2
                  color={" bg-secoundblue"}
                  colormain={"text-maincyan hover:border-mainblue"}
                  icon={"/images/home_retire2.png"}
                  title={"วางแผนเกษียณ"}
                  onClick={() => {
                    navigate("/retireplan");
                  }}
                  planed={dataRetire}
                />
                <CardMenu2
                  color={` bg-blue200 `}
                  colormain={" text-mainblue hover:border-mainblue"}
                  icon={"/images/home_tax2.png"}
                  title={"วางแผนภาษี"}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/taxplan");
                  }}
                  planed={dataTax}
                />
                <CardMenu2
                  color={"bg-yellowopacity"}
                  colormain={"text-mainbrown hover:border-mainbrown"}
                  icon={"images/home_other5.png"}
                  title={"วางแผนออม"}
                  onClick={() => {
                    navigate("/savingsplan");
                  }}
                  planed={dataSaving}
                />
                {!premium && (
                  <CardMenu2
                    color={"bg-[#E2CCFF]"}
                    colormain={"text-[#8D32E8] hover:border-[#8D32E8] text-xs"}
                    icon={"images/home_other6.png"}
                    title={"ปรึกษานักวางแผนการเงิน"}
                    padding="p-2"
                    onClick={() => {
                      window?.open(
                        "https://liff.line.me/1645278921-kWRPP32q/?accountId=assurewealth"
                      );
                    }}
                    planed={true}
                  />
                )}
                <CardMenu2
                  color={" bg-[#16AA37]/30"}
                  colormain={"text-maingreen hover:border-maingreen"}
                  icon={"/images/home_balance2.png"}
                  title={"งบดุล"}
                  planed={dataBalance}
                  onClick={() => {
                    navigate("/balance");
                  }}
                />
                <CardMenu2
                  color={" bg-red200"}
                  colormain={"text-red400 hover:border-red400"}
                  icon={"/images/home_debt2.png"}
                  title={"แผนจัดการหนี้สิน"}
                  planed={dataDebt}
                  onClick={() => {
                    navigate("/debtplan");
                  }}
                />
                <CardMenu2
                  color={" bg-[#DFEFFD]"}
                  colormain={"text-blue400 hover:border-blue400"}
                  icon={"/images/shield_insuarance_menu.png"}
                  title={"แผนประกัน"}
                  planed={dataInsuarance}
                  onClick={() => {
                    navigate("/insuranceplan");
                  }}
                />
                <CardMenu2
                  color={" bg-[#FFEDDF]"}
                  colormain={"text-orange300 hover:border-orange300 text-xs"}
                  icon={"/images/home_risk2.png"}
                  title={"แผนบริหารความเสี่ยง"}
                  onClick={() => {
                    navigate("/riskplan");
                  }}
                  planed={dataRisk}
                />
                <CardMenu2
                  color={" bg-[#AFFCE9]"}
                  colormain={"text-maincyan hover:border-maincyan"}
                  icon={"/images/home_invest2.png"}
                  title={"แผนลงทุน"}
                  onClick={() => {
                    navigate("/investplan");
                  }}
                  planed={dataInvest}
                />
                <CardMenu2
                  color={" bg-[#E6D5FB]"}
                  colormain={"text-purple600 hover:border-purple600"}
                  icon={"/images/home_legacy2.png"}
                  title={"แผนมรดก"}
                  planed={dataLegacy}
                  onClick={() => {
                    navigate("/legacyplan");
                  }}
                />
                <CardMenu2
                  color={" bg-[#FFE0E0]"}
                  colormain={"text-[#CC8C8D] hover:border-[#CC8C8D]"}
                  icon={"/images/home_kids.png"}
                  title={"แผนการศึกษาบุตร"}
                  onClick={() => {
                    navigate("/education");
                  }}
                  planed={dataKids}
                />
                <CardMenu2
                  color={" bg-[#FADF4D]"}
                  colormain={"text-[#B95940] hover:border-[#B95940]"}
                  icon={"/images/home_graph2.png"}
                  title={"แผนอนาคต"}
                  planed={dataFuture}
                  onClick={() => {
                    navigate("/futureplan");
                  }}
                />
              </div>
            </>

            <button
              onClick={() => {
                navigate("/report");
              }}
              className="w-full flex cursor-pointer space-x-2 justify-center items-center mt-3 rounded-[50px] border-[#00A9A0] border h-[48px]"
            >
              <div>
                <img src="/images/report.svg" />
              </div>
              <div className="text-[#00A9A0]">ดูรายงานสรุปผล</div>
            </button>
          </div>
        ) : (
          // Not Logined
          <div className={STYLE_PARENT_MENU_HOME}>
            <CardMenu
              color={" bg-secoundblue"}
              colormain={"text-maincyan hover:border-mainblue"}
              icon={"/images/home_retire2.png"}
              title={"วางแผนเกษียณ"}
              onClick={() => {
                navigate("/retireplan");
              }}
              planed={true}
            />
            <CardMenu
              color={` bg-blue200 `}
              colormain={" text-mainblue hover:border-mainblue"}
              icon={"/images/home_tax2.png"}
              title={"วางแผนภาษี"}
              onClick={(e) => {
                e.preventDefault();
                navigate("/taxplan");
              }}
              planed={true}
            />
            <CardMenu
              color={"bg-yellowopacity"}
              colormain={"text-mainbrown hover:border-mainbrown"}
              icon={"images/home_other5.png"}
              title={"วางแผนออม"}
              onClick={() => {
                navigate("/savingsplan");
              }}
              planed={true}
            />
            <CardMenu
              color={"bg-[#E2CCFF]"}
              colormain={"text-[#8D32E8] hover:border-[#8D32E8] text-xs"}
              icon={"images/home_other6.png"}
              title={"ปรึกษานักวางแผนการเงิน"}
              padding="p-2"
              onClick={() => {
                window?.open(
                  "https://liff.line.me/1645278921-kWRPP32q/?accountId=assurewealth"
                );
              }}
              planed={true}
            />
          </div>
        )}
      </>
    );
  };

  //---------- Login - None Premium ----------//
  const MenuNonePremium = () => {
    return (
      <div className={STYLE_PARENT_MENU_HOME}>
        <CardMenu3
          color={" bg-gray30"}
          colormain={"text-maingray hover:border-maingray"}
          icon={"/images/home_balance.png"}
          title={"งบดุล"}
        />
        <CardMenu3
          color={" bg-gray30"}
          colormain={"text-maingray hover:border-maingray"}
          icon={"/images/home_debt.png"}
          title={"แผนจัดการหนี้สิน"}
        />
        <CardMenu3
          color={" bg-gray30"}
          colormain={"text-maingray hover:border-maingray"}
          icon={"/images/home_insuarance.png"}
          title={"แผนประกัน"}
        />
        <CardMenu3
          color={" bg-gray30"}
          colormain={"text-maingray hover:border-maingray"}
          icon={"/images/home_risk.png"}
          title={"แผนบริหารความเสี่ยง"}
        />
        <CardMenu3
          color={" bg-gray30"}
          colormain={"text-maingray hover:border-maingray"}
          icon={"/images/home_invest.png"}
          title={"แผนลงทุน"}
        />
        <CardMenu3
          color={" bg-gray30"}
          colormain={"text-maingray hover:border-maingray"}
          icon={"/images/home_legacy.png"}
          title={"แผนมรดก"}
        />
        <CardMenu3
          color={" bg-gray30"}
          colormain={"text-maingray hover:border-maingray"}
          icon={"/images/home_kids.png"}
          title={"แผนการศึกษาบุตร"}
        />
        <CardMenu3
          color={" bg-gray30"}
          colormain={"text-maingray hover:border-maingray"}
          icon={"/images/home_graph.png"}
          title={"แผนอนาคต"}
        />
      </div>
    );
  };

  // console.log("insuaranceData", insuaranceData);
  // console.log("userData : ", userData);
  // console.log("balanceData", balanceData);
  // console.log("riskData", riskData);

  return (
    <Container>
      <div
        style={{
          backgroundImage: `url(/images/circle_right.png)`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right top",
        }}
        className={` h-full  xxs:px-4 xs:px-7  py-6 font-IBMPlexSansThai `}
      >
        {loading ? (
          <>
            <div className={STYLE_HEAD_TITLE}>
              <SkeletonMain height={100} />
            </div>
            <div className=" mt-[6px] text-black font-normal mb-[18px]">
              <SkeletonMain height={161} />
              <SkeletonMain height={88} />
            </div>
            <div className=" grid grid-cols-2 gap-[15px]">
              <SkeletonMain height={143} />
              <SkeletonMain height={143} />
              <div className=" col-span-2">
                {" "}
                <SkeletonMain height={91} />
              </div>
            </div>
          </>
        ) : (
          <>
            {userData && userData?.is_premium ? (
              <div className=" flex items-center">
                <ImageMain
                  src="/images/house_welcome.png"
                  className=" w-10 h-10 "
                />
                <div className=" text-mainblue">
                  {greeting}
                  <span className=" pl-1 text-2xl font-semibold text-mainblue">
                    คุณ{userData?.firstname}
                  </span>{" "}
                </div>
              </div>
            ) : (
              <div>
                <div className={` flex justify-center `}>
                  <ImageMain
                    src="/images/homeicon_smile_1.png"
                    className=" w-8 h-8 "
                  />
                  <div className=" text-mainblue text-sm font-medium pt-2">
                    {" "}
                    วางแผนเรื่องเงินให้เป็นเรื่องง่าย
                  </div>
                </div>
                <div className=" flex justify-center items-center ml-4 -mt-1">
                  <div className=" text-mainblue text-base font-semibold  ">
                    มาทำแผนการเงินของคุณให้เป็นจริงวันนี้
                  </div>
                  <ImageMain
                    src="/images/homeicon_board_1.png"
                    className=" w-8 h-8"
                  />
                </div>
              </div>
            )}

            {/*-------------------- MENU --------------------*/}
            <div>
              <Menu
                dataUser={userData ? true : false}
                dataRetire={retireData ? true : false}
                dataTax={taxData ? true : false}
                dataSaving={savingData ? true : false}
                // dataRetire={retireData ? false : true}
                // dataTax={taxData ? false : true}
                // dataSaving={savingData ? false : true}
                dataBalance={balanceData ? true : false}
                dataDebt={
                  balanceData?.debt_short_list?.length > 0 ||
                  balanceData?.debt_long_list?.length > 0
                    ? true
                    : false
                }
                dataInsuarance={insuaranceData?.is_plan ? true : false}
                dataRisk={riskData ? true : false}
                dataInvest={
                  formInvest?.sum_score > 0 ? true : false
                }
                dataLegacy={false}
                dataKids={educationData?.length > 0 ? true : false}
                dataFuture={false}
                premium={userData?.is_premium}
              />
              <div className=" text-black text-base text-center my-4">
                เราสร้างความสุขและความสงบทางจิตใจ
                <div>ด้วยการวางแผนการเงิน</div>
              </div>
              {userData ? (
                <div className=" space-y-4 mb-4 ">
                  <CardProfile
                    onClick={() => {
                      setOpen(true);
                      navigate("/profile");
                    }}
                    img={"/images/home_profile.png"}
                    details={userData?.email}
                  />
                  <CardProfile
                    img={"/images/home_profile_2.png"}
                    head="นักวางแผนที่ดูแลท่าน"
                    title="ชื่อ"
                    details={
                      <div className="">
                        <span className=" pr-2">
                          {/* {userData?.firstname} */}
                          วรเดช
                        </span>
                        <span>
                          {/* {userData?.lastname} */}
                          ปัญจรงคะ
                        </span>
                      </div>
                    }
                    bottom="-bottom-5"
                  />
                  <div
                    className={`text-lg font-semibold font-IBMPlexSansThai text-mainblue text-center`}
                  >
                    เปิดโอกาสพิเศษสำหรับสมาชิกพรีเมี่ยม!
                  </div>
                  {!userData?.is_premium ? <MenuNonePremium /> : ""}
                </div>
              ) : (
                ""
              )}

              <div className=" mb-5">
                <PromotionCard />
              </div>
            </div>
          </>
        )}
      </div>
      <PopupMain
        flex=" flex flex-col"
        iconleft={true}
        icon={<ImageMain src="/images/workwithus.png" className=" w-5 h-5 " />}
        open={openPopup}
        textButton="สมัครโปรแกรมวางแผนการเงิน"
        img="/images/popup_icon_premium.png"
        width="w-[127px] "
        height="h-[93px]"
        text={
          <div className=" text-sm ">
            ตอบรับทุกช่วงชีวิต
            <div>ด้วยการวางแผนการเงินที่มีประสิทธิภาพ!</div>
            <div>เริ่มต้นกับโปรแกรมวางแผนการเงินแบบพรีเมี่ยม</div>
            <div>
              เพื่อให้มีเพื่อนคู่คิดตลอดทุกช่วงชีวิต มีความสุขและความสงบทางจิตใจ
            </div>
          </div>
        }
        title={`มั่นใจทางการเงินในระดับพรีเมี่ยม`}
        onClick={async () => {
          try {
            await api.put(`/profiles/schedule/${userData?._id}`, {});
            alert("สมัครสมาชิกในระดับพรีเมี่ยมเรียบร้อย");
            setOpenPopup(false);
            localStorage.clear();
            window.location.href = "/login";
          } catch (error) {
            console.log("preimum error", error);
          }
        }}
        button2={true}
        textButton2="ขอพิจารณาเพิ่มเติม"
        onClickSecond={() => {
          setOpenPopup(false);
        }}
      />
    </Container>
  );
};
export default HomeMain;
