import _ from "lodash";
import numeral from "numeral";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useBalanceStore } from "../../../_store/balanceStore";
import { MTButton } from "../../../components/button/MTButton";
import { HeaderTitle } from "../../../components/header/Header";
import { ImageMain } from "../../../components/img/Img.Main";
import Container from "../../../components/layout/Container";
import useExportCSV from "../../../utils/useExportCsv";
import { STYLE_REPORT_CONTAIN } from "../../../utils/useStyle";
import { INSURANCE_POLICY_STATUS } from "../../balance/functions";
import { HeaderReport } from "../components/header.main";

const ReportAllInsurancePage = () => {
  const navigate = useNavigate();
  const balanceData = useBalanceStore((state) => state.balanceData);
  const userData = useBalanceStore((state) => state.userData);
  const [loadingExport, setLoadingExport] = useState(false);

  // console.log("balanceData", balanceData);

  const options_insurance = [
    "expenses_insuarant_life",
    "expenses_insuarant_accident",
    "expenses_insuarant_car",
    "expenses_insuarant_fire",
    "expenses_insuarant_home",
  ];

  // INSURANCE_POLICY_STATUS
  const insuranceItems = useMemo(() => {
    let items = [];
    if (balanceData?.expenses_fixed_list?.length > 0) {
      let index = 0;
      let last_item = {};
      for (const thisExpenesFixed of balanceData?.expenses_fixed_list) {
        if (
          options_insurance.includes(thisExpenesFixed?.options_expenses_fixed)
        ) {
          const findOptionKey = options_insurance.find((option) => {
            return option === thisExpenesFixed?.options_expenses_fixed;
          });
          if (findOptionKey) {
            const thisInsurances = thisExpenesFixed[`${findOptionKey}s`];
            for (const thisInsurance of thisInsurances) {
              let createItem = {
                index: index + 1, // ลำดับ
                company: _.get(thisInsurance, "title", ""), // บริษัท
                insurance_policy_status:
                  INSURANCE_POLICY_STATUS?.find((thisStatus) => {
                    return (
                      thisStatus.value ===
                      thisInsurance?.insurance_policy_status
                    );
                  })?.label || "", // สถานะกรมธรรม์
                insurance_policy_number: _.get(
                  thisInsurance,
                  "insurance_policy_number",
                  ""
                ), // เลขที่กรมธรรม์
                insurance_policy_name: _.get(
                  thisInsurance,
                  "insurance_policy_name",
                  ""
                ), // ชื่อกรมธรรม์
                insurance_policy_date: _.get(
                  thisInsurance,
                  "insurance_policy_date",
                  ""
                ), // วันที่ของกรมธรรม์ประกันภัย
                date_insurance_policy_makecompact: _.get(
                  thisInsurance,
                  "date_insurance_policy_makecompact",
                  ""
                ), // วันทำสัญญาประกันภัย
                date_insurance_policy_finishcompact: _.get(
                  thisInsurance,
                  "date_insurance_policy_finishcompact",
                  ""
                ), // วันสิ้นสุดสัญญาประกันภัย
                insurance_fund: _.get(thisInsurance, "insurance_fund", ""), // ทุนประกัน
                life_insurance: _.get(
                  thisInsurance,
                  "more_details.life_insurance",
                  ""
                ), // ชีวิตตามแบบประกันภัยข้างต้น
                life_allowance: _.get(
                  thisInsurance,
                  "more_details.life_allowance",
                  ""
                ), // ชีวิตสำหรับเบี้ยประกันภัยลงทุน
                except_allowance_wp: _.get(
                  thisInsurance,
                  "more_details.except_allowance_wp",
                  ""
                ), //ยกเว้นเบี้ยกรมธรรม์ประกันภัยหลัก (WP)
                except_allowance_pb: _.get(
                  thisInsurance,
                  "more_details.except_allowance_sp",
                  ""
                ), //ยกเว้นเบี้ยกรมธรรม์ประกันภัยหลัก (PB)
                contract_tpd: _.get(
                  thisInsurance,
                  "more_details.contract_tpd",
                  ""
                ), // สัญญาเพิ่มเติมกรณีทุพพลภาพ (TPD)
                protection_life_lt: _.get(
                  thisInsurance,
                  "more_details.protection_life_lt",
                  ""
                ), // ประกันชีวิตเพิ่มเติม (LT)
                except_allowance_lt: _.get(
                  thisInsurance,
                  "more_details.except_allowance_lt",
                  ""
                ), // ยกเว้นเบี้ยหลัก(LT) กรณีทุพพลภาพ
                accident_ai: _.get(
                  thisInsurance,
                  "more_details.accident_ai",
                  ""
                ), // อุบัติเหตุเพิ่มเติม (AI)
                accident_adb: _.get(
                  thisInsurance,
                  "more_details.accident_adb",
                  ""
                ), // อุบัติเหตุเพิ่มเติม (ADB)
                accident_add: _.get(
                  thisInsurance,
                  "more_details.accident_add",
                  ""
                ), // อุบัติเหตุเพิ่มเติม (ADD)
                hospital_treatment_surgery: _.get(
                  thisInsurance,
                  "more_details.hospital_treatment_surgery",
                  ""
                ), // การรักษาในโรงพยาบาลและศัลยกรรม
                hospital_treatment_surgery_lump_sum_payment: _.get(
                  thisInsurance,
                  "more_details.hospital_treatment_surgery_lump_sum_payment",
                  ""
                ), // การรักษาในโรงพยาบาลและศัลยกรรมแบบเหมาจ่าย
                daily_compensation_for_treatment: _.get(
                  thisInsurance,
                  "more_details.daily_compensation_for_treatment",
                  ""
                ), // เงินชดเชยรายวันในการรักษา
                daily_compensation_for_treatment_special: _.get(
                  thisInsurance,
                  "more_details.daily_compensation_for_treatment_special",
                  ""
                ), // เงินชดเชยรายวันในการรักษาพิเศษ
                compensation_for_cancer_treatment_cr: _.get(
                  thisInsurance,
                  "more_details.compensation_for_cancer_treatment_cr",
                  ""
                ), // ค่าชดเชยการรักษาโรคมะเร็งในโรงพยาบาล (CR)
                compensation_ecir: _.get(
                  thisInsurance,
                  "more_details.compensation_ecir",
                  ""
                ), // ค่าชดเชยโรยร้ายแรง (ECIR)
                compensation_aia_supercare: _.get(
                  thisInsurance,
                  "more_details.compensation_aia_supercare",
                  ""
                ), // ค่าชดเชยโรยร้ายแรง (AIA Supercare)
                compensation_aia_ci_plus_gold: _.get(
                  thisInsurance,
                  "more_details.compensation_aia_ci_plus_gold",
                  ""
                ), // ค่าชดเชยโรยร้ายแรง (AIA CI Plus Gold)
                compensation_aia_ci_plus: _.get(
                  thisInsurance,
                  "more_details.compensation_aia_ci_plus",
                  ""
                ), // ค่าชดเชยโรยร้ายแรง (AIA CI Plus) ไม่มีเงินคืน
                compensation_mutiple_care_ci: _.get(
                  thisInsurance,
                  "more_details.compensation_mutiple_care_ci",
                  ""
                ), // ค่าชดเชยโรยร้ายแรง (Mutiple Care CI) ไม่มีเงินคืน
                critical_illness_insurance_yearly_ci: _.get(
                  thisInsurance,
                  "more_details.critical_illness_insurance_yearly_ci",
                  ""
                ), // ประกันภัยโรคร้ายแรงแบบซีไอรายปี
                medical_expenses_accident_pa: _.get(
                  thisInsurance,
                  "more_details.medical_expenses_accident_pa",
                  ""
                ), // ค่ารักษาพยาบาลจากอุบัติเหตุ (PA)
                allowance_last_time: _.get(
                  thisInsurance,
                  "more_details.allowance_last_time",
                  ""
                ), // ค่าเบี้ยประกันครั้งล่าสุด
                date_pay_allowance_last_time: _.get(
                  thisInsurance,
                  "more_details.date_pay_allowance_last_time",
                  ""
                ), // วันที่จ่ายเบี้ยประกันครั้งล่าสุด
                date_due_allowance_next_time: _.get(
                  thisInsurance,
                  "more_details.date_due_allowance_next_time",
                  ""
                ), // วันที่ครบกำหนดจ่ายเบี้ยประกันครั้งถัดไป
                date_due_allowance_final_time: _.get(
                  thisInsurance,
                  "more_details.date_due_allowance_final_time",
                  ""
                ), // วันที่ครบกำหนดจ่ายเบี้ยประกันครั้งสุดท้าย
                beneficiary_name_one: _.get(
                  thisInsurance,
                  "more_details.beneficiary_name_one",
                  ""
                ), // ชื่อผู้รับผลประโยชน์คนที่ 1
                beneficiary_name_two: _.get(
                  thisInsurance,
                  "more_details.beneficiary_name_two",
                  ""
                ), // ชื่อผู้รับผลประโยชน์คนที่ 2
                annotation: _.get(thisInsurance, "more_details.annotation", ""), // หมายเหตุ
                // สัญญาเพิ่มเติม เอไอเอ มัลติเพย์ ซีไอ
                aia_mutiplay_ci_more: _.get(
                  thisInsurance,
                  "more_details.aia_mutiplay_ci_more",
                  ""
                ),
                // สัญญาเพิ่มเติม เอไอเอ โทเทิล แคร์
                aia_total_care_more: _.get(
                  thisInsurance,
                  "more_details.aia_total_care_more",
                  ""
                ),
                // ประเภทงวดชำระเบี้ย
                type_of_payment: _.get(
                  thisInsurance,
                  "more_details.type_of_payment",
                  ""
                ),
                // สถานะสิทธิประโยชน์ เช่น AIA Vitlaity
                status_aia_vitlaity: _.get(
                  thisInsurance,
                  "more_details.status_aia_vitlaity",
                  ""
                ),
                // มูลค่าเวนคืนกรมธรรม์ /มูลค่าหน่วยลงทุน
                policy_surrender_value: _.get(
                  thisInsurance,
                  "more_details.policy_surrender_value",
                  ""
                ),
                // เงินคืนตามสัญญาและเงินปันผลสะสมที่ฝากกับบริษัท
                contractual_refund: _.get(
                  thisInsurance,
                  "more_details.contractual_refund",
                  ""
                ),
                // ค่าเบี้้ยประกันรวมรายปี
                total_year: _.get(thisInsurance, "more_details.total_year", ""),
              };
              items.push(createItem);
              index++;
            }
          }
        }
      }

      last_item = {
        insurance_fund: items.reduce((sum, item) => {
          return sum + parseFloat(item.insurance_fund);
        }, 0),
        allowance_last_time: items.reduce((sum, item) => {
          return sum + parseFloat(item.allowance_last_time);
        }, 0),
      };
      items.push({
        index: "รวม",
        ...last_item,
      });
    }
    return items;
  }, [balanceData]);

  const { onClickExport } = useExportCSV({
    fileName: "รายงานประกันของคุณ",
    headers: [
      {
        label: "ลำดับ",
        key: "index",
      },
      {
        label: "บริษัท",
        key: "company",
      },
      {
        label: "สถานะกรมธรรม์",
        key: "insurance_policy_status",
      },
      {
        label: "เลขที่กรมธรรม์",
        key: "insurance_policy_number",
      },
      {
        label: "ชื่อกรมธรรม์",
        key: "insurance_policy_name",
      },
      {
        label: "วันที่ของกรมธรรม์ประกันภัย",
        key: "insurance_policy_date",
      },
      {
        label: "วันทำสัญญาประกันภัย",
        key: "date_insurance_policy_makecompact",
      },
      {
        label: "วันสิ้นสุดสัญญาประกันภัย",
        key: "date_insurance_policy_finishcompact",
      },
      {
        label: "ทุนประกัน",
        key: "insurance_fund",
      },
      {
        label: "ชีวิตตามแบบประกันภัยข้างต้น",
        key: "life_insurance",
      },
      {
        label: "ชีวิตสำหรับเบี้ยประกันภัยลงทุน",
        key: "life_allowance",
      },
      {
        label: "ยกเว้นเบี้ยกรมธรรม์ประกันภัยหลัก (WP)",
        key: "except_allowance_wp",
      },
      {
        label: "ยกเว้นเบี้ยกรมธรรม์ประกันภัยหลัก (PB)",
        key: "except_allowance_pb",
      },
      {
        label: "สัญญาเพิ่มเติมกรณีทุพพลภาพ (TPD)",
        key: "contract_tpd",
      },
      {
        label: "สัญญาเพิ่มเติม เอไอเอ มัลติเพย์ ซีไอ",
        key: "aia_mutiplay_ci_more",
      },
      {
        label: "สัญญาเพิ่มเติม เอไอเอ โทเทิล แคร์",
        key: "aia_total_care_more",
      },
      {
        label: "ประกันชีวิตเพิ่มเติม (LT)",
        key: "protection_life_lt",
      },
      {
        label: "ยกเว้นเบี้ยหลัก(LT) กรณีทุพพลภาพ",
        key: "except_allowance_lt",
      },
      {
        label: "อุบัติเหตุเพิ่มเติม (AI)",
        key: "accident_ai",
      },
      {
        label: "อุบัติเหตุเพิ่มเติม (ADB)",
        key: "accident_adb",
      },
      {
        label: "อุบัติเหตุเพิ่มเติม (ADD)",
        key: "accident_add",
      },
      {
        label: "การรักษาในโรงพยาบาลและศัลยกรรม",
        key: "hospital_treatment_surgery",
      },
      {
        label: "การรักษาในโรงพยาบาลและศัลยกรรมแบบเหมาจ่าย",
        key: "hospital_treatment_surgery_lump_sum_payment",
      },
      {
        label: "เงินชดเชยรายวันในการรักษา",
        key: "daily_compensation_for_treatment",
      },
      {
        label: "เงินชดเชยรายวันในการรักษาพิเศษ",
        key: "daily_compensation_for_treatment_special",
      },
      {
        label: "ค่าชดเชยการรักษาโรคมะเร็งในโรงพยาบาล (CR)",
        key: "compensation_for_cancer_treatment_cr",
      },
      {
        label: "ค่าชดเชยโรยร้ายแรง (ECIR)",
        key: "compensation_ecir",
      },
      {
        label: "ค่าชดเชยโรยร้ายแรง (AIA Supercare)",
        key: "compensation_aia_supercare",
      },
      {
        label: "ค่าชดเชยโรยร้ายแรง (AIA CI Plus Gold)",
        key: "compensation_aia_ci_plus_gold",
      },
      {
        label: "ค่าชดเชยโรยร้ายแรง (AIA CI Plus) ไม่มีเงินคืน",
        key: "compensation_aia_ci_plus",
      },
      {
        label: "ค่าชดเชยโรยร้ายแรง (Mutiple Care CI) ไม่มีเงินคืน",
        key: "compensation_mutiple_care_ci",
      },
      {
        label: "ประกันภัยโรคร้ายแรงแบบซีไอรายปี",
        key: "critical_illness_insurance_yearly_ci",
      },
      {
        label: "ค่ารักษาพยาบาลจากอุบัติเหตุ (PA)",
        key: "medical_expenses_accident_pa",
      },
      {
        label: "สถานะสิทธิประโยชน์ เช่น AIA Vitlaity",
        key: "status_aia_vitlaity",
      },
      {
        label: "มูลค่าเวนคืนกรมธรรม์ /มูลค่าหน่วยลงทุน",
        key: "policy_surrender_value",
      },
      {
        label: "เงินคืนตามสัญญาและเงินปันผลสะสมที่ฝากกับบริษัท",
        key: "contractual_refund",
      },
      {
        label: "ประเภทงวดชำระเบี้ย",
        key: "type_of_payment",
      },
      {
        label: "ค่าเบี้ยประกันครั้งล่าสุด",
        key: "allowance_last_time",
      },
      {
        label: "ค่าเบี้้ยประกันรวมรายปี",
        key: "total_year",
      },
      {
        label: "วันที่จ่ายเบี้ยประกันครั้งล่าสุด",
        key: "date_pay_allowance_last_time",
      },
      {
        label: "วันที่ครบกำหนดจ่ายเบี้ยประกันครั้งถัดไป",
        key: "date_due_allowance_next_time",
      },
      {
        label: "วันที่ครบกำหนดจ่ายเบี้ยประกันครั้งสุดท้าย",
        key: "date_due_allowance_final_time",
      },
      {
        label: "ชื่อผู้รับผลประโยชน์คนที่ 1",
        key: "beneficiary_name_one",
      },
      {
        label: "ชื่อผู้รับผลประโยชน์คนที่ 2",
        key: "beneficiary_name_two",
      },
      {
        label: "หมายเหตุ",
        key: "annotation",
      },
    ],
    data: insuranceItems.map((item) => {
      return {
        ...item,
        insurance_fund: numeral(item?.insurance_fund).format("0,0.00"),
        allowance_last_time: numeral(item?.allowance_last_time).format(
          "0,0.00"
        ),
      };
    }),
    sheetName: "รายงานประกันของคุณ",
  });

  return (
    <Container>
      <div id="first_reload" className="pt-[14px]">
        <HeaderTitle
          title="รายงาน"
          isGoback
          onClickGoBack={() => {
            navigate("/report");
          }}
        />

        <div className={`${STYLE_REPORT_CONTAIN} flex flex-col gap-5`}>
          <HeaderReport
            icon={"/images/insurance-all-report.png"}
            title={"ประกันของคุณ"}
            className={" z-20"}
          />
          <div className="mx-auto">
            <ImageMain
              src="/images/allinsurance-report.png"
              className="h-[130px] w-fit"
            />
          </div>
          <div className="text-center text-sm font-medium">
            ดาวน์โหลดไฟล์ Excel เพื่อดูประกันของคุณที่มีทั้งหมด
          </div>
          <div className=" flex justify-center gap-2.5 ">
            <MTButton
              // loading={loadingExport}
              onClick={onClickExport}
              flexnone="flex-none"
              width="w-full text-xs"
              text="ดาวน์โหลดไฟล์ Excel"
              iconleft
              icon={
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_6711_51468)">
                    <path
                      d="M14.1733 8.54535H11.6287V7.27304H14.1733V8.54535ZM14.1733 9.27235H11.6287V10.5447H14.1733V9.27235ZM14.1733 3.27441H11.6287V4.5467H14.1733V3.27441ZM14.1733 5.27373H11.6287V6.54601H14.1733V5.27373ZM14.1733 11.2717H11.6287V12.544H14.1733V11.2717ZM15.9309 13.7799C15.8582 14.158 15.4038 14.167 15.1003 14.1798H9.44769V15.8156H8.31897L0 14.3615V1.64045L8.36806 0.18457H9.44769V1.63316H14.9058C15.213 1.64588 15.5511 1.62407 15.8182 1.80763C16.0054 2.07663 15.9873 2.41835 16 2.72732L15.9927 12.1895C15.9837 12.7184 16.0418 13.2583 15.9309 13.7799ZM6.66497 10.9282C6.16334 9.91035 5.65263 8.89976 5.15278 7.88191C5.64716 6.89135 6.13428 5.89716 6.61956 4.90295C6.20697 4.92295 5.79438 4.94838 5.38363 4.97748C5.07644 5.72448 4.71838 6.45151 4.47847 7.22398C4.25491 6.49513 3.95866 5.79354 3.68784 5.08288C3.28797 5.10469 2.88809 5.12832 2.48825 5.15195C2.90991 6.08257 3.35887 7.00038 3.76781 7.93645C3.28616 8.84523 2.83541 9.76676 2.36828 10.681C2.76631 10.6973 3.16438 10.7137 3.56241 10.7191C3.84597 9.99576 4.19856 9.29963 4.44575 8.5617C4.6675 9.35416 5.04372 10.0866 5.35272 10.8445C5.79075 10.8755 6.22694 10.9027 6.66497 10.9282ZM15.1331 2.49638H9.44769V3.27441H10.9017V4.5467H9.44769V5.27373H10.9017V6.54601H9.44769V7.27304H10.9017V8.54535H9.44769V9.27235H10.9017V10.5447H9.44769V11.2717H10.9017V12.544H9.44769V13.3845H15.1331V2.49638Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_6711_51468">
                      <rect width="16" height="16" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              }
            />
            <MTButton
              onClick={() => {
                navigate("/report");
              }}
              width="w-full"
              text="ย้อนกลับ"
              color="bg-white"
              border="border border-maincyan"
              colortext="text-maincyan group-hover:text-white"
              hovercolor="hover:bg-maincyan group "
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ReportAllInsurancePage;
