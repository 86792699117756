import numeral from "numeral";
import { ImageMain } from "../../../components/img/Img.Main";
import { ChartCashFlow } from "./VIChartCashFlow.Main";
import { useFuturePlanStore } from "../../../_store/futurePlanStore";
import { useMemo } from "react";
import { toFloat2 } from "../../../utils/useFunctions";
import { styleTableBody } from "../../retirement_plan/styles/TableStyle";

export const FutureStepThree = ({ age, value, valueAll }) => {
  const futurePlanData = useFuturePlanStore((state) => state.futurePlanData);

  const merge_keys = useMemo(() => {
    let income_keys = futurePlanData.cash_flow_keys.filter((cash_flow_key) => {
      return cash_flow_key.includes("income");
    });
    let expenese_keys = futurePlanData.cash_flow_keys.filter(
      (cash_flow_key) => {
        return cash_flow_key.includes("expenese");
      }
    );
    let merge_income_keys = [
      ...income_keys,
      "income_peryear",
      "remain_accumulate",
      "pension_peryear",
      "income_investment",
    ];
    let merge_expenese_keys = [
      ...expenese_keys,
      "outcome_peryear",
      "out_come_stable",
    ];
    let merge_keys = [...merge_expenese_keys, ...merge_income_keys];
    return merge_keys;
  }, [futurePlanData]);

  const dataDebt = useMemo(() => {
    let dataDebt = [];

    for (const merge_key of merge_keys) {
      let dataDebtItems = [];
      for (const cash_flow_item of futurePlanData.cash_flow_items) {
        if (cash_flow_item[merge_key] !== undefined) {
          let amount = String(merge_key).startsWith("expenese")
            ? -cash_flow_item[merge_key]
            : cash_flow_item[merge_key];

          amount = Math.round(amount);
          if ([0, -0].includes(amount)) {
            amount = "";
          }

          dataDebtItems.push(amount);
        }
      }
      dataDebt.push(dataDebtItems);
    }

    return dataDebt;
  }, [futurePlanData]);

  const labels = useMemo(() => {
    let labels = [];
    for (const cash_flow_item of futurePlanData.cash_flow_items) {
      labels.push(cash_flow_item.age);
    }
    return labels;
  }, [futurePlanData]);

  const moneyDeficite = useMemo(() => {
    let deficiteList = [];
    futurePlanData?.cash_flow_items?.map((item, index) => {
      let income =
        item?.income_other_items ||
        0 + item?.income_peryear ||
        0 + item?.income_legacy_items ||
        0 + item?.income_business_items ||
        0 + item?.income_property_items ||
        0 + item?.pension_peryear ||
        0;
      let expense =
        item?.expenese_home_items ||
        0 + item?.expenese_car_items ||
        0 + item?.expenese_children_items ||
        0 + item?.expenese_travel_items ||
        0 + item?.expenese_collection_items ||
        0 + item?.expenese_other_items ||
        0 + item?.outcome_peryear ||
        0 + item?.out_come_stable ||
        0;

      let diff = income - expense;

      if (income < expense) {
        let deficiteAge = {
          age: item.age,
          diff: numeral(Math.abs(diff)).format("0,0"),
        };
        deficiteList.push(deficiteAge);
      }
    });
    return deficiteList;
  }, [futurePlanData]);

  const moneyNeed = useMemo(() => {
    const findData = futurePlanData?.cash_flow_items?.filter((item) => {
      return item?.remain_accumulate < 0 && item?.remain < 0 ;
    });
    return findData;
  }, [futurePlanData]);

  return (
    <div className=" flex flex-col justify-center items-center gap-2.5">
      <div className=" text-mainblue font-bold text-center ">
        Cash flow รายรับรายจ่ายของแผนอนาคต
      </div>
      <ChartCashFlow
        labels={labels}
        dataDebt={dataDebt}
        merge_keys={merge_keys}
        width={320}
      />
      <div className=" bg-gray50 w-full rounded-lg h-full p-3 space-y-[6px]">
        <ImageMain
          src="../images/cashflow.png"
          className=" w-12 h-12 mx-auto"
        />
        <div className=" flex flex-col text-center text-mainblue text-sm ">
          เพื่อทำให้แผนอนาคตของคุณเป็นไปตามเป้าหมาย
          {moneyNeed?.length > 0 &&
            moneyNeed?.map((item, index) => {
              return (
                <div key={index}>
                  อายุ {item?.age} คุณต้องหารายรับเพิ่มอีก
                  <span className=" font-semibold">
                    {" "}
                    {numeral(Math.abs(item?.remain))?.format(
                      "0,0"
                    )}{" "}
                    บาท
                  </span>{" "}
                </div>
              );
            })}
        </div>
        <div className="flex justify-center text-center text-mainblue text-sm" >
          รวม
          <span className=" font-semibold">
            {" "}
            {numeral(Math.abs(moneyNeed.reduce((acc,cur)=>{
              return acc + cur?.remain
            },0)))?.format("0,0")} บาท
          </span>{" "}
        </div>
      </div>
    </div>
  );
};
