import { useNavigate } from "react-router-dom";
import { HeaderTitle } from "../../../components/header/Header";
import Container from "../../../components/layout/Container";
import { NavigatePath } from "../../../functions/Navigate.Goback";
import { MTButton } from "../../../components/button/MTButton";
import { CardReport } from "../components/cardMenu";
import { STYLE_BG_CIRCLE, STYLE_REPORT_CONTAIN } from "../../../utils/useStyle";
import { useRiskStore } from "../../../_store/riskStore";
import { HeaderReport } from "../components/header.main";
import { useEffect } from "react";
import { first_reload } from "../../tax_plan/functions/firstLoad";

const ReportRiskPage = () => {
  const navigate = useNavigate();
  const riskData = useRiskStore((state) => state.riskData);

  const checkDeadCase = () => {
    if (riskData?.dead_sum_score === 1) {
      return {
        icon: "/images/good1.png",
        panel: "ท่านมีความสามารถในการรับความเสี่ยงไว้เองได้",
      };
    } else if (riskData?.sick_sum_score === 1.5) {
      return {
        icon: "/images/good2.png",
        panel:
          "โอนความเสี่ยง' ท่านเลือกวิธีจัดการความเสี่ยงได้เหมาะสมท่านมีความสามารถในการรับ ความเสี่ยงไว้เองได้",
      };
    } else if (riskData?.dead_sum_score === 2) {
      return {
        icon: "/images/car_shield.png",
        panel:
          "แนะนำให้ท่านโอนความเสี่ยงด้วยการทำประกันชีวิตและประกันอุบัติเหตุ",
      };
    } else if (riskData?.dead_sum_score === 2.5) {
      return {
        icon: "/images/car_good_shield.png",
        panel:
          "โอนความเสี่ยง'ท่านเลือกวิธีจัดการความเสี่ยงได้เหมาะสม ท่านมีความสามารถในการรับความเสี่ยงไว้เองได้",
      };
    } else {
      return {
        icon: "/images/risk_dead.svg",
        panel: "ไม่มีข้อมูล",
      };
    }
  };

  const checkSickCase = () => {
    if (riskData?.sick_sum_score === 1) {
      return {
        icon: "/images/healing_shield.png",
        panel:
          "แนะนำให้ท่านโอนความเสี่ยงด้วยการทำประกันสุขภาพ ถ้าท่านสุขภาพไม่ดีเกิดการเจ็บป่วยก่อนการทำประกัน บริษัทประกันจะปฏิเสธการรับประกันท่านในโรคที่เป็นมาก่อนและภาวะสืบเนื่อง",
      };
    } else if (riskData?.sick_sum_score === 1.5) {
      return {
        icon: "/images/good_shield.png",
        panel:
          "แนะนำให้ท่านโอนความเสี่ยงด้วยการทำประกันสุขภาพ ถ้าท่านสุขภาพไม่ดีเกิดการเจ็บป่วยก่อนการทำประกัน บริษัทประกันจะปฏิเสธการรับประกันท่านในโรคที่เป็นมาก่อนและภาวะสืบเนื่อง",
      };
    } else if (riskData?.sick_sum_score === 2) {
      return {
        icon: "/images/food_healing.png",
        panel:
          "แนะนำให้ท่านโอนความเสี่ยงด้วยการทำประกัน สุขภาพ ดูแลสุขภาพตนเอง ทานอาหารให้ครบ 5 หมู่ ออกกำลังกายอย่างสม่ำเสมอ พักผ่อนให้เพียงพออยู่ในสภาพแวดล้อมที่ดี ฝึกทำสมาธิ ดูแลสติให้อยู่ในปัจจุบัน ทำจิตใจให้เบิกบาน",
      };
    } else if (riskData?.sick_sum_score === 2.5) {
      return {
        icon: "/images/good_food.png",
        panel:
          "'โอนความเสี่ยง' ท่านเลือกวิธีจัดการความเสี่ยงได้เหมาะสมแนะนำให้ท่านโอนความเสี่ยงด้วย การทำประกันสุขภาพดูแลสุขภาพตนเอง ทานอาหารให้ครบ 5 หมู่ ออกกำลังกายอย่าง สม่ำเสมอ พักผ่อนให้เพียงพอ อยู่ในสภาพแวดล้อมที่ดี ฝึกทำสมาธิ ดูแลสติให้อยู่ในปัจจุบัน ทำจิตใจให้เบิกบาน",
      };
    } else {
      return {
        icon: "/images/risk_sick.svg",
        panel: "ไม่มีข้อมูล",
      };
    }
  };

  const checkDisabledCase = () => {
    if (riskData?.disable_sum_score === 1) {
      return {
        icon: "/images/good1.png",
        panel: "ท่านมีความสามารถในการรับความเสี่ยงไว้เองได้",
      };
    } else if (riskData?.disable_sum_score === 1.5) {
      return {
        icon: "/images/good2.png",
        panel:
          "'โอนความเสี่ยง' ท่านเลือกวิธีจัดการความเสี่ยงได้เหมาะสมท่านมีความสามารถในการรับ ความเสี่ยงไว้เองได้",
      };
    } else if (riskData?.disable_sum_score === 2) {
      return {
        icon: "/images/food_healing.png",
        panel: "แนะนำให้ท่านโอนความเสี่ยงด้วยการทำประกันทุพพลภาพ",
      };
    } else if (riskData?.disable_sum_score === 2.5) {
      return {
        icon: "/images/good_food.png",
        panel:
          "'โอนความเสี่ยง' ท่านเลือกวิธีจัดการความเสี่ยงได้เหมาะสม แนะนำให้ท่านโอนความเสี่ยงด้วยการทำประกันการทุพพลภาพ",
      };
    } else {
      return {
        icon: "/images/risk_sick.svg",
        panel: "ไม่มีข้อมูล",
      };
    }
  };

  const checkAccidentCase = () => {
    if (riskData?.accident_sum_score === 1) {
      return {
        icon: "/images/good1.png",
        panel: "ท่านมีความสามารถในการรับความเสี่ยงไว้เองได้",
      };
    } else if (riskData?.accident_sum_score === 1.5) {
      return {
        icon: "/images/good2.png",
        panel:
          "'โอนความเสี่ยง' ท่านเลือกวิธีจัดการความเสี่ยงได้เหมาะสมท่านมีความสามารถในการรับความเสี่ยงไว้เองได้",
      };
    } else if (riskData?.accident_sum_score === 2) {
      return {
        icon: "/images/accident_shield.png",
        panel: "แนะนำให้ท่านโอนความเสี่ยงด้วยการทำประกันอุบัติเหตุ",
      };
    } else if (riskData?.accident_sum_score === 2.5) {
      return {
        icon: "/images/good_accident_shield.png",
        panel:
          "'โอนความเสี่ยง' ท่านเลือกวิธีจัดการความเสี่ยงได้เหมาะสม แนะนำให้ท่านโอนความเสี่ยงด้วยการทำประกันการอุบัติเหตุ",
      };
    } else {
      return {
        icon: "/images/risk_sick.svg",
        panel: "ไม่มีข้อมูล",
      };
    }
  };

  const checkFireCase = () => {
    if (riskData?.fire_sum_score === 1) {
      return {
        icon: "/images/good1.png",
        panel: "ท่านมีความสามารถในการรับความเสี่ยงไว้เองได้",
      };
    } else if (riskData?.fire_sum_score === 1.5) {
      return {
        icon: "/images/good2.png",
        panel:
          "'โอนความเสี่ยง' ท่านเลือกวิธีจัดการความเสี่ยง ได้เหมาะสมท่านมีความสามารถในการรับความเสี่ยงไว้เองได้",
      };
    } else if (riskData?.fire_sum_score === 2) {
      return {
        icon: "/images/fire_shield.png",
        panel: "แนะนำให้ท่านโอนความเสี่ยงด้วยการทำประกันอุบัติเหตุ",
      };
    } else if (riskData?.fire_sum_score === 2.5) {
      return {
        icon: "/images/good_fire_shield.png",
        panel:
          "'โอนความเสี่ยง' ท่านเลือกวิธีจัดการความเสี่ยงได้เหมาะสม แนะนำให้ท่านโอนความเสี่ยงด้วยการทำประกันการอุบัติเหตุ",
      };
    } else {
      return {
        icon: "/images/risk_fire.svg",
        panel: "ไม่มีข้อมูล",
      };
    }
  };

  const checkCarAccidentCase = () => {
    if (riskData?.car_accident_sum_score === 1) {
      return {
        icon: "/images/caraccidentshield.png",
        panel: "แนะนำให้ท่านโอนความเสี่ยง ด้วยการทำประกันภัยภาคบังคับ พ.ร.บ.",
      };
    } else if (riskData?.car_accident_sum_score === 1.5) {
      return {
        icon: "/images/caraccidentshield_good.png",
        panel:
          "'โอนความเสี่ยง' ท่านเลือกวิธีจัดการความเสี่ยงได้เหมาะสมแนะนำให้ท่านโอนความเสี่ยงด้วย การทำประกันภัยภาคบังคับ พ.ร.บ.",
      };
    } else if (riskData?.car_accident_sum_score === 2) {
      return {
        icon: "/images/caraccidentshield_one_two.png",
        panel:
          "แนะนำให้ท่านโอนความเสี่ยงด้วยการทำประกันภัยรถยนต์ภาคสมัครใจ ชั้น1, ชั้น2 และ ภาคบังคับ พ.ร.บ.",
      };
    } else if (riskData?.car_accident_sum_score === 2.5) {
      return {
        icon: "/images/caraccidentshield_three_four.png",
        panel:
          "'โอนความเสี่ยง' ท่านเลือกวิธีจัดการความเสี่ยงได้เหมาะสม แนะนำให้ท่านโอนความเสี่ยง ด้วยการทำประกันภัยรถยนต์ภาคสมัครใจ ชั้น2,ชั้น3 และ ภาคบังคับ พ.ร.บ.",
      };
    } else if (riskData?.car_accident_sum_score >= 3) {
      return {
        icon: "/images/good_caraccidentshield_one_two.png",
        panel:
          "'โอนความเสี่ยง' ท่านเลือกวิธีจัดการความเสี่ยงได้เหมาะสมแนะนำให้ท่านโอนความเสี่ยงด้วยการทำ ประกันภัยรถยนต์ภาคสมัครใจ ชั้น1, ชั้น2 และ ภาคบังคับ พ.ร.บ.",
      };
    } else {
      return {
        icon: "/images/risk_fire.svg",
        panel: "ไม่มีข้อมูล",
      };
    }
  };

  const caseType = [
    {
      title: "กรณีเสียชีวิต ",
      icon: checkDeadCase("dead_case")?.icon,
      panel: checkDeadCase("dead_case")?.panel,
    },
    {
      title: "กรณีเจ็บป่วย",
      icon: checkSickCase("sick_case")?.icon,
      panel: checkSickCase("sick_case")?.panel,
    },
    {
      title: "กรณีทุพพลภาพ",
      icon: checkDisabledCase("disable_case")?.icon,
      panel: checkDisabledCase("disable_case")?.panel,
    },
    {
      title: "กรณีอุบัติเหตุ",
      icon: checkAccidentCase("accident_case")?.icon,
      panel: checkAccidentCase("accident_case")?.panel,
    },
    {
      title: "กรณีอัคคีภัย",
      icon: checkFireCase("fire_case")?.icon,
      panel: checkFireCase("fire_case")?.panel,
    },
    {
      title: "กรณีอุบัติเหตุทางรถยนต์",
      icon: checkCarAccidentCase("car_accident")?.icon,
      panel: checkCarAccidentCase("car_accident")?.panel,
    },
  ];

  useEffect(() => {
    let ignore = false;
    first_reload(ignore);
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <Container>
      <div id="first_reload" className="pt-[14px]">
        <HeaderTitle
          title="รายงาน"
          isGoback
          onClickGoBack={() => {
            navigate("/report");
          }}
        />
        <div className={STYLE_REPORT_CONTAIN}>
          <HeaderReport
            icon={"/images/risk-report.png"}
            title={"แผนบริหารจัดการความเสี่ยง"}
          />

          <div className=" flex flex-col gap-5 mt-[17px]">
            {caseType?.map((item, index) => (
              <CardReport
                key={index}
                icon={item.icon}
                title={item.title}
                panel={item.panel}
              />
            ))}
          </div>
          <div className=" flex justify-center">
            <MTButton
              onClick={() => {
                navigate("/report");
              }}
              text="ย้อนกลับ"
              color="bg-white"
              border="border border-maincyan"
              colortext="text-maincyan group-hover:text-white"
              hovercolor="hover:bg-maincyan group "
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ReportRiskPage;
