import { useNavigate } from "react-router-dom";
import { HeaderTitle } from "../../components/header/Header";
import Container from "../../components/layout/Container";
import NavigateGoBack, { NavigatePath } from "../../functions/Navigate.Goback";
import { STYLE_MARGIN_TOP_PARENT } from "../../utils/useStyle";
import { InputMain } from "../../components/input/InputMain";
import { ImageMain } from "../../components/img/Img.Main";
import { useState } from "react";
import { CardFooter, Step, Stepper } from "@material-tailwind/react";
import { MTButton } from "../../components/button/MTButton";
import ViewNumberOne from "./component/ViewNumberOne.Main";
import ViewNumberTwo from "./component/ViewNumberTwo.Main";
import ViewNumberThree from "./component/ViewNumberThree.Main";
import ViewNumberFour from "./component/ViewNumberFour.Main";
import ViewNumberFive from "./component/ViewNumberFive.Main";
import ViewNumberSix from "./component/ViewNumberSix.Main";
import ViewNumberSeven from "./component/ViewNumberSeven.Main";
import ViewNumberEight from "./component/ViewNumberEight.Main";
import ViewNumberNine from "./component/ViewNumberNine.Main";
import ViewNumberTen from "./component/ViewNumberTen.Main";
import ViewSumTest from "./component/ViewSumTest.Main";
import { set } from "lodash";
import ViewMyPortResult from "./component/VIMyPortResult.Main";
import { FooterAfterSum } from "../../components/FooterAfterSum/FooterAfterSum";
import { PopupMain } from "../../components/popup/PopupMain";
import { useBalanceStore } from "../../_store/balanceStore";
import { useInvestStore } from "../../_store/investStore";

const InvestPlanMain = () => {
  const balanceData = useBalanceStore((state) => state?.balanceData);

  const navigate = useNavigate();
  const [tab, setTab] = useState(0);
  const [isLastStep, setIsLastStep] = useState(false);
  const [isFirstStep, setIsFirstStep] = useState(false);
  const [status, setStatus] = useState(""); // ["good", "bad", "getmore"
  const [openAlert, setOpenAlert] = useState(
    balanceData?.sum_property_invest === 0 ? true : false
  );

  const [test, setTest] = useState(false);
  const [done, setDone] = useState(false);

  const formInvest = useInvestStore((state) => state.investData);
  const setFormInvest = useInvestStore((state) => state.setInvestData);

  // Sum Score
  const getSum = () => {
    let sumScore = 0;
    //--------------- One ---------------//
    if (formInvest?.one_question_choice_one) {
      sumScore += 1;
    }
    if (formInvest?.one_question_choice_two) {
      sumScore += 2;
    }
    if (formInvest?.one_question_choice_three) {
      sumScore += 3;
    }
    if (formInvest?.one_question_choice_four) {
      sumScore += 4;
    }

    //--------------- Two ---------------//
    if (formInvest?.two_question_choice_one) {
      sumScore += 1;
    }
    if (formInvest?.two_question_choice_two) {
      sumScore += 2;
    }
    if (formInvest?.two_question_choice_three) {
      sumScore += 3;
    }
    if (formInvest?.two_question_choice_four) {
      sumScore += 4;
    }

    //--------------- Three ---------------//
    if (formInvest?.three_question_choice_one) {
      sumScore += 1;
    }
    if (formInvest?.three_question_choice_two) {
      sumScore += 2;
    }
    if (formInvest?.three_question_choice_three) {
      sumScore += 3;
    }
    if (formInvest?.three_question_choice_four) {
      sumScore += 4;
    }

    //--------------- Four ---------------//
    if (formInvest?.four_question_choice_four) {
      sumScore += 4;
    } else if (formInvest?.four_question_choice_three) {
      sumScore += 3;
    } else if (formInvest?.four_question_choice_two) {
      sumScore += 2;
    } else if (formInvest?.four_question_choice_one) {
      sumScore += 1;
    }

    //--------------- Five ---------------//
    if (formInvest?.five_question_choice_one) {
      sumScore += 1;
    }
    if (formInvest?.five_question_choice_two) {
      sumScore += 2;
    }
    if (formInvest?.five_question_choice_three) {
      sumScore += 3;
    }
    if (formInvest?.five_question_choice_four) {
      sumScore += 4;
    }

    //--------------- Six ---------------//
    if (formInvest?.six_question_choice_one) {
      sumScore += 1;
    }
    if (formInvest?.six_question_choice_two) {
      sumScore += 2;
    }
    if (formInvest?.six_question_choice_three) {
      sumScore += 3;
    }
    if (formInvest?.six_question_choice_four) {
      sumScore += 4;
    }

    //--------------- Seven ---------------//
    if (formInvest?.seven_question_choice_one) {
      sumScore += 1;
    }
    if (formInvest?.seven_question_choice_two) {
      sumScore += 2;
    }
    if (formInvest?.seven_question_choice_three) {
      sumScore += 3;
    }
    if (formInvest?.seven_question_choice_four) {
      sumScore += 4;
    }

    //--------------- Eight ---------------//
    if (formInvest?.eigth_question_choice_one) {
      sumScore += 1;
    }
    if (formInvest?.eigth_question_choice_two) {
      sumScore += 2;
    }
    if (formInvest?.eigth_question_choice_three) {
      sumScore += 3;
    }
    if (formInvest?.eigth_question_choice_four) {
      sumScore += 4;
    }

    //--------------- Nine ---------------//
    if (formInvest?.nine_question_choice_one) {
      sumScore += 1;
    }
    if (formInvest?.nine_question_choice_two) {
      sumScore += 2;
    }
    if (formInvest?.nine_question_choice_three) {
      sumScore += 3;
    }
    if (formInvest?.nine_question_choice_four) {
      sumScore += 4;
    }

    //--------------- Ten ---------------//
    if (formInvest?.ten_question_choice_one) {
      sumScore += 1;
    }
    if (formInvest?.ten_question_choice_two) {
      sumScore += 2;
    }
    if (formInvest?.ten_question_choice_three) {
      sumScore += 3;
    }
    if (formInvest?.ten_question_choice_four) {
      sumScore += 4;
    }

    return sumScore;
  };

  const checkAllAnswer = () => {
    if (
      (formInvest.one_question_choice_one === false &&
        formInvest.one_question_choice_two === false &&
        formInvest.one_question_choice_three === false &&
        formInvest.one_question_choice_four === false) ||
      (formInvest.two_question_choice_one === false &&
        formInvest.two_question_choice_two === false &&
        formInvest.two_question_choice_three === false &&
        formInvest.two_question_choice_four === false) ||
      (formInvest.three_question_choice_one === false &&
        formInvest.three_question_choice_two === false &&
        formInvest.three_question_choice_three === false &&
        formInvest.three_question_choice_four === false) ||
      (formInvest.four_question_choice_one === false &&
        formInvest.four_question_choice_two === false &&
        formInvest.four_question_choice_three === false &&
        formInvest.four_question_choice_four === false) ||
      (formInvest.five_question_choice_one === false &&
        formInvest.five_question_choice_two === false &&
        formInvest.five_question_choice_three === false &&
        formInvest.five_question_choice_four === false) ||
      (formInvest.six_question_choice_one === false &&
        formInvest.six_question_choice_two === false &&
        formInvest.six_question_choice_three === false &&
        formInvest.six_question_choice_four === false) ||
      (formInvest.seven_question_choice_one === false &&
        formInvest.seven_question_choice_two === false &&
        formInvest.seven_question_choice_three === false &&
        formInvest.seven_question_choice_four === false) ||
      (formInvest.eigth_question_choice_one === false &&
        formInvest.eigth_question_choice_two === false &&
        formInvest.eigth_question_choice_three === false &&
        formInvest.eigth_question_choice_four === false) ||
      (formInvest.nine_question_choice_one === false &&
        formInvest.nine_question_choice_two === false &&
        formInvest.nine_question_choice_three === false &&
        formInvest.nine_question_choice_four === false) ||
      (formInvest.ten_question_choice_one === false &&
        formInvest.ten_question_choice_two === false &&
        formInvest.ten_question_choice_three === false &&
        formInvest.ten_question_choice_four === false)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const onClickSumScore = () => {
    const isCheckAllAnswer = checkAllAnswer();
    if (isCheckAllAnswer) return;
    const score = getSum();
    setDone(true);
    setFormInvest({ ...formInvest, sum_score: score });
    // setInvestData({ ...formInvest, sum_score: score });
  };

  // Validate ถ้าไม่กรอกสินทรัพย์เพื่อการลงทุน ให้แสดง popup
  const validatePropertyInvest = () => {
    if (balanceData?.sum_property_invest === 0) {
      return setOpenAlert(true);
    } else {
      return onClickSumScore();
    }
  };

  // console.log(status, "status");
  // console.log("formInvest : ", formInvest);

  return (
    <Container>
      <div className=" mb-20">
        <div className={`${STYLE_MARGIN_TOP_PARENT}  `}>
          <HeaderTitle
            title="วางแผนการลงทุน"
            isGoback
            onClickGoBack={() => {
              test ? setTest("") : NavigatePath(navigate, "/");
            }}
          />

          {!test ? (
            <div>
              {" "}
              <ImageMain
                src="../images/invest_icon.png"
                className="  w-[232px] h-[118px] mx-auto mt-[51px]"
              />
              <div className=" text-center mt-8 text-maincyan font-semibold">
                <div> วัดระดับความสามารถในการรับความเสี่ยง</div>
                <div>ของท่านด้วยแบบทดสอบจำนวน 10 ข้อ</div>
              </div>
              <div className=" flex justify-center ">
                <MTButton
                  text="เริ่มทำแบบทดสอบ"
                  width="w-fit"
                  onClick={() => {
                    if (balanceData?.sum_property_invest === 0) {
                      setOpenAlert(true);
                    } else {
                      setTest(true);
                      setTab(0);
                    }
                  }}
                />
              </div>
              <PopupMain
                open={openAlert}
                text={`กรุณากรอกข้อมูลสินทรัพย์เพื่อการลงทุน สำหรับการวิเคราะห์ข้อมูล`}
                onClick={() => {
                  setOpenAlert(false);
                  setTest(false);
                }}
              />
            </div>
          ) : (
            <div className=" px-5 mt-4 ">
              {!done ? (
                <div>
                  <Stepper
                    className=" gap-1 "
                    activeStep={tab}
                    lineClassName="hidden"
                    activeLineClassName="bg-maincyan"
                    //   className={`${STYLE_TAB} `}
                    isLastStep={(value) => setIsLastStep(value)}
                    isFirstStep={(value) => setIsFirstStep(value)}
                  >
                    {/*---- test 1 ----*/}
                    <Step
                      className={`${
                        tab === 0 ? "!bg-maincyan w-14" : " !bg-gray-300 w-10 "
                      } h-[6px]`}
                      activeClassName="!bg-maincyan "
                      onClick={() => setTab(0)}
                    ></Step>

                    {/*---- test 2 ----*/}
                    <Step
                      onClick={() => setTab(1)}
                      className={`${
                        tab === 1 ? "!bg-maincyan w-14" : " !bg-gray-300 w-10"
                      } h-[6px]`}
                      activeClassName="!bg-maincyan"
                    ></Step>

                    {/*---- test 3 ----*/}
                    <Step
                      className={`${
                        tab === 2 ? "!bg-maincyan w-14" : " !bg-gray-300 w-10"
                      } h-[6px]`}
                      activeClassName="!bg-maincyan"
                      onClick={() => setTab(2)}
                    ></Step>

                    {/*---- test 4 ----*/}
                    <Step
                      className={`${
                        tab === 3 ? "!bg-maincyan w-14" : " !bg-gray-300 w-10"
                      } h-[6px]`}
                      activeClassName="!bg-maincyan"
                      onClick={() => setTab(3)}
                    ></Step>

                    {/*---- test 5 ----*/}
                    <Step
                      className={`${
                        tab === 4 ? "!bg-maincyan w-14" : " !bg-gray-300 w-10"
                      } h-[6px]`}
                      activeClassName="!bg-maincyan"
                      onClick={() => setTab(4)}
                    ></Step>

                    {/*---- test 6 ----*/}
                    <Step
                      className={`${
                        tab === 5 ? "!bg-maincyan w-14" : " !bg-gray-300 w-10"
                      } h-[6px]`}
                      activeClassName="!bg-maincyan"
                      onClick={() => setTab(5)}
                    ></Step>

                    {/*---- test 7 ----*/}
                    <Step
                      className={`${
                        tab === 6 ? "!bg-maincyan w-14" : " !bg-gray-300 w-10"
                      } h-[6px]`}
                      activeClassName="!bg-maincyan"
                      onClick={() => setTab(6)}
                    ></Step>

                    {/*---- test 8 ----*/}
                    <Step
                      className={`${
                        tab === 7 ? "!bg-maincyan w-14" : " !bg-gray-300 w-10"
                      } h-[6px]`}
                      activeClassName="!bg-maincyan"
                      onClick={() => setTab(7)}
                    ></Step>

                    {/*---- test 9 ----*/}
                    <Step
                      className={`${
                        tab === 8 ? "!bg-maincyan w-14" : " !bg-gray-300 w-10"
                      } h-[6px]`}
                      activeClassName="!bg-maincyan"
                      onClick={() => setTab(8)}
                    ></Step>

                    {/*---- test 10 ----*/}
                    <Step
                      className={`${
                        tab === 9 ? "!bg-maincyan w-14" : " !bg-gray-300 w-10"
                      } h-[6px]`}
                      activeClassName="!bg-maincyan"
                      onClick={() => setTab(9)}
                    ></Step>
                  </Stepper>
                  {tab === 0 && (
                    <ViewNumberOne
                      formInvest={formInvest}
                      setFormInvest={setFormInvest}
                    />
                  )}
                  {tab === 1 && (
                    <ViewNumberTwo
                      formInvest={formInvest}
                      setFormInvest={setFormInvest}
                    />
                  )}
                  {tab === 2 && (
                    <ViewNumberThree
                      formInvest={formInvest}
                      setFormInvest={setFormInvest}
                    />
                  )}
                  {tab === 3 && (
                    <ViewNumberFour
                      formInvest={formInvest}
                      setFormInvest={setFormInvest}
                    />
                  )}
                  {tab === 4 && (
                    <ViewNumberFive
                      formInvest={formInvest}
                      setFormInvest={setFormInvest}
                    />
                  )}
                  {tab === 5 && (
                    <ViewNumberSix
                      formInvest={formInvest}
                      setFormInvest={setFormInvest}
                    />
                  )}
                  {tab === 6 && (
                    <ViewNumberSeven
                      formInvest={formInvest}
                      setFormInvest={setFormInvest}
                    />
                  )}
                  {tab === 7 && (
                    <ViewNumberEight
                      formInvest={formInvest}
                      setFormInvest={setFormInvest}
                    />
                  )}
                  {tab === 8 && (
                    <ViewNumberNine
                      formInvest={formInvest}
                      setFormInvest={setFormInvest}
                    />
                  )}
                  {tab === 9 && (
                    <ViewNumberTen
                      formInvest={formInvest}
                      setFormInvest={setFormInvest}
                    />
                  )}
                  <div className="flex justify-center gap-2.5 w-full mt-[16px] mb-[38px]">
                    <MTButton
                      text="ย้อนกลับ"
                      color="bg-white"
                      border="border border-maincyan"
                      colortext="text-maincyan group-hover:text-white"
                      hovercolor="hover:bg-maincyan group "
                      width="w-full"
                      onClick={() => {
                        tab > 0 ? setTab(tab - 1) : setTest(false);
                      }}
                    />
                    {tab === 9 ? (
                      <MTButton
                        disabled={checkAllAnswer()}
                        text={"ส่งแบบทดสอบ"}
                        color="bg-maincyan"
                        width="w-full"
                        onClick={onClickSumScore}
                      />
                    ) : (
                      <MTButton
                        text={"ถัดไป"}
                        color="bg-maincyan"
                        width="w-full"
                        onClick={() => {
                          tab < 9 ? setTab(tab + 1) : setTab(tab);
                        }}
                      />
                    )}
                  </div>{" "}
                  {tab === 9 && checkAllAnswer() && (
                    <div className="-mt-6 text-center text-red-500">
                      *กรุณาตอบคำถามให้ครบก่อนส่งแบบทดสอบ
                    </div>
                  )}
                </div>
              ) : (
                <>
                  <ViewSumTest result={formInvest.sum_score} />
                  <ViewMyPortResult status={status} />

                  <FooterAfterSum />
                  <div className=" flex justify-center items-center mt-5">
                    <MTButton
                      text="ย้อนกลับ"
                      color="bg-white"
                      width="w-full"
                      border="border border-maincyan"
                      colortext="text-maincyan group-hover:text-white"
                      hovercolor="hover:bg-maincyan group "
                      // width="w-full"
                      onClick={() => {
                        setTab(tab);
                        setDone(false);
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};

export default InvestPlanMain;
