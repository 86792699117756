import { NumericFormat } from "react-number-format";
import { CardMain } from "../../../components/card/CardMain";
import {
  STYLE_BORDER_YELLOW_AC,
  STYLE_BORDER_YELLOW_PROPOTION,
  STYLE_INPUT,
  STYLE_INPUT_SLIDER,
  STYLE_PADDING_MAIN,
  STYLE_TEXT_CYAN,
} from "../../../utils/useStyle";
import { useState } from "react";
import numeral from "numeral";
import { ICON_ARROW_UP_DOWN } from "../../../utils/useIcons";
import { HeadtitleCard } from "../../../components/header/Header";
import { SliderSingle } from "../../../components/slider/SliderSingle";
import { useInsuranceStore } from "../../../_store/insuranceStore";
import { defaultValue } from "../../../functions/main";
import NumbericValidateRed from "../../../components/DebtBalance/numericRed";

const ViewDisable_StepTwo = ({ onChangeForm }) => {
  const [isOpen, setIsOpen] = useState(true);
  const insuranceData = useInsuranceStore((state) => state.insuranceData);

  return (
    <div>
      <div className={`${STYLE_PADDING_MAIN} my-3 space-y-5 `}>
        <CardMain
          title={"คำนวณรายได้"}
          panel={
            <div>
              <div className=" px-3 space-y-1">
                <div className=" font-semibold text-maincyan text-center text-xl">
                  {numeral(insuranceData.sum_disabled_family_expenses).format(
                    "0,0"
                  )}{" "}
                  บาท
                </div>
                <div className=" text-mainblue text-center text-sm font-medium">
                รายได้คงเหลือตอนท่านยังเป็นปกติ
                </div>
              </div>
              <div className=" border-b border-gray60 w-full my-2.5"></div>
              <div
                onClick={() => setIsOpen(!isOpen)}
                className=" flex justify-center items-center gap-1 text-xs text-maingray font-medium"
              >
                รายละเอียด
                <ICON_ARROW_UP_DOWN
                  className={` ${
                    isOpen ? "rotate-180" : ""
                  } w-4 h-4 transition-all`}
                />
              </div>
              {isOpen && (
                <div className=" my-4">
                  <div className=" text-sm text-center  px-3 mb-2.5 ">
                    หากทุพพลภาพค่าใช้จ่ายที่จะไม่เกิดขึ้น ได้แก่  
                  </div>
                  <div className=" space-y-2.5">
                    <HeadtitleCard title="เงินออมทั้งปี" />
                    <div className=" px-3">
                     <NumbericValidateRed
                        maxLength={11}
                        placeholder="xx,xxx"
                        thousandSeparator=","
                        inputMode="decimal"
                        pattern="[0-9],*"
                        className={`${STYLE_INPUT} `}
                        onChange={onChangeForm(
                          "disabled_personal_expenses_saving"
                        )}
                        value={insuranceData?.disabled_personal_expenses_saving}
                      />
                    </div>
                    <HeadtitleCard title="ภาษีเงินได้" />
                    <div className=" px-3">
                     <NumbericValidateRed
                        maxLength={11}
                        placeholder="xx,xxx"
                        thousandSeparator=","
                        inputMode="decimal"
                        pattern="[0-9],*"
                        className={`${STYLE_INPUT} `}
                        onChange={onChangeForm(
                          "disabled_personal_expenses_tax"
                        )}
                        value={insuranceData?.disabled_personal_expenses_tax}
                      />
                    </div>
                    <HeadtitleCard title="กองทุนสำรองเลี้ยงชีพทั้งปี" />
                    <div className=" px-3">
                     <NumbericValidateRed
                        maxLength={11}
                        placeholder="xx,xxx"
                        thousandSeparator=","
                        inputMode="decimal"
                        pattern="[0-9],*"
                        className={`${STYLE_INPUT} `}
                        onChange={onChangeForm(
                          "disabled_personal_expenses_life_fund"
                        )}
                        value={
                          insuranceData?.disabled_personal_expenses_life_fund
                        }
                      />
                    </div>
                    <HeadtitleCard title="เงินประกันสังคมทั้งปี" />
                    <div className=" px-3">
                     <NumbericValidateRed
                        maxLength={11}
                        placeholder="xx,xxx"
                        thousandSeparator=","
                        inputMode="decimal"
                        pattern="[0-9],*"
                        className={`${STYLE_INPUT} `}
                        onChange={onChangeForm(
                          "disabled_personal_expenses_social_insurance"
                        )}
                        value={
                          insuranceData?.disabled_personal_expenses_social_insurance
                        }
                      />
                    </div>
                  </div>
                  <div className=" text-center mt-4 font-medium">
                  รวมค่าใช้จ่ายที่จะไม่เกิดขึ้นหลังทุพพลภาพ
                    <div className=" text-mainblue font-semibold text-xl">
                      {numeral(
                        defaultValue(
                          insuranceData.sum_disabled_personal_expenses
                        )
                      ).format("0,0")}{" "}
                    </div>
                  </div>
                  <div className="border-b border-mainyellow w-full my-2.5"></div>
                  <div className=" text-center mt-4">
                    <div className=" text-mainblue font-semibold text-xl">
                      รายได้คงเหลือตอนท่านยังเป็นปกติ
                    </div>
                    <div className=" text-maincyan font-semibold text-2xl">
                      {numeral(
                        insuranceData.sum_disabled_family_expenses
                      ).format("0,0")}{" "}
                      บาท
                    </div>
                  </div>
                </div>
              )}
            </div>
          }
        />
      </div>
    </div>
  );
};

export default ViewDisable_StepTwo;
