import { useEffect, useState } from "react";
import { useBalanceStore } from "../../../_store/balanceStore";
import {
  INSURANCE_POLICY_STATUS,
  INSURANCE_POLICY_TYPE,
  INSURANCE_POLICY_TYPE_CAR,
  calPeryear,
  options_expenses_fixed,
  options_expenses_invest,
  options_expenses_variable,
} from "../functions";
import { Drawer } from "@material-tailwind/react";
import {
  STYLE_ACCORDION_BODY,
  STYLE_ACCORDION_HEADER,
  STYLE_BUTTON_DELETE,
  STYLE_CARD_ACCORDION,
  STYLE_ICON_PLUS,
  STYLE_IMG_BALANCE,
  STYLE_INPUT,
  STYLE_INPUT_CARD_BALANCE,
  STYLE_INPUT_DISALE,
  STYLE_MAX_W_DRAWER,
  STYLE_PADDING_BALANCE_DRAWER,
  STYLE_PARENT_CARD_BALANCE,
  STYLE_TITLE_CARD_DRAWER_BL,
  STYLE_TITLE_DRAWER,
} from "../../../utils/useStyle";
import {
  ICON_DELETE,
  ICON_MINUS_CIRCLE,
  ICON_NAVIGATE,
  ICON_PLUS,
} from "../../../utils/useIcons";
import numeral from "numeral";
import DropDownMain from "../../../components/dropdown/DropDownMain";
import {
  ButtonCounter,
  DeleteButtonCard,
  MTButton,
} from "../../../components/button/MTButton";
import {
  CardAccordian,
  CardExpenses,
  CardExpensesInvest,
  CardMonthly,
  CardYearly,
} from "../styles/Card";
import { NumericFormat } from "react-number-format";
import { HeaderDrawer } from "../../../components/header/Header";
import { CardYearInput } from "../../../components/card/CardMain";
import { PopupMain } from "../../../components/popup/PopupMain";
import ListDebtMain from "./VIListDebt";
import { DateTime } from "luxon";
import NumbericValidateRed from "../../../components/DebtBalance/numericRed";

const numberWithoutComma = (value) => {
  let result = parseInt(value?.replace(/,/g, ""), 10);
  return result;
};

export const DrawerBalanceOutCome = ({
  open,
  onclose,
  formBL,
  setFormBL,
  setOpenDrawer,
  openAccordion,
  setOpenAccordion,
  addExpensesFixed,
  setAddExpensesFixed,
  addExpensesVariable,
  setAddExpensesVariable,
  addExpensesInvest,
  setAddExpensesInvest,
  addInvest,
  setAddInvest,
}) => {
  const setBalanceData = useBalanceStore((state) => state.setBalanceData);
  const balanceData = useBalanceStore((state) => state.balanceData);

  const [openETC, setOpenETC] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [openAlert2, setOpenAlert2] = useState(false);

  const [dataIndexDeleteOne, setDataIndexDeleteOne] = useState(null);
  const [dataIndexDeleteAll, setDataIndexDeleteAll] = useState(null);

  const handleOpen = (value) => {
    setOpenAccordion(open === value ? 0 : value);
  };

  //mark
  //================================================== ค่าใช้จ่ายคงที่ ==================================================//
  //---------------------------------------- Select ค่าใช่จ่ายคงที่ Dropdown ----------------------------------------//
  const onChangeSelectedFixed = (created, index, e) => {
    setAddExpensesFixed((prev) => {
      let value = e.value;
      const result = [...prev];

      //---- ค่าเบี้ยประกันชีวิตรายปี ----//
      if (value === "expenses_insuarant_life") {
        result[index].options_expenses_fixed = value;
        result[index].expenses_insuarant_lifes = [
          {
            created: created,
            title: "",
            insurance_policy_type: "",
            insurance_policy_number: "",
            insurance_policy_name: "",
            insurance_policy_date: "",
            date_insurance_policy_makecompact: "",
            date_insurance_policy_finishcompact: "",
            insurance_policy_status: "",
            allowance_peryear: "",
            insurance_fund: "",
            insurance_policy_cash_surrender: "",
            more_details: {
              life_insurance: "",
              life_allowance: "",
              except_allowance_wp: "",
              except_allowance_pb: "",
              contract_tpd: "",
              protection_life_lt: "",
              except_allowance_lt: "",
              accident_ai: "",
              accident_adb: "",
              accident_add: "",
              hospital_treatment_surgery: "",
              hospital_treatment_surgery_lump_sum_payment: "",
              daily_compensation_for_treatment: "",
              daily_compensation_for_treatment_special: "",
              compensation_for_cancer_treatment_cr: "",
              compensation_ecir: "",
              compensation_aia_supercare: "",
              compensation_aia_ci_plus_gold: "",
              compensation_aia_ci_plus: "",
              compensation_mutiple_care_ci: "",
              critical_illness_insurance_yearly_ci: "",
              medical_expenses_accident_pa: "",
              allowance_last_time: "",
              date_pay_allowance_last_time: "",
              date_due_allowance_next_time: "",
              date_due_allowance_final_time: "",
              beneficiary_name_one: "",
              beneficiary_relationship_one: "",
              beneficiary_name_two: "",
              beneficiary_relationship_two: "",
              annotation: "",
            },
          },
        ];
        delete result[index].expenses_debts;
        delete result[index].expenses_insuarant_accidents;
        delete result[index].expenses_insuarant_fires;
        delete result[index].expenses_insuarant_villages;
        delete result[index].expenses_insuarant_socials;
        delete result[index].expenses_provide_funds;
        delete result[index].expenses_fixed_others;
        delete result[index].expenses_insuarant_homes;
        delete result[index].expenses_insuarant_cars;
      }

      //---- ค่าเบี้ยประกันอุบัติเหตุรายปี ----//
      if (value === "expenses_insuarant_accident") {
        result[index].options_expenses_fixed = value;
        result[index].expenses_insuarant_accidents = [
          {
            created: created,
            title: "",
            insurance_policy_type: "",
            insurance_policy_number: "",
            insurance_policy_name: "",
            date_insurance_policy_makecompact: "",
            date_insurance_policy_finishcompact: "",
            insurance_fund: "",
            allowance_peryear: "",
            beneficiary_name_one: "",
            beneficiary_relationship_one: "",
            beneficiary_name_two: "",
            beneficiary_relationship_two: "",
          },
        ];
        delete result[index].expenses_debts;
        delete result[index].expenses_insuarant_lifes;
        delete result[index].expenses_insuarant_fires;
        delete result[index].expenses_insuarant_villages;
        delete result[index].expenses_insuarant_socials;
        delete result[index].expenses_provide_funds;
        delete result[index].expenses_fixed_others;
        delete result[index].expenses_insuarant_homes;
        delete result[index].expenses_insuarant_cars;
      }

      //---- ค่าเบี้ยประกันรถยนต์รายปี ----//
      if (value === "expenses_insuarant_car") {
        result[index].options_expenses_fixed = value;
        result[index].expenses_insuarant_cars = [
          {
            created: created,
            title: "",
            registration_car: "",
            insurance_policy_type: "",
            insurance_policy_number: "",
            insurance_policy_name: "",
            date_insurance_policy_makecompact: "",
            date_insurance_policy_finishcompact: "",
            insurance_fund: "",
            allowance_peryear: "",
            beneficiary_name_one: "",
            beneficiary_relationship_one: "",
            beneficiary_name_two: "",
            beneficiary_relationship_two: "",
          },
        ];
        delete result[index].expenses_debts;
        delete result[index].expenses_insuarant_lifes;
        delete result[index].expenses_insuarant_accidents;
        delete result[index].expenses_insuarant_fires;
        delete result[index].expenses_insuarant_villages;
        delete result[index].expenses_insuarant_socials;
        delete result[index].expenses_provide_funds;
        delete result[index].expenses_fixed_others;
        delete result[index].expenses_insuarant_homes;
      }

      //---- ค่าเบี้ยประกันอัคคีภัยรายปี ----//
      if (value === "expenses_insuarant_fire") {
        result[index].options_expenses_fixed = value;
        result[index].expenses_insuarant_fires = [
          {
            created: created,
            title: "",
            insurance_policy_type: "",
            insurance_policy_number: "",
            insurance_policy_name: "",
            date_insurance_policy_makecompact: "",
            date_insurance_policy_finishcompact: "",
            insurance_fund: "",
            allowance_peryear: "",
            beneficiary_name_one: "",
            beneficiary_relationship_one: "",
            beneficiary_name_two: "",
            beneficiary_relationship_two: "",
          },
        ];
        delete result[index].expenses_debts;
        delete result[index].expenses_insuarant_lifes;
        delete result[index].expenses_insuarant_accidents;
        delete result[index].expenses_insuarant_villages;
        delete result[index].expenses_insuarant_socials;
        delete result[index].expenses_provide_funds;
        delete result[index].expenses_fixed_others;
        delete result[index].expenses_insuarant_homes;
        delete result[index].expenses_insuarant_cars;
      }

      //---- ค่าเบี้ยประกันบ้านรายปี ----//
      if (value === "expenses_insuarant_home") {
        result[index].options_expenses_fixed = value;
        result[index].expenses_insuarant_homes = [
          {
            created: created,
            title: "",
            insurance_policy_type: "",
            insurance_policy_number: "",
            insurance_policy_name: "",
            date_insurance_policy_makecompact: "",
            date_insurance_policy_finishcompact: "",
            insurance_fund: "",
            allowance_peryear: "",
            beneficiary_name_one: "",
            beneficiary_relationship_one: "",
            beneficiary_name_two: "",
            beneficiary_relationship_two: "",
          },
        ];
        delete result[index].expenses_debts;
        delete result[index].expenses_insuarant_lifes;
        delete result[index].expenses_insuarant_accidents;
        delete result[index].expenses_insuarant_fires;
        delete result[index].expenses_insuarant_villages;
        delete result[index].expenses_insuarant_socials;
        delete result[index].expenses_provide_funds;
        delete result[index].expenses_fixed_others;
        delete result[index].expenses_insuarant_cars;
      }

      //---- ค่าส่วนกลางหมู่บ้านต่อเดือน (บาท/เดือน) ----//
      if (value === "expenses_insuarant_village") {
        result[index].options_expenses_fixed = value;
        result[index].expenses_insuarant_villages = [
          {
            created: created,
            title: "",
            pay_permonth: "",
            pay_peryear: "",
          },
        ];
        delete result[index].expenses_debts;
        delete result[index].expenses_insuarant_lifes;
        delete result[index].expenses_insuarant_accidents;
        delete result[index].expenses_insuarant_fires;
        delete result[index].expenses_insuarant_socials;
        delete result[index].expenses_provide_funds;
        delete result[index].expenses_fixed_others;
        delete result[index].expenses_insuarant_homes;
        delete result[index].expenses_insuarant_cars;
      }

      //---- ประกันสังคมต่อเดือน (บาท /เดือน) ----//
      if (value === "expenses_insuarant_social") {
        result[index].options_expenses_fixed = value;
        result[index].expenses_insuarant_socials = [
          {
            created: created,
            pay_permonth: "",
            pay_peryear: "",
          },
        ];
        delete result[index].expenses_debts;
        delete result[index].expenses_insuarant_lifes;
        delete result[index].expenses_insuarant_accidents;
        delete result[index].expenses_insuarant_fires;
        delete result[index].expenses_insuarant_villages;
        delete result[index].expenses_provide_funds;
        delete result[index].expenses_fixed_others;
        delete result[index].expenses_insuarant_homes;
        delete result[index].expenses_insuarant_cars;
      }

      //---- เงินสะสมกองทุนสำรองเลี้ยงชีพต่อปี ----//
      if (value === "expenses_provide_fund") {
        result[index].options_expenses_fixed = value;
        result[index].expenses_provide_funds = [
          {
            created: created,
            pay_peryear: "",
          },
        ];
        delete result[index].expenses_debts;
        delete result[index].expenses_insuarant_lifes;
        delete result[index].expenses_insuarant_accidents;
        delete result[index].expenses_insuarant_fires;
        delete result[index].expenses_insuarant_villages;
        delete result[index].expenses_insuarant_socials;
        delete result[index].expenses_fixed_others;
        delete result[index].expenses_insuarant_homes;
        delete result[index].expenses_insuarant_cars;
      }

      //---- ค่าใช้จ่ายคงที่อื่น ๆ ----//
      if (value === "expenses_fixed_other") {
        result[index].options_expenses_fixed = value;
        result[index].expenses_fixed_others = [
          {
            created: created,
            title: "",
            pay_permonth: "",
            pay_peryear: "",
          },
        ];
        delete result[index].expenses_debts;
        delete result[index].expenses_insuarant_lifes;
        delete result[index].expenses_insuarant_accidents;
        delete result[index].expenses_insuarant_fires;
        delete result[index].expenses_insuarant_villages;
        delete result[index].expenses_insuarant_socials;
        delete result[index].expenses_provide_funds;
        delete result[index].expenses_insuarant_homes;
        delete result[index].expenses_insuarant_cars;
      }

      return result;
    });
  };

  //------------------------------ ค่าส่วนกลางหมู่บ้านต่อเดือน (บาท/เดือน) ------------------------------//
  const handleNextExpensesInsuarantVillage = (index, e) => {
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      const date = new Date();
      const created = date.toISOString();
      const newList = {
        created: created,
        title: "",
        pay_permonth: "",
        pay_peryear: "",
      };
      result[index] = {
        ...result[index],
        expenses_insuarant_villages: [
          ...result[index].expenses_insuarant_villages,
          { ...newList },
        ],
      };
      return result;
    });
  };
  const onClickDeleteCardExpensesInsuarantVillage = (index, indexCard, e) => {
    setAddExpensesFixed((prev) => {
      const result = [...prev];
      const updatedExpensesInsuarantVillage = [
        ...result[index].expenses_insuarant_villages,
      ];
      updatedExpensesInsuarantVillage.splice(indexCard, 1);
      result[index] = {
        ...result[index],
        expenses_insuarant_villages: updatedExpensesInsuarantVillage,
      };
      return result;
    });
  };
  const onChangeTitleVillages = (index, indexCard, e) => {
    const value = e.target.value;
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      result[index].expenses_insuarant_villages[indexCard].title = value;
      return result;
    });
  };
  const onChangePayPerMonthVillage = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].expenses_insuarant_villages[indexCard].pay_permonth = "";
        result[index].expenses_insuarant_villages[indexCard].pay_peryear = "";
      } else {
        result[index].expenses_insuarant_villages[indexCard].pay_permonth =
          value;
        result[index].expenses_insuarant_villages[indexCard].pay_peryear =
          value * 12;
      }
      return result;
    });
  };

  //------------------------------ ค่าเบี้ยประกันชีวิตรายปี ------------------------------//
  const handleNextExpensesInsuarantLife = (index, e) => {
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      const date = new Date();
      const created = date.toISOString();
      const newList = {
        created: created,
        title: "",
        insurance_policy_type: "",
        insurance_policy_number: "",
        insurance_policy_name: "",
        insurance_policy_date: "",
        date_insurance_policy_makecompact: "",
        date_insurance_policy_finishcompact: "",
        insurance_policy_status: "",
        allowance_peryear: "",
        insurance_fund: "",
        insurance_policy_cash_surrender: "",
        more_details: {
          life_insurance: "",
          life_allowance: "",
          except_allowance_wp: "",
          except_allowance_pb: "",
          contract_tpd: "",
          protection_life_lt: "",
          except_allowance_lt: "",
          accident_ai: "",
          accident_adb: "",
          accident_add: "",
          hospital_treatment_surgery: "",
          hospital_treatment_surgery_lump_sum_payment: "",
          daily_compensation_for_treatment: "",
          daily_compensation_for_treatment_special: "",
          compensation_for_cancer_treatment_cr: "",
          compensation_ecir: "",
          compensation_aia_supercare: "",
          compensation_aia_ci_plus_gold: "",
          compensation_aia_ci_plus: "",
          compensation_mutiple_care_ci: "",
          critical_illness_insurance_yearly_ci: "",
          medical_expenses_accident_pa: "",
          allowance_last_time: "",
          date_pay_allowance_last_time: "",
          date_due_allowance_next_time: "",
          date_due_allowance_final_time: "",
          beneficiary_name_one: "",
          beneficiary_relationship_one: "",
          beneficiary_name_two: "",
          beneficiary_relationship_two: "",
          annotation: "",
        },
      };
      result[index] = {
        ...result[index],
        expenses_insuarant_lifes: [
          ...result[index].expenses_insuarant_lifes,
          { ...newList },
        ],
      };
      return result;
    });
  };

  // -------------- ค่าเบี้ยประกันชีวิตรายปี --------------

  const onClickDeleteCardExpensesInsuarantLife = (index, indexCard, e) => {
    setDataIndexDeleteOne({
      index,
      indexCard,
    });
    setOpenAlert(true);
  };

  const onClickConfirmDeleteCardExpensesInsuarantLife = () => {
    setAddExpensesFixed((prev) => {
      const result = [...prev];
      const index = dataIndexDeleteOne?.index;
      const indexCard = dataIndexDeleteOne?.indexCard;
      const updatedExpensesInsuarantLife = [
        ...result[index]?.expenses_insuarant_lifes,
      ];
      updatedExpensesInsuarantLife.splice(indexCard, 1);
      result[index] = {
        ...result[index],
        expenses_insuarant_lifes: updatedExpensesInsuarantLife,
      };
      return result;
    });
    setDataIndexDeleteOne(null);
    setOpenAlert(false);
  };

  const onChangeTitleLife = (index, indexCard, e) => {
    const value = e.target.value;
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      result[index].expenses_insuarant_lifes[indexCard].title = value;
      return result;
    });
  };
  const onChangeInsurancePolicyTypeLife = (index, indexCard, e) => {
    const value = e.target.value;
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      result[index].expenses_insuarant_lifes[indexCard].insurance_policy_type =
        value;
      return result;
    });
  };
  const onChangeInsurancePolicyNumberLife = (index, indexCard, e) => {
    const value = e.target.value;
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      result[index].expenses_insuarant_lifes[
        indexCard
      ].insurance_policy_number = value;
      return result;
    });
  };
  const onChangeInsurancePolicyNameLife = (index, indexCard, e) => {
    const value = e.target.value;
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      result[index].expenses_insuarant_lifes[indexCard].insurance_policy_name =
        value;
      return result;
    });
  };
  const onChangeInsurancePolicyDateLife = (index, indexCard, date) => {
    let value = date;
    const dateFormated = DateTime.fromJSDate(new Date(value)).toFormat(
      "yyyy-MM-dd"
    );
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      result[index].expenses_insuarant_lifes[indexCard].insurance_policy_date =
        dateFormated;
      return result;
    });
  };
  const onChangeInsurancePolicyDateMakecompactLife = (
    index,
    indexCard,
    date
  ) => {
    let value = date;
    const dateFormated = DateTime.fromJSDate(new Date(value)).toFormat(
      "yyyy-MM-dd"
    );
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      result[index].expenses_insuarant_lifes[
        indexCard
      ].date_insurance_policy_makecompact = dateFormated;
      return result;
    });
  };
  const onChangeInsurancePolicyDateFinishcompactLife = (
    index,
    indexCard,
    date
  ) => {
    let value = date;
    const dateFormated = DateTime.fromJSDate(new Date(value)).toFormat(
      "yyyy-MM-dd"
    );
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      result[index].expenses_insuarant_lifes[
        indexCard
      ].date_insurance_policy_finishcompact = dateFormated;
      return result;
    });
  };
  const onChangeDropdownLife = (index, indexCard, e) => {
    const value = e.value;
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      result[index].expenses_insuarant_lifes[
        indexCard
      ].insurance_policy_status = value;
      return result;
    });
  };
  const onChangeAllowancePeryearLife = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].expenses_insuarant_lifes[indexCard].allowance_peryear =
          "";
      } else {
        result[index].expenses_insuarant_lifes[indexCard].allowance_peryear =
          value;
      }
      return result;
    });
  };
  const onChangeInsuranceFundLife = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].expenses_insuarant_lifes[indexCard].insurance_fund = "";
      } else {
        result[index].expenses_insuarant_lifes[indexCard].insurance_fund =
          value;
      }
      return result;
    });
  };
  const onChangeInsurancePolicyCashSurrenderLife = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].expenses_insuarant_lifes[
          indexCard
        ].insurance_policy_cash_surrender = "";
      } else {
        result[index].expenses_insuarant_lifes[
          indexCard
        ].insurance_policy_cash_surrender = value;
      }
      return result;
    });
  };
  //---------- รายระเอียดเพิ่มเติม ---------//
  const onChangeAIAMutiplayCIMore = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].expenses_insuarant_lifes[
          indexCard
        ].more_details.aia_mutiplay_ci_more = "";
      } else {
        result[index].expenses_insuarant_lifes[
          indexCard
        ].more_details.aia_mutiplay_ci_more = value;
      }
      return result;
    });
  };
  const onChangeAIATotalCareMore = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].expenses_insuarant_lifes[
          indexCard
        ].more_details.aia_total_care_more = "";
      } else {
        result[index].expenses_insuarant_lifes[
          indexCard
        ].more_details.aia_total_care_more = value;
      }
      return result;
    });
  };
  const onChangeTypeOfPayment = (index, indexCard, e) => {
    const value = e.value;
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      result[index].expenses_insuarant_lifes[
        indexCard
      ].more_details.type_of_payment = value;
      return result;
    });
  };
  const onChangeStatusAIAVitlaity = (index, indexCard, e) => {
    const value = e.value;
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      result[index].expenses_insuarant_lifes[
        indexCard
      ].more_details.status_aia_vitlaity = value;
      return result;
    });
  };

  const onChangePolicySurrenderValue = (index, indexCard, e) => {
    const value = e.target.value;
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      result[index].expenses_insuarant_lifes[
        indexCard
      ].more_details.policy_surrender_value = +value;
      return result;
    });
  };

  const onChangeContractualRefund = (index, indexCard, e) => {
    const value = e.value;
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      result[index].expenses_insuarant_lifes[
        indexCard
      ].more_details.contractual_refund = value;
      return result;
    });
  };
  const onChangeTotalYear = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].expenses_insuarant_lifes[
          indexCard
        ].more_details.total_year = "";
      } else {
        result[index].expenses_insuarant_lifes[
          indexCard
        ].more_details.total_year = value;
      }
      return result;
    });
  };
  const onChangeLifeInsuranceLife = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].expenses_insuarant_lifes[
          indexCard
        ].more_details.life_insurance = "";
      } else {
        result[index].expenses_insuarant_lifes[
          indexCard
        ].more_details.life_insurance = value;
      }
      return result;
    });
  };
  const onChangeLifeAllowanceLife = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].expenses_insuarant_lifes[
          indexCard
        ].more_details.life_allowance = "";
      } else {
        result[index].expenses_insuarant_lifes[
          indexCard
        ].more_details.life_allowance = value;
      }
      return result;
    });
  };
  const onChangeExceptAllowanceWPLife = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].expenses_insuarant_lifes[
          indexCard
        ].more_details.except_allowance_wp = "";
      } else {
        result[index].expenses_insuarant_lifes[
          indexCard
        ].more_details.except_allowance_wp = value;
      }
      return result;
    });
  };
  const onChangeExceptAllowancePBLife = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].expenses_insuarant_lifes[
          indexCard
        ].more_details.except_allowance_pb = "";
      } else {
        result[index].expenses_insuarant_lifes[
          indexCard
        ].more_details.except_allowance_pb = value;
      }
      return result;
    });
  };
  const onChangeContractTPDLife = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].expenses_insuarant_lifes[
          indexCard
        ].more_details.contract_tpd = "";
      } else {
        result[index].expenses_insuarant_lifes[
          indexCard
        ].more_details.contract_tpd = value;
      }
      return result;
    });
  };
  const onChangeProtectionLifeLTLife = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].expenses_insuarant_lifes[
          indexCard
        ].more_details.protection_life_lt = "";
      } else {
        result[index].expenses_insuarant_lifes[
          indexCard
        ].more_details.protection_life_lt = value;
      }
      return result;
    });
  };
  const onChangeExceptAllowanceLTLife = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].expenses_insuarant_lifes[
          indexCard
        ].more_details.except_allowance_lt = "";
      } else {
        result[index].expenses_insuarant_lifes[
          indexCard
        ].more_details.except_allowance_lt = value;
      }
      return result;
    });
  };
  const onChangeAccidentAILife = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].expenses_insuarant_lifes[
          indexCard
        ].more_details.accident_ai = "";
      } else {
        result[index].expenses_insuarant_lifes[
          indexCard
        ].more_details.accident_ai = value;
      }
      return result;
    });
  };
  const onChangeAccidentADBLife = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].expenses_insuarant_lifes[
          indexCard
        ].more_details.accident_adb = "";
      } else {
        result[index].expenses_insuarant_lifes[
          indexCard
        ].more_details.accident_adb = value;
      }
      return result;
    });
  };
  const onChangeAccidentADDLife = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].expenses_insuarant_lifes[
          indexCard
        ].more_details.accident_add = "";
      } else {
        result[index].expenses_insuarant_lifes[
          indexCard
        ].more_details.accident_add = value;
      }
      return result;
    });
  };
  const onChangeHospitalTreatmentSurgeryLife = (index, indexCard, e) => {
    const value = e.target.value;
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      result[index].expenses_insuarant_lifes[
        indexCard
      ].more_details.hospital_treatment_surgery = value;
      return result;
    });
  };
  const onChangeHospitalTreatmentSurgeryLumpSumPaymentLife = (
    index,
    indexCard,
    e
  ) => {
    const value = e.target.value;
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      result[index].expenses_insuarant_lifes[
        indexCard
      ].more_details.hospital_treatment_surgery_lump_sum_payment = value;
      return result;
    });
  };
  const onChangeDailyCompensationForTreatmentLife = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].expenses_insuarant_lifes[
          indexCard
        ].more_details.daily_compensation_for_treatment = "";
      } else {
        result[index].expenses_insuarant_lifes[
          indexCard
        ].more_details.daily_compensation_for_treatment = value;
      }
      return result;
    });
  };
  const onChangeDailyCompensationForTreatmentSpecialLife = (
    index,
    indexCard,
    e
  ) => {
    let value = numberWithoutComma(e.target.value);
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].expenses_insuarant_lifes[
          indexCard
        ].more_details.daily_compensation_for_treatment_special = "";
      } else {
        result[index].expenses_insuarant_lifes[
          indexCard
        ].more_details.daily_compensation_for_treatment_special = value;
      }
      return result;
    });
  };
  const onChangeCompensationForCancerTreatmentCRLife = (
    index,
    indexCard,
    e
  ) => {
    let value = numberWithoutComma(e.target.value);
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].expenses_insuarant_lifes[
          indexCard
        ].more_details.compensation_for_cancer_treatment_cr = "";
      } else {
        result[index].expenses_insuarant_lifes[
          indexCard
        ].more_details.compensation_for_cancer_treatment_cr = value;
      }
      return result;
    });
  };
  const onChangeCompensationECIRLife = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].expenses_insuarant_lifes[
          indexCard
        ].more_details.compensation_ecir = "";
      } else {
        result[index].expenses_insuarant_lifes[
          indexCard
        ].more_details.compensation_ecir = value;
      }
      return result;
    });
  };
  const onChangeCompensationAIASupercareLife = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].expenses_insuarant_lifes[
          indexCard
        ].more_details.compensation_aia_supercare = "";
      } else {
        result[index].expenses_insuarant_lifes[
          indexCard
        ].more_details.compensation_aia_supercare = value;
      }
      return result;
    });
  };
  const onChangeCompensationAIACIPlusGoldLife = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].expenses_insuarant_lifes[
          indexCard
        ].more_details.compensation_aia_ci_plus_gold = "";
      } else {
        result[index].expenses_insuarant_lifes[
          indexCard
        ].more_details.compensation_aia_ci_plus_gold = value;
      }
      return result;
    });
  };
  const onChangeCompensationAIACIPlusLife = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].expenses_insuarant_lifes[
          indexCard
        ].more_details.compensation_aia_ci_plus = "";
      } else {
        result[index].expenses_insuarant_lifes[
          indexCard
        ].more_details.compensation_aia_ci_plus = value;
      }
      return result;
    });
  };
  const onChangeCompensationMutipleCareCiLife = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].expenses_insuarant_lifes[
          indexCard
        ].more_details.compensation_mutiple_care_ci = "";
      } else {
        result[index].expenses_insuarant_lifes[
          indexCard
        ].more_details.compensation_mutiple_care_ci = value;
      }
      return result;
    });
  };
  const onChangeCriticalIllnessInsuranceYearlyCiLife = (
    index,
    indexCard,
    e
  ) => {
    let value = numberWithoutComma(e.target.value);
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].expenses_insuarant_lifes[
          indexCard
        ].more_details.critical_illness_insurance_yearly_ci = "";
      } else {
        result[index].expenses_insuarant_lifes[
          indexCard
        ].more_details.critical_illness_insurance_yearly_ci = value;
      }
      return result;
    });
  };
  const onChangeMedicalExpensesAccidentPALife = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].expenses_insuarant_lifes[
          indexCard
        ].more_details.medical_expenses_accident_pa = "";
      } else {
        result[index].expenses_insuarant_lifes[
          indexCard
        ].more_details.medical_expenses_accident_pa = value;
      }
      return result;
    });
  };
  const onChangeAllowanceLastTimeLife = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].expenses_insuarant_lifes[
          indexCard
        ].more_details.allowance_last_time = "";
      } else {
        result[index].expenses_insuarant_lifes[
          indexCard
        ].more_details.allowance_last_time = value;
      }
      return result;
    });
  };
  const onChangeDatePayAllowanceLastTimeLife = (index, indexCard, e) => {
    const value = e.target.value;
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      result[index].expenses_insuarant_lifes[
        indexCard
      ].more_details.date_pay_allowance_last_time = value;
      return result;
    });
  };
  const onChangeDateDueAllowanceNextTimeLife = (index, indexCard, e) => {
    const value = e.target.value;
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      result[index].expenses_insuarant_lifes[
        indexCard
      ].more_details.date_due_allowance_next_time = value;
      return result;
    });
  };
  const onChangeDateDueAllowanceFinalTimeLife = (index, indexCard, e) => {
    const value = e.target.value;
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      result[index].expenses_insuarant_lifes[
        indexCard
      ].more_details.date_due_allowance_final_time = value;
      return result;
    });
  };
  //---- ผู้รับผลประโยชน์ ----//
  const onChangeBeneficiaryNameOneLife = (index, indexCard, e) => {
    const value = e.target.value;
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      result[index].expenses_insuarant_lifes[
        indexCard
      ].more_details.beneficiary_name_one = value;
      return result;
    });
  };
  const onChangeBeneficiaryNameTwoLife = (index, indexCard, e) => {
    const value = e.target.value;
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      result[index].expenses_insuarant_lifes[
        indexCard
      ].more_details.beneficiary_name_two = value;
      return result;
    });
  };
  const onChangeBeneficiaryRelationshipOneLife = (index, indexCard, e) => {
    const value = e.target.value;
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      result[index].expenses_insuarant_lifes[
        indexCard
      ].more_details.beneficiary_relationship_one = value;
      return result;
    });
  };
  const onChangeBeneficiaryRelationshipTwoLife = (index, indexCard, e) => {
    const value = e.target.value;
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      result[index].expenses_insuarant_lifes[
        indexCard
      ].more_details.beneficiary_relationship_two = value;
      return result;
    });
  };
  const onChangeAnnotationLife = (index, indexCard, e) => {
    const value = e.target.value;
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      result[index].expenses_insuarant_lifes[
        indexCard
      ].more_details.annotation = value;
      return result;
    });
  };

  //------------------------------ ค่าเบี้ยประกันบ้านรายปี ------------------------------//
  const handleNextExpensesInsuarantHomes = (index, e) => {
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      const date = new Date();
      const created = date.toISOString();
      const newList = {
        created: created,
        title: "",
        insurance_policy_type: "",
        insurance_policy_number: "",
        insurance_policy_name: "",
        date_insurance_policy_makecompact: "",
        date_insurance_policy_finishcompact: "",
        insurance_fund: "",
        allowance_peryear: "",
        beneficiary_name_one: "",
        beneficiary_relationship_one: "",
        beneficiary_name_two: "",
        beneficiary_relationship_two: "",
      };
      result[index] = {
        ...result[index],
        expenses_insuarant_homes: [
          ...result[index].expenses_insuarant_homes,
          { ...newList },
        ],
      };
      return result;
    });
  };

  const onClickDeleteCardExpensesInsuarantHome = (index, indexCard, e) => {
    setDataIndexDeleteOne({
      index,
      indexCard,
    });
    setOpenAlert(true);
  };

  const onClickConfirmDeleteCardExpensesInsuarantHome = () => {
    setAddExpensesFixed((prev) => {
      const result = [...prev];
      const index = dataIndexDeleteOne?.index;
      const indexCard = dataIndexDeleteOne?.indexCard;
      const updatedExpensesInsuarantHome = [
        ...result[index].expenses_insuarant_homes,
      ];
      updatedExpensesInsuarantHome.splice(indexCard, 1);
      result[index] = {
        ...result[index],
        expenses_insuarant_homes: updatedExpensesInsuarantHome,
      };
      return result;
    });

    setDataIndexDeleteOne(null);
    setOpenAlert(false);
  };

  const onChangeTitleHome = (index, indexCard, e) => {
    const value = e.target.value;
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      result[index].expenses_insuarant_homes[indexCard].title = value;
      return result;
    });
  };
  const onChangeInsurancePolicyTypeHome = (index, indexCard, e) => {
    const value = e.value;
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      result[index].expenses_insuarant_homes[indexCard].insurance_policy_type =
        value;
      return result;
    });
  };
  const onChangeInsurancePolicyNumberHome = (index, indexCard, e) => {
    const value = e.target.value;
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      result[index].expenses_insuarant_homes[
        indexCard
      ].insurance_policy_number = value;
      return result;
    });
  };
  const onChangeInsurancePolicyNameHome = (index, indexCard, e) => {
    const value = e.target.value;
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      result[index].expenses_insuarant_homes[indexCard].insurance_policy_name =
        value;
      return result;
    });
  };
  const onChangeInsurancePolicyDateMakecompactHome = (index, indexCard, e) => {
    const value = e.target.value;
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      result[index].expenses_insuarant_homes[
        indexCard
      ].date_insurance_policy_makecompact = value;
      return result;
    });
  };
  const onChangeInsurancePolicyDateFinishcompactHome = (
    index,
    indexCard,
    e
  ) => {
    const value = e.target.value;
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      result[index].expenses_insuarant_homes[
        indexCard
      ].date_insurance_policy_finishcompact = value;
      return result;
    });
  };
  const onChangeInsuranceFundHome = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].expenses_insuarant_homes[indexCard].insurance_fund = "";
      } else {
        result[index].expenses_insuarant_homes[indexCard].insurance_fund =
          value;
      }
      return result;
    });
  };
  const onChangeAllowancePeryearHome = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].expenses_insuarant_homes[indexCard].allowance_peryear =
          "";
      } else {
        result[index].expenses_insuarant_homes[indexCard].allowance_peryear =
          value;
      }
      return result;
    });
  };
  const onChangeBeneficiaryNameOneHome = (index, indexCard, e) => {
    const value = e.target.value;
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      result[index].expenses_insuarant_homes[indexCard].beneficiary_name_one =
        value;
      return result;
    });
  };
  const onChangeBeneficiaryNameTwoHome = (index, indexCard, e) => {
    const value = e.target.value;
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      result[index].expenses_insuarant_homes[indexCard].beneficiary_name_two =
        value;
      return result;
    });
  };
  const onChangeBeneficiaryRelationshipOneHome = (index, indexCard, e) => {
    const value = e.target.value;
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      result[index].expenses_insuarant_homes[
        indexCard
      ].beneficiary_relationship_one = value;
      return result;
    });
  };
  const onChangeBeneficiaryRelationshipTwoHome = (index, indexCard, e) => {
    const value = e.target.value;
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      result[index].expenses_insuarant_homes[
        indexCard
      ].beneficiary_relationship_two = value;
      return result;
    });
  };

  //------------------------------ ค่าเบี้ยประกันรถยนต์รายปี ------------------------------//
  const handleNextExpensesInsuarantCars = (index, e) => {
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      const date = new Date();
      const created = date.toISOString();
      const newList = {
        created: created,
        title: "",
        registration_car: "",
        insurance_policy_type: "",
        insurance_policy_number: "",
        insurance_policy_name: "",
        date_insurance_policy_makecompact: "",
        date_insurance_policy_finishcompact: "",
        insurance_fund: "",
        allowance_peryear: "",
        beneficiary_name_one: "",
        beneficiary_relationship_one: "",
        beneficiary_name_two: "",
        beneficiary_relationship_two: "",
      };
      result[index] = {
        ...result[index],
        expenses_insuarant_cars: [
          ...result[index].expenses_insuarant_cars,
          { ...newList },
        ],
      };
      return result;
    });
  };

  const onClickDeleteCardExpensesInsuarantCar = (index, indexCard, e) => {
    setDataIndexDeleteOne({
      index,
      indexCard,
    });
    setOpenAlert(true);
  };

  const onClickConfirmDeleteCardExpensesInsuarantCar = () => {
    setAddExpensesFixed((prev) => {
      const result = [...prev];
      const index = dataIndexDeleteOne?.index;
      const indexCard = dataIndexDeleteOne?.indexCard;
      const updatedExpensesInsuarantCar = [
        ...result[index].expenses_insuarant_cars,
      ];
      updatedExpensesInsuarantCar.splice(indexCard, 1);
      result[index] = {
        ...result[index],
        expenses_insuarant_cars: updatedExpensesInsuarantCar,
      };
      return result;
    });
    setDataIndexDeleteOne(null);
    setOpenAlert(false);
  };
  const onChangeTitleCar = (index, indexCard, e) => {
    const value = e.target.value;
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      result[index].expenses_insuarant_cars[indexCard].title = value;
      return result;
    });
  };

  const onChangeRegistrationCar = (index, indexCard, e) => {
    const value = e.target.value;
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      result[index].expenses_insuarant_cars[indexCard].registration_car = value;
      return result;
    });
  };
  const onChangeInsurancePolicyTypeCar = (index, indexCard, e) => {
    const value = e.value;
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      result[index].expenses_insuarant_cars[indexCard].insurance_policy_type =
        value;
      return result;
    });
  };
  const onChangeInsurancePolicyNumberCar = (index, indexCard, e) => {
    const value = e.target.value;
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      result[index].expenses_insuarant_cars[indexCard].insurance_policy_number =
        value;
      return result;
    });
  };
  const onChangeInsurancePolicyNameCar = (index, indexCard, e) => {
    const value = e.target.value;
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      result[index].expenses_insuarant_cars[indexCard].insurance_policy_name =
        value;
      return result;
    });
  };
  const onChangeInsurancePolicyDateMakecompactCar = (index, indexCard, e) => {
    const value = e.target.value;
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      result[index].expenses_insuarant_cars[
        indexCard
      ].date_insurance_policy_makecompact = value;
      return result;
    });
  };
  const onChangeInsurancePolicyDateFinishcompactCar = (index, indexCard, e) => {
    const value = e.target.value;
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      result[index].expenses_insuarant_cars[
        indexCard
      ].date_insurance_policy_finishcompact = value;
      return result;
    });
  };
  const onChangeInsuranceFundCar = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].expenses_insuarant_cars[indexCard].insurance_fund = "";
      } else {
        result[index].expenses_insuarant_cars[indexCard].insurance_fund = value;
      }
      return result;
    });
  };
  const onChangeAllowancePeryearCar = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].expenses_insuarant_cars[indexCard].allowance_peryear = "";
      } else {
        result[index].expenses_insuarant_cars[indexCard].allowance_peryear =
          value;
      }
      return result;
    });
  };
  // ผู้ได้รับประโยชน์
  const onChangeBeneficiaryNameOneCar = (index, indexCard, e) => {
    const value = e.target.value;
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      result[index].expenses_insuarant_cars[indexCard].beneficiary_name_one =
        value;
      return result;
    });
  };
  const onChangeBeneficiaryNameTwoCar = (index, indexCard, e) => {
    const value = e.target.value;
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      result[index].expenses_insuarant_cars[indexCard].beneficiary_name_two =
        value;
      return result;
    });
  };
  const onChangeBeneficiaryRelationshipOneCar = (index, indexCard, e) => {
    const value = e.target.value;
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      result[index].expenses_insuarant_cars[
        indexCard
      ].beneficiary_relationship_one = value;
      return result;
    });
  };
  const onChangeBeneficiaryRelationshipTwoCar = (index, indexCard, e) => {
    const value = e.target.value;
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      result[index].expenses_insuarant_cars[
        indexCard
      ].beneficiary_relationship_two = value;
      return result;
    });
  };

  //------------------------------ ค่าเบี้ยประกันอุบัติเหตุรายปี ------------------------------//
  const handleNextExpensesInsuarantAccidents = (index, e) => {
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      const date = new Date();
      const created = date.toISOString();
      const newList = {
        created: created,
        title: "",
        insurance_policy_type: "",
        insurance_policy_number: "",
        insurance_policy_name: "",
        date_insurance_policy_makecompact: "",
        date_insurance_policy_finishcompact: "",
        insurance_fund: "",
        allowance_peryear: "",
        beneficiary_name_one: "",
        beneficiary_relationship_one: "",
        beneficiary_name_two: "",
        beneficiary_relationship_two: "",
      };
      result[index] = {
        ...result[index],
        expenses_insuarant_accidents: [
          ...result[index].expenses_insuarant_accidents,
          { ...newList },
        ],
      };
      return result;
    });
  };

  const onClickDeleteCardExpensesInsuarantAccident = (index, indexCard, e) => {
    setDataIndexDeleteOne({
      index,
      indexCard,
    });
    setOpenAlert(true);
  };

  const onClickConfirmDeleteCardExpensesInsuarantAccident = (
    index,
    indexCard,
    e
  ) => {
    setAddExpensesFixed((prev) => {
      const result = [...prev];
      const index = dataIndexDeleteOne?.index;
      const indexCard = dataIndexDeleteOne?.indexCard;
      const updatedExpensesInsuarantAccident = [
        ...result[index].expenses_insuarant_accidents,
      ];
      updatedExpensesInsuarantAccident.splice(indexCard, 1);
      result[index] = {
        ...result[index],
        expenses_insuarant_accidents: updatedExpensesInsuarantAccident,
      };
      return result;
    });
    setDataIndexDeleteOne(null);
    setOpenAlert(false);
  };

  const onChangeTitleAccident = (index, indexCard, e) => {
    const value = e.target.value;
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      result[index].expenses_insuarant_accidents[indexCard].title = value;
      return result;
    });
  };
  const onChangeInsurancePolicyTypeAccident = (index, indexCard, e) => {
    const value = e.target.value;
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      result[index].expenses_insuarant_accidents[
        indexCard
      ].insurance_policy_type = value;
      return result;
    });
  };
  const onChangeInsurancePolicyNumberAccident = (index, indexCard, e) => {
    const value = e.target.value;
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      result[index].expenses_insuarant_accidents[
        indexCard
      ].insurance_policy_number = value;
      return result;
    });
  };
  const onChangeInsurancePolicyNameAccident = (index, indexCard, e) => {
    const value = e.target.value;
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      result[index].expenses_insuarant_accidents[
        indexCard
      ].insurance_policy_name = value;
      return result;
    });
  };
  const onChangeInsurancePolicyDateMakecompactAccident = (
    index,
    indexCard,
    e
  ) => {
    const value = e.target.value;
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      result[index].expenses_insuarant_accidents[
        indexCard
      ].date_insurance_policy_makecompact = value;
      return result;
    });
  };
  const onChangeInsurancePolicyDateFinishcompactAccident = (
    index,
    indexCard,
    e
  ) => {
    const value = e.target.value;
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      result[index].expenses_insuarant_accidents[
        indexCard
      ].date_insurance_policy_finishcompact = value;
      return result;
    });
  };
  const onChangeInsuranceFundAccident = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].expenses_insuarant_accidents[indexCard].insurance_fund =
          "";
      } else {
        result[index].expenses_insuarant_accidents[indexCard].insurance_fund =
          value;
      }
      return result;
    });
  };
  const onChangeAllowancePeryearAccident = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].expenses_insuarant_accidents[
          indexCard
        ].allowance_peryear = "";
      } else {
        result[index].expenses_insuarant_accidents[
          indexCard
        ].allowance_peryear = value;
      }
      return result;
    });
  };
  // ผู้ได้รับประโยชน์
  const onChangeBeneficiaryNameOneAccident = (index, indexCard, e) => {
    const value = e.target.value;
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      result[index].expenses_insuarant_accidents[
        indexCard
      ].beneficiary_name_one = value;
      return result;
    });
  };
  const onChangeBeneficiaryNameTwoAccident = (index, indexCard, e) => {
    const value = e.target.value;
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      result[index].expenses_insuarant_accidents[
        indexCard
      ].beneficiary_name_two = value;
      return result;
    });
  };
  const onChangeBeneficiaryRelationshipOneAccident = (index, indexCard, e) => {
    const value = e.target.value;
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      result[index].expenses_insuarant_accidents[
        indexCard
      ].beneficiary_relationship_one = value;
      return result;
    });
  };
  const onChangeBeneficiaryRelationshipTwoAccident = (index, indexCard, e) => {
    const value = e.target.value;
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      result[index].expenses_insuarant_accidents[
        indexCard
      ].beneficiary_relationship_two = value;
      return result;
    });
  };

  //------------------------------ ค่าเบี้ยประกันอัคคีภัยรายปี ------------------------------//
  const handleNextExpensesInsuarantFires = (index, e) => {
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      const date = new Date();
      const created = date.toISOString();
      const newList = {
        created: created,
        title: "",
        insurance_policy_type: "",
        insurance_policy_number: "",
        insurance_policy_name: "",
        date_insurance_policy_makecompact: "",
        date_insurance_policy_finishcompact: "",
        insurance_fund: "",
        allowance_peryear: "",
        beneficiary_name_one: "",
        beneficiary_relationship_one: "",
        beneficiary_name_two: "",
        beneficiary_relationship_two: "",
      };
      result[index] = {
        ...result[index],
        expenses_insuarant_fires: [
          ...result[index].expenses_insuarant_fires,
          { ...newList },
        ],
      };
      return result;
    });
  };

  const onClickDeleteCardExpensesInsuarantFire = (index, indexCard, e) => {
    setDataIndexDeleteOne({
      index,
      indexCard,
    });
    setOpenAlert(true);
  };

  const onClickConfirmDeleteCardExpensesInsuarantFire = () => {
    setAddExpensesFixed((prev) => {
      const result = [...prev];
      const index = dataIndexDeleteOne?.index;
      const indexCard = dataIndexDeleteOne?.indexCard;
      const updatedExpensesInsuarantFire = [
        ...result[index].expenses_insuarant_fires,
      ];
      updatedExpensesInsuarantFire.splice(indexCard, 1);
      result[index] = {
        ...result[index],
        expenses_insuarant_fires: updatedExpensesInsuarantFire,
      };
      return result;
    });
    setDataIndexDeleteOne(null);
    setOpenAlert(false);
  };

  const onChangeTitleFire = (index, indexCard, e) => {
    const value = e.target.value;
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      result[index].expenses_insuarant_fires[indexCard].title = value;
      return result;
    });
  };

  const onChangeInsurancePolicyTypeFire = (index, indexCard, e) => {
    const value = e.target.value;
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      result[index].expenses_insuarant_fires[indexCard].insurance_policy_type =
        value;
      return result;
    });
  };
  const onChangeInsurancePolicyNumberFire = (index, indexCard, e) => {
    const value = e.target.value;
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      result[index].expenses_insuarant_fires[
        indexCard
      ].insurance_policy_number = value;
      return result;
    });
  };
  const onChangeInsurancePolicyNameFire = (index, indexCard, e) => {
    const value = e.target.value;
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      result[index].expenses_insuarant_fires[indexCard].insurance_policy_name =
        value;
      return result;
    });
  };
  const onChangeInsurancePolicyDateMakecompactFire = (index, indexCard, e) => {
    const value = e.target.value;
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      result[index].expenses_insuarant_fires[
        indexCard
      ].date_insurance_policy_makecompact = value;
      return result;
    });
  };
  const onChangeInsurancePolicyDateFinishcompactFire = (
    index,
    indexCard,
    e
  ) => {
    const value = e.target.value;
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      result[index].expenses_insuarant_fires[
        indexCard
      ].date_insurance_policy_finishcompact = value;
      return result;
    });
  };
  const onChangeInsuranceFundFire = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].expenses_insuarant_fires[indexCard].insurance_fund = "";
      } else {
        result[index].expenses_insuarant_fires[indexCard].insurance_fund =
          value;
      }
      return result;
    });
  };
  const onChangeAllowancePeryearFire = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].expenses_insuarant_fires[indexCard].allowance_peryear =
          "";
      } else {
        result[index].expenses_insuarant_fires[indexCard].allowance_peryear =
          value;
      }
      return result;
    });
  };
  // ผู้ได้รับประโยชน์
  const onChangeBeneficiaryNameOneFire = (index, indexCard, e) => {
    const value = e.target.value;
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      result[index].expenses_insuarant_fires[indexCard].beneficiary_name_one =
        value;
      return result;
    });
  };
  const onChangeBeneficiaryNameTwoFire = (index, indexCard, e) => {
    const value = e.target.value;
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      result[index].expenses_insuarant_fires[indexCard].beneficiary_name_two =
        value;
      return result;
    });
  };
  const onChangeBeneficiaryRelationshipOneFire = (index, indexCard, e) => {
    const value = e.target.value;
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      result[index].expenses_insuarant_fires[
        indexCard
      ].beneficiary_relationship_one = value;
      return result;
    });
  };
  const onChangeBeneficiaryRelationshipTwoFire = (index, indexCard, e) => {
    const value = e.target.value;
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      result[index].expenses_insuarant_fires[
        indexCard
      ].beneficiary_relationship_two = value;
      return result;
    });
  };

  //------------------------------ เงินสะสมกองทุนสำรองเลี้ยงชีพต่อปี ------------------------------//
  const handleNextExpensesProvideFunds = (index, e) => {
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      const date = new Date();
      const created = date.toISOString();
      const newList = {
        created: created,
        pay_peryear: "",
      };
      result[index] = {
        ...result[index],
        expenses_provide_funds: [
          ...result[index].expenses_provide_funds,
          { ...newList },
        ],
      };
      return result;
    });
  };
  const onClickDeleteCardExpensesProvideFund = (index, indexCard, e) => {
    setAddExpensesFixed((prev) => {
      const result = [...prev];
      const updatedExpensesProvideFund = [
        ...result[index].expenses_provide_funds,
      ];
      updatedExpensesProvideFund.splice(indexCard, 1);
      result[index] = {
        ...result[index],
        expenses_provide_funds: updatedExpensesProvideFund,
      };
      return result;
    });
  };
  const onChangePayPerYearExpensesProvideFund = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].expenses_provide_funds[indexCard].pay_peryear = "";
      } else {
        result[index].expenses_provide_funds[indexCard].pay_peryear = value;
      }
      return result;
    });
  };

  //------------------------------ ประกันสังคมต่อเดือน (บาท/เดือน) ------------------------------//
  const handleNextExpensesInsuarantSocials = (index, e) => {
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      const date = new Date();
      const created = date.toISOString();
      const newList = {
        created: created,
        pay_permonth: "",
        pay_peryear: "",
      };
      result[index] = {
        ...result[index],
        expenses_insuarant_socials: [
          ...result[index].expenses_insuarant_socials,
          { ...newList },
        ],
      };
      return result;
    });
  };
  const onClickDeleteCardExpensesInsuarantSocial = (index, indexCard, e) => {
    setAddExpensesFixed((prev) => {
      const result = [...prev];
      const updatedExpensesInsuarantSocial = [
        ...result[index].expenses_insuarant_socials,
      ];
      updatedExpensesInsuarantSocial.splice(indexCard, 1);
      result[index] = {
        ...result[index],
        expenses_insuarant_socials: updatedExpensesInsuarantSocial,
      };
      return result;
    });
  };
  const onChangePayPerMonthExpensesInsuarantSocial = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].expenses_insuarant_socials[indexCard].pay_permonth = "";
        result[index].expenses_insuarant_socials[indexCard].pay_peryear = "";
      } else {
        result[index].expenses_insuarant_socials[indexCard].pay_permonth =
          value;
        result[index].expenses_insuarant_socials[indexCard].pay_peryear =
          value * 12;
      }
      return result;
    });
  };

  //------------------------------ ค่าใช้จ่ายคงที่อื่น ๆ ------------------------------//
  const handleNextExpensesFixedOthers = (index, e) => {
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      const date = new Date();
      const created = date.toISOString();
      const newList = {
        created: created,
        title: "",
        pay_permonth: "",
        pay_peryear: "",
      };
      result[index] = {
        ...result[index],
        expenses_fixed_others: [
          ...result[index].expenses_fixed_others,
          { ...newList },
        ],
      };
      return result;
    });
  };
  const onClickDeleteCardExpensesFixedOther = (index, indexCard, e) => {
    setAddExpensesFixed((prev) => {
      const result = [...prev];
      const updatedexpensesFixedOther = [
        ...result[index].expenses_fixed_others,
      ];
      updatedexpensesFixedOther.splice(indexCard, 1);
      result[index] = {
        ...result[index],
        expenses_fixed_others: updatedexpensesFixedOther,
      };
      return result;
    });
  };
  const onChangeTitleExpensesFixedOther = (index, indexCard, e) => {
    const value = e.target.value;
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      result[index].expenses_fixed_others[indexCard].title = value;
      return result;
    });
  };
  const onChangePayPerMonthExpensesFixedOther = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddExpensesFixed((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].expenses_fixed_others[indexCard].pay_permonth = "";
        result[index].expenses_fixed_others[indexCard].pay_peryear = "";
      } else {
        result[index].expenses_fixed_others[indexCard].pay_permonth = value;
        result[index].expenses_fixed_others[indexCard].pay_peryear = value * 12;
      }
      return result;
    });
  };

  //================================================== แปรผัน ==================================================//
  const onChangeSelectedVariable = (index, e) => {
    setAddExpensesVariable((prev) => {
      let value = e.value;
      const result = [...prev];
      const findTitle = options_expenses_variable?.filter((item) => {
        if (item.value === value) return item;
      });
      result[index].options_expenses_variable = value;
      result[index].title = findTitle?.[0]?.label;
      return result;
    });
  };
  const onChangePayPerMonthVariable = (index, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddExpensesVariable((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].pay_permonth = "";
        result[index].pay_peryear = "";
      } else {
        result[index].pay_permonth = value;
        result[index].pay_peryear = value * 12;
      }
      return result;
    });
  };
  const onChangePayPerYearVariable = (index, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddExpensesVariable((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].pay_peryear = "";
      } else {
        result[index].pay_peryear = value;
      }
      return result;
    });
  };
  const onChangeTitleOtherVariable = (index, e) => {
    const value = e.target.value;
    setAddExpensesVariable((prev) => {
      let result = [...prev];
      result[index].title_other = value;
      return result;
    });
  };

  //================================================== ออม / ลงทุน ==================================================//
  const onChangeSelectedInvest = (index, e) => {
    setAddExpensesInvest((prev) => {
      let value = e.value;
      const result = [...prev];
      const findTitle = options_expenses_invest?.filter((item) => {
        if (item.value === value) return item;
      });
      result[index].options_expenses_invest = value;
      result[index].title = findTitle?.[0]?.label;
      return result;
    });
  };
  const onChangeTitleSertviceProviderInvest = (index, e) => {
    const value = e.target.value;
    setAddExpensesInvest((prev) => {
      let result = [...prev];
      result[index].title_service_provider = value;
      return result;
    });
  };
  const onChangeTitleAccountInvest = (index, e) => {
    const value = e.target.value;
    setAddExpensesInvest((prev) => {
      let result = [...prev];
      result[index].title_account = value;
      return result;
    });
  };
  const onChangePayPerMonthInvest = (index, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddExpensesInvest((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].pay_permonth = "";
        result[index].pay_peryear = "";
      } else {
        result[index].pay_permonth = value;
        result[index].pay_peryear = value * 12;
      }
      return result;
    });
  };
  const onChangeTitleOtherInvest = (index, e) => {
    const value = e.target.value;
    setAddExpensesInvest((prev) => {
      let result = [...prev];
      result[index].title_other = value;
      return result;
    });
  };

  //================================================== Sum ค่าใช้จ่ายคงที่ ==================================================//
  // ค่าเบี้ยประกันชีวิตรายปี
  const sumExpensesInsuarantLifes = () => {
    let result = 0;
    addExpensesFixed?.map((item) => {
      item?.expenses_insuarant_lifes?.map((row) => {
        result += row?.allowance_peryear ? row?.allowance_peryear : 0;
      });
    });
    return result;
  };
  // ค่าเบี้ยประกันอุบัติเหตุรายปี
  const sumExpensesInsuarantAccidents = () => {
    let result = 0;
    addExpensesFixed?.map((item) => {
      item?.expenses_insuarant_accidents?.map((row) => {
        result += row?.allowance_peryear ? row?.allowance_peryear : 0;
      });
    });
    return result;
  };
  // ค่าเบี้ยประกันรถยนต์รายปี
  const sumExpensesInsuarantCars = () => {
    let result = 0;
    addExpensesFixed?.map((item) => {
      item?.expenses_insuarant_cars?.map((row) => {
        result += row?.allowance_peryear ? row?.allowance_peryear : 0;
      });
    });
    return result;
  };
  // ค่าเบี้ยประกันอัคคีภัยรายปี
  const sumExpensesInsuarantFires = () => {
    let result = 0;
    addExpensesFixed?.map((item) => {
      item?.expenses_insuarant_fires?.map((row) => {
        result += row?.allowance_peryear ? row?.allowance_peryear : 0;
      });
    });
    return result;
  };
  // ค่าเบี้ยประกันบ้านรายปี
  const sumExpensesInsuarantHomes = () => {
    let result = 0;
    addExpensesFixed?.map((item) => {
      item?.expenses_insuarant_homes?.map((row) => {
        result += row?.allowance_peryear ? row?.allowance_peryear : 0;
      });
    });
    return result;
  };
  // ค่าส่วนกลางหมู่บ้าน
  const sumExpensesInsuarantVillages = () => {
    let result = 0;
    addExpensesFixed?.map((item) => {
      item?.expenses_insuarant_villages?.map((row) => {
        result += row?.pay_peryear ? row?.pay_peryear : 0;
      });
    });
    return result;
  };
  // ประกันสังคมต่อเดือน (บาท /เดือน)
  const sumExpensesInsuarantSocials = () => {
    let result = 0;
    addExpensesFixed?.map((item) => {
      item?.expenses_insuarant_socials?.map((row) => {
        result += row?.pay_peryear ? row?.pay_peryear : 0;
      });
    });
    return result;
  };
  // เงินสะสมกองทุนสำรองเลี้ยงชีพต่อปี
  const sumExpensesProvideFunds = () => {
    let result = 0;
    addExpensesFixed?.map((item) => {
      item?.expenses_provide_funds?.map((row) => {
        result += row?.pay_peryear ? row?.pay_peryear : 0;
      });
    });
    return result;
  };
  // ค่าใช้จ่ายคงที่อื่น ๆ
  const sumExpensesFixedOthers = () => {
    let result = 0;
    addExpensesFixed?.map((item) => {
      item?.expenses_fixed_others?.map((row) => {
        result += row?.pay_peryear ? row?.pay_peryear : 0;
      });
    });
    return result;
  };
  // รวมค่าใช้จ่ายคงที่ทั้งหมด
  const sumAllExpensesFixed = () => {
    let result = 0;
    result += sumExpensesInsuarantLifes();
    result += sumExpensesInsuarantAccidents();
    result += sumExpensesInsuarantCars();
    result += sumExpensesInsuarantFires();
    result += sumExpensesInsuarantHomes();
    result += sumExpensesInsuarantVillages();
    result += sumExpensesInsuarantSocials();
    result += sumExpensesProvideFunds();
    result += sumExpensesFixedOthers();
    return result;
  };

  //================================================== Sum ค่าใช้จ่ายผันแปร ==================================================//
  const sumAllExpensesVariable = () => {
    let result = 0;
    addExpensesVariable?.map((item) => {
      result += item?.pay_peryear ? item?.pay_peryear : 0;
    });
    return result;
  };

  //============================================== Sum ค่าใช้จ่ายเพื่อออม/ลงทุน ==============================================//
  const sumAllExpensesInvest = () => {
    let result = 0;
    addExpensesInvest?.map((item) => {
      result += item?.pay_peryear ? item?.pay_peryear : 0;
    });
    return result;
  };

  //=====================================================================================================================//
  // หายอดรวมค่าใช้จ่ายหนี้สินรายเดือนทั้งหมด
  const sumExpensesDebtPerMonth = () => {
    let result = 0;
    if (balanceData?.sum_debt_short_permonth) {
      result += balanceData?.sum_debt_short_permonth;
    }
    if (balanceData?.sum_debt_long_permonth) {
      result += balanceData?.sum_debt_long_permonth;
    }
    return result;
  };
  // หายอดรวมค่าใช้จ่ายหนี้สินรายปีทั้งหมด
  const sumExpensesDebtPerYear = () => {
    let result = 0;
    result = sumExpensesDebtPerMonth() * 12;
    return result;
  };

  //-------------------- Submit --------------------//
  const onClickSubmit = () => {
    const expenses_propotion = Number(
      formBL.expenses_propotion - (formBL?.temp_net_cash_flow || 0)
    );

    // let tempBalanceData = {};
    // let tempInsuranceReturn = addExpensesFixed
    //   ?.filter(
    //     (item) => item.options_expenses_fixed === "expenses_insuarant_life"
    //   )
    //   ?.map((item) => item.expenses_insuarant_lifes)
    //   ?.flat();

    //=============เงินลงทุนอื่น ๆ (สินทรัพย์ทางเลือก)=============//
    // if (
    //   balanceData?.property_invest_list
    //     ?.map((item) => item.options_property_invest_title)
    //     .includes("เงินลงทุนอื่น ๆ (สินทรัพย์ทางเลือก)")
    // ) {
    //   // ถ้ามีหัวข้อนี้อยู่แล้ว
    //   let tempNameList = balanceData?.property_invest_list.find(
    //     (item) =>
    //       item?.options_property_invest_title ===
    //       "เงินลงทุนอื่น ๆ (สินทรัพย์ทางเลือก)"
    //   )?.invest_other_lists?.filter((item)=> item !== null).map((item) => item?.title);

    //   let tempInvestList = balanceData?.property_invest_list?.map((item) => {
    //     if (item?.invest_other_lists) {
    //       let tempData = tempInsuranceReturn.map((item2) => {
    //         if (!tempNameList?.includes(item2.title))
    //           return {
    //             amount: item2.allowance_peryear,
    //             created: item2.created,
    //             reward_amount_per_year: 1,
    //             target_invest: {},
    //             title: item2.title,
    //             editable: false,
    //           };
    //       }).filter((item) => item !== undefined);
    //       return {
    //         ...item,
    //         invest_other_lists: [...item.invest_other_lists, ...tempData],
    //       };
    //     }
    //     return item;
    //   });

    //   tempBalanceData = {
    //     ...balanceData,
    //     property_invest_list: [...tempInvestList],
    //   };
    // } else {
    //   // ถ้ายังไม่มีหัวข้อนี้อยู่
    //   let tempInvestList = tempInsuranceReturn.map((item) => {
    //     return {
    //       amount: item.allowance_peryear,
    //       created: item.created,
    //       reward_amount_per_year: 1,
    //       target_invest: {},
    //       title: item.title,
    //       editable: true,
    //     };
    //   });
    //   tempBalanceData = {
    //     ...balanceData,
    //     property_invest_list: [
    //       ...balanceData?.property_invest_list || '',
    //       {
    //         created: "",
    //         invest_other_lists: [...tempInvestList],
    //         options_property_invest: "property_invest_other",
    //         options_property_invest_category: "property_other",
    //         options_property_invest_title:
    //           "เงินลงทุนอื่น ๆ (สินทรัพย์ทางเลือก)",
    //       },
    //     ],
    //   };
    // }
    //==============================================//

    let new_temp_net_cash_flow =
      (formBL?.salary_propotion || 0) -
      (expenses_propotion - sumAllExpensesInvest());

    new_temp_net_cash_flow = new_temp_net_cash_flow - sumAllExpensesInvest();

    if (new_temp_net_cash_flow < 0) {
      new_temp_net_cash_flow = 0;
    }

    const sum_expenses_invest_saving =
      sumAllExpensesInvest() + new_temp_net_cash_flow;
    const new_expenses_propotion =
      sumAllExpensesVariable() +
      sumAllExpensesFixed() +
      sum_expenses_invest_saving;

    setBalanceData({
      ...formBL,
      expenses_fixed_list: addExpensesFixed,
      expenses_propotion: new_expenses_propotion,
      sum_expenses_invest_saving: sum_expenses_invest_saving,
      temp_net_cash_flow: new_temp_net_cash_flow,
      // property_invest_list: tempBalanceData?.property_invest_list,
    });
    onclose();
  };

  //----------  onClick ลบกระแสเงินสุทธิ ----------//

  const onClickDeleteNetCashFlow = () => {
    setFormBL({
      ...formBL,
      net_cash_flow_yes_no: "no",
      net_cash_flow: 0,
      temp_net_cash_flow: 0,
      sum_expenses_invest_saving:
        formBL?.sum_expenses_invest_saving - formBL?.temp_net_cash_flow,
    });
  };

  // LOAD SUM
  useEffect(() => {
    const expenses_propotion = Number(
      sumAllExpensesFixed() +
        sumExpensesDebtPerYear() +
        sumAllExpensesVariable() +
        sumAllExpensesInvest()
    );

    let new_temp_net_cash_flow = formBL?.temp_net_cash_flow || 0;
    const sum_expenses_invest_saving =
      sumAllExpensesInvest() + new_temp_net_cash_flow;

    setFormBL({
      ...formBL,
      expenses_fixed_list: addExpensesFixed,
      sum_expenses_fixed: sumAllExpensesFixed() + sumExpensesDebtPerYear(),
      expenses_variable_list: addExpensesVariable,
      sum_expenses_variable: sumAllExpensesVariable(),
      expenses_invest_saving_list: addExpensesInvest,
      sum_expenses_invest_saving: sum_expenses_invest_saving,
      sum_property_invest: sumAllExpensesInvest(),
      expenses_propotion: expenses_propotion + new_temp_net_cash_flow,
    });
  }, [
    sumExpensesInsuarantLifes(),
    sumExpensesInsuarantAccidents(),
    sumExpensesInsuarantCars(),
    sumExpensesInsuarantFires(),
    sumExpensesInsuarantHomes(),
    sumExpensesInsuarantVillages(),
    sumExpensesInsuarantSocials(),
    sumExpensesProvideFunds(),
    sumExpensesFixedOthers(),
    sumAllExpensesFixed(),
    sumAllExpensesVariable(),
    sumAllExpensesInvest(),
    sumExpensesDebtPerYear(),
  ]);

  return (
    <Drawer
      placement="right"
      open={open}
      onClose={onclose}
      className=" overflow-y-scroll !max-w-[100%] "
    >
      <div
        style={{
          backgroundImage: `url(/images/circle.png)`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: " left  ",
        }}
        className={`min-h-screen  `}
      >
        <div className={STYLE_MAX_W_DRAWER}>
          <HeaderDrawer title={`สัดส่วนรายจ่าย`} onClickGoBack={onclose} />
          <div className={`${STYLE_PADDING_BALANCE_DRAWER} `}>
            <div className="flex flex-col gap-5 relative">
              {/*------------------------------ ค่าใช้จ่ายคงที่ ------------------------------*/}
              <CardAccordian
                zIndex="3"
                onClickOpen={() => {
                  handleOpen(1);
                  if (openAccordion === 1) setOpenAccordion(false);
                }}
                open={openAccordion === 1}
                add
                title="ค่าใช้จ่ายคงที่"
                textColor="text-yellow400"
                icon="/images/finance_outcome2.png"
                value={formBL?.sum_expenses_fixed}
                onClickAccordion={() => {
                  const date = new Date();
                  const created = date.toISOString();
                  const result = [
                    {
                      created: created,
                      options_expenses_fixed: "",
                    },
                  ];
                  setAddExpensesFixed((prev) => {
                    return [...prev, ...result];
                  });
                  handleOpen(1);
                }}
                panel={
                  <div>
                    {/*----- เงินผ่อนชำระงวดหนี้สินคงค้างรายเดือน (บาท/เดือน) -----*/}
                    <ListDebtMain
                      sumExpensesDebtPerYear={sumExpensesDebtPerYear}
                    />

                    {addExpensesFixed
                      ?.sort(
                        (a, b) => new Date(b.created) - new Date(a.created)
                      )
                      ?.map((row, index) => {
                        return (
                          <div key={index} className=" ">
                            <div className=" pt-3 ">
                              <DropDownMain
                                value={options_expenses_fixed?.filter(
                                  (option) => {
                                    if (
                                      option?.value ===
                                      row?.options_expenses_fixed
                                    )
                                      return option?.label;
                                  }
                                )}
                                onChange={onChangeSelectedFixed?.bind(
                                  this,
                                  row?.created,
                                  index
                                )}
                                options={options_expenses_fixed}
                                zIndex={3}
                              />
                            </div>

                            {/*----- ค่าเบี้ยประกันชีวิตรายปี -----*/}
                            {row?.options_expenses_fixed ===
                              "expenses_insuarant_life" && (
                              <div className=" mt-4">
                                <div className=" bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9 ">
                                  <div className=" text-sm text-black font-medium">
                                    จำนวนกรมธรรม์ (เล่ม)
                                  </div>
                                  <ButtonCounter
                                    value={
                                      row?.expenses_insuarant_lifes?.length
                                    }
                                    handleNext={handleNextExpensesInsuarantLife.bind(
                                      this,
                                      index
                                    )}
                                  />
                                </div>
                                {row?.expenses_insuarant_lifes
                                  ?.sort(
                                    (a, b) =>
                                      new Date(b.created) - new Date(a.created)
                                  )
                                  ?.map((row, indexCard) => {
                                    return (
                                      <CardExpenses
                                        keyIndex={index}
                                        indexCard={indexCard}
                                        isFormOne={true}
                                        etc={true}
                                        title={row?.title}
                                        insurancePolicyType={
                                          row?.insurance_policy_type
                                        }
                                        insurancePolicyNumber={
                                          row?.insurance_policy_number
                                        }
                                        insurancePolicyName={
                                          row?.insurance_policy_name
                                        }
                                        insurancePolicyDate={
                                          row?.insurance_policy_date
                                        }
                                        insurancePolicyDateMakecompact={
                                          row?.date_insurance_policy_makecompact
                                        }
                                        insurancePolicyDateFinishcompact={
                                          row?.date_insurance_policy_finishcompact
                                        }
                                        valueDropdown={
                                          row?.insurance_policy_status
                                        }
                                        allowancePeryear={
                                          row?.allowance_peryear
                                        }
                                        insuranceFund={row?.insurance_fund}
                                        insurancePolicyCashSurrender={
                                          row?.insurance_policy_cash_surrender
                                        }
                                        moreDetails={row?.more_details}
                                        head={`ชื่อบริษัท/องค์กร`}
                                        placeholder={`ระบุชื่อบริษัทรับประกัน`}
                                        optionsDropdown={
                                          INSURANCE_POLICY_STATUS
                                        }
                                        onClickOpen={() => {
                                          setOpenETC(!openETC);
                                        }}
                                        open={openETC}
                                        onChangeContractualRefund={onChangeContractualRefund.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangePolicySurrenderValue={onChangePolicySurrenderValue?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeStatusAIAVitlaity={onChangeStatusAIAVitlaity.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeTotalYear={onChangeTotalYear?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onClickDelete={onClickDeleteCardExpensesInsuarantLife?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeTitle={onChangeTitleLife?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeAIAMutiplayCIMore={onChangeAIAMutiplayCIMore?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeTypeOfPayment={onChangeTypeOfPayment?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeAIATotalCareMore={onChangeAIATotalCareMore?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeInsurancePolicyType={onChangeInsurancePolicyTypeLife?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeInsurancePolicyNumber={onChangeInsurancePolicyNumberLife?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeInsurancePolicyName={onChangeInsurancePolicyNameLife?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeInsurancePolicyDate={onChangeInsurancePolicyDateLife?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeInsurancePolicyDateMakecompact={onChangeInsurancePolicyDateMakecompactLife?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeInsurancePolicyDateFinishcompact={onChangeInsurancePolicyDateFinishcompactLife?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeDropdown={onChangeDropdownLife?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeAllowancePeryear={onChangeAllowancePeryearLife?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeInsuranceFund={onChangeInsuranceFundLife?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeInsurancePolicyCashSurrender={onChangeInsurancePolicyCashSurrenderLife?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeLifeInsurance={onChangeLifeInsuranceLife?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeLifeAllowance={onChangeLifeAllowanceLife?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeExceptAllowanceWP={onChangeExceptAllowanceWPLife?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeExceptAllowancePB={onChangeExceptAllowancePBLife?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeContractTPD={onChangeContractTPDLife?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeProtectionLifeLT={onChangeProtectionLifeLTLife?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeExceptAllowanceLT={onChangeExceptAllowanceLTLife?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeAccidentAI={onChangeAccidentAILife?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeAccidentADB={onChangeAccidentADBLife?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeAccidentADD={onChangeAccidentADDLife?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeHospitalTreatmentSurgery={onChangeHospitalTreatmentSurgeryLife?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeHospitalTreatmentSurgeryLumpSumPayment={onChangeHospitalTreatmentSurgeryLumpSumPaymentLife?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeDailyCompensationForTreatment={onChangeDailyCompensationForTreatmentLife?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeDailyCompensationForTreatmentSpecial={onChangeDailyCompensationForTreatmentSpecialLife?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeCompensationForCancerTreatmentCR={onChangeCompensationForCancerTreatmentCRLife?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeCompensationECIR={onChangeCompensationECIRLife?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeCompensationAIASupercare={onChangeCompensationAIASupercareLife?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeCompensationAIACIPlusGold={onChangeCompensationAIACIPlusGoldLife?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeCompensationAIACIPlus={onChangeCompensationAIACIPlusLife?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeCompensationMutipleCareCi={onChangeCompensationMutipleCareCiLife?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeCriticalIllnessInsuranceYearlyCi={onChangeCriticalIllnessInsuranceYearlyCiLife?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeMedicalExpensesAccidentPA={onChangeMedicalExpensesAccidentPALife?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeAllowanceLastTime={onChangeAllowanceLastTimeLife?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeDatePayAllowanceLastTime={onChangeDatePayAllowanceLastTimeLife?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeDateDueAllowanceNextTime={onChangeDateDueAllowanceNextTimeLife?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeDateDueAllowanceFinalTime={onChangeDateDueAllowanceFinalTimeLife?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeBeneficiaryNameOne={onChangeBeneficiaryNameOneLife?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeBeneficiaryNameTwo={onChangeBeneficiaryNameTwoLife?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeBeneficiaryRelationshipOne={onChangeBeneficiaryRelationshipOneLife?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeBeneficiaryRelationshipTwo={onChangeBeneficiaryRelationshipTwoLife?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeAnnotation={onChangeAnnotationLife?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                      />
                                    );
                                  })}

                                <PopupMain
                                  title={"ยืนยันการลบข้อมูล"}
                                  text={
                                    <div className=" text-base">
                                      คุณต้องการลบข้อมูลรายการนี้ใช่หรือไม่
                                    </div>
                                  }
                                  button2={true}
                                  textButton={"ยืนยัน"}
                                  textButton2={"ยกเลิก"}
                                  open={openAlert}
                                  onClick={
                                    onClickConfirmDeleteCardExpensesInsuarantLife
                                  }
                                  onClickSecond={() => {
                                    setDataIndexDeleteOne(null);
                                    setOpenAlert(false);
                                  }}
                                />
                              </div>
                            )}

                            {/*----- ค่าเบี้ยประกันอุบัติเหตุรายปี -----*/}
                            {row?.options_expenses_fixed ===
                              "expenses_insuarant_accident" && (
                              <div className=" mt-4">
                                <div className=" bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9 ">
                                  <div className=" text-sm text-black font-medium">
                                    จำนวนกรมธรรม์ (เล่ม)
                                  </div>
                                  <ButtonCounter
                                    value={
                                      row?.expenses_insuarant_accidents?.length
                                    }
                                    handleNext={handleNextExpensesInsuarantAccidents.bind(
                                      this,
                                      index
                                    )}
                                  />
                                </div>
                                {row?.expenses_insuarant_accidents
                                  ?.sort(
                                    (a, b) =>
                                      new Date(b.created) - new Date(a.created)
                                  )
                                  ?.map((row, indexCard) => {
                                    return (
                                      <CardExpenses
                                        keyIndex={index}
                                        indexCard={indexCard}
                                        isFormTwo={true}
                                        title={row?.title}
                                        insurancePolicyType={
                                          row?.insurance_policy_type
                                        }
                                        insurancePolicyNumber={
                                          row?.insurance_policy_number
                                        }
                                        insurancePolicyName={
                                          row?.insurance_policy_name
                                        }
                                        insurancePolicyDateMakecompact={
                                          row?.date_insurance_policy_makecompact
                                        }
                                        insurancePolicyDateFinishcompact={
                                          row?.date_insurance_policy_finishcompact
                                        }
                                        insuranceFund={row?.insurance_fund}
                                        allowancePeryear={
                                          row?.allowance_peryear
                                        }
                                        beneficiaryNameOne={
                                          row?.beneficiary_name_one
                                        }
                                        beneficiaryNameTwo={
                                          row?.beneficiary_name_two
                                        }
                                        beneficiaryRelationshipOne={
                                          row?.beneficiary_relationship_one
                                        }
                                        beneficiaryRelationshipTwo={
                                          row?.beneficiary_relationship_two
                                        }
                                        head={`ชื่อบริษัท / ชื่อองค์กร`}
                                        placeholder={`ระบุชื่อบริษัทรับประกัน`}
                                        onClickDelete={onClickDeleteCardExpensesInsuarantAccident?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeTitle={onChangeTitleAccident?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeInsurancePolicyType={onChangeInsurancePolicyTypeAccident?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeInsurancePolicyNumber={onChangeInsurancePolicyNumberAccident?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeInsurancePolicyName={onChangeInsurancePolicyNameAccident?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeInsurancePolicyDateMakecompact={onChangeInsurancePolicyDateMakecompactAccident?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeInsurancePolicyDateFinishcompact={onChangeInsurancePolicyDateFinishcompactAccident?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeInsuranceFund={onChangeInsuranceFundAccident?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeAllowancePeryear={onChangeAllowancePeryearAccident?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeBeneficiaryNameOne={onChangeBeneficiaryNameOneAccident?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeBeneficiaryNameTwo={onChangeBeneficiaryNameTwoAccident?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeBeneficiaryRelationshipOne={onChangeBeneficiaryRelationshipOneAccident?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeBeneficiaryRelationshipTwo={onChangeBeneficiaryRelationshipTwoAccident?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                      />
                                    );
                                  })}
                                <PopupMain
                                  title={"ยืนยันการลบข้อมูล"}
                                  text={
                                    <div className=" text-base">
                                      คุณต้องการลบข้อมูลรายการนี้ใช่หรือไม่
                                    </div>
                                  }
                                  button2={true}
                                  textButton={"ยืนยัน"}
                                  textButton2={"ยกเลิก"}
                                  open={openAlert}
                                  onClick={
                                    onClickConfirmDeleteCardExpensesInsuarantAccident
                                  }
                                  onClickSecond={() => {
                                    setDataIndexDeleteOne(null);
                                    setOpenAlert(false);
                                  }}
                                />
                              </div>
                            )}

                            {/*----- ค่าเบี้ยประกันรถยนต์รายปี -----*/}
                            {row?.options_expenses_fixed ===
                              "expenses_insuarant_car" && (
                              <div className=" mt-4">
                                <div className=" bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9 ">
                                  <div className=" text-sm text-black font-medium">
                                    จำนวนกรมธรรม์ (เล่ม)
                                  </div>
                                  <ButtonCounter
                                    value={row?.expenses_insuarant_cars?.length}
                                    handleNext={handleNextExpensesInsuarantCars.bind(
                                      this,
                                      index
                                    )}
                                  />
                                </div>
                                {row?.expenses_insuarant_cars
                                  ?.sort(
                                    (a, b) =>
                                      new Date(b.created) - new Date(a.created)
                                  )
                                  ?.map((row, indexCard) => {
                                    return (
                                      <CardExpenses
                                        keyIndex={index}
                                        indexCard={indexCard}
                                        isFormTwo={true}
                                        isCar={true}
                                        hasDropdown={true}
                                        title={row?.title}
                                        registrationCar={row?.registration_car}
                                        valueDropdown={
                                          row?.insurance_policy_type
                                        }
                                        insurancePolicyNumber={
                                          row?.insurance_policy_number
                                        }
                                        insurancePolicyName={
                                          row?.insurance_policy_name
                                        }
                                        insurancePolicyDateMakecompact={
                                          row?.date_insurance_policy_makecompact
                                        }
                                        insurancePolicyDateFinishcompact={
                                          row?.date_insurance_policy_finishcompact
                                        }
                                        insuranceFund={row?.insurance_fund}
                                        allowancePeryear={
                                          row?.allowance_peryear
                                        }
                                        beneficiaryNameOne={
                                          row?.beneficiary_name_one
                                        }
                                        beneficiaryNameTwo={
                                          row?.beneficiary_name_two
                                        }
                                        beneficiaryRelationshipOne={
                                          row?.beneficiary_relationship_one
                                        }
                                        beneficiaryRelationshipTwo={
                                          row?.beneficiary_relationship_two
                                        }
                                        head={`ชื่อบริษัท / ชื่อองค์กร`}
                                        placeholder={`ระบุชื่อบริษัทรับประกัน`}
                                        optionsDropdown={
                                          INSURANCE_POLICY_TYPE_CAR
                                        }
                                        onClickDelete={onClickDeleteCardExpensesInsuarantCar?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeTitle={onChangeTitleCar?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeRegistrationCar={onChangeRegistrationCar?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeDropdown={onChangeInsurancePolicyTypeCar?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeInsurancePolicyNumber={onChangeInsurancePolicyNumberCar?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeInsurancePolicyName={onChangeInsurancePolicyNameCar?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeInsurancePolicyDateMakecompact={onChangeInsurancePolicyDateMakecompactCar?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeInsurancePolicyDateFinishcompact={onChangeInsurancePolicyDateFinishcompactCar?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeInsuranceFund={onChangeInsuranceFundCar?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeAllowancePeryear={onChangeAllowancePeryearCar?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeBeneficiaryNameOne={onChangeBeneficiaryNameOneCar?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeBeneficiaryNameTwo={onChangeBeneficiaryNameTwoCar?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeBeneficiaryRelationshipOne={onChangeBeneficiaryRelationshipOneCar?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeBeneficiaryRelationshipTwo={onChangeBeneficiaryRelationshipTwoCar?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                      />
                                    );
                                  })}
                                <PopupMain
                                  title={"ยืนยันการลบข้อมูล"}
                                  text={
                                    <div className=" text-base">
                                      คุณต้องการลบข้อมูลรายการนี้ใช่หรือไม่
                                    </div>
                                  }
                                  button2={true}
                                  textButton={"ยืนยัน"}
                                  textButton2={"ยกเลิก"}
                                  open={openAlert}
                                  onClick={
                                    onClickConfirmDeleteCardExpensesInsuarantCar
                                  }
                                  onClickSecond={() => {
                                    setDataIndexDeleteOne(null);
                                    setOpenAlert(false);
                                  }}
                                />
                              </div>
                            )}

                            {/*----- ค่าเบี้ยประกันอัคคีภัยรายปี -----*/}
                            {row?.options_expenses_fixed ===
                              "expenses_insuarant_fire" && (
                              <div className=" mt-4">
                                <div className=" bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9 ">
                                  <div className=" text-sm text-black font-medium">
                                    จำนวนกรมธรรม์ (เล่ม)
                                  </div>
                                  <ButtonCounter
                                    value={
                                      row?.expenses_insuarant_fires?.length
                                    }
                                    handleNext={handleNextExpensesInsuarantFires.bind(
                                      this,
                                      index
                                    )}
                                  />
                                </div>
                                {row?.expenses_insuarant_fires
                                  ?.sort(
                                    (a, b) =>
                                      new Date(b.created) - new Date(a.created)
                                  )
                                  ?.map((row, indexCard) => {
                                    return (
                                      <CardExpenses
                                        keyIndex={index}
                                        indexCard={indexCard}
                                        isFormTwo={true}
                                        title={row?.title}
                                        insurancePolicyType={
                                          row?.insurance_policy_type
                                        }
                                        insurancePolicyNumber={
                                          row?.insurance_policy_number
                                        }
                                        insurancePolicyName={
                                          row?.insurance_policy_name
                                        }
                                        insurancePolicyDateMakecompact={
                                          row?.date_insurance_policy_makecompact
                                        }
                                        insurancePolicyDateFinishcompact={
                                          row?.date_insurance_policy_finishcompact
                                        }
                                        insuranceFund={row?.insurance_fund}
                                        allowancePeryear={
                                          row?.allowance_peryear
                                        }
                                        beneficiaryNameOne={
                                          row?.beneficiary_name_one
                                        }
                                        beneficiaryNameTwo={
                                          row?.beneficiary_name_two
                                        }
                                        beneficiaryRelationshipOne={
                                          row?.beneficiary_relationship_one
                                        }
                                        beneficiaryRelationshipTwo={
                                          row?.beneficiary_relationship_two
                                        }
                                        head={`ชื่อบริษัท / ชื่อองค์กร`}
                                        placeholder={`ระบุชื่อบริษัทรับประกัน`}
                                        onClickDelete={onClickDeleteCardExpensesInsuarantFire?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeTitle={onChangeTitleFire?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeInsurancePolicyType={onChangeInsurancePolicyTypeFire?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeInsurancePolicyNumber={onChangeInsurancePolicyNumberFire?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeInsurancePolicyName={onChangeInsurancePolicyNameFire?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeInsurancePolicyDateMakecompact={onChangeInsurancePolicyDateMakecompactFire?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeInsurancePolicyDateFinishcompact={onChangeInsurancePolicyDateFinishcompactFire?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeInsuranceFund={onChangeInsuranceFundFire?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeAllowancePeryear={onChangeAllowancePeryearFire?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeBeneficiaryNameOne={onChangeBeneficiaryNameOneFire?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeBeneficiaryNameTwo={onChangeBeneficiaryNameTwoFire?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeBeneficiaryRelationshipOne={onChangeBeneficiaryRelationshipOneFire?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeBeneficiaryRelationshipTwo={onChangeBeneficiaryRelationshipTwoFire?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                      />
                                    );
                                  })}
                                <PopupMain
                                  title={"ยืนยันการลบข้อมูล"}
                                  text={
                                    <div className=" text-base">
                                      คุณต้องการลบข้อมูลรายการนี้ใช่หรือไม่
                                    </div>
                                  }
                                  button2={true}
                                  textButton={"ยืนยัน"}
                                  textButton2={"ยกเลิก"}
                                  open={openAlert}
                                  onClick={
                                    onClickConfirmDeleteCardExpensesInsuarantFire
                                  }
                                  onClickSecond={() => {
                                    setDataIndexDeleteOne(null);
                                    setOpenAlert(false);
                                  }}
                                />
                              </div>
                            )}

                            {/*----- ค่าเบี้ยประกันบ้านรายปี -----*/}
                            {row?.options_expenses_fixed ===
                              "expenses_insuarant_home" && (
                              <div className=" mt-4">
                                <div className=" bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9 ">
                                  <div className=" text-sm text-black font-medium">
                                    จำนวนกรมธรรม์ (เล่ม)
                                  </div>
                                  <ButtonCounter
                                    value={
                                      row?.expenses_insuarant_homes?.length
                                    }
                                    handleNext={handleNextExpensesInsuarantHomes.bind(
                                      this,
                                      index
                                    )}
                                  />
                                </div>
                                {row?.expenses_insuarant_homes
                                  ?.sort(
                                    (a, b) =>
                                      new Date(b.created) - new Date(a.created)
                                  )
                                  ?.map((row, indexCard) => {
                                    return (
                                      <CardExpenses
                                        keyIndex={index}
                                        indexCard={indexCard}
                                        isFormTwo={true}
                                        hasDropdown={true}
                                        title={row?.title}
                                        valueDropdown={
                                          row?.insurance_policy_type
                                        }
                                        insurancePolicyNumber={
                                          row?.insurance_policy_number
                                        }
                                        insurancePolicyName={
                                          row?.insurance_policy_name
                                        }
                                        insurancePolicyDateMakecompact={
                                          row?.date_insurance_policy_makecompact
                                        }
                                        insurancePolicyDateFinishcompact={
                                          row?.date_insurance_policy_finishcompact
                                        }
                                        insuranceFund={row?.insurance_fund}
                                        allowancePeryear={
                                          row?.allowance_peryear
                                        }
                                        beneficiaryNameOne={
                                          row?.beneficiary_name_one
                                        }
                                        beneficiaryNameTwo={
                                          row?.beneficiary_name_two
                                        }
                                        beneficiaryRelationshipOne={
                                          row?.beneficiary_relationship_one
                                        }
                                        beneficiaryRelationshipTwo={
                                          row?.beneficiary_relationship_two
                                        }
                                        head={`ที่ตั้งทรัพย์สิน`}
                                        placeholder={`ระบุชื่อบริษัทรับประกัน`}
                                        optionsDropdown={INSURANCE_POLICY_TYPE}
                                        onClickDelete={onClickDeleteCardExpensesInsuarantHome?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeTitle={onChangeTitleHome?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeDropdown={onChangeInsurancePolicyTypeHome?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeInsurancePolicyNumber={onChangeInsurancePolicyNumberHome?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeInsurancePolicyName={onChangeInsurancePolicyNameHome?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeInsurancePolicyDateMakecompact={onChangeInsurancePolicyDateMakecompactHome?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeInsurancePolicyDateFinishcompact={onChangeInsurancePolicyDateFinishcompactHome?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeInsuranceFund={onChangeInsuranceFundHome?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeAllowancePeryear={onChangeAllowancePeryearHome?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeBeneficiaryNameOne={onChangeBeneficiaryNameOneHome?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeBeneficiaryNameTwo={onChangeBeneficiaryNameTwoHome?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeBeneficiaryRelationshipOne={onChangeBeneficiaryRelationshipOneHome?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                        onChangeBeneficiaryRelationshipTwo={onChangeBeneficiaryRelationshipTwoHome?.bind(
                                          this,
                                          index,
                                          indexCard
                                        )}
                                      />
                                    );
                                  })}
                                <PopupMain
                                  title={"ยืนยันการลบข้อมูล"}
                                  text={
                                    <div className=" text-base">
                                      คุณต้องการลบข้อมูลรายการนี้ใช่หรือไม่
                                    </div>
                                  }
                                  button2={true}
                                  textButton={"ยืนยัน"}
                                  textButton2={"ยกเลิก"}
                                  open={openAlert}
                                  onClick={
                                    onClickConfirmDeleteCardExpensesInsuarantHome
                                  }
                                  onClickSecond={() => {
                                    setDataIndexDeleteOne(null);
                                    setOpenAlert(false);
                                  }}
                                />
                              </div>
                            )}

                            {/*----- ค่าส่วนกลางหมู่บ้านต่อเดือน (บาท/เดือน) -----*/}
                            {row?.options_expenses_fixed ===
                              "expenses_insuarant_village" && (
                              <div className=" mt-4">
                                {row?.expenses_insuarant_villages
                                  ?.sort(
                                    (a, b) =>
                                      new Date(b.created) - new Date(a.created)
                                  )
                                  ?.map((row, indexCard) => {
                                    return (
                                      <>
                                        <div
                                          className={STYLE_PARENT_CARD_BALANCE}
                                        >
                                          <div
                                            className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}
                                          >
                                            ระบุชื่อหมู่บ้าน
                                          </div>
                                          <input
                                            placeholder="ระบุ"
                                            value={row?.title}
                                            onChange={onChangeTitleVillages?.bind(
                                              this,
                                              index,
                                              indexCard
                                            )}
                                            className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
                                          />
                                        </div>
                                        <div
                                          className={STYLE_PARENT_CARD_BALANCE}
                                        >
                                          <div
                                            className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}
                                          >
                                            เดือนละ
                                          </div>
                                          <NumbericValidateRed
                                            value={row?.pay_permonth}
                                            onChange={onChangePayPerMonthVillage?.bind(
                                              this,
                                              index,
                                              indexCard
                                            )}
                                            className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
                                          />
                                        </div>

                                        <div className=" flex justify-center text-sm items-center mt-3">
                                          จำนวน (ต่อปี){" "}
                                          <span className="font-semibold text-center text-maincyan ml-1">
                                            {numeral(row?.pay_peryear).format(
                                              "0,0"
                                            )}
                                          </span>
                                        </div>
                                      </>
                                    );
                                  })}
                              </div>
                            )}

                            {/*----- ประกันสังคมต่อเดือน (บาท /เดือน) -----*/}
                            {row?.options_expenses_fixed ===
                              "expenses_insuarant_social" && (
                              <div className=" mt-4">
                                {row?.expenses_insuarant_socials
                                  ?.sort(
                                    (a, b) =>
                                      new Date(b.created) - new Date(a.created)
                                  )
                                  ?.map((row, indexCard) => {
                                    return (
                                      <>
                                        <div
                                          className={STYLE_PARENT_CARD_BALANCE}
                                        >
                                          <div
                                            className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-1 `}
                                          >
                                            เดือนละ
                                          </div>
                                          <NumbericValidateRed
                                            value={row?.pay_permonth}
                                            onChange={onChangePayPerMonthExpensesInsuarantSocial?.bind(
                                              this,
                                              index,
                                              indexCard
                                            )}
                                            className={`${STYLE_INPUT_CARD_BALANCE} col-span-4 ml-1`}
                                          />
                                        </div>

                                        <div className=" flex justify-center text-sm items-center mt-3">
                                          จำนวน (ต่อปี){" "}
                                          <span className="font-semibold text-center text-maincyan ml-1">
                                            {numeral(row?.pay_peryear).format(
                                              "0,0"
                                            )}
                                          </span>
                                        </div>
                                      </>
                                    );
                                  })}
                              </div>
                            )}

                            {/*----- เงินสะสมกองทุนสำรองเลี้ยงชีพต่อปี -----*/}
                            {row?.options_expenses_fixed ===
                              "expenses_provide_fund" && (
                              <div className=" mt-4">
                                {/* <div className=" bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9 ">
                                  <div className=" text-sm text-black font-medium">
                                    จำนวนกองทุนสำรองเลี้ยงชีพ
                                  </div>
                                  <ButtonCounter
                                    value={row?.expenses_provide_funds?.length}
                                    handleNext={handleNextExpensesProvideFunds.bind(
                                      this,
                                      index
                                    )}
                                  />
                                </div> */}
                                {row?.expenses_provide_funds
                                  ?.sort(
                                    (a, b) =>
                                      new Date(b.created) - new Date(a.created)
                                  )
                                  ?.map((row, indexCard) => {
                                    return (
                                      <div
                                        className={STYLE_PARENT_CARD_BALANCE}
                                      >
                                        <div
                                          className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-1 `}
                                        >
                                          ปีละ
                                        </div>
                                        <NumbericValidateRed
                                          value={row?.pay_peryear}
                                          onChange={onChangePayPerYearExpensesProvideFund?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          className={`${STYLE_INPUT_CARD_BALANCE} col-span-4 ml-1`}
                                        />
                                      </div>
                                    );
                                  })}
                              </div>
                            )}

                            {/*----- ค่าใช้จ่ายคงที่อื่น ๆ -----*/}
                            {row?.options_expenses_fixed ===
                              "expenses_fixed_other" && (
                              <div className=" mt-4">
                                <div className=" bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9 ">
                                  <div className=" text-sm text-black font-medium">
                                    จำนวน
                                  </div>
                                  <ButtonCounter
                                    value={row?.expenses_fixed_others?.length}
                                    handleNext={handleNextExpensesFixedOthers.bind(
                                      this,
                                      index
                                    )}
                                  />
                                </div>
                                {row?.expenses_fixed_others
                                  ?.sort(
                                    (a, b) =>
                                      new Date(b.created) - new Date(a.created)
                                  )
                                  ?.map((row, indexCard) => {
                                    return (
                                      <>
                                        <div className=" flex justify-between items-center mt-5">
                                          <div className="grid grid-cols-5 items-center w-full">
                                            <div
                                              className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-1`}
                                            >
                                              หัวข้อ
                                            </div>
                                            <div className="flex items-center gap-1.5 col-span-4">
                                              <input
                                                placeholder={`ระบุ`}
                                                className={`${STYLE_INPUT_CARD_BALANCE} h-5 rounded-[4px] w-full`}
                                                value={row?.title}
                                                onChange={onChangeTitleExpensesFixedOther?.bind(
                                                  this,
                                                  index,
                                                  indexCard
                                                )}
                                              />
                                              <ICON_DELETE
                                                className=" w-6 h-6 mb-1.5 text-maincyan cursor-pointer"
                                                onClick={onClickDeleteCardExpensesFixedOther?.bind(
                                                  this,
                                                  index,
                                                  indexCard
                                                )}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className={STYLE_PARENT_CARD_BALANCE}
                                        >
                                          <div
                                            className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-1 `}
                                          >
                                            เดือนละ
                                          </div>
                                          <NumbericValidateRed
                                            value={row?.pay_permonth}
                                            onChange={onChangePayPerMonthExpensesFixedOther?.bind(
                                              this,
                                              index,
                                              indexCard
                                            )}
                                            className={`${STYLE_INPUT_CARD_BALANCE} col-span-4 ml-1`}
                                          />
                                        </div>
                                        <div className=" flex justify-center text-sm items-center my-3">
                                          จำนวน (ต่อปี){" "}
                                          <span className="font-semibold text-center text-maincyan ml-1">
                                            {numeral(row?.pay_peryear).format(
                                              "0,0"
                                            )}
                                          </span>
                                        </div>
                                      </>
                                    );
                                  })}
                              </div>
                            )}

                            <DeleteButtonCard
                              onClick={() => {
                                // const result = [...addExpensesFixed];
                                // result?.splice(index, 1);
                                // setAddExpensesFixed(result);\
                                setDataIndexDeleteAll(index);
                                setOpenAlert2(true);
                              }}
                            />
                            {addExpensesFixed?.length > 1 &&
                              index !== addExpensesFixed?.length - 1 && (
                                <div className=" border-b border-gray80 pb-3 my-2"></div>
                              )}

                            <PopupMain
                              title={"ยืนยันการลบข้อมูล ค่าใช้จ่ายคงที่"}
                              text={
                                <div className=" text-base">
                                  คุณต้องการลบข้อมูลทั้งหมดนี้ใช่หรือไม่
                                </div>
                              }
                              button2={true}
                              textButton={"ยืนยัน"}
                              textButton2={"ยกเลิก"}
                              open={openAlert2}
                              onClick={() => {
                                const result = [...addExpensesFixed];
                                result?.splice(dataIndexDeleteAll, 1);
                                setAddExpensesFixed(result);
                                setDataIndexDeleteAll(null);
                                setOpenAlert2(false);
                              }}
                              onClickSecond={() => {
                                setDataIndexDeleteAll(null);
                                setOpenAlert2(false);
                              }}
                            />
                          </div>
                        );
                      })}
                  </div>
                }
              />

              {/*------------------------------ ค่าใช้จ่ายแปรผัน ------------------------------*/}
              <CardAccordian
                zIndex="2"
                // onClickOpen={onClickHandleOpenExpensesVariable}
                onClickOpen={() => {
                  handleOpen(2);
                  if (openAccordion === 2) setOpenAccordion(false);
                }}
                open={openAccordion === 2}
                add
                title="ค่าใช้จ่ายแปรผัน"
                textColor="text-red400"
                icon="/images/finance_variable2.png"
                value={formBL?.sum_expenses_variable}
                onClickAccordion={() => {
                  const date = new Date();
                  const created = date.toISOString();
                  const result = [
                    {
                      created: created,
                      options_expenses_variable: "",
                      title: "",
                      title_other: "",
                      pay_permonth: "",
                      pay_peryear: "",
                    },
                  ];
                  setAddExpensesVariable((prev) => {
                    return [...prev, ...result];
                  });
                  handleOpen(2);
                }}
                panel={
                  <div>
                    {addExpensesVariable
                      ?.sort(
                        (a, b) => new Date(b.created) - new Date(a.created)
                      )
                      ?.map((row, index) => {
                        return (
                          <div key={index}>
                            <div className=" pt-3 ">
                              <DropDownMain
                                value={options_expenses_variable?.filter(
                                  (option) => {
                                    if (
                                      option?.value ===
                                      row?.options_expenses_variable
                                    )
                                      return option?.label;
                                  }
                                )}
                                onChange={onChangeSelectedVariable?.bind(
                                  this,
                                  index
                                )}
                                options={options_expenses_variable}
                                zIndex={2}
                              />
                            </div>
                            {/*---- ค่าอาหาร ----*/}
                            {row?.options_expenses_variable ===
                              "expenses_food" && (
                              <CardMonthly
                                valuePayPerYear={row?.pay_peryear}
                                value={row?.pay_permonth}
                                onChange={onChangePayPerMonthVariable?.bind(
                                  this,
                                  index
                                )}
                              />
                            )}
                            {/*---- ค่าอุปโภค ----*/}
                            {row?.options_expenses_variable ===
                              "expenses_consumer" && (
                              <CardMonthly
                                valuePayPerYear={row?.pay_peryear}
                                value={row?.pay_permonth}
                                onChange={onChangePayPerMonthVariable?.bind(
                                  this,
                                  index
                                )}
                              />
                            )}
                            {/*---- ค่าสาธารณูปโภค ----*/}
                            {row?.options_expenses_variable ===
                              "expenses_public_utilities" && (
                              <CardMonthly
                                valuePayPerYear={row?.pay_peryear}
                                value={row?.pay_permonth}
                                onChange={onChangePayPerMonthVariable?.bind(
                                  this,
                                  index
                                )}
                              />
                            )}
                            {/*---- ค่าโทรศัพท์ ----*/}
                            {row?.options_expenses_variable ===
                              "expenses_mobile" && (
                              <CardMonthly
                                valuePayPerYear={row?.pay_peryear}
                                value={row?.pay_permonth}
                                onChange={onChangePayPerMonthVariable?.bind(
                                  this,
                                  index
                                )}
                              />
                            )}
                            {/*---- ค่านันทนาการ ----*/}
                            {row?.options_expenses_variable ===
                              "expenses_recreation" && (
                              <CardMonthly
                                valuePayPerYear={row?.pay_peryear}
                                value={row?.pay_permonth}
                                onChange={onChangePayPerMonthVariable?.bind(
                                  this,
                                  index
                                )}
                              />
                            )}
                            {/*---- ภาษีบุคคลธรรมดา รายปี ----*/}
                            {row?.options_expenses_variable ===
                              "expenses_tax" && (
                              <CardYearly
                                value={row?.pay_peryear}
                                onChange={onChangePayPerYearVariable?.bind(
                                  this,
                                  index
                                )}
                              />
                            )}
                            {/*---- ภาษีดอกเบี้ยเงินฝาก ----*/}
                            {row?.options_expenses_variable ===
                              "expenses_deposit_interest" && (
                              <CardYearly
                                value={row?.pay_peryear}
                                onChange={onChangePayPerYearVariable?.bind(
                                  this,
                                  index
                                )}
                              />
                            )}
                            {/*---- ภาษีอัตราสารหนี้ ----*/}
                            {row?.options_expenses_variable ===
                              "expenses_debt_instruments" && (
                              <CardYearly
                                value={row?.pay_peryear}
                                onChange={onChangePayPerYearVariable?.bind(
                                  this,
                                  index
                                )}
                              />
                            )}
                            {/*---- ภาษีเงินปันผล ----*/}
                            {row?.options_expenses_variable ===
                              "expenses_dividend" && (
                              <CardYearly
                                value={row?.pay_peryear}
                                onChange={onChangePayPerYearVariable?.bind(
                                  this,
                                  index
                                )}
                              />
                            )}
                            {/*---- ภาษีรถยนต์ ----*/}
                            {row?.options_expenses_variable ===
                              "expenses_tax_car" && (
                              <CardYearly
                                value={row?.pay_peryear}
                                onChange={onChangePayPerYearVariable?.bind(
                                  this,
                                  index
                                )}
                              />
                            )}
                            {/*---- ภาษีอื่น ๆ ----*/}
                            {row?.options_expenses_variable ===
                              "expenses_tax_other" && (
                              <div>
                                <div className=" text-black text-sm flex-none mt-2.5 mb-1">
                                  โปรดระบุ
                                </div>
                                <input
                                  className={STYLE_INPUT}
                                  placeholder="ระบุ"
                                  value={row?.title_other}
                                  onChange={onChangeTitleOtherVariable?.bind(
                                    this,
                                    index
                                  )}
                                />
                                <CardYearly
                                  value={row?.pay_peryear}
                                  onChange={onChangePayPerYearVariable?.bind(
                                    this,
                                    index
                                  )}
                                />
                              </div>
                            )}
                            {/*---- ค่าน้ำมัน ----*/}
                            {row?.options_expenses_variable ===
                              "expenses_gasoline" && (
                              <CardMonthly
                                valuePayPerYear={row?.pay_peryear}
                                value={row?.pay_permonth}
                                onChange={onChangePayPerMonthVariable?.bind(
                                  this,
                                  index
                                )}
                              />
                            )}
                            {/*---- ค่าดูแลรักษารถยนต์ ----*/}
                            {row?.options_expenses_variable ===
                              "expenses_car_maintenance" && (
                              <CardMonthly
                                valuePayPerYear={row?.pay_peryear}
                                value={row?.pay_permonth}
                                onChange={onChangePayPerMonthVariable?.bind(
                                  this,
                                  index
                                )}
                              />
                            )}
                            {/*---- ค่าโดยสารรถสาธารณะ ----*/}
                            {row?.options_expenses_variable ===
                              "expenses_transport" && (
                              <CardMonthly
                                valuePayPerYear={row?.pay_peryear}
                                value={row?.pay_permonth}
                                onChange={onChangePayPerMonthVariable?.bind(
                                  this,
                                  index
                                )}
                              />
                            )}
                            {/*---- ค่าแม่บ้าน ----*/}
                            {row?.options_expenses_variable ===
                              "expenses_maid" && (
                              <CardMonthly
                                valuePayPerYear={row?.pay_peryear}
                                value={row?.pay_permonth}
                                onChange={onChangePayPerMonthVariable?.bind(
                                  this,
                                  index
                                )}
                              />
                            )}
                            {/*---- ค่ารักษาพยาบาล ----*/}
                            {row?.options_expenses_variable ===
                              "expenses_medical" && (
                              <CardMonthly
                                valuePayPerYear={row?.pay_peryear}
                                value={row?.pay_permonth}
                                onChange={onChangePayPerMonthVariable?.bind(
                                  this,
                                  index
                                )}
                              />
                            )}
                            {/*---- บริจาค ----*/}
                            {row?.options_expenses_variable ===
                              "expenses_donate" && (
                              <CardMonthly
                                valuePayPerYear={row?.pay_peryear}
                                value={row?.pay_permonth}
                                onChange={onChangePayPerMonthVariable?.bind(
                                  this,
                                  index
                                )}
                              />
                            )}
                            {/*---- ค่าใช้จ่ายอื่น ๆ ----*/}
                            {row?.options_expenses_variable ===
                              "expenses_variable_other" && (
                              <div>
                                <div className=" text-black text-sm flex-none  mt-2.5 mb-1 ">
                                  โปรดระบุ
                                </div>
                                <input
                                  className={STYLE_INPUT}
                                  placeholder="ระบุ"
                                  value={row?.title_other}
                                  onChange={onChangeTitleOtherVariable?.bind(
                                    this,
                                    index
                                  )}
                                />
                                <CardMonthly
                                  valuePayPerYear={row?.pay_peryear}
                                  value={row?.pay_permonth}
                                  onChange={onChangePayPerMonthVariable?.bind(
                                    this,
                                    index
                                  )}
                                />
                              </div>
                            )}
                            <DeleteButtonCard
                              onClick={() => {
                                // const result = [...addExpensesVariable];
                                // result.splice(index, 1);
                                // setAddExpensesVariable(result);
                                setDataIndexDeleteAll(index);
                                setOpenAlert2(true);
                              }}
                            />

                            <PopupMain
                              title={"ยืนยันการลบข้อมูลทั้งหมด"}
                              text={
                                <div className=" text-base">
                                  คุณต้องการลบข้อมูลทั้งหมดนี้ใช่หรือไม่
                                </div>
                              }
                              button2={true}
                              textButton={"ยืนยัน"}
                              textButton2={"ยกเลิก"}
                              open={openAlert2}
                              onClick={() => {
                                const result = [...addExpensesVariable];
                                result.splice(dataIndexDeleteAll, 1);
                                setAddExpensesVariable(result);
                                setOpenAlert2(false);
                              }}
                              onClickSecond={() => {
                                setOpenAlert2(false);
                              }}
                            />

                            {addExpensesVariable?.length > 1 &&
                              index !== addExpensesVariable?.length - 1 && (
                                <div className=" border-b border-gray80 pb-3 my-2"></div>
                              )}
                          </div>
                        );
                      })}
                  </div>
                }
              />

              {/*------------------------------ ค่าใช้จ่ายเพื่อออม/ลงทุน ------------------------------*/}
              <CardAccordian
                zIndex="1"
                onClickOpen={() => {
                  handleOpen(3);
                  if (openAccordion === 3) setOpenAccordion(false);
                }}
                open={openAccordion === 3}
                add
                title="ค่าใช้จ่ายเพื่อออม/ลงทุน"
                textColor="text-maingreen"
                icon="/images/finance_invest2.png"
                value={formBL?.sum_expenses_invest_saving}
                onClickAccordion={() => {
                  const date = new Date();
                  const created = date.toISOString();
                  const result = [
                    {
                      created: created,
                      options_expenses_invest: "",
                      title: "",
                      title_service_provider: "",
                      title_account: "",
                      title_other: "",
                      pay_permonth: "",
                      pay_peryear: "",
                    },
                  ];
                  setAddExpensesInvest((prev) => {
                    return [...prev, ...result];
                  });
                  handleOpen(3);
                }}
                panel={
                  <div>
                    {formBL?.temp_net_cash_flow > 0 && (
                      <div className="mb-1.5">
                        <div className=" border-b border-gray60 pb-3 mb-4"></div>
                        <div className="flex flex-col gap-2.5">
                          <div className={`text-mainblue text-sm font-medium`}>
                            ค่าใช้จ่ายเพื่อออม/ลงทุนเพิ่มเติม
                          </div>
                          <div className="flex justify-between items-center">
                            <div
                              className={`text-mainblue text-[13px] font-medium`}
                            >
                              (จากรายได้หักรายจ่าย)
                            </div>
                            <div className=" flex justify-center gap-2 text-sm items-center">
                              <span>ปีละ</span>
                              <div className="font-semibold text-center text-maincyan">
                                {numeral(Number(formBL?.temp_net_cash_flow)).format(
                                  "0,0"
                                )}
                              </div>
                              <div>บาท</div>
                            </div>
                          </div>
                        </div>
                        <DeleteButtonCard
                          onClick={() => {
                            setOpenAlert2(true);
                          }}
                        />
                        <PopupMain
                          title={"ยืนยันการลบข้อมูลทั้งหมด"}
                          text={
                            <div className=" text-base">
                              คุณต้องการลบข้อมูลทั้งหมดนี้ใช่หรือไม่
                            </div>
                          }
                          button2={true}
                          textButton={"ยืนยัน"}
                          textButton2={"ยกเลิก"}
                          open={openAlert2}
                          onClick={onClickDeleteNetCashFlow}
                          onClickSecond={() => {
                            setOpenAlert2(false);
                          }}
                        />

                        <div className=" border-b border-gray60 pb-3 my-2"></div>
                      </div>
                    )}
                    {addExpensesInvest
                      ?.sort(
                        (a, b) => new Date(b.created) - new Date(a.created)
                      )
                      ?.map((row, index) => {
                        return (
                          <div key={index}>
                            <div className=" pt-3 ">
                              <DropDownMain
                                value={options_expenses_invest?.filter(
                                  (option) => {
                                    if (
                                      option?.value ===
                                      row?.options_expenses_invest
                                    )
                                      return option?.label;
                                  }
                                )}
                                onChange={onChangeSelectedInvest?.bind(
                                  this,
                                  index
                                )}
                                options={options_expenses_invest}
                                zIndex={1}
                              />
                            </div>
                            {/*---- เงินลงทุน ----*/}
                            {row?.options_expenses_invest ===
                              "expenses_invest" && (
                              <CardExpensesInvest
                                valuePayPerYear={row?.pay_peryear}
                                valueTitleServiceProvider={
                                  row?.title_service_provider
                                }
                                onChangeTitleSertviceProvider={onChangeTitleSertviceProviderInvest?.bind(
                                  this,
                                  index
                                )}
                                valueTitleAccount={row?.title_account}
                                onChangeTitleAccount={onChangeTitleAccountInvest?.bind(
                                  this,
                                  index
                                )}
                                value={row?.pay_permonth}
                                onChange={onChangePayPerMonthInvest?.bind(
                                  this,
                                  index
                                )}
                              />
                            )}
                            {/*---- เงินออมวัยเกษียณ ----*/}
                            {row?.options_expenses_invest ===
                              "expenses_conservation" && (
                              <CardExpensesInvest
                                valuePayPerYear={row?.pay_peryear}
                                valueTitleServiceProvider={
                                  row?.title_service_provider
                                }
                                onChangeTitleSertviceProvider={onChangeTitleSertviceProviderInvest?.bind(
                                  this,
                                  index
                                )}
                                valueTitleAccount={row?.title_account}
                                onChangeTitleAccount={onChangeTitleAccountInvest?.bind(
                                  this,
                                  index
                                )}
                                value={row?.pay_permonth}
                                onChange={onChangePayPerMonthInvest?.bind(
                                  this,
                                  index
                                )}
                              />
                            )}
                            {/*---- เงินออมกองทุน RMF ----*/}
                            {row?.options_expenses_invest ===
                              "expenses_rmf" && (
                              <CardExpensesInvest
                                valuePayPerYear={row?.pay_peryear}
                                valueTitleServiceProvider={
                                  row?.title_service_provider
                                }
                                onChangeTitleSertviceProvider={onChangeTitleSertviceProviderInvest?.bind(
                                  this,
                                  index
                                )}
                                valueTitleAccount={row?.title_account}
                                onChangeTitleAccount={onChangeTitleAccountInvest?.bind(
                                  this,
                                  index
                                )}
                                value={row?.pay_permonth}
                                onChange={onChangePayPerMonthInvest?.bind(
                                  this,
                                  index
                                )}
                              />
                            )}
                            {/*---- อื่น ๆ ----*/}
                            {row?.options_expenses_invest ===
                              "expenses_other" && (
                              <CardExpensesInvest
                                etc={true}
                                valuePayPerYear={row?.pay_peryear}
                                valueTitleServiceProvider={
                                  row?.title_service_provider
                                }
                                onChangeTitleSertviceProvider={onChangeTitleSertviceProviderInvest?.bind(
                                  this,
                                  index
                                )}
                                valueTitleAccount={row?.title_account}
                                onChangeTitleAccount={onChangeTitleAccountInvest?.bind(
                                  this,
                                  index
                                )}
                                value={row?.pay_permonth}
                                onChange={onChangePayPerMonthInvest?.bind(
                                  this,
                                  index
                                )}
                                valueEtc={row?.title_other}
                                onChangeEtc={onChangeTitleOtherInvest?.bind(
                                  this,
                                  index
                                )}
                              />
                            )}

                            <DeleteButtonCard
                              onClick={() => {
                                // const result = [...addExpensesInvest];
                                // result.splice(index, 1);
                                // setAddExpensesInvest(result);
                                setDataIndexDeleteAll(index);
                                setOpenAlert2(true);
                              }}
                            />
                            <PopupMain
                              title={"ยืนยันการลบข้อมูลทั้งหมด"}
                              text={
                                <div className=" text-base">
                                  คุณต้องการลบข้อมูลทั้งหมดนี้ใช่หรือไม่
                                </div>
                              }
                              button2={true}
                              textButton={"ยืนยัน"}
                              textButton2={"ยกเลิก"}
                              open={openAlert2}
                              onClick={() => {
                                const result = [...addExpensesInvest];
                                result.splice(dataIndexDeleteAll, 1);
                                setAddExpensesInvest(result);
                                setDataIndexDeleteAll(null);
                                setOpenAlert2(false);
                              }}
                              onClickSecond={() => {
                                setDataIndexDeleteAll(null);
                                setOpenAlert2(false);
                              }}
                            />
                            {addExpensesInvest?.length > 1 &&
                              index !== addExpensesInvest?.length - 1 && (
                                <div className=" border-b border-gray80 pb-3 my-2"></div>
                              )}
                          </div>
                        );
                      })}
                  </div>
                }
              />
            </div>

            <div className="flex justify-center mb-6">
              <MTButton
                text="บันทึก"
                // loading={loading}
                onClick={onClickSubmit}
              />
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
};
