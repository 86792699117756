import numeral from "numeral";

export const TableReport = ({
  data = [],
  headTable = "แหล่งที่มาของทรัพย์สิน",
  isHeadMonth = true,
  isHeadYear = true,
  isBath = true,
  isBorder,
}) => {
  return (
    <div>
      {/* Head  */}
      <div className=" grid grid-cols-8 justify-center font-semibold text-sm mt-2.5">
        <div className=" col-span-4">{headTable}</div>
        <div className=" col-span-2 text-right">
          {" "}
          {isHeadMonth ? "ต่อเดือน" : ""}
        </div>
        <div className={`col-span-2 text-right `}>
          {" "}
          {isHeadYear ? "ต่อปี" : ""}{" "}
        </div>
      </div>
      <div className=" border-b border-mainyellow mt-2.5 "></div>
      {data?.map((item, index) => {
        return (
          <div
            key={index}
            className={`  grid grid-cols-7  justify-center font-semibold text-sm  `}
          >
            {item?.head && (
              <div className=" col-span-full flex items-center gap-[5px] my-2.5 ">
                <img src={item.icon} alt="" className={`w-6 h-fit`} />
                <div className=" font-semibold ">{item?.head}</div>
              </div>
            )}
            <div
              className={`col-span-full bg-[#F9F9F9] px-2 py-1 ${
                index <= index / 2 ? "rounded-t-[10px] " : ""
              }  ${index === data.length - 1 ? "rounded-b-[10px]" : ""} 
             `}
            >
              <div
                className={`grid grid-cols-8  justify-center font-semibold text-sm  `}
              >
                <div className={`col-span-4 `}>
                  <div>{item.source}</div>
                  {item?.source_subtitle && (
                    <div className=" text-mainblue">
                      {item?.source_subtitle}
                    </div>
                  )}
                  {item?.source_2 && (
                    <div className=" font-normal">{item?.source_2}</div>
                  )}
                </div>
                <div
                  className={` ${
                    isHeadMonth ? "col-span-2" : "col-span-1"
                  }  text-right text-mainblue `}
                >
                  {" "}
                  {item.monthly}
                </div>
                <div
                  className={`${
                    isHeadMonth ? "col-span-2" : "col-span-3"
                  }  text-right text-mainblue`}
                >
                  {item.yearly ? (
                    <div> {numeral(item.yearly)?.format("0,0")} บาท </div>
                  ) : (
                    item?.percent && <div>{item?.percent} % </div>
                  )}
                </div>
                {item?.results && (
                  <div className=" col-span-full flex justify-between text-base mt-3">
                    <div className=" font-semibold ">รวม</div>
                    <div className="text-right text-mainblue text-base">
                      <span className=" text-maincyan">{item?.results}</span>{" "}
                      {isBath ? "บาท" : "%"}
                    </div>
                  </div>
                )}
                {item?.time && (
                  <div className=" col-span-full flex justify-between">
                    <div className="font-normal">ระยะเวลา</div>
                    <div className="text-right text-mainblue text-base">
                      <span className="">{item?.time} งวด</span>{" "}
                      <span className="">{item?.des_time} </span>{" "}
                    </div>
                  </div>
                )}

                {item?.border && (
                  <div className="border-b border-gray55 my-1 w-full col-span-full"></div>
                )}
              </div>
            </div>
            {item?.all_results && (
              <div className=" col-span-full ">
                <div className="flex justify-between my-2.5">
                  <div className=" font-medium">{item?.title_all_results}</div>
                  <div className=" text-mainblue text-base font-bold">
                    {" "}
                    {item?.all_results} บาท
                  </div>
                </div>

                {item?.all_results_2 && (
                  <div className="flex justify-between my-2.5">
                    <div className=" font-medium">
                      {item?.title_all_results_2}
                    </div>
                    <div className=" text-mainblue text-base font-bold">
                      {" "}
                      {item?.all_results_2} บาท
                    </div>
                  </div>
                )}

                {item?.all_results_3 && (
                  <div className="flex justify-between my-2.5">
                    <div className=" font-medium">
                      {item?.title_all_results_3}
                    </div>
                    <div className=" text-mainblue text-base font-bold">
                      {" "}
                      {item?.all_results_3} บาท
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export const HeaderTableReport = ({
  title = "แหล่งที่มาของทรัพย์สิน",
  monthly = "ต่อเดือน",
  yearly = "ต่อปี",
  textjusify = "text-right",
}) => {
  return (
    <>
      <div className=" grid grid-cols-8 justify-center font-semibold text-sm mt-2.5">
        <div className=" col-span-4">{title}</div>
        <div className={` col-span-2 ${textjusify} `}> {monthly}</div>
        <div className={`col-span-2 ${textjusify} `}> {yearly}</div>
      </div>
      <div className=" border-b border-mainyellow mt-2.5 "></div>
    </>
  );
};

export const HeaderTitleTableReport = ({
  img = "/images/balance.svg",
  title = "งบดุล",
}) => {
  return (
    <div className="flex items-center gap-[5px] my-2.5 ">
      <img src={img} alt="" className={`w-6 h-fit`} />
      <div className=" font-semibold text-sm">{title}</div>
    </div>
  );
};
