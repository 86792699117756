import { ImageMain } from "../../../components/img/Img.Main";

export const CardMenuinsuarance = ({ title, img, onClick }) => {
  return (
    <div
      onClick={onClick}
      className=" bg-white border border-maincyan rounded-[10px] p-2.5 hover:bg-maincyan duration-300 group cursor-pointer"
    >
      <div className=" flex flex-col justify-center items-center gap-2.5 ">
        <ImageMain src={img} className=" w-[50px] h-[50px] " />
        <div className=" text-black group-hover:text-white font-semibold text-sm  ">
          {title}
        </div>
      </div>
    </div>
  );
};
