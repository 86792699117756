//parent
export const STYLE_MARGIN_TOP_PARENT = " pt-[14px] font-IBMPlexSansThai ";
export const STYLE_INPUT =
  " rounded-full px-[14px] py-2 placeholder:text-gray80 text-mainblue text-sm font-semibold w-full focus:outline-none focus:ring-1 focus:ring-mainblue ";
export const STYLE_INPUT_BORDER =
  "rounded-full px-[14px] py-2 bg-gray30 border border-gray60 placeholder:text-gray80 text-mainblue text-sm font-semibold w-full focus:outline-none focus:ring-1 focus:ring-mainblue";
export const STYLE_PARENT_TWO_BUTTON =
  "grid grid-cols-2 md:flex justify-center gap-2 my-6";
export const STYLE_BG_CIRCLE = "h-full  pb-6 font-IBMPlexSansThai";
export const STYLE_PADDING_MAIN = "px-6 mt-5";
export const STYLE_SPACE_GRAY_MENU = "flex flex-col space-y-5 ";

//Navbar
export const STYLE_SIDEBAR_NAVBAR =
  "text-mainblue hover:text-maincyan duration-100 text-lg font-bold font-IBMPlexSansThai cursor-pointer";

//Accordion
export const STYLE_BF_ACCORDION = "border-b-0";
export const STYLE_AF_ACCORDION =
  "border-b border-b-gray55  transition-colors text-xs font-IBMPlexSansThai antialiased w-full pr-5";
export const STYLE_PARENT_HEAD_AC =
  "flex justify-between items-center w-full font-IBMPlexSansThai";
export const STYLE_HEAD_AC =
  "text-mainblue text-sm font-medium flex gap-2 items-center pl-5";
export const STYLE_HEAD_GRAY =
  "text-maingray text-sm font-medium flex gap-2 items-center pl-5";
export const STYLE_BORDER_YELLOW_AC =
  "border-b border-mainyellow w-[119px] mt-1";
export const STYLE_ACCORDION_BODY_TAX =
  "flex justify-center items-center gap-2.5 text-black font-medium";
export const STYLE_ACCORDION_BODY_TAX_2 =
  "text-xs mt-2 flex justify-center gap-2 items-center";
export const STYLE_ACCORDION_BODY_TAX_3 =
  "mb-1 text-center text-mainblue font-medium  md:text-lg text-md mt-4";
export const STYLE_INPUT_ERROR =
  "rounded-full px-[14px] py-2 placeholder:text-gray80 text-mainred text-sm font-semibold w-full focus:outline-none focus:ring-1 focus:ring-red-500 border border-red-500";
export const STYLE_INPUT_INSET_ERROR =
  "  px-[14px] py-2  text-red-500  md:text-lg text-sm font-semibold focus:outline-none  border border-red-500  focus:ring-1 focus:ring-mainred md:w-40  w-28 h-8 rounded-[8px] `";
export const STYLE_INPUT_INSET =
  " px-[14px] py-2 placeholder:text-gray80 text-mainblue  md:text-lg text-sm font-semibold focus:outline-none focus:ring-1 focus:ring-mainblue md:w-40  w-28 h-8 rounded-[8px] border border-gray60 ";
export const STYLE_INPUT_CARD_BALANCE =
  "px-2.5 py-1 placeholder:text-gray80 placeholder:font-normal placeholder:text-xs text-mainblue  md:text-lg text-xs font-semibold focus:outline-none focus:ring-1 focus:ring-mainblue  h-8 rounded-full border border-gray60";

export const STYLE_TEXTAREA_CARD_BALANCE =
  "px-2.5 py-2 placeholder:text-gray80 placeholder:font-normal placeholder:text-xs text-mainblue  md:text-lg text-xs font-semibold focus:outline-none focus:ring-1 focus:ring-mainblue  h-10 rounded-[5px] border border-gray60";

export const STYLE_INPUT_DISALE =
  "rounded-full px-[14px] py-2 bg-[#EAEAEA] w-full h-8 text-mainblue text-sm font-normal ";
export const STYLE_SPACE_Y = "space-y-5 mb-6";
export const STYLE_FLEX_STATUS =
  "flex gap-[10px] items-center font-IBMPlexSansThai text-maingray";
export const STYLE_HEAD_STRAGADY =
  "text-mainblue font-semibold text-md font-IBMPlexSansThai pl-[18px]";
export const STYLE_INPUT_SLIDER =
  " input-number w-12 text-center text-mainblue font-semibold focus:outline-none focus:ring-2 focus:ring-mainblue";
export const STYLE_INPUT_SLIDER_MAX =
  " px-1 py-[7px] text-mainblue placeholder:text-bluegray50 font-semibold text-sm font-IBMPlexSansThai shadowinner rounded-md text-center focus:outline-none  focus:ring-2 focus:ring-mainblue";

//card
export const STYLE_CARD_BG =
  "bg-gray50 rounded-[10px] py-[14px] font-IBMPlexSansThai";
export const STYLE_CARD_STRAGADY =
  "bg-white  border border-gray60 rounded-[10px] font-IBMPlexSansThai ";
export const STYLE_PARENT_CARD_BALANCE = "grid grid-cols-5 items-center mt-2";

//tab
export const STYLE_TAB = "mt-[19px]  font-IBMPlexSansThai";
export const STYLE_TAB_HEADER_CLASSNAME =
  "rounded-[10px]  bg-gray30 z-10 p-[2px] overflow-visible xxs:gap-1 gap-[9px]";
export const STYLE_TAB_HEDER_PROPS =
  " bg-white shadowtab text-secondcyan rounded-[10px]  px-[22px] py-1 ";
export const STYLE_TAB_ACTIVE =
  "font-semibold font-IBMPlexSansThai  xxs:text-sm sm:text-md w-full";
export const STYLE_TAB_INACTIVE =
  "font-semibold font-IBMPlexSansThai text-gray100 text-md md:text-lg ";
export const STYLE_TAB_PARENT =
  "flex justify-center items-center leading-6 pt-1 text-gray100";
export const STYLE_TAB_PANEL = "px-5 mt-10";
export const STYLE_TAB_PANEL_2 = "px-5 mt-[34px]";

//stepper
export const STYLE_ACTIVE_STEPPER = "text-maincyan font-semibold";
export const STYLE_INACTIVE_STEPPER = "font-medium text-gray-600";
export const STYLE_PARENT_STEPPER =
  "absolute -bottom-[25px]  w-max text-center font-IBMPlexSansThai text-sm ";

//drawer
export const STYLE_TITLE_DRAWER =
  " text-2xl sm:text-3xl font-IBMPlexSansThai font-semibold text-mainblue text-center";
export const STYLE_IMG_DRAWER = "w-[100px] h-[103px] -mb-[10px] mt-2";
export const STYLE_TITLE_DISPLAY =
  "text-xl font-IBMPlexSansThai font-semibold text-mainblue text-center";
export const STYLE_ICON_CLOSE_DW = "ml-auto mt-5 mr-5";
export const STYLE_MAX_W_DRAWER = " w-full md:max-w-2xl md:mx-auto";
export const STYLE_BG_BLUE_DRAWER = "w-full absolute bottom-0 -z-10";
export const STYLE_BG_BLUE_DRAWER_2 = "w-full absolute -bottom-20 -z-10";
export const STYLE_TITE_DRAWER_INFO = "font-semibold text-black";

//home
export const STYLE_HEAD_TITLE =
  "text-xl font-semibold font-IBMPlexSansThai text-mainblue";

//about us
export const STYTLE_ABOUT_US = "grid grid-cols-2 gap-2.5 items-center";
export const STYLE_ABOUT_US_DES =
  "text-mainblue font-semibold text-[10px] md:text-base text-center";
export const STYLE_ABOUST_US_ICON = "w-16 h-[35px] md:w-24 md:h-fit mx-auto";

//balance
export const STYLE_CARD_BALANCE =
  "bg-white p-4 rounded-[10px] mx-5 flex space-x-[18px] items-center mb-[14px]";
export const STYLE_IMG_BALANCE = " w-8 h-8 ";
export const STYLE_TITLE_BALANCE = "text-black";
export const STYLE_VALUE_BALANCE = "text-gray90 text-xl font-semibold";
export const STYLE_CARD_BALANCE_2 =
  "px-[18px] py-3 rounded-[10px] flex gap-4 items-center mb-[14px] bg-gray50";
export const STYLE_BUTTON_DELETE =
  "flex items-center justify-center rounded-[10px] w-14 h-[24px] bg-white text-black mx-auto mt-3";
export const STYLE_TITLE_WITH_INFO = "flex gap-[6px]";
export const STYLE_CARD_ACCORDION =
  "px-[18px] py-3 rounded-[10px] mb-[14px] bg-gray50 font-IBMPlexSansThai transform transition duration-500 ease-in-out";
export const STYLE_ACCORDION_HEADER =
  "border-b border-b-gray55  font-IBMPlexSansThai antialiased w-full text-md font-normal py-2 transition transition-[max-height]";
export const STYLE_ICON_PLUS =
  "w-6 text-maincyan transform transition duration-500 ease-in-out";
export const STYLE_ACCORDION_BODY =
  "transition-[max-height] duration-300 ease-in-out  transform antialiased";
export const STYLE_TITLE_CARD_DRAWER_BL =
  "text-[13px] text-mainblue font-medium";
export const STYLE_CARD_PROPOTION = "bg-gray50 rounded-[20px] h-full pb-6";
export const STYLE_HEADER_SECTION_PROPOTION =
  "pt-4 flex justify-between items-center pr-4 pb-1";
export const STYLE_TITLE_PROPOTION = "text-black text-md font-medium pl-5";
export const STYLE_BORDER_YELLOW_PROPOTION =
  "border-b border-mainyellow w-[119px] mt-1";
export const STYLE_WIDTH_DONUT_BALANCE = " w-[310px] mx-auto pb-6 relative";
export const STYLE_TEXT_CENTER_DONUT_BALANCE =
  "absolute top-[55%] left-1/2 -translate-x-1/2 -translate-y-1/2 text-xl text-black font-IBMPlexSansThai text-center";
export const STYLE_VALUE_CENTER_DONUT_BALANCE =
  "text-center text-xl font-semibold text-maincyan";
export const STYLE_TITLE_ACCORDION_BALANCE = "text-black text-md";
export const STYLE_VALUE_ACCORDION_BALANCE =
  "text-sm text-black flex gap-2.5 items-center";
export const STYLE_INPUT_PROPERTY_PROPORTION =
  "border border-maincyan px-[14px] py-2 placeholder:text-gray80 text-mainblue text-xs font-light focus:outline-none focus:ring-1 focus:ring-mainblue h-[22px] w-full rounded-[5px]";
export const STYLE_PARENT_MENU_HOME = "grid grid-cols-2 gap-[15px]";
export const PARENT_INPUT_BALANCE_CARD = "mt-2.5 flex gap-2 items-center";
export const STYLE_PADDING_BALANCE_DRAWER = " px-6 mt-5 ";
export const STYLE_TITLE_FORM_BALANCE = " text-mainblue text-xs font-medium ";
export const STYLE_TITLE_SUM_NETFLOW =
  "font-IBMPlexSansThai font-semibold text-mainblue text-lg flex gap-2 items-center mb-2";
export const STYLE_SPACE_SUM_NETFLOW =
  "space-y-5 border-b border-mainyellow pb-5";
export const STYLE_INFODRAWER_ETC = "text-sm space-y-2 ";
export const STYLE_BOXCAL_ETC =
  "text-center text-mainblue text-sm p-2 bg-[#fcfcfc] rounded-2xl relative";

export const STYLE_BODY_INVEST = " grid grid-cols-3 gap-2 mt-1";
export const STYLE_INPUT_INVEST = "w-full h-9";

//DonutChart
export const STYLE_TITLE_DONUTCHART = "text-gray400 text-xs";

//legacy
export const STYLE_HEADER_LEGACY =
  "text-center text-xl text-maincyan font-semibold";

//tax
export const STYLE_PARENT_BUTTON_DRAWER =
  "flex justify-center gap-2 items-center pt-10 w-full";
export const STYLE_GRAY_DRAWER_TAB =
  "animate-fade-up bg-grayopacity/40 w-full h-20 mb-auto flex flex-col justify-center items-center p-[14px]";
export const STYLE_MAX_W_DRAWER_TAX = `${STYLE_MAX_W_DRAWER} md:mt-32 mt-16 px-6 `;
export const STYLE_ACCORDION_SUBTITLE = " text-maingray font-medium";
export const STYLE_NUMBER_PLAN_BAR = "text-mainblue text-xs font-semibold";
export const STYLE_DOK_JAN =
  "text-xs px-3 mt-3 text-mainblue font-medium leading-relaxed";
export const STYLE_PARENT_BUTTON_DRAWER_2 = "space-y-5 py-10";
export const STYLE_PARENT_BUTTON_DRAWER_3 =
  "space-y-5 absolute w-full bottom-20";
export const STYLE_BOX_DONATE =
  "animate animate-fade-up flex justify-center text-center text-mainblue bg-white border border-gray-300 p-2 rounded-md mt-5";
export const STYLE_BG_WHITE_CARD_TAXINCOME =
  "bg-white rounded-[20px] px-4 pt-3 pb-[14px] mt-4";
export const STYLE_TABLE_TAX =
  " bg-[#F9F9F9] w-[180px] text-mainblue  flex-none border-b border-mainblue font-bold text-sm text-center border-r border-r-gray60 h-full flex justify-center items-center px-3 ";
export const STYLE_BODY_TAX =
  " bg-white whitespace-nowrap  flex-none w-[180px] text-sm text-center border-r border-r-gray60 h-[60px] flex justify-center items-center px-3 border-b border-b-gray60";
export const STYLE_BORDER_BODY_TAX = " flex ";

//tax premium
export const STYLE_SPACE_INPUT = "space-y-[2px]";
export const STYLE_SPACE_TAX_PROFILE = "space-y-[5px]";
export const STYLE_LABEL_RADIO_TAX_PROFILE = "text-base mt-1 ml-1 text-black";
export const STYLE_PARENT_PADDING = " px-8 mt-4 mb-10 space-y-5 ";
export const STYLE_PARENT_BUTTON_TWO =
  "flex justify-between gap-2.5 w-full mt-[26px] mb-[38px]";

//retire
export const STYLE_INCOME_STRATEGY =
  "font-semibold text-mainblue text-md md:text-lg";
export const STYLE_INPUT_STG =
  "border-b-2 border-t-0 border-x-0 focus:outline-none focus:ring-0 focus:border-b-gray-200 w-full border-b-gray-300  placeholder:font-normal font-semibold text-mainblue text-md md:text-lg";
export const STYLE_PARENT_STG = " mt-3 relative";
export const STYLE_BATH_STG =
  "absolute top-0 right-0 text-maingray font-medium";
export const STYLE_TEXT_BLUE = "text-mainblue font-semibold";
export const STYLE_TEXT_CYAN = "text-maincyan font-semibold";
export const STYLE_WHITE_TABLE_RETIRE =
  "whitespace-nowrap h-14 flex justify-center items-center  bg-white border-b border-gray-200 border-r";

//navbar
export const STYLE_ICON_LOGIN =
  " text-maincyan w-6 h-6 hover:text-secondcyan  cursor-pointer duration-300";
export const STYLE_ICON_LOGOUT = " w-6 h-6 text-mainred cursor-pointer";

//invest

// education
export const STYLE_TEXT_BLUE_EDU = "text-mainblue text-sm font-medium";
export const STYLE_TABLE_EDU =
  " bg-[#F9F9F9] w-[128px] flex-none text-mainblue  border-b border-mainblue font-bold text-sm text-center border-r border-r-gray60 h-full flex justify-center items-center px-3 ";
export const STYLE_BODY_EDU =
  " bg-white whitespace-nowrap  flex-none w-[128px] text-sm text-center border-r border-r-gray60 h-[60px] flex justify-center items-center px-3 border-b border-b-gray60";
export const STYLE_TABLE_EDU_FUND =
  " bg-[#F9F9F9]  flex-none text-mainblue  border-b border-mainblue font-bold text-sm text-center border-r border-r-gray60 h-full flex justify-center items-center px-3 ";
export const STYLE_BODY_EDU_FUND =
  " bg-white whitespace-nowrap  flex-none  text-sm text-center border-r border-r-gray60 h-[56px] flex justify-center items-center px-3 border-b border-b-gray60";

//debt
export const STYLE_FLEX_DEBT_TITLE = "flex justify-between text-xs";
export const STYLE_TITLE_DEBT_CARD = "text-maingray";
export const STYLE_TOTAL_DEBT_CARD = "text-maincyan font-semibold";
export const STYLE_TITLE_TABLE_DEBT =
  "text-sm text-center font-medium text-mainblue h-10";
export const STYLE_BODY_TABLE_DEBT =
  " whitespace-nowrap h-10 flex justify-center items-center font-normal  bg-white border-b border-gray-200 border-r";
export const STYLE_PARENT_TABLE_OVERSCROLL =
  "px-6 rounded-[20px] h-[600px] overflow-auto   mt-[14px]";

//future
export const STYLE_LABEL_FUTURE = "text-black font-semibold mb-1";

//report
export const STYLE_REPORT_CONTAIN = "px-[25px] mt-5 mb-[30px]";
export const STYLE_BODY_REPORT =
  "bg-[#F9F9F9] px-2 py-1 rounded-[10px] space-y-2";
export const STYLE_BODY_DETAILS = "grid grid-cols-8  justify-center text-sm";
