import numeral from "numeral";
import { ImageMain } from "../../../components/img/Img.Main";
import {
  ICON_NAVIGATE_RIGHT,
  ICON_RIGHT,
  ICON_RIGHT_2,
} from "../../../utils/useIcons";
import { STYLE_TEXT_BLUE } from "../../../utils/useStyle";
import { cn } from "../../../utils/useFunctions";
import classNames from "classnames";

export const CardMenuReport = ({ title, onClick, icon }) => {
  return (
    <div
      onClick={onClick}
      className=" flex justify-between items-center border-b border-gray55 py-[18px] cursor-pointer"
    >
      <div className=" flex items-center gap-3">
        <div className=" bg-mainblue/5 rounded-full w-[46px] h-[46px] flex justify-center items-center">
          <ImageMain src={icon} className={` w-[30px] h-[30px]  `} />
        </div>
        <div className={STYLE_TEXT_BLUE}>{title}</div>
      </div>
      <div>
        <ICON_RIGHT className={" text-gray900 w-6 h-6 "} />
      </div>
    </div>
  );
};

export const CardReport = ({
  title,
  onClick,
  icon,
  panel,
  icon_head,
  className,
  classNameHead,
}) => {
  return (
    <div
      onClick={onClick}
      className={cn(" rounded-[20px] overflow-hidden", classNameHead)}
    >
      <div
        className={cn(
          "flex items-center gap-2.5 px-3 py-2 bg-gray50 text-black  rounded-b-none",
          className
        )}
      >
        {icon_head && <img src={icon_head} alt="" className=" h-6 w-6" />}
        <div className=" font-semibold text-sm ">{title}</div>
      </div>
      <div
        className={cn(
          "bg-[#fcfcfc] rounded-t-none px-3 py-2 text-base w-full h-full space-y-1",
          className
        )}
      >
        {icon && (
          <div className=" flex justify-center mb-[17px]">
            <img src={icon} alt="" className=" h-[52px] " />
          </div>
        )}
        <div className=" text-center text-mainblue ">{panel}</div>
      </div>
    </div>
  );
};

export const CardBorderYellow = ({ title, results, yellowWidth, panel }) => {
  return (
    <div className="bg-gray50 rounded-[20px]">
      <div className=" flex justify-between  pt-[14px]  ">
        <div className=" pl-[14px] font-medium">{title}</div>
        <div className=" pr-8 font-semibold text-maincyan text-xl">
          {results} บาท
        </div>
      </div>
      <div className={`border-b border-mainyellow ${yellowWidth} `}></div>
      <div>{panel}</div>
    </div>
  );
};

export const CardReportAverage = ({
  title = "คุณมีรายได้เฉลี่ยต่อเดือน ",
  value,
}) => {
  return (
    <div
      className={` bg-gray50 rounded-[20px] py-[14px] font-IBMPlexSansThai px-[18px] `}
    >
      <div className="flex justify-between  w-full">
        <div className=" font-semibold">
          <div>{title}</div>
          <div className=" text-xl text-mainblue mt-2.5">
            {numeral(value)?.format("0,0")} บาท
          </div>
        </div>
        <img
          src={"/images/health-insurance-3.png"}
          className={`w-[64px] h-[66px] -mb-[10px] mt-2`}
          alt=""
        />
      </div>
    </div>
  );
};
