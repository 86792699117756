import numeral from "numeral";
import {
  STYLE_BORDER_YELLOW_PROPOTION,
  STYLE_CARD_PROPOTION,
  STYLE_HEADER_SECTION_PROPOTION,
  STYLE_TITLE_PROPOTION,
} from "../../../utils/useStyle";
import { ICON_WARNING } from "../../../utils/useIcons";

const ViNetWealthMian = ({
  amount,
  property_value,
  debt_value,
  net_wealth_value,
  status,
}) => {
  return (
    <div className={STYLE_CARD_PROPOTION}>
      <div className={STYLE_HEADER_SECTION_PROPOTION}>
        <div>
          <div className={STYLE_TITLE_PROPOTION}>ความมั่งคั่งสุทธิ</div>
          <div className={STYLE_BORDER_YELLOW_PROPOTION}></div>
        </div>
        <div className="text-center text-lg font-semibold text-maincyan">
          {numeral(amount)?.format("0,0")} บาท
        </div>
      </div>

      {status === "good" && (
        <div className=" grid grid-cols-2 gap-2  w-full px-9 pt-4 h-[171px]">
          <div className=" w-full h-full bg-[#C4F3BD] rounded-[4px] ">
            <div className=" flex flex-col justify-center items-center h-full ">
              <img src="/images/wealth_green.svg" className="w-14" />
              <div className=" text-mainblue font-medium text-md mt-[3px] ">
                สินทรัพย์
              </div>
              <div className="text-mainblue font-semibold text-base leading-3 pt-1">
                {property_value} บาท
              </div>
            </div>
          </div>
          <div className=" grid grid-rows-5 gap-2 ">
            <div className=" bg-[#FFCCCC] w-full h-full  row-span-2 rounded-[4px] py-2">
              <div className=" flex flex-col justify-center items-center h-full ">
                <div className=" text-mainblue font-medium text-md mt-[3px] ">
                  หนี้สิน
                </div>
                <div className="text-mainblue font-semibold text-base leading-3 pt-1">
                  {debt_value} บาท
                </div>
              </div>
            </div>
            <div className=" bg-[#CEE3FF] w-full h-full row-span-3 rounded-[4px] py-2">
              <div className=" flex flex-col justify-center items-center h-full ">
                <img src="/images/wealth_blue.svg" className="w-10" />
                <div className=" text-mainblue font-medium text-md mt-[3px] ">
                  ความมั่งคั่งสุทธิ
                </div>
                <div className="text-mainblue font-semibold text-base leading-3 pt-1">
                  {net_wealth_value} บาท
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {status === "bad" && (
        <div className="">
          <div className=" grid grid-cols-2 gap-2 w-full px-9 pt-4">
            <div className="grid grid-rows-5 gap-2">
              <div className=" row-span-2"></div>
              <div className=" w-full h-full bg-[#C4F3BD] rounded-[4px] row-span-3 p-1.5 ">
                <div className=" flex flex-col justify-center items-center h-full ">
                  <img src="/images/wealth_green.svg" className="w-14" />
                  <div className=" text-mainblue font-medium text-md mt-[3px] ">
                    สินทรัพย์
                  </div>
                  <div className="text-mainblue font-semibold text-base leading-3 pt-1">
                    {property_value} บาท
                  </div>
                </div>
              </div>
            </div>
            <div className=" w-full bg-[#FFCCCC] rounded-[4px] h-full p-1.5">
              <div className=" flex flex-col justify-center items-center ">
                <img src="/images/wealth_bad.svg" className="w-14 h" />
                <div className=" text-mainblue font-medium text-md mt-[3px] ">
                  หนี้สิน
                </div>
                <div className="text-mainblue font-semibold text-base pt-1">
                  {debt_value} บาท
                </div>
              </div>
            </div>
          </div>
          <div className=" border border-mainyellow rounded-[10px]  mx-9 mt-2.5 p-1 px-7 flex gap-[6px] items-center">
            <ICON_WARNING className={` w-7 h-7 text-mainyellow`} />
            <div className=" text-sm text-center text-mainblue">
              <div>หากมีหนี้สินมากกว่าสินทรัพย์</div>
              <div>ต้องระมัดระวังในการใช้จ่ายเงิน</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default ViNetWealthMian;
