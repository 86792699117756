import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

const store = (set) => ({
  familytreeData: [],
  setFamilytreeData: (familytreeData) => set(() => ({ familytreeData })),
  clearFamilytreeData: () => set(() => ({ familytreeData: [] })),
});

export const useFamilytreeStore = create(
  persist(store, {
    name: "familytreeStore",
    storage: createJSONStorage(() => localStorage), // (optional) by default the 'localStorage' is used
  })
);
