import { MTButton } from "../../../components/button/MTButton";
import { ImageMain } from "../../../components/img/Img.Main";

export const PromotionCard = () => {
  return (
    <div className=" border border-maincyan p-[8px] rounded-[20px]">
      <div className="text-black  font-semibold tex-base">โอกาสของชีวิต</div>
      <div className=" grid grid-cols-5 gap-2.5">
        <div className=" col-span-2  w-full h-[124px] md:h-40 rounded-[10px] overflow-hidden">
          <ImageMain src={"/images/promotion2.png"} className="" alt="" />
        </div>
        <div className=" col-span-3 ">
          {/* <div className=" text-mainblue text-base font-medium line-clamp-2">
            อยากมีเงินทุนสักก้อนเพื่อใช้ในการประกอบอาชีพ ต้องทำอย่างไรดี
          </div> */}
          <div className=" text-mainblue text-xs md:text-sm ">
            <div>1. รายได้ไม่จำกัดตามความสามารถ</div>
            <div> 2. จัดสรรเวลาทำงานได้เอง</div>
            <div className=" ">3. ช่วยให้ผู้คนบรรลุเป้าหมายทางการเงิน</div>
            <div> 4.ได้พัฒนาตนเองอย่างต่อเนื่อง</div>
          </div>
        </div>
      </div>
      <div className=" w-full mt-2.5">
        {" "}
        <MTButton
          iconleft
          icon={
            <ImageMain src="/images/workwithus.png" className=" w-6 h-6 " />
          }
          text="ร่วมงานกับเรา"
          textsize="text-base font-semibold"
          width="w-full h-12 "
          onClick={() => {
            window?.open(
              "https://liff.line.me/1645278921-kWRPP32q/?accountId=assurewealth"
            );
          }}
        />
      </div>
    </div>
  );
};
