import { Step, Stepper, Typography } from "@material-tailwind/react";
import { HeaderTitle } from "../../components/header/Header";
import Container from "../../components/layout/Container";
import {
  STYLE_HEADER_LEGACY,
  STYLE_MARGIN_TOP_PARENT,
  STYLE_PADDING_MAIN,
} from "../../utils/useStyle";
import NavigateGoBack from "../../functions/Navigate.Goback";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { CardStepperLegacy } from "./styles/Card";
import { MTButton } from "../../components/button/MTButton";
import ViewLagacyForm from "./components/VILegacyForm.Main";
import { FormLegacy, FormLegacyManulOne } from "./components/VIForm";

const LegacyPlanMain = () => {
  const navigate = useNavigate();
  const [tab, setTab] = useState(0);

  const [isOpen, setIsOpen] = useState();

  const [isLastStep, setIsLastStep] = useState(false);
  const [isFirstStep, setIsFirstStep] = useState(false);

  return (
    <Container>
      <div className="">
        <div className={`${STYLE_MARGIN_TOP_PARENT} `}>
          <HeaderTitle
            title="วางแผนมรดก"
            isGoback
            onClickGoBack={() => {
              NavigateGoBack(navigate, "/");
            }}
          />

          <div className={STYLE_PADDING_MAIN}>
            {tab === 0 && (
              <CardStepperLegacy
                img={"../images/legacy_1.png"}
                details={
                  <div>
                    เริ่มต้นด้วยการทำทะเบียนสินทรัพย์สินส่วนตัว สินสมรส หนี้สิน
                    ทะเบียนญาติ ทำการระบุ
                    <div>
                      {" "}
                      ผู้จัดการมรดก ผู้ปกครองโดยชอบธรรมให้ บุตรที่ยังเยาว์วัย 
                    </div>
                  </div>
                }
              />
            )}
            {tab === 1 && (
              <CardStepperLegacy
                img={"../images/legacy_2.png"}
                img_className=" w-44 h-fit mx-auto"
                details={
                  <div>
                    เลือกเขียนพินัยกรรมด้วย
                    <div>ลายมือตนเองทั้งฉบับ</div>
                  </div>
                }
              />
            )}
            {tab === 2 && (
              <CardStepperLegacy
                img={"../images/legacy_3.png"}
                img_className=" w-44 h-fit mx-auto"
                details={
                  <div>
                    เลือกแบบพิมพ์ต้องมีพยานลงชื่อพร้อมกัน 2 คน
                    พยานห้ามเป็นผู้รับผลประโยชน์จากพินัยกรรม
                    <div>
                      {" "}
                      ทำมอบให้บุคคลที่ไว้วางใจ
                      <div>และปรับปรุงข้อมูลอย่างสม่ำเสมอ</div>
                    </div>
                  </div>
                }
              />
            )}
            {tab === 3 && (
              <CardStepperLegacy
                img={"../images/legacy_4.png"}
                img_className=" w-44 h-fit mx-auto"
                details={
                  <div>
                    เลือกแบบพิมพ์ต้องมีพยานลงชื่อพร้อมกัน 2 คน
                    พยานห้ามเป็นผู้รับผลประโยชน์จากพินัยกรรม
                    <div>
                      {" "}
                      ทำมอบให้บุคคลที่ไว้วางใจ
                      <div>และปรับปรุงข้อมูลอย่างสม่ำเสมอ</div>
                    </div>
                  </div>
                }
              />
            )}

            {tab === 4 && (
              <React.Fragment>
                <ViewLagacyForm
                  onClickManualFormOne={() => {
                    navigate("/legacy/manualformone");
                  }}
                  onClickManualFormTwo={() => {
                    navigate("/legacy/manualformtwo");
                  }}
                  onClickManualFormThree={() => {
                    navigate("/legacy/manualformthree");
                  }}
                  onClickNormalFormOne={() => {
                    navigate("/legacy/normalformone");
                  }}
                  onClickNormalFormTwo={() => {
                    navigate("/legacy/normalformtwo");
                  }}
                  onClickNormalFormThree={() => {
                    navigate("/legacy/normalformthree");
                  }}
                />
              </React.Fragment>
            )}

            {/* ------- Stepper ------ */}
            {tab === 4 ? (
              ""
            ) : (
              <Stepper
                className=" w-4/6 gap-2 mx-auto mt-4"
                activeStep={tab}
                lineClassName="hidden"
                activeLineClassName="bg-maincyan"
                //   className={`${STYLE_TAB} `}
                isLastStep={(value) => setIsLastStep(value)}
                isFirstStep={(value) => setIsFirstStep(value)}
              >
                {/*---- test 2 ----*/}
                <Step
                  onClick={() => setTab(0)}
                  className={`${
                    tab === 0 ? "!bg-maincyan w-full" : " !bg-gray-300 w-32"
                  } h-[6px]`}
                  activeClassName="!bg-maincyan"
                ></Step>
                <Step
                  onClick={() => setTab(1)}
                  className={`${
                    tab === 1 ? "!bg-maincyan w-full" : " !bg-gray-300 w-32"
                  } h-[6px]`}
                  activeClassName="!bg-maincyan"
                ></Step>

                {/*---- test 3 ----*/}
                <Step
                  className={`${
                    tab === 2 ? "!bg-maincyan w-full" : " !bg-gray-300 w-32"
                  } h-[6px]`}
                  activeClassName="!bg-maincyan"
                  onClick={() => setTab(2)}
                ></Step>

                {/*---- test 4 ----*/}
                <Step
                  className={`${
                    tab === 3 ? "!bg-maincyan w-full" : " !bg-gray-300 w-32"
                  } h-[6px]`}
                  activeClassName="!bg-maincyan"
                  onClick={() => setTab(3)}
                ></Step>
              </Stepper>
            )}
          </div>

          <div>
            {tab === 4 ? (
              <div className=" flex justify-center items-center gap-2 mt-4">
                <MTButton
                  // loading={loading}
                  text="ย้อนกลับ"
                  color="border border-maincyan "
                  colortext="text-maincyan group-hover:text-white"
                  hovercolor="hover:bg-maincyan group "
                  onClick={() => {
                    if (isOpen) {
                      setIsOpen(false);
                    } else setTab((prev) => prev - 1);
                  }}
                />
                {isOpen ? (
                  <MTButton
                    // loading={loading}
                    text={"บันทึก"}
                    color="bg-maincyan"
                  />
                ) : (
                  ""
                )}
              </div>
            ) : (
              <div className=" flex justify-center items-center gap-2 mt-4">
                {tab === 0 ? (
                  ""
                ) : (
                  <MTButton
                    // loading={loading}
                    text="ย้อนกลับ"
                    color="border border-maincyan "
                    colortext="text-maincyan group-hover:text-white"
                    hovercolor="hover:bg-maincyan group "
                    onClick={() => {
                      setTab((prev) => prev - 1);
                    }}
                  />
                )}
                <MTButton
                  // loading={loading}
                  text={"ถัดไป"}
                  color="bg-maincyan"
                  onClick={(e) => {
                    if (tab === 4) {
                      setIsOpen(false);
                    } else {
                      setTab((prev) => prev + 1);
                    }
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};
export default LegacyPlanMain;
