import axios from "axios";

const env = process.env?.REACT_APP_NODE_ENV ?? "production";

const BASE_URL =
  env === "development"
    ? "http://localhost:1337/api"
    : "https://c2v1kz8v4f.execute-api.ap-southeast-1.amazonaws.com/dev/api";

const api = axios.create({
  baseURL: BASE_URL,
});

export default api;
