import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

const store = (set) => ({
  taxData: null,
  setTaxData: (taxData) => set(() => ({ taxData })),
  clearTaxData: () => set(() => ({ taxData: null })),
});

export const useTaxStore = create(
  persist(store, {
    name: "taxData", // unique name
    storage: createJSONStorage(() => localStorage), // (optional) by default the 'localStorage' is used
  })
);
