import { useNavigate } from "react-router-dom";
import { HeaderTitle } from "../../../components/header/Header";
import Container from "../../../components/layout/Container";
import { useEffect } from "react";
import { first_reload } from "../../tax_plan/functions/firstLoad";
import { HeaderReport } from "../components/header.main";
import { STYLE_REPORT_CONTAIN } from "../../../utils/useStyle";
import { useInsuranceStore } from "../../../_store/insuranceStore";
import { CardMain, CardTips } from "../../../components/card/CardMain";
import numeral from "numeral";
import CustomHorizontalBarChart from "../../pdf/component/pdf.c.familyChart";
import { StatusCard } from "../../insuarance_plan/styles/Card";
import { MTButton } from "../../../components/button/MTButton";

const ReportDisableInsurancePage = () => {
  const navigate = useNavigate();
  const insuranceData = useInsuranceStore((state) => state.insuranceData);

  const getStatus = () => {
    if (insuranceData.sum_insurance_more === 0) {
      return "normal";
    } else if (insuranceData.sum_insurance_more < 0) {
      return "toomuch";
    }
    return "more";
  };

  useEffect(() => {
    let ignore = false;
    first_reload(ignore);
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <Container>
      <div>
        <HeaderTitle
          title="รายงาน"
          isGoback
          onClickGoBack={() => {
            navigate("/report");
          }}
        />

        <div className={`${STYLE_REPORT_CONTAIN} flex flex-col gap-5`}>
          <HeaderReport
            icon={"/images/personal-report.png"}
            title={"แผนทุนประกันทุพพลภาพ"}
            className={" z-20"}
          />
          <CardMain
            title={
              <div className=" text-mainblue font-semibold ">
                ผลลัพธ์วางแผนทุนประกันคุ้มครองค่าใช้จ่าย
              </div>
            }
            panel={
              <div className=" px-3  space-y-5 font-medium">
                <div>
                  <div>ทุนประกันสุทธิ</div>
                  <div className=" text-maincyan font-semibold text-2xl">
                    {numeral(insuranceData?.sum_disabled_insurance_net)?.format(
                      "0,0"
                    )}{" "}
                    บาท
                  </div>
                </div>
                <div>
                  <div>ทุนประกันที่คุณมี ณ ปัจจุบัน</div>
                  <div className=" text-maincyan font-semibold text-2xl">
                    {numeral(
                      insuranceData?.sum_disabled_insurance_current
                    )?.format("0,0")}{" "}
                    บาท
                  </div>
                </div>
                <div>
                  <div>มูลค่าทรัพย์สินที่มีในปัจจุบัน</div>
                  <div className=" text-maincyan font-semibold text-2xl">
                    {numeral(insuranceData?.sum_disabled_asset_current)?.format(
                      "0,0"
                    )}{" "}
                    บาท
                  </div>
                </div>
                <div className="w-[300px] flex justify-center mx-auto">
                  <CustomHorizontalBarChart
                    width={120}
                    yourData={[
                      insuranceData?.sum_disabled_insurance_current,
                      0,
                    ]}
                    recomenedData={[
                      0,
                      insuranceData.sum_disabled_insurance_more,
                    ]}
                    barThickness={40}
                  />
                </div>
                <StatusCard
                  status={getStatus()}
                  value={insuranceData.sum_disabled_insurance_more}
                />
              </div>
            }
          />
          <CardTips
            title="เครื่องมือช่วยให้บรรลุเป้าหมาย"
            panel={
              <div className=" space-y-1">
                <div>1. ประกันอุบัติเหตุวงเงินสูง</div>
                <div>2. ประกันที่มีสัญญาเพิ่มเติมคุ้มครองการทุพพลภาพ (WP)</div>
              </div>
            }
          />

          <div className=" flex justify-center ">
            <MTButton
              onClick={() => {
                navigate("/report");
              }}
              text="ย้อนกลับ"
              color="bg-white"
              border="border border-maincyan"
              colortext="text-maincyan group-hover:text-white"
              hovercolor="hover:bg-maincyan group "
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ReportDisableInsurancePage;
