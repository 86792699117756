import React, { useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { cn } from "../../utils/useFunctions";
import fontSarabunTH from "./font/fontSarabunTH";
import {
  STYLE_PAGE,
  STYLE_PAGE_LANDING,
  STYLE_PDF_CONTENT,
} from "./styles/pdf.style";
import PageLanding from "./view/pdf.v.pageLanding";
import PdfPageBalananceOne from "./view/pdf.v.pageBalanceOne";
import PdfPageBalananceTwo from "./view/pdf.v.pageBalanceTwo";
import PdfPageBalananceThree from "./view/pdf.v.pageBalanceThree";
import PdfPageBalananceFour from "./view/pdf.v.pageBalanceFour";
import PdfPageBalananceSix from "./view/pdf.v.pageBalanceSix";
import PdfPageBalananceSeven from "./view/pdf.v.pageBalanceSeven";
import PdfPageFinacialOne from "./view/pdf.v.pageFinacialOne";
import PdfPageFinacialTwo from "./view/pdf.v.pageFinalcialTwo";
import PdfPageFinacialLanding from "./view/pdf.v.pageFinalcialLanding";
import PdfPageRiskOne from "./view/pdf.v.pageRiskOne";
import PdfPageFamilyOne from "./view/pdf.v.pageFamilyOne";
import PdfPageFamilyTwo from "./view/pdf.v.pageFamilyTwo";
import PdfPageFamilyThree from "./view/pdf.v.pageFamilyThree";
import PdfPageInvestOne from "./view/pdf.v.pageInvestOne";
import PdfPageSavingOne from "./view/pdf.v.pageSavingOne";
import PdfPageFutureOne from "./view/pdf.v.pageFutureOne";
import PdfPageFutureTwo from "./view/pdf.v.pageFutureTwo";
import PdfPageFutureThree from "./view/pdf.v.pageFutureThree";
import PdfPageFutureFour from "./view/pdf.v.pageFutureFour";
import PdfPageDebtOne from "./view/pdf.v.pageDebtOne";
import PdfPageDebtTwo from "./view/pdf.v.pageDebtTwo";
import PdfPageDebtThree from "./view/pdf.v.pageDebtThree";
import PdfPageTaxOne from "./view/pdf.v.pageTaxOne";
import PdfPageTaxTwo from "./view/pdf.v.pageTaxTwo";
import PdfPageTaxSix from "./view/pdf.v.pageTaxSix";
import PdfPageEducationOne from "./view/pdf.v.pageEducation";
import PdfPageRetireOne from "./view/pdf.v.pageRetireOne";
import PdfPageRetireTwo from "./view/pdf.v.pageRetireTwo";
import PdfPageRetireThree from "./view/pdf.v.pageRetireThree";
import { useEducationStore } from "../../_store/educationStore";
import PdfPageOverviewOne from "./view/pdf.v.pageOverviewOne";
import PdfPageOverviewTwo from "./view/pdf.v.pageOverviewTwo";
import PdfPageOverviewThree from "./view/pdf.v.pageOverviewThree";
import PdfPageOVIncomeOne from "./view/pdf.v.pageOVIncomeOne";
import PdfPageOVExpenseOne from "./view/pdf.v.pageOVExpenseOne";
import PdfPageOVDebtOne from "./view/pdf.v.pageOVDebtOne";
import PdfPageOVPropertyOne from "./view/pdf.v.pageOVPropertyOne";
import PdfPageAssumeOne from "./view/pdf.v.pageAssumeOne";
import PdfPageAppendix from "./view/pdf.v.pageAppendix";
import PdfPageAppendixEducationOne from "./view/pdf.v.pageAppendixEducationOne";
import PdfPageInvestTwo from "./view/pdf.v.pageInvestTwo";
import { useInvestStore } from "../../_store/investStore";
import { useBalanceStore } from "../../_store/balanceStore";
import _ from "lodash";
import PdfPageBalananceFive from "./view/pdf.v.pageBalanceFive";
import PdfPageInvestThree from "./view/pdf.v.pageInvestThree";
import PdfPageInvestFour from "./view/pdf.v.pageInvestFour";
import PdfPageInvestFive from "./view/pdf.v.pageInvestFive";
import PdfPageDebtOneSum from "./view/pdf.v.pageDebtOneSum";
import PdfPageDebtFour from "./view/pdf.v.pageDebtFour";
import PdfPageTaxThree from "./view/pdf.v.pageTaxThree";

const PDFRender = () => {
  const educationData = useEducationStore((state) => state.educationData);
  const balanceData = useBalanceStore((state) => state?.balanceData);

  const getPageAfterEducation = (page) => {
    if (educationData?.length === 0) return page - 1;
    return page + educationData.length + 1;
  };

  const getPageAfterInvestTwo = (page) => {
    const filterPropertyInvest = balanceData?.property_invest_list?.filter(
      (item) => item?.options_property_invest_category
    );
    const groupInvest = _.groupBy(
      filterPropertyInvest,
      "options_property_invest_category"
    );
    const keys = Object.keys(groupInvest);
    if (keys?.length === 0) return page - 1;
    return page + keys?.length - 1;
  };

  const getPageAfterDebtOneSum = (page) => {
    const creditShortItems =
      balanceData?.debt_short_list?.filter(
        (item) => item?.options_debt_short === "debt_creditcard"
      ) || [];
    const shortTermShortItems =
      balanceData?.debt_short_list?.filter(
        (item) => item?.options_debt_short === "debt_shortterm"
      ) || [];
    const informalShortItems =
      balanceData?.debt_short_list?.filter(
        (item) => item?.options_debt_short === "debt_informal_shortterm"
      ) || [];
    const otherShortItems =
      balanceData?.debt_short_list?.filter(
        (item) => item?.options_debt_short === "debt_shortterm_other"
      ) || [];
    const carLongItems =
      balanceData?.debt_long_list?.filter(
        (item) => item?.options_debt_long === "debt_car"
      ) || [];
    const placeLongItems =
      balanceData?.debt_long_list?.filter(
        (item) => item?.options_debt_long === "debt_place"
      ) || [];
    const otherLongItems =
      balanceData?.debt_long_list?.filter(
        (item) => item?.options_debt_long === "debt_long_other"
      ) || [];
    const newChunkDebtItems = _.chunk(
      [
        ...creditShortItems,
        ...shortTermShortItems,
        ...informalShortItems,
        ...otherShortItems,
        ...carLongItems,
        ...placeLongItems,
        ...otherLongItems,
      ],
      2
    );
    return page + newChunkDebtItems.length - 1;
  };

  return (
    <div
      className={cn(
        " h-screen overflow-auto",
        "absolute top-0 left-[-150%] -z-20 max-w-xs"
      )}
    >
      <div id="pdf-content" className={cn(STYLE_PDF_CONTENT)}>
        <PageLanding />

        {/* Overview */}
        <PdfPageOverviewOne page={1} />
        <PdfPageOverviewTwo page={2} />
        <PdfPageOverviewThree page={3} />

        {/* ASSUME */}
        <PdfPageAssumeOne page={4} />

        {/* Overview Income */}
        <PdfPageOVIncomeOne page={5} />

        {/* Overview Expense */}
        <PdfPageOVExpenseOne page={6} />

        {/* Overview Property */}
        <PdfPageOVPropertyOne page={7} />

        {/* Overview Debt  */}
        <PdfPageOVDebtOne page={8} />

        {/* งบดุล */}
        <PdfPageBalananceOne page={9} />
        <PdfPageBalananceTwo page={10} />
        <PdfPageBalananceThree page={11} />
        <PdfPageBalananceFour page={12} />
        <PdfPageBalananceFive page={13} />
        <PdfPageBalananceSix page={14} />
        <PdfPageBalananceSeven page={15} />

        {/* อัตราส่วนทางการเงิน */}
        <PdfPageFinacialOne page={16} />
        <PdfPageFinacialTwo page={17} />
        <PdfPageFinacialLanding page={18} />

        {/* แผนบริหารจัดการความเสี่ยง */}
        <PdfPageRiskOne page={19} />

        {/* แผนทุนประกันครอบครัว */}
        <PdfPageFamilyOne page={20} />
        <PdfPageFamilyTwo page={21} />
        <PdfPageFamilyThree page={22} />

        {/* แผนเกษียณ */}
        <PdfPageRetireOne page={23} />
        <PdfPageRetireTwo page={24} />
        <PdfPageRetireThree page={25} />

        {/* วางแผนภาษี */}
        <PdfPageTaxOne page={26} />
        <PdfPageTaxTwo page={27} />
        <PdfPageTaxThree page={28} />
        <PdfPageTaxSix page={29} />

        {/* สัดส่วนการลงทุน */}
        <PdfPageInvestOne page={30} />
        <PdfPageInvestTwo page={31} />
        <PdfPageInvestThree page={getPageAfterInvestTwo(32)} />
        <PdfPageInvestFour page={getPageAfterInvestTwo(33)} />
        <PdfPageInvestFive page={getPageAfterInvestTwo(34)} />

        {/* แผนการศึกษาบุตร */}
        <PdfPageEducationOne page={getPageAfterInvestTwo(35)} />

        {/* แผนการออม */}
        <PdfPageSavingOne
          page={getPageAfterEducation(getPageAfterInvestTwo(36))}
        />

        {/* แผนอนาคต */}
        <PdfPageFutureOne
          page={getPageAfterEducation(getPageAfterInvestTwo(37))}
        />
        <PdfPageFutureTwo
          page={getPageAfterEducation(getPageAfterInvestTwo(38))}
        />
        <PdfPageFutureThree
          page={getPageAfterEducation(getPageAfterInvestTwo(39))}
        />
        <PdfPageFutureFour
          page={getPageAfterEducation(getPageAfterInvestTwo(40))}
        />

        {/* แผนจัดการหนี้สิน */}
        <PdfPageDebtOne
          page={getPageAfterEducation(getPageAfterInvestTwo(41))}
        />
        <PdfPageDebtOneSum
          page={getPageAfterEducation(getPageAfterInvestTwo(42))}
        />
        <PdfPageDebtTwo
          page={getPageAfterDebtOneSum(
            getPageAfterEducation(getPageAfterInvestTwo(43))
          )}
        />
        <PdfPageDebtThree
          page={getPageAfterDebtOneSum(
            getPageAfterEducation(getPageAfterInvestTwo(44))
          )}
        />
        <PdfPageDebtFour
          page={getPageAfterDebtOneSum(
            getPageAfterEducation(getPageAfterInvestTwo(45))
          )}
        />

        {/* ภาคผนวก */}
        <PdfPageAppendix
          page={getPageAfterEducation(getPageAfterInvestTwo(46))}
        />

        {/* ตารางการศึกษา */}
        <PdfPageAppendixEducationOne
          page={getPageAfterEducation(getPageAfterInvestTwo(47))}
        />
      </div>
    </div>
  );
};

export default PDFRender;
