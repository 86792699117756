import { useBalanceStore } from "../../../_store/balanceStore";
import { useDebtStore } from "../../../_store/debtplanStore";
import { cn } from "../../../utils/useFunctions";
import {
    PdfCard,
    PdfCardWhite
} from "../component/pdf.c.card";
import PdfFooter from "../component/pdf.c.footer";
import PdfHeader from "../component/pdf.c.header";
import {
    PdfHeaderText
} from "../component/pdf.c.headerText";
import PdfLine from "../component/pdf.c.line";
import {
    STYLE_PAGE,
    STYLE_PDF_TEXT_MEDIUM_12,
    STYLE_PDF_TEXT_MEDIUM_BLUE_12,
    STYLE_PDF_TEXT_NORMAL_11
} from "../styles/pdf.style";

function PdfPageDebtFour({ page }) {
  const balanceData = useBalanceStore((state) => state.balanceData);
  const debtData = useDebtStore((state) => state.debtData);

  return (
    <div className={cn(STYLE_PAGE)}>
      <PdfHeader />
      <PdfLine />
      <PdfHeaderText title="แผนจัดการหนี้สิน" icon="/images/wealth_1.svg" />

      <PdfCard>
        {/* FIRST */}
        <PdfCardWhite className={cn("px-3 flex flex-col items-center")}>
          <div className={cn(STYLE_PDF_TEXT_NORMAL_11, "text-center")}>
            หากคุณเป็นพนักงานในองค์กรและบริษัทยินยอมให้ทำระบบหักเงินเดือนเพื่อชำระหนี้สถาบันการเงิน
            เราให้สินเชื่อส่วนบุคคลพิเศษ
          </div>

          <div className="flex justify-center items-center mt-1">
            <img src="/images/emoji_money_hand.svg" />
            <div
              className={cn(
                "flex flex-col justify-center text-center",
                STYLE_PDF_TEXT_NORMAL_11
              )}
            >
              <div>
                เป็นหนี้เสียบัตรเครดิต บัตรกดเงินสดหรือสินเชื่อส่วนบุคคล
              </div>
              <div>
                ที่ไม่มีหลักประกันของสถาบันที่เข้าร่วมโครงการ แล้วทำอย่างไรดี ?
              </div>
            </div>
          </div>

          <div
            className={cn(STYLE_PDF_TEXT_MEDIUM_BLUE_12, "text-center mt-1")}
          >
            ปรึกษาคลินิคแก้หนี้ “ CLINIC SAM”
          </div>
          <div
            className={cn(
              STYLE_PDF_TEXT_MEDIUM_BLUE_12,
              "text-center mt-1 flex justify-center space-x-1 underline"
            )}
          >
            <div>
              <img src="/images/link.svg" />
            </div>
            <div>www.debtclinicbysam.com</div>
          </div>
        </PdfCardWhite>

        {/* SECOND */}
        <PdfCardWhite className={cn("px-3 mt-3")}>
          <div className="flex items-center space-x-1">
            <div className="">
              <img src="/images/lamp.svg" />
            </div>
            <div className={cn(STYLE_PDF_TEXT_MEDIUM_12)}>
              คุณสมบัติผู้สมัคร
            </div>
          </div>

          <div
            className={cn("flex flex-col ml-2 mt-1", STYLE_PDF_TEXT_NORMAL_11)}
          >
            <div>1. เป็นบุคคลธรรมดาที่มีรายได้ อายุไม่เกิน 70 ปี</div>
            <div>
              2. เป็นหนี้เสียบัตรเครดิต บัตรกดเงินสด หรือ
              สินเชื่อส่วนบุคคลที่ไม่มี
            </div>
            <div>3. เป็นหนี้ค้างชำระ (NPL) มากว่า 120 วัน</div>
            <div>4. หนี้รวมไม่เกิน 2 ล้านบาท</div>
            <div>5. ไม่เป็นบุคคลล้มละลาย</div>
          </div>
        </PdfCardWhite>
      </PdfCard>

      <PdfCard>
        {/* THIRD */}
        <PdfCardWhite className={cn("px-3")}>
          <div className="flex items-center space-x-1">
            <div className="">
              <img src="/images/lamp.svg" />
            </div>
            <div className={cn(STYLE_PDF_TEXT_MEDIUM_12)}>
              กลยุทธ์การรับมือหนี้
            </div>
          </div>

          <div
            className={cn("flex flex-col ml-2 mt-1", STYLE_PDF_TEXT_NORMAL_11)}
          >
            <div>1. งดการสร้างหนี้ที่ไม่ก่อให้เกิดรายได้</div>
            <div>2. หาทางลดอัตราดอกเบี้ยด้วยการต่อรองกับสถาบันการเงิน</div>
            <div>
              3. เพิ่มจำนวนเงินผ่อนชำระในแต่ละงวดในหนี้ที่ไม่ Fixed Rate
              เพื่อให้ยอดหนี้สินรวมหมดเร็วขึ้น และเสียดอกเบี้ยน้อยลง
            </div>
          </div>
        </PdfCardWhite>
      </PdfCard>
      <PdfFooter page={page} />
    </div>
  );
}

export default PdfPageDebtFour;
