import numeral from "numeral";
import { useSavingStore } from "../../../_store/savingStore";
import { MTButton } from "../../../components/button/MTButton";
import {
  STYLE_CARD_BG,
  STYLE_FLEX_STATUS,
  STYLE_PARENT_TWO_BUTTON,
} from "../../../utils/useStyle";
import LineChartSavingMain from "./LineChartSaving.Main";
import { useEffect, useState } from "react";
import { FooterAfterSum } from "../../../components/FooterAfterSum/FooterAfterSum";

const ViewExpensesSum = ({ sum, setSum, setShow }) => {
  const savingData = useSavingStore((state) => state.savingData);
  const clearSavingData = useSavingStore((state) => state.clearSavingData);

  const [loading, setLoading] = useState(false);

  const dataAge = () => {
    let sum = [];
    for (let i = 1; i <= savingData?.expenses_per_year; i++) {
      sum.push(i);
    }
    return sum;
  };

  const labels = dataAge();

  const getExpensesYield = () => {
    let sum = 0;
    sum = savingData?.expenses_invest_yield / 12 / 100;
    return Number(sum);
  };

  const getExpensesYear = () => {
    let sum = 0;
    sum =
      (savingData?.expenses_reduce_year *
        (Math.pow(1 + (savingData?.expenses_invest_yield/100), savingData?.expenses_per_year) - 1)) /
      (savingData?.expenses_invest_yield/100);

    return Number(sum);
  };

  const getExpensesYieldYear = () => {
    let sum = 0;
    sum =
      getExpensesYear() -
      savingData?.expenses_reduce_year * savingData?.expenses_per_year;
    return Number(sum);
  };

  const getSum = () => {
    let results = [];
    let expenses = savingData?.expenses_reduce_year;

    for (let i = 0; i <= savingData?.expenses_per_year; i++) {
      if (i === 0) {
        results.push(0);
      } else if (i === 1) {
        results.push(expenses);
      } else {
        const newSaving =
          (expenses * (Math.pow(1 + (savingData?.expenses_invest_yield/100), i) - 1)) /
          (savingData?.expenses_invest_yield/100);
        results.push(newSaving);
      }
    }

    return results;
  };

  const dataAmount = getSum();

  useEffect(() => {
    if (window != undefined) {
      window.scrollTo(0, 0);
    }
  }, []);

  return (
    <div className=" mx-[25px]">
      <LineChartSavingMain
        labels={labels}
        dataPlan={dataAmount}
        head={`ผลตอบแทน`}
        title_x="บาท"
        title_y="ปี"
      />
      <div className={`${STYLE_CARD_BG} mt-6`}>
        <div className=" flex gap-[6px] items-center font-IBMPlexSansThai h-6">
          <div className=" text-md font-semibold pl-[14px] text-mainblue">{`ผลลัพธ์วางแผนลดค่าใช้จ่าย`}</div>
        </div>
        <div className={`border-b border-mainyellow w-[295px]`}></div>
        <div className=" px-[13px] pt-5 space-y-2.5">
          <div className={STYLE_FLEX_STATUS}>
            <svg
              className="flex-none"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M5 21C5 18.7909 8.13401 17 12 17C15.866 17 19 18.7909 19 21M17 8C17 10.7614 14.7614 13 12 13C9.23858 13 7 10.7614 7 8C7 5.23858 9.23858 3 12 3C14.7614 3 17 5.23858 17 8Z"
                stroke="#00A9A0"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <div className="  text-md ">
              อีก {savingData?.expenses_per_year} ปี
              การลดค่าใช้จ่ายแล้วนำเงินออมไปลงทุนที่ให้ดอกเบี้ยทบต้น
              <div>
                {savingData?.expenses_invest_yield} % ต่อปี (ลงทุนปลายงวด)
                <div>
                  เดือนละ{" "}
                  <span className="font-semibold text-maincyan">
                    {numeral(savingData?.expenses_reduce_month).format("0,0")}
                  </span>{" "}
                  บาท{" "}
                </div>
              </div>
            </div>
          </div>
          <div className={STYLE_FLEX_STATUS}>
            <svg
              className="flex-none"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M16.5934 17.3334H21.8334C21.3934 11.5267 18.8934 7.92008 15.6867 5.78675L17.1467 2.60008C17.1908 2.49896 17.2092 2.38847 17.2001 2.27852C17.191 2.16856 17.1548 2.06258 17.0947 1.97007C17.0345 1.87757 16.9524 1.80143 16.8556 1.74849C16.7588 1.69555 16.6504 1.66747 16.54 1.66675H7.40003C7.2897 1.66747 7.18128 1.69555 7.08448 1.74849C6.98768 1.80143 6.90554 1.87757 6.84541 1.97007C6.78528 2.06258 6.74905 2.16856 6.73997 2.27852C6.73088 2.38847 6.74923 2.49896 6.79337 2.60008L7.59337 4.33341H14.34L13.74 5.66675H12.5067C12.8834 6.67958 13.1602 7.72677 13.3334 8.79341C13.4455 9.45506 13.5168 10.123 13.5467 10.7934L12.4934 11.5334C12.4962 10.6711 12.4248 9.81015 12.28 8.96008C12.0958 7.82873 11.778 6.72323 11.3334 5.66675H10.6667C10.0515 6.44412 9.49862 7.26892 9.01337 8.13341C8.6221 8.87498 8.28996 9.64626 8.02003 10.4401L7.1067 9.83342C7.37329 9.06618 7.70101 8.32157 8.0867 7.60675C8.47641 6.93644 8.90376 6.28874 9.3667 5.66675H8.20003L8.25337 5.78008C4.72003 8.13341 2.03337 12.2667 2.03337 19.1667C2.02436 19.3118 2.04418 19.4572 2.09168 19.5945C2.13919 19.7319 2.21344 19.8584 2.31014 19.9669C2.40684 20.0754 2.52407 20.1636 2.65508 20.2265C2.78608 20.2894 2.92824 20.3258 3.07337 20.3334H8.4067C8.17769 20.0951 8.01957 19.7977 7.95005 19.4745C7.88053 19.1514 7.90236 18.8153 8.01309 18.5038C8.12383 18.1924 8.31909 17.918 8.577 17.7112C8.83491 17.5045 9.14529 17.3737 9.47337 17.3334C9.31371 17.0626 9.2286 16.7544 9.2267 16.4401C9.2267 15.9627 9.41634 15.5049 9.75391 15.1673C10.0915 14.8297 10.5493 14.6401 11.0267 14.6401H15.0267C15.5041 14.6401 15.9619 14.8297 16.2995 15.1673C16.6371 15.5049 16.8267 15.9627 16.8267 16.4401C16.8301 16.7534 16.7496 17.0618 16.5934 17.3334Z"
                fill="#FFD200"
              />
              <path
                d="M14.4002 18.9999C14.4002 18.8231 14.33 18.6535 14.205 18.5285C14.0799 18.4035 13.9104 18.3333 13.7336 18.3333H9.73356C9.55675 18.3333 9.38718 18.4035 9.26216 18.5285C9.13713 18.6535 9.06689 18.8231 9.06689 18.9999C9.06689 19.1767 9.13713 19.3463 9.26216 19.4713C9.38718 19.5963 9.55675 19.6666 9.73356 19.6666H13.7336C13.9104 19.6666 14.0799 19.5963 14.205 19.4713C14.33 19.3463 14.4002 19.1767 14.4002 18.9999Z"
                fill="#FFD200"
              />
              <path
                d="M15.0265 15.6667H11.0265C10.8497 15.6667 10.6801 15.737 10.5551 15.862C10.4301 15.987 10.3599 16.1566 10.3599 16.3334C10.3599 16.5102 10.4301 16.6798 10.5551 16.8048C10.6801 16.9298 10.8497 17.0001 11.0265 17.0001H15.0265C15.2033 17.0001 15.3729 16.9298 15.4979 16.8048C15.623 16.6798 15.6932 16.5102 15.6932 16.3334C15.6932 16.1566 15.623 15.987 15.4979 15.862C15.3729 15.737 15.2033 15.6667 15.0265 15.6667Z"
                fill="#FFD200"
              />
              <path
                d="M14.6667 21H10.6667C10.4899 21 10.3203 21.0702 10.1953 21.1953C10.0702 21.3203 10 21.4899 10 21.6667C10 21.8435 10.0702 22.013 10.1953 22.1381C10.3203 22.2631 10.4899 22.3333 10.6667 22.3333H14.6667C14.8435 22.3333 15.013 22.2631 15.1381 22.1381C15.2631 22.013 15.3333 21.8435 15.3333 21.6667C15.3333 21.4899 15.2631 21.3203 15.1381 21.1953C15.013 21.0702 14.8435 21 14.6667 21Z"
                fill="#FFD200"
              />
              <path
                d="M21.8001 21H17.1335C16.9567 21 16.7871 21.0702 16.6621 21.1953C16.537 21.3203 16.4668 21.4899 16.4668 21.6667C16.4668 21.8435 16.537 22.013 16.6621 22.1381C16.7871 22.2631 16.9567 22.3333 17.1335 22.3333H21.8001C21.9769 22.3333 22.1465 22.2631 22.2715 22.1381C22.3966 22.013 22.4668 21.8435 22.4668 21.6667C22.4668 21.4899 22.3966 21.3203 22.2715 21.1953C22.1465 21.0702 21.9769 21 21.8001 21Z"
                fill="#FFD200"
              />
              <path
                d="M22.4666 18.3333H17.8C17.6232 18.3333 17.4536 18.4035 17.3286 18.5285C17.2035 18.6535 17.1333 18.8231 17.1333 18.9999C17.1333 19.1767 17.2035 19.3463 17.3286 19.4713C17.4536 19.5963 17.6232 19.6666 17.8 19.6666H22.4666C22.6434 19.6666 22.813 19.5963 22.938 19.4713C23.0631 19.3463 23.1333 19.1767 23.1333 18.9999C23.1333 18.8231 23.0631 18.6535 22.938 18.5285C22.813 18.4035 22.6434 18.3333 22.4666 18.3333Z"
                fill="#FFD200"
              />
            </svg>
            <div className="  text-md ">
              จะมีเงินจำนวน{" "}
              <span className=" text-mainblue font-semibold">
                {numeral(getExpensesYear()).format("0,0.00")}
              </span>{" "}
              <span className=" text-mainblue font-semibold">บาท</span>
            </div>
          </div>

          <div className={STYLE_FLEX_STATUS}>
            <svg
              className="flex-none"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M20 7L12.6154 14.5L9.53846 11.375L4 17M20 7L20 13M20 7L14 7"
                stroke="#FF7A00"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <div className="  text-md ">
              <div>
                เป็นดอกเบี้ย{" "}
                <span className=" font-medium">
                  {numeral(getExpensesYieldYear())?.format("0, 0.00")}
                </span>
                <span> บาท</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterAfterSum />
      <div className={STYLE_PARENT_TWO_BUTTON}>
        <MTButton
          loading={loading}
          width="w-full md:w-48 flex-none "
          text="ย้อนกลับ"
          color="border border-maincyan "
          colortext="text-maincyan group-hover:text-white"
          hovercolor="hover:bg-maincyan group "
          onClick={() => {
            setSum("");
          }}
        />
        <MTButton
          text="วางแผนการออม"
          width="w-full md:w-48 flex-none "
          loading={loading}
          onClick={() => {
            setSum({
              ...savingData,
              expenses_reduce_year: getExpensesYear(),
            });
            setShow("saving");
          }}
        />
      </div>
    </div>
  );
};
export default ViewExpensesSum;
