import { NumericFormat } from "react-number-format";
import { CardMain } from "../../../components/card/CardMain";
import {
  STYLE_BORDER_YELLOW_AC,
  STYLE_BORDER_YELLOW_PROPOTION,
  STYLE_INPUT,
  STYLE_INPUT_SLIDER,
  STYLE_PADDING_MAIN,
  STYLE_TEXT_CYAN,
} from "../../../utils/useStyle";
import { useState } from "react";
import numeral from "numeral";
import { ICON_ARROW_UP_DOWN } from "../../../utils/useIcons";
import { HeadtitleCard } from "../../../components/header/Header";
import { SliderSingle } from "../../../components/slider/SliderSingle";
import { SecondSlider } from "../../../components/slider/SecondSlider.Main";
import { useInsuranceStore } from "../../../_store/insuranceStore";
import NumbericValidateRed from "../../../components/DebtBalance/numericRed";
import { RadioMain } from "../../../components/radio/Radio.Main";

const ViewDisable_StepThree = ({ onChangeForm, onChangeAge }) => {
  const insuranceData = useInsuranceStore((state) => state.insuranceData);

  return (
    <div>
      <div className={`${STYLE_PADDING_MAIN} my-3 space-y-5 `}>
        <CardMain
          title={"อายุ"}
          panel={
            <div>
              <div className=" px-3 space-y-1">
                <div className=" grid grid-cols-2 justify-center items-center">
                  <div className=" mx-auto flex flex-col justify-center items-center gap-1">
                    <img
                      src="/images/present_age2.png"
                      className=" mx-auto w-[50px] h-[50px]"
                      alt=""
                    />
                    <div className="mt-2 text-sm font-semibold text-maincyan">
                      อายุปัจจุบัน
                    </div>

                    <NumbericValidateRed
                      maxLength={3}
                      placeholder="0"
                      pattern="[0-9],*"
                      className={`${STYLE_INPUT_SLIDER} `}
                      onChange={onChangeForm("disabled_current_age")}
                      value={insuranceData?.disabled_current_age}
                    />
                  </div>

                  <div className=" mx-auto flex flex-col justify-center items-center gap-1">
                    <img
                      src="/images/dead2.png"
                      className=" mx-auto w-[50px] h-[50px]"
                      alt=""
                    />
                    <div className="mt-2 text-sm font-semibold text-mainorange">
                      สิ้นอายุขัย
                    </div>

                    <NumbericValidateRed
                      maxLength={3}
                      placeholder="0"
                      pattern="[0-9],*"
                      className={`${STYLE_INPUT_SLIDER} `}
                      onChange={onChangeForm("disabled_dead_age")}
                      value={insuranceData?.disabled_dead_age}
                    />
                  </div>
                </div>
                <div className=" pt-4 w-full">
                  <SecondSlider
                    onChange={onChangeAge}
                    // onChange={onChangeAge}
                    current={insuranceData?.disabled_current_age || 25}
                    dead={insuranceData?.disabled_dead_age || 90}
                    maxdead={120}
                    // onChangeMaxDead={onChangeMaxDeadAge}
                  />
                </div>
                {/* <div className="pt-4">
                  <div className=" text-center text-mainblue font-semibold">
                    รวมรายได้ส่วนตัว
                  </div>
                  <div className=" text-center text-maincyan font-semibold text-xl ">
                    {numeral(
                      insuranceData.disabled_income_salary_per_year
                    )?.format("0,0")}{" "}
                    บาท
                  </div>
                </div> */}
              </div>
            </div>
          }
        />
        <CardMain
          title={"โดยปกติรายได้ท่านเพิ่มขึ้นปีละ (%)"}
          panel={
            <div className=" px-3 -mt-3 space-y-2.5">
              <div className=" flex justify-center items-center">
                <NumbericValidateRed
                  // type="number"
                  maxLength={11}
                  thousandSeparator=","
                  inputMode="decimal"
                  pattern="[0-9],*"
                  placeholder="0"
                  className={STYLE_INPUT_SLIDER}
                  value={insuranceData?.disabled_income_year}
                  onChange={onChangeForm("disabled_income_year")}
                />
                <div className=" text-bluegray50 font-semibold pl-1">%</div>
              </div>
              <SliderSingle
                placeholder="0"
                valuemax={20}
                value={insuranceData?.disabled_income_year}
                onChange={onChangeForm("disabled_income_year")}
                //   value={formRetire?.salary_peryear}
                //   onChange={onChangeSalaryPerYear}
                //   onChnageMax={onChangeSalaryPerYearMax}
              />
              {/* <div>
                <div className=" text-center text-mainblue font-semibold">
                  เพื่อให้สามารถดำรงชีพได้ท่านต้องมีเงิน
                </div>
                <div className=" text-center text-maincyan font-semibold text-xl ">
                  {numeral(insuranceData.sum_disabled_have_to_money)?.format(
                    "0,0"
                  )}{" "}
                  บาท
                </div>
              </div> */}
            </div>
          }
        />
        <CardMain
          title={"ท่านสามารถลงทุนได้ผลตอบแทนต่อปี (%)"}
          panel={
            <div className=" px-3 -mt-3 space-y-2.5">
              <div className=" flex justify-center items-center">
                <NumbericValidateRed
                  maxLength={11}
                  thousandSeparator=","
                  inputMode="decimal"
                  pattern="[0-9],*"
                  placeholder="0"
                  className={STYLE_INPUT_SLIDER}
                  value={insuranceData?.disabled_return_year}
                  onChange={onChangeForm("disabled_return_year")}
                />
                <div className=" text-bluegray50 font-semibold pl-1">%</div>
              </div>
              <SliderSingle
                placeholder="0"
                valuemax={20}
                value={insuranceData?.disabled_return_year}
                onChange={onChangeForm("disabled_return_year")}
              />
               <div>
                <div className=" text-center text-mainblue font-semibold">
                  เพื่อให้สามารถดำรงชีพได้ท่านต้องมีเงิน
                </div>
                <div className=" text-center text-maincyan font-semibold text-xl ">
                  {numeral(insuranceData.sum_disabled_have_to_money)?.format(
                    "0,0"
                  )}{" "}
                  บาท
                </div>
              </div>
            </div>
          }
        />

        <CardMain
          title={
            "สินทรัพย์รวม (ยกเว้นสินทรัพย์ส่วนตัว เช่น บ้านที่อยู่อาศัย รถยนต์ที่ใช้ส่วนตัว)"
          }
          classNameLine={"mt-2"}
          panel={
            <div className=" px-3 -mt-3 space-y-2.5">
              <div className=" font-semibold text-mainblue text-center">
                <NumbericValidateRed
                  maxLength={11}
                  placeholder="25,000"
                  thousandSeparator=","
                  inputMode="decimal"
                  pattern="[0-9],*"
                  className={`${STYLE_INPUT} `}
                  onChange={onChangeForm("disabled_asset_all")}
                  value={insuranceData?.disabled_asset_all}
                />
              </div>
            </div>
          }
        />

        <CardMain
          title={"ทุนประกันทุพลภาพ"}
          panel={
            <div className=" px-3 -mt-3 space-y-2.5">
              <div className=" font-semibold text-mainblue text-center">
                <NumbericValidateRed
                  maxLength={11}
                  placeholder="25,000"
                  thousandSeparator=","
                  inputMode="decimal"
                  pattern="[0-9],*"
                  className={`${STYLE_INPUT} `}
                  value={insuranceData?.disabled_asset_insurance_private}
                  onChange={onChangeForm("disabled_asset_insurance_private")}
                />
              </div>
            </div>
          }
        />

        <CardMain
          title={"เงินกองทุนสำรองเลี้ยงชีพ"}
          panel={
            <div className=" px-3 -mt-3 space-y-2.5">
              <div className=" font-semibold text-mainblue text-center">
                <NumbericValidateRed
                  maxLength={11}
                  placeholder="25,000"
                  thousandSeparator=","
                  inputMode="decimal"
                  pattern="[0-9],*"
                  className={`${STYLE_INPUT} `}
                  value={insuranceData?.disabled_asset_invest_rmf}
                  onChange={onChangeForm("disabled_asset_invest_rmf")}
                />
              </div>
            </div>
          }
        />

        <CardMain
          title={"ท่านมีประกันสังคมหรือไม่ ?"}
          panel={
            <div className=" px-3 -mt-3 space-y-2.5">
              <div className=" flex items-center">
                <RadioMain
                  name="disabled_asset_is_social_insurance"
                  value="N"
                  disabledDesc
                  checked={
                    insuranceData.disabled_asset_is_social_insurance === "N"
                  }
                  label={
                    <div className={"font-semibold text-black"}>ไม่มี</div>
                  }
                  onChange={onChangeForm(
                    "disabled_asset_is_social_insurance",
                    "string"
                  )}
                />
                <RadioMain
                  name="disabled_asset_is_social_insurance"
                  value="Y"
                  disabledDesc
                  checked={
                    insuranceData.disabled_asset_is_social_insurance === "Y"
                  }
                  label={<div className={"font-semibold text-black"}>มี</div>}
                  onChange={onChangeForm(
                    "disabled_asset_is_social_insurance",
                    "string"
                  )}
                />
              </div>
            </div>
          }
        />
        {insuranceData?.disabled_asset_is_social_insurance === "Y" ? (
          <div className="space-y-5">
            <CardMain
              title={"เงินสงเคราะห์ประกันสังคมกรณีเสียชีวิต"}
              panel={
                <div className=" px-3 -mt-3 space-y-2.5">
                  <div className=" text-center mt-4  text-mainblue">
                    เงินสงเคราะห์ทุพพลภาพจากประกันสังคม
                    {/* <div className=" text-maincyan font-semibold text-xl">
                      {numeral(
                        insuranceData.disabled_asset_social_dead || 90000
                      ).format("0,0")}{" "}
                      บาท
                    </div> */}
                    <div className=" text-maincyan font-semibold text-xl">
                      {numeral(
                        insuranceData.disabled_asset_social_dead_pv
                      ).format("0,0")}{" "}
                      บาท
                    </div>
                  </div>
                </div>
              }
            />
            <CardMain
              title={"เงินคืนจากกองทุนประกันสังคมกรณีชราภาพ"}
              panel={
                <div className=" px-3 -mt-3 space-y-2.5">
                  <NumbericValidateRed
                    maxLength={11}
                    placeholder="25,000"
                    thousandSeparator=","
                    inputMode="decimal"
                    pattern="[0-9],*"
                    className={`${STYLE_INPUT} `}
                    value={insuranceData?.disabled_asset_social_retire}
                    onChange={onChangeForm("disabled_asset_social_retire")}
                  />
                </div>
              }
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default ViewDisable_StepThree;
