import { useNavigate } from "react-router-dom";
import { MTButton } from "../../../components/button/MTButton";
import { DonutChartProperty } from "../../../components/chart/DonutChartMain";
import { HeaderTitle } from "../../../components/header/Header";
import Container from "../../../components/layout/Container";
import { STYLE_REPORT_CONTAIN } from "../../../utils/useStyle";
import { CardReportAverage } from "../components/cardMenu";
import { HeaderReport } from "../components/header.main";
import {
  HeaderTableReport,
  HeaderTitleTableReport,
  TableReport,
} from "../components/table.main";
import { useBalanceStore } from "../../../_store/balanceStore";
import { useEffect, useMemo } from "react";
import { first_reload } from "../../tax_plan/functions/firstLoad";
import numeral from "numeral";
import { cn } from "../../../utils/useFunctions";

const ChartSection = ({ balanceData, items }) => {
  return (
    <div className=" flex flex-col items-center">
      <DonutChartProperty
        text={`สินทรัพย์`}
        className=" -mt-28"
        classNameText={
          " text-sm  absolute top-[69%] left-1/2 -translate-x-1/2 -translate-y-1/2 text-black font-IBMPlexSansThai text-center"
        }
        classNameValue={" text-base"}
        classNameDonut=" w-[260px] pb-2"
        classNameGray="bg-white h-[90px] "
        value={numeral(balanceData?.property_propotion).format("0,0")}
        data1={balanceData?.sum_property_asset}
        data2={balanceData?.sum_property_invest}
        data3={balanceData?.sum_property_private}
        data4={balanceData?.sum_property_intangible}
      />
      <div className=" flex flex-col justify-center ">
        {items.map((item, index) => {
          return (
            <div key={index} className=" flex items-center gap-2.5 font-medium">
              <div className={` ${item?.color} h-2 w-5`}></div>
              <div className="flex items-center text-xs gap-1">
                <div>
                  {item?.title}{" "}
                  <span className=" font-bold">
                    {numeral(item?.sum_per_year)?.format("0,0")}
                  </span>{" "}
                  บาท คิดเป็น{" "}
                  <div className={` ${item?.textcolor} font-bold text-sm `}>
                    {item?.sum_percent?.toFixed(2)} %
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const TableAsset = ({ items }) => {
  return (
    <div className="bg-gray50 rounded-[20px] w-full h-ful p-3">
      <HeaderTableReport
        title="แหล่งที่มาของรายจ่าย"
        textjusify="text-center"
      />
      {items.map((item, index) => {
        return (
          <div key={index} className={cn("  items-center")}>
            <div className=" col-span-full">
              <HeaderTitleTableReport
                img={`/images/${item?.icon}`}
                title={item?.title}
              />
            </div>

            <div
              className={`bg-[#F9F9F9] py-1 rounded-[10px] grid grid-cols-8 px-2 items-center font-semibold text-mainblue text-sm`}
            >
              <div className=" col-span-3 ">
                {/* <HeaderTitleTableReport
              img={`/images/${item?.icon}`}
              title={item?.title}
            /> */}
              </div>
              <div className=" col-span-2 text-right">
                {numeral(item.sum_per_month).format("0,0")}
              </div>
              <div className="col-span-3 text-right ">
                {numeral(item.sum_per_year).format("0,0")} บาท
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const ExplanSection = ({ balanceData }) => {
  return (
    <div className=" text-center flex flex-col gap-[6px]">
      <div className=" text-base font-medium ">
        หลังจากแจกแจงสินทรัพย์แล้วเราจะได้ภาพรวม ดังนี้
      </div>
      <div className="text-gray90 text-xs">
        (สินทรัพย์สภาพคล่อง + สินทรัพย์เพื่อการลงทุน + สินทรัพย์ใช้ส่วนตัว +
        สินทรัพย์ใช้ส่วนตัว = สินทรัพย์รวมต่อเดือน)
      </div>
      <div className=" text-base font-medium text-maincyan">
        สินทรัพย์รวมต่อเดือน{" "}
        <span className=" text-mainblue">
          {numeral(balanceData?.property_propotion).format("0,0")}
        </span>
      </div>
    </div>
  );
};

const ReportAssetPage = () => {
  const navigate = useNavigate();
  const balanceData = useBalanceStore((state) => state.balanceData);

  const createSumObject = (sum_per_month, sum_per_year, sum_percent) => ({
    sum_per_month: sum_per_month || 0,
    sum_per_year: sum_per_year || 0,
    sum_percent: sum_percent || 0,
  });

  const sumAsset = useMemo(() => {
    return createSumObject(
      0,
      balanceData?.sum_property_asset,
      (balanceData?.sum_property_asset / balanceData?.property_propotion) * 100
    );
  }, [balanceData]);
  const sumPropertyInvest = useMemo(() => {
    return createSumObject(
      0,
      balanceData?.sum_property_invest,
      (balanceData?.sum_property_invest / balanceData?.property_propotion) * 100
    );
  }, [balanceData]);
  const sumPropertyPrivate = useMemo(() => {
    return createSumObject(
      0,
      balanceData?.sum_property_private,
      (balanceData?.sum_property_private / balanceData?.property_propotion) *
        100
    );
  }, [balanceData]);
  const sumPropertyIntangible = useMemo(() => {
    return createSumObject(
      0,
      balanceData?.sum_property_intangible,
      (balanceData?.sum_property_intangible / balanceData?.property_propotion) *
        100
    );
  }, [balanceData]);

  const sumTotal = useMemo(
    () => balanceData?.property_propotion,
    [balanceData]
  );

  const items = useMemo(() => {
    return [
      {
        title: "สินทรัพย์สภาพคล่อง",
        icon: "proporty_asset2.png",
        classNameIcon: "w-[20px]",
        ...sumAsset,
        color: "bg-purple700",
        textcolor: "text-purple700",
      },
      {
        title: "สินทรัพย์เพื่อการลงทุน",
        icon: "proporty_invest3.svg",
        classNameIcon: "w-[20px]",
        ...sumPropertyInvest,
        color: "bg-purple400",
        textcolor: "text-purple400",
      },
      {
        title: "สินทรัพย์ใช้ส่วนตัว",
        icon: "proporty_private2.svg",
        classNameIcon: "w-[20px]",
        ...sumPropertyPrivate,
        color: "bg-purple500",
        textcolor: "text-purple500",
      },
      {
        title: "สินทรัพย์ไม่มีตัวตน",
        icon: "proporty_Intangible2.png",
        classNameIcon: "w-[20px]",
        ...sumPropertyIntangible,
        color: "bg-purple600",
        textcolor: "text-purple600",
      },
    ];
  }, [sumTotal]);

  useEffect(() => {
    let ignore = false;
    first_reload(ignore);
    return () => {
      ignore = true;
    };
  }, []);
  return (
    <Container>
      <div id="first_reload" className="pt-[14px]">
        <HeaderTitle
          title="รายงาน"
          isGoback
          onClickGoBack={() => {
            navigate("/report");
          }}
        />

        <div className={`${STYLE_REPORT_CONTAIN} flex flex-col gap-5`}>
          <HeaderReport
            icon={"/images/asset-report.png"}
            title={"สัดส่วนทรัพย์สินของคุณ"}
            className={" z-20"}
          />
          <ChartSection balanceData={balanceData} items={items} />
          {/* <CardReportAverage
            title="คุณมีสินทรัพย์เฉลี่ยรวมต่อเดือน"
            value={numeral(balanceData?.property_propotion).format("0,0")}
          /> */}
          <TableAsset items={items} />
          <ExplanSection balanceData={balanceData} />

          <div className=" flex justify-center ">
            <MTButton
              onClick={() => {
                navigate("/report");
              }}
              text="ย้อนกลับ"
              color="bg-white"
              border="border border-maincyan"
              colortext="text-maincyan group-hover:text-white"
              hovercolor="hover:bg-maincyan group "
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ReportAssetPage;
