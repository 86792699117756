import { useEffect, useState } from "react";
import { CheckTest, PopupResult, QuestionCard } from "../styles/TestCard.Main";
import { Radio } from "@material-tailwind/react";
import { MTButton } from "../../../components/button/MTButton";
import RiskPlanList from "./Risk_Plan_List";
import { ICON_INFO } from "../../../utils/useIcons";

const ViewDeadCase = ({ formRisk, setFormRisk, setMenu, setRiskData }) => {
  const [openResult, setOpenResult] = useState(false);

  //---------- ข้อหนึ่ง ----------//
  const onChangeDeadLevelPassAway = (e) => {
    let value = e.target.value;
    if (value === "high") {
      setFormRisk({
        ...formRisk,
        dead_level_pass_away_high: true,
        dead_level_pass_away_medium: false,
        dead_level_pass_away_low: false,
      });
    } else if (value === "medium") {
      setFormRisk({
        ...formRisk,
        dead_level_pass_away_high: false,
        dead_level_pass_away_medium: true,
        dead_level_pass_away_low: false,
      });
    } else {
      setFormRisk({
        ...formRisk,
        dead_level_pass_away_high: false,
        dead_level_pass_away_medium: false,
        dead_level_pass_away_low: true,
      });
    }
  };

  //---------- ข้อสอง ----------//
  const onChangeDeadPassAwayYesterday = (e) => {
    let value = e.target.value;
    if (value === "high") {
      setFormRisk({
        ...formRisk,
        dead_pass_away_yesterday_high: true,
        dead_pass_away_yesterday_medium: false,
        dead_pass_away_yesterday_low: false,
      });
    } else if (value === "medium") {
      setFormRisk({
        ...formRisk,
        dead_pass_away_yesterday_high: false,
        dead_pass_away_yesterday_medium: true,
        dead_pass_away_yesterday_low: false,
      });
    } else {
      setFormRisk({
        ...formRisk,
        dead_pass_away_yesterday_high: false,
        dead_pass_away_yesterday_medium: false,
        dead_pass_away_yesterday_low: true,
      });
    }
  };

  //---------- ข้อสาม ----------//
  const onChangeDeadManageRisk = (e) => {
    let value = e.target.value;
    if (value === "avoid") {
      setFormRisk({
        ...formRisk,
        dead_manage_risk_avoidance: true,
        dead_manage_risk_control: false,
        dead_manage_risk_accept: false,
        dead_manage_risk_transfer: false,
      });
    } else if (value === "control") {
      setFormRisk({
        ...formRisk,
        dead_manage_risk_avoidance: false,
        dead_manage_risk_control: true,
        dead_manage_risk_accept: false,
        dead_manage_risk_transfer: false,
      });
    } else if (value === "accept") {
      setFormRisk({
        ...formRisk,
        dead_manage_risk_avoidance: false,
        dead_manage_risk_control: false,
        dead_manage_risk_accept: true,
        dead_manage_risk_transfer: false,
      });
    } else {
      setFormRisk({
        ...formRisk,
        dead_manage_risk_avoidance: false,
        dead_manage_risk_control: false,
        dead_manage_risk_accept: false,
        dead_manage_risk_transfer: true,
      });
    }
  };

  //---------------------------//

  const getSum = () => {
    let sumScore = 0;
    if (formRisk?.dead_pass_away_yesterday_high) {
      sumScore += 1;
    }
    if (formRisk?.dead_pass_away_yesterday_medium) {
      sumScore += 1;
    }
    if (formRisk?.dead_manage_risk_avoidance) {
      sumScore += 1;
    }
    if (formRisk?.dead_manage_risk_control) {
      sumScore += 1;
    }
    if (formRisk?.dead_manage_risk_accept) {
      sumScore += 1;
    }
    if (formRisk?.dead_manage_risk_transfer) {
      sumScore += 1.5;
    }

    return sumScore;
  };

  useEffect(() => {
    setFormRisk({
      ...formRisk,
      dead_sum_score: getSum(),
    });
    setRiskData({
      ...formRisk,
      dead_sum_score: getSum(),
    });
  }, [getSum()]);

  useEffect(() => {
    if (openResult) {
      document?.getElementById("result_dead")?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [openResult]);

  const onClickSubmit = () => {
    setOpenResult(!openResult);
  };

  // console.log("formRisk : ", formRisk);
  // console.log("sum : ", getSum());

  return (
    <div className="mt-[14px] px-[25px]">
      <div className=" flex flex-col justify-center items-center gap-1">
        <img src="/images/risk_dead.svg" className=" w-[50px] h-[50px]" />
        <div className=" text-center text-black font-semibold ">
          กรณีเสียชีวิต
        </div>
      </div>
      <div className=" flex flex-col gap-4 mt-[14px]">
        <QuestionCard
          name={"one"}
          checkedHigh={formRisk?.dead_level_pass_away_high}
          checkedMedium={formRisk?.dead_level_pass_away_medium}
          checkedLow={formRisk?.dead_level_pass_away_low}
          formRisk={formRisk}
          onChange={onChangeDeadLevelPassAway}
          question="1. ท่านคิดว่าโอกาศที่ท่านเสียชีวิตก่อนวัยอันควรอยู่ในระดับใด"
          choice1={`สูง`}
          choice2={`กลาง`}
          choice3={`ต่ำ`}
        />
        <QuestionCard
          name={"two"}
          checkedHigh={formRisk?.dead_pass_away_yesterday_high}
          checkedMedium={formRisk?.dead_pass_away_yesterday_medium}
          checkedLow={formRisk?.dead_pass_away_yesterday_low}
          formRisk={formRisk}
          onChange={onChangeDeadPassAwayYesterday}
          question="2. ถ้าเมื่อวานท่านเกิดเหตุเสียชีวิตก่อนวัยอันควร จะมีผลกระทบการเงินกับครอบครัว
          ในระดับใด"
          choice1={`สูง`}
          choice2={`กลาง`}
          choice3={`ต่ำ`}
        />
        <QuestionCard
          name={"three"}
          checkedAvoidance={formRisk?.dead_manage_risk_avoidance}
          checkedControl={formRisk?.dead_manage_risk_control}
          checkedAccept={formRisk?.dead_manage_risk_accept}
          checkedTransfer={formRisk?.dead_manage_risk_transfer}
          formRisk={formRisk}
          onChange={onChangeDeadManageRisk}
          position={"verticle"}
          question={
            <div className="flex gap-1.5">
              3.
              ท่านมีวิธีการในการจัดการความเสี่ยงกรณีเสียชีวิตก่อนวัยอันควรในปัจจุบันอย่างไร
              <a href="https://www.youtube.com/watch?v=PlqpT01BoIk">
                <ICON_INFO className={`flex-none text-gray90 w-6 h-6`} />
              </a>
            </div>
          }
          choice1={`หลีกเลี่ยงความเสี่ยง`}
          choice2={`ควบคุมความเสี่ยง`}
          choice3={`รับความเสี่ยง`}
          choice4={`โอนความเสี่ยง`}
        />
      </div>

      <div className="flex justify-center my-6">
        <MTButton
          text="แสดงผลลัพธ์"
          // loading={loading}
          onClick={onClickSubmit}
        />
      </div>

      {openResult && (
        <div className="flex flex-col">
          <div className=" text-mainblue font-semibold text-center mt-6 mb-4 ">
            ผลลัพธ์วางแผนกรณีเสียชีวิต
          </div>

          {formRisk?.dead_sum_score === 1 && (
            <PopupResult
              img="/images/good1.png"
              classNameImg={`h-[52px] mt-[7px]`}
              text={`ท่านมีความสามารถในการรับ`}
              text2={`ความเสี่ยงไว้เองได้`}
            />
          )}

          {formRisk?.dead_sum_score === 1.5 && (
            <PopupResult
              img="/images/good2.png"
              classNameImg={`h-[52px] mt-[7px]`}
              text={`'โอนความเสี่ยง' ท่านเลือกวิธีจัดการความเสี่ยง`}
              text2={`ได้เหมาะสมท่านมีความสามารถในการรับ`}
              text3={`ความเสี่ยงไว้เองได้`}
            />
          )}

          {formRisk?.dead_sum_score === 2 && (
            <PopupResult
              img="/images/car_shield.png"
              classNameImg={`h-[52px] mt-[7px]`}
              text={`แนะนำให้ท่านโอนความเสี่ยง`}
              text2={`ด้วยการทำประกันชีวิตและประกันอุบัติเหตุ`}
            />
          )}

          {formRisk?.dead_sum_score === 2.5 && (
            <PopupResult
              img="/images/car_good_shield.png"
              classNameImg={`h-[52px] mt-[7px]`}
              text={`'โอนความเสี่ยง' ท่านเลือกวิธีจัดการความเสี่ยง
        ได้เหมาะสม  ท่านมีความสามารถในการรับ`}
              text2={`ความเสี่ยงไว้เองได้`}
            />
          )}

          <div className=" mt-6">
            <div className=" text-black text-center font-medium">
              แบบทดสอบวิธีจัดการความเสี่ยงของท่าน
            </div>
            <RiskPlanList formRisk={formRisk} setMenu={setMenu} />
          </div>
        </div>
      )}
      <div className="mt-6" id="result_dead"></div>
    </div>
  );
};
export default ViewDeadCase;
