import numeral from "numeral";
import { CardMain } from "../../../components/card/CardMain";
import { ImageMain } from "../../../components/img/Img.Main";
import {
  STYLE_FLEX_DEBT_TITLE,
  STYLE_TITLE_DEBT_CARD,
  STYLE_TOTAL_DEBT_CARD,
} from "../../../utils/useStyle";
import _ from "lodash";
import { cn, toFloat2 } from "../../../utils/useFunctions";
import React from "react";

export const CardStepper = ({ index, isFirst, details }) => {
  return (
    <div>
      <div className="mx-auto w-[144px] h-[140px]">
        <ImageMain className=" w-full" src="../images/debt_img.png" />
      </div>
      {isFirst ? (
        <div className=" text-center font-semibold text-xl text-maincyan mt-5 ">
          {" "}
          คุณลงทะเบียนหนี้สินแล้วหรือยัง ?{" "}
        </div>
      ) : (
        <div>
          <div className=" text-center font-semibold text-xl text-maincyan my-5 ">
            {" "}
            ขั้นตอนที่ {index}{" "}
          </div>
          <div className=" bg-gray50 rounded-[20px] p-4 text-mainblue text-center ">
            {details}
          </div>
        </div>
      )}
    </div>
  );
};

export const CardDebt = ({
  title,
  total_start_overdue,
  total_overdue,
  onClick,
  interest_loan_peryear,
  type_payment,
  type_payment_ori,
  total_payment,
  period,
  total_creditor,
  total_interest_loan,
  total_loan,
  total_creditor2,
  total_interest_loan2,
  total_loan2,
  typepayment,
  name = "",

  //หนี้นอกระบบ//
  informal_debt,
  interest_loan_permonth,
  compounding,
  isCreditCard = false,
  options_debt_short = "",
  pay_permonth,
  year,
  compounding_type,
  periodCustom,
  number_of_days,
  typeDebt = "",
  stylehead,
  classNamePanel,
  classNameTitle = "",
}) => {
  // แปลงจำนวนงวดเป็น กี่ปีกี่เดือน
  const formatPeriodTime = (period) => {
    // if (["place", "long_other"].includes(typeDebt)) {
    //   return `${period} ปี`;
    // }

    let years = Math.floor(period / 12);
    let remainingMonths = period % 12;
    if (years > 0) {
      return `${years} ปี ${remainingMonths.toFixed(0)} เดือน`;
    } else {
      return `${remainingMonths?.toFixed(0)} เดือน`;
    }
  };

  const amountInterest = total_overdue * (interest_loan_peryear / 100);

  return (
    <div>
      <CardMain
        // marginyellow="mt-3"
        stylehead={stylehead}
        title={title || "บัตรเครดิต"}
        panel={
          <div className={cn(" px-3 -mt-3 space-y-2.5 ml-4", classNamePanel)}>
            <div className="text-md text-gray-800 font-semibold">{name}</div>
            {/* <div className={STYLE_FLEX_DEBT_TITLE}>
              <div className={STYLE_TITLE_DEBT_CARD}>จำนวนเงินต้นค้างชำระ</div>
              <div className={STYLE_TOTAL_DEBT_CARD}>
                {numeral(total_start_overdue)?.format("0,0")} บาท
              </div>
            </div> */}
            {informal_debt ? (
              <div className=" space-y-2.5">
                <div className={cn(STYLE_FLEX_DEBT_TITLE, classNameTitle)}>
                  <div className={STYLE_TITLE_DEBT_CARD}>
                    อัตราดอกเบี้ยเงินกู้ต่องวด
                  </div>
                  <div className={STYLE_TOTAL_DEBT_CARD}>
                    {interest_loan_permonth} %
                  </div>
                </div>
                <div className={cn(STYLE_FLEX_DEBT_TITLE, classNameTitle)}>
                  <div className={STYLE_TITLE_DEBT_CARD}>การคิดทบต้น</div>
                  <div className={`text-mainblue font-medium`}>
                    {compounding}
                  </div>
                </div>
                <div className={cn(STYLE_FLEX_DEBT_TITLE, classNameTitle)}>
                  <div className={STYLE_TITLE_DEBT_CARD}>
                    เปรียบเทียบเป็นอัตราดอกเบี้ย
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className={cn(STYLE_FLEX_DEBT_TITLE, classNameTitle)}>
              <div className={STYLE_TITLE_DEBT_CARD}>
                อัตราดอกเบี้ยเงินกู้
                {!["debt_shortterm_other"].includes(options_debt_short) &&
                  "ต่อปี"}
                {["debt_shortterm_other"].includes(options_debt_short) &&
                  "ต่อเดือน"}
              </div>
              <div className={STYLE_TOTAL_DEBT_CARD}>
                {numeral(interest_loan_peryear).format("number")} %
              </div>
            </div>

            {options_debt_short === "debt_informal_shortterm" ? (
              <React.Fragment>
                <div className={cn(STYLE_FLEX_DEBT_TITLE, classNameTitle)}>
                  <div className={STYLE_TITLE_DEBT_CARD}>จำนวนเงินต้น</div>
                  <div className={STYLE_TOTAL_DEBT_CARD}>
                    {numeral(total_overdue)?.format("number")} บาท
                  </div>
                </div>
                <div className={cn(STYLE_FLEX_DEBT_TITLE, classNameTitle)}>
                  <div className={STYLE_TITLE_DEBT_CARD}>ชำระวันละ </div>
                  <div className={STYLE_TOTAL_DEBT_CARD}>
                    {numeral(pay_permonth)?.format("number")} บาท
                  </div>
                </div>
                <div className={cn(STYLE_FLEX_DEBT_TITLE, classNameTitle)}>
                  <div className={STYLE_TITLE_DEBT_CARD}>ระยะเวลา </div>
                  <div className={STYLE_TOTAL_DEBT_CARD}>
                    {number_of_days} วัน
                  </div>
                </div>
                {/* <div className={cn(STYLE_FLEX_DEBT_TITLE, classNameTitle)}>
                  <div className={STYLE_TITLE_DEBT_CARD}>การคิดทบต้น</div>
                  <div className={STYLE_TOTAL_DEBT_CARD}>
                    {compounding_type}
                  </div>
                </div> */}
                {/* <div className={cn(STYLE_FLEX_DEBT_TITLE, classNameTitle)}>
                  <div className={STYLE_TITLE_DEBT_CARD}>เปรียบเทียบเป็นอัตราดอกเบี้ยเป็นรายปี</div>
                  <div className={STYLE_TOTAL_DEBT_CARD}>
                    <span className="text-mainblue"> {type_payment} </span>
                    xx%
                  </div>
                </div> */}
                {/* <div className={cn(STYLE_FLEX_DEBT_TITLE, classNameTitle)}>
                  <div className={STYLE_TITLE_DEBT_CARD}>ระยะเวลาที่จะชำระหมด</div>
                  <div className={STYLE_TOTAL_DEBT_CARD}>
                    {year} ปี
                  </div>
                </div> */}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className={cn(STYLE_FLEX_DEBT_TITLE, classNameTitle)}>
                  <div className={STYLE_TITLE_DEBT_CARD}>จำนวนเงินค้างชำระ</div>
                  <div className={STYLE_TOTAL_DEBT_CARD}>
                    {numeral(total_overdue)?.format("number")} บาท
                  </div>
                </div>
                <div className={cn(STYLE_FLEX_DEBT_TITLE, classNameTitle)}>
                  <div className={STYLE_TITLE_DEBT_CARD}>
                    {type_payment_ori === "pay_other" &&
                    options_debt_short === "creditcard"
                      ? "หากผ่อนเดือนละ"
                      : options_debt_short === "creditcard"
                      ? "หากผ่อนชำระขั้นต่ำ"
                      : "หากผ่อนชำระ"}
                  </div>
                  <div className="flex flex-col items-end">
                    <div className={STYLE_TOTAL_DEBT_CARD}>
                      {numeral(total_payment)?.format("number")} บาท
                    </div>
                    {type_payment_ori === "pay_minimum" && (
                      <div
                        className={cn("text-mainblue text-xs", classNameTitle)}
                      >
                        (8% ของยอดค้างชำระแต่ละเดือน)
                      </div>
                    )}
                    {type_payment_ori === "pay_other" && (
                      <div
                        className={cn("text-mainblue text-xs", classNameTitle)}
                      >
                        (จ่ายเท่ากันทุกเดือน)
                      </div>
                    )}
                  </div>
                </div>
                <div className={cn(STYLE_FLEX_DEBT_TITLE, classNameTitle)}>
                  <div className={STYLE_TITLE_DEBT_CARD}>ระยะเวลา</div>
                  {periodCustom ? (
                    <div className={STYLE_TOTAL_DEBT_CARD}>
                      {`${periodCustom} งวด (${formatPeriodTime(
                        periodCustom
                      )})`}
                    </div>
                  ) : (
                    <div className={STYLE_TOTAL_DEBT_CARD}>
                      {String(period) === "Infinity"
                        ? "ไม่มีที่สิ้นสุด"
                        : `${period?.toFixed(0)} งวด (${formatPeriodTime(
                            period
                          )})`}
                    </div>
                  )}
                </div>
                {options_debt_short === "creditcard" &&
                  amountInterest >= total_payment &&
                  type_payment_ori === "pay_minimum" && (
                    <div className={cn(STYLE_FLEX_DEBT_TITLE, classNameTitle)}>
                      <div className={cn(STYLE_TITLE_DEBT_CARD)}>
                        * เมื่อคุณมีหนี้บัตรเครดิตแล้วชำระขั้นต่ำทุกงวด
                        จะทำให้ใช้ระยะเวลานาน ในการชำระ
                      </div>
                    </div>
                  )}
              </React.Fragment>
            )}

            {/* {options_debt_short === "debt_informal_shortterm" && {}} */}

            {/* ---------------- โดยไม่ก่อภาระหนี้สินเพิ่ม --------------- */}

            <div className=" w-full flex gap-[5px] items-center ">
              <div className="border-t border-bluegray50 w-full"></div>
              <div className=" text-center text-maingray font-medium text-xs flex-none">
                โดยไม่ก่อภาระหนี้สินเพิ่ม
              </div>
              <div className="border-t border-bluegray50 w-full"></div>
            </div>

            {options_debt_short === "debt_shortterm_other" &&
              amountInterest >= total_payment && (
                <div className={cn(STYLE_FLEX_DEBT_TITLE, classNameTitle)}>
                  <div className={STYLE_TITLE_DEBT_CARD}>ในระยะ 1 ปี</div>
                </div>
              )}

            <div className={cn(STYLE_FLEX_DEBT_TITLE, classNameTitle)}>
              <div className={STYLE_TITLE_DEBT_CARD}>
                เจ้าหนีั้จะได้เงินไปทั้งสิ้น
              </div>
              <div className={STYLE_TOTAL_DEBT_CARD}>
                {numeral(total_creditor)?.format("number") === "NaN"
                  ? "0"
                  : numeral(total_creditor)?.format("number")}{" "}
                บาท
              </div>
            </div>
            <div className={cn(STYLE_FLEX_DEBT_TITLE, classNameTitle)}>
              <div className={STYLE_TITLE_DEBT_CARD}>เป็นดอกเบี้ยเงินกู้</div>
              <div className={STYLE_TOTAL_DEBT_CARD}>
                {numeral(total_interest_loan)?.format("number") === "NaN"
                  ? "0"
                  : numeral(total_interest_loan)?.format("number")}{" "}
                บาท
              </div>
            </div>
            <div className={cn(STYLE_FLEX_DEBT_TITLE, classNameTitle)}>
              <div className={STYLE_TITLE_DEBT_CARD}>จากเงินกู้</div>
              <div className={STYLE_TOTAL_DEBT_CARD}>
                {numeral(total_loan)?.format("number") === "NaN"
                  ? "0"
                  : numeral(total_loan)?.format("number")}{" "}
                บาท
              </div>
            </div>

            {options_debt_short === "debt_shortterm_other" &&
              amountInterest >= total_payment && (
                <>
                  <div className={cn(STYLE_FLEX_DEBT_TITLE, classNameTitle)}>
                    <div className={STYLE_TITLE_DEBT_CARD}>ในระยะ 5 ปี</div>
                  </div>
                  <div className={cn(STYLE_FLEX_DEBT_TITLE, classNameTitle)}>
                    <div className={STYLE_TITLE_DEBT_CARD}>
                      เจ้าหนีั้จะได้เงินไปทั้งสิ้น
                    </div>
                    <div className={STYLE_TOTAL_DEBT_CARD}>
                      {numeral(total_creditor2)?.format("number") === "NaN"
                        ? "0"
                        : numeral(total_creditor2)?.format("number")}{" "}
                      บาท
                    </div>
                  </div>
                  <div className={cn(STYLE_FLEX_DEBT_TITLE, classNameTitle)}>
                    <div className={STYLE_TITLE_DEBT_CARD}>
                      เป็นดอกเบี้ยเงินกู้
                    </div>
                    <div className={STYLE_TOTAL_DEBT_CARD}>
                      {numeral(total_interest_loan2)?.format("number") === "NaN"
                        ? "0"
                        : numeral(total_interest_loan2)?.format("number")}{" "}
                      บาท
                    </div>
                  </div>
                  <div className={cn(STYLE_FLEX_DEBT_TITLE, classNameTitle)}>
                    <div className={STYLE_TITLE_DEBT_CARD}>จากเงินกู้</div>
                    <div className={STYLE_TOTAL_DEBT_CARD}>
                      {numeral(total_loan2)?.format("number") === "NaN"
                        ? "0"
                        : numeral(total_loan2)?.format("number")}{" "}
                      บาท
                    </div>
                  </div>
                </>
              )}

            {isCreditCard && amountInterest >= total_payment && (
              <div
                className={`${cn(
                  STYLE_FLEX_DEBT_TITLE,
                  classNameTitle
                )} text-maincyan`}
              >
                สมมติฐาน กรณีชำระขั้นต่ำ จะชำระ 8% ของเงินค้างชำระในรอบเดือน
                กรณีชำระแบบระบุจำนวนเงิน
                จะชำระตามจำนวนเงินที่ระบุเท่ากันทุกเดือน
              </div>
            )}

            {amountInterest > total_payment &&
              options_debt_short === "debt_shortterm_other" && (
                <div
                  className={`${cn(
                    STYLE_FLEX_DEBT_TITLE,
                    classNameTitle
                  )} text-maincyan`}
                >
                  หากคุณยังชำระงวดละ {numeral(pay_permonth)?.format("number")}{" "}
                  บาท อาจจะมีปัญหากับเจ้าหนี้ได้และจะไม่มีทาง หมดหนี้
                  ยกเว้นนำเงินต้นทั้งหมดมาชำระคืนเจ้าหนี้
                </div>
              )}

            {amountInterest === total_payment &&
              options_debt_short === "debt_shortterm_other" && (
                <div
                  className={`${cn(
                    STYLE_FLEX_DEBT_TITLE,
                    classNameTitle
                  )} text-maincyan`}
                >
                  หากคุณยังชำระงวดละ {numeral(pay_permonth)?.format("number")}{" "}
                  บาท จะไม่มีทางหมดหนี้ยกเว้นนำเงินต้น ทั้งหมดมาชำระคืนเจ้าหนี้
                </div>
              )}

            {/* <div
              onClick={onClick}
              className=" flex justify-center items-center text-base text-mainblue font-semibold underline underline-offset-2 hover:text-secondcyan"
            >
              ดูรายละเอียด
            </div> */}
          </div>
        }
      />
    </div>
  );
};
