import { useRetireStore } from "../_store/retireStore";

export function decodeAccessToken(accessToken) {
  // Access token is usually in the format "Bearer <token>". Extracting just the token part.
  const token = accessToken.split(" ")[1];

  try {
    // Using Buffer.from for base64 decoding
    const decodedBuffer = Buffer.from(token, "base64");

    // Converting the decoded buffer to a string
    const decodedPayload = decodedBuffer.toString("utf-8");

    // Parsing the JSON payload
    const parsedPayload = JSON.parse(decodedPayload);

    return parsedPayload;
  } catch (error) {
    console.error("Error decoding access token:", error);
    return null;
  }
}

export const normalrizeDatatoLabel = (datas = [], labels = []) => {
  const normalizedArray = [];
  // console.log("datas", datas);

  // console.log("test" , retireData?.bf_investment_assets)

  //   let currentY = 0;
  //   for (let i = 0; i < datas.length; i++) {
  //     let temp = datas[i];
  //     if (temp.age > labels[currentY] + 1) {
  //       currentY++;
  //     }
  //     temp.age = labels[currentY];
  //     normalizedArray.push(temp);
  //   }

  for (let i = 0; i < labels.length; i++) {
    for (let j = 0; j < datas.length; j++) {
      if (datas[j].age === labels[i]) {
        // console.log("add " + datas[j].age + " " + datas[j].results);
        normalizedArray.push(datas[j].results);
        break;
      }
    }
  }

  // console.log("normalizedArray", normalizedArray);
  return normalizedArray;
};

const checkInterestPoint = (dataPlan) => {
  const interestPoints = [];
  let color = "green";

  //red
  for (let index = 0; index < dataPlan?.length; index++) {
    const currentValue = dataPlan[index]?.results;
    const nextValue = dataPlan[index + 1]?.results;

    // red
    if (dataPlan[index]?.results <= 0) {
      if (nextValue > currentValue && currentValue < 0 && nextValue > 0) {
        interestPoints?.push(dataPlan[index]?.age);
        continue;
      }
      // interestPoints?.push(dataPlan[index]?.age);
      // continue;
    }

    if (dataPlan[index]?.results < dataPlan[index - 1]?.results) {
      // yellow => green
      if (nextValue > currentValue) {
        interestPoints?.push(dataPlan[index]?.age);
        continue;
      }

      //yellow => red
      if (nextValue < currentValue && nextValue < 0 && color !== "red") {
        interestPoints?.push(dataPlan[index]?.age);
        color = "red";
        continue;
      }

      // console.log(`currentValue: `, currentValue);
      // console.log(`nextValue: `, nextValue);
    } else {
      //green => yellow

      if (nextValue < currentValue && nextValue > 0 && color !== "yellow") {
        interestPoints?.push(dataPlan[index]?.age);
        color = "yellow";
        // yellow.push(null);
        continue;
      }
      //green
    }
  }
  return interestPoints;
};

export const normalLizeLabel = (labels, limit, dataPlan) => {
  let newRange = Math.ceil(labels.length / limit);
  // return [
  //   25, 29, 34, 36, 41, 45, 49, 53, 57, 61, 65, 69, 73, 77, 81, 85, 89, 93, 97,
  //   100,
  // ];

  let normalizedArray = [];
  let begin = labels[0];
  let last = labels[labels - 1];
  let interestPoints = checkInterestPoint(dataPlan);
  let isAdjustBegin = false;
  let isAdjustEnd = false;

  // // for (let i = 0; i < limit; i++) {
  // //   if (begin + newRange * i > labels[labels.length - 1]) break;
  // //   normalizedArray.push(begin + newRange * i);
  // // }
  // for (let i = 0; i <= limit; i++) {
  //   // Change condition from i < limit to i <= limit
  //   if (begin + newRange * i > labels[labels.length - 1]) break;
  //   normalizedArray.push(begin + newRange * i);
  // }

  for (let i = 0; i < limit; i++) {
    // Change condition from i <= limit to i < limit
    if (begin + newRange * i > labels[labels.length - 1]) {
      // If the next label goes beyond the last label, push the last label and break
      // console.log("normalLizeLabel : ", normalizedArray);

      if (i === last) {
        return;
      } else {
        normalizedArray.push(labels[labels.length - 1]);
      }
      break;
    }
    // if (begin + newRange * i > interestPoints[0] && isAdjustBegin === false) {
    //   normalizedArray.push(interestPoints[0]);
    //   isAdjustBegin = true;
    // } else
    let newLabel = begin + newRange * i;
    let gap = Math.ceil(newRange / 2);

    let isAdjust = false;

    for (let index = 0; index < interestPoints.length; index++) {
      if (
        (newLabel - interestPoints[index] <= gap &&
          newLabel - interestPoints[index] > 0) ||
        (interestPoints[index] - newLabel <= gap &&
          interestPoints[index] - newLabel > 0)
      ) {
        /// it closes value  and need to be replace
        console.log("Adjusstment ", gap, interestPoints[index], newLabel);
        normalizedArray.push(interestPoints[index]);
        isAdjust = true;
      }
    }

    if (!isAdjust) normalizedArray.push(newLabel);
  }

  return normalizedArray;
};

export const checkExist = (labels = [], points = []) => {
  let begin = points[0];
  let end = points[points?.length - 1];
  let isBeginFound = false;
  let isEndFound = false;

  console.log("begin", begin);
  console.log("end", end);
  console.log("points", points);

  for (let i = 0; i < labels?.length; i++) {
    if (labels?.[i] === begin) {
      isBeginFound = true;
    }

    if (labels?.[i] > begin && !isBeginFound) {
      // points[0] = labels?.[i - 1];
      console.log("adjust Begin");
      // isBeginFound = true;
    }

    if (labels?.[i] === end) {
      isEndFound = true;
    }

    if (labels?.[i] > begin && !isEndFound) {
      console.log("adjust End");
      // points[points?.length - 1] = labels?.[i];
      break;
    }
  }

  return points;
};

export const roundUp = (number, digits) => {
  var multiplier = Math.pow(10, digits);
  return Math.ceil(number * multiplier) / multiplier;
};

export const defaultValue = (value, defaultValue = "") => {
  return value ? value : defaultValue;
};

// FV=PV*(1+r)^n
export const PV = (pv, r, n) => {
  return pv * Math.pow(1 + r, n);
}


// export const generateLabel = (interestPoints = [], numOfPoint = 20) => {
//   let pointsBetween = numOfPoint / interestPoints.length - 2;

//   for (let i = 0; i < interestPoints.length - 2; i++) {
//     let beginPoint = test[i];
//     let endPoint = test[i + 1];
//     let range = (endPoint - beginPoint) / pointsBetween;

//     interestPoints.push(beginPoint);
//     for (let j = 1; j <= pointsBetween; j++)
//       interestPoints.push(beginPoint + range * j);
//   }

//   // interestPoints.push(endPoint);
//   return interestPoints;
// };
