import React, { useEffect, useMemo, useState } from "react";
import { MTButton } from "../../../components/button/MTButton";
import { CardMain } from "../../../components/card/CardMain";
import {
  STYLE_INPUT,
  STYLE_INPUT_ERROR,
  STYLE_TITE_DRAWER_INFO,
} from "../../../utils/useStyle";

import numeral from "numeral";
import { calculateTax, calculateTaxProperty } from "../functions";
import ErrorMain from "../../../components/error/Error.Main";
import { NumericFormat } from "react-number-format";
import { Drawer } from "@material-tailwind/react";
import { ICON_NAVIGATE_RIGHT } from "../../../utils/useIcons";
import { InfoDrawer } from "../../../components/drawer/InfoDrawer";
import ViewStepOnePremium from "./Premium/VIStep1_Premium";
import NumbericValidateRed from "../../../components/DebtBalance/numericRed";
import { useUserStore } from "../../../_store/userStore";
import { useTaxStore } from "../../../_store/taxplanStore";
import _ from "lodash";
import { numberWithoutComma } from "../../balance/component/VIDrawerDebt.Main";

export const IncomeInfo = ({ openIncomeInfo, setOpenIncomeInfo }) => {
  return (
    <InfoDrawer
      head={" เงินเดือน (ต่อปี)"}
      text={
        // " รายได้ตลอดทั้งปีจะมาจากเงินเดือนในแต่ละเดือนที่นายจ้างมีการจ่ายเป็นค่าจ้าง ลบด้วยค่าใช้จ่ายของรายได้จากงานประจำ 50% แต่ไม่เกิน 100,000 บาท ลบด้วยลดหย่อนส่วนตัว คนละ 60,000 บาท"

        <div className=" space-y-2">
          <div className={STYLE_TITE_DRAWER_INFO}>รายได้จากเงินเดือน</div>
          <li>
            เงินได้ตามมาตรา 40(1) เงินได้เนื่องจากการจ้างแรงงาน เช่น เงินเดือน
            ค่าจ้าง เบี้ยเลี้ยง โบนัส เบี้ยหวัด
            เงินค่าเช่าบ้านและสินทรัพย์สินหรือประโยชน์ใด ๆ
            ที่ได้เนื่องจากการจ้างแรงงานเป็นต้น{" "}
            <div className=" text-sm  mt-2">
              {" "}
              *** กรณีได้รับเงินเดือนและค่านายหน้าจากนายจ้างผู้จ่ายเงินได้
              รายเดียวกันถือเป็นเงินได้ตามมาตรา 40(1)
            </div>
          </li>
          <div className=" text-center pt-2 text-mainblue text-sm">
            หากมีรายได้มาตราอื่น 40(2) - 40(8)
            <div className=" text-base mt-1">
              ท่านสามารถสมัครโหมดพรีเมี่ยม{" "}
              <span
                onClick={() => {
                  window?.open(
                    "https://liff.line.me/1645278921-kWRPP32q/?accountId=assurewealth"
                  );
                }}
                className=" font-medium underline underline-offset-4 text-maincyan "
              >
                ได้ที่นี่
              </span>
            </div>
          </div>
        </div>
      }
      open={openIncomeInfo}
      onClose={() => {
        setOpenIncomeInfo(false);
      }}
    />
  );
};

const ViewStepOneTaxPlan = ({
  error,
  setError,
  form,
  setForm,
  openIncomeInfo,
  setOpenIncomeInfo,
  userData,
}) => {
  const taxData = useTaxStore((state) => state.taxData);

  const onIncomeChange = (e) => {
    e?.preventDefault();
    const value = e.target.value;
    let output = parseFloat(value?.replace(/,/g, ""))
      ? parseFloat(value?.replace(/,/g, ""))
      : null;
    if (isNaN(output)) return;
    if (Number(output) < 0) return;
    if (Number(output) > 1000000000) return;
    else {
      setError({
        ...error,
        tax_income: false,
      });
    }
    let tax_paid = calculateTax(output);

    if (userData?.is_premium) {
      tax_paid = calculateTax(sumIncome() || 0);
    }
    setForm({
      ...form,
      tax_income: output,
      tax_paid: tax_paid,
    });
  };

  const onWithoutTaxChange = (e) => {
    e?.preventDefault();
    const value = e.target.value;
    let output = parseFloat(value?.replace(/,/g, ""))
      ? parseFloat(value?.replace(/,/g, ""))
      : 0;
    if (isNaN(output)) return;
    if (Number(output) < 0) return;
    if (Number(output) > 1000000000) return;
    else {
      setError({
        ...error,
        tax_withholding: false,
      });
    }

    setForm({
      ...form,
      tax_withholding: output,
    });
  };

  const sumIncome = () => {
    let salary_Income = numberWithoutComma(_.get(taxData, "tax_income", 0) || 0);
  let freeLance_Income = Number(taxData?.freelance?.tax_income || 0);
  let freeJob_Income = taxData?.freeJob?.reduce((total, num) => {
    return total + Number(num?.tax_income || 0);
  }, 0);

  let income_tax_403 = taxData?.tax_403?.reduce((total, num) => {
    return total + Number(num?.tax_income || 0);
  }, 0);

  let income_tax_405 = taxData?.tax_405?.reduce((total, num) => {
    return total + Number(num?.tax_income || 0);
  }, 0);

  let income_tax_407 = taxData?.tax_407?.reduce((total, num) => {
    return total + Number(num?.tax_income || 0);
  }, 0);

  let income_tax_408 = taxData?.tax_408?.reduce((total, num) => {
    return total + Number(num?.tax_income || 0);
  }, 0);

  let income_tax_408_property = taxData?.tax_408_property?.reduce(
    (total, num) => {
      return total + Number(num?.tax_income || 0);
    },
    0
  );

  let income_tax_404 = taxData?.tax_404?.reduce((total, num) => {
    return total + Number(num?.tax_income || 0);
  }, 0);

  // tax_404_stock
  let income_tax_404_stock = taxData?.tax_404_stock?.reduce((total, num) => {
    if (num?.type_job?.label === "หุ้น") {
      return total + Number(num?.dividend || 0) + Number(num?.tax_credit || 0);
    }
    return total + Number(num?.dividend || 0);
  }, 0);

  let income_not_include_tax = _.get(taxData, "income_not_include_tax", 0);

  let RMF_Profit =
    Number(taxData?.RMF_Profit?.selling_price || 0) -
    Number(taxData?.RMF_Profit?.cost_price || 0);
  let SSF_Profit =
    Number(taxData?.SSF_Profit?.selling_price || 0) -
    Number(taxData?.SSF_Profit?.cost_price || 0);

  // console.log("heritage", taxData?.heritage);
  let Income_42_26 = 0;
  let Income_42_27 = 0;
  let Income_42_28 = 0;

  if (!taxData?.heritage?.tax_calc_include) {
    Income_42_26 = Number(taxData?.heritage?.income_42_26 || 0);
    Income_42_27 = Number(taxData?.heritage?.income_42_27 || 0);
    Income_42_28 = Number(taxData?.heritage?.income_42_28 || 0);
  }

  let tax408_property = taxData?.tax_408_property?.reduce((total, num) => {
    // if (num?.calculating_way === "ไม่แยกคำนวณ") {
    //   return total + Number(num?.tax_income || 0);
    // }
    // return total;

    return total + Number(num?.tax_income || 0);
  }, 0);

  return (
    Number(salary_Income || 0) +
    Number(freeJob_Income || 0) +
    Number(freeLance_Income || 0) +
    Number(income_tax_403 || 0) +
    Number(income_tax_405 || 0) +
    Number(income_tax_407 || 0) +
    Number(income_tax_408 || 0) +
    // income_tax_408_property +
    Number(income_tax_404 || 0) +
    Number(income_tax_404_stock || 0) +
    Number(income_not_include_tax || 0) +
    Number(RMF_Profit || 0) +
    Number(SSF_Profit || 0) +
    Number(Income_42_26 || 0) +
    Number(Income_42_27 || 0) +
    Number(Income_42_28 || 0) +
    Number(tax408_property || 0)
  );
  };

  useEffect(() => {
    // if (userData?.is_premium) {
    //   tax_paid = calculateTax(sumIncome() || 0);
    // }
    setForm({
      ...form,
      tax_sum_income: sumIncome(),
    });
  }, [taxData]);

  // console.log("taxData", taxData);

  return (
    <div className=" my-9">
      {userData?.is_premium ? (
        //-------------------- Premium --------------------//
        <React.Fragment>
          <div className=" -mt-2 flex flex-col justify-center gap-1 items-center font-IBMPlexSansThai font-medium mb-[15px]">
            <img
              src="/images/clarity_coin_bag_yellow.svg"
              className=" w-[61px] h-[61px]"
              alt=""
            />

            <div className=" text-black text-sm text-center ">
              ภาษีที่ต้องจ่าย
            </div>
            <div className=" text-maincyan text-center text-md ">
              {numeral(calculateTax(sumIncome())).format("0,0")} บาท
            </div>

            <div className=" text-xs font-normal text-maingray ">
              *ยังไม่ได้หักค่าใช้จ่ายและค่าลดหย่อนส่วนตัว
            </div>

            <div className="w-fit flex mt-[12px] ">
              <div className="text-center text-md mr-[20px] ">
                รายได้ต่อปีรวม
              </div>
              <div className=" text-maincyan text-center text-md ">
                {numeral(sumIncome()).format("0,0")} บาท
              </div>
            </div>
          </div>
          <ViewStepOnePremium form={form} setForm={setForm} />
        </React.Fragment>
      ) : (
        //-------------------- Not Premium --------------------//
        <React.Fragment>
          <div className=" flex flex-col justify-center gap-1 items-center font-IBMPlexSansThai font-medium mb-[15px]">
            {form?.tax_income > 0 && form?.tax_income <= 150000 ? (
              <img
                src="/images/clarity_coin_bag_yellow.svg"
                className=" w-[61px] h-[61px]"
                alt=""
              />
            ) : (
              <React.Fragment>
                {form?.tax_paid ? (
                  <img
                    src="/images/clarity_coin_bag_yellow.svg"
                    className=" w-[61px] h-[61px]"
                    alt=""
                  />
                ) : (
                  <img
                    src="/images/clarity_coin_bag.svg"
                    className=" w-[61px] h-[61px]"
                    alt=""
                  />
                )}
              </React.Fragment>
            )}

            <div className=" text-black text-sm text-center ">
              ภาษีที่ต้องจ่าย
            </div>

            {form?.tax_income > 0 && form?.tax_income <= 150000 ? (
              <div className=" text-mainblue ">
                *ท่านกรอกรายได้ต่อปีไม่ถึงเกณฑ์ที่ต้องเสียภาษี
              </div>
            ) : (
              <React.Fragment>
                {form?.tax_paid > 0 ? (
                  <div className=" text-maincyan text-center text-md ">
                    {numeral(form?.tax_paid).format("0,0")} บาท
                  </div>
                ) : (
                  <div className=" text-maingray text-center text-md ">
                    คำนวณจากรายได้ต่อปี
                  </div>
                )}
              </React.Fragment>
            )}

            <div className=" text-xs font-normal text-maingray ">
              *ยังไม่ได้หักค่าใช้จ่ายและค่าลดหย่อนส่วนตัว
            </div>
          </div>
          <div className=" space-y-5">
            <CardMain
              onClick={() => {
                setOpenIncomeInfo(true);
              }}
              icon={true}
              title="รายได้จากเงินเดือน  (ต่อปี)"
              panel={
                <div className="mt-5 px-3">
                  <NumericFormat
                    maxLength={11}
                    value={form?.tax_income}
                    placeholder="1,000,000"
                    onChange={onIncomeChange}
                    thousandSeparator=","
                    inputMode="decimal"
                    pattern="[0-9],*"
                    className={
                      error?.tax_income ? STYLE_INPUT_ERROR : STYLE_INPUT
                    }
                  />

                  {error?.tax_income && (
                    <ErrorMain errortext="กรุณากรอกรายได้ต่อปี" />
                  )}
                  {/* <button onClick={calculateTax}>Calculate Tax</button> */}
                  <div className="text-right text-xs text-maingray mt-2.5">
                    * รายได้ที่ยังไม่ได้หักภาษี 
                  </div>
                </div>
              }
            />

            <CardMain
              onClick={() => {
                setOpenIncomeInfo(true);
              }}
              icon={false}
              title=" ภาษีที่ถูกหัก ณ ที่จ่าย / เครดิตภาษี"
              panel={
                <div className="mt-5 px-3">
                  <NumericFormat
                    maxLength={11}
                    value={
                      form?.tax_withholding <= 0 ? "" : form?.tax_withholding
                    }
                    placeholder="100,000"
                    onChange={onWithoutTaxChange}
                    thousandSeparator=","
                    inputMode="decimal"
                    pattern="[0-9],*"
                    className={STYLE_INPUT}
                  />
                  {/* <div className="text-right text-xs text-maingray mt-2.5">
                * รายได้ที่ยังยังไม่ได้หักภาษี 
              </div> */}
                </div>
              }
            />
          </div>
        </React.Fragment>
      )}

      {openIncomeInfo && (
        <IncomeInfo
          openIncomeInfo={openIncomeInfo}
          setOpenIncomeInfo={setOpenIncomeInfo}
        />
      )}
    </div>
  );
};
export default ViewStepOneTaxPlan;
