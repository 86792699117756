import { useNavigate } from "react-router-dom";
import { CardGrayMenu } from "../../../components/card/CardMain";
import {
  STYLE_PADDING_MAIN,
  STYLE_SPACE_GRAY_MENU,
} from "../../../utils/useStyle";
import { MTButton } from "../../../components/button/MTButton";

const ViewLagacyForm = ({
  onClickManualFormOne,
  onClickManualFormTwo,
  onClickManualFormThree,
  onClickNormalFormOne,
  onClickNormalFormTwo,
  onClickNormalFormThree,
}) => {
  const navigate = useNavigate();

  return (
    <div>
      <div className={`${STYLE_SPACE_GRAY_MENU}`}>
        <CardGrayMenu
          img={"../images/lagacy_icon.png"}
          title={"พินัยกรรมแบบเขียนเอง แบบที่ 1 "}
          onClick={onClickManualFormOne}
        />
        <CardGrayMenu
          img={"../images/lagacy_icon.png"}
          title={"พินัยกรรมแบบเขียนเอง แบบที่ 2 "}
          onClick={onClickManualFormTwo}
        />
        <CardGrayMenu
          img={"../images/lagacy_icon.png"}
          title={"พินัยกรรมแบบเขียนเอง แบบที่ 3 "}
          onClick={onClickManualFormThree}
        />
        <CardGrayMenu
          img={"../images/lagacy_icon.png"}
          title={"พินัยกรรมแบบธรรมดา แบบที่ 1 "}
          onClick={onClickNormalFormOne}
        />
        <CardGrayMenu
          img={"../images/lagacy_icon.png"}
          title={"พินัยกรรมแบบธรรมดา แบบที่ 2 "}
          onClick={onClickNormalFormTwo}
        />
        <CardGrayMenu
          img={"../images/lagacy_icon.png"}
          title={"พินัยกรรมแบบธรรมดา แบบที่ 3 "}
          onClick={onClickNormalFormThree}
        />
      </div>
    </div>
  );
};

export default ViewLagacyForm;
