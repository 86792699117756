import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

const openStore = (set) => ({
  openOutcome: false,
  setOpenOutcome: (openOutcome) => set(() => ({ openOutcome })),

  openPassive: false,
  setOpenPasssive: (openPassive) => set(() => ({ openPassive })),

  openIncome: false,
  setOpenIncome: (openIncome) => set(() => ({ openIncome })),

  openAssetGen: false,
  setOpenAssetGen: (openAssetGen) => set(() => ({ openAssetGen })),

  openSalary: false,
  setOpenSalary: (openSalary) => set(() => ({ openSalary })),

  openInvestment: false,
  setOpenInvestment: (openInvestment) => set(() => ({ openInvestment })),

  openControlRate: false,
  setOpenControlRate: (openControlRate) => set(() => ({ openControlRate })),

  openRetire: false,
  setOpenRetire: (openRetire) => set(() => ({ openRetire })),

  openDead: false,
  setOpenDead: (openDead) => set(() => ({ openDead })),
});

export const useOpenStore = create(
  persist(openStore, {
    name: "openStore",
    storage: createJSONStorage(() => localStorage), // (optional) by default the 'localStorage' is used
  })
);
