export const calculateTax = (income) => {
  // Define the income ranges and tax rates
  const incomeRanges = [
    { min: 0, max: 150000, rate: 0.0 },
    { min: 150000, max: 300000, rate: 0.05 },
    { min: 300000, max: 500000, rate: 0.1 },
    { min: 500000, max: 750000, rate: 0.15 },
    { min: 750000, max: 1000000, rate: 0.2 },
    { min: 1000000, max: 2000000, rate: 0.25 },
    { min: 2000000, max: 5000000, rate: 0.3 },
    { min: 5000000, max: "Infinity", rate: 0.35 },
  ];

  // Initialize tax and previousMax
  let tax = 0;
  let previousMax = 0;

  // Loop through income ranges and calculate tax
  for (const range of incomeRanges) {
    if (income <= range.min) {
      // Income is within the current range
      tax += (income - previousMax) * range.rate;
      break;
    } else if (income <= range.max) {
      // Income is within the current and next range
      tax += (income - range.min) * range.rate;
      break;
    } else {
      // Income is higher than the current range
      tax += (range.max - range.min) * range.rate;
      previousMax = range.max;
    }
  }

  return tax;
};

export const calculateTaxProperty = (income) => {
  // Define the income ranges and tax rates
  const incomeRanges = [
    { min: 0, max: 150000, rate: 0.05 },
    { min: 150000, max: 300000, rate: 0.05 },
    { min: 300000, max: 500000, rate: 0.1 },
    { min: 500000, max: 750000, rate: 0.15 },
    { min: 750000, max: 1000000, rate: 0.2 },
    { min: 1000000, max: 2000000, rate: 0.25 },
    { min: 2000000, max: 5000000, rate: 0.3 },
    { min: 5000000, max: "Infinity", rate: 0.35 },
  ];

  // Initialize tax and previousMax
  let tax = 0;
  let previousMax = 0;

  // Loop through income ranges and calculate tax
  for (const range of incomeRanges) {
    if (income <= range.min) {
      // Income is within the current range
      tax += (income - previousMax) * range.rate;
      break;
    } else if (income <= range.max) {
      // Income is within the current and next range
      tax += (income - range.min) * range.rate;
      break;
    } else {
      // Income is higher than the current range
      tax += (range.max - range.min) * range.rate;
      previousMax = range.max;
    }
  }

  return tax;
};

// ฟังก์ชันคำนวณภาษีจากเงินได้สุทธิ
export const calculatePersonalIncomeTax = (income) => {
  // อัตราภาษีเงินได้บุคคลธรรมดา (ก้าวหน้า)
  const incomeRanges = [
    { min: 0, max: 300000, rate: 0.05 },
    { min: 300000, max: 500000, rate: 0.1 },
    { min: 500000, max: 750000, rate: 0.15 },
    { min: 750000, max: Infinity, rate: 0.2 },
  ];

  let tax = 0; // ภาษีสะสมเริ่มต้น
  let remainingIncome = income; // รายได้ที่เหลือสำหรับคำนวณ

  for (let i = 0; i < incomeRanges.length; i++) {
    // console.log("---------------")
    let calcRange = income - incomeRanges[i].max
    let taxableIncome =0
    if (calcRange >= 0) {
      taxableIncome = (incomeRanges[i].max - incomeRanges[i].min)*incomeRanges[i].rate
      // console.log(i,"taxableIncome",taxableIncome)
      tax += taxableIncome
    }else{
      taxableIncome = (income - incomeRanges[i].min)*incomeRanges[i].rate
      // console.log(i,"taxableIncome",taxableIncome)
      tax += taxableIncome
      break
    }
  }

  return tax; // คืนค่าภาษีรวม
}

// ตัวอย่างการใช้งาน
const netIncome = 757500; // เงินได้สุทธิ
const tax = calculatePersonalIncomeTax(netIncome);

// console.log(`เงินได้สุทธิ: ${netIncome} บาท`);
// console.log(`ภาษีที่ต้องชำระ: ${tax.toFixed(2)} บาท`);


export const getcalPaid = (paid, maxValue) => {
  const remain = maxValue - paid;
  return remain;
};

export const getcalcRemainEducaid = (value) => {
  const result = value * 0.1;
  return result;
};

export const getcalSum = (value1, value2) => {
  let sum = value1 + value2;
  return sum;
};

export const checkMinusMaxValue = (maxvalue) => {
  let sum = 0;
  sum = maxvalue < 0 ? 0 : maxvalue;
  return sum;
};

export const getPercent = (value, maxvalue) => {
  const percent = (Number(value) / Number(maxvalue)) * 100;
  // console.log(percent);
  return Number(percent);
};

export const calInsuarant = (value1, value2) => {
  let sum = 100000 -  value2;
  if (sum >= 25000) {
    return 25000;
  } else return sum;
};

export const calInsuarant2 = (value1, value2, value3, value4) => {
  let sum = value1 + value2 + value3 + value4;
  if (sum >= 500000) {
    if ((value1, value2, value3, value4 === 0)) {
      return 0;
    }
    return 0;
  } else return 500000 - sum;
};

export const calSum5Value = (value) => {
  let sum = 0;
  if (value.includes(undefined)) {
    return 0;
  }
  sum = value.reduce((a, b) => Number(a) + Number(b), 0);
  return sum;
};

export const checkSSF = (value, value2) => {
  let sum = (value - value2) * 0.15;
  if (sum === 0) {
    return 0;
  } else if (sum >= 200000) {
    return 200000;
  }

  return sum;
};

export const checkSpouseIncome = (ishasIncome, isMarriage, isSpouseDisable) => {
  if (ishasIncome) {
    return 0;
  } else {
    if (isMarriage) {
      return 60000;
    } else if (isSpouseDisable) {
      return 60000;
    } else return 60000;
  }
};

export const checkChild = (value) => {
  let sum = 0;
  value?.map((row) => {
    sum += row;
  });
  return sum;
};

export const checkIncome = (ishasIncome) => {
  if (ishasIncome) {
    return 30000;
  } else {
    return 0;
  }
};

export const checkIncome2 = (ishasIncome) => {
  if (ishasIncome) {
    return 60000;
  } else {
    return 0;
  }
};

export const calBadget = (value1, value2, value3, value4) => {
  let sum = value1 + value2 + value3 + value4;
  if ((value1, value2, value3, value4 === 0)) {
    return 0;
  } else return sum;
};

export const calPercentValue = (value1, value2) => {
  let sum = value1 * (value2 / 100);
  return Number(sum);
};

export const deductNamelist = {
  deduct_spouse: "คู่สมรส",
  deduct_mother: "แม่",
  deduct_father: "พ่อ",
  deduct_child: "ลูก",
  deduct_disable_people: "เลี้ยงดูคนพิการ",
  deduct_disabilities_people: "เลี้ยงดูคนทุพพลภาพ",
  deduct_insurance_premiums_parents:
    "เบี้ยประกันสุขภาพบิดามารดาของผู้มีเงินได้และคู่สมรส",
  insurance_life: "ประกันชีวิต",
  insurance_health: "ประกันสุขภาพ",
  insurance_annuity: "ประกันชีวิตแบบบำนาญ",
  fund_provident: "เงินสะสมกองทุนสำรองเลี้ยงชีพ",
  fund_nsf: "เงินสะสมกองทุนการออมแห่งชาติ (กอช)  ",
  fund_esg: "ค่าซื้อหน่วยลงทุนกองทุนรวมไทยเพื่อความยั่งยืน (Thailand ESG)",
  fund_provident_rmf_value: "ค่าซื้อหน่วยลงทุนในกองทุนรวมเพื่อการเลี้ยงชีพ",
  fund_provident_ssf_value: "ค่าซื้อหน่วยลงทุนในกองทุนรวมเพื่อการออม (SSF)",
  fund_GorBorKhor_value:"กองทุน กบข.",
  fund_PrivateSchoolTeacher_value:"กองทุนสงเคราะห์ครูโรงเรียนเอกชน",
  fund_CompensationMoney_value: "เงินค่าชดเชยที่ได้รับตามกฎหมายแรงงาน",
  home_loan: "ดอกเบี้ยเงินกู้ยืมเพื่อ ซื้อ เช่าซื้อ หรือสร้างอาคารอยู่อาศัย",
  fund_social_security: "เงินสมทบกองทุนประกันสังคม",
  prenatal_care_cost: "ค่าฝากครรภ์และค่าคลอดบุตร",
  political_donate: "เงินที่บริจาคแก่พรรคการเมือง",
  special_discount_gov: "สิทธิลดหย่อนพิเศษจากนโยบายรัฐบาล",
  education_donate: "เงินบริจาคสนับสนุนการศึกษา/สถานพยาบาล/สภากาชาดไทย/อื่น ๆ",
  other_donate: "เงินบริจาคอื่น ๆ",
};

export const deduction_default_result = [
  {
    title: "ผู้มีเงินได้",
    amount: 60000,
  },
];

export const formatDeduction = (form) => {
  let result = [...deduction_default_result];
  for (const key in deductNamelist) {
    if (form?.[key] !== 0 && form?.[key] !== "") {
      result.push({
        title: deductNamelist[key],
        amount: form?.[key],
        key,
      });
    }
  }

  return result;
};

export const checkError = (income, rate, value, maxvalue) => {
  if (income * rate > maxvalue && value > maxvalue) {
    return true;
  } else if (income * rate < maxvalue && value > income * rate) {
    return true;
  } else return false;
};

export const checkSum = (value1, value2, value3, value4) => {
  let sum = value1 - value2 - value3 - value4;
  return sum;
};

export const chechSumResult = (value1, value2) => {
  let sum = value1 - value2;
  return sum;
};
