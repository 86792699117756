const Footer = () => {
  return (
    <div className=" md:shadow-xl  shadowfooter bg-white flex justify-center items-center gap-[11px] py-3 w-full ">
      <img src="/images/logo_assure.png" className="h-5" />
      <div className=" text-mainblue text-[10px] font-normal flex-none">
        สงวนลิขสิทธิ โดย บริษัทแอสชัวเวลธ์จำกัด
      </div>
    </div>
  );
};

export default Footer;
