import { useNavigate } from "react-router-dom";
import { MTButton } from "../../../components/button/MTButton";
import { HeaderTitle } from "../../../components/header/Header";
import Container from "../../../components/layout/Container";
import { STYLE_REPORT_CONTAIN } from "../../../utils/useStyle";
import { CardReportAverage } from "../components/cardMenu";
import { HeaderReport } from "../components/header.main";
import {
  HeaderTableReport,
  HeaderTitleTableReport,
  TableReport,
} from "../components/table.main";
import { useBalanceStore } from "../../../_store/balanceStore";
import { useEffect, useMemo } from "react";
import { first_reload } from "../../tax_plan/functions/firstLoad";
import { DonutChartMain } from "../../../components/chart/DonutChartMain";
import numeral from "numeral";
import { cn } from "../../../utils/useFunctions";

const ChartSection = ({ balanceData, items }) => {
  return (
    <div className=" flex flex-col items-center">
      <DonutChartMain
        className=" -mt-20"
        classNameText={
          " text-sm  absolute top-[59%] left-1/2 -translate-x-1/2 -translate-y-1/4 text-black font-IBMPlexSansThai text-center"
        }
        classNameValue={" text-base"}
        classNameDonut=" w-[250px] pb-3"
        classNameGray="bg-white h-[66px]"
        text={`ค่าใช้จ่าย`}
        value={numeral(balanceData?.expenses_propotion).format("0,0")}
        data1={balanceData?.sum_expenses_fixed}
        data2={balanceData?.sum_expenses_variable}
        data3={balanceData?.sum_expenses_invest_saving}
      />

      <div className=" flex flex-col justify-center ">
        {items.map((item, index) => {
          return (
            <div key={index} className=" flex items-center gap-2.5 font-medium">
              <div className={` ${item?.color} h-2 w-5`}></div>
              <div className="flex items-center text-xs gap-1">
                <div>
                  {item?.title}{" "}
                  <span className=" font-bold">
                    {numeral(item?.sum_per_year)?.format("0,0")}
                  </span>{" "}
                  บาท คิดเป็น{" "}
                  <div className={` ${item?.textcolor} font-bold text-sm `}>
                    {item?.sum_percent?.toFixed(2)} %
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const TableExpense = ({ items }) => {
  return (
    <div className="bg-gray50 rounded-[20px] w-full h-ful p-3">
      <HeaderTableReport
        title="แหล่งที่มาของรายจ่าย"
        textjusify="text-center"
      />
      {items.map((item, index) => {
        return (
          <div key={index} className={cn("  items-center")}>
            <div className=" col-span-full">
              <HeaderTitleTableReport
                img={`/images/${item?.icon}`}
                title={item?.title}
              />
            </div>

            <div
              className={`bg-[#F9F9F9] py-1 rounded-[10px] grid grid-cols-8 px-2 items-center font-semibold text-mainblue text-sm`}
            >
              <div className=" col-span-3 ">
                {/* <HeaderTitleTableReport
              img={`/images/${item?.icon}`}
              title={item?.title}
            /> */}
              </div>
              <div className=" col-span-2 text-right">
                {numeral(item.sum_per_month).format("0,0")}
              </div>
              <div className="col-span-3 text-right ">
                {numeral(item.sum_per_year).format("0,0")} บาท
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const ExplanSection = ({ balanceData }) => {
  return (
    <div className=" text-center flex flex-col gap-[6px]">
      <div className=" text-base font-medium ">
        หลังจากแจกแจงรายจ่ายแล้วเราจะได้ภาพรวม ดังนี้
      </div>
      <div className="text-gray90 text-xs">
        (ค่าใช้จ่ายคงที่ + ค่าใช้จ่ายแปรผัน + ค่าใช้จ่ายเพื่อการออม/ลงทุน =
        รายจ่ายรวมต่อเดือน)
      </div>
      <div className=" text-base font-medium text-maincyan">
        รายจ่ายรวมต่อเดือน{" "}
        <span className=" text-mainblue">
          {numeral(balanceData?.expenses_propotion).format("0,0")}
        </span>
      </div>
    </div>
  );
};

const ReportExpensePage = () => {
  const navigate = useNavigate();
  const balanceData = useBalanceStore((state) => state.balanceData);

  const createSumObject = (sum_per_month, sum_per_year, sum_percent) => ({
    sum_per_month: sum_per_month || 0,
    sum_per_year: sum_per_year || 0,
    sum_percent: sum_percent || 0,
  });

  const sumFixed = useMemo(() => {
    return createSumObject(
      0,
      balanceData?.sum_expenses_fixed,
      (balanceData?.sum_expenses_fixed / balanceData?.expenses_propotion) * 100
    );
  }, [balanceData]);

  const sumVariable = useMemo(() => {
    return createSumObject(
      0,
      balanceData?.sum_expenses_variable,
      (balanceData?.sum_expenses_variable / balanceData?.expenses_propotion) *
        100
    );
  }, [balanceData]);

  const sumExpensesInvestSaving = useMemo(() => {
    return createSumObject(
      0,
      balanceData?.sum_expenses_invest_saving,
      (balanceData?.sum_expenses_invest_saving /
        balanceData?.expenses_propotion) *
        100
    );
  }, [balanceData]);

  const sumTotal = useMemo(
    () => balanceData?.expenses_propotion,
    [balanceData]
  );

  const items = useMemo(() => {
    return [
      {
        title: "ค่าใช้จ่ายคงที่",
        icon: "accounting.svg",
        ...sumFixed,
        color: "bg-yellow400",
        textcolor: "text-yellow400",
      },
      {
        title: "ค่าใช้จ่ายแปรผัน",
        icon: "cost (1).svg",
        ...sumVariable,
        color: "bg-red500",
        textcolor: "text-red-500",
      },
      {
        title: "ค่าใช้จ่ายเพื่อการออม/ลงทุน",
        icon: "bank.svg",
        classNameIcon: "w-[24px]",
        ...sumExpensesInvestSaving,
        color: "bg-maingreen",
        textcolor: "text-maingreen",
      },
    ];
  }, [sumTotal]);

  useEffect(() => {
    let ignore = false;
    first_reload(ignore);
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <Container>
      <div id="first_reload" className="pt-[14px]">
        <HeaderTitle
          title="รายงาน"
          isGoback
          onClickGoBack={() => {
            navigate("/report");
          }}
        />

        <div className={`${STYLE_REPORT_CONTAIN} flex flex-col gap-5`}>
          <HeaderReport
            icon={"/images/expense-report.png"}
            title={"สัดส่วนรายจ่ายของคุณ"}
            className={" z-20"}
          />
          <ChartSection balanceData={balanceData} items={items} />
          <CardReportAverage
            value={numeral(balanceData?.expenses_propotion / 12).format("0,0")}
          />
          <TableExpense items={items} />
          <ExplanSection balanceData={balanceData} />

          <div className=" flex justify-center ">
            <MTButton
              onClick={() => {
                navigate("/report");
              }}
              text="ย้อนกลับ"
              color="bg-white"
              border="border border-maincyan"
              colortext="text-maincyan group-hover:text-white"
              hovercolor="hover:bg-maincyan group "
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ReportExpensePage;
