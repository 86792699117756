import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { options_car_period } from "../pages/debt/component/VIDebtPlanForm";

const store = (set) => ({
  debtData: {
    sum_start_unpaid_amount_short: "",
    sum_unpaid_amount: "",
    loan_interest_rate: "",
    // รถ
    total_current_price_car: "",
    total_unpaid_amount_car: "",
    difference_total_car: "",
    car_interest_rate: 13,
    car_period: options_car_period[0],
    refinance_amount: 100000,
    total_car_interest_rate: 0,
    original_loan_interest_rate: 0,
    total_per_month: 0,
    payPerMonth: 0,

    // หลังวางแผน
    pay_permonth_af: "",
    sum_unpaid_amount_af: "",
    total_creditor_receive_af: "",
    total_loan_interest_rate_af: "",
    total_save_amount_af: "",
  },
  setDebtData: (debtData) => set(() => ({ debtData })),
  clearDebtData: () => set(() => ({ debtData: null })),
});

export const useDebtStore = create(
  persist(store, {
    name: "debtData", // unique name
    storage: createJSONStorage(() => localStorage), // (optional) by default the 'localStorage' is used
  })
);
