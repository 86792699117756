import numeral from "numeral";
import { useEducationStore } from "../../../_store/educationStore";
import { useInsuranceStore } from "../../../_store/insuranceStore";
import { cn } from "../../../utils/useFunctions";

const ViewTableFamily = ({ className  }) => {
  const educationData = useEducationStore((state) => state.educationData);
  const insuranceData = useInsuranceStore((state) => state.insuranceData);

  return (
    <div>
      <div
        className={cn(
          "bg-maincyan/10 mb-1 grid grid-cols-3 text-mainblue text-xs p-2 rounded-[5px]",
          className
        )}
      >
        <div>ชื่อ-นามสกุล</div>
        <div>เป้าหมาย</div>
        <div>ทุนประกัน</div>
      </div>
      {educationData.map((educationItem, index) => {
        return (
          <div
            key={index}
            className={cn(
              "bg-[#f9f9f9] grid grid-cols-3 text-mainblue text-xs p-2 rounded-b-[5px] ",
              className
            )}
          >
            <div>{educationItem?.name}</div>
            <div>
              {
                educationItem?.education_items?.[
                  educationItem?.education_items.length - 1
                ]?.education?.label
              }
            </div>
            <div>
              {numeral(
                educationItem?.education_items?.[0]?.sum_insurance
              )?.format("0,0")}
            </div>
          </div>
        );
      })}
      <div
        className={cn(
          " grid grid-cols-3 text-mainblue text-xs p-2 rounded-[5px]",
          className
        )}
      >
        <div>รวม</div>
        <div></div>
        <div>
          {numeral(insuranceData.sum_insurance_education).format("0,0")}
        </div>
      </div>
    </div>
  );
};

export default ViewTableFamily;
