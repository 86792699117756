import { cn } from "../../../utils/useFunctions";

export const HeaderReport = ({ icon, title, className }) => {
  return (
    <div className={cn("flex flex-col gap-2.5", className)}>
      <div className=" mx-auto">
        <img src={icon} className="w-[60px] h-[60px]" alt="" />
      </div>
      <div className=" text-center font-semibold text-xl">{title}</div>
    </div>
  );
};
