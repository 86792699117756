import { useLocation, useNavigate } from "react-router-dom";
import { HeaderTitle } from "../../components/header/Header";
import Container from "../../components/layout/Container";
import {
  STYLE_MARGIN_TOP_PARENT,
  STYLE_PADDING_MAIN,
} from "../../utils/useStyle";
import { useEffect, useMemo, useState } from "react";
import { MTButton } from "../../components/button/MTButton";
import { Step, Stepper } from "@material-tailwind/react";

import {
  ICON_CIRCLE_RIGHT,
  ICON_RIGHT,
  ICON_RIGHT_2,
  ICON_RLOCNG_RIGHT,
} from "../../utils/useIcons";
import { CardStepper } from "./styles/Card";
import ViewDebtPlan from "./component/VIDebtPlan.Main";
import { VIDrawerDebtDetails } from "./component/VIDrawerDebtDetails";
import { NavigatePath } from "../../functions/Navigate.Goback";
import { useBalanceStore } from "../../_store/balanceStore";

const DebtMain = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [tab, setTab] = useState(0);
  const [isPlan, setIsPlan] = useState(false);
  const [isLastStep, setIsLastStep] = useState(false);
  const [isFirstStep, setIsFirstStep] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);

  const query = new URLSearchParams(location.search);

  useEffect(() => {
    if (query.get("tab")) {
      setTab(parseInt(query.get("tab")));
      setIsPlan(true);
    }
  }, [query]);

  return (
    <Container>
      <div className={`${STYLE_MARGIN_TOP_PARENT}  `}>
        <HeaderTitle
          title="แผนจัดการหนี้สิน"
          isGoback
          onClickGoBack={() => {
            if (isPlan) {
              setIsPlan(false);
              setTab(5);
              return;
            }
            NavigatePath(navigate, "/");
          }}
        />
        {!isPlan ? (
          <div className={STYLE_PADDING_MAIN}>
            {tab === 0 && <CardStepper isFirst={true} />}
            {tab === 1 && (
              <CardStepper
                index={1}
                details={
                  <div>
                    ทำการย้ายหนี้จากนอกระบบเข้า
                    <div>สถาบันการเงินที่เป็นธรรม</div>
                  </div>
                }
              />
            )}
            {tab === 2 && (
              <CardStepper
                index={2}
                details={
                  <div>
                    จัดอันดับอัตราดอกเบี้ยโดยพิจารณา
                    <div>จัดการหนี้ที่มีอัตราดอกเบี้ยสูงที่สุดก่อน</div>
                  </div>
                }
              />
            )}
            {tab === 3 && (
              <CardStepper
                index={3}
                details={
                  <div>
                    ทำการรวมหนี้จากหลายแหล่งเข้ามา
                    <div>ยังสถาบันการเงินที่สนับสนุนการแก้ไขหนี้</div>
                    <div>ที่มีอัตราดอกเบี้ยที่ถูกกว่าเดิม</div>
                  </div>
                }
              />
            )}
            {tab === 4 && (
              <CardStepper
                index={4}
                details={
                  <div>
                    หากสภาพคล่องไม่เพียงพอกับการดำรงชีพให้หารื้อกับเจ้าหนี้
                    <div> เพื่อขอลดหย่อนชำระต่อเดือน</div>
                    <div> ยืดระยะเวลาการผ่อนชำระให้ยาวขึ้น</div>
                  </div>
                }
              />
            )}
            {tab === 5 && (
              <CardStepper
                index={5}
                details={
                  <div>
                    ประกันชีวิตเพื่อคุ้มครองภาระหนี้สิน
                    <div> เป็นส่วนหนึ่งที่ช่วยลดภาระหากผู้กู้เสียชีวิต</div>
                    <div> ก่อนวัยอันควรภาระหนี้สินบางส่วน</div>
                    <div> จะได้ไม่ตกทอดมายังทายาท</div>
                  </div>
                }
              />
            )}
            {tab === 0 ? (
              ""
            ) : (
              <Stepper
                className=" w-2/6 gap-[2px] mx-auto  mt-20 "
                activeStep={tab}
                lineClassName="hidden"
                activeLineClassName="bg-maincyan"
                //   className={`${STYLE_TAB} `}
                isLastStep={(value) => setIsLastStep(value)}
                isFirstStep={(value) => setIsFirstStep(value)}
              >
                {/*---- test 2 ----*/}
                <Step
                  onClick={() => setTab(1)}
                  className={`${
                    tab === 0 ? "!bg-maincyan w-10" : " hidden"
                  } h-[6px]`}
                  activeClassName="!bg-maincyan"
                ></Step>
                <Step
                  onClick={() => setTab(1)}
                  className={`${
                    tab === 1 ? "!bg-maincyan w-10" : " !bg-gray-300 w-6"
                  } h-[6px]`}
                  activeClassName="!bg-maincyan"
                ></Step>

                {/*---- test 3 ----*/}
                <Step
                  className={`${
                    tab === 2 ? "!bg-maincyan w-10" : " !bg-gray-300 w-6"
                  } h-[6px]`}
                  activeClassName="!bg-maincyan"
                  onClick={() => setTab(2)}
                ></Step>

                {/*---- test 4 ----*/}
                <Step
                  className={`${
                    tab === 3 ? "!bg-maincyan w-10" : " !bg-gray-300 w-6"
                  } h-[6px]`}
                  activeClassName="!bg-maincyan"
                  onClick={() => setTab(3)}
                ></Step>

                {/*---- test 5 ----*/}
                <Step
                  className={`${
                    tab === 4 ? "!bg-maincyan w-10" : " !bg-gray-300 w-6"
                  } h-[6px]`}
                  activeClassName="!bg-maincyan"
                  onClick={() => setTab(4)}
                ></Step>
                <Step
                  className={`${
                    tab === 5 ? "!bg-maincyan w-10" : " !bg-gray-300 w-6"
                  } h-[6px]`}
                  activeClassName="!bg-maincyan"
                  onClick={() => setTab(5)}
                ></Step>

                {/*---- test 6 ----*/}
              </Stepper>
            )}
            <div
              className={`flex justify-center gap-2.5 w-full ${
                tab === 0 ? "mt-28" : "mt-10"
              }  `}
            >
              <MTButton
                text={
                  tab === 0
                    ? "ไปดูกันเลย"
                    : "ไม่ต้องเครียด มาวางแผนหนี้สินกันเถอะ"
                }
                color="bg-maincyan"
                width="w-full"
                iconright={true}
                height="h-10"
                icon={
                  <ICON_CIRCLE_RIGHT className={` text-white w-6 h-6  -ml-2`} />
                }
                onClick={() => {
                  tab === 5 ? setIsPlan(true) : setTab(tab + 1);
                }}
                // onClick={onClicถSumScore}
              />
            </div>
            <div
              className={`flex justify-center gap-2.5 w-full mt-4 mb-[38px] `}
            >
              <MTButton
                text={"ข้าม"}
                color="bg-maincyan"
                width="w-full"
                iconright={true}
                height="h-10"
                onClick={() => {
                  setIsPlan(true);
                }}
                // onClick={onClicถSumScore}
              />
            </div>
          </div>
        ) : (
          <>
            {/*---- รายการหนี้ ----*/}
            <ViewDebtPlan openInfo={openInfo} setOpenInfo={setOpenInfo} />
            {/*---- ดูรายละเอียดหนี้ ----*/}
            {openInfo && (
              <VIDrawerDebtDetails
                openInfo={openInfo}
                setOpenInfo={setOpenInfo}
              />
            )}
          </>
        )}
      </div>
    </Container>
  );
};

export default DebtMain;
