import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

export const settingStore = (set) => ({
  settingData: null,
  setSettingData: (settingData) => set(() => ({ settingData })),
  clearSettingData: () => set(() => ({ settingData: null })),
});

export const useSettingStore = create(
  persist(settingStore, {
    name: "settingData",
    storage: createJSONStorage(() => localStorage), // (optional) by default the 'localStorage' is used
  })
);
