import { useMemo } from "react";
import { useBalanceStore } from "../../../_store/balanceStore";
import { cn, toFloat2 } from "../../../utils/useFunctions";
import {
  PdfBalanceHeaderItemCard,
  PdfBalanceItemCard,
  PdfCard,
  PdfCardGray,
  PdfCardWhite,
  PdfFamilyHeaderItemCard,
} from "../component/pdf.c.card";
import PdfHeader from "../component/pdf.c.header";
import {
  PdfHeaderCardText,
  PdfHeaderText,
} from "../component/pdf.c.headerText";
import PdfLine from "../component/pdf.c.line";
import {
  STYLE_PAGE,
  STYLE_PDF_ITEM_TOTAL,
  STYLE_PDF_TEXT_MEDIUM_11,
  STYLE_PDF_TEXT_MEDIUM_12,
  STYLE_PDF_TEXT_MEDIUM_13,
  STYLE_PDF_TEXT_MEDIUM_BLUE_11,
  STYLE_PDF_TEXT_MEDIUM_BLUE_12,
  STYLE_PDF_TEXT_NORMAL_10,
  STYLE_PDF_TEXT_NORMAL_11,
  STYLE_PDF_TEXT_NORMAL_BLUE_10,
  STYLE_PDF_TEXT_NORMAL_BLUE_11,
  STYLE_PDF_TEXT_NORMAL_BLUE_12,
  STYLE_PDF_TEXT_NORMAL_GRAY_11,
  STYLE_PDF_TEXT_SEMIBOLD_BLUE_12,
  STYLE_PDF_TEXT_SEMIBOLD_BLUE_14,
} from "../styles/pdf.style";
import {
  key_property_invest_category,
  options_property_asset,
  options_property_intangible,
  options_property_invest,
  options_property_private,
} from "../../balance/functions";
import _ from "lodash";
import numeral from "numeral";
import {
  calPropertyLocalOption,
  getAllBalanceTotal,
  getBalanceItems,
} from "../lib/pdf.lib";
import PdfFooter from "../component/pdf.c.footer";
import { useFuturePlanStore } from "../../../_store/futurePlanStore";
import FamilyBarChart from "../component/pdf.c.familyChart";
import ViewTableFamily from "../../insuarance_plan/component/VITableFamily.Main";
import { StatusCard } from "../../insuarance_plan/styles/Card";
import { ImageMain } from "../../../components/img/Img.Main";
import { DonutChartInvestMain } from "../../../components/chart/DonutChartMain";
import LineChartSavingMain from "../../saving_plan/components/LineChartSaving.Main";
import { useTaxStore } from "../../../_store/taxplanStore";

function PdfPageTaxOne({ page }) {
  const taxData = useTaxStore((state) => state.taxData);

  return (
    <div className={cn(STYLE_PAGE)}>
      <PdfHeader />
      <PdfLine />
      <PdfHeaderText
        title="วางแผนภาษี"
        icon="/images/tax_plan.svg"
        classNameIcon="w-[24px] h-[27px]"
      />
      {/* วางแผนภาษี */}
      <PdfCard className={"flex flex-col space-y-3"}>
        {/* รายรับ */}
        <PdfCardWhite className={cn("px-3")}>
          <div className={cn("flex items-center space-x-1")}>
            <div>
              <img src="/images/outcome_money.svg" />
            </div>

            <div className={cn(STYLE_PDF_TEXT_MEDIUM_12)}>รายรับ</div>
          </div>

          <div
            className={cn(
              "flex justify-between mx-1 mt-1",
              STYLE_PDF_TEXT_MEDIUM_12
            )}
          >
            <div>รายการ</div>
            <div>ต่อปี</div>
          </div>

          <PdfLine />

          <div className={cn("mt-1 flex items-center space-x-2")}>
            <div>
              <img src="/images/salary_2.svg" />
            </div>

            <div className={cn(STYLE_PDF_TEXT_MEDIUM_11)}>เงินได้</div>
          </div>

          <PdfCardGray className={cn("px-3 mt-1")}>
            <div className="flex justify-between space-x-1">
              <div className={cn(STYLE_PDF_TEXT_MEDIUM_11)}>
                รายได้จากเงินเดือน
              </div>

              <div className={cn(STYLE_PDF_TEXT_MEDIUM_BLUE_11)}>
                {numeral(taxData?.tax_income).format("0,0")} บาท
              </div>
            </div>
          </PdfCardGray>

          <div className="flex space-x-1 justify-center mt-2">
            <div className={cn(STYLE_PDF_TEXT_MEDIUM_11)}>รวม</div>

            <div className={cn(STYLE_PDF_TEXT_MEDIUM_BLUE_12)}>
              {numeral(taxData?.tax_income).format("0,0")} บาท
            </div>
          </div>
        </PdfCardWhite>

        {/* รายจ่าย */}
        <PdfCardWhite className={cn("px-3")}>
          <div className={cn("flex items-center space-x-1")}>
            <div>
              <img src="/images/outcomepdf.svg" />
            </div>

            <div className={cn(STYLE_PDF_TEXT_MEDIUM_12)}>รายจ่าย</div>
          </div>

          <div
            className={cn(
              "flex justify-between mx-1 mt-1",
              STYLE_PDF_TEXT_MEDIUM_12
            )}
          >
            <div>รายการ</div>
            <div>ต่อปี</div>
          </div>

          <PdfLine />

          <div className={cn("mt-1 flex items-center space-x-2")}>
            <div>
              <img src="/images/salary_2.svg" />
            </div>

            <div className={cn(STYLE_PDF_TEXT_MEDIUM_11)}>หักค่าใช้จ่าย</div>
          </div>

          <PdfCardGray className={cn("px-3 mt-1")}>
            <div className="flex justify-between space-x-1">
              <div className="flex space-x-1">
                <div className={cn(STYLE_PDF_TEXT_MEDIUM_11)}>
                  ภาษีที่ถูกหัก ณ ที่จ่าย / เครดิตภาษี
                </div>
              </div>

              <div className={cn(STYLE_PDF_TEXT_MEDIUM_BLUE_11)}>
                {numeral(
                  taxData?.tax_withholding <= 0 ? "" : taxData?.tax_withholding
                ).format("0,0")}{" "}
                บาท
              </div>
            </div>
          </PdfCardGray>

          <PdfCardGray className={cn("px-3 mt-1")}>
            <div className="flex justify-between space-x-1">
              <div className="flex flex-col ">
                <div className={cn(STYLE_PDF_TEXT_MEDIUM_11)}>
                  เงินเดือนค่าจ้างที่ได้รับจากบริษัทฯ บุคคลทั่วไป ฯลฯ
                </div>
                <div className={cn(STYLE_PDF_TEXT_NORMAL_GRAY_11)}>
                  (อัตราค่าใช้จ่ายร้อยละ 50 แต่ไม่เกิน 100,000 บาท)
                </div>
              </div>

              <div className={cn(STYLE_PDF_TEXT_MEDIUM_BLUE_11)}>
                {numeral(taxData?.deduction_expenses).format("0,0")} บาท
              </div>
            </div>
          </PdfCardGray>

          <div className="flex space-x-1 justify-center mt-2">
            <div className={cn(STYLE_PDF_TEXT_MEDIUM_11)}>รวม</div>

            <div className={cn(STYLE_PDF_TEXT_MEDIUM_BLUE_12)}>
              {numeral(taxData?.deduction_expenses).format("0,0")} บาท
            </div>
          </div>
        </PdfCardWhite>
      </PdfCard>
      <PdfFooter page={page} />
    </div>
  );
}

export default PdfPageTaxOne;
