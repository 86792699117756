import { useMemo } from "react";
import { useBalanceStore } from "../../../_store/balanceStore";
import { cn, toFloat2 } from "../../../utils/useFunctions";
import {
  PdfBalanceHeaderItemCard,
  PdfBalanceItemCard,
  PdfCard,
  PdfCardGray,
  PdfCardWhite,
  PdfFamilyHeaderItemCard,
} from "../component/pdf.c.card";
import PdfHeader from "../component/pdf.c.header";
import {
  PdfHeaderCardText,
  PdfHeaderText,
} from "../component/pdf.c.headerText";
import PdfLine from "../component/pdf.c.line";
import {
  STYLE_PAGE,
  STYLE_PDF_ITEM_TOTAL,
  STYLE_PDF_TEXT_MEDIUM_11,
  STYLE_PDF_TEXT_MEDIUM_12,
  STYLE_PDF_TEXT_MEDIUM_13,
  STYLE_PDF_TEXT_MEDIUM_BLUE_11,
  STYLE_PDF_TEXT_MEDIUM_BLUE_12,
  STYLE_PDF_TEXT_NORMAL_10,
  STYLE_PDF_TEXT_NORMAL_11,
  STYLE_PDF_TEXT_NORMAL_BLUE_10,
  STYLE_PDF_TEXT_NORMAL_BLUE_11,
  STYLE_PDF_TEXT_NORMAL_BLUE_12,
  STYLE_PDF_TEXT_SEMIBOLD_BLUE_12,
  STYLE_PDF_TEXT_SEMIBOLD_BLUE_14,
} from "../styles/pdf.style";
import {
  key_property_invest_category,
  options_property_asset,
  options_property_intangible,
  options_property_invest,
  options_property_private,
} from "../../balance/functions";
import _ from "lodash";
import numeral from "numeral";
import {
  calPropertyLocalOption,
  getAllBalanceTotal,
  getBalanceItems,
} from "../lib/pdf.lib";
import PdfFooter from "../component/pdf.c.footer";
import { useFuturePlanStore } from "../../../_store/futurePlanStore";
import FamilyBarChart from "../component/pdf.c.familyChart";
import ViewTableFamily from "../../insuarance_plan/component/VITableFamily.Main";
import { StatusCard } from "../../insuarance_plan/styles/Card";
import { ImageMain } from "../../../components/img/Img.Main";
import { DonutChartInvestMain } from "../../../components/chart/DonutChartMain";
import LineChartSavingMain from "../../saving_plan/components/LineChartSaving.Main";
import { useSavingStore } from "../../../_store/savingStore";
import { ChartCashFlow } from "../../future_plan/components/VIChartCashFlow.Main";

function PdfPageFutureFour({page}) {
  const futurePlanData = useFuturePlanStore((state) => state.futurePlanData);

  const merge_keys = useMemo(() => {
    let income_keys = futurePlanData.cash_flow_keys.filter((cash_flow_key) => {
      return cash_flow_key.includes("income");
    });
    let expenese_keys = futurePlanData.cash_flow_keys.filter(
      (cash_flow_key) => {
        return cash_flow_key.includes("expenese");
      }
    );
    let merge_income_keys = [
      ...income_keys,
      "income_peryear",
      "remain_accumulate",
    ];
    let merge_expenese_keys = [...expenese_keys, "outcome_peryear"];
    let merge_keys = [...merge_expenese_keys, ...merge_income_keys];
    return merge_keys;
  }, [futurePlanData]);

  const dataDebt = useMemo(() => {
    let dataDebt = [];

    for (const merge_key of merge_keys) {
      let dataDebtItems = [];
      for (const cash_flow_item of futurePlanData.cash_flow_items) {
        if (cash_flow_item[merge_key] !== undefined) {
          let amount = String(merge_key).startsWith("expenese")
            ? -cash_flow_item[merge_key]
            : cash_flow_item[merge_key];

          amount = Math.round(amount);
          if ([0, -0].includes(amount)) {
            amount = "";
          }

          dataDebtItems.push(amount);
        }
      }
      dataDebt.push(dataDebtItems);
    }

    return dataDebt;
  }, [futurePlanData]);

  const labels = useMemo(() => {
    let labels = [];
    for (const cash_flow_item of futurePlanData.cash_flow_items) {
      labels.push(cash_flow_item.age);
    }
    return labels;
  }, [futurePlanData]);

  //   expenese_home_items: "บ้าน",
  //   expenese_car_items: "รถ",
  //   expenese_children_items: "ลูก",
  //   expenese_travel_items: "ท่องเที่ยว",
  //   expenese_collection_items: "ของสะสม",
  //   expenese_other_items: "รายจ่ายอื่นๆ",
  //   income_legacy_items: "รายได้จากมรดก",
  //   income_business_items: "รายได้จากธุรกิจ",
  //   income_property_items: "รายได้จากอสังหาริม",
  //   income_other_items: "รายได้อื่นๆ",
  //   income_peryear: "รายได้ต่อปี",
  //   remain_accumulate: "ยอดเงินสะสม",
  //   outcome_peryear: "รายจ่ายต่อปี",

  const colors = useMemo(() => {
    return merge_keys?.map((key) => {
      if (key === "income_peryear") {
        return { label: "รายได้ต่อปี", color: "#47A3F7" };
      } else if (key === "remain_accumulate") {
        return { label: "ยอดเงินสะสม", color: "#00B61D" };
      } else if (key === "outcome_peryear") {
        return { label: "รายจ่ายต่อปี", color: "#F7A647" };
      } else if (key === "expenese_home_items") {
        return { label: "บ้าน", color: "#FFD12C" };
      } else if (key === "expenese_car_items") {
        return { label: "รถ", color: "#AF2CFF" };
      } else if (key === "expenese_children_items") {
        return { label: "ลูก", color: "#FF00FF" };
      } else if (key === "expenese_travel_items") {
        return { label: "ท่องเที่ยว", color: "#00FF00" };
      } else if (key === "expenese_collection_items") {
        return { label: "ของสะสม", color: "#FB3CBD" };
      } else if (key === "expenese_other_items") {
        return { label: "รายจ่ายอื่นๆ", color: "#BDC333" };
      } else if (key === "income_legacy_items") {
        return { label: "รายได้จากมรดก", color: "#FF0000" };
      } else if (key === "income_business_items") {
        return { label: "รายได้จากธุรกิจ", color: "#00FFFF" };
      } else if (key === "income_property_items") {
        return { label: "รายได้จากอสังหาริม", color: "#FFA500" };
      } else if (key === "income_other_items") {
        return { label: "รายได้อื่นๆ", color: "#0000FF" };
      }
    });
  }, [dataDebt]);

  return (
    <div className={cn(STYLE_PAGE)}>
      <PdfHeader />
      <PdfLine />
      <PdfHeaderText
        title="แผนอนาคต"
        icon="/images/personal.svg"
        classNameIcon="w-[24px] h-[27px]"
      />
      {/* Cash Flow */}
      <PdfCard
        className={""}
        title={"Cash flow รายรับรายจ่ายของแผนอนาคต"}
        classNameTitle={cn(STYLE_PDF_TEXT_MEDIUM_BLUE_12)}
      >
        <PdfCardWhite>
          <div className="w-full h-full">
            <ChartCashFlow
              labels={labels}
              dataDebt={dataDebt}
              merge_keys={merge_keys}
            />
          </div>
          {/* <div className="flex gap-2 flex-wrap justify-center mt-3 items-center">
            {colors.map((color, index) => {
              return (
                <div
                  key={index}
                  className={cn("flex gap-2 justify-center items-center")}
                >
                  <div
                    className="w-[14px] h-[14px]"
                    style={{
                      backgroundColor: color.color,
                    }}
                  ></div>
                  <div className={cn(STYLE_PDF_TEXT_NORMAL_11)}>
                    {color.label}
                  </div>
                </div>
              );
            })}
          </div> */}
        </PdfCardWhite>

        <PdfCardWhite className={"mt-3"}>
          <div className="  w-full rounded-lg h-full p-3 space-y-[6px]">
            <ImageMain
              src="../images/cashflow.png"
              className=" w-12 h-12 mx-auto"
            />
            <div className=" text-center text-mainblue text-sm ">
              เพื่อทำให้แผนอนาคตของคุณเป็นไปตามเป้าหมาย
              <div>
                คุณต้องหารายรับเพิ่มอีก
                <span className=" font-semibold">
                  {" "}
                  {numeral(futurePlanData.sum_diff_total)?.format("0,0")} บาท
                </span>{" "}
              </div>
            </div>
          </div>
        </PdfCardWhite>
      </PdfCard>
      <PdfFooter page={page}/>
    </div>
  );
}

export default PdfPageFutureFour;
