import { Drawer } from "@material-tailwind/react";

import {
  STYLE_BORDER_YELLOW_PROPOTION,
  STYLE_CARD_BALANCE,
  STYLE_CARD_PROPOTION,
  STYLE_HEADER_SECTION_PROPOTION,
  STYLE_IMG_BALANCE,
  STYLE_TEXT_CENTER_DONUT_BALANCE,
  STYLE_TITLE_BALANCE,
  STYLE_TITLE_PROPOTION,
  STYLE_VALUE_BALANCE,
  STYLE_VALUE_CENTER_DONUT_BALANCE,
  STYLE_WIDTH_DONUT_BALANCE,
} from "../../../utils/useStyle";
import { useBalanceContext } from "../Balance.Main";
import { useEffect, useMemo } from "react";
import { useBalanceStore } from "../../../_store/balanceStore";
import numeral from "numeral";
import {
  ICON_NAVIGATE_BALANCE,
  ICON_NAVIGATE_RIGHT,
} from "../../../utils/useIcons";
import { ImageMain } from "../../../components/img/Img.Main";
import { BoxWhite } from "../styles/Card";
import {
  DonutChartEmpty,
  DonutChartIncome,
  DonutChartMain,
} from "../../../components/chart/DonutChartMain";

const ViewIncomeProportion = ({ onClick }) => {
  const setBalanceData = useBalanceStore((state) => state.setBalanceData);
  const balanceData = useBalanceStore((state) => state.balanceData);

  return (
    <div className={STYLE_CARD_PROPOTION}>
      <div className={`${STYLE_HEADER_SECTION_PROPOTION} `}>
        <div>
          <div className={STYLE_TITLE_PROPOTION}>สัดส่วนรายได้</div>
          <div className={STYLE_BORDER_YELLOW_PROPOTION}></div>
        </div>
        <ICON_NAVIGATE_BALANCE onClick={onClick} />
      </div>

      {balanceData?.salary_propotion <= 0 ||
      balanceData?.salary_propotion === undefined ? (
        <DonutChartEmpty text={`รายได้`} />
      ) : (
        <DonutChartIncome
          text={`รายได้`}
          value={numeral(
            balanceData?.sum_salary_peryear +
              balanceData?.sum_interest_peryear +
              balanceData?.sum_other_income
          ).format("0,0")}
          data1={balanceData?.sum_salary_peryear}
          data2={balanceData?.sum_interest_peryear}
          data3={balanceData?.sum_other_income}
        />
      )}

      <BoxWhite
        title="เงินเดือน"
        icon="/images/finance_income2.png"
        colortext="text-green400"
        value={balanceData?.sum_salary_peryear}
      />
      <BoxWhite
        title="ดอกเบี้ย"
        icon="/images/finance_interest2.png"
        colortext="text-green300"
        value={balanceData?.sum_interest_peryear}
      />
      <BoxWhite
        title="รายได้อื่น ๆ"
        icon="/images/finance_others2.png"
        colortext="text-green600"
        value={balanceData?.sum_other_income}
        iconStyle="w-[46px] h-[25px]"
        cardStyle={`bg-white py-4 px-3 rounded-[10px] mx-5 flex gap-[9px] items-center mb-[14px]`}
      />
    </div>
  );
};

export default ViewIncomeProportion;
