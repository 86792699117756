import ReactSlider from "react-slider";
import "./styleSecond.css";
import { useState } from "react";

export const SecondSlider = ({ current, onChange, dead }) => {
  const [value, setValue] = useState([current, dead]);
  return (
    <div className=" flex gap-1 items-center w-full justify-center">
      {/* <div className=" text-bluegray50 font-semibold text-xl font-IBMPlexSansThai">
          0
        </div> */}
      <ReactSlider
        value={[current, dead]}
        className="horizontal-sliders"
        thumbClassName="example-thumbs"
        trackClassName="example-tracks"
        defaultValue={[current, dead]}
        // onAfterChange={[onChangeCurrent, onChangeRetire, onChangeDead]}
        ariaLabel={["Leftmost thumb", "Rightmost thumb"]}
        renderThumb={(props, state) => <div {...props}>{state?.valueNow}</div>}
        pearling
        minDistance={1}
        min={18}
        max={120}
        onChange={onChange}
      />

      {/* <div className=" ml-1 text-mainblue font-semibold text-sm font-IBMPlexSansThai">
          120
          <input
            className=" w-10 px-1 py-[7px] text-mainblue placeholder:text-maingray font-semibold text-sm font-IBMPlexSansThai shadowinner rounded-md text-center focus:outline-none"
            value={120}
            onChange={onChangeMaxDead}
          />
        </div> */}
    </div>
  );
};
