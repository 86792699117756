import React from "react";
import Select from "react-select";

const DropDownGroupMain = ({
  options,
  onChange,
  value,
  placeholder = "เลือกหัวข้อที่ต้องการ",
  disable,
  zIndex = null,
  border = "1px solid #D3D3D3",
  height = 36,
  className = "",
}) => {
  let optionProps = {};

  const formatGroupLabel = (data) => {
    return (
      <div className="flex justify-between items-center w-full">
        <span className=" font-bold text-sm text-mainblue border-b border-b-gray80 w-full">
          {data.label}
        </span>
      </div>
    );
  };

  return (
    <Select
      className={className}
      {...optionProps}
      placeholder={placeholder}
      // isClearable={isClearable}
      // isSearchable={isSearchable}
      // isMulti={isMulti}
      options={options}
      value={value}
      onChange={onChange}
      formatGroupLabel={formatGroupLabel}
      components={{
        IndicatorSeparator: () => null,
      }}
      styles={{
        control: (base) => ({
          ...base,
          menuPosition: "fixed",
          minHeight: height,
          fontSize: 14,
          color: "#18488A",
          border: border,
          backgroundColor: "#FFFFFF",
          outline: "none",
          cursor: "pointer",
          borderRadius: 20,
        }),
        option: (styles) => ({
          // ...styles,
          zIndex: zIndex,
          cursor: "pointer",
          borderRadius: 20,
          border: "none",
          fontSize: 14,
          padding: "5px 20px",
          color: "#18488A",
        }),
      }}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: "none",
          primary: disable ? "none" : "",
        },
        borderRadius: 7,
      })}
    />
  );
};

export default DropDownGroupMain;
