import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  RadialLinearScale,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { useEffect } from "react";
import { useIsMobile } from "../../../utils/useIsMobile";
import numeral from "numeral";

ChartJS.register(
  RadialLinearScale,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Filler,
  Title,
  Tooltip,
  Legend
);

export const LineChartEduExpensesMain = ({
  dataExpenses,
  labels,
  head,
  title_x,
  title_y,
  fontSizeY,
  fontSizeX,
}) => {
  const isMobile = useIsMobile();

  const data = {
    labels: labels,
    datasets: [
      {
        label: head,
        data: dataExpenses,
        fill: true,
        pointRadius: 0,
        borderColor: ({ chart: { ctx } }) => {
          const borderColor = ["#FF7A00"];
          return borderColor;
        },

        pointBackgroundColor: ["#FF7A00"],
        backgroundColor: "rgba(255, 122, 0, 0.20)",
        tension: 0.2,
      },
    ],
  };

  const config = {
    type: "line",
    data,
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          maxTicksLimit: isMobile ? 10 : 20,
          font: {
            size: fontSizeX,
            family: "IBMPlexSansThai",
          },
        },
      },
      y: {
        ticks: {
          beginAtZero: true,
          callback: function (value, index, values) {
            return value > 1000000
              ? `${numeral(value / 1000000)?.format("0,0")}M`
              : value > 10000
              ? `${numeral(value / 10000)?.format("0,0")}k`
              : value === 0
              ? 0
              : value < 1000
              ? `${numeral(value)?.format("0,0")}`
              : value;
          },
          font: {
            size: fontSizeY,
            family: "IBMPlexSansThai",
          },
        },
      },

      // y: {
      //   title: {
      //     beginAtZero: true,
      //     display: true,
      //     text: "บาท",
      //     font: {
      //       size: isMobile ? 14 : 17,
      //       fontFamily: "IBMPlexSansThai",
      //     },
      //   },
      // },
    },
  };

  return (
    <div className=" flex items-end relative">
      <div className=" absolute -top-5 left-10 text-sm text-mainblue font-semibold">
        {title_x}
      </div>
      <Line data={data} config={config} options={options} />
      <div className=" absolute bottom-0 -right-2 md:-right-5 text-sm text-mainblue font-semibold">
        {title_y}
      </div>
    </div>
  );
};

export const LineChartEduInvestMain = ({
  dataFund,
  labels,
  head,
  title_x,
  title_y,
  fontSizeX,
  fontSizeY,
  width,
  height,
}) => {
  const isMobile = useIsMobile();

  const data = {
    labels: labels,
    datasets: [
      {
        label: head,
        data: dataFund,
        fill: true,
        pointRadius: 0,
        borderColor: ({ chart: { ctx } }) => {
          const borderColor = ["#16AA37"];
          return borderColor;
        },

        pointBackgroundColor: ["#16AA37"],
        backgroundColor: "rgba(22, 170, 55, 0.20)",
        tension: 0.2,
      },
    ],
  };

  const config = {
    type: "line",
    data,
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          maxTicksLimit: isMobile ? 10 : 20,
          font: {
            size: fontSizeX,
            family: "IBMPlexSansThai",
          },
        },
      },
      y: {
        ticks: {
          font: {
            size: fontSizeY,
            family: "IBMPlexSansThai",
          },
          beginAtZero: true,
          callback: function (value, index, values) {
            // Format the number to display as K (thousands)
            // return value > 1000
            //   ? `${numeral(value / 1000)?.format("0,0")}K`
            //   : value === 0
            //   ? 0
            //   : value < 1000
            //   ? `${numeral(value / 1000)?.format("0,0")}K`
            //   : value;

            return value > 1000000
              ? `${numeral(value / 1000000)?.format("0,0")}M`
              : value > 10000
              ? `${numeral(value / 10000)?.format("0,0")}k`
              : value === 0
              ? 0
              : value < 1000
              ? `${numeral(value)?.format("0,0")}`
              : value;
          },
        },
      },

      // y: {
      //   title: {
      //     beginAtZero: true,
      //     display: true,
      //     text: "บาท",
      //     font: {
      //       size: isMobile ? 14 : 17,
      //       fontFamily: "IBMPlexSansThai",
      //     },
      //   },
      // },
    },
  };

  return (
    <div className=" flex items-end relative">
      <div className=" absolute -top-5 left-10 text-sm text-mainblue font-semibold">
        {title_x}
      </div>
      <Line
        width={width}
        height={height}
        data={data}
        config={config}
        options={options}
      />
      <div className=" absolute bottom-0 -right-2 md:-right-5 text-sm text-mainblue font-semibold">
        {title_y}
      </div>
    </div>
  );
};
