import { useEffect, useState } from "react";
import { MTButton } from "../../../components/button/MTButton";
import { HeaderTitle, HeadtitleCard } from "../../../components/header/Header";
import Container from "../../../components/layout/Container";
import {
  STYLE_CARD_BG,
  STYLE_INPUT,
  STYLE_INPUT_ERROR,
  STYLE_INPUT_SLIDER,
  STYLE_MARGIN_TOP_PARENT,
} from "../../../utils/useStyle";
import { useSavingStore } from "../../../_store/savingStore";
import numeral from "numeral";
import { Slider } from "@material-tailwind/react";
import ViewExpensesSum from "./VIExpensesSum.Main";
import { SliderSingle } from "../../../components/slider/SliderSingle";
import ErrorMain from "../../../components/error/Error.Main";
import { formatNumberWithComma } from "../../../functions/Navigate.Goback";
import { set } from "lodash";
import { NumericFormat } from "react-number-format";
import Api from "../../../api/api.main";
import { useUserStore } from "../../../_store/userStore";
import NumbericValidateRed from "../../../components/DebtBalance/numericRed";

const checkError = (formSaving) => {
  const expenses_reduce_month =
    formSaving?.expenses_reduce_month?.length < 0 ||
    formSaving?.expenses_reduce_month === "" ||
    formSaving?.expenses_reduce_month === 0 ||
    formSaving?.expenses_reduce_month === null;
  // formSaving?.expenses_reduce_month > 10000000;

  const expenses_invest_yield =
    formSaving?.expenses_invest_yield === "" ||
    formSaving?.expenses_invest_yield === 0 ||
    formSaving?.expenses_invest_yield > formSaving?.expenses_invest_yield_max;

  const expenses_invest_yield_max =
    formSaving?.expenses_invest_yield_max === "" ||
    formSaving?.expenses_invest_yield_max === 0 ||
    formSaving?.expenses_invest_yield_max > 100 ||
    formSaving?.expenses_invest_yield_max === null ||
    formSaving?.expenses_invest_yield > formSaving?.expenses_invest_yield_max;

  const expenses_per_year =
    formSaving?.expenses_per_year === "" || formSaving?.expenses_per_year === 0;

  const expenses_per_year_max =
    formSaving?.expenses_per_year_max === "" ||
    formSaving?.expenses_per_year_max === 0 ||
    formSaving?.expenses_per_year_max > 100 ||
    formSaving?.expenses_per_year_max === null;

  return {
    expenses_reduce_month,
    expenses_invest_yield,
    expenses_invest_yield_max,
    expenses_per_year,
    expenses_per_year_max,
  };
};

const ViewExpensesPlanMain = ({ formSaving, setFormSaving, setShow }) => {
  const [sum, setSum] = useState("");

  // SavingPlan Logined
  const savingData = useSavingStore((state) => state.savingData);
  const setSavingeData = useSavingStore((state) => state.setSavingeData);
  const clearSavingData = useSavingStore((state) => state.clearSavingData);

  // UserData Logined
  const userData = useUserStore((state) => state.userData);
  const setUserData = useUserStore((state) => state.setUserData);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    expenses_reduce_month: false,
    expenses_invest_yield: false,
    expenses_invest_yield_max: false,
    expenses_per_year: false,
    expenses_per_year_max: false,
  });

  const onChangeExpensesMonth = (e) => {
    // if (isNaN(e.target.value)) return;
    // if (Number(e.target.value) > 10000000) return;
    let value = e.target.value;
    let output = parseFloat(value?.replace(/,/g, ""))
      ? parseFloat(value?.replace(/,/g, ""))
      : null;
    if (isNaN(output)) return;
    // if (output > 10000000) return;
    else {
      setError({
        ...error,
        expenses_reduce_month: false,
      });
    }
    setFormSaving({
      ...formSaving,
      expenses_reduce_month: output,
    });
    // setFormSaving({
    //   ...formSaving,
    //   expenses_reduce_month: formatNumberWithComma(value),
    // });
  };

  const getExpensesYear = () => {
    let sum = 0;
    const thisValue = numeral(formSaving?.expenses_reduce_month).value();
    sum = thisValue * 12;
    return Number(sum);
  };

  const onChangeExpeneseYield = (e) => {
    const value = e.target.value ? Number(e.target.value) : null;
    if (isNaN(e.target.value)) return;
    if (value > Number(formSaving?.expenses_invest_yield_max)) return;
    else {
      setError({
        ...error,
        expenses_invest_yield: false,
      });
    }

    setFormSaving({
      ...formSaving,
      expenses_invest_yield: value,
    });
  };
  const onChangeExpeneseYieldMax = (e) => {
    const value = e.target.value ? Number(e.target.value) : null;
    if (isNaN(e.target.value)) return;
    if (value > 100) return;

    setFormSaving({
      ...formSaving,
      expenses_invest_yield_max: value,
      expenses_invest_yield: value,
    });
  };

  const onChangeExpenesePerYear = (e) => {
    const value = e.target.value ? Number(e.target.value) : null;
    if (isNaN(e.target.value)) return;
    if (value > formSaving?.expenses_per_year_max) return;

    setFormSaving({
      ...formSaving,
      expenses_per_year: value,
    });
  };
  const onChangeExpenesePerYearMax = (e) => {
    const value = e.target.value ? Number(e.target.value) : null;
    if (isNaN(e.target.value)) return;
    if (e.target.value > 100) return;
    else {
      setError({
        ...error,
        expenses_per_year_max: false,
      });
    }

    setFormSaving({
      ...formSaving,
      expenses_per_year_max: value,
      expenses_per_year: value,
    });
  };

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      setFormSaving({
        ...formSaving,
        expenses_reduce_year: getExpensesYear(),
      });
    }
  }, [formSaving?.expenses_reduce_month]);

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      if (!userData) {
        setFormSaving({
          ...formSaving,
          // expenses_reduce_year: getExpensesYear(),
          // expenses_invest_yield: formSaving?.expenses_invest_yield_max,
          expenses_per_year: formSaving?.expenses_per_year_max,
        });
      }
    }
  }, [
    // formSaving?.expenses_invest_yield_max,
    // formSaving?.expenses_reduce_month,
    formSaving?.expenses_per_year_max,
  ]);

  const onClickSubmit = async () => {
    const formerror = checkError(formSaving);
    const isformerror = Object.values(formerror).some((item) => item === true);
    setError(formerror);
    if (isformerror) {
      return;
    } else {
      setSavingeData(formSaving);
      setSum("sum");

      // เช็คว่า User Online อยู่ไหม
      if (userData) {
        try {
          const userId = userData._id;

          const paramFilter = {
            filter: {
              user_id: userId,
            },
          };

          //---------- ตารางแผนการออม ----------//
          const { data: dataSavingPlan } = await Api.get("/savingPlans", {
            params: paramFilter,
          });

          if (dataSavingPlan?.savingPlans?.length > 0) {
            // update saving_plan table
            const id = dataSavingPlan?.savingPlans[0]?._id;
            await Api.put(`savingPlans/${id}`, formSaving);
          } else {
            // create saving_plan table
            await Api.post(`savingPlans`, {
              user_id: userId,
              ...formSaving,
            });
          }
          //------------------------------------//
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  return (
    <div>
      <div className="  flex flex-col justify-center items-center my-5">
        <img
          src="/images/saving_expenses.png"
          className=" w-[60px] h-[60px]"
          alt=""
        />
        <div className=" text-black text-xl font-semibold mt-2.5">
          วางแผนลดค่าใช้จ่าย
        </div>
      </div>
      {!sum && (
        <div className=" mx-[25px] space-y-4 ">
          <div className={`${STYLE_CARD_BG}`}>
            <HeadtitleCard title={`ลดค่าใช้จ่ายเดือนละ`} border />
            <div className={` px-[13px] mt-6`}>
              {/* <input
                className={
                  error?.expenses_reduce_month ? STYLE_INPUT_ERROR : STYLE_INPUT
                }
                value={formSaving?.expenses_reduce_month}
                onChange={onChangeExpensesMonth}
                placeholder="5,000"
                onWheelCapture={(e) => {
                  e.target.blur();
                }}
              /> */}
             <NumbericValidateRed
                maxLength={11}
                value={formSaving?.expenses_reduce_month}
                placeholder="5,000"
                onChange={onChangeExpensesMonth}
                thousandSeparator=","
                inputMode="decimal"
                pattern="[0-9],*"
                className={
                  error?.expenses_reduce_month ? STYLE_INPUT_ERROR : STYLE_INPUT
                }
              />

              <div className=" mt-[18px] text-center text-mainblue font-medium ">
                รวมลดได้ปีละ{" "}
                <span className=" text-maincyan text-xl font-semibold">
                  {" "}
                  {numeral(getExpensesYear()).format("0,0")}
                </span>{" "}
                บาท
              </div>
              {error?.expenses_reduce_month && <ErrorMain />}
            </div>
          </div>
          <div className={`${STYLE_CARD_BG}`}>
            <HeadtitleCard
              title={`นำเงินไปลงทุนอัตราผลตอบแทนต่อปี (%)`}
              border
            />
            <div className={` px-[13px] mt-6`}>
              <div>
                <div className=" flex justify-center">
                  <input
                    inputMode="decimal"
                    pattern="[0-9],*"
                    className={STYLE_INPUT_SLIDER}
                    value={formSaving?.expenses_invest_yield}
                    onChange={onChangeExpeneseYield}
                    placeholder="5"
                  />
                </div>
                <SliderSingle
                  inputMode="decimal"
                  pattern="[0-9],*"
                  valuemax={formSaving?.expenses_invest_yield_max}
                  value={formSaving?.expenses_invest_yield}
                  onChange={onChangeExpeneseYield}
                  onChnageMax={onChangeExpeneseYieldMax}
                />
              </div>

              {error?.expenses_invest_yield_max ? (
                <ErrorMain
                  errortext={
                    formSaving?.expenses_invest_yield_max === null
                      ? `กรุณากรอกอัตราผลตอบแทนสูงสุดต่อปี`
                      : `กรุณากรอกระยะเวลาไม่เกิน ${formSaving?.expenses_invest_yield_max} ปี`
                  }
                />
              ) : error?.expenses_invest_yield ? (
                <ErrorMain errortext="กรุณากรอกอัตราผลตอบแทนต่อปี" />
              ) : (
                ""
              )}
            </div>
          </div>
          <div className={`${STYLE_CARD_BG}`}>
            <HeadtitleCard title={`ระยะเวลาในการลงทุน (ปี)`} border />
            <div className={` px-[13px] mt-6`}>
              <div>
                <div className=" flex justify-center">
                  <input
                    className={STYLE_INPUT_SLIDER}
                    value={formSaving?.expenses_per_year}
                    onChange={onChangeExpenesePerYear}
                    placeholder="10"
                  />
                </div>
                <SliderSingle
                  valuemax={formSaving?.expenses_per_year_max}
                  value={formSaving?.expenses_per_year}
                  onChange={onChangeExpenesePerYear}
                  onChnageMax={onChangeExpenesePerYearMax}
                  year
                />
              </div>

              {error?.expenses_per_year_max ? (
                <ErrorMain
                  errortext={
                    formSaving?.expenses_per_year_max === null
                      ? `กรุณากรอกระยะเวลาสูงสุดในการลงทุน`
                      : `กรุณากรอกระยะเวลาไม่เกิน ${formSaving?.expenses_per_year_max} ปี`
                  }
                />
              ) : error?.expenses_per_year ? (
                <ErrorMain errortext="กรุณากรอกระยะเวลา" />
              ) : (
                ""
              )}
            </div>
          </div>
          <div
            style={{
              paddingBottom: `calc(87px)`,
            }}
            className="flex justify-center mb-6"
          >
            <MTButton text="คำนวณ" loading={loading} onClick={onClickSubmit} />
          </div>
        </div>
      )}

      {sum === "sum" && (
        <ViewExpensesSum
          formSaving={formSaving}
          setFormSaving={setFormSaving}
          sum={sum}
          setSum={setSum}
          setShow={setShow}
        />
      )}
    </div>
  );
};
export default ViewExpensesPlanMain;
