import { useNavigate } from "react-router-dom";
import { HeaderTitle } from "../../components/header/Header";
import Container from "../../components/layout/Container";
import NavigateGoBack from "../../functions/Navigate.Goback";
import { STYLE_MARGIN_TOP_PARENT } from "../../utils/useStyle";
import { CardMenuinsuarance } from "./component/Card";

const AllInsuarancMain = () => {
  const navigate = useNavigate();
  const NavigateAssureWealth = () => {
    window?.open("https://assurewealth.co.th/", "_blank");
  };

  return (
    <Container>
      <div className=" mb-20">
        <div className={`${STYLE_MARGIN_TOP_PARENT}`}>
          <HeaderTitle
            title="รวมแผนประกัน"
            isGoback
            onClickGoBack={() => {
              NavigateGoBack(navigate);
            }}
          />
          <div className=" text-center mt-[25px] text-maincyan font-semibold">
            <div> มาเริ่มต้นวางแผนกันเถอะ !</div>
            <div> เพื่อให้คุณและครอบครัวได้รับความคุ้มครองที่ดีที่สุด</div>
          </div>
          <div className=" grid grid-cols-2 gap-2.5 px-7 mt-4">
            <CardMenuinsuarance
              title={"ประกันชีวิต"}
              img={"/images/life_insuarance_all.png"}
              onClick={NavigateAssureWealth}
            />
            <CardMenuinsuarance
              title={"ประกันสุขภาพ"}
              img={"/images/sick_insuarance_all.png"}
              onClick={NavigateAssureWealth}
            />
            <CardMenuinsuarance
              title={"ประกันรถยนต์"}
              img={"/images/car_insuarance_all.png"}
              onClick={NavigateAssureWealth}
            />
            <CardMenuinsuarance
              title={"ประกันทรัพย์สิน"}
              img={"/images/money_insuarance_all.png"}
              onClick={NavigateAssureWealth}
            />
            <CardMenuinsuarance
              title={"ประกันรายได้"}
              img={"/images/income_insuarance_all.png"}
              onClick={NavigateAssureWealth}
            />
            <CardMenuinsuarance
              title={"ประกันอื่น ๆ"}
              img={"/images/invest_insuarance_all.png"}
              onClick={NavigateAssureWealth}
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default AllInsuarancMain;
