import { Drawer } from "@material-tailwind/react";
import { MTButton } from "../../../components/button/MTButton";

export const PopupBalanceMain = ({
  open,
  onClose,
  head,
  subtitle,
  discription,
  bottom = "bottom-[-15%]",
  icon,
  panel,
  padding = "px-10",
}) => {
  return (
    <Drawer
      zindex={100}
      placement="bottom"
      open={open}
      onClose={onClose}
      className=" rounded-t-[40px] !max-h-[60vh] overflow-y-scroll  "
    >
      <div
        className={` w-[90px] h-[3px] rounded-full mx-auto bg-[#E0DFDF] mb-7 mt-5`}
      ></div>
      <div className="mx-auto w-20 h-fit ">
        <img src={icon} className="w-full h-full" alt="" />
      </div>
      <div className=" h-fit md:h-full relative ">
        <div className="max-w-2xl mx-auto">
          <div
            className={`${padding}  flex flex-col justify-center items-center mt-4`}
          >
            <div className={``}>{head}</div>
            <div className={`  text-xl font-semibold text-center my-2.5`}>
              {subtitle}
            </div>
            <div className=" text-mainblue text-center leading-relaxed">
              {discription}
            </div>
          </div>
          <div className=" flex justify-center pt-5">
            {" "}
            <MTButton text="ปิด" onClick={onClose} />
          </div>
        </div>

        <img
          src="/images/bgblue.png"
          // className={`absolute ${bottom} -z-10 w-full md:h-full h-fit`}
          className={`absolute bottom-[-65%] -z-10 w-full  h-fit`}
          alt=""
        />
      </div>
    </Drawer>
  );
};
