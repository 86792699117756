import { useContext } from "react";
import { PdfContext } from "../context/pdf.ctx";
import { DateTime } from "luxon";

function PdfHeader({ date = "DD/MM/YYYY เวลา 12:00 น." }) {
  const pdfCtx = useContext(PdfContext);

  const getDate = () => {
    if (pdfCtx?.pdfDate) {
      const pdfJsDate = new Date(pdfCtx?.pdfDate);
      const fullDate = DateTime.fromJSDate(pdfJsDate).toFormat("dd/MM/yyyy");
      const time = DateTime.fromJSDate(pdfJsDate).toFormat("HH:mm");
      return `${fullDate} เวลา ${time} น.`;
    }
    return date;
  };

  return (
    <div className="flex justify-between items-start">
      <img
        src="/images/logo_assure.svg"
        alt="logo"
        className=" bg-cover cursor-pointer mr-auto "
      />
      <div className="flex flex-col items-end">
        <div className="text-mainblue font-bold text-[12px]">
          รายงานแผนการเงินส่วนบุคคล
        </div>
        <div className="text-mainblue text-[11px]">ณ วันที่ {getDate()}</div>
      </div>
    </div>
  );
}

export default PdfHeader;
