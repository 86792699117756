import { Drawer, Input } from "@material-tailwind/react";
import { useRetireStore } from "../../../_store/retireStore";
import CheckBoxMain from "../../../components/checkbox/CheckboxMain";
import { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { SliderSingle } from "../../../components/slider/SliderSingle";
import { MTButton } from "../../../components/button/MTButton";
import {
  STYLE_BATH_STG,
  STYLE_INPUT_SLIDER,
  STYLE_INPUT_STG,
  STYLE_PARENT_STG,
  STYLE_TEXT_BLUE,
} from "../../../utils/useStyle";
import { formatSTG, mapingSTG } from "../functions/functionMain";
import _, { set } from "lodash";
import numeral from "numeral";
import ErrorMain from "../../../components/error/Error.Main";
import { useIsMobile } from "../../../utils/useIsMobile";
import { ICON_ARROW_UP_DOWN } from "../../../utils/useIcons";
import { NumericFormat } from "react-number-format";
import { MiltiSlider } from "../../../components/slider/Slider.Main";
import { CardStrategy, CardStrategy2 } from "../../../components/card/CardMain";
import { ImageMain } from "../../../components/img/Img.Main";
import NumbericValidateRed from "../../../components/DebtBalance/numericRed";

const numberWithoutComma = (value) => {
  let result = parseInt(value?.replace(/,/g, ""), 10);
  return result;
};

const StrategyMain = ({
  openStrategy,
  setOpenStrategy,
  onClick,
  openInfo,
  setOpenInfo,
  setStrategy,
  strategy,
  openOutcome,
  setOpenOutcome,
  openPassive,
  setOpenPasssive,
  openIncome,
  setOpenIncome,
  openAssetGen,
  setOpenAssetGen,
  openSalary,
  setOpenSalary,
  openInvestment,
  setOpenInvestment,
  openControlRate,
  setOpenControlRate,
  openRetire,
  setOpenRetire,
  openDead,
  setOpenDead,
  setTabPlan,
  error,
  setError,
}) => {
  const retireData = useRetireStore((state) => state.retireData);
  const setRetireData = useRetireStore((state) => state.setRetireData);
  const [age, setAge] = useState([
    retireData?.strategy_age_retire,
    retireData?.strategy_age_dead,
  ]);
  // const clearRetireData = useRetireStore((state) => state.retireData);
  const isMobile = useIsMobile();

  const checkErrorSTG = (
    retireData,
    openOutcome,
    openPassive,
    openIncome,
    openAssetGen,
    openSalary,
    openInvestment,
    openControlRate,
    openRetire,
    openDead
  ) => {
    const checkopenOutcome =
      openOutcome && retireData?.strategy_out_come === "" ? true : false;

    // const checkOpenPassive =
    //   openPassive && retireData?.strategy_passive_income_retire === ""
    //     ? true
    //     : false;
    // const checkOpenIncome =
    //   openIncome && retireData?.strategy_income === "" ? true : false;
    // const checkOpenAssetGen =
    //   openAssetGen && retireData?.strategy_assets_generate === ""
    //     ? true
    //     : false;
    // const checkOpenSalary =
    //   openSalary && retireData?.strategy_salary === "" ? true : false;
    // const checkOpenInvestment =
    //   openInvestment && retireData?.strategy_investment_bf_retirement === ""
    //     ? true
    //     : false;
    // const checkOpenControlRate =
    //   openControlRate && retireData?.strategy_control_rate_outcome === ""
    //     ? true
    //     : false;
    // const checkOpenRetire =
    //   openRetire && retireData?.strategy_age_retire === "" ? true : false;
    // const checkOpenDead =
    //   openDead && retireData?.strategy_age_dead === "" ? true : false;
    return {
      checkopenOutcome,
      // checkOpenPassive,
      // checkOpenIncome,
      // checkOpenAssetGen,
      // checkOpenSalary,
      // checkOpenInvestment,
      // checkOpenControlRate,
      // checkOpenRetire,
      // checkOpenDead,
    };
  };

  const isValidate = () => {
    // const check_age_retire =
    //   openRetire && retireData?.strategy_age_retire < retireData?.age_retire;
    const check_age_dead =
      openDead && retireData?.strategy_age_dead < retireData?.age_retire;
    return {
      // check_age_retire,
      check_age_dead,
    };
  };

  useEffect(() => {
    setRetireData({
      ...retireData,
      openAssetGen,
      openInvestment,
    });
  }, [openAssetGen, openInvestment]);

  const handleOpen = (value) => setOpenInfo(openInfo === value ? 0 : value);

  const [value, setValue] = useState(false);

  const onChangeSTGOutcome = (e) => {
    let value = numberWithoutComma(e.target.value);
    if (isNaN(value))
      return setRetireData({
        ...retireData,
        strategy_out_come: "",
      });

    setRetireData({
      ...retireData,
      strategy_out_come: value,
    });
  };

  const onChangePassiveIncome = (e) => {
    let value = numberWithoutComma(e.target.value);
    if (isNaN(value))
      return setRetireData({
        ...retireData,
        strategy_passive_income_retire: "",
      });

    setRetireData({
      ...retireData,
      strategy_passive_income_retire: value,
    });
  };

  const onChangeSTGIncome = (e) => {
    let value = numberWithoutComma(e.target.value);
    if (isNaN(value))
      return setRetireData({
        ...retireData,
        strategy_income: "",
      });

    setRetireData({
      ...retireData,
      strategy_income: value,
    });
  };

  const onChangeAsset = (e) => {
    let value = numberWithoutComma(e.target.value);
    if (isNaN(value))
      return setRetireData({
        ...retireData,
        strategy_assets_generate: "",
      });

    setRetireData({
      ...retireData,
      strategy_assets_generate: value,
    });
  };

  const onChangeAssetPerYear = (e) => {
    const value = e.target.value ? Number(e.target.value) : null;

    if (isNaN(e.target.value)) return setRetireData({});
    if (Number(e.target.value) > retireData?.investment_bf_retirement_max)
      return;
    else
      setRetireData({
        ...retireData,
        strategy_assets_generate_per_year: value,
        strategy_investment_bf_retirement: value,
      });
  };

  const onChangeSTGSalary = (e) => {
    let value = numberWithoutComma(e.target.value);
    if (isNaN(value))
      return setRetireData({
        ...retireData,
        strategy_salary: "",
      });

    setRetireData({
      ...retireData,
      strategy_salary: value,
    });
  };

  const onChangeSTGSalaryPerYear = (e) => {
    if (isNaN(e.target.value)) return;
    setRetireData({
      ...retireData,
      strategy_salary_peryear: e.target.value ? Number(e.target.value) : "",
    });
  };

  const onChangeSTGSalaryPerYearMax = (e) => {
    if (isNaN(e.target.value)) return;
    setRetireData({
      ...retireData,
      strategy_salary_peryear: e.target.value ? Number(e.target.value) : "",
      strategy_salary_peryear_max: e.target.value ? Number(e.target.value) : "",
    });
  };

  const onChangeBeforeRetire = (e) => {
    const value = e.target.value ? Number(e.target.value) : null;

    if (isNaN(e.target.value)) return;
    if (Number(e.target.value) > retireData?.investment_bf_retirement_max)
      return;
    setRetireData({
      ...retireData,
      strategy_investment_bf_retirement: value,
      strategy_assets_generate_per_year: value,
    });
  };

  const onChangeBeforeRetireMax = (e) => {
    const value = e.target.value ? Number(e.target.value) : null;
    if (value > 100) return;

    if (isNaN(e.target.value)) return;
    // if (value < retireData?.strategy_investment_bf_retirement) return;
    // if (value < retireData?.strategy_assets_generate_per_year) return;

    setRetireData({
      ...retireData,
      strategy_investment_bf_retirement: value,
      investment_bf_retirement_max: value,
    });
  };

  const onChangeAfterRetire = (e) => {
    const value = e.target.value ? Number(e.target.value) : null;

    if (isNaN(e.target.value)) return;
    if (Number(e.target.value) > retireData?.investment_af_retirement_max)
      return;
    setRetireData({
      ...retireData,
      strategy_investment_af_retirement: value,
    });
  };

  const onChangeAfterRetireMax = (e) => {
    const value = e.target.value ? Number(e.target.value) : null;
    if (value > 100) return;

    if (isNaN(e.target.value)) return;
    setRetireData({
      ...retireData,
      strategy_investment_af_retirement: value,
      investment_af_retirement_max: value,
    });
  };

  const onChangeControlRate = (e) => {
    if (isNaN(e.target.value)) return;
    setRetireData({
      ...retireData,
      strategy_control_rate_outcome: e.target.value
        ? Number(e.target.value)
        : null,
    });
  };

  const onChangeControlRateMax = (e) => {
    if (isNaN(e.target.value)) return;
    setRetireData({
      ...retireData,
      strategy_control_rate_outcome: e.target.value
        ? Number(e.target.value)
        : null,
      strategy_control_rate_outcome_max: e.target.value
        ? Number(e.target.value)
        : null,
    });
  };

  const onChangeSTGRetireAge = (e) => {
    const value = e.target.value ? Number(e.target.value) : null;
    if (isNaN(e.target.value)) return setRetireData({});
    if (value === null) {
      setRetireData({
        ...retireData,
        strategy_age_retire: "",
      });
      return;
    }
    setRetireData({
      ...retireData,
      strategy_age_retire: value,
    });

    if (value > 120) return;
    setAge(Number(e?.target?.value));
    if (isNaN(e.target.value)) return;
  };

  const onChangeSTGDeadAge = (e) => {
    const value = e.target.value ? Number(e.target.value) : null;
    if (isNaN(e.target.value)) return setRetireData({});
    if (value > 120) return;

    if (value === null) {
      setRetireData({
        ...retireData,
        strategy_age_dead: "",
      });
      return;
    }
    let retire = retireData?.strategy_age_retire
      ? Number(retireData?.strategy_age_retire)
      : Number(retireData?.age_retire);

    // if (value < retire) return;

    if (value === null) {
      setRetireData({
        ...retireData,
        strategy_age_dead: "",
      });
      return;
    }

    setAge(Number(e?.target?.value));
    setRetireData({
      ...retireData,
      strategy_age_dead: value,
    });
  };

  //slibar อายุเกษียณ
  const onChangeAgeSTG = (e) => {
    // console.log("AA:", e);

    if (e[1] > e[2])
      return setRetireData({
        ...retireData,
        strategy_age_retire: e[1],
      });

    setAge(e);
    setRetireData({
      ...retireData,
      strategy_age_retire: e[1],
    });
  };

  const onChangeAgeSTGDDead = (e) => {
    // console.log("AA:", e);
    setAge(e);
    setRetireData({
      ...retireData,
      strategy_age_dead: e[2],
    });
  };

  useEffect(() => {
    if (retireData?.strategy_out_come > 0 ) {
    setOpenOutcome(true)
    }
    if (retireData?.strategy_passive_income_retire > 0) {
      setOpenPasssive(true)
    }
    if (retireData?.strategy_income > 0) {
      setOpenIncome(true)
    }
    if (retireData?.strategy_assets_generate > 0) {
      setOpenAssetGen(true)
    }
    if (retireData?.strategy_salary > 0) {
      setOpenSalary(true)
    }
    if (retireData?.strategy_investment_bf_retirement > 0) {
      setOpenInvestment(true)
    }
    if (retireData?.strategy_control_rate_outcome > 0) {
      setOpenControlRate(true)
    }
    if (retireData?.strategy_age_retire > 0) {
      setOpenRetire(true)
    }
    if (retireData?.strategy_age_dead > 0) {
      setOpenDead(true)
    }
  },[])
  
  return (
    <div>
      <Drawer
        placement="bottom"
        open={openStrategy}
        onClose={() => setOpenStrategy(false)}
        className=" p-5  rounded-t-[40px] !max-h-[90%] overflow-y-scroll"
      >
        <div className=" w-[90px] h-[3px] rounded-full mx-auto bg-[#E0DFDF] mb-7 "></div>

        <div className=" space-y-[11px]">
          <div className="text-mainblue text-[28px] font-semibold ">
            ปรับกลยุทธ์ให้มีสุข
          </div>
        </div>

        <div className=" space-y-3 h-full">
          {/* -------- ลดรายจ่าย -------- */}
          <div className=" ">
            <CheckBoxMain
              retireData={retireData}
              index={1}
              label="ลดรายจ่าย"
              icon="../images/stg_1.png"
              info
              open={openOutcome}
              setOpen={setOpenOutcome}
              onClickInfo={() => {
                handleOpen(1);
              }}
              field={"strategy_out_come"}
              setRetireData={setRetireData}
              panel={
                <div className="px-4 font-IBMPlexSansThai pb-2">
                  <div>
                    รายจ่ายเดิม
                    <span className=" ml-2 font-semibold text-maincyan">
                      {numeral(retireData?.out_come)?.format("0,0")} บาท
                    </span>
                  </div>
                  {retireData?.strategy_out_come ? (
                    <CardStrategy2
                      padding="p-1"
                      space="space-y-1"
                      icon_width="w-5 h-5"
                      icon={`../images/stg_1.png`}
                      unit={`บาท`}
                      title={`ลดรายจ่าย`}
                      value1={numeral(retireData?.out_come)?.format("0,0")}
                      value2={numeral(
                        retireData?.out_come - retireData?.strategy_out_come
                      )?.format("0,0")}
                    />
                  ) : (
                    ""
                  )}

                  <div className={STYLE_PARENT_STG}>
                    <NumericFormat
                      className={STYLE_INPUT_STG}
                      placeholder="ใส่จำนวนลดรายจ่ายที่จะลดลง ที่นี่ (บาท) "
                      value={retireData?.strategy_out_come}
                      onChange={onChangeSTGOutcome}
                      // type="number"
                      maxLength={11}
                      thousandSeparator=","
                      inputMode="decimal"
                      pattern="[0-9],*"
                    />
                    <div className={STYLE_BATH_STG}>บาท</div>
                    {error?.checkopenOutcome && <ErrorMain errortext="test" />}
                  </div>
                </div>
              }
            />
          </div>
          {/* passive income ยามเกษียณ */}
          <div>
            <CheckBoxMain
              retireData={retireData}
              index={2}
              label={
                isMobile ? (
                  <div>
                    <div> หา Passive Income เพิ่มยามเกษียณ </div>
                    <div>(เดือนละ)</div>
                  </div>
                ) : (
                  <div>
                    <div> หา Passive Income เพิ่มยามเกษียณ (เดือนละ)</div>
                  </div>
                )
              }
              icon="../images/stg_2.png"
              info
              // open={openInfo === 2}
              open={openPassive}
              setOpen={setOpenPasssive}
              field={"strategy_passive_income_retire"}
              setRetireData={setRetireData}
              onClickInfo={() => handleOpen(2)}
              panel={
                <div
                  className={classNames(
                    value
                      ? "px-4 pt-2 font-IBMPlexSansThai pb-3"
                      : " px-4 font-IBMPlexSansThai pb-3"
                  )}
                >
                  <div className=" mt-1">
                    รายได้จากบำนาญเดิม
                    <span className=" ml-2 font-semibold text-maincyan">
                      {numeral(retireData?.pension)?.format("0,0")} บาท
                    </span>
                  </div>
                  {retireData?.strategy_passive_income_retire ? (
                    <CardStrategy2
                      padding="p-1"
                      space="space-y-1"
                      icon_width="w-5 h-5"
                      icon={`../images/stg_2.png`}
                      unit={`บาท`}
                      title={`หา Passive Income เพิ่มยามเกษียณ`}
                      value1={numeral(retireData?.pension)?.format("0,0")}
                      value2={numeral(
                        retireData?.pension +
                          retireData?.strategy_passive_income_retire
                      )?.format("0,0")}
                    />
                  ) : (
                    ""
                  )}
                  <div className={STYLE_PARENT_STG}>
                    <NumericFormat
                      className={`${STYLE_INPUT_STG}`}
                      label="ใส่รายได้ยามเกษียณ ที่นี่"
                      value={retireData?.strategy_passive_income_retire}
                      onChange={onChangePassiveIncome}
                      // type="number"
                      maxLength={11}
                      placeholder="ใส่รายได้ยามเกษียณ ที่นี่"
                      thousandSeparator=","
                      inputMode="decimal"
                      pattern="[0-9],*"
                    />
                    <div className={STYLE_BATH_STG}>บาท</div>
                  </div>
                </div>
              }
            />
          </div>
          {/* เพิ่มรายได้ */}
          <div>
            <CheckBoxMain
              retireData={retireData}
              index={3}
              label="เพิ่มรายได้"
              icon={"../images/saving_expenses.png"}
              info
              open={openIncome}
              setOpen={setOpenIncome}
              field={"strategy_income"}
              setRetireData={setRetireData}
              onClickInfo={() => handleOpen(3)}
              panel={
                <div
                  className={classNames(
                    value
                      ? "px-4 pt-2 font-IBMPlexSansThai pb-3"
                      : " px-4 font-IBMPlexSansThai pb-3"
                  )}
                >
                  <div>
                    รายได้เดิม
                    <span className=" ml-2 font-semibold text-maincyan ">
                      {numeral(retireData?.salary)?.format("0,0")} บาท
                    </span>
                  </div>
                  {retireData?.strategy_income ? (
                    <CardStrategy2
                      padding="p-1"
                      space="space-y-1"
                      icon_width="w-5 h-5"
                      icon={`../images/saving_expenses.png`}
                      unit={`บาท`}
                      title={`เพิ่มรายได้`}
                      value1={numeral(retireData?.salary)?.format("0,0")}
                      value2={numeral(
                        retireData?.salary + retireData?.strategy_income
                      )?.format("0,0")}
                    />
                  ) : (
                    ""
                  )}
                  <div className={STYLE_PARENT_STG}>
                    <NumericFormat
                      className={STYLE_INPUT_STG}
                      value={retireData?.strategy_income}
                      onChange={onChangeSTGIncome}
                      maxLength={11}
                      placeholder="ใส่จำนวนรายได้เพิ่มเติม ที่นี่"
                      thousandSeparator=","
                      inputMode="decimal"
                      pattern="[0-9],*"
                    />
                    <div className={STYLE_BATH_STG}>บาท</div>
                  </div>
                </div>
              }
            />
          </div>

          {/* แปลงสินทรัพย์ให้มีผลตอบแทน */}
          <div>
            <CheckBoxMain
              retireData={retireData}
              index={4}
              label="แปลงสินทรัพย์ให้มีผลตอบแทน"
              icon={"../images/saving_save.png"}
              info
              // open={openInfo === 4}
              open={openAssetGen}
              setOpen={setOpenAssetGen}
              field={"strategy_assets_generate"}
              field2={"strategy_assets_generate_per_year"}
              setRetireData={setRetireData}
              onClickInfo={() => handleOpen(4)}
              panel={
                <div
                  className={classNames(
                    value
                      ? "px-4 pt-2 font-IBMPlexSansThai pb-3"
                      : " px-4 font-IBMPlexSansThai pb-3"
                  )}
                >
                  <div className=" space-y-5 ">
                    <div>
                      สินทรัพย์เดิม
                      <span className=" ml-2 font-semibold text-maincyan">
                        {numeral(retireData?.investment_assets)?.format("0,0")}{" "}
                        บาท
                      </span>
                      {retireData?.strategy_assets_generate ? (
                        <div className=" space-y-1">
                          <CardStrategy2
                            padding="p-1"
                            space="space-y-1"
                            icon_width="w-5 h-5"
                            icon={`../images/saving_save.png`}
                            unit={``}
                            title={` แปลงสินทรัพย์ให้มีผลตอบแทน`}
                            value1={numeral(
                              retireData?.investment_assets
                            )?.format("0,0")}
                            value2={numeral(
                              retireData?.strategy_assets_generate +
                                retireData?.investment_assets
                            )?.format("0,0")}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <li className="  ">
                      แปลงสินทรัพย์ให้มีผลตอบแทน
                      <div className={STYLE_PARENT_STG}>
                        <NumericFormat
                          // type="number"
                          maxLength={11}
                          className={STYLE_INPUT_STG}
                          value={retireData?.strategy_assets_generate}
                          onChange={onChangeAsset}
                          placeholder="ใส่มูลค่าสินทรัพย์ลงทุนที่นี่"
                          thousandSeparator=","
                          inputMode="decimal"
                          pattern="[0-9],*"
                        />
                        <div className={STYLE_BATH_STG}>บาท</div>
                      </div>
                    </li>
                    <div>
                      อัตราผลตอบแทนเดิม
                      <span className=" ml-2 font-semibold text-maincyan">
                        {numeral(retireData?.investment_bf_retirement)?.format(
                          "0,0"
                        )}{" "}
                        %
                      </span>
                      {retireData?.strategy_assets_generate_per_year ? (
                        <div className=" space-y-1">
                          <CardStrategy2
                            padding="p-1"
                            space="space-y-1"
                            icon_width="w-5 h-5"
                            icon={`../images/saving_save.png`}
                            unit={`%`}
                            title={` อัตราผลตอบแทนคาดหวัง (%) `}
                            value1={numeral(
                              retireData?.investment_bf_retirement
                            )?.format("0,0")}
                            value2={numeral(
                              retireData?.strategy_assets_generate_per_year
                            )?.format("0,0")}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <li className=" ">
                      อัตราผลตอบแทนคาดหวัง (%)
                      <div className={STYLE_PARENT_STG}>
                        <div className=" flex justify-center ">
                          <input
                            onWheelCapture={(e) => {
                              e.target.blur();
                            }}
                            className={`input-number w-7 h-6 text-center text-mainblue font-semibold focus:outline-none focus:ring-2 focus:ring-mainblue`}
                            value={
                              retireData?.strategy_assets_generate_per_year
                            }
                            onChange={onChangeAssetPerYear}
                            placeholder="0"
                            inputMode="decimal"
                            pattern="[0-9],*"
                          />

                          <div className=" text-xs text-bluegray50">%</div>
                        </div>
                        <div className=" md:px-28 ">
                          <SliderSingle
                            placeholder="0"
                            valuemax={retireData?.investment_bf_retirement_max}
                            value={
                              retireData?.strategy_assets_generate_per_year
                            }
                            onChange={onChangeAssetPerYear}
                            onChnageMax={onChangeBeforeRetireMax}
                          />
                        </div>
                      </div>
                    </li>
                  </div>
                </div>
              }
            />
          </div>

          {/* พัฒนาเงินเดือน */}
          <div>
            <CheckBoxMain
              retireData={retireData}
              index={5}
              label="พัฒนาศักยภาพให้เงินเดือนและอัตราการปรับเงินเดือนเพิ่มขึ้น"
              icon={"../images/stg_3.png"}
              info
              open={openSalary}
              setOpen={setOpenSalary}
              field={"strategy_salary"}
              field2={"strategy_salary_peryear"}
              setRetireData={setRetireData}
              onClickInfo={() => handleOpen(5)}
              panel={
                <div
                  className={classNames(
                    value
                      ? "px-4 pt-2 font-IBMPlexSansThai pb-3 "
                      : "px-4 font-IBMPlexSansThai pb-3 "
                  )}
                >
                  <div className=" space-y-5 ">
                    <div className=" mt-1">
                      เงินเดือนเดิม
                      <span className=" ml-2 font-semibold text-maincyan">
                        {numeral(retireData?.salary)?.format("0,0")} บาท
                      </span>
                      {openSalary && retireData?.strategy_salary ? (
                        <div className="mb-2">
                          <CardStrategy2
                            padding="p-1"
                            space="space-y-1"
                            icon_width="w-5 h-5"
                            icon={`../images/stg_3.png`}
                            unit={``}
                            title={`เงินเดือนใหม่`}
                            value1={numeral(retireData?.salary)?.format("0,0")}
                            value2={numeral(
                              retireData?.strategy_salary
                            )?.format("0,0")}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <li className=" ">
                      เงินเดือนใหม่
                      <div className={STYLE_PARENT_STG}>
                        <NumericFormat
                          // type="number"
                          maxLength={11}
                          className={STYLE_INPUT_STG}
                          value={retireData?.strategy_salary}
                          onChange={onChangeSTGSalary}
                          placeholder="ใส่จำนวนเงินเดือนใหม่ที่นี่"
                          thousandSeparator=","
                          inputMode="decimal"
                          pattern="[0-9],*"
                        />
                        <div className={STYLE_BATH_STG}>บาท</div>
                      </div>
                    </li>

                    <div>
                      อัตราการเพิ่มเงินเดือนเก่า
                      <span className=" ml-2 font-semibold text-maincyan">
                        {retireData?.salary_peryear} %
                      </span>
                      {openSalary && retireData?.strategy_salary_peryear ? (
                        <CardStrategy2
                          padding="p-1"
                          space="space-y-1"
                          icon_width="w-5 h-5"
                          icon={`../images/stg_3.png`}
                          unit={``}
                          title={` อัตราการเพิ่มเงินเดือนใหม่ (%)`}
                          value1={numeral(retireData?.salary_peryear)?.format(
                            "0,0"
                          )}
                          value2={numeral(
                            retireData?.strategy_salary_peryear
                          )?.format("0,0")}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <li className=" ">
                      อัตราการเพิ่มเงินเดือนใหม่ (%)
                      <div className=" flex justify-center">
                        <input
                          onWheelCapture={(e) => {
                            e.target.blur();
                          }}
                          className={`input-number w-7 h-6 text-center text-mainblue font-semibold focus:outline-none focus:ring-2 focus:ring-mainblue`}
                          value={retireData?.strategy_salary_peryear}
                          onChange={onChangeSTGSalaryPerYear}
                          placeholder="0"
                          inputMode="decimal"
                          pattern="[0-9],*"
                        />
                        <div className=" text-xs text-bluegray50">%</div>
                      </div>
                      <div className=" md:px-28 ">
                        <SliderSingle
                          placeholder="0"
                          valuemax={retireData?.strategy_salary_peryear_max}
                          value={retireData?.strategy_salary_peryear}
                          onChange={onChangeSTGSalaryPerYear}
                          onChnageMax={onChangeSTGSalaryPerYearMax}
                        />
                      </div>
                    </li>
                  </div>
                </div>
              }
            />
          </div>

          {/* การจัดการ การลงทุนก่อนและหลังเกษียณ */}
          <div>
            <CheckBoxMain
              retireData={retireData}
              index={6}
              label="การจัดการ การลงทุนก่อนและหลังเกษียณ"
              icon={"../images/stg_7.png"}
              info
              // open={openInfo === 6}
              open={openInvestment}
              setOpen={setOpenInvestment}
              field={"strategy_investment_bf_retirement"}
              field2={"strategy_investment_af_retirement"}
              setRetireData={setRetireData}
              onClickInfo={() => handleOpen(6)}
              panel={
                <div
                  className={classNames(
                    value
                      ? "px-4 mt-2 font-IBMPlexSansThai"
                      : " px-4 font-IBMPlexSansThai"
                  )}
                >
                  <div className="">
                    <div className=" text-sm font-medium text-medium">
                      1. อัตราผลตอบแทนก่อนเกษียณ (%)
                      <div className=" mt-2">
                        <div className=" text-[16px] mt-1 font-normal">
                          เดิม
                          <span className="ml-2 font-semibold text-maincyan">
                            {retireData?.investment_bf_retirement} %
                          </span>
                        </div>
                        {openInvestment &&
                        retireData?.strategy_investment_bf_retirement ? (
                          <div className="mb-2">
                            <CardStrategy2
                              padding="p-1"
                              space="space-y-1"
                              icon_width="w-5 h-5"
                              icon={`../images/stg_7.png`}
                              unit={``}
                              title={`อัตราผลตอบแทนก่อนเกษียณ (%)`}
                              value1={numeral(
                                retireData?.investment_bf_retirement
                              )?.format("0,0")}
                              value2={numeral(
                                retireData?.strategy_investment_bf_retirement
                              )?.format("0,0")}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        <div className=" flex justify-center ">
                          <input
                            onWheelCapture={(e) => {
                              e.target.blur();
                            }}
                            className={`input-number w-7 h-6 text-center text-mainblue font-semibold focus:outline-none focus:ring-2 focus:ring-mainblue`}
                            value={
                              retireData?.strategy_investment_bf_retirement
                            }
                            onChange={onChangeBeforeRetire}
                            placeholder="0"
                            inputMode="decimal"
                            pattern="[0-9],*"
                          />

                          <div className=" text-xs text-bluegray50">%</div>
                        </div>
                        <div className=" md:px-28 ">
                          <SliderSingle
                            placeholder="0"
                            valuemax={retireData?.investment_bf_retirement_max}
                            value={
                              retireData?.strategy_investment_bf_retirement
                            }
                            onChange={onChangeBeforeRetire}
                            onChnageMax={onChangeBeforeRetireMax}
                          />
                        </div>
                      </div>
                    </div>

                    <div className=" text-sm font-medium text-medium">
                      2. อัตราผลตอบแทนหลังเกษียณ (%)
                      <div className=" mt-2">
                        <div className=" text-[16px] mt-1 font-normal">
                          เดิม
                          <span className="ml-2 font-semibold text-maincyan">
                            {retireData?.investment_af_retirement} %
                          </span>
                        </div>
                        {openInvestment &&
                        retireData?.strategy_investment_bf_retirement ? (
                          <div className="mb-2">
                            <CardStrategy2
                              padding="p-1"
                              space="space-y-1"
                              icon_width="w-5 h-5"
                              icon={`../images/stg_7.png`}
                              unit={``}
                              title={`อัตราผลตอบแทนหลังเกษียณ (%)`}
                              value1={numeral(
                                retireData?.investment_af_retirement
                              )?.format("0,0")}
                              value2={numeral(
                                retireData?.strategy_investment_af_retirement
                              )?.format("0,0")}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        <div className=" flex justify-center">
                          <input
                            onWheelCapture={(e) => {
                              e.target.blur();
                            }}
                            className={`input-number w-7 h-6 text-center text-mainblue font-semibold focus:outline-none focus:ring-2 focus:ring-mainblue`}
                            value={
                              retireData?.strategy_investment_af_retirement
                            }
                            onChange={onChangeAfterRetire}
                            placeholder="0"
                            inputMode="decimal"
                            pattern="[0-9],*"
                          />
                          <div className=" text-xs text-bluegray50">%</div>
                        </div>
                        <div className=" md:px-28 ">
                          <SliderSingle
                            placeholder="0"
                            valuemax={retireData?.investment_af_retirement_max}
                            value={
                              retireData?.strategy_investment_af_retirement
                            }
                            onChange={onChangeAfterRetire}
                            onChnageMax={onChangeAfterRetireMax}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
            />
          </div>

          {/* ปรับพฤติกรรมและควบคุมอัตราการเพิ่มขึ้นของค่าใช้จ่าย */}
          <div>
            <CheckBoxMain
              retireData={retireData}
              index={7}
              label="ปรับพฤติกรรมและควบคุมอัตราการเพิ่มขึ้นของค่าใช้จ่าย"
              icon={"../images/stg_6.png"}
              info
              open={openControlRate}
              setOpen={setOpenControlRate}
              field={"strategy_control_rate_outcome"}
              setRetireData={setRetireData}
              onClickInfo={() => handleOpen(7)}
              panel={
                <div
                  className={classNames(
                    value
                      ? "px-4 pt-2 font-IBMPlexSansThai"
                      : " px-4 font-IBMPlexSansThai"
                  )}
                >
                  <div className=" mt-1">
                    อัตราการเพิ่มค่าใช้จ่ายเก่า
                    <span className=" ml-2 font-semibold text-maincyan">
                      {retireData?.out_come_peryear} %
                    </span>
                  </div>
                  {openControlRate &&
                  retireData?.strategy_control_rate_outcome ? (
                    <div className="mb-2">
                      <CardStrategy2
                        padding="p-1"
                        space="space-y-1"
                        icon_width="w-5 h-5"
                        icon={`../images/stg_7.png`}
                        unit={``}
                        title={`ปรับพฤติกรรมและควบคุมอัตราการเพิ่มขึ้นของค่าใช้จ่าย (%)`}
                        value1={numeral(retireData?.out_come_peryear)?.format(
                          "0,0"
                        )}
                        value2={numeral(
                          retireData?.strategy_control_rate_outcome
                        )?.format("0,0")}
                      />
                    </div>
                  ) : (
                    ""
                  )}

                  <div className=" flex justify-center">
                    <input
                      onWheelCapture={(e) => {
                        e.target.blur();
                      }}
                      className={`input-number w-7 h-6 text-center text-mainblue font-semibold focus:outline-none focus:ring-2 focus:ring-mainblue`}
                      value={retireData?.strategy_control_rate_outcome}
                      onChange={onChangeControlRate}
                      placeholder="0"
                      inputMode="decimal"
                      pattern="[0-9],*"
                    />
                    <div className=" text-xs text-bluegray50">%</div>
                  </div>
                  <div className=" md:px-28 ">
                    <SliderSingle
                      placeholder="0"
                      valuemax={retireData?.investment_af_retirement_max}
                      value={retireData?.strategy_control_rate_outcome}
                      onChange={onChangeControlRate}
                      onChnageMax={onChangeControlRateMax}
                    />
                  </div>
                </div>
              }
            />
          </div>

          <CheckBoxMain
            retireData={retireData}
            index={8}
            label="ปรับเวลาเกษียณ"
            icon={"../images/stg_4.png"}
            info
            // open={openInfo === 8}
            open={openRetire}
            setOpen={setOpenRetire}
            field={"strategy_age_retire"}
            setRetireData={setRetireData}
            onClickInfo={() => handleOpen(8)}
            panel={
              <div
                className={classNames(
                  value
                    ? "px-4 pt-2 font-IBMPlexSansThai"
                    : " px-4 font-IBMPlexSansThai"
                )}
              >
                <div>
                  อายุเกษียณเดิม
                  <span className=" ml-2 font-semibold text-maincyan">
                    {retireData?.age_retire} ปี
                  </span>
                </div>
                {/* {error?.check_age_retire && (
                  <ErrorMain errortext=" กรุณากรอกเลขอายุเกษียณใหม่มากกว่าอายุเกษียณปัจจุบัน" />
                )} */}
                <div className=" px-[7px] mt-3 mb-6 ">
                  <div className=" grid grid-cols-3 justify-center items-center">
                    <div className=" mx-auto flex flex-col justify-center items-center gap-1">
                      <img
                        src="/images/present_age2.png"
                        className=" mx-auto w-[50px] h-[50px]"
                        alt="present_age"
                      />
                      <div className="mt-2 text-sm font-semibold text-maincyan">
                        อายุปัจจุบัน
                      </div>
                      <div className={STYLE_TEXT_BLUE}>
                        {retireData.age_current}
                      </div>
                    </div>
                    <div className=" mx-auto flex flex-col justify-center items-center gap-1">
                      <img
                        src="/images/old_age2.png"
                        className=" mx-auto w-[50px] h-[50px]"
                        alt="old_age"
                      />
                      <div className="mt-2 text-sm font-semibold text-[#B95940]">
                        อายุเกษียณ
                      </div>

                      <input
                        type="number"
                        className={STYLE_INPUT_SLIDER}
                        value={retireData.strategy_age_retire}
                        onChange={onChangeSTGRetireAge}
                        inputMode="decimal"
                        pattern="[0-9],*"
                        placeholder="0"
                        // onChange={onChangeRetireAge}
                      />
                    </div>
                    <div className=" mx-auto flex flex-col justify-center items-center gap-1">
                      <img
                        src="/images/dead2.png"
                        className=" mx-auto w-[50px] h-[50px]"
                        alt="dead_age"
                      />
                      <div className="mt-2 text-sm font-semibold text-mainorange">
                        สิ้นอายุขัย
                      </div>

                      <div className={STYLE_TEXT_BLUE}>
                        {retireData?.strategy_age_dead
                          ? retireData?.strategy_age_dead
                          : retireData?.age_dead}
                      </div>
                    </div>
                  </div>
                  <div className=" mt-4 w-full">
                    <MiltiSlider
                      onChange={onChangeAgeSTG}
                      current={retireData?.age_current}
                      retire={
                        retireData?.strategy_age_retire
                          ? retireData?.strategy_age_retire
                          : retireData?.age_retire
                      }
                      dead={
                        retireData?.strategy_age_dead
                          ? retireData?.strategy_age_dead
                          : retireData?.age_dead
                      }
                      maxdead={120}
                    />
                  </div>
                </div>

                {/* <div className={STYLE_PARENT_STG}>
                  <Input
                    onWheelCapture={(e) => {
                      e.target.blur();
                    }}
                    // placeholder="0"
                    variant="standard"
                    label="ใส่อายุเกษียณใหม่ ที่นี่"
                    className={STYLE_INPUT_STG}
                    value={retireData?.strategy_age_retire}
                    onChange={onChangeSTGRetireAge}
                    icon={`ปี`}
                  />

                 <NumbericValidateRed
                    // type="number"
                    maxLength={11}
                    className={STYLE_INPUT_STG}
                    value={retireData?.strategy_age_retire}
                    placeholder="ใส่อายุเกษียณใหม่ ที่นี่"
                    onChange={onChangeSTGRetireAge}
                    thousandSeparator=","
                    inputMode="decimal"
                    pattern="[0-9],*"
                    // className={
                    //   error?.saving_money_month ? STYLE_INPUT_ERROR : STYLE_INPUT
                    // }
                  />
                  <div className={STYLE_BATH_STG}>ปี</div>
                </div> */}
              </div>
            }
          />

          <CheckBoxMain
            retireData={retireData}
            index={9}
            label="พิจารณาอายุขัย"
            icon={"../images/stg_5.png"}
            info
            // open={openInfo === 9}
            open={openDead}
            setOpen={setOpenDead}
            field={"strategy_age_dead"}
            setRetireData={setRetireData}
            onClickInfo={() => handleOpen(9)}
            panel={
              <div
                className={classNames(
                  value
                    ? "px-4 mt-2 font-IBMPlexSansThai"
                    : " px-4 font-IBMPlexSansThai"
                )}
              >
                <div>
                  อายุขัยเดิม
                  <span className=" ml-2 font-semibold text-maincyan">
                    {retireData?.age_dead} ปี
                  </span>
                </div>
                <div className=" px-[7px] mt-3 mb-6 ">
                  {error?.check_age_dead && (
                    <ErrorMain errortext=" กรุณากรอกเลขอายุขัยใหม่ให้มากกว่าอายุเกษียณ" />
                  )}
                  <div className=" mt-2 grid grid-cols-3 justify-center items-center">
                    <div className=" mx-auto flex flex-col justify-center items-center gap-1">
                      <img
                        src="/images/present_age2.png"
                        className=" mx-auto w-[50px] h-[50px]"
                        alt="present_age"
                      />
                      <div className="mt-2 text-sm font-semibold text-maincyan">
                        อายุปัจจุบัน
                      </div>

                      <div className={STYLE_TEXT_BLUE}>
                        {retireData.age_current}
                      </div>
                    </div>
                    <div className=" mx-auto flex flex-col justify-center items-center gap-1">
                      <img
                        src="/images/old_age2.png"
                        className=" mx-auto w-[50px] h-[50px]"
                        alt="old_age"
                      />
                      <div className="mt-2 text-sm font-semibold text-[#B95940]">
                        อายุเกษียณ
                      </div>

                      <div className={STYLE_TEXT_BLUE}>
                        {retireData?.strategy_age_retire
                          ? retireData.strategy_age_retire
                          : retireData?.age_retire}
                      </div>
                    </div>
                    <div className=" mx-auto flex flex-col justify-center items-center gap-1">
                      <img
                        src="/images/dead2.png"
                        className=" mx-auto w-[50px] h-[50px]"
                        alt="dead_age"
                      />
                      <div className="mt-2 text-sm font-semibold text-mainorange">
                        สิ้นอายุขัย
                      </div>

                      <input
                        type="number"
                        className={STYLE_INPUT_SLIDER}
                        value={retireData?.strategy_age_dead}
                        onChange={onChangeSTGDeadAge}
                        inputMode="decimal"
                        pattern="[0-9],*"
                        placeholder="0"
                      />
                    </div>
                  </div>
                  <div className=" mt-4 w-full">
                    <MiltiSlider
                      onChange={onChangeAgeSTGDDead}
                      current={retireData?.age_current}
                      retire={
                        retireData?.strategy_age_retire
                          ? retireData?.strategy_age_retire
                          : retireData?.age_retire
                      }
                      dead={
                        retireData?.strategy_age_dead
                          ? retireData?.strategy_age_dead
                          : retireData?.age_dead
                      }
                      maxdead={120}
                    />
                  </div>
                </div>
              </div>
            }
          />

          <div className=" flex flex-col justify-center items-center pt-5 ">
            <ImageMain src="/images/youtube_stg.png" className=" w-24 h-fit" />
            <div
              onClick={() => {
                window.open(
                  "https://www.youtube.com/watch?v=IYo7WVTvnoI",
                  "_blank"
                );
              }}
              className=" text-maincyan underline underline-offset-2 mt-1 font-semibold "
            >
              ศึกษาเพิ่มเติม
            </div>
          </div>

          <div className=" flex justify-center pt-5 pb-10">
            <MTButton
              // disabled={!isValidate()}
              onClick={() => {
                const result = formatSTG(retireData, true);
                const sumResult = _?.sumBy(result, "amount");
                const newResults = mapingSTG(result, {
                  openOutcome,
                  openPassive,
                  openIncome,
                  openAssetGen,
                  openSalary,
                  openInvestment,
                  openControlRate,
                  openRetire,
                  openDead,
                });

                if (sumResult === 0 && newResults?.length === 0) {
                  setTabPlan(1);
                } else {
                  setTabPlan(2);
                }

                const formerror = isValidate(retireData?.strategy_age_retire);
                const isformerror = Object?.values(formerror).some(
                  (item) => item === true
                );
                setError(formerror);
                if (isformerror) {
                  return;
                } else setRetireData(retireData);

                setStrategy(newResults);
                setRetireData({
                  ...retireData,
                  strategy_sum: sumResult,
                });

                setOpenStrategy(false);
              }}
              text="ปรับกลยุทธ์"
              color="bg-maincyan "
              colortext="text-white font-medium"
            />
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default StrategyMain;
