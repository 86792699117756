import classNames from "classnames";
import {
  ICON_DELETE,
  ICON_LOADING,
  ICON_MINUS_CIRCLE,
  ICON_PLUS,
} from "../../utils/useIcons";
import { Button } from "@material-tailwind/react";
import { STYLE_BUTTON_DELETE, STYLE_INPUT_INSET } from "../../utils/useStyle";
import { useState } from "react";

export const MTButton = ({
  onClick,
  loading,
  text = "",
  iconleft = null,
  iconright = null,
  icon,
  colortext,
  color = "bg-maincyan",
  hovercolor = "hover:bg-secondcyan",
  variant = "filled",
  width = " md:w-[200px] w-[135px]",
  onChange,
  colorFocus,
  value,
  disabled,
  textsize = " md:text-md text-sm",
  type,
  flexnone = "",
  rounded = "rounded-full",
  border = "",
  height = "md:h-10 h-9",
  ...rest
}) => {
  return (
    <>
      <Button
        {...rest}
        type={type}
        value={value}
        onChange={onChange}
        onClick={onClick}
        disabled={loading || disabled}
        size="lg"
        color="white"
        variant={variant}
        className={`flex justify-center items-center ${border} ${rounded} ${color} ${hovercolor} text-white font-IBMPlexSansThai ${textsize}  py-2.5 font-medium  ${height}  shadow-none focus:shadow-none ${width} `}
      >
        {iconleft && <span className={`${colortext} mr-1 `}> {icon} </span>}
        <span
          className={`relative flex justify-center items-center ${colortext} leading-[17px] ${flexnone}`}
        >
          {loading ? (
            <ICON_LOADING
              className={classNames(
                loading ? ` w-7 h-7 ${colortext} ` : `hidden`,
                `mr-2  `
              )}
            />
          ) : (
            ""
          )}
          {text}
        </span>
        {iconright && <span className={`${colortext} ml-1 `}> {icon} </span>}
      </Button>
    </>
  );
};

export const ButtonCounter = ({
  onChange,
  number,
  handleNext,
  handlePrev,
  value,
  bg = "bg-gray50 ",
  isMinus = false,
}) => {
  // const [value, setValue] = useState(0);

  return (
    <div className=" flex gap-[2px] items-center ">
      {isMinus && (
        <ICON_MINUS_CIRCLE
          onClick={handlePrev}
          className={`w-5 h-5 flex-none text-maincyan cursor-pointer`}
        />
      )}
      <input
        disabled={true}
        className={`text-mainblue text-md pt-0.5 ${bg} rounded-md w-6 h-6  text-center font-medium`}
        value={value}
        onChange={onChange}
      />
      <ICON_PLUS
        onClick={handleNext}
        className={`w-5 h-5 flex-none text-maincyan cursor-pointer`}
      />
    </div>
  );
};

export const DeleteButtonCard = ({ onClick }) => {
  return (
    <button
      style={{
        transition: "all .15s ease",
      }}
      onClick={onClick}
      className={STYLE_BUTTON_DELETE}
    >
      <ICON_DELETE className=" w-4 text-maincyan mr-1" />
      ลบ
    </button>
    // <ICON_DELETE className=" w-5 text-maincyan mr-1" onClick={onClick} />
  );
};
