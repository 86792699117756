import jsPDF from "jspdf";
import { MTButton } from "../../components/button/MTButton";
import Container from "../../components/layout/Container";
import React, { useContext, useEffect, useMemo, useState } from "react";
import html2canvas from "html2canvas";
import { cn } from "../../utils/useFunctions";
import fontSarabunTH from "../../pages/pdf/font/fontSarabunTH";
import PDFRender from "../pdf/pdf.render";
import { PdfContext } from "../pdf/context/pdf.ctx";
import { HeaderTitle } from "../../components/header/Header";
import { useNavigate } from "react-router-dom";
import { NavigatePath } from "../../functions/Navigate.Goback";
import { ImageMain } from "../../components/img/Img.Main";
import { CardMenuReport } from "./components/cardMenu";
import { ICON_DL_PDF, ICON_LOADING } from "../../utils/useIcons";
import { OPTION_MENU_REPORT } from "./constrant/constrant.main";
import { PopupMain } from "../../components/popup/PopupMain";
import { DateTime } from "luxon";
import { uploadData } from "aws-amplify/storage";
import { useUserStore } from "../../_store/userStore";
import api from "../../api/api.main";
import numeral from "numeral";

function ReportMain({ date = "DD/MM/YYYY เวลา 12:00 น." }) {
  const navigate = useNavigate();

  const [isSubmit, setIsSubmit] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const [loadingPDF, setLoadingPDF] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const userData = useUserStore((state) => state.userData);

  const [progress,setProgress] = useState(0)
  const [allPages,setAllPages] = useState(0)

  const pdfCtx = useContext(PdfContext);

  const doc = useMemo(() => {
    return new jsPDF("p", "px", "a4");
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant", // for smooth scrolling
    });
    setTimeout(() => {
      setLoadingPage(false);
    }, 500);
    const input = document.getElementById("pdf-content");
    const pages = input.querySelectorAll(".page");

    setAllPages(pages.length+2)
  }, []);

  useEffect(() => {
    if (isSubmit) {
      doc.save("รายงานสรุป.pdf");
      setLoadingPDF(false);
    }
  }, [isSubmit]);

  const onClickDownload = async () => {
    try {
      setIsSubmit(false);
      setLoadingPDF(true);
      pdfCtx.setPdfDate(new Date());

      const input = document.getElementById("pdf-content");

      doc.addFileToVFS("THSarabun.ttf", fontSarabunTH);
      doc.addFont("THSarabun.ttf", "THSarabun", "normal");
      doc.setFont("THSarabun");

      const pages = input.querySelectorAll(".page");

      for (let i = 0; i < pages.length; i++) {
        const page = pages[i];
        const canvas = await html2canvas(pages[i], {
          scale: 1.5,
          useCORS: true,
        });
        const image = canvas.toDataURL("image/png");
        const isHorizontal = page.classList.contains("page-horizontal");

        if (i !== 0) {
          // doc.addPage(null, "p");
          if (isHorizontal) {
            doc.addPage(null, "l");
          } else {
            doc.addPage(null, "p");
          }
        }

        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();

        const widthRatio = pageWidth / canvas.width;
        const heightRatio = pageHeight / canvas.height;
        const ratio = Math.min(widthRatio, heightRatio);

        const canvasWidth = canvas.width * ratio;
        const canvasHeight = canvas.height * ratio;

        const marginX = (pageWidth - canvasWidth) / 2;
        const marginY = (pageHeight - canvasHeight) / 2;
        doc.addImage(image, "PNG", marginX, marginY, canvasWidth, canvasHeight);
        setProgress((prev)=> prev+1)
      }

      const pdfBlob = doc.output("blob");
      await uploadData({
        key: `${userData?._id}.pdf`,
        data: pdfBlob,
      }).result;
      setProgress((prev)=> prev+1)

      await api.put(`/profiles/${userData._id}`, {
        is_export: true,
        export_date: DateTime.now().setZone("Asia/Bangkok").toISO(),
      });
      setProgress((prev)=> prev+1)

      setIsSubmit(true);

    } catch (error) {
      console.log("error", error);
    }
  };

  const getDate = () => {
    if (pdfCtx?.pdfDate) {
      const pdfJsDate = new Date(pdfCtx?.pdfDate);
      const fullDate = DateTime.fromJSDate(pdfJsDate).toFormat("dd/MM/yyyy");
      const time = DateTime.fromJSDate(pdfJsDate).toFormat("HH:mm");
      return `${fullDate} เวลา ${time} น.`;
    }
    return date;
  };

  return (
    <Container className={"overflow-auto bg-white"}>
      {!loadingPage ? (
        <div className=" pt-[14px]">
          <HeaderTitle
            title="รายงาน"
            isGoback
            onClickGoBack={() => {
              NavigatePath(navigate, "/");
            }}
          />
          <div className="text-maincyan font-medium text-center text-xl mt-5">
            รายงานผลการเงินส่วนบุคคล
            {/* <div> ณ วันที่ {getDate()} </div> */}
          </div>
          <div className=" px-[25px] mt-8">
            {OPTION_MENU_REPORT?.map((item, index) => (
              <div className="">
                <CardMenuReport
                  key={index}
                  icon={item.icon}
                  title={item.title}
                  onClick={() => {
                    navigate(item?.path);
                  }}
                />
              </div>
            ))}
          </div>
          <div className="mt-10 flex justify-center mb-[33px]">
            <MTButton
              flexnone="-ml-4"
              loading={loadingPDF}
              onClick={onClickDownload}
              text="ดาวน์โหลด"
              color=" bg-white border border-maincyan hover:bg-maincyan hover:text-white group "
              colortext="text-maincyan font-medium group-hover:text-white "
              iconleft
              icon={
                <ICON_DL_PDF className="w-4 h-4 text-maincyan group-hover:text-white ml-4" />
              }
            />
          </div>
          <PopupMain
            img={false}
            open={loadingPDF}
            isHasButton={false}
            text={
              <div>
                <ICON_LOADING className=" w-14 h-14 mx-auto text-maincyan" />
                {(numeral((progress/allPages)*100).format("0.00"))}/100 %
                <div className=" mt-4 font-semibold text-mainblue flex flex-col items-center justify-center">
                  {" "}
                  <div>กำลังดาวน์โหลดรายงาน</div>
                  <div>กรุณาอยู่หน้านี้จนกว่าจะดาวน์โหลดเสร็จสิ้น</div>
                </div>
              </div>
            }
          />
        </div>
      ) : null}
      <PDFRender />
    </Container>
  );
}

export default ReportMain;
