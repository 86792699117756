export const OPTION_MENU_REPORT = [
  {
    title: "ภาพรวมเป้าหมายทางการเงินของคุณ ​",
    icon: "/images/coin-report.png",
    path: "/report/overview",
  },
  {
    title: "สมมติฐานรายงาน",
    icon: "/images/diagram-report.png",
    path: "/report/hypothesis",
  },
  {
    title: "รายได้",
    icon: "/images/money-report.png",
    path: "/report/income",
  },
  {
    title: "รายจ่าย",
    icon: "/images/expense-report.png",
    path: "/report/expense",
  },
  {
    title: "สินทรัพย์",
    icon: "/images/asset-report.png",
    path: "/report/asset",
  },
  {
    title: "หนี้สิน",
    icon: "/images/debt-report.png",
    path: "/report/debt",
  },
  {
    title: "งบดุล",
    icon: "/images/balance-report.png",
    path: "/report/balance",
  },
  {
    title: "อัตราส่วนทางการเงิน ",
    icon: "/images/financial-analysis-report.png",
    path: "/report/financial",
  },
  {
    title: "แผนบริหารและจัดการความเสี่ยง",
    icon: "/images/risk-report.png",
    path: "/report/riskplan",
  },

  {
    title: "แผนทุนประกันคุ้มครองครอบครัว ",
    icon: "/images/family-report.png",
    path: "/report/familyInsurance",
  },
  {
    title: "แผนทุนประกันทุพพลภาพ",
    icon: "/images/personal-report.png",
    path: "/report/disableInsurance",
  },
  {
    title: "แผนเกษียณ ",
    icon: "/images/grandparents-report.png",
    path: "/report/retirement",
  },
  {
    title: "แผนภาษี",
    icon: "/images/tax-report.png",
    path: "/report/tax",
  },

  {
    title: "สัดส่วนการลงทุน ",
    icon: "/images/revenue-report.png",
    path: "/report/investment",
  },

  {
    title: "แผนการศึกษาบุตร ",
    icon: "/images/graduate-report.png",
    path: "/report/education",
  },
  {
    title: "แผนการออม",
    icon: "/images/savings-report.png",
    path: "/report/savingplan",
  },

  {
    title: "แผนอนาคต",
    icon: "/images/banknote-report.png",
    path: "/report/futureplan",
  },
  {
    title: "วางแผนหนี้สิน",
    icon: "/images/wealth-report.png",
    path: "/report/debtplan",
  },
  {
    title: "แผนมรดก",
    icon: "/images/stakeholders-report.png",
    path: "/report/heritage",
  },
  {
    title: "รวมประกันของคุณ",
    icon: "/images/insurance-all-report.png",
    path: "/report/allInsurance",
  },
];
