import React, { useMemo } from "react";
import { useFuturePlanStore } from "../../../_store/futurePlanStore";
import numeral from "numeral";

const TABLE_HEADER_STYLE =
  " px-[16px] pt-[14px] pb-[6px] break-keep whitespace-nowrap text-[#18488A] text-base font-semibold font-IBMPlexSansThai";

const FutureResultTable = () => {
  const futurePlanData = useFuturePlanStore((state) => state.futurePlanData);

  const merge_keys = useMemo(() => {
    let income_keys = futurePlanData.cash_flow_keys.filter((cash_flow_key) => {
      return cash_flow_key.includes("income");
    });
    let expenese_keys = futurePlanData.cash_flow_keys.filter(
      (cash_flow_key) => {
        return cash_flow_key.includes("expenese");
      }
    );
    let merge_income_keys = [
      ...income_keys,
      "income_peryear",
      "remain_accumulate",
      "pension_peryear",
    ];
    let merge_expenese_keys = [
      ...expenese_keys,
      "outcome_peryear",
      "out_come_stable",
    ];
    // let merge_keys = [...merge_income_keys, ...merge_expenese_keys];
    let merge_keys = [
      "income_peryear",
      "outcome_total",
      "remain",
      "income_investment",
      "remain_accumulate",
      "outcome_peryear",
      "out_come_stable",
      "debt_short_list",
      "debt_creditCard"
    ];

    return merge_keys;
  }, [futurePlanData]);

  const merge_label_key = {
    expenese_home_items: "บ้าน",
    expenese_car_items: "รถ",
    expenese_children_items: "ลูก",
    expenese_travel_items: "ท่องเที่ยว",
    expenese_collection_items: "ของสะสม",
    expenese_other_items: "รายจ่ายอื่นๆ",
    income_legacy_items: "รายได้จากมรดก",
    income_business_items: "รายได้จากธุรกิจ",
    income_property_items: "รายได้จากอสังหาริม",
    income_other_items: "รายได้อื่นๆ",
    income_peryear: "รายได้",
    remain_accumulate: "สินทรัพย์ลงทุน",
    outcome_peryear: "จ่ายผันแปร",
    out_come_stable: "จ่ายคงที่ (ไม่รวมหนี้)",
    outcome_total: "รวมรายจ่ายต่อปี ",
    pension_peryear: "บำนาญต่อปี",
    remain: "เงินคงเหลือ",
    income_investment: "ผลตอบแทนการลงทุน ",
  };

  return (
    <div className="w-fit max-w-full ">
      <div className=" flex w-fit max-w-full min-h-[300px] rounded-[20px] overflow-hidden ">
        <div className="flex flex-col">
          <div className={TABLE_HEADER_STYLE + " bg-[#F4F2F3] "}> อายุ </div>
          {futurePlanData?.cash_flow_items.map((item, index) => {
            return (
              <div
                key={index}
                className={
                  TABLE_HEADER_STYLE +
                  " text-center h-[44px] bg-[#16AA37]/[0.2] border-b-[1px] border-b-[#16AA37] "
                }
              >
                {item.age}
              </div>
            );
          })}
        </div>
        <div className="flex overflow-x-scroll ">
          {merge_keys.length > 0 &&
            merge_keys.map((item, index) => {
              // console.log("merge_keys item",item);

              if (['debt_short_list','debt_creditCard'].includes(item)) {

                futurePlanData?.cash_flow_items[0]?.[item]?.allPeriods.map(
                  (items, index) => {
                    return items?.name;
                  }
                );
                return (
                  <>
                    {futurePlanData?.cash_flow_items[0]?.[item]?.allPeriods.map(
                      (item, index) => {
                        // console.log("item", item?.pay_periods);
                        return (
                          <div key={index} className="flex flex-col">
                            <div
                              className={
                                TABLE_HEADER_STYLE +
                                " text-center h-[44px] bg-[#F4F2F3] "
                              }
                            >
                              {item.name}
                              
                            </div>
                            {item?.pay_periods.map((item2, index2) => {
                              // console.log("index2",index2)
                                return (
                                  <div
                                    key={index2}
                                    className={
                                      TABLE_HEADER_STYLE +
                                      " text-center h-[44px] bg-white border-[#F4F2F3] border-b-[1px] "
                                    }
                                  >
                                    {numeral(item2)?.format("0,0.00")}
                                  </div>
                                );
                              })}
                          </div>
                        );
                      }
                    )}
                  </>
                );
              } else {
                return (
                  <div key={index} className="flex flex-col">
                    <div
                      className={
                        TABLE_HEADER_STYLE +
                        " text-center h-[44px] bg-[#F4F2F3] "
                      }
                    >
                      {merge_label_key[item]}
                    </div>
                    {futurePlanData?.cash_flow_items.map(
                      (cash_flow_items, index2) => {
                        return (
                          <div
                            key={index2}
                            className={
                              TABLE_HEADER_STYLE +
                              " text-center h-[44px] bg-white border-[#F4F2F3] border-b-[1px] "
                            }
                          >
                            {item === "income_peryear"
                              ? cash_flow_items?.age >
                                futurePlanData?.age_retire
                                ? numeral(
                                    cash_flow_items?.pension_peryear
                                  )?.format("0,0.00")
                                : numeral(
                                    cash_flow_items?.income_peryear
                                  )?.format("0,0.00")
                              : numeral(cash_flow_items?.[item])?.format(
                                  "0,0.00"
                                )}
                          </div>
                        );
                      }
                    )}
                  </div>
                );
              }
            })}
        </div>
      </div>
    </div>
  );
};

export default FutureResultTable;
