import React, { useEffect, useMemo, useState } from "react";
import { useBalanceStore } from "../../../_store/balanceStore";
import { calTotalCreditorFloatInterest, cn } from "../../../utils/useFunctions";
import {
  PdfBalanceHeaderItemCard,
  PdfBalanceItemCard,
  PdfCard,
  PdfCardGray,
  PdfCardWhite,
} from "../component/pdf.c.card";
import PdfHeader from "../component/pdf.c.header";
import {
  PdfHeaderCardText,
  PdfHeaderText,
} from "../component/pdf.c.headerText";
import PdfLine from "../component/pdf.c.line";
import {
  STYLE_PAGE,
  STYLE_PDF_ITEM_TOTAL,
  STYLE_PDF_TEXT_MEDIUM_11,
  STYLE_PDF_TEXT_MEDIUM_12,
  STYLE_PDF_TEXT_MEDIUM_13,
  STYLE_PDF_TEXT_MEDIUM_BLUE_11,
  STYLE_PDF_TEXT_MEDIUM_BLUE_12,
  STYLE_PDF_TEXT_MEDIUM_BLUE_13,
  STYLE_PDF_TEXT_MEDIUM_GRAY_11,
  STYLE_PDF_TEXT_NORMAL_12,
  STYLE_PDF_TEXT_NORMAL_BLUE_11,
  STYLE_PDF_TEXT_NORMAL_BLUE_12,
  STYLE_PDF_TEXT_NORMAL_BLUE_13,
  STYLE_PDF_TEXT_NORMAL_GRAY_11,
} from "../styles/pdf.style";
import {
  OPTIONS_TYPE_CAR,
  OPTIONS_TYPE_PLACE,
  options_property_asset,
  options_property_intangible,
  options_property_invest,
  options_property_private,
} from "../../balance/functions";
import _ from "lodash";
import numeral from "numeral";
import {
  calPropertyLocalOption,
  getAllBalanceTotal,
  getBalanceItems,
} from "../lib/pdf.lib";
import PdfFooter from "../component/pdf.c.footer";
import {
  formatPeriodTime,
  mapDebtStatus,
} from "../../debt/component/VIDebtTable";
import { useDebtStore } from "../../../_store/debtplanStore";
import { BarChartGroupDebt } from "../../debt/styles/ChartDebt";

function PdfPageDebtThree({ page }) {
  const balanceData = useBalanceStore((state) => state.balanceData);
  const debtData = useDebtStore((state) => state.debtData);
  const [isHasCollateral, setIsHasCollateral] = useState(false);
  const [mostCar, setMostCar] = useState(null);

  // รวมยอดเงินต้นค้างชำระ
  const sumStartUnpaidAmount = () => {
    // บัตรเครดิต
    const sumCreditCard = () => {
      let result = 0;
      balanceData?.debt_short_list?.map((item) => {
        item?.credit_cards?.map((row) => {
          result += row?.unpaid_amount ? row?.unpaid_amount : 0;
        });
      });
      return result;
    };
    // เงินกู้ระยะสั้น
    const sumShortTerm = () => {
      let result = 0;
      balanceData?.debt_short_list?.map((item) => {
        item?.short_terms?.map((row) => {
          result += row?.unpaid_amount ? row?.unpaid_amount : 0;
        });
      });
      return result;
    };
    // เงินกู้นอกระบบ
    const sumInformalShortTerm = () => {
      let result = 0;
      balanceData?.debt_short_list?.map((item) => {
        item?.informal_short_terms?.map((row) => {
          result += row?.unpaid_amount ? row?.unpaid_amount : 0;
        });
      });
      return result;
    };
    // เงินกู้ระยะสั้นอื่นๆ
    const sumShortTermOther = () => {
      let result = 0;
      balanceData?.debt_short_list?.map((item) => {
        item?.short_term_others?.map((row) => {
          result += row?.unpaid_amount ? row?.unpaid_amount : 0;
        });
      });
      return result;
    };

    const finalResult =
      sumCreditCard() +
      sumShortTerm() +
      sumInformalShortTerm() +
      sumShortTermOther();

    return finalResult;
  };

  useEffect(() => {
    // if (firstRender) return;

    // ดูว่ามีรถคันไหนที่ผ่านเงื่อนไข
    // 1.มูลค่ารถยนต์ปัจจุบัน > หนี้รถยนต์ โดยที่ ต้องมากกว่า 100,000 บาทขึ้นไป
    // 2.ต้องมีหนี้ระยะสั้น
    // 3.ส่วนต่างข้องมูลค่ารถยนต์ปัจจุบัน - หนี้รถยนต์ ต้องมากกว่าจำนวนหนี้ระยะสั้น
    if (balanceData) {
      //---- เช็คว่ามีหนี้รพยะสั้นไหม ----//
      if (balanceData?.sum_debt_short > 0) {
        let carItems = [];

        for (const debt_long_item of balanceData?.debt_long_list) {
          if (debt_long_item?.options_debt_long === "debt_car") {
            carItems = [...carItems, ...debt_long_item?.debt_long_cars];
          }
        }

        //---- หารถที่มูลค่าปัจจุบันเยอะที่สุด ----//
        const mostCar = () => {
          let latestItemMax = { current_price: 0, unpaid_amount: 0 };
          for (const carItem of carItems) {
            if (carItem.current_price > latestItemMax.current_price) {
              latestItemMax = carItem;
            }
          }
          return latestItemMax;
        };

        //---- ส่วนต่างข้องมูลค่ารถยนต์ปัจจุบัน - หนี้รถยนต์ ----//
        const differenceCurrentPriceAndDebtCar =
          mostCar().current_price - mostCar().unpaid_amount;

        //========== เช็คว่ามีรถที่สามารถขายเพื่อล้างหนี้ได้ไหม ==========//
        if (
          differenceCurrentPriceAndDebtCar > 100000 &&
          differenceCurrentPriceAndDebtCar > sumStartUnpaidAmount()
        ) {
          setIsHasCollateral(true);
          setMostCar(mostCar());
        } else {
          setIsHasCollateral(false);
          setMostCar(null);
        }
      }
    }
  }, [balanceData]);

  const debtShortItems = useMemo(() => {
    const newDebtShortItems = [];

    if (balanceData?.debt_short_list?.length > 0) {
      for (const debt_short_item of balanceData.debt_short_list) {
        const keys = Object.keys(debt_short_item);
        for (const key of keys) {
          if (_.isArray(debt_short_item[key])) {
            for (const item of debt_short_item[key]) {
              let createItem = {
                start_unpaid_amount: item?.start_unpaid_amount,
                unpaid_amount: item?.unpaid_amount,
                pay_permonth: item?.pay_permonth,
                interest: item?.interest,
                period: item?.period,
                period_time: "",
                type_payment: "",
                label: item?.creditor_name,
              };

              // เครดิตการ์ด
              if (debt_short_item?.options_debt_short === "debt_creditcard") {
                createItem.period = !item?.type_payment
                  ? ""
                  : Math.ceil(item?.unpaid_amount / item?.pay_permonth);
                createItem.period_time = !item?.type_payment
                  ? ""
                  : formatPeriodTime(
                      Math.ceil(item?.unpaid_amount / item?.pay_permonth)
                    );
                createItem.type_payment = !item?.type_payment
                  ? ""
                  : item?.type_payment === "pay_minimum"
                  ? "จ่ายขั้นต่ำ"
                  : item?.type_payment === "pay_full"
                  ? "จ่ายเต็มจำนวน"
                  : "จ่ายแบบระบุจำนวน";
              }

              // เงินกู้ระยะสั้น, หนี้นอกระบบ, เงินกู้ระยะสั้นอื่นๆ
              if (
                [
                  "debt_shortterm",
                  "debt_informal_shortterm",
                  "debt_shortterm_other",
                ].includes(debt_short_item?.options_debt_short)
              ) {
                createItem.period_time = formatPeriodTime(item?.period);
                createItem.type_payment = mapDebtStatus?.[item?.status];
              }

              newDebtShortItems.push(createItem);
            }
          }
        }
      }
    }

    return newDebtShortItems;
  }, [balanceData]);

  const debtLongItems = useMemo(() => {
    const newDebtLongItems = [];

    if (balanceData?.debt_long_list?.length > 0) {
      for (const debt_long_item of balanceData?.debt_long_list) {
        const keys = Object.keys(debt_long_item);
        for (const key of keys) {
          if (_.isArray(debt_long_item[key])) {
            for (const item of debt_long_item[key]) {
              let createItem = {
                start_unpaid_amount: item?.start_unpaid_amount,
                unpaid_amount: item?.unpaid_amount,
                pay_permonth: item?.pay_permonth,
                interest: item?.interest,
                period: item?.period,
                period_time: formatPeriodTime(item?.period),
                type_payment: "",
                label: item.title,
              };

              // รถยนต์
              if (debt_long_item?.options_debt_long === "debt_car") {
                const findTypeCar = OPTIONS_TYPE_CAR?.filter((thisType) => {
                  if (thisType.value === item?.type_car) return thisType;
                });
                if (findTypeCar) {
                  createItem.label = `${findTypeCar[0]?.label} ${item?.title_car}`;
                }
              }

              // สินเชื่อที่อยู่อาศัย
              if (debt_long_item?.options_debt_long === "debt_place") {
                const findTypePlace = OPTIONS_TYPE_PLACE?.filter((thisType) => {
                  if (thisType.value === item?.type_place) return thisType;
                });
                const typePlace = item?.type_place_other
                  ? item?.type_place_other
                  : findTypePlace?.[0]?.label;
                if (typePlace) {
                  createItem.label = `${typePlace} (${item?.address_place})`;
                }
              }

              newDebtLongItems.push(createItem);
            }
          }
        }
      }
    }

    return newDebtLongItems;
  }, [balanceData]);

  const mergeDebtItems = useMemo(() => {
    const newMergeDebtItems = [
      {
        label: "หนี้ระยะสั้น",
        items: debtShortItems,
      },
      {
        label: "หนี้ระยะยาว",
        items: debtLongItems,
      },
    ];
    return newMergeDebtItems;
  }, [balanceData]);

  const totalItem = useMemo(() => {
    let total = 0;
    for (const item of [...debtShortItems, ...debtLongItems]) {
      total += item.start_unpaid_amount;
    }
    return total;
  }, [balanceData]);

  const totalShortItem = useMemo(() => {
    let total = 0;
    for (const item of debtShortItems) {
      total += item.start_unpaid_amount;
    }
    return total;
  }, [balanceData]);

  const sumTotalWeightInterest = () => {
    let total_unpaid = 0;
    let total_left = 0;
    let total_final = 0;

    if (!balanceData?.debt_short_list) return total_final;

    for (const debt_short_item of balanceData?.debt_short_list) {
      const keys = Object.keys(debt_short_item);
      for (const key of keys) {
        if (_.isArray(debt_short_item[key])) {
          for (const item of debt_short_item[key]) {
            if (
              debt_short_item?.options_debt_short === "debt_informal_shortterm"
            ) {
              total_unpaid += item.start_amount;
              total_left += item.start_amount * item.interest;
            } else if (
              debt_short_item?.options_debt_short === "debt_shortterm_other"
            ) {
              total_unpaid += item.unpaid_amount;
              total_left += item.unpaid_amount * item.interest * 12;
            } else {
              total_unpaid += item.unpaid_amount;
              total_left += item.unpaid_amount * item.interest;
            }
          }
        }
      }
    }
    total_final = total_left / total_unpaid;
    return total_final;
  };

  const sumTotalInterest = () => {
    let total_unpaid = 0;
    let total_left = 0;

    if (!balanceData?.debt_short_list) return total_left;

    for (const debt_short_item of balanceData?.debt_short_list) {
      const keys = Object.keys(debt_short_item);
      for (const key of keys) {
        if (_.isArray(debt_short_item[key])) {
          for (const item of debt_short_item[key]) {
            if (
              debt_short_item?.options_debt_short === "debt_informal_shortterm"
            ) {
              total_unpaid += item.start_amount;
              total_left += item.start_amount * (item.interest / 100);
            } else if (
              debt_short_item?.options_debt_short === "debt_shortterm_other"
            ) {
              const creditor = calTotalCreditorFloatInterest(item);
              const totalCreditorInterest = creditor.reduce(
                (acc, item, index) => {
                  if (index > 11) {
                    return acc;
                  }
                  return acc + item.interest;
                },
                0
              );
              total_unpaid += item.unpaid_amount;
              total_left += totalCreditorInterest;
            } else {
              total_unpaid += item.unpaid_amount;
              total_left += item.unpaid_amount * (item.interest / 100);
            }
          }
        }
      }
    }
    return total_left;
  };

  return (
    <div className={cn(STYLE_PAGE)}>
      <PdfHeader />
      <PdfLine />
      <PdfHeaderText title="แผนจัดการหนี้สิน" icon="/images/wealth_1.svg" />

      <PdfCard>
        {/* FIRST */}
        <PdfCardWhite className={cn("px-3")}>
          <div className={cn(STYLE_PDF_TEXT_MEDIUM_12)}>
            2.
            วางแผนขอสินเชื่อส่วนบุคคลจากสถาบันการเงินที่คิดอัตราดอกเบี้ยต่อปีถูกกว่าเดิม
            แล้วมาชำระหนี้สินเดิม ​
          </div>

          <div className={cn(STYLE_PDF_TEXT_MEDIUM_13, "flex justify-between")}>
            <div>จำนวนเงินค้างชำระ</div>
            <div>บาท</div>
          </div>

          <PdfLine />

          <PdfCardGray className={cn("px-3")}>
            <div className="flex justify-between">
              <div className={cn("flex space-x-1")}>
                <div className={cn(STYLE_PDF_TEXT_MEDIUM_12)}>
                  ปรับโครงสร้างหนี้ด้วยอัตราดอกเบี้ยใหม่
                </div>
                <div className={cn(STYLE_PDF_TEXT_MEDIUM_BLUE_12)}>
                  ({numeral(debtData?.loan_interest_rate).format("0,0")}%)
                </div>
              </div>

              <div className={cn("flex space-x-1")}>
                <div className={cn(STYLE_PDF_TEXT_MEDIUM_12)}>
                  ทำให้มีภาระหนี้สิน
                </div>
                <div className={cn(STYLE_PDF_TEXT_MEDIUM_BLUE_12)}>
                  {numeral(debtData?.sum_unpaid_amount_af).format("0,0")}
                </div>
              </div>
            </div>

            <div className="flex justify-between">
              <div className={cn("flex space-x-1")}>
                <div className={cn(STYLE_PDF_TEXT_MEDIUM_12)}>
                  อัตราดอกเบี้ยเดิม
                </div>
                <div className={cn(STYLE_PDF_TEXT_MEDIUM_BLUE_12)}>
                  (
                  {numeral(sumTotalWeightInterest()).format("0,0") === "NaN"
                    ? "0"
                    : numeral(sumTotalWeightInterest()).format("0,0")}
                  %)
                </div>
              </div>

              <div className={cn("flex space-x-1")}>
                <div className={cn(STYLE_PDF_TEXT_MEDIUM_12)}>
                  ทำให้มีภาระหนี้สิน
                </div>
                <div className={cn(STYLE_PDF_TEXT_MEDIUM_BLUE_12)}>
                  {numeral(debtData?.sum_unpaid_amount).format("0,0")}
                </div>
              </div>
            </div>
          </PdfCardGray>

          <div className={cn("flex justify-between mt-2")}>
            <div className={cn("flex space-x-1")}>
              <div className={cn(STYLE_PDF_TEXT_MEDIUM_12)}>ประหยัดเงินได้</div>
              <div className={cn(STYLE_PDF_TEXT_MEDIUM_GRAY_11)}>
                ({numeral(debtData?.sum_unpaid_amount).format("0,0")} -
                {numeral(debtData?.sum_unpaid_amount_af).format("0,0")})
              </div>
            </div>

            <div className={cn(STYLE_PDF_TEXT_MEDIUM_BLUE_13)}>
              {numeral(debtData?.total_save_amount_af).format("0,0")}
            </div>
          </div>
        </PdfCardWhite>

        {/* SECOND GRAHP */}
        <PdfCardWhite className={cn("mt-3")}>
          <BarChartGroupDebt
            data1={debtData?.sum_unpaid_amount}
            data2={debtData?.sum_unpaid_amount_af}
            data3={debtData?.total_save_amount_af}
            width={300}
            height={120}
          />
        </PdfCardWhite>

        {/* THIRD */}
        <PdfCardWhite className={cn("px-3 mt-3")}>
          <div className={cn(STYLE_PDF_TEXT_MEDIUM_12)}>
            3. ถ้าหากมีรถยนต์ที่เข้า 3 คุณสมบัตินี้
          </div>

          <div className={cn(STYLE_PDF_TEXT_NORMAL_GRAY_11, "mt-3")}>
            1.มูลค่ารถยนต์ปัจจุบัน {">"} หนี้รถยนต์ โดยที่ ต้องมากกว่า 100,000
            บาทขึ้นไป
          </div>
          <div className={cn(STYLE_PDF_TEXT_NORMAL_GRAY_11, "mt-1")}>
            2.ต้องมีหนี้ระยะสั้น ​
          </div>
          <div className={cn(STYLE_PDF_TEXT_NORMAL_GRAY_11, "mt-1")}>
            3.ส่วนต่างข้องมูลค่ารถยนต์ปัจจุบัน - หนี้รถยนต์
            ต้องมากกว่าจำนวนหนี้ระยะสั้น 
          </div>

          {/* {isHasCollateral && (
            <React.Fragment>
              <div className={cn(STYLE_PDF_TEXT_MEDIUM_13, "text-center mt-2")}>
                สามารถนำไปรีไฟแนนซ์ได้
              </div>
              <div
                className={cn(
                  "flex text-center mt-2 justify-center items-center space-x-1",
                  STYLE_PDF_TEXT_MEDIUM_13
                )}
              >
                <div>กรณีรถยนต์รีไฟแนนซ์ได้</div>
                <div className={cn(STYLE_PDF_TEXT_MEDIUM_BLUE_13)}>
                  {numeral(debtData.refinance_amount).format("0,0")}
                </div>
                <div>บาท</div>
                <div>ดอกเบี้ย</div>
                <div className={cn(STYLE_PDF_TEXT_MEDIUM_BLUE_13)}>
                  {debtData?.car_interest_rate}%
                </div>
                <div>จะประหยัดเงินได้</div>
                <div className={cn(STYLE_PDF_TEXT_MEDIUM_BLUE_13)}>
                  {numeral(debtData.refinance_amount).format("0,0")}
                </div>
                <div>บาท</div>
              </div>
            </React.Fragment>
          )} */}

          {isHasCollateral && (
            <React.Fragment>
              <div className={cn(STYLE_PDF_TEXT_MEDIUM_11, "text-center mt-2")}>
                รีไฟแนนซ์ รถ {mostCar?.title_car} ได้เงิน{" "}
                {numeral(debtData?.refinance_amount).format("0,0")} บาท
                ต้องการผ่อน {numeral(debtData?.total_per_month).format("0,0")}{" "}
                เดือน เจ้าหนี้จะได้เงินไปทั้งสิ้น{" "}
                {numeral(
                  debtData?.refinance_amount + debtData?.total_car_interest_rate
                )?.format("0,0")}{" "}
                บาท เป็นดอกเบี้ย{" "}
                {numeral(debtData?.total_car_interest_rate)?.format("0,0")} บาท
                จากเงินกู้ {numeral(debtData?.refinance_amount).format("0,0")}{" "}
                บาท
              </div>
            </React.Fragment>
          )}
        </PdfCardWhite>
      </PdfCard>
      <PdfFooter page={page} />
    </div>
  );
}

export default PdfPageDebtThree;
