import { useNavigate } from "react-router-dom";
import { HeaderTitle } from "../../components/header/Header";
import Container from "../../components/layout/Container";
import NavigateGoBack, { NavigatePath } from "../../functions/Navigate.Goback";
import { STYLE_MARGIN_TOP_PARENT } from "../../utils/useStyle";
import ViewAccidentCase from "./components/VIAccident_Case";
import ViewCarAccidentCase from "./components/VICarAccident_Case";
import ViewDeadCase from "./components/VIDead_Case";
import ViewDisabledCase from "./components/VIDisabled_Case";
import ViewFireCase from "./components/VIFire_Case";
import ViewSickCase from "./components/VISick_Case";
import { TestCardMain } from "./styles/TestCard.Main";
import { createContext, useContext, useEffect, useState } from "react";
import TestMain from "../../components/chart/Test";
import { useRiskStore } from "../../_store/riskStore";

const RiskContext = createContext();

export const useRiskContext = () => useContext(RiskContext);

const RiskPlanMain = () => {
  const navigate = useNavigate();

  // Risk Data
  const riskData = useRiskStore((state) => state.riskData);
  const setRiskData = useRiskStore((state) => state.setRiskData);
  const clearRiskData = useRiskStore((state) => state.clearRiskData);

  const [menu, setMenu] = useState("");

  const [formRisk, setFormRisk] = useState({
    dead_level_pass_away_high: false,
    dead_level_pass_away_medium: false,
    dead_level_pass_away_low: false,
    dead_pass_away_yesterday_high: false,
    dead_pass_away_yesterday_medium: false,
    dead_pass_away_yesterday_low: false,
    dead_manage_risk_avoidance: false,
    dead_manage_risk_control: false,
    dead_manage_risk_accept: false,
    dead_manage_risk_transfer: false,
    dead_sum_score: 0,

    sick_level_pass_away_high: false,
    sick_level_pass_away_medium: false,
    sick_level_pass_away_low: false,
    sick_pass_away_yesterday_high: false,
    sick_pass_away_yesterday_medium: false,
    sick_pass_away_yesterday_low: false,
    sick_manage_risk_avoidance: false,
    sick_manage_risk_control: false,
    sick_manage_risk_accept: false,
    sick_manage_risk_transfer: false,
    sick_sum_score: 0,

    disable_level_pass_away_high: false,
    disable_level_pass_away_medium: false,
    disable_level_pass_away_low: false,
    disable_pass_away_yesterday_high: false,
    disable_pass_away_yesterday_medium: false,
    disable_pass_away_yesterday_low: false,
    disable_manage_risk_avoidance: false,
    disable_manage_risk_control: false,
    disable_manage_risk_accept: false,
    disable_manage_risk_transfer: false,
    disable_sum_score: 0,

    accident_level_pass_away_high: false,
    accident_level_pass_away_medium: false,
    accident_level_pass_away_low: false,
    accident_pass_away_yesterday_high: false,
    accident_pass_away_yesterday_medium: false,
    accident_pass_away_yesterday_low: false,
    accident_manage_risk_avoidance: false,
    accident_manage_risk_control: false,
    accident_manage_risk_accept: false,
    accident_manage_risk_transfer: false,
    accident_sum_score: 0,

    fire_level_pass_away_high: false,
    fire_level_pass_away_medium: false,
    fire_level_pass_away_low: false,
    fire_pass_away_yesterday_high: false,
    fire_pass_away_yesterday_medium: false,
    fire_pass_away_yesterday_low: false,
    fire_manage_risk_avoidance: false,
    fire_manage_risk_control: false,
    fire_manage_risk_accept: false,
    fire_manage_risk_transfer: false,
    fire_sum_score: 0,

    car_accident_level_pass_away_high: false,
    car_accident_level_pass_away_medium: false,
    car_accident_level_pass_away_low: false,
    car_accident_pass_away_yesterday_high: false,
    car_accident_pass_away_yesterday_medium: false,
    car_accident_pass_away_yesterday_low: false,
    car_accident_manage_risk_avoidance: false,
    car_accident_manage_risk_control: false,
    car_accident_manage_risk_accept: false,
    car_accident_manage_risk_transfer: false,
    car_accident_sum_score: 0,
  });

  // console.log("formRisk : ", formRisk);
  // console.log("riskData : ", riskData);

  useEffect(() => {
    if (riskData) {
      setFormRisk({
        ...riskData,
      });
    }
  }, [riskData]);

  return (
    <RiskContext.Provider value={{ formRisk, setFormRisk }}>
      <Container>
        <div className=" mb-20">
          <div className={`${STYLE_MARGIN_TOP_PARENT}  `}>
            <HeaderTitle
              title="วางแผนบริหารความเสี่ยง"
              isGoback
              onClickGoBack={() => {
                menu ? setMenu("") : NavigatePath(navigate, "/");
              }}
            />

            {/*---------- Menu ----------*/}
            {!menu && (
              <div>
                <div className=" text-maincyan font-medium text-center mt-5">
                  แบบทดสอบวิธีจัดการความเสี่ยงของท่าน
                </div>
                <div className=" grid grid-cols-2 gap-5 px-[25px] mt-7">
                  <TestCardMain
                    title={`กรณีเสียชีวิต`}
                    img={"/images/risk_dead.svg"}
                    onClick={() => {
                      setMenu("dead_case");
                    }}
                  />
                  <TestCardMain
                    title={`กรณีเจ็บป่วย`}
                    img={"/images/risk_sick.svg"}
                    onClick={() => {
                      setMenu("sick_case");
                    }}
                  />
                  <TestCardMain
                    title={`กรณีทุพพลภาพ`}
                    img={"/images/risk_diabled.svg"}
                    onClick={() => {
                      setMenu("disable_case");
                    }}
                  />
                  <TestCardMain
                    title={`กรณีอุบัติเหตุ`}
                    img={"/images/risk_accident.svg"}
                    onClick={() => {
                      setMenu("accident_case");
                    }}
                  />
                  <TestCardMain
                    title={`กรณีอัคคีภัย`}
                    img={"/images/risk_fire.svg"}
                    onClick={() => {
                      setMenu("fire_case");
                    }}
                  />
                  <TestCardMain
                    title={`กรณีอุบัติเหตุทางรถยนต์`}
                    img={"/images/risk_car_accident.svg"}
                    classNameImg=""
                    onClick={() => {
                      setMenu("car_accident");
                    }}
                  />
                </div>
              </div>
            )}

            {/*---------- กรณีเสียชีวิต ----------*/}
            {menu === "dead_case" && (
              <ViewDeadCase
                formRisk={formRisk}
                setFormRisk={setFormRisk}
                setMenu={setMenu}
                setRiskData={setRiskData}
              />
            )}

            {/*---------- กรณีเจ็บป่วย ----------*/}
            {menu === "sick_case" && (
              <ViewSickCase
                formRisk={formRisk}
                setFormRisk={setFormRisk}
                setMenu={setMenu}
                setRiskData={setRiskData}
              />
            )}

            {/*---------- กรณีทุพพลภาพ ----------*/}
            {menu === "disable_case" && (
              <ViewDisabledCase
                formRisk={formRisk}
                setFormRisk={setFormRisk}
                setMenu={setMenu}
                setRiskData={setRiskData}
              />
            )}

            {/*---------- กรณีอุบัติเหตุ----------*/}
            {menu === "accident_case" && (
              <ViewAccidentCase
                formRisk={formRisk}
                setFormRisk={setFormRisk}
                setMenu={setMenu}
                setRiskData={setRiskData}
              />
            )}

            {/*---------- กรณีอัคคีภัย ----------*/}
            {menu === "fire_case" && (
              <ViewFireCase
                formRisk={formRisk}
                setFormRisk={setFormRisk}
                setMenu={setMenu}
                setRiskData={setRiskData}
              />
            )}

            {/*---------- กรณีอุบัติเหตุรถยนต์ ----------*/}
            {menu === "car_accident" && (
              <ViewCarAccidentCase
                formRisk={formRisk}
                setFormRisk={setFormRisk}
                setMenu={setMenu}
                setRiskData={setRiskData}
              />
            )}
          </div>
        </div>
      </Container>
    </RiskContext.Provider>
  );
};
export default RiskPlanMain;
